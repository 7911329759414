import React, { Component } from 'react';
import withWidth from '@material-ui/core/withWidth';
import toRenderProps from 'recompose/toRenderProps';
import { CheckRtl } from '../../../Utilities/CheckLang';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { jalaliFullDateTime } from '../../../Utilities/jalaliDate';
import './RadiologyCard.css';
const WithWidth = toRenderProps(withWidth());

class RadiologyCard extends Component {
  constructor() {
    super();
    this.state = { openResponsiveDrawer: false };
  }
  handleResponsiveDrawer() {
    // this.setState({ openResponsiveDrawer: !this.state.openResponsiveDrawer });
  }
  createMarkup(title) {
    if (title) {
      title = title.replace(/(?:\r\n|\r|\n|↵)/g, '<br />');
      return { __html: `${title}` };
    } else {
      return { __html: '' };
    }
  }
  render() {
    const { radio } = this.props;
    return (
      <WithWidth>
        {({ width }) => (
          <div className='RadioCardWholeContainer'>
            <div
              className={'hoverable radiology-card'}
              onClick={() => this.handleResponsiveDrawer()}
            >
              <Link
                to={{
                  pathname: `${this.props.location.pathname}/${radio.id}`,
                  state: {
                    radio: radio,
                  },
                }}
              >
                <div className=''>
                  <div className='RadioDateAndTitleContainer'>
                    <div
                      className={
                        CheckRtl(radio.title[0])
                          ? 'farsiNum rtl'
                          : 'farsiNum ltr'
                      }
                    >
                      {radio.service_name}
                    </div>
                    <div className=''>
                      <div className='flex'>
                        {jalaliFullDateTime(radio.service_datetime)}
                      </div>
                    </div>
                  </div>
                  <div
                    className={
                      CheckRtl(radio.title[0])
                        ? 'farsiNum rtl text-right'
                        : 'farsiNum ltr text-left'
                    }
                    dangerouslySetInnerHTML={this.createMarkup(radio.title)}
                  />
                </div>
              </Link>
            </div>
          </div>
        )}
      </WithWidth>
    );
  }
}

export default RadiologyCard;
