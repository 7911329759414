export const createURLSearchParams = (obj) => {
  for (let prop in obj) {
    if (
      obj[prop] === undefined ||
      obj[prop] === null ||
      Number.isNaN(obj[prop])
    ) {
      delete obj[prop];
    }
  }
  return new URLSearchParams(obj);
};
