import React from 'react';
import Loader from 'react-loader-spinner';
export const LoaderWrapper = (props) => {
  const { height, width, color } = props;
  return (
    <Loader
      type='ThreeDots'
      color={color ? color : '#386fff'}
      height={height ? height : '20px'}
      width={width ? width : '40px'}
    />
  );
};
export default LoaderWrapper;
