import axios from 'axios';
import { getRefreshToken, getToken, setTokens,getNamespaceId } from '../Utilities/storage';
import { reauthenticate } from './userApi';
import store from '../store';

export const BASE_URL = window.env.REACT_APP_API_URL;
export const HOST_URL = window.env.REACT_APP_HOST_URL ?? '';

const API_VERSION = 'v2';

axios.defaults.baseURL = BASE_URL + (API_VERSION ? '/' + API_VERSION : '');
axios.defaults.timeout = 60 * 4 * 1000;
axios.interceptors.request.use((config) => {
  config.headers['Authorization'] = 'Bearer ' + getToken();

  const excludedUrls = ['/login', '/logout'];
  if (excludedUrls.some(url => config.url.includes(url))) {
    return config;
  }

  config.headers['X-NAMESPACE'] = getNamespaceId();
  return config;
});
let onRefreshToken = false;
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const request = error.config;
    if (error.response) {
      const status = await error.response.status;
      const code = await error.response.data?.code;
      const message = await error.response.data?.message;
      if (
        code === 'ERR_REFRESH_TOKEN_INVALID' ||
        code === 'ERR_TOKEN_INVALID' ||
        code === 'ERR_DEVICE_ID_MISMATCHED'
      ) {
        handleInvalidToken();
      } else if (
        (code === 'ERR_TOKEN_EXPIRED' ||
          (status === 401 && code !== 'ERR_CREDENTIALS_INVALID')) &&
        !request._retry &&
        !request.url.includes('auth')
      ) {
        onRefreshToken = true;
        request._retry = true;
        return await handleReAuth()
          .then(() => {
            request._retry = false;
            return axios(request);
          })
          .catch((error) => {
            request._retry = false;
            console.log(error);
            handleInvalidToken();
          })
          .finally(() => {
            onRefreshToken = false;
          });
      } else if (message) {
        store.dispatch({
          type: 'ADD_MESSAGE',
          message,
          status,
        });
      } else if (onRefreshToken) {
        store.dispatch({
          type: 'ADD_MESSAGE',
          message: 'Session has expired. You must login again.',
          status: status,
        });
      } else {
        store.dispatch({
          type: 'ADD_MESSAGE',
          message: 'Face unknown issue, please contact system administrator.',
          status: status,
        });
      }
    } else {
      store.dispatch({
        type: 'ADD_MESSAGE',
        message:
          'No Internet connection! Please check your Internet connection!',
        status: 500,
      });
      return Promise.reject({ networkError: true });
    }
    return Promise.reject(error);
  }
);
export const Get = (url, params, config = {}) =>
  axios.get(url, { params, ...config });
export const Post = (url, formData) => axios.post(url, formData);
export const Put = (url, formData) => axios.put(url, formData);
export const Delete = (url) => axios.delete(url);
export const GetImage = (url) =>
  axios.get(url, {
    baseURL: HOST_URL,
    Authorization: 'Bearer ' + getToken(),
    responseType: 'blob',
    validateStatus: function (status) {
      // for get signatures for handling 404 response
      return (status >= 200 && status < 300) || status === 404; // Reject rule
    },
  });

export const GetBlob = (url) =>
  axios.get(url, {
    Authorization: 'Bearer ' + getToken(),
    responseType: 'blob',
    validateStatus: function (status) {
      // for get signatures for handling 404 response
      return (status >= 200 && status < 300) || status === 404; // Reject rule
    },
  });
export const PostICD11 = (url, formData) =>
  axios.post(url, formData, {
    headers: {
      Authorization: 'bearer ' + getToken(),
      'API-Version': 'v2',
      'Accept-Language': 'en',
    },
  });

const handleReAuth = async () => {
  return await reauthenticate({ refresh_token: getRefreshToken() }).then(
    (response) => {
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + response.data.access;
      setTokens(response.data);
    }
  );
};
const handleInvalidToken = () => {
    // TODO: DRY
    localStorage.removeItem('myData');
    localStorage.removeItem('fcm-token');
    localStorage.removeItem('namespaceId');
  window.location.reload();
};

export default axios;
