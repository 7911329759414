import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BackArrow from '../../Assets/icons/vuesax_linear_arrow_left.svg';
import FEMALE_ICON from '../../Assets/icons/vuesax_linear_woman.svg';
import MALE_ICON from '../../Assets/icons/vuesax_linear_man.svg';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import { CheckRtl } from '../../Utilities/CheckLang';
import clsx from 'clsx';
import { getAgeWithUnit } from '../../Utilities/jalaliDate';

const styles = makeStyles((theme) => ({
  container: {
    marginTop: '0px',
    textAlign: 'left',
    padding: '0px 24px 0 12px',
    background: 'white',
    minHeight: '53px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #ccccd2e0',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'unset',
      flexDirection: 'column',
    },
  },
  outerContainer: {
    position: 'relative',
    zIndex: 20,
  },
  backIcon: {
    opacity: '.73',
  },
  loadingContainer: {
    display: 'flex',
    '&::after': {
      padding: '0 2px',
      content: '" ."',
      animation: 'dots 1s steps(5, end) infinite',
      width: '10px',
    },
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      color: '#363636',
    },
  },
  titleNames: {
    flex: 1,
  },
  element: {
    width: '50px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 1,
    width: '100%',
    height: '100%',
  },
  linearProgress: {
    position: 'absolute',
    bottom: -4,
    left: 0,
    width: '100%',
  },
}));
const PageHeader = ({
  title,
  subTitle,
  centerAdjustment,
  optionComponent,
  component,
  cacheEnable,
  backButton,
  desktopBackButton,
  defaultGoBack,
  showPatientInfo,
  onBackButtonClick,
  profile,
  dataFetchedFromDB,
  pageDataFetched,
  pageDataNotReloaded,
}) => {
  const classes = styles();
  const params = useParams();
  const history = useHistory();
  const theme = useTheme();
  const [dataFromDB, setDataFromDB] = useState(null);
  const [upToDate, setUpToDate] = useState(null);
  const [errorUpdating, setErrorUpdating] = useState(null);
  const mobileMedia = useMediaQuery(theme.breakpoints.down('sm'));

  const userName = useMemo(
    () => (
      <div className='flex-center'>
        <Typography variant='button' className='ml-2'>
          {getAgeWithUnit(profile?.birth_date)?.age}
          {getAgeWithUnit(profile?.birth_date)?.unit && (
            <sup>{getAgeWithUnit(profile?.birth_date)?.unit}</sup>
          )}
        </Typography>
        <div className='farsiNum ml-2'>
          {`${profile?.first_name || ''} ${profile?.last_name || ''}`}
        </div>
      </div>
    ),
    [profile, params.patientId]
  );

  const patientGender = useMemo(
    () =>
      profile?.gender ? (
        profile.gender === 'F' ? (
          <img alt='gender icon' height={20} src={FEMALE_ICON} />
        ) : (
          <img alt='gender icon' height={20} src={MALE_ICON} />
        )
      ) : null,
    [profile, params.patientId]
  );

  useEffect(() => {
    if (!cacheEnable) return;
    if (dataFetchedFromDB) {
      setDataFromDB(true);
    } else {
      setDataFromDB(false);
    }
    if (pageDataFetched) {
      setUpToDate(true);
    } else {
      setUpToDate(false);
    }
    if (pageDataNotReloaded) {
      setErrorUpdating(true);
    } else {
      setErrorUpdating(false);
    }
  }, [dataFetchedFromDB, pageDataFetched, pageDataNotReloaded, cacheEnable]);

  const handleGoBack = () => {
    if (defaultGoBack) history.goBack();
    if (typeof onBackButtonClick === 'function') onBackButtonClick();
  };

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.content}>
          {backButton && (mobileMedia || desktopBackButton) && (
            <IconButton onClick={handleGoBack} className={classes.backIcon}>
              <img src={BackArrow} alt='back' />
            </IconButton>
          )}

          {component ? (
            component({ params, patientName: userName })
          ) : (
            <>
              <div
                className={clsx(
                  'min-height',
                  classes.titleNames,
                  (title || centerAdjustment) && 'text-center',
                  CheckRtl(title) ? 'farsiNum' : ''
                )}
              >
                {(!showPatientInfo ||
                  params?.patientId === String(profile?.profile_id)) && (
                  <>
                    {title ? (
                      <div className='flex-center'>
                        {showPatientInfo && !optionComponent ? (
                          <div className='xnormalFont flex-center'>
                            {userName}
                            &nbsp;-&nbsp;
                            {title}
                          </div>
                        ) : (
                          title
                        )}
                      </div>
                    ) : (
                      <div
                        className={clsx(
                          'align-items-center greyText',
                          !centerAdjustment && mobileMedia
                            ? 'justify-content-end'
                            : 'flex-center'
                        )}
                      >
                        {patientGender}
                        {userName}
                      </div>
                    )}
                  </>
                )}
              </div>
              {optionComponent
                ? optionComponent
                : mobileMedia
                ? backButton &&
                  (centerAdjustment || title) && (
                    <div className={classes.element} />
                  )
                : desktopBackButton && <div className={classes.element} />}
            </>
          )}
        </div>
        {!mobileMedia && (
          <div className='flex flex-space-between'>
            {!component && (
              <Typography
                color='textSecondary'
                variant='body1'
                className={classes.title}
              >
                {subTitle}
              </Typography>
            )}

            {cacheEnable ? (
              upToDate === true && dataFromDB === true ? (
                <Typography color='textSecondary' variant='body2'>
                  Updated
                  <span>&#10004;</span>
                </Typography>
              ) : dataFromDB === true && errorUpdating === false ? (
                <Typography
                  color='textSecondary'
                  variant='body2'
                  className={classes.loadingContainer}
                >
                  <span>{` Updating ${subTitle || ''} `}</span>
                </Typography>
              ) : dataFromDB === true && errorUpdating === true ? (
                <Typography color='textSecondary' variant='body2'>
                  <span> Offline Mode! </span>
                </Typography>
              ) : (
                ''
              )
            ) : (
              ''
            )}
          </div>
        )}
      </div>
      {dataFromDB === true && errorUpdating === false && upToDate === false && (
        <LinearProgress className={classes.linearProgress} />
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  profile: state.patientsReducer.patient?.profile,
});

PageHeader.defaultProps = {
  centerAdjustment: false,
  defaultGoBack: true,
  backButton: true,
  cacheEnable: false,
  desktopBackButton: false,
  showPatientInfo: true,
  optionComponent: null,
};

PageHeader.propTypes = {
  desktopBackButton: PropTypes.bool,
  showPatientInfo: PropTypes.bool,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  centerAdjustment: PropTypes.bool,
  dataFetchedFromDB: PropTypes.bool,
  onBackButtonClick: PropTypes.func,
  pageDataFetched: PropTypes.bool,
  pageDataNotReloaded: PropTypes.bool,
  component: PropTypes.func,
  optionComponent: PropTypes.any,
};

export default connect(mapStateToProps)(PageHeader);
