import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
} from '@material-ui/core/styles';
import theme from '../../theme-rtl';

interface IProps {
  children: React.ReactNode;
}

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const RTL: React.FC<IProps> = ({ children }) => {
  return (
    <div dir='rtl'>
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>{children}</StylesProvider>
      </ThemeProvider>
    </div>
  );
};

export default RTL;
