import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
export default function DisableApp() {
  const history = useHistory();
  if (!window.env.REACT_APP_DISABLE) {
    history.push('/login');
  }
  return (
    <Dialog open={true}>
      <DialogContent style={{ padding: '1em' }}>
        <DialogContentText
          style={{ direction: 'rtl', color: 'inherit' }}
          id='alert-dialog-description'
        >
          <p style={{ margin: '0' }}> {'سلام.'} </p>
          <p style={{ marginTop: '0' }}>
            {' '}
            {
              'اینلب برای یک اپدیت ماژور برای بیمارستان باید یه مدت غیر فعال باشه اما قول میدیم خیلی زود با امکانات جدید برمیگردیم. آرزومون اینه که توی این مدت تونسته باشیم بخشی از خستگی شما رو کم بکنیم و کاری کنیم بیشتر از نجات دادن جون انسان ها لذت ببرید.'
            }
          </p>
          <div style={{ margin: '0.5em 0 1em 0' }}>
            {
              'اگر لازم میدونین در مورد ما چیزی بنویسین خیلی خوشحال میشیم برامون '
            }
            <a
              style={{ color: '#009be5' }}
              href='https://play.google.com/store/apps/details?id=org.inteb.inlab'
              target='_blank'
              rel='noreferrer'
            >
              اینجا
            </a>
            &nbsp;
            {'امتیاز و کامنت بگذارید.ما حتما همه شو میخونیم'}&#128522;
          </div>
          <p style={{ margin: '0' }}>{'دلمون براتون تنگ میشه'}</p>
          <p style={{ margin: '0 0 13px 0' }}>{'تیم اینلب'}</p>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
