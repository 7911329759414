import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';
import './PatientProfile.css';
import { Divider, Box, makeStyles } from '@material-ui/core';
import { NoContent } from '../shared';
import ProfileEditableContent from './ProfileEditableContent';
import ImageContent from '../ImageContent';
import RouteWithSubRoutes from '../RouteWithSubRoutes';
import {
  editProfile,
  uploadProfileImage,
  deleteProfileImage,
} from '../../action/patientAction';
import {
  setPinnedProfileImage,
  unsetPinnedProfileImage,
} from '../../action/actions';
import { getPinnedProfileImages } from '../../action/actions';
import TextListDialog from '../TextListDialog/TextListDialog';
import { IMAGE_ROLES } from './values';
import PageWrapper from '../PageWrapper';
import { getPatientProfile } from '../../action/profile';
import PageHeader from '../PageHeader/PageHeader';
import PatientInfo from './PatientInfo';
import DismissedPatient from './DismissedPatient';
import useAccess from '../../Hook/useAccess';
import { getNamespaceId } from '../../Utilities/storage';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f0f0f0',
  },
  container: {
    padding: '20px 24px 18px',
    backgroundColor: '#ffffff',
  },
  item: {
    marginBottom: 15,
  },
  divider: {
    backgroundColor: '#f0f0f0',
    minHeight: 20,
  },
  infoContainer: {
    paddingBottom: '20px',
  },
  info: {
    padding: '9px 24px',
    textAlign: 'right',
    direction: 'rtl',
    fontSize: 13,
    color: '#999999',
    margin: '10px 0 0',
    backgroundColor: '#fff',
  },
  cardLink: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

function PatientProfile({
  profile,
  profileDataFetchedFromDB,
  match,
  pinnedImages,
  uploadQueue,
  routes,
  headerOptions,
  getPatientProfile,
  editProfile,
  uploadProfileImage,
  deleteProfileImage,
  getPinnedProfileImages,
  setPinnedProfileImage,
  unsetPinnedProfileImage,
}) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { patientId } = useParams();
  const { data: dismissedAccess, isLoading: isLoadingAccess } = useAccess(
    'can_search_dismissed_patients'
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const openInfoDialog = Boolean(location.state?.action === 'show_info');
  const patientUploadQueue = useMemo(
    () => uploadQueue.filter((item) => String(item.profileId) === patientId),
    [patientId, uploadQueue]
  );

  useEffect(() => {
    fetchPageData();
    getPinnedProfileImages(patientId);
  }, [patientId]);

  const fetchPageData = async () => {
    await setLoading(true);
    await setError(null);
    getPatientProfile(patientId)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        console.log(err);
      });
  };

  // typeof values: { note?:string, height?:number, weight?:number, dismissed?:boolean }
  const handleEditSubmit = (values) => {
    const requestData = {
      note: profile.note,
      height: profile.height,
      weight: profile.weight,
      dismissed: !!profile.dismissed,
      ...values,
    };
    return editProfile(requestData, profile.profile_id);
  };

  const handleImageUpload = (img, queueId = 0) => {
    return uploadProfileImage(img, profile.profile_id, queueId);
  };

  const handleImageDelete = (images) => {
    deleteProfileImage(images, profile.profile_id);
  };

  const handleActionStateChange = (action) => {
    if (location.state?.action) {
      if (action) {
        history.replace(match.url, { action });
      } else {
        history.replace(match.url, { action: '' });
        history.goBack();
      }
    } else {
      if (action) history.push(match.url, { action });
    }
  };

  if (!profile && !loading && !error) {
    return <NoContent width={'65%'} text={'Patient not found!'} />;
  }

  function renderInfo() {
    return (
      <Fragment>
        <Box className={classes.infoContainer}>
          <Box className={classes.info}>
            <Box>
              به ازای هر بیمار حداکثر تعداد مشخصی (۱۵ تصویر) قابل ثبت است.
            </Box>
            <Box
              className={classes.cardLink}
              onClick={() => handleActionStateChange('show_info')}
            >
              توضیح بیشتر »
            </Box>
          </Box>
        </Box>
        <TextListDialog
          listData={IMAGE_ROLES}
          open={openInfoDialog}
          handleClose={() => handleActionStateChange('')}
        />
      </Fragment>
    );
  }

  var bioArcPatientDeepLink = (profile ?
    `https://clinics.tums.ac.ir/physician?inlab_instance=${window.env.REACT_APP_ORGANIZATION}&inlab_namespace=${getNamespaceId()}&profile_id=${profile.profile_id}&national_code=${profile.national_id}` :
    undefined
    );

  return (
    <Fragment>
      <PageHeader
        {...headerOptions}
        showPatientInfo={false}
        title='Health Record'
        cacheEnable={true}
        pageDataFetched={!loading && !error}
        pageDataNotReloaded={!loading && error}
        dataFetchedFromDB={profileDataFetchedFromDB}
      />
      <PageWrapper
        loading={loading || isLoadingAccess}
        error={error}
        onReload={fetchPageData}
      >
        <div className={classes.root}>
          <PatientInfo withEditButton profile={profile} bioArcPatientDeepLink={bioArcPatientDeepLink} />
          <Divider className={classes.divider} />
          {dismissedAccess && (
            <>
              <DismissedPatient
                defaultValue={!!profile?.dismissed}
                onSubmit={handleEditSubmit}
              />
              <Divider className={classes.divider} />
            </>
          )}
          <ProfileEditableContent
            profile={profile}
            onSubmit={handleEditSubmit}
          />
          <Divider className={classes.divider} />
          <ImageContent
            basePath={`/patient/${patientId}/profile`}
            className={classes.container}
            images={profile?.images}
            limits={15}
            pinnedImages={pinnedImages}
            uploadQueue={patientUploadQueue}
            handleImageUpload={handleImageUpload}
            handleDelete={handleImageDelete}
            onPin={setPinnedProfileImage}
            onUnpin={unsetPinnedProfileImage}
          />
          {renderInfo()}
          {Boolean(routes?.length) &&
            routes.map((route) => (
              <RouteWithSubRoutes key={route.key} route={route} />
            ))}
        </div>
      </PageWrapper>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state.patientsReducer.patient?.profile,
    profileDataFetchedFromDB:
      state.patientsReducer.patient?.profileDataFetchedFromDB,
    pinnedImages: state.patientsReducer.pinnedProfileImages,
    uploadQueue: state.patientsReducer?.imageUploadQueue || [],
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getPatientProfile,
    editProfile,
    uploadProfileImage,
    deleteProfileImage,
    getPinnedProfileImages,
    setPinnedProfileImage,
    unsetPinnedProfileImage,
  })(PatientProfile)
);
