import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  ICardex,
  ICardexAppendix,
  IVisit,
  IVisitSummery,
} from '../../../../types/order';
import { connect, RootStateOrAny } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@material-ui/core';
import DraftVisitsDrawer from '../../Visit/DraftVisitsDrawer';
import EMPTY_CARDEX_VECTOR from '../../../../Assets/vectors/empty.svg';
import LastCardexTitle from './Title';
import CardexState from '../State';
import NursingCardex from '../Nursing';
import NursingMultiFinish from '../Nursing/MultiFinish';
import useAccess from '../../../../Hook/useAccess';
import usePathAction from '../../../../Hook/usePathAction';
import { addMessage } from '../../../../action/generalActions';
import { AlertDialog } from '../../../shared';
import { reformatCardex } from '../../../../Utilities/order';
import FinishedTasks from '../Nursing/FinishedTasks';
import UncheckedVisitsCarousel from '../../Visit/UncheckedVisitsCarousel';
import {
  getHeadVisit,
  getRecentVisits,
  getCardexAppendix,
  getLastCheckedVisit,
} from '../../../../action/order';
import PageWrapper from '../../../PageWrapper';
import DrawerPage from '../../../DrawerPage';

export interface CardexTypeIState {
  cardexType?: 'head' | 'last_checked';
  viewMode?: 'compact' | 'detailed';
}

interface IProps {
  headVisit: IVisit | null;
  parentVisit: IVisit | null;
  lastCheckedVisit: IVisit | null;
  uncheckedVisits: IVisitSummery[];
  cardexAppendix: ICardexAppendix | null;
  getUncheckedVisits: (profileId: number) => Promise<any>;
  getHeadVisit: (profileId: number) => Promise<any>;
  getLastCheckedVisit: (profileId: number | string) => Promise<any>;
  getCardexAppendix: (profileId: number) => Promise<any>;
  addMessage: (text: string, status: number) => Promise<void>;
}

const LastCardex: React.FC<IProps> = ({
  headVisit,
  parentVisit,
  lastCheckedVisit,
  uncheckedVisits,
  cardexAppendix,
  getUncheckedVisits,
  getHeadVisit,
  getLastCheckedVisit,
  getCardexAppendix,
  addMessage,
}) => {
  const uncheckedVisitsCarouselRef = useRef<HTMLDivElement | null>(null);
  const { patientId } = useParams<{ patientId: string }>();
  const location = useLocation<CardexTypeIState>();
  const { data: nurseVisitOperatorAccess } = useAccess('nurse_visit_operator');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pathAction, setPathAction] = usePathAction();
  const [cardex, setCardex] = useState<ICardex>({});
  const [enableMultiFinish, setEnableMultiFinish] = useState(false);

  const currentViewMode = useMemo(
    () => location.state?.viewMode || 'detailed',
    [location.state?.viewMode]
  );

  const fetchPageData = async () => {
    await setLoading(true);
    await setError(null);

    await Promise.all([
      getHeadVisit(parseInt(patientId)),
      getUncheckedVisits(parseInt(patientId)),
      getCardexAppendix(parseInt(patientId)),
      getLastCheckedVisit(patientId),
    ]).catch((err) => {
      console.log(err);
      setError(err);
    });

    setLoading(false);
  };

  useEffect(() => {
    fetchPageData();
  }, [patientId]);

  useEffect(() => {
    const newCardex = headVisit
      ? reformatCardex({
          visit: headVisit,
          parentVisit,
          lastCheckedVisit,
          cardexAppendix,
        })
      : {};

    setCardex(newCardex);
  }, [headVisit, parentVisit, lastCheckedVisit, cardexAppendix]);

  const checkNursingPermissions = (shouldCheckUnchecked = true) => {
    if (!nurseVisitOperatorAccess) {
      addMessage('You need permission to do this action!', 300);
    } else if (shouldCheckUnchecked && uncheckedVisits.length) {
      setPathAction('openCheckUnchecked');
    } else {
      return true;
    }
    return false;
  };

  const triggerMultiFinish = (enable?: boolean) => () => {
    setEnableMultiFinish(!!enable);
  };

  const scrollToUncheckedVisitsSection = async () => {
    await setPathAction('');
    await triggerMultiFinish(false)();
    if (uncheckedVisitsCarouselRef.current) {
      uncheckedVisitsCarouselRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const isEmpty = !headVisit;
  return (
    <PageWrapper
      loading={loading}
      error={error}
      onReload={fetchPageData}
      reloadWholePage={false}
      style={{
        minHeight: 'calc(100vh - 111px)',
        ...(loading ? {} : { display: 'contents' }),
      }}
    >
      <Box py={3}>
        <Box mb={2}>
          <DraftVisitsDrawer
            buttonLabel='New visit'
            drawerButtonLabel='Add new visit'
            skipPath={`/patient/${patientId}/order/create-visit`}
            purpose='add'
          />
        </Box>

        {isEmpty ? (
          <Box mt={10}>
            <img src={EMPTY_CARDEX_VECTOR} height={120} alt='no visit' />
            <Typography className='mt-3 hint'>The cardex is empty</Typography>
          </Box>
        ) : (
          <Box>
            {!!uncheckedVisits.length && (
              <Box mb={1}>
                <UncheckedVisitsCarousel
                  offset={3}
                  title='Unchecked visits'
                  items={uncheckedVisits}
                  ref={uncheckedVisitsCarouselRef}
                />
              </Box>
            )}

            <Box mb={1}>
              <LastCardexTitle />
            </Box>

            <Box mb={1.5}>
              <Button
                fullWidth
                color='primary'
                variant='outlined'
                onClick={triggerMultiFinish(true)}
              >
                Multiple select
              </Button>

              <DrawerPage
                open={enableMultiFinish}
                onClose={triggerMultiFinish(false)}
              >
                <NursingMultiFinish
                  cardex={cardex}
                  setCardex={setCardex}
                  checkPermissions={checkNursingPermissions}
                  onClose={triggerMultiFinish(false)}
                />
              </DrawerPage>
            </Box>

            <Box mb={1}>
              <CardexState
                label='Last revised by'
                user={headVisit?.signers[0]}
                datetime={headVisit?.modified}
              />
              {!!headVisit?.checker?.id && (
                <CardexState
                  label='Checked by'
                  user={headVisit?.checker}
                  datetime={headVisit?.checked}
                />
              )}
            </Box>

            <Box mb={2}>
              <NursingCardex
                cardex={cardex}
                detailedView={currentViewMode === 'detailed'}
                setCardex={setCardex}
                checkPermissions={checkNursingPermissions}
              />
            </Box>

            <Box width={1} height='1px' bgcolor='#babfcc' />

            <Box>
              <FinishedTasks />
            </Box>

            <AlertDialog
              open={pathAction === 'openCheckUnchecked'}
              alertText='Please check visits before marking orders as finished.'
              confirmButton={true}
              confirmText='Check unchecked visits'
              confirmButtonProps={{
                fullWidth: true,
                className: '',
                variant: 'contained',
              }}
              handleConfirm={scrollToUncheckedVisitsSection}
              handleClose={() => setPathAction('')}
            />
          </Box>
        )}
      </Box>
    </PageWrapper>
  );
};

export default connect(
  (state: RootStateOrAny) => ({
    headVisit: state.order.headVisit,
    parentVisit: state.order.parentVisit,
    lastCheckedVisit: state.order.lastCheckedVisit,
    uncheckedVisits: state.order.visits,
    cardexAppendix: state.order.cardexAppendix,
  }),
  {
    addMessage,
    getCardexAppendix,
    getLastCheckedVisit,
    getUncheckedVisits: (profileId: number) =>
      getRecentVisits(profileId, 10, 'unchecked'),
    getHeadVisit: (profileId: number) => getHeadVisit(profileId, true),
  }
)(LastCardex);
