import {
  ICategory,
  IVisit,
  ICardex,
  ICardexItem,
  ITask,
  ICardexGroup,
  IOrder,
  ITaskCategory,
  IConflictItem,
  ITaskNoticesMapping,
  ICardexItemState,
  IHistoryItem,
  ICardexAppendix,
  ITaskExtensionsMapping,
  ITaskNoticeLabel,
} from '../types/order';
import { CheckRtl } from './CheckLang';
import { isEqualArray } from './equal';

////////////////
///// Task /////
////////////////

export const isValidTask = (task: ITask): boolean => {
  switch (task.category) {
    case 'impression':
      return !!task.impressions.length;
    case 'condition':
      return !!task.condition.id;
    case 'diet':
      return !!task.diets.length;
    case 'activity':
      return !!task.activity.id;
    case 'lab':
      return !!task.tests.length && !task.timing?.errors?.length;
    case 'nursing':
      return !!task.cares.length && !task.timing?.errors?.length;
    case 'radiography':
      return (
        !!task.image_type.id &&
        !!task.location.id &&
        !!task.laterality.id &&
        !task.timing?.errors?.length
      );
    case 'medication':
      return (
        !!task.drug.id &&
        !!task.form.id &&
        !!task.amount &&
        !!task.route.id &&
        !task.timing?.errors?.length
      );
    case 'consult':
      return !!task.hospital_service.id;
    case 'other':
      return !!task.description && !task.timing?.errors?.length;
  }
  return true;
};

export const isEmptyTask = (task: ITask): boolean => {
  switch (task.category) {
    case 'impression':
      return !task.impressions.length && !task.note;
    case 'condition':
      return !task.condition.id && !task.note;
    case 'diet':
      return !task.diets.length && !task.note;
    case 'activity':
      return !task.activity.id && !task.positions.length && !task.note;
    case 'lab':
      return !task.tests.length && !task.timing?.raw && !task.note;
    case 'nursing':
      return !task.cares.length && !task.timing?.raw && !task.note;
    case 'radiography':
      return (
        !task.image_type.id &&
        !task.location.id &&
        !task.laterality.id &&
        !task.timing?.raw &&
        !task.contrasted_views.length &&
        !task.note
      );
    case 'medication':
      return (
        !task.drug.id &&
        !task.form.id &&
        !task.strength.id &&
        !task.amount &&
        !task.route.id &&
        !task.timing?.raw &&
        !task.added.length &&
        !task.note
      );
    case 'consult':
      return !task.hospital_service.id && !task.timing?.raw && !task.note;
    case 'other':
      return !task.description && !task.timing?.raw && !task.note;
  }
  return true;
};

export const isEqualTask = (task1: ITask, task2: ITask): boolean => {
  // Note: uuid of tasks not check for equality
  if (task1.category === task2.category) {
    switch (task1.category) {
      case 'impression': {
        if (task2.category === 'impression')
          if (task1.note === task2.note) {
            return isEqualArray(
              task1.impressions,
              task2.impressions,
              (i1, i2) => i1.id === i2.id
            );
          }
        return false;
        break;
      }
      case 'condition': {
        if (task2.category === 'condition')
          return (
            task1.condition.id === task2.condition.id &&
            task1.note === task2.note
          );
        break;
      }
      case 'activity': {
        if (task2.category === 'activity') {
          if (
            task1.activity.id === task2.activity.id &&
            task1.note === task2.note
          ) {
            return isEqualArray(
              task1.positions,
              task2.positions,
              (i1, i2) => i1.id === i2.id
            );
          }
          return false;
        }
        break;
      }
      case 'diet': {
        if (task2.category === 'diet')
          if (task1.note === task2.note) {
            return isEqualArray(
              task1.diets,
              task2.diets,
              (i1, i2) => i1.id === i2.id
            );
          }
        return false;
        break;
      }
      case 'lab': {
        if (task2.category === 'lab') {
          if (
            task1.timing?.raw === task2.timing?.raw &&
            task1.note === task2.note
          ) {
            return isEqualArray(
              task1.tests,
              task2.tests,
              (i1, i2) => i1.id === i2.id
            );
          }
          return false;
        }
        break;
      }
      case 'nursing': {
        if (task2.category === 'nursing') {
          if (
            task1.timing?.raw === task2.timing?.raw &&
            task1.note === task2.note
          ) {
            return isEqualArray(
              task1.cares,
              task2.cares,
              (i1, i2) => i1.id === i2.id
            );
          }
          return false;
        }
        break;
      }
      case 'radiography': {
        if (task2.category === 'radiography') {
          if (
            task1.image_type.id === task2.image_type.id &&
            task1.location.id === task2.location.id &&
            task1.laterality.id === task2.laterality.id &&
            task1.timing?.raw === task2.timing?.raw &&
            task1.note === task2.note
          ) {
            return isEqualArray(
              task1.contrasted_views.filter(
                (contrasted_view) =>
                  contrasted_view.view.id && contrasted_view.contrast.id
              ),
              task2.contrasted_views.filter(
                (contrasted_view) =>
                  contrasted_view.view.id && contrasted_view.contrast.id
              ),
              (i1, i2) =>
                i1.view.id === i2.view.id && i1.contrast?.id === i2.contrast?.id
            );
          }
          return false;
        }
        break;
      }
      case 'medication': {
        if (task2.category === 'medication') {
          if (
            task1.drug.id === task2.drug.id &&
            task1.form.id === task2.form.id &&
            task1.strength.id === task2.strength.id &&
            task1.amount === task2.amount &&
            task1.route.id === task2.route.id &&
            task1.timing?.raw === task2.timing?.raw &&
            task1.note === task2.note
          ) {
            return isEqualArray(
              task1.added,
              task2.added,
              (i1, i2) =>
                i1.drug.id === i2.drug.id &&
                i1.form.id === i2.form.id &&
                i1.strength.id === i2.strength.id &&
                i1.amount === i2.amount
            );
          }
          return false;
        }
        break;
      }
      case 'consult': {
        if (task2.category === 'consult') {
          return (
            task1.hospital_service.id === task2.hospital_service.id &&
            task1.note === task2.note &&
            task1.timing?.raw === task2.timing?.raw
          );
        }
        break;
      }
      case 'other': {
        if (task2.category === 'other') {
          return (
            task1.description === task2.description &&
            task1.note === task2.note &&
            task1.timing?.raw === task2.timing?.raw
          );
        }
        break;
      }
    }
  }

  return false;
};

export const stringifyTaskObject = (
  task: ITask,
  withOldValues?: boolean
): string => {
  const items: string[] = [];

  switch (task.category) {
    case 'impression': {
      items.push('Imp');
      items.push(
        task[withOldValues ? 'old_impressions' : 'impressions']
          ?.map((item) => item.title_en)
          .join(', ') ?? ''
      );
      break;
    }

    case 'condition': {
      items.push('Cond');
      items.push(
        task[withOldValues ? 'old_condition' : 'condition']?.title_en ?? ''
      );
      break;
    }

    case 'diet': {
      items.push('Diet');
      items.push(
        task[withOldValues ? 'old_diets' : 'diets']
          ?.map((item) => item.title_en)
          .join(', ') ?? ''
      );
      break;
    }

    case 'activity': {
      items.push('Act');
      items.push(
        task[withOldValues ? 'old_activity' : 'activity']?.title_en ?? ''
      );
      items.push(
        task[withOldValues ? 'old_positions' : 'positions']
          ?.map((item) => item.title_en)
          .join(', ') ?? ''
      );
      break;
    }

    case 'lab': {
      items.push('Check');
      items.push(
        task[withOldValues ? 'old_tests' : 'tests']
          ?.map((item) => item.title_en)
          .join(', ') ?? ''
      );
      if (task[withOldValues ? 'old_timing' : 'timing']?.raw) {
        items.push(task[withOldValues ? 'old_timing' : 'timing']?.raw ?? '');
      }
      break;
    }

    case 'nursing': {
      items.push(
        task[withOldValues ? 'old_cares' : 'cares']
          ?.map((item) => item.title_en)
          .join(', ') ?? ''
      );
      if (task[withOldValues ? 'old_timing' : 'timing']?.raw) {
        items.push(task[withOldValues ? 'old_timing' : 'timing']?.raw ?? '');
      }
      break;
    }

    case 'radiography': {
      items.push(
        task[withOldValues ? 'old_image_type' : 'image_type']?.title_en ?? ''
      );
      items.push(
        task[withOldValues ? 'old_location' : 'location']?.title_en ?? ''
      );
      if (task[withOldValues ? 'old_laterality' : 'laterality']?.id !== '0') {
        items.push(
          task[withOldValues ? 'old_laterality' : 'laterality']?.title_en ?? ''
        );
      }

      const contrastedViews = [];
      for (const item of task[
        withOldValues ? 'old_contrasted_views' : 'contrasted_views'
      ] ?? []) {
        const viewId = item.view.id === '0' ? 0 : item.view.id;
        const contrastId = item.contrast.id === '0' ? 0 : item.contrast.id;
        if (viewId || contrastId) {
          const contrastedView =
            (viewId ? item.view.title_en ?? '' : '') +
            (contrastId && viewId ? ', ' : '') +
            (contrastId ? item.contrast.title_en ?? '' : '');
          contrastedViews.push(contrastedView);
        }
      }
      if (contrastedViews.length) items.push(contrastedViews.join('    '));

      if (task[withOldValues ? 'old_timing' : 'timing']?.raw) {
        items.push(task[withOldValues ? 'old_timing' : 'timing']?.raw ?? '');
      }
      break;
    }

    case 'medication': {
      items.push(task[withOldValues ? 'old_drug' : 'drug']?.title_en ?? '');
      items.push(task[withOldValues ? 'old_form' : 'form']?.title_en ?? '');
      if (task[withOldValues ? 'old_strength' : 'strength']?.id !== '0') {
        items.push(
          task[withOldValues ? 'old_strength' : 'strength']?.title_en ?? ''
        );
      }
      items.push(task[withOldValues ? 'old_amount' : 'amount'] ?? '');
      items.push(task[withOldValues ? 'old_route' : 'route']?.title_en ?? '');
      if (task[withOldValues ? 'old_timing' : 'timing']?.raw) {
        items.push(task[withOldValues ? 'old_timing' : 'timing']?.raw ?? '');
      }
      for (const addedItem of task[withOldValues ? 'old_added' : 'added'] ??
        []) {
        items.push('\n+');
        items.push(addedItem.drug?.title_en ?? '');
        items.push(addedItem.form?.title_en ?? '');
        if (addedItem.strength?.id !== '0') {
          items.push(addedItem.strength?.title_en ?? '');
        }
        items.push(addedItem.amount ?? '');
      }
      break;
    }

    case 'consult': {
      items.push('Consult from');

      const title =
        task[withOldValues ? 'old_hospital_service' : 'hospital_service']
          ?.title_en ?? '';
      items.push(
        `<span ${CheckRtl(title) ? 'class="rtl farsiFont"' : ''}>` +
          title +
          '</span>'
      );

      if (task[withOldValues ? 'old_timing' : 'timing']?.raw) {
        items.push(task[withOldValues ? 'old_timing' : 'timing']?.raw ?? '');
      }
      break;
    }

    case 'other': {
      items.push(task[withOldValues ? 'old_description' : 'description'] ?? '');
      if (task[withOldValues ? 'old_timing' : 'timing']?.raw) {
        items.push(task[withOldValues ? 'old_timing' : 'timing']?.raw ?? '');
      }
      break;
    }
  }

  if (task[withOldValues ? 'old_note' : 'note']) {
    items.push('\n' + task[withOldValues ? 'old_note' : 'note'] ?? '');
  }

  return items.join('    ');
};

export const getFinishedUUIDs = (
  taskNotices: ITaskNoticesMapping
): string[] => {
  const result: string[] = [];

  for (const uuid in taskNotices) {
    if (taskNotices[uuid].all[0].label === 'finished') result.push(uuid);
  }

  return result;
};

export const insertOldTask = (task: ITask, oldTask: ITask): ITask => {
  let newTask = task;

  switch (oldTask.category) {
    case 'impression': {
      if (task.category === 'impression')
        newTask = { ...task, old_impressions: oldTask.impressions };
      break;
    }
    case 'condition': {
      if (task.category === 'condition')
        newTask = { ...task, old_condition: oldTask.condition };
      break;
    }
    case 'diet': {
      if (task.category === 'diet')
        newTask = { ...task, old_diets: oldTask.diets };
      break;
    }
    case 'activity': {
      if (task.category === 'activity')
        newTask = {
          ...task,
          old_activity: oldTask.activity,
          old_positions: oldTask.positions,
        };
      break;
    }
    case 'nursing': {
      if (task.category === 'nursing')
        newTask = {
          ...task,
          old_cares: oldTask.cares,
          old_timing: oldTask.timing,
        };
      break;
    }
    case 'lab': {
      if (task.category === 'lab')
        newTask = {
          ...task,
          old_tests: oldTask.tests,
          old_timing: oldTask.timing,
        };
      break;
    }
    case 'radiography': {
      if (task.category === 'radiography')
        newTask = {
          ...task,
          old_image_type: oldTask.image_type,
          old_location: oldTask.location,
          old_laterality: oldTask.laterality,
          old_timing: oldTask.timing,
          old_contrasted_views: oldTask.contrasted_views,
        };
      break;
    }
    case 'medication': {
      if (task.category === 'medication')
        newTask = {
          ...task,
          old_drug: oldTask.drug,
          old_form: oldTask.form,
          old_strength: oldTask.strength,
          old_amount: oldTask.amount,
          old_route: oldTask.route,
          old_timing: oldTask.timing,
          old_added: oldTask.added,
        };
      break;
    }
    case 'consult': {
      if (task.category === 'consult')
        newTask = {
          ...task,
          old_hospital_service: oldTask.hospital_service,
          old_timing: oldTask.timing,
        };
      break;
    }
    case 'other': {
      if (task.category === 'other')
        newTask = {
          ...task,
          old_description: oldTask.description,
          old_timing: oldTask.timing,
        };
      break;
    }
  }

  return newTask;
};

///////////////
//// Order ////
///////////////

export const isEqualOrder = (order1: IOrder, order2: IOrder): boolean => {
  if (order1.action !== order2.action) return false;
  return isEqualTask(order1.task, order2.task);
};

export const isNewOrder = (cardex: ICardex, order: IOrder): boolean => {
  if (order.action === 'add') return true;
  if (order.action === null) return false;
  const category = getCategory(order.task);
  const categoryValue = cardex[category];

  let group: ICardexGroup;
  if (categoryValue) {
    for (group in categoryValue) {
      for (const item of categoryValue[group]) {
        if (item.task.uuid === order.task.uuid) {
          switch (order.action) {
            case 'dc':
            case 'hold':
              return item.state !== order.action;
            case 'unhold':
              return item.state !== 'active' && item.state !== 'unhold';
            case 'change': {
              return !isEqualTask(order.task, item.task);
            }
          }
        }
      }
    }
  }
  return true;
};

export const addOrdersToCardex = (
  cardex: ICardex,
  orders: IOrder[]
): ICardex => {
  for (const order of orders) {
    if (order.action) {
      if (order.action === 'add') {
        cardex = addNewCardexItem(
          cardex,
          { task: order.task, state: 'active' },
          'new'
        );
      } else {
        cardex = updateCardexItem({
          cardex,
          task: order.task,
          action: order.action,
          newGroup: 'new',
        });
      }
    }
  }
  return sortCardex(cardex);
};

////////////////
//// Cardex ////
////////////////

const mepGeneralItems: Partial<Record<ITaskCategory, number>> = {
  impression: 1,
  condition: 2,
  diet: 3,
  activity: 4,
};

const defaultCardex: ICardex = {
  general: {
    new: [],
    checked: [
      {
        task: { category: 'impression', impressions: [], note: '' },
        state: 'active',
      },
      {
        task: { category: 'condition', condition: {}, note: '' },
        state: 'active',
      },
      {
        task: { category: 'diet', diets: [], note: '' },
        state: 'active',
      },
      {
        task: { category: 'activity', activity: {}, positions: [], note: '' },
        state: 'active',
      },
    ],
    unchecked: [],
  },
};

export const getCategory = (task: ITask): ICategory => {
  switch (task.category) {
    case 'impression':
    case 'condition':
    case 'diet':
    case 'activity': {
      return 'general';
    }
    default: {
      return task.category;
    }
  }
};

function sortCardexGroup(groupItems: ICardexItem[]): ICardexItem[] {
  if (groupItems.length === 0) return groupItems;

  const isGeneralCategory = getCategory(groupItems[0].task) === 'general';
  if (isGeneralCategory) {
    const sortedGroup = groupItems.sort((a, b) => {
      return (
        (mepGeneralItems[a.task.category] || 0) -
        (mepGeneralItems[b.task.category] || 0)
      );
    });
    return sortedGroup;
  } else {
    let dc: ICardexItem[] = [];
    let hold: ICardexItem[] = [];
    let active: ICardexItem[] = [];
    for (const item of groupItems) {
      switch (item.state) {
        case 'dc':
          dc = [...dc, item];
          break;
        case 'hold':
          hold = [...hold, item];
          break;
        default:
          active = [...active, item];
      }
    }
    return [...active, ...hold, ...dc];
  }
}

function sortCardex(cardex: ICardex): ICardex {
  for (const group of Object.values(cardex)) {
    group.checked = sortCardexGroup(group.checked);
    group.unchecked = sortCardexGroup(group.unchecked);
    group.new = sortCardexGroup(group.new);
  }
  return cardex;
}

function addNewCardexItem(
  currentCardex: ICardex,
  item: ICardexItem,
  group: ICardexGroup
): ICardex {
  const category = getCategory(item.task);
  const cardex = { ...currentCardex };
  let categoryGroups = currentCardex[category];

  // Prevent from duplicate new general task
  // This Part just use in first visit because in other visits generals just can edit
  // and we not allow to add new item (items are: impressions, condition, diets, activity) to them
  if (category === 'general') {
    categoryGroups = removeCardexItem(cardex, item.task)['general'];

    // const filteredNewGeneralItems = categoryGroups.new.filter(
    //   (groupItem) => groupItem.task.category !== item.task.category
    // );
    // categoryGroups = { ...categoryGroups, new: [...filteredNewGeneralItems] };
  }

  if (categoryGroups === undefined) {
    categoryGroups = { new: [], unchecked: [], checked: [] };
  }

  categoryGroups = {
    ...categoryGroups,
    [group]: [...categoryGroups[group], item],
  };

  return { ...cardex, [category]: categoryGroups };
}

export const updateCardexItem = ({
  cardex,
  task,
  action,
  newGroup,
  newUUID,
}: {
  cardex: ICardex;
  task: ITask;
  action: 'dc' | 'hold' | 'unhold' | 'change';
  newGroup?: ICardexGroup;
  newUUID?: string;
}): ICardex => {
  const category = getCategory(task);
  const categoryGroups = cardex[category];
  let newCategoryGroups: Record<ICardexGroup, ICardexItem[]> = {
    unchecked: [],
    checked: [],
    new: [],
  };

  if (categoryGroups) {
    let group: ICardexGroup;
    for (group in categoryGroups) {
      for (const item of categoryGroups[group]) {
        if (
          category === 'general'
            ? item.task.category === task.category
            : item.task.uuid === task.uuid
        ) {
          let newItem: ICardexItem;
          if (action === 'change') {
            const newTask = insertOldTask(task, item.task);

            newItem = {
              ...item,
              state: 'changed',
              task: {
                ...newTask,
                uuid: newUUID ?? newTask.uuid,
                old_note: item.task.note,
              },
            };
          } else {
            newItem = { ...item, state: action };
          }

          newCategoryGroups = {
            ...newCategoryGroups,
            [newGroup ?? group]: [
              ...newCategoryGroups[newGroup ?? group],
              newItem,
            ],
          };
        } else {
          newCategoryGroups = {
            ...newCategoryGroups,
            [group]: [...newCategoryGroups[group], item],
          };
        }
      }
    }
    return { ...cardex, [category]: newCategoryGroups };
  }
  return cardex;
};

export const updateCardexItemLabel = ({
  cardex,
  task,
  label,
}: {
  cardex: ICardex;
  task: ITask;
  label: ITaskNoticeLabel;
}): ICardex => {
  const category = getCategory(task);
  const categoryGroups = cardex[category];
  let newCategoryGroups: Record<ICardexGroup, ICardexItem[]> = {
    unchecked: [],
    checked: [],
    new: [],
  };

  if (categoryGroups) {
    let group: ICardexGroup;
    for (group in categoryGroups) {
      for (const item of categoryGroups[group]) {
        if (
          category === 'general'
            ? item.task.category === task.category
            : item.task.uuid === task.uuid
        ) {
          newCategoryGroups = {
            ...newCategoryGroups,
            [group]: [...newCategoryGroups[group], { ...item, label }],
          };
        } else {
          newCategoryGroups = {
            ...newCategoryGroups,
            [group]: [...newCategoryGroups[group], item],
          };
        }
      }
    }
    return { ...cardex, [category]: newCategoryGroups };
  }
  return cardex;
};

export const removeCardexItem = (cardex: ICardex, task: ITask): ICardex => {
  const category = getCategory(task);
  const categoryGroups = cardex[category];
  let newCategoryGroups: Record<ICardexGroup, ICardexItem[]> = {
    unchecked: [],
    checked: [],
    new: [],
  };

  if (categoryGroups) {
    let group: ICardexGroup;
    for (group in categoryGroups) {
      for (const item of categoryGroups[group]) {
        if (
          category === 'general'
            ? item.task.category === task.category
            : item.task.uuid === task.uuid
        )
          continue;

        newCategoryGroups = {
          ...newCategoryGroups,
          [group]: [...newCategoryGroups[group], item],
        };
      }
    }
    return { ...cardex, [category]: newCategoryGroups };
  }
  return cardex;
};

export const addTaskExtensions = (
  prevVisit?: IVisit | null,
  taskExtensions?: ITaskExtensionsMapping
): IVisit | null | undefined => {
  if (!prevVisit || !taskExtensions) return prevVisit;

  const visit: IVisit = {
    ...prevVisit,
    orders: prevVisit.orders.map((order) => {
      const uuid = order.task.uuid;
      if (uuid && taskExtensions[uuid]) {
        return {
          ...order,
          task: {
            ...order.task,
            extensions: taskExtensions[uuid],
          },
        };
      }
      return order;
    }),
    cardex: {
      ...prevVisit.cardex.items,
      items: prevVisit.cardex.items.map((item) => {
        const uuid = item.task.uuid;
        if (uuid && taskExtensions[uuid]) {
          return {
            ...item,
            task: {
              ...item.task,
              extensions: taskExtensions[uuid],
            },
          };
        }
        return item;
      }),
    },
  };

  return visit;
};

export const getConflicts = (
  cardexItems: ICardexItem[],
  orders: IOrder[]
): IConflictItem[] => {
  const conflicts: IConflictItem[] = [];

  for (const order of orders) {
    if (order.action) {
      const cardexItem = cardexItems.find(
        (item) => item.task.uuid === order.task.uuid
      );

      if (order.action === 'add' && cardexItem) {
        conflicts.push({
          order,
          state: 1,
        });
        continue;
      }

      const isItemDisconnected = !!cardexItem && cardexItem.state === 'dc';
      const isItemExtincted = !!cardexItem && cardexItem.state === 'extinct';
      const isItemEdited =
        isItemExtincted &&
        !!cardexItems.find((item) => order.task.uuid === item.old_task);

      if (order.action !== 'add') {
        if (cardexItem && !isItemDisconnected) {
          if (cardexItem.state === 'hold' && order.action === 'hold') {
            conflicts.push({
              order,
              state: 3,
            });
          }

          if (cardexItem.state === 'active' && order.action === 'unhold') {
            conflicts.push({
              order,
              state: 4,
            });
          }
        } else {
          if (
            isItemEdited &&
            (order.action === 'hold' || order.action === 'dc')
          ) {
            conflicts.push({
              order,
              state: 5,
            });
            continue;
          }
          conflicts.push({
            order,
            state: 2,
          });
        }
      }
    }
  }
  return conflicts;
};

export const initialCardexStructure = ({
  visit = null,
  lastCheckedVisit = null,
  headVisit,
  finishedUUIDs = [],
  skipStates = [],
}: {
  visit?: IVisit | null;
  lastCheckedVisit?: IVisit | null;
  headVisit?: IVisit | null;
  finishedUUIDs?: string[];
  skipStates?: ICardexItemState[];
}): ICardex => {
  let cardex: ICardex = { ...defaultCardex };
  let headItems = headVisit?.cardex.items ?? [];

  function isInCheckedGroup(item: ICardexItem) {
    if (lastCheckedVisit && item.restated) {
      const lastCheckedVisitCreated = new Date(lastCheckedVisit.created);
      const itemReStated = new Date(item.restated);
      return itemReStated <= lastCheckedVisitCreated;
    }
    return visit?.checked ? 'checked' : 'unchecked';
  }

  if (visit) {
    for (const item of visit.cardex.items) {
      if (finishedUUIDs.includes(item.task.uuid ?? '')) continue;
      if (skipStates.includes(item.state)) continue;

      const group: ICardexGroup = isInCheckedGroup(item)
        ? 'checked'
        : 'unchecked';

      if (headVisit && headVisit.id !== visit.id) {
        // This Part just use for compare a visit with head
        let isDeletedInHead = false;
        headItems = headItems.filter((headItem) => {
          if (finishedUUIDs.includes(headItem.task.uuid ?? '')) return false;

          if (headItem.task.uuid === item.task.uuid) {
            if (['extinct', 'dc'].includes(headItem.state)) {
              isDeletedInHead = true;
            }
            return false;
          }
          return true;
        });
        if (!isDeletedInHead) {
          cardex = addNewCardexItem(cardex, item, group);
        }
      } else {
        cardex = addNewCardexItem(cardex, item, group);
      }
    }
  }

  // This Part just use for compare a visit with head
  if (headVisit && headVisit.id !== visit?.id && headItems.length) {
    const group: ICardexGroup = headVisit.checked ? 'checked' : 'unchecked';
    for (const item of headItems) {
      if (['extinct', 'dc'].includes(item.state)) continue;
      cardex = addNewCardexItem(cardex, { ...item, isNew: true }, group);
    }
  }

  return cardex;
};

export const reformatCardexWithChanges = ({
  visit,
  parentVisit,
  cardexAppendix,
}: {
  visit: IVisit;
  parentVisit?: IVisit | null;
  cardexAppendix?: ICardexAppendix | null;
}): ICardex => {
  let cardex = initialCardexStructure({
    visit: addTaskExtensions(parentVisit, cardexAppendix?.task_extensions),
    skipStates: ['dc', 'extinct'],
  });

  cardex = addOrdersToCardex(
    cardex,
    addTaskExtensions(visit, cardexAppendix?.task_extensions)?.orders ?? []
  );

  return sortCardex(cardex);
};

export const reformatCardex = ({
  visit,
  parentVisit = null,
  lastCheckedVisit = null,
  cardexAppendix,
}: {
  visit: IVisit;
  parentVisit?: IVisit | null;
  lastCheckedVisit?: IVisit | null;
  cardexAppendix?: ICardexAppendix | null;
  initialCardex?: ICardex | null;
}): ICardex => {
  const finishedUUIDs = getFinishedUUIDs(cardexAppendix?.task_notices ?? {});
  let cardex = initialCardexStructure({
    visit: addTaskExtensions(parentVisit, cardexAppendix?.task_extensions),
    lastCheckedVisit,
    finishedUUIDs,
    skipStates: ['extinct'],
  });

  const group: ICardexGroup = visit.checked ? 'checked' : 'unchecked';

  for (const order of addTaskExtensions(visit, cardexAppendix?.task_extensions)
    ?.orders || []) {
    if (finishedUUIDs.includes(order.task.uuid ?? '')) continue;

    switch (order.action) {
      case 'add': {
        cardex = addNewCardexItem(
          cardex,
          { task: order.task, state: 'active' },
          group
        );
        break;
      }
      case 'dc':
      case 'hold':
      case 'unhold': {
        cardex = updateCardexItem({
          cardex,
          task: order.task,
          action: order.action,
          newGroup: group,
        });
        break;
      }
      case 'change': {
        const newUUID = visit.cardex.items.find(
          (item) => item.old_task === order.task.uuid
        )?.task.uuid;

        if (finishedUUIDs.includes(newUUID ?? '')) {
          cardex = removeCardexItem(cardex, order.task);
        } else {
          cardex = updateCardexItem({
            cardex,
            task: order.task,
            action: order.action,
            newGroup: group,
            newUUID,
          });
        }
        break;
      }
    }
  }

  return sortCardex(cardex);
};

export const getPrintCardex = (visit: IVisit | null): ICardex => {
  let cardex: ICardex = {};

  for (const order of visit?.orders ?? []) {
    let item: ICardexItem | undefined;
    switch (order.action) {
      case 'add': {
        item = { task: order.task, state: 'active' };
        break;
      }
      case 'change': {
        const previousItem = visit?.cardex.items.find(
          (item) =>
            item.state === 'extinct' && item.task.uuid === order.task.uuid
        );

        if (previousItem) {
          item = {
            task: insertOldTask(order.task, previousItem.task),
            state: 'changed',
          };
        }
        break;
      }
      case 'dc':
      case 'hold':
      case 'unhold': {
        item = { task: order.task, state: order.action };
        break;
      }
    }
    if (item) cardex = addNewCardexItem(cardex, item, 'new');
  }

  return cardex;
};

///////////////////
///// History /////
///////////////////

export const getLastModifiedHistoryItem = (item: IHistoryItem): string => {
  return (item.type === 'finished' ? item.notified : item.changed) ?? '';
};

export const sortCardexHistory = (items: IHistoryItem[]): IHistoryItem[] => {
  return items.sort(
    (a, b) =>
      Date.parse(getLastModifiedHistoryItem(a)) -
      Date.parse(getLastModifiedHistoryItem(b))
  );
};

export const getCardexHistoryItems = (
  headVisit: IVisit | null,
  taskNotices: ITaskNoticesMapping | null
): IHistoryItem[] => {
  const items: IHistoryItem[] = [];
  const finishedUUIDs = getFinishedUUIDs(taskNotices ?? {});

  for (const cardexItem of headVisit?.cardex.items ?? []) {
    const uuid = cardexItem.task.uuid;

    if (uuid) {
      if (taskNotices && finishedUUIDs.includes(uuid)) {
        items.push({
          task: cardexItem.task,
          type: 'finished',
          state: cardexItem.state,
          notified: taskNotices[uuid].all[0].notified,
          notifier: taskNotices[uuid].all[0].notifier,
        });
      }

      if (cardexItem.state === 'extinct') {
        const newTask = headVisit?.cardex.items.find(
          (item) => item.old_task === cardexItem.task.uuid
        );

        items.push({
          task: cardexItem.task,
          type: 'extinct',
          state: newTask ? 'changed' : cardexItem.state,
          changed: newTask?.task.created,
          changer: newTask?.task.submitter,
        });
      }
    }
  }

  return sortCardexHistory(items);
};
