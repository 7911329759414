import VisitTemplateCreate from '../Components/PatientOrder/Template/Visit/Create';
import VisitTemplatesList from '../Components/PatientOrder/Template/Visit/List';
import VisitTemplateView from '../Components/PatientOrder/Template/Visit/View';

const taskTemplateRoutes = [
  {
    key: 'task_template_list',
    path: [
      '/patient/:patientId/order/create-visit/templates',
      '/patient/:patientId/order/visit/:visitId/templates',
    ],
    component: VisitTemplatesList,
    absolutePath: true,
  },
  {
    key: 'task_template_view',
    path: [
      '/patient/:patientId/order/create-visit/template/:templateId',
      '/patient/:patientId/order/visit/:visitId/template/:templateId',
    ],
    component: VisitTemplateView,
    absolutePath: true,
  },
  {
    key: 'task_template_create',
    path: [
      '/patient/:patientId/order/create-visit/create-template',
      '/patient/:patientId/order/visit/:visitId/create-template',
    ],
    component: VisitTemplateCreate,
    absolutePath: true,
  },
  {
    key: 'task_template_edit',
    path: [
      '/patient/:patientId/order/create-visit/template/:templateId/edit',
      '/patient/:patientId/order/visit/:visitId/template/:templateId/edit',
    ],
    component: VisitTemplateCreate,
    absolutePath: true,
  },
];

export default taskTemplateRoutes;
