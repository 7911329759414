import React from 'react';
import { IPrintItem } from '..';
import { Box } from '@material-ui/core';
import PhysicianOrdersPrintHeader from './Header';
import PhysicianOrdersPrintContent from './Content';

interface IProps {
  items: IPrintItem[];
  signatures: { [id: string]: string };
}

const PhysicianOrdersComponentToPrint = React.forwardRef<
  HTMLTableElement,
  IProps
>(({ items, signatures }, ref) => {
  return (
    <table className='phop-table' ref={ref}>
      <thead className='phop-table_header'>
        <tr>
          <td>
            <PhysicianOrdersPrintHeader />
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {items
              .slice(0)
              .reverse()
              .map((item) => (
                <Box
                  key={item.visit?.id}
                  className='phop-table_body-content-item'
                >
                  <PhysicianOrdersPrintContent
                    item={item}
                    signatures={signatures}
                  />
                </Box>
              ))}
          </td>
        </tr>
      </tbody>
    </table>
  );
});

PhysicianOrdersComponentToPrint.displayName = 'PhysicianOrdersComponentToPrint';

export default PhysicianOrdersComponentToPrint;
