import { CharStreams, CommonTokenStream } from 'antlr4ts';
import * as c3 from 'antlr4-c3';
import TimingErrorAggregator from './timing-error-aggregator';
import { timingLexer } from '../../../../gen/timingLexer';
import { timingParser } from '../../../../gen/timingParser';

const getTokenPosition = (input: string, caretPosition: number): number => {
  const inputStream = CharStreams.fromString(input);
  const lexer = new timingLexer(inputStream);

  let tokenCaretPosition = 0;
  const tokens = lexer.getAllTokens();

  for (let index = 0; index < tokens.length; index++) {
    const token = tokens[index];
    if (caretPosition >= token.startIndex && caretPosition <= token.stopIndex) {
      return tokenCaretPosition;
    }

    tokenCaretPosition = index;
  }

  return tokenCaretPosition;
};

const getSuggestions = ({
  input,
  caretPosition = 0,
}: {
  input: string,
  caretPosition?: number,
}) => {
  const errorAggregator = new TimingErrorAggregator();
  const tokenCaretPosition = getTokenPosition(input, caretPosition);

  const inputStream = CharStreams.fromString(input);
  const lexer = new timingLexer(inputStream);

  const tokenStream = new CommonTokenStream(lexer);

  const parser = new timingParser(tokenStream);

  lexer.removeErrorListeners();
  lexer.addErrorListener(errorAggregator);

  parser.removeErrorListeners();
  parser.addErrorListener(errorAggregator);

  parser.main();

  const core = new c3.CodeCompletionCore(parser);

  core.preferredRules = new Set([]);

  core.ignoredTokens = new Set([
    timingLexer.NUM,
    timingLexer.WS,
    timingLexer.EOF,
    timingLexer.QNUNIT,
  ]);

  const candidates = core.collectCandidates(tokenCaretPosition);

  const keywords: string[] = [];
  candidates.tokens.forEach((token, k) => {
    // eslint-disable-next-line quotes
    const suggestion = parser.vocabulary.getDisplayName(k).split("'").join('');

    const notRecommandTokens = ['bid', 'twice', 'tid', 'thrice'];
    if (!notRecommandTokens.includes(suggestion)) {
      keywords.push(suggestion);
    }
  });

  return {
    errors: errorAggregator.getErrors(),
    candidates: {
      keywords,
      rules: candidates.rules,
    },
  };
};

export default getSuggestions;
