import React, { useMemo } from 'react';
import { makeStyles, Theme, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { CheckRtl, renderAutoDirText } from '../../../Utilities/CheckLang';
import { jalaliFullDate, getTime } from '../../../Utilities/jalaliDate';
import { getUserFullName } from '../../../Utilities/user';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    '& > p': {
      color: '#5d5f66',
      ...theme.typography.body2,

      '&.farsiFont': {
        lineHeight: '21px',
        fontWeight: 500,
      },
    },
  },
}));

interface IProps {
  label: string;
  user?: { first_name: string | null; last_name: string | null };
  datetime?: string | null;
}

const CardexState: React.FC<IProps> = ({ label, user, datetime = '' }) => {
  const classes = useStyles();

  const fullName = useMemo(() => (user ? getUserFullName(user) : ''), [user]);
  return (
    <Box className={classes.root}>
      <Typography>{label}&nbsp;</Typography>
      <Typography>{renderAutoDirText(fullName)}</Typography>
      {datetime ? (
        <Typography>
          &nbsp;|&nbsp;{jalaliFullDate(datetime)}&nbsp;
          {getTime(datetime)}
        </Typography>
      ) : null}
    </Box>
  );
};

export default CardexState;
