import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { IPatient } from '../../../types/patient';
import { useHistory, useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Hidden,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import PageHeader from '../../PageHeader/PageHeader';
import PageWrapper from '../../PageWrapper';
import clsx from 'clsx';
import RTL from '../../RTL';
import { useQuery } from 'react-query';
import { getAllServices, getAllWards } from '../../../api/generalAPI';
import CustomAutocomplete from '../../shared/Inputs/Autocomplete';
import { getList } from '../../../api/list';
import DatePicker from '../../shared/Inputs/DatePicker';
import {
  createPatient,
  getPatient,
  getPatientHint,
  updatePatient,
} from '../../../api/patient';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../action/generalActions';
import pickKeys from '../../../Utilities/pickKeys';
import {
  getAllWardsPatients,
  updatePatientProfile,
} from '../../../action/actions';
import ProfileCard from './ProfileCard';
import debounce from 'lodash.debounce';
import useAccess from '../../../Hook/useAccess';

const defaultValues: IPatient = {
  first_name: '',
  last_name: '',
  profile_number: '',
  admission_id: '',
  national_id: '',
  ward: { id: 0, name: 'بدون بخش' },
  primary_service: { id: 0, name: 'بدون سرویس' },
  has_national_id: true,
  gender: 'M',
  marital_status: 'S',
};

const validationSchema = yup.object().shape({
  first_name: yup.string().required('این فیلد الزامی است!'),
  last_name: yup.string().required('این فیلد الزامی است!'),
  has_national_id: yup.bool(),
  national_id: yup
    .number()
    .required('این فیلد الزامی است!')
    .typeError('لطفا یک مقدار عددی وارد کنید!')
    .when('has_national_id', {
      is: true,
      then: (schema) =>
        schema.test(
          'national_id_len',
          'کد ملی باید 10 رقمی باشد',
          (val) => String(val).length === 10
        ),
      otherwise: (schema) =>
        schema.test(
          'not_national_id_len',
          'کد اتباع حداقل 4 رقمی است',
          (val) => String(val).length >= 4
        ),
    }),
});

const useStyles = makeStyles((theme: Theme) => ({
  inputLabel: {
    display: 'flex',
    lineHeight: '22px',
    marginBottom: 9,

    '&.error': {
      color: theme.palette.error.main,
    },
  },
  formGroup: {
    flexDirection: 'row',
    width: '100%',
  },
  formControlLabel: {
    margin: '0 0 10px 0',
    flex: 1,
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',

    '& > *': {
      fontFamily: 'IRANYekan',
      color: theme.palette.text.secondary,
      whiteSpace: 'nowrap',
    },
  },
  radio: {
    padding: '0 2px 0 0',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  button: {
    fontFamily: 'Poppins',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    padding: '10px 39px 9px',
    marginTop: 31,
    height: 'fit-content',

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

interface IRadioButtonOption {
  label: string;
  value: string;
}

const HAS_NATIONAL_ID: IRadioButtonOption[] = [
  { label: 'کد ملی', value: 'true' },
  { label: 'کد اتباع', value: 'false' },
];

const GENDERS: IRadioButtonOption[] = [
  { label: 'مرد', value: 'M' },
  { label: 'زن', value: 'F' },
  { label: 'دیگر', value: 'O' },
];

const MARITAL_STATUSES: IRadioButtonOption[] = [
  { label: 'مجرد', value: 'S' },
  { label: 'متاهل', value: 'M' },
  { label: 'مطلقه', value: 'D' },
  { label: 'فوت همسر', value: 'I' },
];

interface ISearchQuery {
  name?: string;
  national_id?: string;
}

const PatientCreate: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    data: [standaloneMode, canManagePatients],
    isLoading: isLoadingAccess,
  } = useAccess(['standalone_mode', 'can_manage_patients']);
  const { patientId } = useParams<{ patientId?: string }>();
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    register,
    control,
    handleSubmit,
    reset,

    trigger,
    formState: { errors, isSubmitting, isSubmitted },
  } = useForm<IPatient>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const [national_id, first_name, last_name] = useWatch({
    control,
    name: ['national_id', 'first_name', 'last_name'],
  });
  const [searchQuery, setSearchQuery] = useState<ISearchQuery>({});

  if ((!standaloneMode || !canManagePatients) && !isLoadingAccess) {
    history.replace('/patient');
  }

  const { isFetching } = useQuery(
    ['patient', patientId],
    () => getPatient(patientId ?? ''),
    {
      enabled: !!patientId,
      onSuccess: (res) => {
        reset(res.data);
      },
    }
  );
  const { data: wards } = useQuery('wards', getAllWards);
  const { data: services } = useQuery('services', getAllServices);
  const { data: cities } = useQuery('cities', () => getList('city'));
  const { data: nationalities } = useQuery('nationalities', () =>
    getList('nationality')
  );
  const {
    data: similarPatientsRes,
    isLoading: isLoadingPatients,
    refetch: refetchSimilarPatients,
  } = useQuery(
    ['patients', { ...searchQuery }],
    () => getPatientHint(0, 100, searchQuery),
    { enabled: !!Object.keys(searchQuery).length }
  );

  const isEditMode = !!patientId;

  const triggerSearch = useMemo(
    () =>
      debounce(
        (first_name?: string, last_name?: string, national_id?: string) => {
          const newSearchQuery: ISearchQuery = {};

          let name = '';
          if (first_name) name = first_name;
          if (last_name) name += name ? ' ' + last_name : last_name;

          if (name) newSearchQuery['name'] = name;
          if (national_id) newSearchQuery['national_id'] = national_id;

          setSearchQuery({ ...newSearchQuery });
        },
        500
      ),
    []
  );

  useEffect(() => {
    if (!isEditMode) {
      triggerSearch(first_name, last_name, national_id);

      return () => triggerSearch.cancel();
    }
  }, [first_name, last_name, national_id]);

  const handleSearchButtonClick = async () => {
    if (national_id || first_name || last_name) {
      refetchSimilarPatients();
    } else {
      dispatch(
        addMessage(
          'لطفا ابتدا نام و نام‌خانوادگی یا کد ملی کاربر را وارد کنید',
          300
        )
      );
    }
  };

  const getSubmissionData = (values: IPatient) => {
    const modifiedValues = {
      ...values,
      street: values.street?.trim(),
      dob: values.dob?.replaceAll('/', '-') || null,
      ward_id: values.ward?.id || null,
      primary_service_id: values.primary_service?.id || null,
      nationality: values.nationality?.id,
      birth_location: values.birth_location?.id,
      city: values.city?.id,
      profile_number: values.profile_number || null,
      admission_id: values.admission_id || null,
    };

    const requestKeys = [
      'profile_number',
      'admission_id',
      'dismissed',
      'dismission_datetime',
      'first_name',
      'last_name',
      'father_name',
      'gender',
      'dob',
      'room',
      'bed',
      'national_id',
      'has_national_id',
      'marital_status',
      'street',
      'phone_number_1',
      'phone_number_2',
      'admission_timestamp',
      'ward_id',
      'primary_service_id',
      'nationality',
      'birth_location',
      'city',
      'height',
      'weight',
      'note',
    ];

    return pickKeys(modifiedValues, requestKeys);
  };

  const handleFormSubmit = async (data: IPatient) => {
    const modifiedData = await getSubmissionData(data);

    if (patientId) {
      await updatePatient(patientId, modifiedData)
        .then((res) => {
          dispatch(addMessage('تغییرات با موفقیت ثبت شد', 200));
          dispatch(updatePatientProfile(patientId, res.data));
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
          dispatch(addMessage('ثبت تغییرات با خطا مواجه شد', 500));
        });
    } else {
      await createPatient(modifiedData)
        .then(() => {
          dispatch(addMessage('بیمار با موفقیت اضافه شد', 200));
          dispatch(getAllWardsPatients('rotation', '', 0));
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
          dispatch(addMessage('افزودن بیمار با خطا مواجه شد', 500));
        });
    }
  };

  const renderInput = ({
    name,
    label,
    multiline,
    required,
  }: {
    name: keyof IPatient;
    label?: string;
    multiline?: boolean;
    required?: boolean;
  }) => {
    return (
      <Box textAlign='left'>
        {!!label && (
          <InputLabel
            className={clsx(
              classes.inputLabel,
              errors[name]?.message && 'error'
            )}
          >
            {label}
            {required && <Typography color='error'>&nbsp;*</Typography>}
          </InputLabel>
        )}
        <TextField
          fullWidth
          multiline={multiline}
          variant='outlined'
          InputLabelProps={{ shrink: true }}
          {...register(name)}
          error={!!errors[name]?.message}
          helperText={errors[name]?.message}
        />
      </Box>
    );
  };

  const renderAutocompleteInput = ({
    name,
    options = [],
    label,
    getOptionLabel = (o) => o?.name,
    required,
  }: {
    name: keyof IPatient;
    options?: any[];
    label?: string;
    getOptionLabel?: (o: any) => string;
    required?: boolean;
  }) => {
    return (
      <Box textAlign='left'>
        {!!label && (
          <InputLabel
            className={clsx(
              classes.inputLabel,
              errors[name]?.message && 'error'
            )}
          >
            {label}
            {required && <Typography color='error'>&nbsp;*</Typography>}
          </InputLabel>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange, ref } }) => (
            <CustomAutocomplete
              value={value}
              options={options}
              getOptionLabel={getOptionLabel}
              onChange={(_, newValue) => onChange(newValue)}
              defaultDirection='rtl'
              textFieldProps={{
                fullWidth: true,
                variant: 'outlined',
                error: !!errors[name]?.message,
                helperText: errors[name]?.message,
                inputRef: ref,
              }}
            />
          )}
        />
      </Box>
    );
  };

  const renderNationalID = () => {
    return (
      <Grid item xs={6} md={3}>
        <Controller
          control={control}
          name={'has_national_id'}
          render={({ field: { value, name, onChange } }) => (
            <Box display='flex'>
              <Typography color='error'>*&nbsp;</Typography>
              <RadioGroup
                name={name}
                value={String(value)}
                onChange={async (e, val) => {
                  await onChange(e, val);
                  if (isSubmitted) trigger('national_id');
                }}
                className={classes.formGroup}
              >
                {HAS_NATIONAL_ID.map((type, index) => (
                  <FormControlLabel
                    key={index}
                    label={type.label}
                    value={type.value}
                    control={
                      <Radio
                        size='small'
                        color='primary'
                        className={classes.radio}
                      />
                    }
                    className={classes.formControlLabel}
                  />
                ))}
              </RadioGroup>
            </Box>
          )}
        />

        {renderInput({ name: 'national_id' })}
      </Grid>
    );
  };

  const renderDateOfBirth = () => {
    return (
      <Box textAlign='left'>
        <InputLabel className={classes.inputLabel}>تاریخ تولد</InputLabel>
        <Controller
          name='dob'
          control={control}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              width='100%'
              value={value ?? ''}
              setValue={(val) => onChange(val)}
            />
          )}
        />
      </Box>
    );
  };

  const renderRadioButtonGroup = (
    label: string,
    options: IRadioButtonOption[],
    name: keyof IPatient
  ) => {
    return (
      <Box textAlign='left'>
        <InputLabel className={classes.inputLabel}>{label}</InputLabel>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, name, onChange } }) => (
            <RadioGroup
              name={name}
              value={value}
              onChange={onChange}
              className='button-group'
            >
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  label={option.label}
                  value={option.value}
                  control={<Radio style={{ display: 'none' }} />}
                  className={clsx(value === option.value && 'active')}
                />
              ))}
            </RadioGroup>
          )}
        />
      </Box>
    );
  };

  const fullNameField = (
    <>
      <Grid item xs={6} md={3}>
        {renderInput({ name: 'first_name', label: 'نام', required: true })}
      </Grid>
      <Grid item xs={6} md={3}>
        {renderInput({
          name: 'last_name',
          label: 'نام خانوادگی',
          required: true,
        })}
      </Grid>
    </>
  );

  const renderSearchButton = () => {
    return (
      <Fragment>
        <Grid item xs={6} md={3} className={classes.buttonWrapper}>
          <Button
            variant='contained'
            disabled={!!isLoadingPatients}
            className={classes.button}
            onClick={handleSearchButtonClick}
          >
            {isLoadingPatients ? <CircularProgress size={21} /> : 'Search'}
          </Button>
        </Grid>

        {(!!similarPatientsRes?.data.patients.length || isLoadingPatients) && (
          <Grid item xs={12}>
            {isLoadingPatients ? (
              <Box className='flex-center'>
                <CircularProgress />
              </Box>
            ) : (
              similarPatientsRes?.data.patients.map((patient) => (
                <ProfileCard key={patient.admission_id} patient={patient} />
              ))
            )}
          </Grid>
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <PageHeader
        desktopBackButton
        showPatientInfo={false}
        title={isEditMode ? 'Patient Information' : 'New Patient'}
      />
      <PageWrapper loading={isFetching || isLoadingAccess}>
        <RTL>
          <Box p={3}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Grid container spacing={2}>
                {(isMobile || isEditMode) && fullNameField}

                {isEditMode && (
                  <Hidden smDown>
                    <Grid item md={6} />
                  </Hidden>
                )}

                {renderNationalID()}

                {!(isMobile || isEditMode) && fullNameField}

                {!isEditMode && renderSearchButton()}

                <Grid item xs={6} md={3}>
                  {renderAutocompleteInput({
                    label: 'سرویس',
                    name: 'primary_service',
                    options: services?.data?.services,
                  })}
                </Grid>

                {isEditMode && (
                  <Hidden smDown>
                    <Grid item md={6} />
                  </Hidden>
                )}

                <Grid item xs={6} md={3}>
                  {renderAutocompleteInput({
                    label: 'بخش',
                    name: 'ward',
                    options: wards?.data?.wards,
                  })}
                </Grid>

                {!isEditMode && (
                  <Hidden smDown>
                    <Grid item md={6} />
                  </Hidden>
                )}

                <Grid item xs={6} md={3}>
                  {renderInput({
                    name: 'admission_id',
                    label: 'شماره پذیرش',
                  })}
                </Grid>

                {isEditMode && (
                  <Hidden smDown>
                    <Grid item md={6} />
                  </Hidden>
                )}

                <Grid item xs={6} md={3}>
                  {renderInput({
                    name: 'profile_number',
                    label: 'کد پرونده',
                  })}
                </Grid>

                {!isEditMode && (
                  <Hidden smDown>
                    <Grid item md={6} />
                  </Hidden>
                )}

                <Grid item xs={6} md={3}>
                  {renderInput({ name: 'bed', label: 'تخت' })}
                </Grid>

                {isEditMode ? (
                  <Hidden smDown>
                    <Grid item md={6} />
                  </Hidden>
                ) : (
                  <Grid item xs={6} md={9} />
                )}

                <Grid item xs={6} md={3}>
                  {renderDateOfBirth()}
                </Grid>
                <Grid item xs={6} md={3}>
                  {renderInput({ name: 'father_name', label: 'نام پدر' })}
                </Grid>

                <Hidden smDown>
                  <Grid item md={6} />
                </Hidden>

                <Grid item xs={6} md={3}>
                  {renderRadioButtonGroup('جنسیت', GENDERS, 'gender')}
                </Grid>
                {isEditMode && (
                  <Hidden smDown>
                    <Grid item md={6} />
                  </Hidden>
                )}
                <Grid item xs={12} md={6}>
                  {renderRadioButtonGroup(
                    'وضعیت تاهل',
                    MARITAL_STATUSES,
                    'marital_status'
                  )}
                </Grid>
                <Hidden smDown>
                  <Grid item md={isEditMode ? 6 : 3} />
                </Hidden>

                <Grid item xs={6} md={3}>
                  {renderAutocompleteInput({
                    label: 'ملیت',
                    name: 'nationality',
                    options: nationalities?.data.items,
                    getOptionLabel: (o) => o?.title,
                  })}
                </Grid>
                <Grid item xs={6} md={3}>
                  {renderAutocompleteInput({
                    label: 'محل تولد',
                    name: 'birth_location',
                    options: cities?.data.items,
                    getOptionLabel: (o) => o?.title,
                  })}
                </Grid>
                <Hidden smDown>
                  <Grid item md={6} />
                </Hidden>

                <Grid item xs={6} md={3}>
                  {renderInput({ name: 'phone_number_1', label: 'شماره تماس' })}
                </Grid>
                <Grid item xs={6} md={3}>
                  {renderInput({
                    name: 'phone_number_2',
                    label: 'شماره تماس دوم',
                  })}
                </Grid>
                <Hidden smDown>
                  <Grid item md={6} />
                </Hidden>

                <Grid item xs={6} md={3}>
                  {renderAutocompleteInput({
                    label: 'شهر',
                    name: 'city',
                    options: cities?.data.items,
                    getOptionLabel: (o) => o?.title,
                  })}
                </Grid>
                <Hidden smDown>
                  <Grid item md={9} />
                </Hidden>

                <Grid item xs={12} md={6}>
                  {renderInput({
                    name: 'street',
                    label: 'آدرس',
                    multiline: true,
                  })}
                </Grid>
                <Hidden smDown>
                  <Grid item md={6} />
                </Hidden>

                <Grid item xs={6} md={3}>
                  <Button
                    fullWidth
                    color='primary'
                    variant='contained'
                    type='submit'
                    disabled={isSubmitting}
                    className='en mediumPadding'
                  >
                    {isSubmitting ? <CircularProgress size={22} /> : 'Save'}
                  </Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    fullWidth
                    color='primary'
                    variant='outlined'
                    disabled={isSubmitting}
                    className='en mediumPadding'
                  >
                    Discard
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </RTL>
      </PageWrapper>
    </Fragment>
  );
};

export default PatientCreate;
