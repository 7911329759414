import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AutoDirTextField from '../../../../shared/Inputs/AutoDirTextField';
import AppCheckbox from '../../../../shared/Inputs/AppCheckbox';
import AutoDirTypography from '../../../../shared/Typography/AutoDirTypography';
import CardexList from '../../../Cardex/List';
import { addOrdersToCardex } from '../../../../../Utilities/order';
import {
  IBaseTaskTemplate,
  ICardex,
  ICardexItem,
  ITask,
  IVisit,
} from '../../../../../types/order';
import { ITaskAction } from '../../../Cardex/Task';
import NewOrder from '../../../Order/NewOrder';
import { useHistory, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

const VISIBILITY_DESCRIPTION =
  'در صورت انتخاب این گزینه، این تمپلیت برای استفاده در دسترس همه کاربران قرار خواهد گرفت. اما فقط شما میتوانید آن را ویرایش یا حذف کنید.';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f0f1f7',
  },
  section: {
    padding: 24,
    backgroundColor: '#fff',
    marginBottom: 8,
  },
  footer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    backgroundColor: '#fff',
  },
  visibilityTitle: {
    fontWeight: 600,
    marginBottom: 8,
  },
  actionsWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    columnGap: 12,
    paddingTop: 40,
    marginTop: 'auto',
  },
});

type IFormData = IBaseTaskTemplate;
interface IProps {
  defaultValues: IBaseTaskTemplate;
  onSubmit: (data: IBaseTaskTemplate) => Promise<any>;
  onDiscard: () => void;
}

const VisitTemplateForm: React.FC<IProps> = ({
  defaultValues,
  onSubmit,
  onDiscard,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [cardex, setCardex] = useState<ICardex>({});
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm<IFormData>({ defaultValues });
  const tasks = watch('tasks');

  useEffect(() => {
    setCardex(
      addOrdersToCardex(
        {},
        tasks.map((task) => ({ task, action: 'add' }))
      )
    );
  }, [tasks]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  const handleAddOrEditTask = (task: ITask) => {
    setValue('tasks', [task, ...tasks.filter((t) => t.uuid !== task.uuid)]);
  };

  const handleDeleteTask = (task: ITask) => {
    setValue(
      'tasks',
      tasks.filter((t) => t.uuid !== task.uuid)
    );
  };

  const openEditPage = (task: ITask) => {
    history.push(location.pathname, {
      orderType: task.category,
      defaultOrder: {
        action: 'edit',
        task,
      },
    });
  };

  const getTaskActions = (item: ICardexItem) => {
    const actions: ITaskAction[] = [];

    if (item.task.uuid) {
      actions.push({
        label: 'Edit',
        handler: openEditPage,
      });

      actions.push({
        label: 'Delete',
        handler: handleDeleteTask,
      });
    }

    return actions;
  };

  return (
    <>
      <NewOrder
        hideFloatButton
        onSubmit={(order) => handleAddOrEditTask(order.task)}
      />

      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <Box className={classes.section}>
          <AutoDirTextField
            required
            fullWidth
            placeholder='Template name'
            {...register('title')}
          />
        </Box>
        <Box className={classes.section}>
          <Box mb={3}>
            <CardexList
              withAddButton
              defaultCardex={cardex}
              taskProps={{
                getTaskActions,
              }}
            />
          </Box>

          <AutoDirTextField
            fullWidth
            multiline
            placeholder='Add note...'
            {...register('description')}
          />
        </Box>
        <Box className={classes.footer}>
          <Typography className={classes.visibilityTitle}>
            Visibility
          </Typography>
          <Controller
            control={control}
            name='public'
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <FormControlLabel
                label='Public template'
                control={<AppCheckbox color='primary' />}
                checked={value}
                onBlur={onBlur}
                onChange={onChange}
                inputRef={ref}
              />
            )}
          />

          <AutoDirTypography>{VISIBILITY_DESCRIPTION}</AutoDirTypography>

          <Box className={classes.actionsWrapper}>
            <Button
              fullWidth
              color='primary'
              variant='contained'
              className='mediumPadding'
              disabled={isSubmitting}
              type='submit'
            >
              {isSubmitting ? <CircularProgress size={22} /> : 'Save template'}
            </Button>
            <Button
              fullWidth
              color='primary'
              variant='outlined'
              className='mediumPadding'
              type='button'
              disabled={isSubmitting}
              onClick={onDiscard}
            >
              Discard
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default VisitTemplateForm;
