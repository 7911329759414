import React, { useState, useEffect } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import {
  getConsultDetail,
  deleteConsultRequest,
} from '../../action/consultAction';
import { useParams, useHistory, Link } from 'react-router-dom';
import usePathAction from '../../Hook/usePathAction';
import { getUserId } from '../../Utilities/storage';
import {
  makeStyles,
  Box,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import PageHeader from '../PageHeader/PageHeader';
import PageWrapper from '../PageWrapper';
import PatientInfo from '../PatientProfile/PatientInfo';
import ConsultRequestDetail from './Sections/ConsultRequestDetail';
import PrintContainer from './Print/PrintContainer';
import { AlertDialog } from '../shared';

import DELETE_ICON from '../../Assets/red_bin_icon.svg';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    [theme.breakpoints.down('sm')]: {
      marginRight: -7,
    },
  },
  printWrapper: {
    width: '100%',
    backgroundColor: '#fff',
  },
  hintText: {
    fontFamily: 'IRANYekan',
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.text.secondary,
    direction: 'rtl',
    textAlign: 'justify',
  },
}));
interface ConsultViewProps {
  consult: any;
  getConsultDetail: (consultId: string) => any;
  deleteConsultRequest: (consultId: string) => any;
}

function ConsultView({
  consult,
  getConsultDetail,
  deleteConsultRequest,
}: ConsultViewProps) {
  const classes = useStyles();
  const history = useHistory();
  const { patientId, consultId } = useParams<{
    patientId: string,
    consultId: string,
  }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pathAction, setPathAction] = usePathAction();

  useEffect(() => {
    fetchPageData();
  }, [consultId]);

  const fetchPageData = () => {
    setLoading(true);
    setError(null);
    getConsultDetail(consultId)
      .then(() => setLoading(false))
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setError(err);
      });
  };

  const handleDeleteConsult = async () => {
    const response = await deleteConsultRequest(consultId);
    if (response && response.status === 200) {
      const section = await localStorage.getItem('section');
      history.replace(`/patient/${consult?.patient?.profile_id}/consult`, {
        cardName: section,
      });
    }
  };

  const DeleteConsult = () => (
    <IconButton
      color='secondary'
      aria-label='Delete Consult'
      className={classes.deleteButton}
      onClick={() => setPathAction('openConsultDeleteDialog')}
    >
      <img alt='Trash' height={25} src={DELETE_ICON} />
    </IconButton>
  );

  return (
    <>
      <PageHeader
        desktopBackButton={true}
        showPatientInfo={false}
        title='Consult Request'
        optionComponent={!loading && !error && <DeleteConsult />}
      />
      <PageWrapper
        greyBg
        loading={loading}
        error={error}
        onReload={fetchPageData}
      >
        <Box className={classes.printWrapper}>
          <PrintContainer consult={consult} fixedContainer={false} />
        </Box>
        <PatientInfo profile={consult?.patient} />
        <Box mb={2} />
        <ConsultRequestDetail consult={consult} />
        <Box mx={3} my={2.5}>
          <Typography className={classes.hintText}>
            درخواست‌دهنده مشاوره می‌تواند تا زمانی که پاسخی برای مشاوره ثبت نشده
            است، متن درخواست مشاوره را ویرایش کند یا حذف نماید.
          </Typography>
        </Box>

        <Box mx={2} mb={2}>
          <Button
            fullWidth
            color='primary'
            variant='contained'
            className='mediumPadding'
            onClick={() =>
              history.push(`/patient/${patientId}/consult/${consultId}/edit`)
            }
          >
            Edit
          </Button>
        </Box>

        <AlertDialog
          dialogActionProps={undefined}
          open={pathAction === 'openConsultDeleteDialog'}
          alertText='Are you sure about deleting this consult request?'
          confirmButton={true}
          confirmText='Delete'
          closeText='Cancel'
          confirmButtonColor='secondary'
          handleClose={() => setPathAction('')}
          handleConfirm={handleDeleteConsult}
        />
      </PageWrapper>
    </>
  );
}

export default connect(
  (state: RootStateOrAny) => ({
    consult: state?.patientsReducer.patient?.consultDetail,
  }),
  { getConsultDetail, deleteConsultRequest }
)(ConsultView);
