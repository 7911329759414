import { GET_CONFIGURATION } from '../action/actionTypes';

const initialState = {
  services: [],
  users: null,
  icd: null,
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

export const generalReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIGURATION:
      return { ...state, config: action.data };
    case 'GET_SERVICES':
      return { ...state, services: action.data };
    case 'GET_USERS_BY_SERVICE': {
      return { ...state, users: action.data };
    }
    case 'GET_ICD11':
      return { ...state, icd: action.data };

    case 'SW_INIT': {
      return { ...state, serviceWorkerInitialized: true };
    }
    case 'SW_UPDATE': {
      return {
        ...state,
        serviceWorkerUpdated: true,
        serviceWorkerRegistration: action.data,
      };
    }
    case 'SW_CLEAR': {
      return {
        ...state,
        serviceWorkerUpdated: false,
        serviceWorkerInitialized: false,
      };
    }
    default:
      return state;
  }
};
