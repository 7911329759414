import React, { Component } from 'react';
import { withWidth, Drawer, withStyles } from '@material-ui/core';
import CHEVRON_Icon from '../../Assets/ic_chevron_down_dark_24.png';
import {ContentLoader} from '../shared';
import toRenderProps from 'recompose/toRenderProps';

const WithWidth = toRenderProps(withWidth());

const styles = {
  root: {
    display: 'inline-block !important',
    padding: '7px 0px',
    marginLeft: '30px',
  },
  fullList: {
    width: 'auto',
  },
  paperSM: {
    border: 'none',
    boxShadow: 'none',
    width: '100vw',
    // paddingLeft: '2vw',
    paddingTop: '10px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    direction: 'rtl',
  },
  paperLG: {
    border: 'none',
    boxShadow: 'none',
    width: '70vw',
    marginLeft: '30vw',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    direction: 'rtl',
  },
  farsiDirection: {
    direction: 'rtl',
    fontFamily: 'IRANYekan',
  },
};
class MainDialogContainer extends Component {
  state = {};
  render() {
    const { classes, open } = this.props;
    return (
      <WithWidth>
        {({ width }) => (
          <Drawer
            classes={
              width === 'xs' || width === 'sm'
                ? { paperAnchorBottom: classes.paperSM }
                : { paperAnchorBottom: classes.paperLG }
            }
            anchor='bottom'
            open={open}
            onClose={() => this.props.handleClose()}
          >
            <div
              style={{
                position: 'relative',
              }}
            >
              <img
                className='hoverable drawerTopCloseIcon'
                alt='icon'
                height={20}
                width={20}
                src={CHEVRON_Icon}
                onClick={() => this.props.handleClose()}
              />
            </div>
            <div>
              {this.props.loading ? (
                <ContentLoader />
              ) : (
                this.props.dialogContent
              )}
            </div>
          </Drawer>
        )}
      </WithWidth>
    );
  }
}

export default withStyles(styles)(MainDialogContainer);
