import React from 'react';
import { Loader } from '../shared';
import './BlueButton.scss';
function BlueButton(props) {
  const { text, style, handleClick, disabled, loader, className } = props;
  return (
    <button
      onClick={() => handleClick()}
      className={`inlabButton ${className}`}
      style={style}
      disabled={disabled}
    >
      {loader ? <Loader /> : text}
    </button>
  );
}

export default BlueButton;
