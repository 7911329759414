import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

//ui-components
import { Typography } from '@material-ui/core';

//assets
import FORWARD_ARROW from '../../../Assets/icons/icon_material_arrow_forward.svg';
import { renderAutoDirText } from '../../../Utilities/CheckLang';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
  },
  section: {
    flex: 1,
  },
  service: {
    fontFamily: 'IRANYekan',
    textAlign: 'right',
    fontSize: '1rem',
    minHeight: 25,
    padding: 0,
    margin: 0,
    fontWeight: '600',
    [theme.breakpoints.down(350)]: {
      fontSize: '0.85rem',
    },
  },
  name: {
    textAlign: 'right',
    fontSize: '0.7rem',
    [theme.breakpoints.down(350)]: {
      fontSize: '0.6rem',
    },
  },
  icon: {
    margin: '8px 20px',
    alignSelf: 'flex-start',
  },
}));

export default function Main({
  fromServiceName,
  toServiceName,
  requesterName,
  consultantName,
}) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Typography className={classes.service}>{toServiceName}</Typography>
        <Typography className={classes.name}>
          {renderAutoDirText(consultantName)}
        </Typography>
      </div>
      <img alt='to' src={FORWARD_ARROW} className={classes.icon} />
      <div className={classes.section}>
        <Typography className={classes.service}>{fromServiceName}</Typography>
        <Typography className={classes.name}>
          {renderAutoDirText(requesterName)}
        </Typography>
      </div>
    </div>
  );
}
