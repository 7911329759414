import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getTerms } from '../../../../api/medicalTerms';
import { IOrder } from '../../../../types/order';
import { Box, Grid } from '@material-ui/core';
import ResponsiveAutocomplete from '../../../ResponsiveAutocomplete';
import OrderTimingField from '../Fields/Timing';
import OrderNoteField from '../Fields/Note';

interface IProps {
  defaultOrder: IOrder;
  onOrderChange: (order: IOrder) => void;
}

const LabFormContent: React.FC<IProps> = ({ defaultOrder, onOrderChange }) => {
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isFetching } = useQuery(
    [
      'medical_terms',
      {
        type: 'lab',
        q: searchQuery,
      },
    ],
    () => getTerms('lab', { q: searchQuery })
  );

  if (defaultOrder?.task.category !== 'lab') return null;

  const handleChange = (_: any, value: any) => {
    if (defaultOrder?.task.category === 'lab') {
      const newOrder = {
        ...defaultOrder,
        task: {
          ...defaultOrder.task,
          tests: value
            ? value.map((val: { id: number; title_en: string }) => ({
                id: val.id,
                title_en: val.title_en,
              }))
            : [],
        },
      };

      onOrderChange(newOrder);
    }
  };

  const handleTimingFieldChange = (value: string, errors: any[]) => {
    if (defaultOrder.task.category === 'lab') {
      let newTask = { ...defaultOrder.task };
      newTask = {
        ...newTask,
        timing: {
          raw: value,
          schedules: [],
          errors: errors,
        },
      };
      onOrderChange({ ...defaultOrder, task: newTask });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width={1} display='flex'>
          <Box display='flex' alignItems='start' mr={1} pt={1.25}>
            Check
          </Box>
          <Box width={1}>
            <ResponsiveAutocomplete
              isAsync
              multiple
              disableCloseOnSelect
              loading={isFetching}
              options={data?.data.results ?? []}
              filterOptions={(options) => options}
              getOptionLabel={(o) => o.title_en || ''}
              getOptionSelected={(o, v) => o?.id === v?.id}
              value={defaultOrder.task.tests}
              onChange={handleChange}
              onInputChange={(val) => setSearchQuery(val)}
              textFieldProps={{
                autoFocus: true,
                placeholder: 'Test',
              }}
            />
          </Box>
        </Box>
      </Grid>

      {!!defaultOrder.task.tests.length && (
        <>
          <Grid item xs={9} md={10}>
            <OrderTimingField
              value={defaultOrder.task.timing?.raw}
              onChange={handleTimingFieldChange}
            />
          </Grid>

          <Grid item xs={12}>
            <OrderNoteField order={defaultOrder} onChange={onOrderChange} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LabFormContent;
