import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { ReactComponent as ProfileIcon } from '../../../../../Assets/icons/profile_circle_grey.svg';
import {
  CheckRtl,
  renderAutoDirText,
} from '../../../../../Utilities/CheckLang';
import { ITaskTemplate } from '../../../../../types/order';
import { getUserFullName } from '../../../../../Utilities/user';
import AutoDirTypography from '../../../../shared/Typography/AutoDirTypography';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  header: {
    padding: '13px 16px 8px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px 8px',
    borderTop: '2px solid #f0f1f7',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
    color: theme.palette.grey[700],
  },
}));

interface IProps {
  template: ITaskTemplate;
  onClick?: () => void;
}

const VisitTemplateCard: React.FC<IProps> = ({ template, onClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} onClick={onClick}>
      <Box className={classes.header}>
        <AutoDirTypography
          className='mediumWeight'
          rtlClasses='farsiBold rtl text-right'
        >
          {template.title}
        </AutoDirTypography>

        <AutoDirTypography variant='body2'>
          {template.description}
        </AutoDirTypography>
      </Box>
      <Box className={classes.footer}>
        <Typography>{template.tasks.length} orders</Typography>

        <Box className={classes.user}>
          <Typography className='mediumWeight' variant='body2'>
            {renderAutoDirText(getUserFullName(template.author))}
          </Typography>

          <ProfileIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default VisitTemplateCard;
