/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { connect, RootStateOrAny } from 'react-redux';
import { editDraftVisit } from '../../../action/order';
import { addMessage } from '../../../action/generalActions';
import { useLocation, useHistory } from 'react-router-dom';
import usePathAction from '../../../Hook/usePathAction';
import {
  addOrdersToCardex,
  isNewOrder,
  initialCardexStructure,
  getFinishedUUIDs,
  getCategory,
  isEmptyTask,
} from '../../../Utilities/order';
import {
  IOrder,
  IVisit,
  ICardex,
  ITask,
  IDraftVisit,
  ICardexItem,
  ICardexGroup,
  ICardexAppendix,
} from '../../../types/order';
import { Box, Grid, Button, CircularProgress } from '@material-ui/core';
import CardexList from './List';
import NewOrder from '../Order/NewOrder';
import { AlertDialog } from '../../shared';
import useAutoSaveOrder from '../../../Hook/useAutoSaveOrder';
import VisitDescription from '../Visit/Description';
import { ITaskAction } from './Task';
import VisitTemplates from '../Template/Visit';

const descriptions = {
  edit: [
    'در صورت تایید ویرایش، صرفا امضای شما بر روی ویزیت خواهد ماند و امضای نفرات قبلی حذف خواهد شد. نفرات قبلی باید ویزیت جدید را دوباره امضا کنند.',
  ],
  add: [
    'در صورت انتخاب Save as draft میتوانید از طریق دکمه New visit به Draft های ذخیره شده نیز دسترسی داشته باشید.',
  ],
};

interface IProps {
  purpose?: 'edit' | 'add';
  firstVisit?: boolean;
  visit?: IVisit | null;
  cardexAppendix?: ICardexAppendix | null;
  relatedDraft?: IDraftVisit | null;
  defaultOrders?: IOrder[];
  defaultCardex?: ICardex;
  disabled?: boolean;
  showSaveAsTemplateButton?: boolean;
  onSaveAsTemplate?: () => void;
  addMessage: (message: string, status: number) => void;
  onSubmit: (orders: IOrder[]) => any;
  showSaveAsDraftButton?: boolean;
  onSaveAsDraft?: (orders: IOrder[]) => any;
  onDiscard?: () => any;
  onSuccessSubmit?: () => void;
  editDraftVisit: (
    draftId: number,
    data: any
  ) => Promise<AxiosResponse<IDraftVisit>>;
  createRelatedDraft?: (order: IOrder[]) => any;
}

const CardexForm: React.FC<IProps> = ({
  purpose = 'add',
  firstVisit = false,
  visit,
  cardexAppendix,
  relatedDraft,
  defaultOrders,
  defaultCardex,
  disabled = false,
  showSaveAsTemplateButton = false,
  onSaveAsTemplate,
  addMessage,
  onSubmit,
  showSaveAsDraftButton,
  onSaveAsDraft,
  onDiscard,
  onSuccessSubmit = () => {},
  editDraftVisit,
  createRelatedDraft,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [pending, setPending] = useState<'' | 'draft' | 'submit'>('');
  const [pathAction, setPathAction] = usePathAction();
  const [cardex, setCardex] = useState<ICardex>({});
  const { states, save, removeExtraOrders } = useAutoSaveOrder({
    orders,
    draft: relatedDraft ?? null,
    onCreateDraft: createRelatedDraft,
    onEditDraft: editDraftVisit,
  });

  const initialCardex = useMemo(() => {
    let tempCardex: ICardex;

    if (defaultCardex) {
      tempCardex = defaultCardex;
    } else {
      const finishedUUIDs = getFinishedUUIDs(
        cardexAppendix?.task_notices ?? {}
      );
      tempCardex = initialCardexStructure({
        visit,
        finishedUUIDs,
        skipStates: ['dc', 'extinct'],
      });
    }

    setCardex({ ...tempCardex });

    return tempCardex;
  }, [visit, cardexAppendix, defaultCardex]);

  useEffect(() => {
    if (disabled) return;
    const newCardex = addOrdersToCardex(initialCardex, orders);
    setCardex(newCardex);
  }, [orders]);

  useEffect(() => {
    if (defaultOrders) {
      addNewOrder([...defaultOrders], false);
    }
  }, [defaultOrders]);

  const addNewOrder = (value: IOrder | IOrder[], enableAutoSave = true) => {
    const newOrders = Array.isArray(value) ? value : [value];
    const savedOrders: IOrder[] = [];

    let tempOrders = [...removeExtraOrders(orders)];
    for (const newOrder of newOrders) {
      tempOrders = tempOrders.filter(
        (order) => order.task.uuid !== newOrder.task.uuid
      );

      if (isNewOrder(initialCardex, newOrder)) {
        savedOrders.push(newOrder);
        tempOrders = [...tempOrders, newOrder];
      } else {
        savedOrders.push({ ...newOrder, action: null });
      }
    }

    setOrders(tempOrders);
    if (enableAutoSave) save(relatedDraft ? savedOrders : tempOrders);
  };

  const handleTaskAction = (
    action: 'edit' | 'hold' | 'unhold' | 'dc' | 'delete'
  ) => async (task: ITask) => {
    // This function just handle task actions and actions such as 'add' not handle here!
    switch (action) {
      case 'dc':
      case 'hold':
      case 'unhold': {
        addNewOrder({ action, task });
        break;
      }
      case 'delete': {
        addNewOrder({ action: null, task });
        break;
      }
      case 'edit': {
        let orderAction = 'add';
        if (task.uuid && visit) {
          const isRecentlyAdded = await orders.find(
            (order) => order.action === 'add' && order.task.uuid === task.uuid
          );

          orderAction = isRecentlyAdded ? 'add' : 'change';
        }

        history.push(location.pathname, {
          orderType: task.category,
          defaultOrder: {
            action: orderAction,
            task,
          },
        });
        break;
      }
    }
  };

  const getTaskActions = (item: ICardexItem, group?: ICardexGroup) => {
    const category = getCategory(item.task);
    const actions: ITaskAction[] = [];

    if (item.task.uuid) {
      const savingStatus = states[item.task.uuid];

      if (savingStatus && savingStatus.state === 'error') {
        actions.push({
          label: 'Retry',
          handler: savingStatus.onReload ?? (() => {}),
        });
      } else {
        actions.push({
          label: 'Edit',
          handler: handleTaskAction('edit'),
        });

        if (
          category !== 'general' &&
          (group !== 'new' || item.state !== 'active')
        ) {
          // HOLD
          if (item.state === 'hold') {
            actions.push({
              label: 'UnHold',
              handler: handleTaskAction('unhold'),
            });
          } else {
            actions.push({
              label: 'Hold',
              handler: handleTaskAction('hold'),
            });
          }
          // DC
          if (item.state !== 'dc') {
            actions.push({
              label: 'DC',
              handler: handleTaskAction('dc'),
            });
          }
        }
      }

      if (group === 'new' && !isEmptyTask(item.task)) {
        actions.push({
          label: 'Delete',
          handler: handleTaskAction('delete'),
        });
      }
    } else if (category === 'general') {
      actions.push({
        label: 'Edit',
        handler: handleTaskAction('edit'),
      });
    }

    return actions;
  };

  const isValidOrders = (): boolean => {
    if (orders.length === 0) {
      addMessage('Please set at least one order to save!', 300);
      return false;
    }
    return true;
  };

  const modifyOrders = async (orders: IOrder[]): Promise<IOrder[]> => {
    const modifiedOrders: IOrder[] = [];

    for (const order of orders) {
      if (order.action === 'add') {
        delete order.task.uuid;
      }

      modifiedOrders.push(order);
    }

    return modifiedOrders;
  };

  const handleSubmit = async (
    isDraft: boolean,
    bypassConfirmation?: boolean
  ) => {
    if (isValidOrders()) {
      try {
        if (isDraft) {
          if (onSaveAsDraft) {
            await setPending('draft');
            const modifiedOrders = await modifyOrders(orders);
            await onSaveAsDraft(modifiedOrders);
          }

          onSuccessSubmit();
        } else {
          if (bypassConfirmation) {
            await setPending('submit');
            const modifiedOrders = await modifyOrders(orders);
            await onSubmit(modifiedOrders);
            onSuccessSubmit();
          } else {
            setPathAction('confirmSubmit');
          }
        }
      } catch (e) {
        console.log(e);
      }
      await setPending('');
    }
  };

  const handleConfirmSubmit = () => {
    setPathAction('');
    handleSubmit(false, true);
  };

  const handleSaveAsTemplate = async () => {
    if (isValidOrders() && onSaveAsTemplate) {
      onSaveAsTemplate();
    }
  };

  const handleAddTemplateOrders = (orders: IOrder[]) => {
    addNewOrder(orders);
  };

  return (
    <>
      <VisitTemplates
        tasks={orders.map((o) => o.task)}
        onAddOrders={handleAddTemplateOrders}
      />

      <Box pb={2}>
        <CardexList
          detailedView
          withNewGroups={!firstVisit}
          withAddButton={!disabled}
          mode='visit'
          defaultCardex={cardex}
          savingStatuses={states}
          taskProps={{ getTaskActions }}
        />
      </Box>

      <NewOrder onSubmit={addNewOrder} />

      <Box pt={2}>
        <Box mb={1}>
          <VisitDescription
            descriptions={descriptions[purpose == 'edit' ? 'edit' : 'add']}
          />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              className='mediumPadding'
              disabled={disabled || Boolean(pending)}
              onClick={() => handleSubmit(false)}
            >
              {pending === 'submit' ? (
                <CircularProgress size={22} />
              ) : (
                'Submit & Sign'
              )}
            </Button>
          </Grid>
          <Grid item xs={12}>
            {onDiscard ? (
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                className='mediumPadding'
                onClick={() => onDiscard()}
                disabled={disabled}
              >
                Discard
              </Button>
            ) : null}
            {showSaveAsDraftButton ? (
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                className='mediumPadding'
                disabled={disabled || Boolean(pending)}
                onClick={() => handleSubmit(true)}
              >
                {pending === 'draft' ? (
                  <CircularProgress size={22} />
                ) : (
                  'Save as draft'
                )}
              </Button>
            ) : null}
          </Grid>
          {showSaveAsTemplateButton && (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                className='mediumPadding'
                disabled={disabled || Boolean(pending)}
                onClick={handleSaveAsTemplate}
              >
                Add this visit to templates
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>

      <AlertDialog
        open={pathAction === 'confirmSubmit'}
        alertText='Are you sure you want to submit this visit?'
        confirmButton={true}
        confirmText='Submit'
        confirmButtonColor='primary'
        closeText='Cancel'
        handleClose={() => setPathAction('')}
        handleConfirm={handleConfirmSubmit}
      />
    </>
  );
};

export default connect(
  (state: RootStateOrAny) => ({
    cardexAppendix: state.order.cardexAppendix,
  }),
  { editDraftVisit, addMessage }
)(CardexForm);
