import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
// ui components
import Divider from '@material-ui/core/Divider';
import { ListItem } from '@material-ui/core';
import NotifDrawer from '../NotifDrawer/Notifdrawer';
import ConsultCard from '../Consult/ConsultCards';
import PriorityComponent from '../Consult/ConsultCards/Priority';
import CardOptions from './CardOptions';
import PatientOverallInfo from './PatientOverallInfo';

//actions
import {
  triggerNotif,
  replacePatientOfShift,
  replacePatientOfWard,
} from '../../action/actions';
import { Link, withRouter } from 'react-router-dom';
import { getConsultDetail } from '../../action/consultAction';
import { setPinnedPatient, unsetPinnedPatient } from '../../action/actions';
import { addMessage } from '../../action/generalActions';

// utils
import { withStyles } from '@material-ui/core/styles';

//assets
import './PatientCard.scss';

const pinPatientLimit = window.env.REACT_APP_PATIENT_PIN_LIMIT || 30;
const isOrderEnable = !!window.env.REACT_APP_ENABLE_ORDER;
const styles = {
  listItemContainer: {
    display: 'block',
    padding: '0',
    position: 'relative',
    // minHeight: '133.913px',
    overflow: 'hidden',
    // marginBottom: 0,
    // marginTop: '0px',
    // margin: 0,
    // padding: 0
  },
  listRoot: {
    backgroundColor: '#FFFFFF',
    margin: '0.55rem 0.75rem',
    boxShadow: '0 0 10px 1px rgba(0,0,0,0.12)',
    borderRadius: '6px',
    position: 'absolute',
    zIndex: '10',
    overflow: 'hidden',
  },
  listRootPlaceHolder: {
    margin: '0.55rem 0.75rem',
    padding: '10px',
    position: 'absolute',
    left: '0',
    right: '0',
    top: '1px',
    bottom: '1px',
    zIndex: '0',
    display: 'flex',
    alignItems: 'flex-start',
    fontSize: '.9em',
    background: '#386fff',
    borderRadius: '8px',
    color: 'white',
    flexFlow: 'column',
    justifyContent: 'center',
  },
  cardContainer: {
    left: 0,
    right: 0,
    height: 'auto',
    position: 'relative',
    marginTop: '8px',
    marginBottom: '7px',
  },
  selectedPatientCard: {
    boxShadow: 'rgb(234, 234, 234) 0px 0px 3px 2px',
    backgroundColor: '#e9faff',
  },
  dividerRoot: {
    // marginTop: '0.57rem',
    background: '#ccd2e0',
  },
  pinTip: {
    margin: '5px',
  },
};
class PatientCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinned: false,
      selectedPatientId: null,
      notif_dialog: false,
      // lab_subscription: props.patient.lab_tests_subscription,
      // radio_subscription: props.patient.radiology_subscription,
    };
  }
  componentDidMount() {
    this.setState({
      selectedPatientId: this.props.match?.params?.patientId,
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = { selectedPatientId: nextProps.match?.params?.patientId };
    if (
      nextProps.pinnedPatients &&
      nextProps.pinnedPatients[nextProps.cardName]?.length &&
      nextProps.pinnedPatients[nextProps.cardName].find(
        (item) => item.id === nextProps.patient?.profile_id
      )
    ) {
      return { ...newState, pinned: true };
    } else {
      return { ...newState, pinned: false };
    }
  }
  handleNotif = () => {
    this.setState({ notif_dialog: true });
  };

  handleClose = () => {
    this.setState({ notif_dialog: false });
  };

  async handleTriggerNotif(labValue, radioValue, id) {
    var data = {
      profile_id: id,
    };
    if (labValue === 'none') {
      data.lab_test_any_trigger = false;
      data.lab_test_any_abnormal_only = false;
    } else if (labValue === 'abnormal') {
      data.lab_test_any_trigger = true;
      data.lab_test_any_abnormal_only = true;
    } else {
      data.lab_test_any_trigger = true;
      data.lab_test_any_abnormal_only = false;
    }
    if (radioValue === 'none') {
      data.radiology_service_any_trigger = false;
    } else {
      data.radiology_service_any_trigger = true;
    }
    const response = await this.props.triggerNotif(data);
    // this.setState({
    //   lab_subscription: labValue,
    //   radio_subscription: radioValue,
    // });
    this.handleRefreshPatientData(response, labValue, radioValue);
  }
  handleRefreshPatientData = (response, labValue, radioValue) => {
    const newPatient = {
      ...this.props.patient,
      lab_tests_subscription: labValue,
      radiology_subscription: radioValue,
    };
    if (response?.status === 200) {
      if (this.props.cardName === 'shift') {
        this.props.replacePatientOfShift(newPatient);
      } else {
        this.props.replacePatientOfWard(newPatient);
      }
    }
  };
  handleRefreshPatientData = (response, labValue, radioValue) => {
    const newPatient = {
      ...this.props.patient,
      lab_tests_subscription: labValue,
      radiology_subscription: radioValue,
    };
    if (response?.status === 200) {
      if (this.props.cardName === 'shift') {
        this.props.replacePatientOfShift(newPatient);
      } else {
        this.props.replacePatientOfWard(newPatient);
      }
    }
  };
  handlePinPatient = () => {
    if (this.props.cardName === 'consult') {
      return;
    }
    const selectedPatient = this.props.patient;
    if (this.state.pinned) {
      requestAnimationFrame(() =>
        (async () => {
          await this.props.unsetPinnedPatient(
            selectedPatient.profile_id,
            this.props.cardName
          );
        })()
      );
      return true;
      /** @check */
    } else {
      if (
        this.props.pinnedPatients[this.props.cardName]?.length ===
        pinPatientLimit
      ) {
        this.props.addMessage('You should Unpin a patient to pin this one!');
        return;
      }
      requestAnimationFrame(() =>
        (async () => {
          await this.props.setPinnedPatient(
            selectedPatient,
            selectedPatient.profile_id,
            this.props.cardName
          );
          this.props.scrollToTopOfDrawer();
        })()
      );

      return true;
    }
  };

  handleNotifSubmit = (labValue, radioValue) =>
    this.handleTriggerNotif(
      labValue,
      radioValue,
      this.props.patient?.profile_id
    );

  render() {
    let patient;
    let consult;
    const { key, cardName, classes } = this.props;
    // const { lab_subscription, radio_subscription } = this.state;
    if (cardName === 'consult') {
      // this.props.patient is the consult (bad naming!)
      patient = this.props.patient.patient;
      consult = this.props.patient;
    } else {
      patient = this.props.patient;
    }
    if (!patient) {
      return null;
    }

    const containerClass = `${classes.cardContainer} ${
      patient.profile_id === this.props.patientId && cardName !== 'consult'
        ? classes.selectedPatientCard
        : patient.profile_id === this.props.patientId && cardName === 'consult'
        ? ''
        : ''
    } ${classes.listRoot}
      ${this.state.pinned ? 'pinned-card' : ''}`;

    return (
      <ListItem className={classes.listItemContainer}>
        <div className={containerClass} key={key}>
          <div className='TextContainerPatientCard'>
            {cardName === 'consult' ? (
              <Link
                className='hoverable flex'
                to={{
                  pathname: `/patient/${consult.patient.profile_id}/consult/${consult.id}/answer`,
                  state: {
                    id: consult.patient.profile_id,
                    title: 'consult-create',
                    cardName: 'consult',
                  },
                }}
              >
                <PriorityComponent priority={consult.priority} />
                <div className='consult-tab__card'>
                  <PatientOverallInfo
                    pinned={this.state.pinned}
                    patient={patient}
                    lab_subscription={patient.lab_tests_subscription}
                    handleNotif={this.handleNotif}
                    pinPatient={this.handlePinPatient}
                    consultView
                  />
                  <ConsultCard consult={consult} consultantView />
                </div>
              </Link>
            ) : (
              <PatientOverallInfo
                pinned={this.state.pinned}
                patient={patient}
                lab_subscription={patient.lab_tests_subscription}
                handleNotif={this.handleNotif}
                pinPatient={this.handlePinPatient}
                onClick={
                  isOrderEnable
                    ? () =>
                        this.props.history.push(
                          `/patient/${patient.profile_id}/order`
                        )
                    : undefined
                }
              />
            )}
            <Divider className={classes.dividerRoot} />
            {/* {this.props.pageReloading &&
            this.state.selectedPatientId?.toString() ===
              patient.profile_id?.toString() ? (
              <div className='text-center'>
                <Loader height={35} />
              </div>
            ) : ( */}
            <CardOptions
              cardName={cardName}
              patient={patient}
              lab_subscription={patient.lab_tests_subscription}
              handleNotif={this.handleNotif}
            />
            {/* )} */}
          </div>
        </div>

        {this.state.notif_dialog && (
          <div>
            <NotifDrawer
              handleSubmit={this.handleNotifSubmit}
              labValue={patient.lab_tests_subscription}
              radioValue={patient.radiology_subscription}
              handleClose={this.handleClose}
              /** @check */
              // useless props there is no sign of using this props in NotifDrawer
              // AddOrRemoveNotifer={lab_subscription === 'all'}
              // removeNotifer={() => this.handleRemoveNotifer(patient.profile_id)}
              // newResultNotifer={() =>
              //   this.handlenewResultNotifer(patient.profile_id)
              // }
              // abnormalResultNotifer={() =>
              //   this.handleabnormalResultNotifer(patient.profile_id)
              // }
            />
          </div>
        )}
      </ListItem>
    );
  }
}

PatientCard.propTypes = {
  classes: PropTypes.object,
  cardName: PropTypes.string,
  pageReloading: PropTypes.bool,
  patient: PropTypes.any,
  patientId: PropTypes.number,
  key: PropTypes.string,
  triggerNotif: PropTypes.any,
  getConsultDetail: PropTypes.any,
  setPinnedPatient: PropTypes.any,
  unsetPinnedPatient: PropTypes.any,
  addMessage: PropTypes.any,
  match: PropTypes.any,
  pinnedPatients: PropTypes.any,
  scrollToTopOfDrawer: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    wardPatients: state.patientsReducer.wardPatients,
    patientId: state.patientsReducer.patient?.profile?.profile_id,
    // pageTitle: state.contentDataReducer.pageTitle,
    pageReloading: state.patientsReducer.pageReloading,
    pinnedPatients: state.patientsReducer.pinnedPatients,
    fireBaseToken: state.fireBaseTokenReducer.fireBaseToken,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      triggerNotif,
      getConsultDetail,
      setPinnedPatient,
      unsetPinnedPatient,
      addMessage,
      replacePatientOfShift,
      replacePatientOfWard,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(PatientCard)));
