import { useEffect, useState } from 'react';
import { getFullDate } from '../../../Utilities/jalaliDate';

const useFilterToPrint = ({
  printableConsults,
  date,
  isInAppReplied,
  setFilteredResult,
}) => {
  const minimumDate = new Date(
    Math.min(
      ...printableConsults.map(
        (item) => new Date(item.effective_request_datetime)
      )
    )
  );
  const maximumDate = new Date(
    Math.max(
      ...printableConsults.map(
        (item) => new Date(item.effective_request_datetime)
      )
    )
  );
  const [rangeDate, setRangeDate] = useState({
    start: '',
    end: '',
  });

  useEffect(() => {
    const minDate = getFullDate(minimumDate, false);
    const maxDate = getFullDate(maximumDate, false);
    if (date.from && !date.to) {
      setRangeDate({ start: new Date(date.from), end: new Date(maxDate) });
    } else if (!date.from && date.to) {
      setRangeDate({ start: new Date(minDate), end: new Date(date.to) });
    } else if (date.from && date.to) {
      setRangeDate({ start: new Date(date.from), end: new Date(date.to) });
    } else {
      setRangeDate({ start: new Date(minDate), end: new Date(maxDate) });
    }
  }, [date, printableConsults]);

  useEffect(() => {
    const filteredConsult = printableConsults.filter((item) => {
      const date = getFullDate(item.effective_request_datetime, false);
      const mainDate = new Date(date);
      const filterByDate =
        mainDate >= rangeDate.start && mainDate <= rangeDate.end;
      if (isInAppReplied) {
        return !item.paper_reply && filterByDate;
      } else {
        return filterByDate;
      }
    });
    setFilteredResult(filteredConsult);
  }, [rangeDate.start, rangeDate.end, printableConsults, isInAppReplied]);

  return { minimumDate, maximumDate };
};

export default useFilterToPrint;
