/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from './apiController';
import { IPatient } from '../types/patient';

export const getPatient = (profile_id: string | number) =>
  axios.get<IPatient>(`/patient/${profile_id}`);

export const getPatientHint = (
  offset: number,
  limit: number,
  query: {
    name?: string;
    national_id?: string;
  }
) =>
  axios.get<{ patients: IPatient[] }>('/patient/hint', {
    params: { offset, limit, ...query },
  });

export const createPatient = (data: any) =>
  axios.post<IPatient>('/patient', data);

export const updatePatient = (profile_id: string | number, data: any) =>
  axios.put<IPatient>(`/patient/${profile_id}`, data);
