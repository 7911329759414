import React, { ReactNode, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  makeStyles,
  Button,
  Typography,
  Box,
  ButtonProps,
  Tooltip,
  ClickAwayListener,
  Theme,
  useTheme,
} from '@material-ui/core';
import CALENDAR_ICON from '../../../Assets/icons/vuesax_linear_calendar.svg';
import PROFILE_ICON from '../../../Assets/icons/profile_circle.svg';
import clsx from 'clsx';
import { getTime, jalaliFullDate } from '../../../Utilities/jalaliDate';
import { renderAutoDirText } from '../../../Utilities/CheckLang';
import { ReactComponent as CheckIcon } from '../../../Assets/icons/check.svg';
import useCheckMobileScreen from '../../../Hook/useCheckMobileScreen';
import DraftVisitsDrawer from './DraftVisitsDrawer';
import useAccess from '../../../Hook/useAccess';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../action/generalActions';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#f0f1f7',
    border: '1px solid #ccccd2e0',
    borderRadius: 4,
  },
  cardRow: {
    color: '#191e25',
    display: 'flex',
    columnGap: 8,
    alignItems: 'center',
    padding: '12px 16px',

    '&:first-child': {
      borderBottom: '1px solid #ccccd2e0',
    },
  },
  disabledButton: {
    color: '#fff',
    background: '#B9D2FF',
    boxShadow: 'none',
    cursor: 'default',

    '&:hover': {
      background: '#B9D2FF',
    },
  },
});

type IActionButton = ButtonProps & {
  label?: string | ReactNode;
  toolTipTitle?: string;
};

const ActionButton: React.FC<IActionButton> = ({
  label,
  toolTipTitle = '',
  onClick,
  ...props
}) => {
  const isMobileScreen = useCheckMobileScreen();
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => setOpen(true);

  const handleTooltipClose = () => setOpen(false);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        open={isMobileScreen ? open : undefined}
        onClose={handleTooltipClose}
        title={toolTipTitle}
        placement='top'
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleTooltipOpen();
            if (onClick) onClick(e);
          }}
          {...props}
        >
          <Typography variant='subtitle2'>{label}</Typography>
        </Button>
      </Tooltip>
    </ClickAwayListener>
  );
};

interface IProps {
  datetime: string;
  doctor: string;
  visitId: number;
  hasEditButton?: boolean;
  checkState: 'disabled' | 'checked' | 'active';
  className?: string;
}

const VisitCard: React.FC<IProps> = ({
  datetime,
  doctor,
  visitId,
  hasEditButton,
  checkState,
  className = '',
}) => {
  const classes = useStyles();
  const theme = useTheme<Theme>();
  const { patientId } = useParams<{ patientId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: nurseVisitOperatorAccess } = useAccess('nurse_visit_operator');

  const profileIcon = <img src={PROFILE_ICON} height={20} alt='profile' />;
  const doctorName = (
    <Typography component='span'>{renderAutoDirText(doctor)}</Typography>
  );

  const handleViewVisit = () => {
    history.push(`/patient/${patientId}/order/visit/${visitId}`);
  };

  const actionButtons = useMemo(() => {
    const actionProps: IActionButton[] = [
      {
        color: 'primary',
        variant: 'outlined',
        label: 'View',
        onClick: handleViewVisit,
        style: { minWidth: 88, padding: '4px' },
      },
      {
        color: 'primary',
        variant: checkState === 'checked' ? 'outlined' : 'contained',
        disableRipple: checkState === 'disabled',
        className: checkState === 'disabled' ? classes.disabledButton : '',
        toolTipTitle:
          checkState === 'disabled'
            ? 'The first unchecked visit should be checked before this visit.'
            : '',
        label:
          checkState === 'checked' ? (
            <>
              Checked <CheckIcon />
            </>
          ) : (
            'Check'
          ),
        onClick: () => {
          if (checkState === 'active') {
            if (nurseVisitOperatorAccess) {
              history.push(
                `/patient/${patientId}/order/visit/${visitId}/check`
              );
            } else {
              dispatch(
                addMessage('You need permission to access this page!', 300)
              );
            }
          }
        },
        style: {
          minWidth: 88,
          padding: checkState === 'checked' ? '4px' : '6px',
        },
      },
    ];

    return actionProps;
  }, [patientId, visitId, checkState, hasEditButton, nurseVisitOperatorAccess]);

  return (
    <Box
      className={clsx(classes.root, className && className)}
      onClick={handleViewVisit}
    >
      <Box className={classes.cardRow}>
        <Box display='flex'>
          <img
            src={CALENDAR_ICON}
            height={20}
            alt='calendar'
            className='mr-2'
          />
          <Typography variant='h6' className='mr-2'>
            {jalaliFullDate(datetime)}
          </Typography>
          <Typography variant='h6'>{getTime(datetime)}</Typography>
        </Box>
        <Box ml={1} display='flex'>
          <Box mr={1}>{profileIcon}</Box>
          {doctorName}
        </Box>
      </Box>
      <Box className={classes.cardRow}>
        {actionButtons.map((actionButtonProps, index) => (
          <ActionButton key={index} {...actionButtonProps} />
        ))}
        {hasEditButton && (
          <DraftVisitsDrawer
            purpose='edit'
            buttonLabel='Edit'
            drawerButtonLabel='Edit Visit'
            skipPath={`/patient/${patientId}/order/visit/${visitId}/edit`}
            loadingSize={10}
            buttonProps={{
              fullWidth: false,
              style: {
                ...theme.typography.subtitle2,
                minWidth: 88,
                padding: '6px',
              },
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default VisitCard;
