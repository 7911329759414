import * as actionTypes from './actionTypes';
import store from '../store';
import * as api from '../api/api';
import * as GeneralAPI from '../api/generalAPI';
import { getAllConsults } from './consultAction';

import {
  setPatientDB,
  getPatientDB,
  setPinnedPatientDB,
  getPinnedPatientsDB,
  removeInaccessiblePinnedPatientsDB,
  getPinnedPatientsDbBySection,
  unsetPinnedPatientDB,
  getPinnedProfileImagesDB,
  setPinnedProfileImageDB,
  unsetPinnedProfileImageDB,
  replacePinnedPatient,
} from '../DB/db';
import { SET_PINNED_PROFILE_IMAGES } from './actionTypes';

export const PAGINATE_DEFAULT_LIMIT =
  window.env.REACT_APP_PAGINATE_DEFAULT_LIMIT;
export const PAGINATE_DEFAULT_OFFSET =
  window.env.REACT_APP_PAGINATE_DEFAULT_OFFSET;

export const REPLACE_PATIENT_WARD = 'REPLACE_PATIENT_WARD';
export const REPLACE_PATIENT_SHIFT = 'REPLACE_PATIENT_SHIFT';
export const REPLACE_PINNED_PATIENT_SHIFT = 'REPLACE_PINNED_PATIENT_SHIFT';
export const REPLACE_PINNED_PATIENT_WARD = 'REPLACE_PINNED_PATIENT_WARD';

export const setSection = (section = 'ward') => async (dispatch) => {
  await localStorage.setItem('section', section);
  await dispatch({ type: actionTypes.SET_SECTION, section });
};

export const getAllWardsPatients = (
  section,
  searchQuery,
  offset,
  limit,
  ward_id,
  primary_service_id,
  has_unchecked_visits
) => async (dispatch) => {
  limit = limit ? limit : PAGINATE_DEFAULT_LIMIT;
  offset = offset ? offset : PAGINATE_DEFAULT_OFFSET;
  const params = {
    section: searchQuery ? '' : section,
    search_name: searchQuery,
    offset,
    limit,
    ward_id,
    primary_service_id,
  };
  if (has_unchecked_visits) {
    // only filter on truthy values of has_unchecked_visits
    params.has_unchecked_visits = has_unchecked_visits;
  }
  try {
    let noMoreHttpFetch;
    await dispatch({ type: 'PATIENT_LOADER', data: true });
    const setQuery = store.getState().patientsReducer.searchQuery;
    if (searchQuery === undefined) searchQuery = '';
    var response = {};
    if (setQuery === searchQuery) {
      response = await api.getWardPatientList(params);
    } else return;
    await dispatch({ type: 'PATIENT_LOADER', data: false });

    if (
      response.data.patients &&
      response.data.patients.length > 0 &&
      response.data.patients.length >= limit
    ) {
      noMoreHttpFetch = false;
    } else {
      noMoreHttpFetch = true;
    }
    if (setQuery === searchQuery) {
      if (offset > 0) {
        await dispatch({
          type:
            section === 'shift' ? 'ADD_SHIFT_PATIENTS' : 'ADD_WARDS_PATIENTS',
          data: response.data.patients,
          noMoreHttpFetch,
        });
      } else {
        await dispatch({
          type:
            section === 'shift'
              ? 'REPLACE_SHIFT_PATIENTS'
              : 'REPLACE_WARD_PATIENTS',
          data: response.data.patients,
          noMoreHttpFetch,
        });
      }
      if (offset && limit) {
        dispatch(addPageNumber(offset / limit + 2));
      } else {
        dispatch(addPageNumber(1));
      }
    }
    await dispatch({ type: 'PATIENT_LOADER', data: false });
  } catch (error) {
    if (error.networkError === true) {
      await dispatch({
        type: 'PATIENT_LOADER',
        data: false,
        networkError: true,
      });
    } else {
      await dispatch({ type: 'PATIENT_LOADER', data: false });
    }
  }
};

export const updatePatientProfile = (patientId, profile) => (dispatch) => {
  dispatch({
    type: actionTypes.UPDATE_PATIENT_PROFILE,
    patientId,
    profile,
  });
};

export const getPinnedPatients = () => async (dispatch) => {
  getPinnedPatientsDB().then(async (result) => {
    if (result) {
      let filteredResult;
      const patientIds = result.map((ward) => ward.patient.profile_id);
      const response = await api.getWardPatientsById(patientIds);
      const inaccessibleIds = response.data?.inaccessibles;
      await removeInaccessiblePinnedPatientsDB(inaccessibleIds).then(
        (res) => res
      );
      if (response.data.inaccessibles) {
        filteredResult = result.filter(
          (patient) => !inaccessibleIds.includes(patient.profile_id)
        );
      }
      dispatch({
        type: 'GET_PINNED_PATIENT',
        data: filteredResult ? filteredResult : result,
      });
    }
  });
};
export const getPinnedPatientsBySection = (section) => async (dispatch) => {
  let patientsDB;
  getPinnedPatientsDbBySection(section)
    .then(async (result) => {
      patientsDB = result;
      dispatch({
        type: 'GET_PINNED_PATIENT',
        data: result,
        section,
      });
      if (!result || !result.length) {
        // no pinned patient then no need to check the accessibility
        return;
      }

      let filteredResult;
      const patientIds = result.map((ward) => ward.patient.profile_id);
      const response = await api.getWardPatientsById(patientIds);
      const inaccessibleIds = response.data?.inaccessibles;
      if (inaccessibleIds && inaccessibleIds.length) {
        await removeInaccessiblePinnedPatientsDB(inaccessibleIds).then(
          (res) => res
        );
      }
      if (inaccessibleIds) {
        filteredResult = result.filter(
          (patientDBItem) =>
            !inaccessibleIds.includes(patientDBItem?.patient?.profile_id)
        );
      }
      dispatch({
        type: 'GET_PINNED_PATIENT',
        data: filteredResult ? filteredResult : result,
        section,
      });
    })
    .catch(async () => {
      if (patientsDB) {
        dispatch({
          type: 'GET_PINNED_PATIENT',
          data: patientsDB,
          section,
        });
      }
    });
};
export const setPinnedPatient = (patient, patientId, section) => async (
  dispatch
) => {
  setPinnedPatientDB(patient, patientId, section)
    .then((result) => {
      if (result === patientId) {
        dispatch(getPinnedPatientsBySection(section));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const unsetPinnedPatient = (patientId, section) => async (dispatch) => {
  unsetPinnedPatientDB(patientId, section)
    .then(() => {
      dispatch(getPinnedPatientsBySection(section));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getPinnedProfileImages = (profileId) => async (dispatch) => {
  getPinnedProfileImagesDB(profileId).then((result) => {
    let data = {};
    result.forEach((obj) => {
      data = { ...data, [obj.id]: obj };
    });
    dispatch({
      type: SET_PINNED_PROFILE_IMAGES,
      data,
    });
  });
};

export const setPinnedProfileImage = (image, patientId) => async (dispatch) => {
  try {
    await getPinnedProfileImagesDB(patientId).then((result) => {
      if (result.length === 5) {
        return dispatch({
          type: 'ADD_MESSAGE',
          message: 'Maximum number of pinned images reached!',
          status: 400,
        });
      }
      GeneralAPI.convertUrlToBlob(image.url).then(async (response) => {
        const blob = new Blob([response.data]);
        await setPinnedProfileImageDB(image, patientId, blob)
          .then(() => {
            dispatch(getPinnedProfileImages(patientId));
          })
          .catch((error) => console.log(error));
      });
    });
  } catch (error) {
    console.log(error);
  }
};

export const unsetPinnedProfileImage = (
  imageId,
  profileId,
  silent = false
) => async (dispatch) => {
  unsetPinnedProfileImageDB(imageId)
    .then(() => {
      if (!silent) dispatch(getPinnedProfileImages(profileId));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const triggerNotif = (data) => async (dispatch) => {
  return api.triggerNotif(data).then(async (response) => {
    return await dispatch({
      type: 'ADD_MESSAGE',
      message: 'Notification updated successfully.',
      status: response.status,
    });
  });
};

export const pickShift = (id) => async (dispatch) => {
  return api.pickShift(id).then(async (response) => {
    await dispatch({
      type: 'LOAD_USER_DATA',
      data: response.data,
    });
    await dispatch(getAllWardsPatients('shift'));
    await dispatch(getPinnedPatientsBySection('shift'));
    return response;
  });
};

export const pickWard = (body) => async (dispatch) => {
  return api.pickWard(body).then(async (response) => {
    await dispatch({
      type: 'LOAD_USER_DATA',
      data: response.data,
    });
    await dispatch(getAllWardsPatients('rotation'));
    dispatch(getPinnedPatientsBySection('ward'));
    await dispatch(getAllConsults());
    return response;
  });
};


export const pickResponsibilityServices = (body) => async (dispatch) => {
  return api.pickResponsibilityServices(body).then(async (response) => {
    await dispatch({
      type: 'LOAD_USER_DATA',
      data: response.data,
    });
    await dispatch(getAllWardsPatients('rotation'));
    dispatch(getPinnedPatientsBySection('ward'));
    await dispatch(getAllConsults());
    return response;
  });
};

export const setPatientDataBase = (
  patientId,
  pageData,
  pageTitle
) => async () => {
  try {
    if (!pageData || !patientId) {
      return;
    }
    let patientInfoDB;
    await getPatientDB(patientId).then((response) => {
      patientInfoDB = response;
    });
    if (pageTitle === 'profile') {
      await setPatientDB(
        patientId,
        pageData.data,
        patientInfoDB?.labResult,
        patientInfoDB?.radiologyResult
      );
    } else if (pageTitle === 'laboratory') {
      await setPatientDB(
        patientId,
        patientInfoDB?.profile,
        pageData.data.lab_test_groups,
        patientInfoDB?.radiologyResult
      );
    } else if (pageTitle === 'radiology') {
      await setPatientDB(
        patientId,
        patientInfoDB?.profile,
        patientInfoDB?.labResult,
        pageData.data.radiology_services
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const addAuthData = (data) => async (dispatch) => {
  dispatch({ type: 'ADD_AUTH', data: data });
};
export const removeSnackData = () => async (dispatch) => {
  dispatch({ type: 'REMOVE_SNACK_DATA' });
};
export const removePatients = () => async (dispatch) => {
  dispatch({ type: 'REMOVE_PATIENTS' });
};

export const addSearchQuery = (data) => async (dispatch) => {
  await dispatch({ type: 'ADD_SEARCH_QUERY', data: data });
};

export const addPageNumber = (pageNumber) => async (dispatch) => {
  dispatch({ type: 'ADD_PAGE_NUMBER', data: pageNumber });
};

export const replacePatientOfWard = (patient) => async (dispatch) => {
  dispatch({
    type: REPLACE_PATIENT_WARD,
    payload: patient,
  });
  replacePinnedPatient(patient.profile_id, patient, 'ward');
  dispatch({
    type: REPLACE_PINNED_PATIENT_WARD,
    payload: patient,
  });
};
export const replacePatientOfShift = (patient) => async (dispatch) => {
  dispatch({
    type: REPLACE_PATIENT_SHIFT,
    payload: patient,
  });
  replacePinnedPatient(patient.profile_id, patient, 'shift');
  dispatch({
    type: REPLACE_PINNED_PATIENT_SHIFT,
    payload: patient,
  });
};
