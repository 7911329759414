import React, { forwardRef, useMemo } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import UncheckedVisitsCarouselCard from './Card';
import { IVisit, IVisitSummery } from '../../../../types/order';
import { getUserFullName } from '../../../../Utilities/user';
import { useParams, useHistory } from 'react-router-dom';
import useAccess from '../../../../Hook/useAccess';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../../action/generalActions';

const useStyles = makeStyles({
  itemsWrapper: {
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    textAlign: 'left',
  },
  item: {
    display: 'inline-block',
    marginRight: 12,
    '&:last-child': {
      marginRight: 24,
    },
  },
});

interface IProps {
  items: (IVisitSummery | IVisit)[];
  title?: string;
  offset?: number;
}

const UncheckedVisitsCarousel = forwardRef<HTMLDivElement, IProps>(
  ({ items, title, offset = 0 }, ref) => {
    const classes = useStyles();
    const { data: nurseVisitOperatorAccess } = useAccess(
      'nurse_visit_operator'
    );
    const { patientId } = useParams<{ patientId: string }>();
    const history = useHistory();
    const dispatch = useDispatch();

    const sortedItems = useMemo(
      () => items.slice(0).sort((a, b) => a.id - b.id),
      [items]
    );

    const handleCardClick = (visitId: number) => () => {
      if (nurseVisitOperatorAccess) {
        history.push(`/patient/${patientId}/order/visit/${visitId}/check`);
      } else {
        dispatch(addMessage('You need permission to access this page!', 300));
      }
    };

    return (
      <Box {...{ ref }}>
        {!!title && (
          <Box mb={1.5}>
            <Typography
              variant='h5'
              align='left'
              className='darkPrimaryColor semiBoldWeight'
            >
              {title}
            </Typography>
          </Box>
        )}

        <Box px={offset} mx={-offset} className={classes.itemsWrapper}>
          {sortedItems.map((item, index) => (
            <Box
              width={items.length === 1 ? 1 : undefined}
              key={item.id}
              className={classes.item}
            >
              <UncheckedVisitsCarouselCard
                datetime={item.modified}
                doctor={getUserFullName(item.signers[0])}
                disabled={!!index}
                onClick={handleCardClick(item.id)}
              />
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
);

export default UncheckedVisitsCarousel;
