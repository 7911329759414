import React, { useMemo } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import {
  ICardexItemState,
  IConflictItem,
  IOrderAction,
} from '../../../types/order';
import { makeStyles, Theme, Button, Box, Typography } from '@material-ui/core';
import { ReactComponent as ArrowRightSecondary } from '../../../Assets/icons/arrow_right_secondary.svg';
import DrawerPage from '../../DrawerPage';
import PageHeader from '../../PageHeader/PageHeader';
import Task from '../Cardex/Task';
import PageWrapper from '../../PageWrapper';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  drawerToggler: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 14,
    border: '2px solid #a8c8ff',
  },
  itemsNum: {
    marginLeft: 8,
    backgroundColor: '#ea23231a',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    minWidth: 20,
    padding: '3px 6px 0',
    borderRadius: 10,
    ...theme.typography.subtitle2,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    padding: 24,
  },
  item: {
    textAlign: 'left',
  },
  itemDescription: {
    '& span': {
      fontWeight: 500,
    },
  },
  taskWrapper: {
    margin: '12px 0 32px',
    padding: '4px 12px',
    border: '2px solid #a8c8ff',
    borderRadius: 4,
  },
  submitButton: {
    marginTop: 'auto',
  },
}));

type IProps = RouteComponentProps & {
  items: IConflictItem[];
  onConfirmOmitting: () => void;
};

const ReviewConflicts: React.FC<IProps> = ({
  items,
  onConfirmOmitting,
  history,
  location,
  match,
}) => {
  const classes = useStyles();
  const open = useMemo(() => `${match.url}/conflicts` === location.pathname, [
    match.url,
    location.pathname,
  ]);

  const toggleDrawer = (state: boolean) => {
    if (state) {
      history.push(`${location.pathname}/conflicts`);
    } else {
      history.goBack();
    }
  };

  const handleConfirm = () => {
    toggleDrawer(false);
    onConfirmOmitting();
  };

  function renderConflictItem(key: number, item: IConflictItem) {
    return (
      item.order.action && (
        <Box key={key} className={classes.item}>
          <Typography className={classes.itemDescription}>
            The task you are{' '}
            <span>
              {item.order.action && presentParticipleForms[item.order.action]}
            </span>{' '}
            (shown below) {conflictState[item.state]}, so this line will be
            omitted from the draft.
          </Typography>
          <Box className={classes.taskWrapper}>
            <Task
              item={{
                task: item.order.task,
                state: mapActionToState[item.order.action],
              }}
            />
          </Box>
        </Box>
      )
    );
  }

  return (
    <>
      <Button
        fullWidth
        color='secondary'
        onClick={() => toggleDrawer(true)}
        className={classes.drawerToggler}
      >
        <Box className='flex-center'>
          <Typography component='span' className='mediumWeight'>
            Review conflicts
          </Typography>
          <Box className={classes.itemsNum}>{items.length}</Box>
        </Box>
        <ArrowRightSecondary />
      </Button>

      <DrawerPage open={open}>
        <PageHeader
          desktopBackButton
          showPatientInfo={false}
          title='Review conflicts'
        />
        <PageWrapper>
          <Box className={classes.content}>
            {items.map((item, idx) => renderConflictItem(idx, item))}

            <Button
              fullWidth
              variant='contained'
              color='primary'
              className={clsx('mediumPadding', classes.submitButton)}
              onClick={handleConfirm}
            >
              Ok, return to the draft
            </Button>
          </Box>
        </PageWrapper>
      </DrawerPage>
    </>
  );
};

export default withRouter(ReviewConflicts);

const mapActionToState: Record<
  Exclude<IOrderAction, null>,
  ICardexItemState
> = {
  add: 'active',
  dc: 'dc',
  hold: 'hold',
  unhold: 'active',
  change: 'changed',
};

const presentParticipleForms = {
  add: 'adding',
  dc: 'discontinuing',
  hold: 'holding',
  unhold: 'unholding',
  change: 'changing',
};

const conflictState = {
  1: (
    <>
      <span>already exists</span> in the cardex
    </>
  ),
  2: (
    <>
      <span>does not exist</span> anymore due to the cardex changes
    </>
  ),
  3: (
    <>
      <span>has already been held</span> due to the cardex changes
    </>
  ),
  4: (
    <>
      <span>has already been unheld</span> due to the cardex changes
    </>
  ),
  5: (
    <>
      <span>has been changed</span> due to the cardex changes
    </>
  ),
};
