import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  makeStyles,
  Box,
  IconButton,
} from '@material-ui/core';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import Cropper from 'react-cropper';
import Compressor from 'compressorjs';
import ResponsiveDrawer from '../ResponsiveDrawer/ResponsiveDrawer';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SWITCH_CAMERA from '../../Assets/switch-camera.png';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../action/generalActions';
import { BlueButton } from '../shared';
const useStyles = makeStyles((theme) => ({
  root: {},
  menuIcon: {
    minWidth: 35,
  },
  cropperContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  cropperAction: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    textAlign: 'right',
  },
  switchCamera: {
    position: 'absolute',
    bottom: 15,
    right: '1rem',
    width: 75,
    height: 75,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    '& img': {
      width: 23,
    },
  },

  [theme.breakpoints.down('xs')]: {
    switchCamera: {
      bottom: 23,
    },
  },
}));

export default function UploadMenu({
  open,
  anchorEl,
  onClose,
  handleImageUpload,
}) {
  const onMount = useRef(true);
  const classes = useStyles();
  const cropper = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [useCamera, setUseCamera] = useState(false);
  const [facingMode, setFacingMode] = useState(FACING_MODES.ENVIRONMENT);
  const [multipleCamera, setMultipleCamera] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices.enumerateDevices().then((sources) => {
        const videoSources = sources.filter(
          (source) => source.kind === 'videoinput'
        );
        if (videoSources.length > 1) {
          setMultipleCamera(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (open) return;
    setIsSubmitting(false);
    setUseCamera(false);
    setFacingMode(FACING_MODES.ENVIRONMENT);
    setMultipleCamera(false);
    setImageUrl('');
  }, [open]);

  useEffect(() => {
    if (!imageUrl) return;
    setUseCamera(false);
  }, [imageUrl]);

  useEffect(() => {
    if (onMount.current) {
      onMount.current = false;
      return;
    }
    if (useCamera) return;
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          if (track.readyState === 'live' && track.kind === 'video')
            track.stop();
        });
      })
      .catch((err) => console.log(err));
  }, [useCamera]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = () => setImageUrl(fileReader.result);
    fileReader.readAsDataURL(file);
  };

  const handleSwitchCamera = () => {
    if (navigator.mediaDevices.getSupportedConstraints().facingMode) {
      if (facingMode === FACING_MODES.ENVIRONMENT) {
        setFacingMode(FACING_MODES.USER);
      } else {
        setFacingMode(FACING_MODES.ENVIRONMENT);
      }
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    cropper.current.getCroppedCanvas().toBlob(async (blob) => {
      new Compressor(blob, {
        quality: 0.6,
        convertSize: 3000000,
        async success(compressedImage) {
          handleImageUpload(compressedImage);
          setImageUrl('');
          setUseCamera(false);
          setIsSubmitting(false);
          onClose();
        },
        error(error) {
          setIsSubmitting(false);
          console.log(error.message);
          dispatch(addMessage(error.message, 300));
        },
      });
    });
  };
  const handleSelectCamera = () => {
    if (!navigator.mediaDevices) {
      return;
    }
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          stream.removeTrack(track);
          track.stop();
        });
      })
      .then(() => {
        setUseCamera(true);
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addMessage('Please first allow us to access your camera', 400)
        );
      });
  };

  return (
    <Fragment>
      <Menu
        open={open && !imageUrl && !useCamera}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <MenuItem component='label'>
          <ListItemIcon className={classes.menuIcon}>
            <InsertDriveFileIcon fontSize='small' />
          </ListItemIcon>
          <Typography>From Gallery</Typography>
          <input
            type='file'
            hidden
            accept={'image/png, image/jpeg'}
            name='image'
            onChange={handleImageChange}
          />
        </MenuItem>
        <MenuItem onClick={() => handleSelectCamera()}>
          <ListItemIcon className={classes.menuIcon}>
            <CameraAltIcon fontSize='small' />
          </ListItemIcon>
          <Typography>Use Camera</Typography>
        </MenuItem>
      </Menu>

      {useCamera && (
        <ResponsiveDrawer
          settingClass={true}
          defaultGoBackAction={false}
          handleClose={async () => {
            await onClose();
            setUseCamera(false);
          }}
          content={
            <Fragment>
              <Camera
                isImageMirror={false}
                isDisplayStartCameraError={false}
                idealFacingMode={facingMode}
                onTakePhoto={(uri) => setImageUrl(uri)}
              />
              {multipleCamera ? (
                <IconButton
                  className={classes.switchCamera}
                  onClick={handleSwitchCamera}
                >
                  <img src={SWITCH_CAMERA} alt='switch-camera' />
                </IconButton>
              ) : null}
            </Fragment>
          }
        />
      )}

      {imageUrl && (
        <ResponsiveDrawer
          title={'Upload Image'}
          settingClass={true}
          defaultGoBackAction={false}
          handleClose={async () => {
            if (!isSubmitting) {
              await onClose();
              setImageUrl('');
            }
          }}
          content={
            <Box className={classes.cropperContent}>
              <Cropper
                style={{
                  height: 400,
                  width: '100%',
                  overflow: 'hidden',
                }}
                viewMode={2}
                dragMode={'none'}
                guides={false}
                background={true}
                scalable={false}
                cropBoxResizable={true}
                src={imageUrl}
                ref={cropper}
              />
              <Box className={classes.cropperAction}>
                <BlueButton
                  className='BlueButton'
                  text={isSubmitting ? 'Compressing...' : 'Upload'}
                  disabled={isSubmitting}
                  handleClick={handleSubmit}
                />
              </Box>
            </Box>
          }
        />
      )}
    </Fragment>
  );
}
