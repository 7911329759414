import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
const styles = {
  ContentLoader: {
    lineHeight: '60vh',
    verticalAlign: 'middle',
    margin: 'auto',
    top: '47%',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function ContentLoader(props) {
  const { style, height } = props;
  return (
    <div style={style} className={props.classes.ContentLoader}>
      <CircularProgress size={height ? height : '40px'} />
    </div>
  );
}

export default withStyles(styles)(ContentLoader);
