import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import { CheckRtl } from '../../../Utilities/CheckLang';

type IProps = TypographyProps & { ltrClasses?: string; rtlClasses?: string };

const AutoDirTypography: React.FC<IProps> = ({
  className,
  rtlClasses,
  ltrClasses,
  children,
  ...rest
}) => {
  return (
    <Typography
      className={clsx(
        CheckRtl(children?.toString() ?? '')
          ? rtlClasses
            ? rtlClasses
            : 'farsiFont rtl text-right'
          : ltrClasses
          ? ltrClasses
          : 'ltr text-left',
        className
      )}
      {...rest}
    >
      {children}
    </Typography>
  );
};

export default AutoDirTypography;
