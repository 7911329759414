import { Delete, Get, GetBlob, Post } from './apiController';

export const logOut = async (data) => await Post('/user/logout', data);

export const login = async (data) => await Post('/user/login', data);

export const demandVerification = async (data) => await Post('/user/demand_verification', data);

export const reauthenticate = async (data) => await Post('/user/reauth', data);

export const getUserCurrentData = async (data) => await Get('/user/info');

export const resetPassword = async (data) => await Post('/user/password', data);

export const notifRegistration = async (data) =>
  await Post('/notification/register', data);

// user signature
export const getUserSignature = async () =>
  await GetBlob('/user/signature');
export const updateUserSignature = async (data) =>
  await Post('/user/signature', data);
export const deleteUserSignature = async () => await Delete('/user/signature');
export const getSignatureByUserId = async (userId) =>
  await GetBlob(`/user/${userId}/signature`);
