import React from 'react';
import { IOrder, isOtherTask } from '../../../../types/order';
import { Grid, TextField } from '@material-ui/core';
import OrderTimingField from '../Fields/Timing';
import { CheckRtl } from '../../../../Utilities/CheckLang';

interface IProps {
  defaultOrder: IOrder;
  onOrderChange: (order: IOrder) => void;
}

const OtherFormContent: React.FC<IProps> = ({
  defaultOrder,
  onOrderChange,
}) => {
  if (!isOtherTask(defaultOrder.task)) return null;

  const handleTextFieldChange = (name: 'description') => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isOtherTask(defaultOrder.task)) {
      const newOrder = {
        ...defaultOrder,
        task: {
          ...defaultOrder.task,
          [name]: event.target.value,
        },
      };

      onOrderChange(newOrder);
    }
  };

  const handleTimingFieldChange = (value: string, errors: any[]) => {
    if (isOtherTask(defaultOrder.task)) {
      let newTask = { ...defaultOrder.task };
      newTask = {
        ...newTask,
        timing: {
          raw: value,
          schedules: [],
          errors: errors,
        },
      };
      onOrderChange({ ...defaultOrder, task: newTask });
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          placeholder='Description'
          value={defaultOrder.task.description}
          onChange={handleTextFieldChange('description')}
          autoFocus
          inputProps={{
            style: {
              direction: CheckRtl(defaultOrder.task.description)
                ? 'rtl'
                : 'ltr',
            },
            className: CheckRtl(defaultOrder.task.description)
              ? 'farsiFont'
              : '',
          }}
        />
      </Grid>
      <Grid item xs={9} md={10}>
        <OrderTimingField
          value={defaultOrder.task.timing?.raw}
          onChange={handleTimingFieldChange}
        />
      </Grid>
    </Grid>
  );
};

export default OtherFormContent;
