import React, { useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  withStyles,
  makeStyles,
  Box,
  FormControlLabel,
  IconButton,
  Menu as MuiMenu,
  MenuItem,
  RadioGroup,
  Typography,
  Radio,
} from '@material-ui/core';
import MENU_ICON from '../../../../Assets/icons/vuesax_linear_more_grey.svg';

const CARDEX_VIEW_MODES = [
  { label: 'Detailed View', value: 'detailed' },
  { label: 'Compact View', value: 'compact' },
];

const Menu = withStyles(() => ({
  paper: {
    boxShadow: '0px 4px 12px 0px #70727a29',
    border: '1px solid #ccd2e066',
  },
}))(MuiMenu);

const useStyles = makeStyles((theme) => ({
  menuItem: {
    minHeight: 0,
    padding: 0,
  },
  formControlLabel: {
    width: '100%',
    padding: '0 16px 0 9px',
    margin: 0,

    '& span:last-child': {
      ...theme.typography.button,
      color: theme.palette.grey[900],
      textTransform: 'none',
    },
  },
}));

export interface CardexTypeIState {
  cardexType?: 'head' | 'last_checked' | 'history';
  viewMode?: 'compact' | 'detailed';
}

const LastCardexTitle = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<CardexTypeIState>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

  const currentViewMode = useMemo(
    () => location.state?.viewMode || 'detailed',
    [location.state?.viewMode]
  );

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleChangeViewMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === currentViewMode) return;
    history.replace(location.pathname, {
      ...location.state,
      viewMode: event.target.value,
    });
    handleCloseMenu();
  };

  return (
    <Box display='flex' width={1}>
      <Box width={1}>
        <Typography
          variant='h5'
          align='left'
          className='darkPrimaryColor semiBoldWeight'
        >
          Last cardex
        </Typography>
      </Box>

      <Box mt={-0.5}>
        <IconButton size='small' onClick={(e) => setAnchorEl(e.currentTarget)}>
          <img height={24} src={MENU_ICON} alt='visit menu' />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <RadioGroup value={currentViewMode} onChange={handleChangeViewMode}>
            {CARDEX_VIEW_MODES.map((type, index) => (
              <MenuItem key={index} className={classes.menuItem}>
                <FormControlLabel
                  value={type.value}
                  control={<Radio color='primary' />}
                  label={type.label}
                  className={classes.formControlLabel}
                />
              </MenuItem>
            ))}
          </RadioGroup>
        </Menu>
      </Box>
    </Box>
  );
};

export default LastCardexTitle;
