function pickKeys(
  dataObj: { [key: string]: any },
  keys: string[]
): { [key: string]: any } {
  const result: { [key: string]: any } = {};

  for (const key of keys) {
    result[key] = dataObj[key];
  }

  return result;
}

export default pickKeys;
