import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import useCheckMobileScreen from '../../../Hook/useCheckMobileScreen';
import { getFullDate } from '../../../Utilities/jalaliDate';
import MainDialogContainer from '../../MainDialogContainer/MainDialogContainer';
import { BlueButton } from '../../shared';
import CustomModal from '../../shared/CustomModal';
import DatePicker from '../../shared/Inputs/DatePicker';
import RadioButton from '../../shared/Inputs/RadioButton';
import { DateObject } from 'react-multi-date-picker';
import useFilterToPrint from './useFilterToPrint';

const styles = makeStyles({
  datePickerWrapper: {
    display: 'flex',
    gap: '15px',
  },
  container: {
    fontSize: '14px',
    padding: ({ isMobile }) => isMobile && '30px 24px 12px 24px',
    textAlign: 'left',
    direction: 'ltr',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: ({ isMobile }) => !isMobile && '40vh',
  },
  item: {
    marginBottom: '12px',
  },
  label: {
    lineHeight: '3',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    gap: '15px',
    '& > button': {
      margin: 0,
    },
  },
});
const signatureOptions = [
  {
    key: 0,
    title: 'Print with signatures',
    value: 'true',
  },
  {
    key: 1,
    title: 'Print without signatures',
    value: 'false',
  },
];
const repliesOptions = [
  {
    key: 0,
    title: 'In app replied',
    value: 'true',
  },
  {
    key: 1,
    title: 'All consults',
    value: 'false',
  },
];
const RenderPrintOptions = ({
  handleClose,
  handlePrint,
  pending,
  printableConsults,
  isMultiConsults,
  setFilteredResult,
  haveSignaturesAccess,
}) => {
  const isMobile = useCheckMobileScreen();
  const [isWithSignature, setIsWithSignature] = useState(true);
  const [isInAppReplied, setIsInAppReplied] = useState(false);
  const [date, setDate] = useState({ from: '', to: '' });
  const { minimumDate, maximumDate } = useFilterToPrint({
    printableConsults,
    date,
    isInAppReplied,
    setFilteredResult,
  });
  const classes = styles({ isMobile });
  const handleSignatureChange = ({ target: { value } }) => {
    setIsWithSignature(value === 'true' ? true : false);
  };
  const handleFilterReplied = ({ target: { value } }) => {
    setIsInAppReplied(value === 'true' ? true : false);
  };
  const minDate = getFullDate(minimumDate);
  const maxDate = getFullDate(maximumDate);
  const currentDate = {
    year: minimumDate.getFullYear(),
    month: minimumDate.getMonth() + 1,
    day: minimumDate.getDate(),
  };
  return (
    <div className={classes.container}>
      <div className={classes.item}>
        {haveSignaturesAccess && (
          <>
            <label className={classes.label}>Filter by signature:</label>
            <RadioButton
              options={signatureOptions}
              value={isWithSignature.toString()}
              handleChange={handleSignatureChange}
            />
          </>
        )}
      </div>
      {isMultiConsults ? (
        <>
          <div className={classes.item}>
            <label className={classes.label}>Filter by reply:</label>
            <RadioButton
              options={repliesOptions}
              value={isInAppReplied.toString()}
              handleChange={handleFilterReplied}
            />
          </div>
          <div className={classes.item}>
            <label className={classes.label}>Filter by date:</label>
            <div className={classes.datePickerWrapper}>
              <DatePicker
                label='From date'
                value={date.from}
                fullWidth
                setValue={(val) =>
                  setDate((prev) => ({ from: val, to: prev.to }))
                }
                minDate={minDate}
                maxDate={maxDate}
                currentDate={
                  new DateObject({
                    year: currentDate.year,
                    month: currentDate.month,
                    day: currentDate.day,
                  })
                }
              />
              <DatePicker
                label='To date'
                setValue={(val) =>
                  setDate((prev) => ({ from: prev.from, to: val }))
                }
                value={date.to}
                fullWidth
                minDate={minDate}
                maxDate={maxDate}
                currentDate={
                  new DateObject({
                    year: currentDate.year,
                    month: currentDate.month,
                    day: currentDate.day,
                  })
                }
              />
            </div>
          </div>
        </>
      ) : null}
      <div className={classes.buttonsContainer}>
        <BlueButton
          className='BlueButton'
          loader={pending}
          text={'print'}
          handleClick={() => handlePrint(isWithSignature)}
        />
        <BlueButton
          className='whiteButton'
          text={'Cancel'}
          handleClick={handleClose}
        />
      </div>
    </div>
  );
};
const PrintOptions = ({
  open,
  pending,
  handleClose,
  handlePrint,
  printableConsults,
  isMultiConsults,
  setFilteredResult,
  haveSignaturesAccess,
}) => {
  const isMobile = useCheckMobileScreen();
  const Options = () => (
    <RenderPrintOptions
      handleClose={handleClose}
      printableConsults={printableConsults}
      handlePrint={handlePrint}
      pending={pending}
      isMultiConsults={isMultiConsults}
      setFilteredResult={setFilteredResult}
      haveSignaturesAccess={haveSignaturesAccess}
    />
  );

  return (
    <>
      {isMobile ? (
        <MainDialogContainer
          open={open}
          dialogContent={Options()}
          handleClose={handleClose}
        />
      ) : (
        <CustomModal open={open} handleClose={handleClose}>
          {Options()}
        </CustomModal>
      )}
    </>
  );
};
export default PrintOptions;
