import { Delete, Get, Post, Put } from './apiController';
import { createURLSearchParams } from './helper';

export const getAllConsults = async (params) =>
  await Get(`/consult?${createURLSearchParams(params)}`);
export const getAllPatientConsults = async (profileId) =>
  await Get(`/patient/${profileId}/consult`, null);
export const getConsultDetail = async (consultId) =>
  Get(`/consult/${consultId}`);
export const createPatientConsult = async (profileId, consult) =>
  await Post(`/patient/${profileId}/consult`, consult);
export const createPatientConsultDraft = async (profileId, consult) =>
  await Post(`/patient/${profileId}/consult/draft`, consult);
export const updateConsultRequest = async (consultId, consult) =>
  await Put(`/consult/${consultId}/request`, consult);
export const updateConsultDraftAsRequest = async (consultId, consult) =>
  await Post(`/consult/${consultId}/request`, consult);
export const updateConsultDraft = async (consultId, consult) =>
  await Put(`/consult/${consultId}/draft`, consult);
export const deleteConsult = async (consultId) =>
  await Delete(`/consult/${consultId}`);

export const replyConsult = async (consultId, reply) =>
  await Post(`/consult/${consultId}/reply`, reply);
export const createDraftReplyConsult = async (consultId, reply) =>
  await Put(`/consult/${consultId}/draft_reply`, reply);
export const confirmRepliedConsultRequest = async (consultId) =>
  await Post(`/consult/${consultId}/confirm`);

export const getPatientIllness = async (profileId) =>
  await Get(`/patient/${profileId}/consult/illness`);
export const getPatientLastProblem = async (profileId) =>
  await Get(`/patient/${profileId}/consult/problem_list/last`);
// consult notification configuration
export const getNotificationConfiguration = async () =>
  await Get('/notification/configuration');

export const updateNotificationConfiguration = async (config) =>
  await Put('/notification/configuration', config);
