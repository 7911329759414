/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useEffect } from 'react';
import {
  ICardexAppendix,
  ITask,
  ITaskExtension,
} from '../../../../types/order';
import { connect, RootStateOrAny } from 'react-redux';
import { CheckRtl } from '../../../../Utilities/CheckLang';
import { stringifyTaskObject } from '../../../../Utilities/order';
import {
  Typography,
  makeStyles,
  withStyles,
  Drawer as MuiDrawer,
  Button,
  Grid,
  IconButton,
  TextField,
} from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../Assets/icon_close.svg';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  timeAdornment: {
    marginRight: 8,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const Drawer = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '70vw',
      left: '30vw !important',

      '& .MuiBackdrop-root': {
        left: '30vw',
      },
    },
  },
  paper: {
    padding: '24px 16px 8px',
    border: `2px solid ${theme.palette.grey[500]}`,
    borderBottom: 'none',
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,

    [theme.breakpoints.up('md')]: {
      marginLeft: '30vw',
      width: '70vw',
    },
  },
}))(MuiDrawer);

interface IProps {
  open?: boolean;
  task?: ITask | null;
  cardexAppendix: ICardexAppendix | null;
  onClose?: () => void;
  onSubmit?: (values: ITaskExtension) => void;
}

const TimeNoteDrawer: React.FC<IProps> = ({
  open,
  task,
  cardexAppendix,
  onClose = () => {},
  onSubmit = () => {},
}) => {
  const classes = useStyles();
  const [values, setValues] = useState<ITaskExtension>({
    timetable: '',
    annotation: '',
  });

  useEffect(() => {
    const uuid = task?.uuid;
    if (uuid) {
      setValues({
        timetable: cardexAppendix?.task_extensions?.[uuid]?.timetable ?? '',
        annotation: cardexAppendix?.task_extensions?.[uuid]?.annotation ?? '',
      });
    }
  }, [task, cardexAppendix]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(values);
  };

  const handleDiscard = () => {
    onClose();
  };

  return (
    <Drawer open={open} onClose={onClose} anchor='bottom'>
      <form onSubmit={handleFormSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.header}>
            <Typography variant='h6'>
              {task && stringifyTaskObject(task)}
            </Typography>

            <IconButton size='small'>
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              placeholder='8, 20, ...'
              name='timetable'
              value={values.timetable}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <Typography className={classes.timeAdornment}>
                    Time:
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              placeholder='Add note...'
              name='annotation'
              value={values.annotation}
              onChange={handleInputChange}
              inputProps={{
                style: {
                  direction: CheckRtl(values.annotation) ? 'rtl' : 'ltr',
                },
                className: CheckRtl(values.annotation) ? 'farsiFont' : '',
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.footer}>
            <Button onClick={handleDiscard}>Discard</Button>
            <Button type='submit' color='primary'>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

export default connect((state: RootStateOrAny) => ({
  cardexAppendix: state.order.cardexAppendix,
}))(TimeNoteDrawer);
