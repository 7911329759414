import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import List from '@material-ui/core/List';
import { connect } from 'react-redux';
import PatientCard from '../PatientCard/PatientCard';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  MenuItem,
  Select,
  InputBase,
  FormControl,
  InputLabel,
  Button,
  Box,
} from '@material-ui/core';
import {
  setSection,
  getAllWardsPatients,
  addPageNumber,
  getPinnedPatientsBySection,
} from '../../action/actions';
import { getAllConsults } from '../../action/consultAction';
import { getUserCurrentData } from '../../action/userAction';
import { getConfiguration } from '../../action/generalActions';
import SearchAndSettingBar from '../SearchAndSettingBar/SearchAndSettingBar';
import { ContentLoader, NoContent } from '../shared';
import { bindActionCreators } from 'redux';

import Loader from 'react-loader-spinner';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import ReloadPage from '../ReloadPage/ReloadPage';
import { PropTypes } from 'prop-types';
import ResponsiveDrawer from '../ResponsiveDrawer/ResponsiveDrawer';
import WardContainer from '../WardContainer/WardContainer';
import AddIcon from '@material-ui/icons/Add';
import PatientsWithUncheckedVisitCheckbox from './PatientsWithUncheckedVisitCheckbox';
import GoToResponsibilityServicesSettingBanner from './GoToResponsibilityServicesSettingBanner';
import ChatPlusIcon from '../../Assets/icons/chat_plus.svg';
import './MainDrawer.scss';

export const PAGINATE_DEFAULT_LIMIT =
  window.env.REACT_APP_PAGINATE_DEFAULT_LIMIT;

const styles = {
  tabRoot: {
    height: '2.5rem',
    padding: '0.5rem 0',
    textTransform: 'capitalize',
    fontFamily: 'Poppins',
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
    color: '#A7A7A7',
  },
  listRoot: {
    padding: '0',
    height: '100%',
  },
  pinOption: {
    padding: '0',
  },
  pinOptionCollapsed: {
    marginTop: '12px',
    padding: '0',
  },
  collapsedImg: {
    cursor: 'pointer',
  },
  chevronIcon: {
    transform: 'rotate(180deg)',
    cursor: 'pointer',
  },
  pinOptionContainer: {
    width: '100%',
    margin: '0.55rem 0.75rem',
    padding: '5px',
    borderRadius: '6px',
    backgroundColor: '#e3e7e9',
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: '.8em',
    cursor: 'pointer',
    display: 'flex',
  },
  domainSelectContainer: {
    display: 'flex',
    flex: '1',
    fontSize: '.9em',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: '#fff',
    justifyContent: 'space-between',
    paddingInlineEnd: '20px',
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  domainSelect: {
    fontSize: '.9em',
    padding: '0',
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  domainSelectInput: {
    flex: '1',
    '& svg': {
      display: 'none',
    },
  },
  domainSelectLabel: {
    position: 'relative',
    display: 'inline-block',
    fontSize: '.9em',
    transform: 'none',
    paddingInlineEnd: '10px',
  },
  domainFormControl: {
    flexFlow: 'row wrap',
    alignItems: 'center',
    width: '100%',
  },
};

class DrawerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      activePage: 1,
      pageNumber: 1,
      selectedTab: 0,
      pinCollapsed: false,
      tabs: [
        { label: 'Rotation', value: 0 },
        { label: 'Shift', value: 1 },
        { label: 'Consult', value: 2 },
      ],
      selectedDomain: 'all',
      openDrawer: {
        ward: false,
        shift: false,
        service: false,
      },
      wardFilter: {
        domain: 'all',
        id: null,
      },
      consultFilter: {
        domain: 'all',
        id: null,
      },
      filterWithUncheckedVisit: false,
    };
  }
  domainOptions = [
    { id: 'all', label: 'All' },
    { id: 'ward', label: 'Ward' },
    { id: 'service', label: 'Service' },
  ];
  consultDomainOptions = [
    { id: 'all', label: 'All' },
    { id: 'patientService', label: 'Requester Service' },
    { id: 'consultantService', label: 'Consultant Service' },
    { id: 'ward', label: 'Patient Ward' },
  ];
  async goToResponsibilityServicesSettingIfNeeded() {
    var userData;
    const userDataResponse = await this.props.getUserCurrentData();
    userData = userDataResponse && userDataResponse.status == 200 ? userDataResponse.data : undefined;
    if (userData === undefined && this.props.userData) {
      userData = this.props.userData;
    }
    if (userData !== undefined && !Array.isArray(userData.responsibility_services)) {
      this.props.history.push('/setting');  // this prevent soft-lock by redirections on pressing back button
      this.props.history.push('/setting/section/responsibility_services');
    }
  }

  async componentDidMount() {
    this.goToResponsibilityServicesSettingIfNeeded();
    await this.setSavedFilter();
    const section = this.props.section;
    if (section === 'consult') {
      await this.setState({
        selectedTab: 2,
      });
      this.props.getAllConsults(...this.getParams());
    } else if (section === 'shift') {
      await this.setState({
        selectedTab: 1,
      });
      this.props.getAllWardsPatients(...this.getParams());
      this.props.getPinnedPatientsBySection('shift');
    } else if (section === 'ward') {
      this.props.getAllWardsPatients(...this.getParams());
      this.props.getPinnedPatientsBySection('ward');
    } else {
      this.props.history.push('/patient');
    }

    if (this.props.standaloneMode === undefined) {
      this.props.getConfiguration();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (this.props.location.state?.refreshData &&
        prevProps.location.state?.refreshData !==
          this.props.location.state?.refreshData) ||
      this.state.filterWithUncheckedVisit !== prevState.filterWithUncheckedVisit
    ) {
      if (this.props.section === 'consult') {
        this.handlePatientConsult();
      } else if (this.props.section === 'shift') {
        this.handleShiftPatients();
      } else if (this.props.section === 'ward') {
        this.handleWardPatients();
      }
    }
  }

  async setSavedFilter() {
    const consultFilter = this.getFilterFromStorage('consult-filter');
    if (consultFilter) {
      await this.setState({
        consultFilter,
        selectedDomain: consultFilter?.domain,
      });
    }
    const wardFilter = this.getFilterFromStorage('ward-filter');
    if (wardFilter) {
      await this.setState({
        wardFilter: wardFilter,
        selectedDomain: wardFilter.domain,
      });
    }
  }
  getFilterFromStorage(filterName) {
    const savedFilter = localStorage.getItem(filterName);
    const filter = savedFilter && JSON.parse(savedFilter);
    return filter;
  }
  handleHideSearchBarAsScrollDown(scrollHeight) {
    const searchMode = this.props.searchQuery;
    if (scrollHeight > 0) {
      if (this.state.prevScrollPosition > scrollHeight) {
        // On scroll up
        document.getElementById('SearchAndSettingContainer').style.top = '0';
        if (searchMode) {
          document.getElementById('AppbarForTabs').style.marginTop = '55px';
          document.getElementById('scrollableElement').style.height =
            window.innerHeight - 55 + 'px';
        } else {
          document.getElementById('AppbarForTabs').style.marginTop = '46px';
          document.getElementById('scrollableElement').style.height =
            window.innerHeight - 94 + 'px';
        }
      } else {
        // On scroll down
        document.getElementById('AppbarForTabs').style.marginTop = '0';
        if (searchMode) {
          document.getElementById('SearchAndSettingContainer').style.top =
            '-55px';
          document.getElementById('scrollableElement').style.height =
            window.innerHeight + 'px';
        } else {
          document.getElementById('SearchAndSettingContainer').style.top =
            '-50px';
          document.getElementById('scrollableElement').style.height =
            window.innerHeight - 48 + 'px';
        }
      }
      this.setState({ prevScrollPosition: scrollHeight });
    }
  }

  async handleShiftPatients() {
    await this.props.addPageNumber(1);
    this.setState({
      activePage: 1,
      pageNumber: 1,
    });
    await this.props.getPinnedPatientsBySection('shift');
    await this.props.getAllWardsPatients('shift');
  }
  async handlePatientConsult() {
    await this.props.addPageNumber(1);
    this.setState({
      activePage: 1,
      pageNumber: 1,
    });
    const params = this.getParams();
    await this.props.getAllConsults(...params);
    this.scrollToTopOfDrawer();
  }
  async handleWardPatients() {
    await this.props.addPageNumber(1);
    this.setState({
      activePage: 1,
      pageNumber: 1,
    });
    await this.props.getPinnedPatientsBySection('ward');
    const params = this.getParams();
    this.props.getAllWardsPatients(...params);
    this.scrollToTopOfDrawer();
  }

  async onChangingSearchQuery() {
    this.props.addPageNumber(1);
    this.setState({ activePage: 1, pageNumber: 1 });
  }

  async handleOnScroll(e) {
    if (this.props.mobileMedia) {
      this.handleHideSearchBarAsScrollDown(e.target.scrollTop);
    }
    if (this.props.networkError === true) return;
    if (this.props.patientLoader) return;
    if (
      (this.state.selectedTab === 2 && this.props.noMoreConsultFetch) ||
      (this.state.selectedTab !== 2 && this.props.noMoreHttpFetch)
    ) {
      return;
    }
    if (
      e.target.scrollHeight - e.target.clientHeight <=
      Math.round(e.target.scrollTop) + 3
    ) {
      await this.setState({
        pageNumber: this.state.pageNumber + 1,
      });

      if (this.state.selectedTab === 2) {
        this.loadMoreConsult();
      } else {
        this.loadMorePatient();
      }
    }
  }
  async loadMorePatient() {
    const params = this.getParams();
    await this.props.getAllWardsPatients(...params);
  }
  async loadMoreConsult() {
    const params = this.getParams();
    await this.props.getAllConsults(...params);
  }
  renderConsultSection() {
    return this.props.consults.length ? (
      this.props.consults.map((consult) => (
        <PatientCard
          key={consult.id}
          patient={consult}
          cardName='consult'
        />
      ))
    ) : (
      <NoContent width={'65%'} text={'There is no consult here.'} />
    );
  }
  renderWardPatientSection() {
    let filteredId = [];
    if (this.props.searchQuery) {
      filteredId = [];
    } else if (this.props.pinnedPatients?.ward?.length) {
      filteredId = this.props.pinnedPatients?.ward.map((ward) => ward.id);
    }
    return this.props.wardPatients.length ? (
      this.props.wardPatients
        .filter((patient) => !filteredId.includes(patient.profile_id))
        .map((patient) => (
          <PatientCard
            key={patient.admission_id}
            patient={patient}
            cardName='ward'
            scrollToTopOfDrawer={this.scrollToTopOfDrawer}
          />
        ))
    ) : (
      <div>
        <NoContent width={'65%'} text={'There is no patient here'} />
      </div>
    );
  }
  renderPinnedPatientsOfWard() {
    return (
      this.props.pinnedPatients?.ward?.length &&
      this.props.pinnedPatients?.ward.map((ward) => (
        <PatientCard
          key={ward.patient.admission_id}
          patient={ward.patient}
          cardName='ward'
          pinSection={true}
          pinned='true'
        />
      ))
    );
  }
  renderPinnedPatientsOfShift() {
    return (
      this.props.pinnedPatients?.shift?.length &&
      this.props.pinnedPatients?.shift.map((shift) => (
        <PatientCard
          key={shift.patient.admission_id}
          patient={shift.patient}
          cardName='shift'
          pinSection={true}
          pinned='true'
        />
      ))
    );
  }
  renderShiftPatientSection() {
    let filteredId = [];
    if (this.props.searchQuery) {
      filteredId = [];
    } else if (this.props.pinnedPatients?.shift?.length) {
      filteredId = this.props.pinnedPatients?.shift.map((shift) => shift.id);
    }
    return this.props.shiftPatients.length ? (
      this.props.shiftPatients
        .filter((patient) => !filteredId.includes(patient.profile_id))
        .map((patient) => (
          <PatientCard
            key={patient.admission_id}
            patient={patient}
            cardName='shift'
            scrollToTopOfDrawer={this.scrollToTopOfDrawer}
          />
        ))
    ) : (
      <div>
        <NoContent width={'65%'} text={'There is no patient here'} />
      </div>
    );
  }
  handleTabChange = (event, selectedTab) => {
    this.setState({ selectedTab });
    if (selectedTab === 0) {
      this.setState((prevState) => ({
        selectedDomain: prevState.wardFilter?.domain,
      }));
    } else if (selectedTab === 2) {
      this.setState((prevState) => ({
        selectedDomain: prevState.consultFilter?.domain,
      }));
    }
    let sectionName =
      selectedTab === 2 ? 'consult' : selectedTab === 1 ? 'shift' : 'ward';
    if (selectedTab === 0) {
      this.handleWardPatients();
    } else if (selectedTab === 1) {
      this.handleShiftPatients();
    } else if (selectedTab === 2) {
      this.handlePatientConsult();
    }
    this.props.setSection(sectionName);
  };

  togglePinnedPatient() {
    this.setState((prevState) => {
      return { pinCollapsed: !prevState.pinCollapsed };
    });
  }
  enableLoading() {
    if (this.state.selectedTab === 2) {
      this.loadMoreConsult();
    } else {
      this.loadMorePatient();
    }
  }
  scrollToTopOfDrawer() {
    const elem = document.getElementById('scrollableElement');
    if (elem) {
      elem.scrollTop = 0;
    }
  }
  renderMainSection() {
    const { selectedTab } = this.state;
    if (selectedTab === 0 && this.props.wardPatients) {
      return this.renderWardPatientSection();
    } else if (selectedTab === 1 && this.props.shiftPatients) {
      return this.renderShiftPatientSection();
    } else if (selectedTab === 2 && this.props.consults) {
      return this.renderConsultSection();
    }
  }

  renderDomainFilter() {
    const { classes } = this.props;
    if (!window.env.REACT_APP_MAIN_PAGE_FILTER) return;

    if (this.state.selectedTab === 0 || this.state.selectedTab === 2) {
      return (
        <ListItem>
          <FormControl className={classes.domainFormControl}>
            <InputLabel className={classes.domainSelectLabel}>
              Filter:
            </InputLabel>
            <div className={classes.domainSelectContainer}>
              <Select
                value={this.state.selectedDomain}
                classes={{ root: classes.domainSelect }}
                input={<InputBase className={classes.domainSelectInput} />}
              >
                {(this.state.selectedTab === 0
                  ? this.domainOptions
                  : this.consultDomainOptions
                ).map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    onClick={() => this.handleDomainChange(option.id)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <div
                style={{ cursor: 'pointer' }}
                onClick={this.handleDomainValueChange}
              >
                {' '}
                {this.state.selectedTab === 0
                  ? this.state.wardFilter?.name
                  : this.state.consultFilter?.name}
              </div>
            </div>
          </FormControl>
        </ListItem>
      );
    }
  }
  renderNewPatientButton() {
    const isRotationTab = this.state.selectedTab === 0;
    const isSearchMode = !!this.props.searchQuery;
    const isCurrentPageIsCreatePatient =
      this.props.location.pathname === '/patient/create';

    return this.props.standaloneMode &&
      this.props.canManagePatients &&
      isRotationTab &&
      !isSearchMode ? (
      <Box px={1.5} pb={1.1} pt={1}>
        <Button
          fullWidth
          color='primary'
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => this.props.history.push('/patient/create')}
          disabled={isCurrentPageIsCreatePatient}
        >
          New patient
        </Button>
      </Box>
    ) : null;
  }
  renderDomainDrawer() {
    const { ward, shift, service } = this.state.openDrawer;
    if (ward || shift || service) {
      return (
        <ResponsiveDrawer
          defaultGoBackAction={false}
          title={this.state.domainTitle}
          settingClass={true}
          handleClose={() => this.handleClosingDrawer()}
          content={
            <WardContainer
              openShiftDrawer={shift}
              openWardDrawer={ward}
              openServiceDrawer={service}
              filter={true}
              handleFilter={(e) => this.handleFilterChanged(e)}
              handleClose={() => this.handleClosingDrawer()}
            />
          }
        />
      );
    }
  }

  handleDomainChange = (value) => {
    let title;
    let domain = value;

    let tab = 'patients';
    if (this.state.selectedTab === 2) {
      tab = 'consults';
      if (value === 'all') {
        domain = 'all';
      } else if (value === 'patientService' || value === 'consultantService') {
        domain = 'service';
      } else {
        domain = 'ward';
      }
      this.setState((prevState) => ({
        consultFilter: {
          domain: value,
          id: domain === 'all' ? null : prevState.consultFilter?.id,
        },
        selectedDomain: value,
      }));
      if (domain === 'all') {
        this.filterConsult(null, null, null);
        this.savedFilterToStorage('consult-filter', {
          domain: 'all',
          id: null,
        });
        return;
      }
    } else {
      this.setState((prevState) => ({
        wardFilter: {
          domain: value,
          id: domain === 'all' ? null : prevState.wardFilter?.id,
        },
        selectedDomain: value,
      }));
      if (domain === 'all') {
        this.filterWardPatient(null, null, null);
        this.savedFilterToStorage('ward-filter', {
          domain: 'all',
          id: null,
        });
        return;
      }
    }
    if (domain === 'shift') {
      title = 'Select shift to filter ' + tab;
    } else if (domain === 'ward') {
      title = 'Select ward to filter ' + tab;
    } else {
      title = 'Select service to filter ' + tab;
    }
    this.setState((prevState) => ({
      selectedDomain: value === 'all' ? 'all' : prevState.selectedDomain,
      openDrawer: {
        ...{
          ward: false,
          shift: false,
          service: false,
        },
        [domain]: true,
      },
      domainTitle: title,
    }));
  };

  handleDomainValueChange = () => {
    let value;
    if (this.state.selectedTab === 2) {
      value = this.state.consultFilter.domain;
    } else {
      value = this.state.wardFilter.domain;
    }

    this.handleDomainChange(value);
  };
  handleFilterChanged = ({ id, name }) => {
    const { selectedTab } = this.state;
    this.setState({
      openDrawer: {
        ward: false,
        shift: false,
        service: false,
      },
    });
    if (selectedTab === 0) {
      const { domain } = this.state.wardFilter;
      this.setState({ selectedDomain: domain });
      this.savedFilterToStorage('ward-filter', {
        domain: this.state.wardFilter?.domain,
        name,
        id,
      });
      this.setState((prevState) => ({
        wardFilter: {
          domain: prevState.wardFilter?.domain,
          name,
          id,
        },
      }));
      if (domain === 'ward') {
        this.filterWardPatient(id, null);
      } else if (domain === 'service') {
        this.filterWardPatient(null, id);
      }
    } else {
      const { domain } = this.state.consultFilter;
      this.setState({ selectedDomain: domain });
      this.savedFilterToStorage('consult-filter', {
        domain: this.state.consultFilter?.domain,
        name,
        id,
      });
      if (domain === 'consultantService') {
        this.filterConsult(null, id, null);
      } else if (domain === 'patientService') {
        this.filterConsult(id, null, null);
      } else if (domain === 'ward') {
        this.filterConsult(null, null, id);
      }
      this.setState((prevState) => ({
        consultFilter: {
          domain: prevState.consultFilter?.domain,
          name,
          id,
        },
      }));
    }
  };
  filterWardPatient(wardId, serviceId) {
    this.props.getAllWardsPatients(
      this.state.selectedTab === 0 ? 'rotation' : 'shift',
      this.props.searchQuery,
      0,
      PAGINATE_DEFAULT_LIMIT,
      wardId,
      serviceId
    );
  }
  async filterConsult(
    effective_patient_service_id,
    consultant_service_id,
    effective_ward_id
  ) {
    await this.props.getAllConsults(
      0,
      PAGINATE_DEFAULT_LIMIT,
      effective_patient_service_id,
      consultant_service_id,
      effective_ward_id
    );
  }
  handleClosingDrawer = () => {
    this.setState({
      openDrawer: {
        ward: false,
        shift: false,
        service: false,
      },
    });
  };
  getParams = () => {
    const {
      selectedTab,
      wardFilter,
      consultFilter,
      filterWithUncheckedVisit,
    } = this.state;

    let params = [];
    if (selectedTab === 0) {
      params = [
        this.state.selectedTab === 0
          ? 'rotation'
          : this.state.selectedTab === 1
          ? 'shift'
          : null,
        this.props.searchQuery,
        (this.state.pageNumber - 1) * PAGINATE_DEFAULT_LIMIT,
        PAGINATE_DEFAULT_LIMIT,
      ];
      if (wardFilter && wardFilter.id) {
        if (wardFilter.domain === 'service') {
          params = [...params, null, wardFilter.id, filterWithUncheckedVisit];
        } else if (wardFilter.domain === 'ward') {
          params = [
            ...params,
            wardFilter.id,
            undefined,
            filterWithUncheckedVisit,
          ];
        }
      } else {
        params = [...params, null, undefined, filterWithUncheckedVisit];
      }
    } else if (selectedTab === 2) {
      params = [
        (this.state.pageNumber - 1) * PAGINATE_DEFAULT_LIMIT,
        PAGINATE_DEFAULT_LIMIT,
      ];
      if (consultFilter && consultFilter.id) {
        if (consultFilter.domain === 'patientService') {
          params = [...params, consultFilter.id];
        } else if (consultFilter.domain === 'consultantService') {
          params = [...params, null, consultFilter.id];
        } else if (consultFilter.domain === 'ward') {
          params = [...params, null, null, consultFilter.id];
        }
      }
    } else {
      params = [
        this.state.selectedTab === 0
          ? 'rotation'
          : this.state.selectedTab === 1
          ? 'shift'
          : null,
        this.props.searchQuery,
        (this.state.pageNumber - 1) * PAGINATE_DEFAULT_LIMIT,
        PAGINATE_DEFAULT_LIMIT,
        undefined,
        undefined,
        filterWithUncheckedVisit,
      ];
    }
    return params;
  };
  savedFilterToStorage(filterName, filter) {
    if (!filter) {
      return;
    }
    localStorage.setItem(filterName, JSON.stringify(filter));
  }
  handleChangePatientWithUncheckedVisitCheckbox = (checked) => {
    this.setState({
      ...this.state,
      activePage: 1,
      pageNumber: 1,
      filterWithUncheckedVisit: checked,
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <div className='HomeDrawerContainer'>
        <SearchAndSettingBar
          style={{
            borderBottom: this.props.searchQuery
              ? '1px solid #ccccd2e0'
              : 'none',
          }}
          section={this.state.selectedTab === 1 ? 'shift' : 'rotation'}
          onChangingSearchQuery={() => this.onChangingSearchQuery()}
          removePaginationNumber={() => {
            this.setState({ activePage: 1, pageNumber: 1 });
            this.props.addPageNumber(1);
          }}
        />
        <AppBar
          position='sticky'
          color='default'
          className='AppbarForTabs'
          id='AppbarForTabs'
          style={{ marginTop: this.props.searchQuery ? 55 : 46 }}
        >
          <Tabs
            className={'TabsContainer'}
            value={this.state.selectedTab}
            onChange={this.handleTabChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            style={this.props.searchQuery ? { display: 'none' } : {}}
          >
            {this.state.tabs.map((tab, index) => (
              <Tab
                key={index}
                className={`${
                  this.state.selectedTab === tab.value
                    ? 'SelectedTab'
                    : 'NoneSelectedTab'
                } ${classes.tabRoot}`}
                label={tab.label}
              />
            ))}
          </Tabs>
        </AppBar>

        <div
          className='PatientScrollableListContainer'
          id='scrollableElement'
          onScroll={(e) => this.handleOnScroll(e)}
          style={{
            height: this.props.searchQuery
              ? 'calc(100% - 55px)'
              : 'calc(100% - 94px)',
          }}
        >
          <Box pt={1.2} />

          {this.state.selectedTab === 2 ? null : (
            <Box p={2} pb={1}>
              <PatientsWithUncheckedVisitCheckbox
                onChange={this.handleChangePatientWithUncheckedVisitCheckbox}
              />
            </Box>
          )}

          {this.renderNewPatientButton()}
          <List className={classes.listRoot}>
            {this.renderDomainFilter()}
            {this.props.section === 'consult' && (
              <GoToResponsibilityServicesSettingBanner
                onClick={() => this.props.history.push('/setting/section/responsibility_services')}
                responsibility_services={this.props.userData?.responsibility_services}
              />
            )}
            {this.props.pinnedPatients &&
            //!this.state.pinCollapsed &&
            !this.props.searchQuery
              ? this.state.selectedTab === 0 &&
                this.props.pinnedPatients.ward?.length
                ? this.renderPinnedPatientsOfWard()
                : this.state.selectedTab === 1 &&
                  this.props.pinnedPatients.shift?.length
                ? this.renderPinnedPatientsOfShift()
                : ''
              : ''}
            {/* {this.props.pinnedPatients &&
                !this.props.searchQuery &&
              ((this.state.ward && this.props.pinnedPatients.ward?.length) ||
                (this.state.shift &&
                  this.props.pinnedPatients.shift?.length)) ? (
                <ListItem
                  className={
                    this.state.pinCollapsed
                      ? classes.pinOptionCollapsed
                      : classes.pinOption
                  }
                  onClick={() => this.togglePinnedPatient()}
                >
                  <div className={classes.pinOptionContainer}>
                    <div className='hoverable'>
                      {this.state.pinCollapsed ? 'show pinned' : ''}
                    </div>

                    <img
                      className={
                        this.state.pinCollapsed
                          ? classes.collapsedImg
                          : classes.chevronIcon
                      }
                      alt='icon'
                      height={20}
                      width={20}
                      src={CHEVRON_Icon}
                    />
                  </div>
                </ListItem>
              ) : (
                ''
              )} */}
            {this.props.patientLoader && this.state.pageNumber === 1 ? (
              <ContentLoader style={{ lineHeight: '30vh' }} />
            ) : (
              this.renderMainSection()
            )}
            {this.props.networkError && (
              <ListItem>
                <ReloadPage reloadPage={() => this.enableLoading()} />
              </ListItem>
            )}
          </List>

          {this.props.patientLoader && this.state.pageNumber !== 1 && (
            <div className='text-center'>
              <Loader
                type='ThreeDots'
                color='#386fff'
                height={'13px'}
                width={'50px'}
              />
            </div>
          )}
        </div>
        {this.renderDomainDrawer()}
        <div className= 'chatPlusButton hoverable'>
          <div className='inner-container'>
              <img
              alt='Contact SynApps'
              className='ChatPlus'
              height={100}
              src={ChatPlusIcon}
              onClick={() =>  window.open('https://docs.google.com/forms/d/e/1FAIpQLSeVxCqYPfByUBPROGdXtrp3dX10uL6SCMHqNAMy05YBsSgzng/viewform?usp=sf_link', '_blank')}
              />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    section: state.patientsReducer.section,
    shiftPatients: state.patientsReducer.shiftPatients,
    wardPatients: state.patientsReducer.wardPatients,
    patientLoader: state.patientsReducer.patientLoader,
    networkError: state.patientsReducer.networkError,
    searchQuery: state.patientsReducer.searchQuery,
    pageNumber: state.patientsReducer.pageNumber,
    consults: state.consultReducer.consults,
    noMoreHttpFetch: state.patientsReducer.noMoreHttpFetch,
    noMoreConsultFetch: state.consultReducer.noMoreHttpFetch,
    pinnedPatients: state.patientsReducer.pinnedPatients,
    standaloneMode: state.generalReducers?.config?.standalone_mode,
    userData: state.userReducer.userData,
    canManagePatients: !!state.userReducer?.userData?.rank?.can_manage_patients,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSection,
      getAllWardsPatients,
      addPageNumber,
      getAllConsults,
      getPinnedPatientsBySection,
      getConfiguration,
      getUserCurrentData,
    },
    dispatch
  );
DrawerList.propTypes = {
  classes: PropTypes.object,
  patientLoader: PropTypes.any,
  networkError: PropTypes.any,
  searchQuery: PropTypes.string,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(DrawerList)));
