import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { CheckRtl } from '../../../Utilities/CheckLang';
import { deepEqual } from '../../../Utilities/equal';

const EditField = withStyles((theme) => ({
  root: {
    width: '100%',

    '& label': {
      fontFamily: 'Poppins',
      color: theme.palette.text.secondary,
      fontSize: 14,
      lineHeight: '21px',
      transform: 'scale(1)',
    },

    '& .MuiInput-underline:before': {
      borderBottomColor: '#ebebeb',
    },

    '& .MuiInput-underline:hover:before': {
      borderBottom: '1px solid #ebebeb !important',
    },
  },
}))(TextField);

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 24px 0',
    backgroundColor: '#ffffff',
  },
  item: {
    marginBottom: 30,

    '&.pl-29': {
      paddingLeft: '29px',
    },

    '&.pr-29': {
      paddingRight: '29px',
    },

    '&.footer': {
      margin: '0 0 14px 0',
      textAlign: 'right',
    },
  },
}));

const initialValues = {
  note: '',
  height: '',
  weight: '',
};

function ProfileEditableContent({ profile, onSubmit }) {
  const classes = useStyles();
  const [prevValues, setPrevValues] = useState(initialValues);
  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    const newValues = {
      note: profile.note || '',
      height: profile.height || '',
      weight: profile.weight || '',
    };
    setValues(newValues);
    setPrevValues(newValues);
  }, [profile]);

  const handleDiscard = () => {
    setValues(prevValues);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (event.target.type === 'number') {
      setValues({ ...values, [name]: parseFloat(value) });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const height = parseFloat(values.height);
    const weight = parseFloat(values.weight);
    const note = String(values.note).trim() || null;

    onSubmit({
      height,
      weight,
      note,
    }).catch((err) => console.log(err));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        justifyContent='space-between'
        className={classes.container}
      >
        <Grid item xs={12} className={classes.item}>
          <EditField
            multiline
            label='Note'
            value={values.note}
            name='note'
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: { direction: CheckRtl(values.note) ? 'rtl' : 'ltr' },
              className: CheckRtl(values.note) ? 'farsiFont' : '',
            }}
          />
        </Grid>
        <Grid item xs={6} className={`${classes.item} pr-29`}>
          <EditField
            label='Height (cm)'
            value={values.height}
            name='height'
            onChange={handleChange}
            type='number'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} className={`${classes.item} pl-29`}>
          <EditField
            label='Weight (kg)'
            value={values.weight}
            name='weight'
            onChange={handleChange}
            type='number'
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {!deepEqual(prevValues, values) && (
          <Grid item xs={12} className={`${classes.item} footer`}>
            <Button onClick={handleDiscard}>Discard</Button>
            <Button type='submit' color='primary'>
              Save
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
}

export default ProfileEditableContent;
