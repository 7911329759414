import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  Theme,
  Button,
  Grid,
  Typography,
  ButtonGroup as MuiButtonGroup,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';

const STATES = [
  { label: 'ترخیص شده', value: true },
  { label: 'بستری', value: false },
];

const ButtonGroup = withStyles((theme: Theme) => ({
  grouped: {
    width: 110,
    maxWidth: '50%',
    border: '2px solid #ccccd2e0',
    whiteSpace: 'nowrap',

    '&.active': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
}))(MuiButtonGroup);

const useStyle = makeStyles({
  root: {
    backgroundColor: '#fff',
    padding: '20px 24px 12px',
    textAlign: 'right',
  },
});

interface IProps {
  defaultValue?: boolean;
  onSubmit?: ({ dismissed }: {dismissed:boolean}) => Promise<AxiosResponse<any>>;
}

const DismissedPatient: React.FC<IProps> = ({
  defaultValue = false,
  onSubmit,
}) => {
  const classes = useStyle();
  const [dismissed, setDismissed] = useState(defaultValue);
  const [showActions, setShowActions] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setDismissed(defaultValue);
  }, [defaultValue]);

  const handleChangeDismissed = (value: boolean) => () => {
    if (isSubmitting) return;
    setDismissed(value);
    setShowActions(true);
  };

  const handleDiscard = () => {
    setDismissed(defaultValue);
    setShowActions(false);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!onSubmit) return;
    console.log(dismissed);
    await setIsSubmitting(true);
    onSubmit({ dismissed })
      .then(() => {
        setShowActions(false);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography className='fa-text-body1 font-weight-medium'>
            وضعیت بیمار
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ButtonGroup>
            {STATES.map((state, index) => (
              <Button
                key={index}
                className={clsx(
                  state.value === dismissed && 'active',
                  'farsiFont'
                )}
                onClick={handleChangeDismissed(state.value)}
              >
                {state.label}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
        {showActions && (
          <Grid item xs={12}>
            <Button onClick={handleDiscard} disabled={isSubmitting}>
              Discard
            </Button>
            <Button type='submit' color='primary' disabled={isSubmitting}>
              {isSubmitting ? (
                <CircularProgress color='inherit' size={21} />
              ) : (
                'Save'
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default DismissedPatient;
