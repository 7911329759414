import React from 'react';
import { AbstractAuthWall, styles } from './AbstractAuthWall';
import { login } from '../../action/userAction';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { Loader } from '../../Components/shared';
import { Link } from 'react-router-dom';

class Login extends AbstractAuthWall {
  async handleSubmit(values) {
    this.setState({ loader: true });
    const body = {
      username: values.username,
      password: values.password,
      device_id: this.state.deviceId,
      force_logout_other_sessions: true,
    };
    const response = await this.props.login(body);
    this.setState({ loader: false });
    if (response) {
      if(!localStorage.getItem("showNPSModal")){
        localStorage.setItem("showNPSModal","true")
      }
      this.props.history.replace('/namespace');
    }
  }

  formButtons() {
    return (
      <>
        <div>
          <button type='submit' className='AuthWallMainButton' disabled={this.props.submitting}>
            {this.state.loader ? (
              <Loader color='#ffffff' height={'30px'} />
            ) : (
              'LOGIN'
            )}
          </button>
        </div>
        <div>
          <button className='AuthWallLink' onClick={async () => (this.props.history.replace('/forgot_password'))}>
            Forgot Password?
          </button>
        </div>
      </>
    );
  }
}

const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.username) {
      errors.username = 'Required';
    }
    return errors;
  };
  
  const warn = (values) => {
    const warnings = {};
    return warnings;
  };
  
const mapStateToProps = (state) => {
    return {
        auth: state.authReducer.auth,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ login }, dispatch);
  
  
export default reduxForm({
  form: 'syncValidation', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn, // <--- warning function given to redux-form
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login)));
