import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addMessage } from '../../../../action/generalActions';
import { createTaskTemplate } from '../../../../api/order';
import { IBaseTaskTemplate, ITask, IVisit } from '../../../../types/order';
import PageHeader from '../../../PageHeader/PageHeader';
import VisitTemplateForm from './Form';

interface IProps {
  visit: IVisit | null;
  tasks?: ITask[];
  onRefreshList?: () => void;
}

interface IPathParams {
  patientId: string;
  visitId: string;
}

const VisitTemplateCreate: React.FC<IProps> = ({
  visit,
  tasks,
  onRefreshList,
}) => {
  const history = useHistory();
  const { patientId, visitId } = useParams<IPathParams>();
  const dispatch = useDispatch();

  const defaultFormValues = useMemo(
    () => ({
      tasks: tasks ?? visit?.orders.map((o) => o.task) ?? [],
      title: '',
      description: '',
      public: true,
    }),
    [visit, tasks]
  );

  const handleSubmit = async (data: IBaseTaskTemplate) => {
    return createTaskTemplate(data)
      .then(() => {
        history.replace(
          visitId
            ? `/patient/${patientId}/order/visit/${visitId}/templates`
            : `/patient/${patientId}/order/create-visit/templates`
        );
        if (onRefreshList) onRefreshList();
        dispatch(addMessage('Template created successfully!', 200));
      })
      .catch((err) => {
        console.log(err);
        dispatch(addMessage('An error occurred on created successfully!', 400));
      });
  };

  const handleDiscard = () => {
    history.goBack();
  };

  return (
    <>
      <PageHeader
        desktopBackButton
        showPatientInfo={false}
        title='New visit template'
      />

      <VisitTemplateForm
        defaultValues={defaultFormValues}
        onSubmit={handleSubmit}
        onDiscard={handleDiscard}
      />
    </>
  );
};

export default VisitTemplateCreate;
