import React from 'react';
import EnvelopeIcon from '../../../../Assets/icons8-envelope-96.png';
import WorldIcon from '../../../../Assets/icons8-world-96.png';

function AboutUs() {
  return (
    <div style={{ padding: '0px 20px', textAlign: 'left' }}>
      <div
        className='EachRowOnAboutUs'
        style={{
          padding: '15px 0px',
        }}
      >
        If you have any idea or skill to make medicine easier, faster and
        smarter; There is no time to spare, Contact us!
      </div>
      <div
        className='flex EachRowOnAboutUs'
        style={{
          padding: '15px 0px',
        }}
      >
        <img height='25px' src={EnvelopeIcon} alt='Phone Icon' />
        <div style={{ paddingLeft: '8px' }} className='flexC'>
          <a href='mailto:info@synappsgroup.com'>info@synappsgroup.com</a>
        </div>
      </div>
      <div
        className='flex EachRowOnAboutUs'
        style={{
          padding: '0px 0px 15px',
        }}
      >
        <img height='25px' src={WorldIcon} alt='Phone Icon' />
        <div style={{ paddingLeft: '8px' }} className='flexC'>
          <a href='https://synappsgroup.com/' rel="noreferrer" target='_blank'>
            www.synappsgroup.com
          </a>
        </div>
      </div>
    </div>
  );
}
export default AboutUs;
