import React, { Component } from 'react';
import { Field } from 'redux-form';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';
import { Loader } from '../../Components/shared';
import Fingerprint2 from 'fingerprintjs2';
import './AbstractAuthWall.css';
const theme = createTheme({
  typography: {
    fontFamily: 'Poppins',
  },
  overrides: {
    MuiTypography: {
      root: {
        color: '#ff0000',
      },
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: '#386fff',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1em',
        '&$focused': {
          color: '#4A90E2',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '1em',
        fontFamily: 'Poppins',
        '&$focused': {
          fontSize: '1em',
          fontFamily: 'Poppins',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: '2px solid #EBEBEB',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: 'none !important',
        },
        '&:before': {
          border: 'none !important',
        },
        '&:after': {
          border: 'none !important',
        },
        '&:hover': {
          borderBottom: 'none !important',
        },
      },
    },
  },
});
export const styles = {
  root: {
    position: 'relative',
    background: 'white',
    boxShadow: 'none',
    color: 'black',
    width: '250px',
  },
  flex: {
    flex: 1,
  },
  centerContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    justifyItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
  },
  redirect: {
    paddingTop: '23px',
    color: 'gray',
    fontSize: '.8em',
  },
};
var defType = null;
const renderField = ({
  input,
  label,
  type,
  classes,
  InputProps,
  meta: { touched, error, warning },
}) => (
  <div>
    <div classes={classes}>
      <TextField
        classes={classes}
        label={label}
        {...input}
        type={defType ? defType : type}
        style={
          touched && error
            ? { border: 'none', borderBottom: 'solid #e54400' }
            : { border: 'none', borderBottom: 'solid #EBEBEB' }
        }
        InputProps={InputProps}
      />
      {touched ? (
        error ? (
          <div
            className='flexC red text-left'
            style={{
              paddingTop: '5px',
              paddingBottom: '7px',
              fontSize: '14px',
            }}
          >
            {error}
          </div>
        ) : warning ? (
          <div>{warning}</div>
        ) : (
          <div style={{ minHeight: '30px', width: '100%' }}></div>
        )
      ) : (
        <div style={{ minHeight: '30px', width: '100%' }}></div>
      )}
    </div>
  </div>
);

function mkCredentialTextField(renderField, classes) {
  function wrapCredentialTextField (props) {
      return (
        <Field
          component={renderField}
          classes={{ root: classes.root }}
          {...props}
        />
      );
  };
  return wrapCredentialTextField;
};

export class AbstractAuthWall extends Component {
  state = {
    deviceId: null,
    showPassword: false,
  };
  componentDidMount() {
    if (JSON.parse(localStorage.getItem('myData'))) {
      this.props.history.replace('/patient');
    }
    const cookies = new Cookies();
    const deviceId = cookies.get('dev_id');
    if (deviceId) {
      this.setState({ deviceId: deviceId });
    } else {
      this.setFingerPrint();
    }
  }
  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };
  setFingerPrint() {
    setTimeout(() => {
      var options = {
        NOT_AVAILABLE: 'not available',
        ERROR: 'error',
        EXCLUDED: 'excluded',
        synchronous: true,
      };
      Fingerprint2.getV18(options, (result) => {
        const device_id = result;
        this.setState({ deviceId: device_id });
        const cookies = new Cookies();
        cookies.set('dev_id', device_id, { path: '/' });
      });
    }, 500);
  }

  abovePasswordFormFields(renderField, classes) {
    return (
      <></>
    );
  }

  formButtons() {
    throw new Error('not implemented');
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, classes } = this.props;
    // const CredentialTextField = mkCredentialTextField(renderField, classes);
    return (
      <div>
        <div className='AuthWallBackground' />
        <form
          className={classes.centerContainer}
          onSubmit={handleSubmit(this.handleSubmit.bind(this))}
        >
          <div className='AuthWallFormTitle'>{this.props.title || 'Hello and Welcome'}</div>
          <MuiThemeProvider theme={theme}>

            {!this.props.hideUsernameField &&
              <Field
              name='username'
              type='text'
              component={renderField}
              label='Username'
              classes={{ root: classes.root }}
              InputProps={this.props.history.location.state?.constantUsername ? {
                value: this.props.history.location.state.constantUsername,
                disabled: true,
              } : {}}
            />}

            {this.abovePasswordFormFields(renderField, classes)}

            {!this.props.hidePasswordField &&
            <Field
              name='password'
              autoComplete={this.props.autoCompleteNewPassword ? 'new-password' : 'current-passsword'}
              type={this.props.passwordValueAlwaysVisible || this.state.showPassword ? 'text' : 'password'}
              component={renderField}
              classes={{ root: classes.root }}
              label={this.props.passwordLabel || 'Password'}
              InputProps={!this.props.passwordValueAlwaysVisible ? {
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Toggle password visibility'
                      onClick={this.handleClickShowPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              } : {}}
            />}

          </MuiThemeProvider>
          <div>
            {this.formButtons()}
            <div className={classes.redirect}>
              {document.referrer.includes('android-app://org.inteb.inlab') && (
                <a href='inlab://hub'>Need to Change Your Hospital ?</a>
              )}
            </div>
          </div>
        </form>
      </div>
    );
  }
}
