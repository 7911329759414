import * as actionTypes from '../action/actionTypes';
import {
  AddVisits,
  SetDraftVisit,
  GetDraftVisits,
  GetHeadVisit,
  GetParentVisit,
  GetSingleVisit,
  GetVisits,
  SetCardexAppendix,
  GetLastCheckedVisit,
} from '../action/order';
import {
  IDraftVisitSummery,
  IDraftVisit,
  IVisitSummery,
  IVisit,
  ICardexAppendix,
} from '../types/order';

export interface OrderState {
  visit: IVisit | null;
  headVisit: IVisit | null;
  parentVisit: IVisit | null;
  lastCheckedVisit: IVisit | null;
  visits: IVisitSummery[];
  draftVisits: IDraftVisitSummery[];
  draftVisit: IDraftVisit | null;
  cardexAppendix: ICardexAppendix | null;
}

const initialState: OrderState = {
  visit: null,
  headVisit: null,
  parentVisit: null,
  lastCheckedVisit: null,
  visits: [],
  draftVisits: [],
  draftVisit: null,
  cardexAppendix: null,
};

export type OrderAction =
  | GetVisits
  | AddVisits
  | GetDraftVisits
  | SetDraftVisit
  | GetSingleVisit
  | GetHeadVisit
  | GetParentVisit
  | GetLastCheckedVisit
  | SetCardexAppendix;

const orderReducer = (
  state: OrderState = initialState,
  action: OrderAction
): OrderState => {
  switch (action.type) {
    case actionTypes.GET_VISIT: {
      return { ...state, visit: action.visit };
    }

    case actionTypes.GET_HEAD_VISIT: {
      return { ...state, headVisit: action.visit };
    }

    case actionTypes.GET_PARENT_VISIT: {
      return { ...state, parentVisit: action.visit };
    }

    case actionTypes.GET_LAST_CHECKED_VISIT: {
      return { ...state, lastCheckedVisit: action.visit };
    }

    case actionTypes.GET_ALL_VISITS: {
      return { ...state, visits: action.visits };
    }

    case actionTypes.ADD_VISITS: {
      return { ...state, visits: [...state.visits, ...action.visits] };
    }

    case actionTypes.GET_DRAFT_VISITS: {
      return { ...state, draftVisits: action.draftVisits };
    }

    case actionTypes.SET_DRAFT_VISIT: {
      return { ...state, draftVisit: action.draftVisit };
    }

    case actionTypes.SET_CARDEX_APPENDIX: {
      return { ...state, cardexAppendix: action.cardexAppendix };
    }

    default:
      return state;
  }
};

export default orderReducer;
