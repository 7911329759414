import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Grid,
  IconButton,
  Checkbox,
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import NoImage from '../../Assets/no_image_vector.svg';
import AddIcon from '../../Assets/ic_add_dark_24.png';
import TrashIcon from '../../Assets/icon_trash_red.svg';
import Image from './Image';
import ImagePreview from './ImagePreview';
import { AlertDialog } from '../shared';
import UploadMenu from './UploadMenu';
import { renderAutoDirText } from '../../Utilities/CheckLang';
import usePathAction from '../../Hook/usePathAction';
import { getUserFullName } from '../../Utilities/user';

const REACT_APP_HOST_URL = window.env.REACT_APP_HOST_URL ?? '';

const useStyles = makeStyles((theme) => ({
  selectOptions: {
    marginTop: -10,
    marginBottom: 8,
  },
  iconBtn: {
    marginTop: -10,
    marginBottom: 8,
  },
  label: {
    fontSize: 13,
    margin: '12px auto 31px',
  },
  emptyRoot: {
    margin: '1rem auto 2rem',
  },
  container: {
    width: 'calc(100% + 16px)',
    margin: '-8px -8px 0',
  },
  item: {
    padding: '6px !important',
  },
  dialogAction: {
    justifyContent: 'end',
  },
  pinnedDivider: {
    paddingTop: 18,
  },
  lastEdited: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    textAlign: 'left',
    padding: '4px 6px 0',

    '& > *': {
      marginLeft: 10,
    },
  },
}));

function ImageContent({
  basePath,
  className,
  images = [],
  limits,
  uploadQueue = [],
  pinnedImages = {},
  handleImageUpload,
  handleDelete,
  onPin,
  onUnpin,
}) {
  const classes = useStyles();
  const history = useHistory();
  const uploadMenuAnchor = useRef(null);
  const [pathAction, setPathAction] = usePathAction();
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectMode, setSelectMode] = useState(false);
  const [previewImage, setPreviewImage] = useState({});

  const imagePreviewMatch = useRouteMatch(`${basePath}/image/:imageId`);

  const hasImage =
    images.length ||
    Object.values(pinnedImages ? pinnedImages : {}).length ||
    uploadQueue.length;

  useEffect(() => {
    if (selectedImages.length) return;
    setSelectMode(false);
  }, [selectedImages]);

  useEffect(() => {
    if (imagePreviewMatch) {
      if (Object.keys(previewImage).length === 0) {
        console.log('side go back');
        history.goBack();
      }
    }
  }, [imagePreviewMatch]);

  const handleUploadIconClick = () => {
    if (limits !== undefined && images.length >= limits) {
      setPathAction('upload_warning');
    } else {
      setPathAction('upload');
    }
  };

  const handleSelectControl = () => {
    if (selectedImages.length === 0) return;
    if (selectedImages.length === images.length) {
      setSelectedImages([]);
      setSelectMode(false);
    } else {
      setSelectedImages([...images]);
    }
  };

  const toggleImageSelect = (image) => {
    const checked = selectedImages.find((obj) => obj.id === image.id);
    if (checked) {
      const images = selectedImages.filter((obj) => obj.id !== image.id);
      setSelectedImages(images);
    } else {
      setSelectedImages([...selectedImages, image]);
    }
  };

  const handleImageClick = (image) => {
    if (selectMode) {
      toggleImageSelect(image);
    } else {
      history.push(`${basePath}/image/${image.id}`);
      setPreviewImage(image);
    }
  };

  const handleImageLongPress = (image) => {
    if (selectMode) return;
    setSelectedImages([image]);
    setSelectMode(true);
  };

  const handleDeleteConfirm = () => {
    const images = selectedImages.map(({ id }) => ({
      pinned: Boolean(pinnedImages && pinnedImages[id]),
      id,
    }));
    setSelectMode(false);
    handleDelete(images);
    setPathAction('');
  };

  function renderLastEditedBy() {
    let lastSubmitter = {};
    if (images && images.length) {
      lastSubmitter = images.sort((a, b) => b.id - a.id)[0].submitter;
    } else if (pinnedImages && pinnedImages.length) {
      lastSubmitter = Object.values(pinnedImages).sort((a, b) => b.id - a.id)[0]
        .submitter;
    }

    if (!Object.keys(lastSubmitter).length) return null;

    if (!lastSubmitter.first_name || !lastSubmitter.last_name) return null;

    return (
      <Grid item xs={12} className={classes.lastEdited}>
        <Typography color='textSecondary' className='inline'>
          Last edited by
        </Typography>
        <Typography color='textSecondary' className='inline'>
          {renderAutoDirText(getUserFullName(lastSubmitter))}
        </Typography>
      </Grid>
    );
  }

  function renderNoImage() {
    return (
      <Grid item xs={12} className={classes.emptyRoot}>
        <img src={NoImage} alt='no image' />
        <Typography color='secondary' className={classes.label}>
          There is no image
        </Typography>
      </Grid>
    );
  }

  function renderImageGrid(
    image,
    pinned = false,
    blob = false,
    upload = false
  ) {
    const checked = selectedImages.find((obj) => obj.id === image.id);

    return (
      <Grid key={image.id} item xs={4} md={3} lg={2} className={classes.item}>
        <Image
          src={
            blob
              ? URL.createObjectURL(image.blob)
              : REACT_APP_HOST_URL + image.url
          }
          imageData={image}
          selectMode={selectMode}
          checked={Boolean(checked)}
          pinned={pinned}
          upload={upload}
          onClick={() => handleImageClick(image)}
          onLongPress={() => handleImageLongPress(image)}
          onUploadRetry={handleImageUpload}
          onPin={onPin}
          onUnpin={onUnpin}
        />
      </Grid>
    );
  }

  return (
    <Fragment>
      <Grid container className={className}>
        <Grid item xs={12} className={'flex-space-between'}>
          <Typography variant='h5' className='regularWeight'>
            Images
          </Typography>
          {selectMode ? (
            <Box className={classes.selectOptions}>
              <Checkbox
                color='primary'
                checked={selectedImages.length === images.length}
                indeterminate={selectedImages.length !== images.length}
                onClick={handleSelectControl}
              />
              <IconButton
                color='secondary'
                onClick={() => setPathAction('multi_delete')}
              >
                <img src={TrashIcon} height={20} alt='trash icon' />
              </IconButton>
            </Box>
          ) : (
            <div>
              <IconButton
                className={classes.iconBtn}
                component='label'
                onClick={handleUploadIconClick}
                ref={uploadMenuAnchor}
              >
                <img src={AddIcon} height={20} alt='add icon' />
              </IconButton>
              <UploadMenu
                open={pathAction === 'upload'}
                anchorEl={uploadMenuAnchor.current}
                onClose={() => setPathAction('')}
                handleImageUpload={handleImageUpload}
              />
            </div>
          )}
        </Grid>
        {hasImage ? (
          <Grid container className={classes.container}>
            {Object.values(pinnedImages ? pinnedImages : {})
              .sort((a, b) => b.id - a.id)
              .map((image) => renderImageGrid(image, true, true))}

            {Object.keys(pinnedImages ? pinnedImages : {}).length ? (
              <Grid xs={12} className={classes.pinnedDivider} />
            ) : null}

            {uploadQueue
              .sort((a, b) => b.id - a.id)
              .map((image) => renderImageGrid(image, false, true, true))}

            {images
              .sort((a, b) => b.id - a.id)
              .map((image) =>
                pinnedImages && Boolean(pinnedImages[image.id])
                  ? null
                  : renderImageGrid(image)
              )}

            {renderLastEditedBy()}
          </Grid>
        ) : (
          <Grid item xs={12}>
            {renderNoImage()}
          </Grid>
        )}
      </Grid>

      <ImagePreview
        open={
          Boolean(Object.keys(previewImage).length) &&
          Boolean(imagePreviewMatch)
        }
        image={previewImage}
        handleClose={() => {
          setPathAction('');
          history.goBack();
        }}
        handleDelete={() =>
          handleDelete([
            {
              id: previewImage?.id,
              pinned: Boolean(pinnedImages && pinnedImages[previewImage?.id]),
            },
          ])
        }
      />

      <AlertDialog
        open={pathAction === 'multi_delete'}
        handleClose={() => setPathAction('')}
        handleConfirm={handleDeleteConfirm}
        alertText={
          selectedImages.length > 1
            ? 'Are you sure you want to delete these images?'
            : 'Are you sure you want to delete this image?'
        }
        dialogActionProps={{
          className: classes.dialogAction,
        }}
        closeText='Cancel'
        confirmButton
        confirmButtonColor='secondary'
        confirmText='Delete'
      />

      <AlertDialog
        open={pathAction === 'upload_warning'}
        handleClose={() => setPathAction('')}
        alertText={
          'You cannot add more images because you have reached the maximum number of images'
        }
        dialogActionProps={{
          className: classes.dialogAction,
        }}
        closeText='Cancel'
      />
    </Fragment>
  );
}

export default ImageContent;
