import React, { forwardRef } from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { CheckRtl } from '../../../../Utilities/CheckLang';
import clsx from 'clsx';

type IProps = TextFieldProps & { ltrClasses?: string; rtlClasses?: string };

const AutoDirTextField = forwardRef<any, IProps>(
  ({ rtlClasses, ltrClasses, inputProps, ...props }, ref) => (
    <TextField
      inputProps={{
        ...inputProps,
        style: {
          direction: CheckRtl(props.value ?? '') ? 'rtl' : 'ltr',
          ...(inputProps?.style ?? {}),
        },
        className: clsx(
          CheckRtl(props.value ?? '')
            ? rtlClasses
              ? rtlClasses
              : 'farsiFont text-right'
            : ltrClasses
            ? ltrClasses
            : 'text-left',
          inputProps?.className
        ),
      }}
      {...props}
      ref={ref}
    />
  )
);

export default AutoDirTextField;
