import React from 'react';
import {
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from '@material-ui/core';

const styles = makeStyles((theme) => ({
  formControlLabel: {
    width: '100%',
    height: '30px',
    margin: 0,
    '& span:last-child': {
      ...theme.typography.button,
      color: theme.palette.grey[900],
      textTransform: 'none',
    },
  },
  radio: {
    '&$checked': {
      color: '#509ffa',
    },
  },
}));
interface IOptions {
  key: number | string;
  title: string;
  value: string;
}
interface IRadioButton {
  options: IOptions[];
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
}
const RadioButton = ({
  options,
  value,
  defaultValue,
  handleChange,
}: IRadioButton) => {
  const classes = styles();

  return (
    <>
      <RadioGroup
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
      >
        {options.map((item) => (
          <FormControlLabel
            key={item.key}
            value={item.value}
            control={
              <Radio classes={{ root: classes.radio }} color='primary' />
            }
            label={item.title}
            className={classes.formControlLabel}
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default RadioButton;
