import { Get, Post, Put, Delete } from './apiController';
import { createURLSearchParams } from './helper';

export const PAGINATE_DEFAULT_LIMIT =
  window.env.REACT_APP_PAGINATE_DEFAULT_LIMIT || 20;
export const PAGINATE_DEFAULT_OFFSET =
  window.env.REACT_APP_PAGINATE_DEFAULT_OFFSET || 0;

export const ENABLE_RAW_LAB_TEST_API = !!window.env
  .REACT_APP_ENABLE_RAW_LAB_TEST_API;

export const getWardPatientList = async (params) =>
  await Get(`/domain/patients?${createURLSearchParams(params)}`);

export const getPatientRadiologyResult = async (id) =>
  await Get(
    `/patient/${id}/radiology_services/recent?offset=${PAGINATE_DEFAULT_OFFSET}&limit=${PAGINATE_DEFAULT_LIMIT}`
  );
export const getPatientLabResult = async (id) =>
  await Get(
    ENABLE_RAW_LAB_TEST_API
      ? `/patient/${id}/raw_lab_tests/recent?offset=${PAGINATE_DEFAULT_OFFSET}&limit=${PAGINATE_DEFAULT_LIMIT}`
      : `/patient/${id}/lab_tests/recent?offset=${PAGINATE_DEFAULT_OFFSET}&limit=${PAGINATE_DEFAULT_LIMIT}`
  );
export const getPatientProfile = async (id) => await Get(`/patient/${id}`);

export const getWardPatientsById = async (patientIds) =>
  await Get(`/patient/batch?profile_ids=${patientIds}`);

export const triggerNotif = async (data) =>
  await Post('/patient/service/trigger', data);

export const pickShift = async (id) =>
  await Post(`/domain/shift/pick/${id}`, id);
export const pickWard = async (data) =>
  await Post(`/domain/rotation/change/${data.id}`, data.id);
export const pickResponsibilityServices = async (data) =>
  await Put('/service/responsibility', data);

export const editProfile = async (data, profile_id) =>
  await Put(`/patient/${profile_id}`, data);

export const uploadProfileImage = async (data, profile_id) =>
  await Post(`/patient/${profile_id}/image`, data);

export const deleteProfileImage = async (image_id, profile_id) =>
  await Delete(`/patient/${profile_id}/image/${image_id}`);
