import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  addMessage,
  getAllServices,
  getUsersByService,
} from '../../../action/generalActions';
import {
  Box,
  makeStyles,
  Button,
  Typography,
  TextField,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import SelectWithDialog from '../ConsultFormComponents/SelectWithDialog';
import PrioritySelector from '../ConsultFormComponents/PrioritySelector';
import AddIcon from '@material-ui/icons/Add';
import { ICD11SearchDrawer, ICD11SelectedItem } from '../../ICD11';
import { CheckRtl, renderAutoDirText } from '../../../Utilities/CheckLang';
import { getTrimmedText } from '../../../Utilities/textUtil';
import { AlertDialog } from '../../shared';
import usePathAction from '../../../Hook/usePathAction';
import { DiscardModal } from '../ConsultFormComponents';
import clsx from 'clsx';
import { getUserFullName } from '../../../Utilities/user';

const defaultIllnessValue = 'با سلام و احترام \n';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: 24,
  },
  label: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    margin: '20px 0 0',
  },
  value: {
    margin: '8px 0 4px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
  illnessControlButton: {
    color: theme.palette.primary.main,

    '& p': {
      fontWeight: 600,
    },
  },
  inputLabel: {
    color: (value) =>
      value ? theme.palette.text.secondary : theme.palette.text.primary,
    transform: 'scale(1)',
  },
  formDescription: {
    fontFamily: 'IRANYekan',
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.text.secondary,
    direction: 'rtl',
    margin: '40px 0 24px',
    textAlign: 'justify',
  },
}));

function ConsultRequestForm({
  consult = {},
  services,
  users,
  illnesses,
  patientLastProblems,
  patientPrimaryService,
  canChangeServices,
  canSubmitConsult,
  canDraftConsult,
  isEditMode,
  handleSubmit,
  addMessage,
  getAllServices,
  getUsersByService,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { patientId } = useParams();
  // const unblockRef = useRef(null);
  const [pathAction, setPathAction] = usePathAction();
  const [loading, setLoading] = useState({ services: false, users: false });
  const [error, setError] = useState({ services: undefined, users: undefined });
  const [values, setValues] = useState({
    effective_patient_service:
      consult?.effective_patient_service || patientPrimaryService || {},
    consultant_service: consult?.consultant_service || {},
    suggested_consultant: consult?.suggested_consultant || {},
    priority: consult?.priority || 3,
    illness:
      consult?.illness ||
      (illnesses?.length && illnesses[illnesses.length - 1]) ||
      defaultIllnessValue,
    problem_list: consult?.problem_list || patientLastProblems || [],
    cause_of_consult: consult?.cause_of_consult || '',
  });
  const [pending, setPending] = useState('');
  // const [nextNavigationLocation, setNextNavigationLocation] = useState('');
  const [openDiscard, setOpenDiscard] = useState(false);

  const uniqueIllnessesArray = useMemo(() => [...new Set(illnesses || [])], [
    illnesses,
  ]);

  // const blockNavigation = () => {
  //   if (unblockRef.current) {
  //     unblockRef.current();
  //   }
  //   return history.block(function (location) {
  //     console.log(location);
  //     if (!localStorage.getItem('myData')) {
  //       return true;
  //     } else {
  //       console.log('block pathAction');
  //       if (pathAction) {
  //         setBlockedPathAction('');
  //       } else {
  //         if (checkWhetherUserChangeInputs()) {
  //           setOpenDiscard(true);
  //           setNextNavigationLocation(location);
  //         } else {
  //           return true;
  //         }
  //       }
  //       return false;
  //     }
  //   });
  // };

  // unblockRef.current = useMemo(blockNavigation, [values, consult]);

  useEffect(() => {
    fetchFieldsData('services');
  }, []);

  useEffect(() => {
    if (values.consultant_service?.id) {
      fetchFieldsData('users', values.consultant_service.id);
    }
  }, [values.consultant_service]);

  useEffect(() => {
    if (pathAction === 'goBackTrigger') {
      setPathAction('');
      if (checkWhetherUserChangeInputs()) {
        setOpenDiscard(true);
      } else {
        history.goBack();
      }
    }
  }, [pathAction]);

  const fetchFieldsData = async (name, ...params) => {
    await setLoading({ ...loading, [name]: true });
    await setError({ ...error, [name]: undefined });

    let response = {};
    switch (name) {
      case 'services': {
        response = await getAllServices();
        break;
      }
      case 'users': {
        response = await getUsersByService(params);
      }
    }
    await setLoading({ ...loading, [name]: false });
    if (!response.data) {
      await setError({ ...error, [name]: response });
    }
  };

  const setBlockedPathAction = (action) => {
    // unblockRef.current();
    setPathAction(action);
    // unblockRef.current = blockNavigation();
  };

  const handleSelectWithDialogChange = (val, name) => {
    setValues({ ...values, [name]: val });
  };

  const handleConsultPriorityChange = (id) => {
    setValues({ ...values, priority: id });
  };

  const handleTextFieldChange = (event) => {
    if (!event.target?.value) {
      setValues({ ...values, [event.target.name]: '' });
      return;
    }
    const value = event.target.value;
    const numberOfLines = value.split(/\r\n|\r|\n/).length;
    if (numberOfLines > 30 || value.length > 4000) {
      addMessage('The text is too long!', 400);
      return;
    }
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleRemoveSuggestedConsultant = () => {
    setValues({ ...values, suggested_consultant: {} });
  };

  const handleToggleSuggestedConsultantDialog = () => {
    if (values.consultant_service?.id) {
      return true;
    }
    addMessage('Please select the service first', 300);
    return false;
  };

  const handleRemoveICD11 = (problem) => {
    const patientProblems = values.problem_list.filter(
      (item) => problem.theCode !== item.theCode
    );
    setValues({ ...values, problem_list: patientProblems });
  };

  const handleSubmitForm = async (isSaveAsDraft, bypassConfirmation) => {
    if (
      (isSaveAsDraft && !canDraftConsult) ||
      (!isSaveAsDraft && !isEditMode && !canSubmitConsult)
    ) {
      return;
    }
    if (window.env.REACT_APP_CONSULT_DISABLE) {
      addMessage('Consult is not activated for your account.', 400);
      return;
    }
    if (!values.consultant_service?.id) {
      addMessage('Please select a service.', 400);
      return;
    }
    let response = '';
    if (isSaveAsDraft) {
      await setPending('draft');
      try {
        response = await handleSubmit(isSaveAsDraft, getSubmissionObject());
      } catch (err) {
        console.log(err);
      }
      handleAfterSubmitAction(response);
    } else {
      if (bypassConfirmation) {
        await setPending('submit');
        try {
          response = await handleSubmit(isSaveAsDraft, getSubmissionObject());
        } catch (err) {
          console.log(err);
        }
        handleAfterSubmitAction(response);
      } else {
        setBlockedPathAction('confirmSubmit');
      }
    }
  };

  const handleDiscard = () => {
    history.goBack();
    // unblockRef.current();
    // if (this.state.nextNavigationLocation.pathname) {
    //   if (this.state.nextNavigationLocation.pathname === this.props.match.url) {
    //     this.props.history.go(-2);
    //   } else if (
    //     this.state.nextNavigationLocation.pathname === this.state.prevPath
    //   ) {
    //     this.props.history.goBack();
    //   } else {
    //     this.props.history.push(this.state.nextNavigationLocation);
    //   }
    // }
  };

  const handleConfirmSubmit = async () => {
    await setPathAction('');
    handleSubmitForm(false, true);
  };

  const getSubmissionObject = () => {
    let submissionObject = {
      illness:
        values.illness?.trim() === defaultIllnessValue
          ? ''
          : getTrimmedText(values.illness),
      problem_list: values.problem_list,
      cause_of_consult: values.cause_of_consult
        ? getTrimmedText(values.cause_of_consult)
        : '',
      priority: values.priority,
      effective_patient_service_id: values.effective_patient_service?.id,
    };
    if (canChangeServices) {
      submissionObject = {
        ...submissionObject,
        consultant_service_id: values.consultant_service?.id,
        suggested_consultant_id: values.suggested_consultant?.id,
      };
    }
    return submissionObject;
  };

  function checkWhetherUserChangeInputs() {
    if (consult?.id) {
      if (values.suggested_consultant?.id) {
        if (consult.suggested_consultant?.id) {
          if (
            consult.suggested_consultant.id !== values.suggested_consultant?.id
          ) {
            return true;
          }
        } else {
          return true;
        }
      }
      if (consult.consultant_service?.id !== values.consultant_service?.id) {
        return true;
      } else if (consult.priority !== values.priority) {
        return true;
      } else if (consult.cause_of_consult !== values.cause_of_consult) {
        return true;
      } else if (
        consult.illness !== values.illness &&
        getTrimmedText(values.illness) !== defaultIllnessValue
      ) {
        return true;
      } else if (
        consult?.problem_list?.length !== values.problem_list?.length
      ) {
        return true;
      } else {
        const difference = consult?.problem_list?.filter(
          (problem) =>
            !values?.problem_list.some(
              (editedProblem) => editedProblem.nid === problem.nid
            )
        );
        if (difference?.length !== 0) {
          return true;
        }
        return false;
      }
    } else {
      if (values.consultant_service?.id) {
        return true;
      } else if (values.suggested_consultant?.id) {
        return true;
      } else if (values.cause_of_consult) {
        return true;
      } else if (
        illnesses &&
        values.illness !== illnesses[illnesses?.length - 1] &&
        getTrimmedText(values.illness) !== defaultIllnessValue
      ) {
        return true;
      } else {
        const difference = patientLastProblems?.filter(
          (problem) =>
            !values.problem_list.some(
              (editedProblem) => editedProblem.nid === problem.nid
            )
        );
        if (difference && difference.length) {
          return true;
        }
        return false;
      }
    }
  }

  const handleAfterSubmitAction = async (response) => {
    await setPending('');
    if (response && response.status === 200) {
      const section = await localStorage.getItem('section');
      history.replace(`/patient/${patientId}/consult`, { cardName: section });
    }
  };

  const TextValue = ({ text }) => (
    <Typography
      className={clsx(classes.value, CheckRtl(text) ? 'fa-text-body1' : 'mt-0')}
      style={{
        textAlign: CheckRtl(text) ? 'right' : 'left',
      }}
      dir='auto'
    >
      {renderAutoDirText(text)}
    </Typography>
  );

  const PresentIllnessDialogControl = () => (
    <Button size='small' className={classes.illnessControlButton}>
      <Typography variant='body2'>From Previous Illnesses</Typography>
      <AddIcon />
    </Button>
  );

  return (
    <form className={classes.root} onSubmit={(e) => e.preventDefault()}>
      {canChangeServices ? (
        <SelectWithDialog
          loading={loading.services}
          error={error.services}
          onReload={() => fetchFieldsData('services')}
          label={'Requester Service'}
          name='effective_patient_service'
          value={values.effective_patient_service}
          propsObjectForDisplay={['name']}
          dialogProps={{
            items: services,
            UID: 'id',
            hasInputFilter: true,
            inputLabel: 'requester service',
            handleSubmit: (val, name) =>
              handleSelectWithDialogChange(val, name),
          }}
        />
      ) : (
        <Box>
          <Typography className={classes.label}>Requester Service</Typography>
          <TextValue text={consult?.effective_patient_service?.name} />
        </Box>
      )}
      <Box mb={4} />
      {canChangeServices ? (
        <SelectWithDialog
          loading={loading.services}
          error={error.services}
          onReload={() => fetchFieldsData('services')}
          label={'Consultant Service'}
          name='consultant_service'
          value={values.consultant_service}
          propsObjectForDisplay={['name']}
          dialogProps={{
            items: services,
            UID: 'id',
            hasInputFilter: true,
            inputLabel: 'service',
            handleSubmit: (val, name) =>
              handleSelectWithDialogChange(val, name),
          }}
        />
      ) : (
        <Box>
          <Typography className={classes.label}>Consultant Service</Typography>
          <TextValue text={consult?.consultant_service?.name} />
        </Box>
      )}
      <Box mb={4} />
      {canChangeServices ? (
        <SelectWithDialog
          loading={loading.users}
          error={error.users}
          onReload={() => fetchFieldsData('users')}
          label={'Consultant Doctor'}
          name='suggested_consultant'
          value={values.suggested_consultant}
          maxDisplayedItemsSize={20}
          propsObjectForDisplay={['rank.role', 'first_name', 'last_name']}
          removable={true}
          onRemove={handleRemoveSuggestedConsultant}
          onToggleDialog={handleToggleSuggestedConsultantDialog}
          dialogProps={{
            items: users,
            UID: 'id',
            hasInputFilter: true,
            inputLabel: 'receiver',
            handleSubmit: (val, name) =>
              handleSelectWithDialogChange(val, name),
          }}
        />
      ) : (
        <Box>
          <Typography className={classes.label}>Consultant Doctor</Typography>
          <TextValue text={
            `${consult.suggested_consultant?.rank ? consult.suggested_consultant?.rank?.role : ''} ${getUserFullName(consult.suggested_consultant)}`
            } />
        </Box>
      )}

      <Box mb={4} />
      <PrioritySelector
        selectedPriority={values.priority}
        setConsultPriority={(id) => handleConsultPriorityChange(id)}
      />
      <SelectWithDialog
        hasInput
        dialogControlComponent={<PresentIllnessDialogControl />}
        label={'Present Illness'}
        name='illness'
        value={values.illness}
        inputProps={{
          multiline: true,
          onChange: (e) => handleTextFieldChange(e),
        }}
        dialogProps={{
          items: uniqueIllnessesArray,
          UID: null,
          hasInputFilter: false,
          inputLabel: 'illness',
          handleSubmit: (val, name) => handleSelectWithDialogChange(val, name),
        }}
      />
      <Box mb={4} />
      <SelectWithDialog
        label={'Your Differential Diagnosis'}
        name='problem_list'
        renderValue={() =>
          values.problem_list.map((problem) => (
            <ICD11SelectedItem
              key={problem.nid}
              icdIsRemovable={true}
              problem={problem}
              handleRemoveICD11={(problem) => handleRemoveICD11(problem)}
              onClick={(e) => e.stopPropagation()}
            />
          ))
        }
        renderDialog={(open, handleClose) => (
          <ICD11SearchDrawer
            open={open}
            handleClose={handleClose}
            problems={values.problem_list}
            inputLabel='Search ICD11'
            handleSubmit={(items) => {
              handleClose();
              handleSelectWithDialogChange(items, 'problem_list');
            }}
          />
        )}
      />
      <Box mb={4} />
      <TextField
        fullWidth
        label={'Cause of Consult'}
        InputLabelProps={{
          shrink: true,
          className: classes.inputLabel,
        }}
        InputProps={{
          style: {
            direction: CheckRtl(values.cause_of_consult || '') ? 'rtl' : 'ltr',
          },
          className: CheckRtl(values.cause_of_consult || '') ? 'farsiFont' : '',
        }}
        name={'cause_of_consult'}
        value={values.cause_of_consult}
        onChange={handleTextFieldChange}
      />

      <Typography className={classes.formDescription}>
        {isEditMode
          ? 'درخواست‌دهنده مشاوره می‌تواند تا زمانی که پاسخی برای مشاوره ثبت نشده است، متن درخواست مشاوره را ویرایش کند یا حذف نماید.'
          : 'اینترن ها و دانشجویانی که شماره نظام پزشکی ندارند، امکان ارسال مشاوره ندارند و صرفا می‌توانند درخواست مشاوره را به صورت پیش‌نویس ذخیره کنند.'}
      </Typography>

      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {isEditMode ? (
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                className='mediumPadding'
                onClick={() => handleDiscard()}
              >
                Discard Changes
              </Button>
            ) : (
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                className='mediumPadding'
                disabled={Boolean(pending) || !canDraftConsult}
                onClick={() => handleSubmitForm(true)}
              >
                {pending === 'draft' ? (
                  <CircularProgress size={22} />
                ) : (
                  'Save as Draft'
                )}
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              className='mediumPadding'
              disabled={Boolean(pending) || (!canSubmitConsult && !isEditMode)}
              onClick={() => handleSubmitForm(false)}
            >
              {pending === 'submit' ? (
                <CircularProgress size={22} />
              ) : isEditMode ? (
                'Save'
              ) : (
                'Send'
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <AlertDialog
        open={pathAction === 'confirmSubmit'}
        alertText='Are you sure about submitting this consult request?'
        confirmButton={true}
        confirmText='Submit'
        confirmButtonColor='primary'
        closeText='Cancel'
        handleClose={() => setBlockedPathAction('')}
        handleConfirm={handleConfirmSubmit}
      />

      <DiscardModal
        open={openDiscard}
        handleClose={() => setOpenDiscard(false)}
        handleDiscard={handleDiscard}
        handleSubmit={() => handleSubmitForm(true, true)}
        submitText={canChangeServices ? 'Save as Draft' : 'Edit Consult'}
      />
    </form>
  );
}

export default connect(
  (state) => ({
    services: state.generalReducers.services,
    users: state.generalReducers.users,
    illnesses: state.patientsReducer.patient?.illnesses,
    patientLastProblems: state.patientsReducer.patient?.lastProblems,
    canSubmitConsult: Boolean(
      state.userReducer?.userData?.rank?.can_submit_consult_request_reply
    ),
    canDraftConsult: Boolean(
      state.userReducer?.userData?.rank?.can_draft_consult_request_reply
    ),
  }),
  { addMessage, getAllServices, getUsersByService }
)(ConsultRequestForm);
