// General

export const GET_CONFIGURATION = 'GET_CONFIGURATION';

export const UPDATE_PATIENT_PROFILE = 'UPDATE_PATIENT_PROFILE';

export const SET_SECTION = 'SET_SECTION';

export const SET_PAGE_PARAMS = 'SET_PAGE_PARAMS';

export const SET_PINNED_PROFILE_IMAGES = 'SET_PINNED_PROFILE_IMAGES';

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_REMOVE = 'UPLOAD_IMAGE_REMOVE';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';

// Profile

export const GET_PROFILE = 'GET_PROFILE';

///////////////////////////////
//////////// Order ////////////
///////////////////////////////

export const GET_VISIT = 'GET_VISIT';
export const GET_HEAD_VISIT = 'GET_HEAD_VISIT';
export const GET_PARENT_VISIT = 'GET_PARENT_VISIT';
export const GET_LAST_CHECKED_VISIT = 'GET_LAST_CHECKED_VISIT';
export const GET_ALL_VISITS = 'GET_ALL_VISITS';
export const ADD_VISITS = 'ADD_VISITS';
export const ADD_UNCHECKED_VISITS = 'ADD_UNCHECKED_VISITS';
export const GET_DRAFT_VISITS = 'GET_DRAFT_VISITS';
export const SET_DRAFT_VISIT = 'SET_DRAFT_VISIT';
export const SET_CARDEX_APPENDIX = 'SET_CARDEX_APPENDIX';


export const SET_NAMESPACE= 'SET_NAMESPACE';  // TODO: SET_NAMESPACE_ID
export const SET_HOSPITAL_NAME= 'SET_HOSPITAL_NAME';  // TODO: SET_NAMESPACE_TITLE
