import { PostICD11 } from './apiController';
export const BASE_URL_ICD11 = window.env.REACT_APP_ICD_API_URL;

export const searchICD11 = async (
  linearizationname,
  releaseId,
  q,
  includeKeywordResult,
  flatResults,
  useFlexisearch,
  includePostcoordination
) => {
  return await PostICD11(
    `${BASE_URL_ICD11}/icd/release/11/${releaseId}/${linearizationname}/search?q=${q}&includeKeywordResult=${includeKeywordResult}&flatResults=${flatResults}&useFlexisearch=${useFlexisearch}&includePostcoordination=${includePostcoordination}`,
    null
  );
};
