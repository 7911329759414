import React, { useState, useEffect } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import { addMessage } from '../../../action/generalActions';
import {
  getVisit,
  getRecentVisits,
  checkVisit,
  getCardexAppendix,
} from '../../../action/order';
import { useParams, useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  ICardexAppendix,
  ICardexGroup,
  ICardexItem,
  ITask,
  IVisit,
  IVisitSummery,
} from '../../../types/order';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from '@material-ui/core';
import PageHeader from '../../PageHeader/PageHeader';
import PageWrapper from '../../PageWrapper';
import CardexState from '../Cardex/State';
import CardexList from '../Cardex/List';
import { jalaliFullDate } from '../../../Utilities/jalaliDate';
import { getPatientProfile } from '../../../action/profile';
import useAccess from '../../../Hook/useAccess';
import { CheckboxState } from '../Cardex/Task';

interface IProps {
  visit: IVisit | null;
  parentVisit: IVisit | null;
  cardexAppendix?: ICardexAppendix | null;
  canCheckVisit: boolean;
  getPatientProfile: (patientId: string, useCacheData?: boolean) => any;
  getVisit: (visitId: number, withParent: true) => any;
  getVisits: (profileId: number) => any;
  checkVisit: (visitId: number) => any;
  addMessage: (message: string, status: number) => void;
  getCardexAppendix: (profileId: number) => any;
}

const VisitCheck: React.FC<IProps> = ({
  visit,
  parentVisit,
  cardexAppendix,
  canCheckVisit,
  getPatientProfile,
  getVisit,
  getVisits,
  checkVisit,
  addMessage,
  getCardexAppendix,
}) => {
  const history = useHistory();
  const { patientId, visitId } = useParams<{
    patientId: string;
    visitId: string;
  }>();
  const {
    data: nurseVisitOperatorAccess,
    isLoading: isLoadingNurseVisitOperatorAccess,
  } = useAccess('nurse_visit_operator');
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const [pending, setPending] = useState(false);

  if (!nurseVisitOperatorAccess && !isLoadingNurseVisitOperatorAccess) {
    history.replace(`/patient/${patientId}/order`);
  }

  useEffect(() => {
    fetchPageData();
  }, [patientId, visitId]);

  const fetchPageData = async () => {
    await setLoading(true);
    await setError(null);

    await Promise.all([
      getPatientProfile(patientId, true),
      getVisits(parseInt(patientId)),
      getVisit(parseInt(visitId), true),
      getCardexAppendix(parseInt(patientId)),
    ]).catch((err: any) => {
      console.log(err);
      setError(err);
    });

    setLoading(false);
  };

  const getCheckboxState = (
    item: ICardexItem,
    group?: ICardexGroup
  ): CheckboxState => {
    if (group === 'unchecked' && item.task.uuid) {
      return checkedItems.includes(item.task.uuid) ? 'checked' : 'unchecked';
    }
    return 'disabled';
  };

  const handleCheckTask = (checked: boolean, task: ITask) => {
    if (task.uuid) {
      let tempCheckedItems = [...checkedItems];
      if (checked) {
        tempCheckedItems.push(task.uuid);
      } else {
        tempCheckedItems = tempCheckedItems.filter(
          (item) => item !== task.uuid
        );
      }
      setCheckedItems(tempCheckedItems);
    }
  };

  const handleCheckAllTasks = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setCheckedItems(visit?.orders.map((o) => o.task.uuid || '') ?? []);
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckVisit = async () => {
    if (!canCheckVisit) return;
    if (checkedItems.length === visit?.orders.length) {
      await setPending(true);
      await checkVisit(parseInt(visitId))
        .then(() => {
          history.goBack();
        })
        .catch((err: any) => {
          console.log(err);
        });
      await setPending(false);
    } else {
      addMessage('Please check all new orders to confirm!', 300);
    }
  };

  return (
    <>
      <PageHeader
        desktopBackButton
        showPatientInfo={false}
        title={`Check visit ${
          visit?.modified ? jalaliFullDate(visit.modified) : ''
        }`}
      />
      <PageWrapper loading={loading} error={error} onReload={fetchPageData}>
        <Box px={3} pt={1} pb={3}>
          <CardexState label='Last revised by' user={visit?.signers[0]} />

          {visit?.signers.map((signer) => (
            <CardexState key={signer.id} label='Signed by' user={signer} />
          ))}

          {!!visit?.checker?.id && (
            <CardexState
              label='Checked by'
              user={visit?.checker}
              datetime={visit?.checked}
            />
          )}

          <Box mt={1} mb={0.5} textAlign='left'>
            <FormControlLabel
              control={
                <Checkbox
                  color='primary'
                  checked={checkedItems.length === visit?.orders.length}
                  onChange={handleCheckAllTasks}
                />
              }
              label='Check all unchecked orders'
            />
          </Box>

          <CardexList
            withGroups
            visit={visit}
            parentVisit={parentVisit}
            cardexAppendix={{
              task_notices: cardexAppendix?.task_notices ?? {},
              task_extensions: {},
            }}
            taskProps={{
              getCheckboxState,
              onCheck: handleCheckTask,
            }}
          />

          {canCheckVisit && (
            <Box pt={4}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                className='mediumPadding'
                disabled={pending}
                onClick={handleCheckVisit}
              >
                {pending ? (
                  <CircularProgress size={22} />
                ) : (
                  `${checkedItems.length}/${visit?.orders.length} items checked`
                )}
              </Button>
            </Box>
          )}
        </Box>
      </PageWrapper>
    </>
  );
};

export default connect(
  (state: RootStateOrAny) => {
    const currentVisit = state.order.visit;
    const visits: IVisitSummery[] = state.order.visits ?? [];

    const firstUncheckedVisit = visits
      .slice(0)
      .sort((a, b) => a.id - b.id)
      .find((visit) => !visit.checker?.id);

    const canCheckVisit =
      !!firstUncheckedVisit?.id && firstUncheckedVisit.id === currentVisit?.id;

    return {
      visit: state.order.visit,
      parentVisit: state.order.parentVisit,
      cardexAppendix: state.order.cardexAppendix,
      canCheckVisit,
    };
  },
  {
    getPatientProfile,
    getVisits: (profileId: number) => getRecentVisits(profileId, 10, 'all'),
    getVisit,
    getCardexAppendix,
    checkVisit,
    addMessage,
  }
)(VisitCheck);
