import React, { useRef } from 'react';
import { IOrder, isConsultTask } from '../../../../types/order';
import { Box, Grid, Typography } from '@material-ui/core';
import OrderTimingField from '../Fields/Timing';
import OrderNoteField from '../Fields/Note';
import { useQuery } from 'react-query';
import { getAllServices } from '../../../../api/generalAPI';
import ResponsiveAutocomplete from '../../../ResponsiveAutocomplete';

interface IProps {
  defaultOrder: IOrder;
  onOrderChange: (order: IOrder) => void;
}

const ConsultFormContent: React.FC<IProps> = ({
  defaultOrder,
  onOrderChange,
}) => {
  const timingRef = useRef<HTMLInputElement | undefined>();
  const { data: services, isFetching } = useQuery('services', getAllServices);
  if (!isConsultTask(defaultOrder.task)) return null;

  const handleHospitalServiceChange = async (_: any, value: any) => {
    const newTask = { ...defaultOrder.task };
    if (isConsultTask(newTask)) {
      await onOrderChange({
        ...defaultOrder,
        task: {
          ...newTask,
          hospital_service: value
            ? { id: value.id, title_en: value.title_en }
            : {},
        },
      });

      if (value) {
        timingRef.current && timingRef.current.focus();
      }
    }
  };

  const handleTimingFieldChange = (value: string, errors: any[]) => {
    if (isConsultTask(defaultOrder.task)) {
      let newTask = { ...defaultOrder.task };
      newTask = {
        ...newTask,
        timing: {
          raw: value,
          schedules: [],
          errors: errors,
        },
      };
      onOrderChange({ ...defaultOrder, task: newTask });
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className='flex'>
        <Box pt={1.25} pr={1}>
          <Typography noWrap>Consult from</Typography>
        </Box>

        <Box width={1}>
          <ResponsiveAutocomplete
            loading={isFetching}
            options={
              services?.data.services.map((service: any) => ({
                id: service.id,
                title_en: service.name,
              })) ?? []
            }
            getOptionLabel={(o) => o.title_en || ''}
            getOptionSelected={(o, v) => o?.id === v?.id}
            value={defaultOrder.task.hospital_service}
            onChange={handleHospitalServiceChange}
            textFieldProps={{
              autoFocus: true,
              placeholder: 'Hospital Service',
            }}
          />
        </Box>
      </Grid>

      {!!defaultOrder.task.hospital_service.id && (
        <>
          <Grid item xs={9} md={10}>
            <OrderTimingField
              inputRef={(el) => {
                timingRef.current = el;
              }}
              value={defaultOrder.task.timing?.raw}
              onChange={handleTimingFieldChange}
            />
          </Grid>

          <Grid item xs={12}>
            <OrderNoteField order={defaultOrder} onChange={onOrderChange} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ConsultFormContent;
