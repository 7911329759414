import { createTheme } from '@material-ui/core/styles';
import createTypography from '@material-ui/core/styles/createTypography';
import { Overrides } from '@material-ui/core/styles/overrides';
import { palette, shadows, overrides as ltrOverRides } from './theme';

export const typography = createTypography(palette, {
  fontFamily: 'IRANYekan',
  h4: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 500,
  },
  h5: {
    fontSize: 16,
    lineHeight: '25px',
    fontWeight: 600,
  },
  h6: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
  },
  body1: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 400,
  },
  body2: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 500,
  },
  button: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
    textTransform: 'none',
  },
  caption: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 600,
  },
});

const overrides: Overrides = {
  ...ltrOverRides,
  MuiFormGroup: {
    root: {
      '&.button-group': {
        flexDirection: 'row',

        '& label': {
          padding: '6px 0',
          margin: 0,
          flex: 1,
          justifyContent: 'center',
          border: '2px solid rgba(0, 0, 0, 0.23)',
          borderLeft: 'none',

          '&:first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
          },

          '&:last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          },

          '&.active': {
            backgroundColor: palette.primary.main,
            borderColor: palette.primary.main,
          },

          '& *': {
            ...typography.button,
          },

          '&.active *': {
            color: '#fff',
          },
        },
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      ...typography['body1'],

      position: 'relative',
      padding: '9px 12px',

      '& $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: '#4A90E2',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
      '&$focused $notchedOutline': {
        borderColor: '#4A90E2',
        borderWidth: 1,
      },
    },
    input: {
      padding: 0,
      height: 22,
      lineHeight: '22px',
    },
  },
};

// A custom theme for this app
const theme = createTheme({
  direction: 'rtl',
  palette,
  shadows,
  typography,
  overrides,
});

export default theme;
