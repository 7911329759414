import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  getDraftVisits,
  getHeadVisit,
  getCardexAppendix,
} from '../../action/order';
import { getPatientProfile } from '../../action/profile';
import { useParams, Link, useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { IDraftVisitSummery, IVisit } from '../../types/order';
import { Button, Typography, Box } from '@material-ui/core';
import PageHeader from '../PageHeader/PageHeader';
import AddIcon from '@material-ui/icons/Add';
import PageWrapper from '../PageWrapper';
import EMPTY_CARDEX_VECTOR from '../../Assets/vectors/empty.svg';
import PatientOrderContent from './Content';
import DraftVisitCard from './Visit/DraftCard';
import { getUserFullName } from '../../Utilities/user';
import useAccess from '../../Hook/useAccess';

const EmptyPatientOrder: React.FC<{ draftVisits: IDraftVisitSummery[] }> = ({
  draftVisits,
}) => {
  const { patientId } = useParams<{ patientId: string }>();
  const history = useHistory();

  const handleContinueDraftClick = (visitId: number) => () => {
    history.push(`/patient/${patientId}/order/draft/${visitId}`);
  };

  return (
    <Box m='auto' width={1} maxWidth='500px' px={3} py={4}>
      <img src={EMPTY_CARDEX_VECTOR} height={120} alt='no visit' />
      <Link to={`/patient/${patientId}/order/create-visit`}>
        <Button fullWidth color='primary' variant='contained' className='mt-4'>
          <AddIcon />
          New visit
        </Button>
      </Link>
      {!!draftVisits.length && (
        <>
          <Box my={2}>
            <Typography className='grey700Color leftSideLine rightSideLine'>
              &nbsp;Or&nbsp;
            </Typography>
          </Box>
          <Box>
            <Typography align='left' variant='h4' className='darkPrimaryColor'>
              Continue draft visits
            </Typography>
            <Box mt={2}>
              {draftVisits.map((visit, index, arr) => (
                <DraftVisitCard
                  key={visit.id}
                  withActionButton
                  actionButtonProps={{
                    label: 'Continue',
                    onClick: handleContinueDraftClick(visit.id),
                  }}
                  doctor={getUserFullName(visit.last_modifier)}
                  datetime={visit.modified}
                  className={index + 1 !== arr.length ? 'mb-3' : ''}
                />
              ))}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

interface IProps {
  getDraftVisits: (
    profileId: number
  ) => Promise<AxiosResponse<IDraftVisitSummery[]>>;
  getHeadVisit: (profileId: number) => Promise<AxiosResponse<IVisit>>;
  getPatientProfile: (patientId: string, useCacheData?: boolean) => any;
  getCardexAppendix: (profileId: number) => Promise<any>;
}

const PatientOrder: React.FC<IProps> = ({
  getDraftVisits,
  getHeadVisit,
  getPatientProfile,
  getCardexAppendix,
}) => {
  const { patientId } = useParams<{ patientId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const {
    data: physicianVisitOperatorAccess,
    isLoading: isLoadingPhysicianVisitOperator,
  } = useAccess('physician_visit_operator');
  const [draftVisits, setDraftVisits] = useState<IDraftVisitSummery[]>([]);
  const [hasVisit, setHasVisit] = useState(false);

  useEffect(() => {
    if (!isLoadingPhysicianVisitOperator) {
      fetchPageData();
    }
  }, [patientId, isLoadingPhysicianVisitOperator]);

  const fetchPageData = async () => {
    await setLoading(true);
    await setError(null);

    await getPatientProfile(patientId, true).catch(async (err: any) => {
      console.log(err);
      await setError(err);
    });

    await setHasVisit(false);
    await getHeadVisit(parseInt(patientId))
      .then((res) => {
        if (res.data?.id) {
          setHasVisit(true);
        }
      })
      .catch(async (err) => {
        console.log(err);
        await setError(err);
      });

    if (physicianVisitOperatorAccess) {
      await getDraftVisits(parseInt(patientId))
        .then((res) => {
          if (res.data?.length) {
            setDraftVisits(res.data);
          } else {
            setDraftVisits([]);
          }
        })
        .catch(async (err) => {
          console.log(err);
          await setError(err);
        });
    }

    await getCardexAppendix(parseInt(patientId)).catch(async (err) => {
      console.log(err);
      await setError(err);
    });

    setLoading(false);
  };

  return (
    <>
      <PageHeader />
      <PageWrapper
        loading={loading}
        error={error}
        onReload={fetchPageData}
        className={hasVisit ? '' : 'flex'}
      >
        {hasVisit ? (
          <PatientOrderContent />
        ) : (
          <EmptyPatientOrder draftVisits={draftVisits} />
        )}
      </PageWrapper>
    </>
  );
};

export default connect(null, {
  getDraftVisits: (profileId: number) => getDraftVisits(profileId, 0, 'add'),
  getHeadVisit: (profileId: number) => getHeadVisit(profileId, true),
  getPatientProfile,
  getCardexAppendix,
})(PatientOrder);
