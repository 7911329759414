import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { deleteTaskTemplate, getTaskTemplate } from '../../../../api/order';
import { IOrder } from '../../../../types/order';
import { getUserId } from '../../../../Utilities/storage';
import PageHeader from '../../../PageHeader/PageHeader';
import PageWrapper from '../../../PageWrapper';
import VistitTemplateItem from './Item';
import { Box, IconButton } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from '../../../../Assets/icon_trash_red.svg';
import { AlertDialog } from '../../../shared';
import usePathAction from '../../../../Hook/usePathAction';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../../action/generalActions';

interface IProps {
  onAddOrders?: (orders: IOrder[]) => void;
  onRefreshList?: () => void;
}

interface IPathParams {
  templateId: string;
}

const VisitTemplateView: React.FC<IProps> = ({
  onAddOrders,
  onRefreshList,
}) => {
  const history = useHistory();
  const { templateId } = useParams<IPathParams>();
  const userId = getUserId();
  const dispatch = useDispatch();
  const [pathAction, setPathAction] = usePathAction();

  const { data: taskTemplateRes, isFetching } = useQuery(
    ['taskTemplate', templateId],
    () => getTaskTemplate(templateId),
    { enabled: !!templateId }
  );

  const isSelfAuthor = useMemo(
    () => (taskTemplateRes ? userId === taskTemplateRes.data.author.id : false),
    [userId, taskTemplateRes?.data.author]
  );

  const openDeleteConfirmation = () => {
    setPathAction('openDeleteTemplateConfirmation');
  };

  const handleDeleteTemplate = async () => {
    await deleteTaskTemplate(templateId)
      .then(() => {
        dispatch(addMessage('Template has been deleted successfully!', 200));
        history.go(-2);
        if (onRefreshList) onRefreshList();
      })
      .catch((err) => {
        console.log(err);
        dispatch(addMessage('An error occurred on delete template!', 400));
      });
  };

  return (
    <>
      <PageHeader
        desktopBackButton
        showPatientInfo={false}
        title='Visit templates'
        optionComponent={
          isSelfAuthor ? (
            <Box mr={-1.5}>
              <IconButton color='secondary' onClick={openDeleteConfirmation}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ) : null
        }
      />

      <PageWrapper loading={isFetching}>
        <VistitTemplateItem
          template={taskTemplateRes?.data}
          isSelfAuthor={isSelfAuthor}
          onAddOrders={onAddOrders}
        />
      </PageWrapper>

      <AlertDialog
        withConfirmLoading
        open={pathAction === 'openDeleteTemplateConfirmation'}
        alertText='Are you sure you want to delete this template?'
        confirmButton={true}
        confirmText='Delete'
        confirmButtonColor='secondary'
        closeText='Cancel'
        handleClose={() => setPathAction('')}
        handleConfirm={handleDeleteTemplate}
      />
    </>
  );
};

export default VisitTemplateView;
