import React from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    textAlign: 'left',
    textTransform: 'uppercase',
    padding: '0 0 0 8px',
    marginTop: 14,

    '&.default': {
      color: theme.palette.grey[600],
      borderLeft: `1px solid ${theme.palette.grey[600]}`,
    },

    '&.primary': {
      color: theme.palette.primary.main,
      borderLeft: `1px solid ${theme.palette.primary.main}`,
    },

    '&.darkPrimary': {
      color: theme.palette.primary.dark,
      borderLeft: `1px solid ${theme.palette.primary.dark}`,
    },

    '&.topBordered': {
      position: 'relative',
      marginTop: 18,

      '&:after': {
        content: '""',
        position: 'absolute',
        top: -8,
        left: 0,
        width: '100%',
        height: 1,
        backgroundColor: '#ccccd2e0',
      },
    },
  },
}));

interface IProps {
  variant?: 'default' | 'primary' | 'darkPrimary';
  borderTop?: boolean;
  label?: string;
}

const ItemSubHeader: React.FC<IProps> = ({
  variant,
  borderTop = false,
  label = '',
}) => {
  const classes = useStyles();

  return (
    <Typography
      variant='caption'
      className={clsx(classes.root, variant, borderTop && 'topBordered')}
    >
      {label}
    </Typography>
  );
};

export default ItemSubHeader;
