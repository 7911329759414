import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  ICardex,
  ITask,
  ITaskExtension,
  ITaskNoticeLabel,
  ICardexItem,
  ITaskExtensionsMapping,
} from '../../../../types/order';
import {
  getCategory,
  updateCardexItemLabel,
} from '../../../../Utilities/order';
import { updateTaskExtensions } from '../../../../action/order';
import CardexList from '../List';
import { useParams } from 'react-router-dom';
import { ITaskAction } from '../Task';
import { createTaskNotice } from '../../../../api/order';
import TimeNoteDrawer from './TimeNoteDrawer';

interface IProps {
  cardex: ICardex;
  detailedView?: boolean;
  setCardex: (cardex: ICardex) => void;
  checkPermissions?: (uncheckedVisits?: boolean) => boolean;
  updateTaskExtensions: (
    profile_id: string,
    task_extensions: ITaskExtensionsMapping
  ) => Promise<any>;
}

const NursingCardex: React.FC<IProps> = ({
  cardex,
  detailedView,
  setCardex,
  updateTaskExtensions,
  checkPermissions = () => false,
}) => {
  const { patientId } = useParams<{ patientId: string }>();
  const [
    onChangeTimeNoteTask,
    setOnChangeTimeNoteTask,
  ] = useState<ITask | null>(null);
  const [savingStatus, setSavingStatus] = useState<
    Record<string, { state: 'loading' | 'error'; onReload?: () => void }>
  >({});

  const updateSaveStatus = (
    task: ITask,
    state: 'loading' | 'error',
    onReload: () => Promise<void>
  ) => {
    if (task.uuid) {
      setSavingStatus({
        ...savingStatus,
        [task.uuid]: { state, onReload },
      });
    }
  };

  const removeSaveStatus = (task: ITask) => {
    setSavingStatus((status) => {
      const newStatus = { ...status };
      if (task.uuid) {
        delete newStatus[task.uuid];
      }
      return newStatus;
    });
  };

  const triggerAddTimeNote = (task: ITask | null) => {
    if (task) {
      const uuid = task.uuid;
      if (checkPermissions(false) && uuid) {
        setOnChangeTimeNoteTask(task);
      }
    } else {
      setOnChangeTimeNoteTask(null);
    }
  };

  const handleAddTimeNote = async (values: ITaskExtension) => {
    const task = onChangeTimeNoteTask;
    if (task) {
      const uuid = task.uuid;
      if (checkPermissions(false) && uuid) {
        try {
          setOnChangeTimeNoteTask(null);
          await updateSaveStatus(task, 'loading', () =>
            handleAddTimeNote(values)
          );

          await updateTaskExtensions(patientId, {
            [uuid]: { ...values },
          });

          removeSaveStatus(task);
        } catch (err) {
          console.log(err);
          updateSaveStatus(task, 'error', () => handleAddTimeNote(values));
        }
      }
    }
  };

  const handleUpdateLabel = (label: ITaskNoticeLabel) => async (
    task: ITask
  ) => {
    const uuid = task.uuid;
    if (checkPermissions() && uuid) {
      try {
        await updateSaveStatus(task, 'loading', () =>
          handleUpdateLabel(label)(task)
        );

        await createTaskNotice(patientId, {
          [uuid]: { label },
        });

        const newCardex = updateCardexItemLabel({
          cardex,
          task,
          label,
        });

        setCardex(newCardex);
        removeSaveStatus(task);
      } catch (err) {
        console.log(err);
        updateSaveStatus(task, 'error', () => handleUpdateLabel(label)(task));
      }
    }
  };

  const getTaskActions = (item: ICardexItem) => {
    const category = getCategory(item.task);
    const actions: ITaskAction[] = [];

    if (category !== 'general' && item.task.uuid) {
      const status = savingStatus[item.task.uuid];
      if (status?.state === 'error') {
        actions.push({
          label: 'Reload',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          handler: status.onReload ?? (() => {}),
        });
        actions.push({
          label: 'Delete',
          handler: () => removeSaveStatus(item.task),
        });
      } else {
        actions.push({
          label: 'Add time & note',
          handler: triggerAddTimeNote,
        });

        if (item.state !== 'hold') {
          if (item.label === 'finished') {
            actions.push({
              label: 'Mark as "unfinished"',
              handler: handleUpdateLabel('unfinished'),
            });
          } else {
            actions.push({
              label: 'Mark as "finished"',
              handler: handleUpdateLabel('finished'),
            });
          }
        }
      }
    }

    return actions;
  };

  return (
    <div>
      <CardexList
        withGroups
        detailedView={detailedView}
        defaultCardex={cardex}
        savingStatuses={savingStatus}
        taskProps={{ getTaskActions }}
      />

      <TimeNoteDrawer
        open={!!onChangeTimeNoteTask}
        onClose={() => triggerAddTimeNote(null)}
        task={onChangeTimeNoteTask}
        onSubmit={handleAddTimeNote}
      />
    </div>
  );
};

export default connect(null, { updateTaskExtensions })(NursingCardex);
