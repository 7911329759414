import React, { useState, useEffect, useMemo } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import {
  createConsult,
  updateConsultRequest,
  getConsultDetail,
  deleteConsultRequest,
  getConsultCreateInitialData,
} from '../../action/consultAction';
import { getUserId } from '../../Utilities/storage';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import { Box, IconButton } from '@material-ui/core';
import PageHeader from '../PageHeader/PageHeader';
import PageWrapper from '../PageWrapper';
import ConsultRequestForm from './Sections/ConsultRequestForm';
import PatientInfo from '../PatientProfile/PatientInfo';

import DELETE_ICON from '../../Assets/red_bin_icon.svg';
import usePathAction from '../../Hook/usePathAction';
import { AlertDialog } from '../shared';

interface ConsultCreateProps {
  profile: any;
  consult: any;
  createConsult: (values: any, profileId: string, draftFlag: boolean) => any;
  updateConsultRequest: (
    consultId: string,
    values: any,
    draftFlag: boolean,
    saveAsDraftFlag: boolean
  ) => any;
  getConsultDetail: (consultId: string, getPatientIllness: boolean) => any;
  deleteConsultRequest: (consultId: string) => any;
  getConsultCreateInitialData: (consultId: string) => any;
}

function ConsultCreate({
  profile,
  consult,
  createConsult,
  updateConsultRequest,
  getConsultDetail,
  deleteConsultRequest,
  getConsultCreateInitialData,
}: ConsultCreateProps) {
  const history = useHistory();
  const { patientId, consultId } = useParams<{
    patientId: string,
    consultId: string,
  }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pathAction, setPathAction] = usePathAction();

  const isOnCreate =
    useRouteMatch('/patient/:patientId/consult/create') !== null;
  const isOnDraft =
    useRouteMatch('/patient/:patientId/consult/:consultId/draft') !== null;
  const isOnEdit =
    useRouteMatch('/patient/:patientId/consult/:consultId/edit') !== null;

  const pageTitle = useMemo(
    () =>
      isOnCreate
        ? 'New Consult'
        : isOnDraft
        ? 'Request Draft'
        : isOnEdit
        ? 'Edit Consult'
        : '',
    [isOnCreate, isOnDraft, isOnEdit]
  );

  useEffect(() => {
    fetchPageData();
  }, [patientId, consultId, isOnCreate, isOnDraft, isOnEdit]);

  const fetchPageData = () => {
    setLoading(true);
    setError(null);
    if (isOnCreate) {
      getConsultCreateInitialData(patientId)
        .then(() => setLoading(false))
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
          setError(err);
        });
    } else {
      getConsultDetail(consultId, true)
        .then(() => setLoading(false))
        .catch((err: any) => {
          console.log(err);
          setLoading(false);
          setError(err);
        });
    }
  };

  const handleBackButtonClick = () => {
    setPathAction('goBackTrigger');
  };

  const handleSubmitForm = (isSaveAsDraft: boolean, values: any) => {
    if (consultId && consult) {
      return updateConsultRequest(consultId, values, isOnDraft, isSaveAsDraft);
    } else {
      return createConsult(values, patientId, isSaveAsDraft);
    }
  };

  const handleDeleteConsult = async () => {
    const response = await deleteConsultRequest(consultId);
    if (response && response.status === 200) {
      const section = await localStorage.getItem('section');
      history.replace(`/patient/${patientId}/consult`, { cardName: section });
    }
  };

  const DeleteConsult = () => (
    <IconButton
      color='secondary'
      aria-label='Delete Consult'
      onClick={() => setPathAction('openConsultDeleteDialog')}
    >
      <img alt='Trash' height={25} src={DELETE_ICON} />
    </IconButton>
  );

  return (
    <>
      <PageHeader
        desktopBackButton={true}
        showPatientInfo={false}
        defaultGoBack={false}
        onBackButtonClick={handleBackButtonClick}
        title={pageTitle}
        optionComponent={
          !loading &&
          !error &&
          !isOnCreate &&
          (isOnDraft || consult?.requester?.id === getUserId()) && (
            <DeleteConsult />
          )
        }
      />
      <PageWrapper
        greyBg
        loading={loading}
        error={error}
        onReload={fetchPageData}
      >
        <PatientInfo profile={profile} />
        <Box mb={2} />
        <ConsultRequestForm
          canChangeServices={!isOnEdit}
          isEditMode={isOnEdit}
          patientPrimaryService={profile?.primary_service}
          consult={isOnCreate ? {} : consult}
          handleSubmit={(isSaveAsDraft: boolean, values: any) =>
            handleSubmitForm(isSaveAsDraft, values)
          }
        />
        <AlertDialog
          dialogActionProps={undefined}
          open={pathAction === 'openConsultDeleteDialog'}
          alertText='Are you sure about deleting this consult request?'
          confirmButton={true}
          confirmText='Delete'
          closeText='Cancel'
          confirmButtonColor='secondary'
          handleClose={() => setPathAction('')}
          handleConfirm={handleDeleteConsult}
        />
      </PageWrapper>
    </>
  );
}

export default connect(
  (state: RootStateOrAny) => ({
    profile: state.patientsReducer.patient?.profile,
    consult: state?.patientsReducer.patient?.consultDetail,
  }),
  {
    createConsult,
    updateConsultRequest,
    getConsultDetail,
    deleteConsultRequest,
    getConsultCreateInitialData,
  }
)(ConsultCreate);
