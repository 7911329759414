import React from 'react';
import Set_Notif from '../../Assets/ic_set_notif_dark_36_2.svg';
import Remove_Notif from '../../Assets/ic_remove_notif_dark_36_2.png';
import Abnormal_Notif from '../../Assets/ic_notif_abnormal_dark_36_2.png';
import Normal_Notif from '../../Assets/ic_notif_normal_dark_36_2.png';
import PUSH_PIN from '../../Assets/icons/pin_active.svg';
import PUSH_PIN_DEFAULT from '../../Assets/pin.svg';
import { CheckRtl } from '../../Utilities/CheckLang';

export const PatientOverallInfo = ({
  consultView,
  lab_subscription,
  handleNotif,
  pinPatient,
  pinned,
  patient,
  onClick,
}) => {
  let notifImageSrc = Normal_Notif;
  const patientName = patient
    ? `${patient.first_name} ${patient.last_name}`
    : '';

  if (lab_subscription === 'none') {
    notifImageSrc = Set_Notif;
  } else if (lab_subscription === 'all') {
    notifImageSrc = Remove_Notif;
  } else if (lab_subscription === 'abnormal') {
    notifImageSrc = Abnormal_Notif;
  }

  const patientInfo = (
    <>
      <div className='farsiBold text-right patientName cardPatientName'>
        {patientName}
      </div>
      <div className='flex-space-between row-reverse'>
        <div>
          {patient.ward && (
            <div
              className={`sectionName ${
                CheckRtl(patient.ward.name[0])
                  ? ' farsiNum rtl text-right'
                  : 'text-left'
              }`}
            >
              {patient.ward.name}
            </div>
          )}
        </div>
        {!consultView && (
          <div>
            <div className='sectionName farsiNum rtl xnormalFont'>
              {`${patient.room || ''} - ${patient.bed || ''}`}
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div className='patientCard-overallInfo'>
      {!consultView && (
        <div className='notificationSection'>
          <img
            alt='notif icon'
            className='PatientCardIcon'
            height={30}
            src={notifImageSrc}
            onClick={handleNotif}
          />
        </div>
      )}
      {!consultView ? (
        <div className={'pin-container'} onClick={pinPatient}>
          <img
            alt='pin icon'
            className='PinIcon'
            height={17}
            src={pinned ? PUSH_PIN : PUSH_PIN_DEFAULT}
          />
        </div>
      ) : null}
      {onClick ? <div onClick={onClick}>{patientInfo}</div> : patientInfo}
    </div>
  );
};
export default PatientOverallInfo;
