import WardContainer from '../Components/WardContainer/WardContainer';
import MultiServiceContainer from '../Components/MultiServiceContainer/MultiServiceContainer';
import ConsultNotification from '../Components/ConsultNotification/ConsultNotification';
import ResetPassContainer from '../Components/ResetPassContainer/ResetPassContainer';
import UserSignature from '../Components/UserSignature/UserSignature';
import UserSignatureUploadHint from '../Components/UserSignature/UserSignatureUploadHint';
import UserSignatureUpload from '../Components/UserSignature/UserSignatureUpload';
import AboutUs from '../Components/SearchAndSettingBar/Setting/AboutUs/AboutUs';

const settingRoutes = [
  {
    key: 'section_responsibility_services',
    path: '/setting/section/responsibility_services',
    component: MultiServiceContainer,
    title: 'سرویس خود را جهت دریافت نوتیفیکیشن و پیامک انتخاب کنید',
  },
  {
    key: 'section_ward',
    path: '/setting/section/ward',
    component: WardContainer,
    title: 'Select Your Rotation Schedule',
    componentProps: {
      openShiftDrawer: false,
      openRotationDrawer: true,
    },
  },
  {
    key: 'section_shift',
    path: '/setting/section/shift',
    component: WardContainer,
    title: 'Select Your On-call Schedule',
    componentProps: {
      openShiftDrawer: true,
      openRotationDrawer: false,
    },
  },
  {
    key: 'notifications_consult',
    path: '/setting/notifications/consult',
    component: ConsultNotification,
    title: 'Consult Notification',
  },
  {
    key: 'profile_reset_password',
    path: '/setting/profile/reset_password',
    component: ResetPassContainer,
    title: 'Reset Password',
  },
  {
    key: 'profile_signature',
    path: '/setting/profile/signature',
    component: UserSignature,
    title: 'Signature',
  },
  {
    key: 'profile_signature_description',
    path: '/setting/profile/signature_description',
    component: UserSignatureUploadHint,
    title: 'Signature Upload',
    headerProps: {
      desktopBackButton: true,
    },
  },
  {
    key: 'profile_signature_upload',
    path: '/setting/profile/signature_upload',
    component: UserSignatureUpload,
    title: 'Signature Upload',
    headerProps: {
      desktopBackButton: true,
    },
  },
  {
    key: 'other_contact',
    path: '/setting/other/contact',
    component: AboutUs,
    title: 'Contact Us',
  },
];

export default settingRoutes;
