import React from 'react';
import { Card, CardContent, Box, Button, Typography } from '@material-ui/core';
import { IPatient } from '../../../types/patient';
import { makeStyles } from '@material-ui/styles';
import { ReactComponent as ProfileIcon } from '../../../Assets/icons/profile.svg';
import { ReactComponent as MaleIcon } from '../../../Assets/icons/vuesax_linear_man.svg';
import { ReactComponent as FemaleIcon } from '../../../Assets/icons/vuesax_linear_woman.svg';
import { getAgeWithUnit } from '../../../Utilities/jalaliDate';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    border: '1px inside #f3f4fa',
    borderRadius: 6,
    boxShadow: '0 2px 8px 0 #2327331a',
    marginBottom: 12,
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '16px !important',

    '& *': {
      fontFamily: 'Poppins',
    },
  },
});

const ProfileCard: React.FC<{ patient: IPatient }> = ({ patient }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleProfileButtonClick = () => {
    history.replace(`/patient/${patient.profile_id}/profile`);
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Box display='flex' alignItems='center'>
          <div className='profile-title farsiBold'>
            {patient.first_name}&nbsp;{patient.last_name}
          </div>
          &nbsp; &nbsp;
          <Typography variant='h5' className='mediumWeight'>
            {getAgeWithUnit(patient.birth_date)?.age}
            <sup>{getAgeWithUnit(patient.birth_date)?.unit}</sup>
          </Typography>
          &nbsp;
          {patient.gender === 'F' ? (
            <FemaleIcon />
          ) : patient.gender === 'M' ? (
            <MaleIcon />
          ) : null}
        </Box>
        <Button
          color='primary'
          endIcon={<ProfileIcon />}
          onClick={handleProfileButtonClick}
        >
          Health Record
        </Button>
      </CardContent>
    </Card>
  );
};

export default ProfileCard;
