import { ANTLRErrorListener, Recognizer } from 'antlr4ts';

class TimingErrorAggregator implements ANTLRErrorListener<symbol> {
  errors: any[];

  constructor() {
    this.errors = [];
  }

  error(message: string) {
    this.errors.push({ message });
  }

  syntaxError(
    recognizer: Recognizer<any, any>,
    offendingSymbol: any,
    line: number,
    charPositionInLine: number,
    message: string
  ) {
    const error = {
      message,
      symbol: offendingSymbol ? offendingSymbol.text : '',
      line: line - 1,
      character: charPositionInLine,
      length: offendingSymbol
        ? offendingSymbol.stop - offendingSymbol.start + 1
        : 0,
    };

    this.errors.push(error);
  }

  getErrors() {
    return this.errors;
  }
}

export default TimingErrorAggregator;
