import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles({
    title: {
      color: '#a7a7a7',
      width: '100%',
      textAlign: 'left',
      marginBottom: '5px'
    },
    content: {
      width:'100%',
      textAlign: 'auto',
      marginBottom: '20px',
      whiteSpace: 'pre-line'
    }
});


export function Label({children,title,...props}) {
        const classes = useStyles();
        return (
          <div {...props}>
            <Typography className={classes.title}>{title}</Typography>
            <div className={classes.content} dir="auto">
            {children}
            </div>
          </div>
        );
}
