/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from './apiController';
import {
  IDraftVisitSummery,
  IDraftVisit,
  IVisitSummery,
  IVisit,
  IOrder,
  IDraftVisitPurpose,
  ITaskNoticeLabel,
  ITaskExtensionsMapping,
  ICardexAppendix,
  ITaskTemplate,
  IBaseTaskTemplate,
} from '../types/order';
import { IPagination } from '../types/general';

export const getRecentVisits = (
  profile_id: number,
  ancestors_count: number,
  start_id?: number,
  type?: 'all' | 'checked' | 'unchecked'
) =>
  axios.get<IVisitSummery[]>('/visit', {
    params: { profile_id, start_id, ancestors_count, type },
  });

export const createVisit = (data: any, by_draft?: number) =>
  axios.post(by_draft ? `/visit?by_draft=${by_draft}` : '/visit', data);

export const getVisit = (visit_id: number) =>
  axios.get<IVisit>(`/visit/${visit_id}`);

export const updateRecentVisit = (
  visit_id: number,
  orders: IOrder[],
  by_sibling_draft?: number
) =>
  axios.put<IVisit>(
    `/visit/${visit_id}`,
    { orders },
    { params: { by_sibling_draft } }
  );

export const deleteVisit = (visit_id: number) =>
  axios.delete(`/visit/${visit_id}`);

export const getDraftVisits = (
  profile_id: number,
  offset: number,
  limit: number,
  purpose?: IDraftVisitPurpose
) =>
  axios.get<IDraftVisitSummery[]>('/visit/draft', {
    params: { profile_id, offset, limit, purpose },
  });

export const getDraftVisit = (draft_id: number) =>
  axios.get<IDraftVisit>(`/visit/draft/${draft_id}`);

export const getSpecificVisit = (
  profile_id: number,
  type: 'head' | 'last_checked'
) =>
  axios.get<IVisit>('/visit/specific', {
    params: { profile_id, type },
    validateStatus: (status: number) =>
      status === 404 || (status < 300 && status >= 200),
  });

export const signVisit = (visit_id: number) =>
  axios.post<IVisit>(`/visit/${visit_id}/sign`);

export const checkVisit = (visit_id: number) =>
  axios.post<IVisit>(`/visit/${visit_id}/check`);

export const createDraftVisit = ({
  profile_id,
  purpose = 'add',
  parent,
  orders,
}: {
  profile_id: string | number;
  purpose?: IDraftVisitPurpose;
  parent: number | string | null;
  orders: IOrder[];
}) =>
  axios.post<IDraftVisit>('/visit/draft', {
    profile_id,
    purpose,
    parent,
    orders,
  });

export const editDraftVisit = (draft_id: number, data: any) =>
  axios.patch<IDraftVisit>(`/visit/draft/${draft_id}`, data);

export const deleteDraftVisit = (draft_id: number) =>
  axios.delete(`/visit/draft/${draft_id}`);

export const getParsedTimingRaw = (raw?: string | null) =>
  axios.post<{ schedules: string[] }>('/visit/timing/parse', { raw });

export const getCardexAppendix = (profile_id: number | string) =>
  axios.get<ICardexAppendix>('/visit/cardex_appendix', {
    params: { profile_id },
  });

export const createTaskNotice = (
  profile_id: number | string,
  new_task_notices: { [key: string]: { label: ITaskNoticeLabel } }
) =>
  axios.post<ICardexAppendix>('/visit/task_notices', {
    profile_id,
    new_task_notices,
  });

export const updateTaskExtensions = (
  profile_id: number | string,
  task_extensions: ITaskExtensionsMapping
) =>
  axios.put<ICardexAppendix>('/visit/task_extensions', {
    profile_id,
    task_extensions,
  });

/////////////////////////
///// Task Template /////
////////////////////////

export const getAllTaskTemplates = (
  offset: number,
  limit: number,
  query?: {
    cardinality?: 'one' | 'multiple' | 'any';
    author?: 'self' | 'any';
    q?: string;
  }
) =>
  axios.get<IPagination<ITaskTemplate>>('/visit/task_template', {
    params: { offset, limit, ...query },
  });

export const getTaskTemplate = (taskTemplateId: number | string) =>
  axios.get<ITaskTemplate>(`/visit/task_template/${taskTemplateId}`);

export const createTaskTemplate = (data: IBaseTaskTemplate) =>
  axios.post<ITaskTemplate>('/visit/task_template', data);

export const updateTaskTemplate = (
  taskTemplateId: number | string,
  data: IBaseTaskTemplate
) => axios.put<ITaskTemplate>(`/visit/task_template/${taskTemplateId}`, data);

export const deleteTaskTemplate = (taskTemplateId: number | string) =>
  axios.delete(`/visit/task_template/${taskTemplateId}`);
