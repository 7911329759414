import React from 'react';
import { Box } from '@material-ui/core';
import Setting from './Setting/Setting';
import OtherSectionInSetting from './OtherSectionInSetting/OtherSectionInSetting';
import Profile from './Profile/Profile';
import Notification from './Notification/Notification';

function SettingMenu() {
  return (
    <Box textAlign='left' style={{ overflowY: 'auto' }}>
      <Setting
        handleSettingCloseOnWardChange={() =>
          this.props.handleSettingCloseOnWardChange()
        }
      />
      <Notification />
      <Profile />
      <OtherSectionInSetting />
    </Box>
  );
}
export default SettingMenu;
