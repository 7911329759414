import React from 'react';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete, AutocompleteProps } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { CheckRtl } from '../../../../Utilities/CheckLang';
import clsx from 'clsx';

const useStyles = makeStyles({
  outlinedInputRoot: {
    padding: '9px 12px',

    '& input': {
      padding: '0 !important',
    },
  },
});

type IProps = Omit<
  AutocompleteProps<any, false, false, false>,
  'renderInput'
> & {
  textFieldProps?: TextFieldProps;
  defaultDirection?: 'rtl' | 'ltr';
};

const CustomAutocomplete: React.FC<IProps> = ({
  textFieldProps,
  getOptionLabel,
  getOptionSelected = (o, v) => o?.id === v?.id,
  value,
  defaultDirection,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      value={value}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            ...textFieldProps?.InputProps,
            inputProps: {
              ...params.inputProps,
              style: {
                direction: CheckRtl(
                  getOptionLabel ? getOptionLabel(value) : value,
                  defaultDirection
                )
                  ? 'rtl'
                  : 'ltr',
                ...textFieldProps?.InputProps?.inputProps?.style,
              },
              className: clsx(
                (params.inputProps as any)?.className,
                CheckRtl(
                  getOptionLabel ? getOptionLabel(value) : value,
                  defaultDirection
                )
                  ? 'farsiFont'
                  : '',
                textFieldProps?.InputProps?.inputProps?.className
              ),
            },
            className: clsx(
              params.InputProps.className,
              textFieldProps?.variant === 'outlined' &&
                classes.outlinedInputRoot,
              textFieldProps?.InputProps?.className
            ),
          }}
        />
      )}
      renderOption={(option) =>
        CheckRtl(
          getOptionLabel ? getOptionLabel(option) : option,
          defaultDirection
        ) ? (
          <div
            className='farsiFont w-100 text-right'
            style={{ direction: 'rtl' }}
          >
            {getOptionLabel ? getOptionLabel(option) : option}
          </div>
        ) : getOptionLabel ? (
          getOptionLabel(option)
        ) : (
          option
        )
      }
      {...rest}
    />
  );
};

export default CustomAutocomplete;
