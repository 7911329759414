import { AxiosResponse } from 'axios';
import {
  IGetFeasibleMedicalTermsQuery,
  IGetMedicalTermsQuery,
  IMedicalTermTypes,
} from '../types/medicalTerms';
import axios from './apiController';

export const DEFAULT_TERMS_OFFSET = 0;
export const DEFAULT_TERMS_LIMIT = 30;

export const getTerms = (
  term_type: IMedicalTermTypes,
  query?: IGetMedicalTermsQuery,
  offset = DEFAULT_TERMS_OFFSET,
  limit = DEFAULT_TERMS_LIMIT
): Promise<
  AxiosResponse<{
    count: number;
    results: {
      id: number;
      title_en: string;
    }[];
  }>
> =>
  axios.get(`/terms/${term_type}`, {
    params: {
      offset,
      limit,
      ...{
        ...query,
        ids: Array.isArray(query?.ids) ? query?.ids.join(',') : query?.ids,
      },
    },
  });

export const getFeasibleTerms = (
  term_type: IMedicalTermTypes,
  query?: IGetFeasibleMedicalTermsQuery,
  offset = DEFAULT_TERMS_OFFSET,
  limit = DEFAULT_TERMS_LIMIT
): Promise<
  AxiosResponse<{
    count: number;
    results: {
      id: number;
      title_en: string;
    }[];
  }>
> =>
  axios.get(`/terms/by_feasibilities/${term_type}`, {
    params: {
      offset,
      limit,
      ...{
        ...query,
        ids: Array.isArray(query?.ids) ? query?.ids.join(',') : query?.ids,
      },
    },
  });
