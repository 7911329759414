// Generated from src/gen/timing.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { timingListener } from "./timingListener";
import { timingVisitor } from "./timingVisitor";


export class timingParser extends Parser {
	public static readonly T__0 = 1;
	public static readonly T__1 = 2;
	public static readonly T__2 = 3;
	public static readonly T__3 = 4;
	public static readonly T__4 = 5;
	public static readonly T__5 = 6;
	public static readonly T__6 = 7;
	public static readonly T__7 = 8;
	public static readonly T__8 = 9;
	public static readonly T__9 = 10;
	public static readonly T__10 = 11;
	public static readonly T__11 = 12;
	public static readonly T__12 = 13;
	public static readonly T__13 = 14;
	public static readonly T__14 = 15;
	public static readonly T__15 = 16;
	public static readonly T__16 = 17;
	public static readonly T__17 = 18;
	public static readonly T__18 = 19;
	public static readonly T__19 = 20;
	public static readonly T__20 = 21;
	public static readonly T__21 = 22;
	public static readonly T__22 = 23;
	public static readonly T__23 = 24;
	public static readonly T__24 = 25;
	public static readonly T__25 = 26;
	public static readonly T__26 = 27;
	public static readonly T__27 = 28;
	public static readonly T__28 = 29;
	public static readonly T__29 = 30;
	public static readonly T__30 = 31;
	public static readonly T__31 = 32;
	public static readonly T__32 = 33;
	public static readonly T__33 = 34;
	public static readonly T__34 = 35;
	public static readonly T__35 = 36;
	public static readonly T__36 = 37;
	public static readonly T__37 = 38;
	public static readonly T__38 = 39;
	public static readonly T__39 = 40;
	public static readonly T__40 = 41;
	public static readonly T__41 = 42;
	public static readonly T__42 = 43;
	public static readonly T__43 = 44;
	public static readonly T__44 = 45;
	public static readonly T__45 = 46;
	public static readonly T__46 = 47;
	public static readonly T__47 = 48;
	public static readonly T__48 = 49;
	public static readonly T__49 = 50;
	public static readonly T__50 = 51;
	public static readonly T__51 = 52;
	public static readonly T__52 = 53;
	public static readonly T__53 = 54;
	public static readonly T__54 = 55;
	public static readonly T__55 = 56;
	public static readonly T__56 = 57;
	public static readonly T__57 = 58;
	public static readonly T__58 = 59;
	public static readonly T__59 = 60;
	public static readonly T__60 = 61;
	public static readonly T__61 = 62;
	public static readonly T__62 = 63;
	public static readonly T__63 = 64;
	public static readonly T__64 = 65;
	public static readonly T__65 = 66;
	public static readonly T__66 = 67;
	public static readonly T__67 = 68;
	public static readonly T__68 = 69;
	public static readonly T__69 = 70;
	public static readonly T__70 = 71;
	public static readonly T__71 = 72;
	public static readonly T__72 = 73;
	public static readonly T__73 = 74;
	public static readonly T__74 = 75;
	public static readonly T__75 = 76;
	public static readonly QNUNIT = 77;
	public static readonly NUM = 78;
	public static readonly WS = 79;
	public static readonly AND = 80;
	public static readonly WORD = 81;
	public static readonly COLON = 82;
	public static readonly DASH = 83;
	public static readonly RULE_main = 0;
	public static readonly RULE_timing = 1;
	public static readonly RULE_ordinal_time = 2;
	public static readonly RULE_ordinal = 3;
	public static readonly RULE_ordinal_unit = 4;
	public static readonly RULE_end_period = 5;
	public static readonly RULE_time_period = 6;
	public static readonly RULE_start_period = 7;
	public static readonly RULE_time_points = 8;
	public static readonly RULE_time_point = 9;
	public static readonly RULE_priority = 10;
	public static readonly RULE_frequency = 11;
	public static readonly RULE_date = 12;
	public static readonly RULE_days = 13;
	public static readonly RULE_period_unit = 14;
	public static readonly RULE_hour_unit = 15;
	public static readonly RULE_month_unit = 16;
	public static readonly RULE_week_unit = 17;
	public static readonly RULE_day_unit = 18;
	public static readonly RULE_time_in_day = 19;
	public static readonly RULE_absolute_time = 20;
	public static readonly RULE_am_pm = 21;
	public static readonly RULE_time = 22;
	public static readonly RULE_iterative_named_time = 23;
	public static readonly RULE_named_time = 24;
	public static readonly RULE_prefix = 25;
	public static readonly RULE_time_interval = 26;
	public static readonly RULE_minute_unit = 27;
	public static readonly RULE_weekdays = 28;
	public static readonly RULE_odd_evens = 29;
	public static readonly RULE_od = 30;
	public static readonly RULE_frequency_for_one_day = 31;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"main", "timing", "ordinal_time", "ordinal", "ordinal_unit", "end_period", 
		"time_period", "start_period", "time_points", "time_point", "priority", 
		"frequency", "date", "days", "period_unit", "hour_unit", "month_unit", 
		"week_unit", "day_unit", "time_in_day", "absolute_time", "am_pm", "time", 
		"iterative_named_time", "named_time", "prefix", "time_interval", "minute_unit", 
		"weekdays", "odd_evens", "od", "frequency_for_one_day",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'then'", "'at'", "'for'", "'first'", "'second'", "'third'", 
		"'hour'", "'day'", "'week'", "'month'", "'until'", "'from'", "'on'", "'stat'", 
		"'prn'", "'asap'", "'urgent'", "'routine'", "'bd'", "'tds'", "'qid'", 
		"'tomorrow'", "'sunday'", "'monday'", "'tuesday'", "'wednesday'", "'thursday'", 
		"'friday'", "'saturday'", "'hours'", "'h'", "'months'", "'mo'", "'weeks'", 
		"'wk'", "'days'", "'d'", "'am'", "'pm'", "'mornings'", "'nights'", "'morning'", 
		"'afternoon'", "'evening'", "'noon'", "'night'", "'bedtime'", "'sleep'", 
		"'lunch'", "'dinner'", "'breakfast'", "'surgery'", "'early'", "'late'", 
		"'before'", "'after'", "'with'", "'every'", "'between'", "'minutes'", 
		"'minute'", "'m'", "'sundays'", "'mondays'", "'tuesdays'", "'wednesdays'", 
		"'thursdays'", "'fridays'", "'saturdays'", "'odds'", "'evens'", "'daily'", 
		"'every other day'", "'weekly'", "'monthly'", "'once'", undefined, undefined, 
		undefined, "','", undefined, "':'", "'-'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, undefined, 
		"QNUNIT", "NUM", "WS", "AND", "WORD", "COLON", "DASH",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(timingParser._LITERAL_NAMES, timingParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return timingParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "timing.g4"; }

	// @Override
	public get ruleNames(): string[] { return timingParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return timingParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(timingParser._ATN, this);
	}
	// @RuleVersion(0)
	public main(): MainContext {
		let _localctx: MainContext = new MainContext(this._ctx, this.state);
		this.enterRule(_localctx, 0, timingParser.RULE_main);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 64;
			this.timing();
			this.state = 69;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === timingParser.T__0) {
				{
				{
				this.state = 65;
				this.match(timingParser.T__0);
				this.state = 66;
				this.timing();
				}
				}
				this.state = 71;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 72;
			this.match(timingParser.EOF);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public timing(): TimingContext {
		let _localctx: TimingContext = new TimingContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, timingParser.RULE_timing);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 76;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case timingParser.T__18:
			case timingParser.T__19:
			case timingParser.T__20:
			case timingParser.T__71:
			case timingParser.T__72:
			case timingParser.T__73:
			case timingParser.T__74:
			case timingParser.T__75:
			case timingParser.QNUNIT:
				{
				this.state = 74;
				this.frequency();
				}
				break;
			case timingParser.T__13:
			case timingParser.T__14:
			case timingParser.T__15:
			case timingParser.T__16:
			case timingParser.T__17:
				{
				this.state = 75;
				this.priority();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this.state = 82;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if ((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << timingParser.T__1) | (1 << timingParser.T__12) | (1 << timingParser.T__21) | (1 << timingParser.T__22) | (1 << timingParser.T__23) | (1 << timingParser.T__24) | (1 << timingParser.T__25) | (1 << timingParser.T__26) | (1 << timingParser.T__27) | (1 << timingParser.T__28))) !== 0) || ((((_la - 40)) & ~0x1F) === 0 && ((1 << (_la - 40)) & ((1 << (timingParser.T__39 - 40)) | (1 << (timingParser.T__40 - 40)) | (1 << (timingParser.T__41 - 40)) | (1 << (timingParser.T__42 - 40)) | (1 << (timingParser.T__43 - 40)) | (1 << (timingParser.T__44 - 40)) | (1 << (timingParser.T__45 - 40)) | (1 << (timingParser.T__46 - 40)) | (1 << (timingParser.T__47 - 40)) | (1 << (timingParser.T__48 - 40)) | (1 << (timingParser.T__49 - 40)) | (1 << (timingParser.T__50 - 40)) | (1 << (timingParser.T__51 - 40)) | (1 << (timingParser.T__52 - 40)) | (1 << (timingParser.T__53 - 40)) | (1 << (timingParser.T__54 - 40)) | (1 << (timingParser.T__55 - 40)) | (1 << (timingParser.T__56 - 40)) | (1 << (timingParser.T__57 - 40)) | (1 << (timingParser.T__58 - 40)) | (1 << (timingParser.T__62 - 40)) | (1 << (timingParser.T__63 - 40)) | (1 << (timingParser.T__64 - 40)) | (1 << (timingParser.T__65 - 40)) | (1 << (timingParser.T__66 - 40)) | (1 << (timingParser.T__67 - 40)) | (1 << (timingParser.T__68 - 40)) | (1 << (timingParser.T__69 - 40)) | (1 << (timingParser.T__70 - 40)))) !== 0) || _la === timingParser.NUM) {
				{
				this.state = 79;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === timingParser.T__1) {
					{
					this.state = 78;
					this.match(timingParser.T__1);
					}
				}

				this.state = 81;
				this.time_points();
				}
			}

			this.state = 85;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === timingParser.T__11) {
				{
				this.state = 84;
				this.start_period();
				}
			}

			this.state = 90;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 5, this._ctx) ) {
			case 1:
				{
				this.state = 87;
				this.time_period();
				}
				break;

			case 2:
				{
				this.state = 88;
				this.end_period();
				}
				break;

			case 3:
				{
				this.state = 89;
				this.ordinal_time();
				}
				break;
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public ordinal_time(): Ordinal_timeContext {
		let _localctx: Ordinal_timeContext = new Ordinal_timeContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, timingParser.RULE_ordinal_time);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 92;
			this.match(timingParser.T__2);
			this.state = 93;
			this.ordinal();
			this.state = 94;
			this.ordinal_unit();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public ordinal(): OrdinalContext {
		let _localctx: OrdinalContext = new OrdinalContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, timingParser.RULE_ordinal);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 96;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << timingParser.T__3) | (1 << timingParser.T__4) | (1 << timingParser.T__5))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public ordinal_unit(): Ordinal_unitContext {
		let _localctx: Ordinal_unitContext = new Ordinal_unitContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, timingParser.RULE_ordinal_unit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 98;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << timingParser.T__6) | (1 << timingParser.T__7) | (1 << timingParser.T__8) | (1 << timingParser.T__9))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public end_period(): End_periodContext {
		let _localctx: End_periodContext = new End_periodContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, timingParser.RULE_end_period);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 100;
			this.match(timingParser.T__10);
			this.state = 103;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case timingParser.T__21:
			case timingParser.T__22:
			case timingParser.T__23:
			case timingParser.T__24:
			case timingParser.T__25:
			case timingParser.T__26:
			case timingParser.T__27:
			case timingParser.T__28:
				{
				this.state = 101;
				this.days();
				}
				break;
			case timingParser.NUM:
				{
				this.state = 102;
				this.date();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public time_period(): Time_periodContext {
		let _localctx: Time_periodContext = new Time_periodContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, timingParser.RULE_time_period);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 105;
			this.match(timingParser.T__2);
			this.state = 106;
			this.match(timingParser.NUM);
			this.state = 107;
			this.period_unit();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public start_period(): Start_periodContext {
		let _localctx: Start_periodContext = new Start_periodContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, timingParser.RULE_start_period);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 109;
			this.match(timingParser.T__11);
			this.state = 112;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case timingParser.T__21:
			case timingParser.T__22:
			case timingParser.T__23:
			case timingParser.T__24:
			case timingParser.T__25:
			case timingParser.T__26:
			case timingParser.T__27:
			case timingParser.T__28:
				{
				this.state = 110;
				this.days();
				}
				break;
			case timingParser.NUM:
				{
				this.state = 111;
				this.date();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public time_points(): Time_pointsContext {
		let _localctx: Time_pointsContext = new Time_pointsContext(this._ctx, this.state);
		this.enterRule(_localctx, 16, timingParser.RULE_time_points);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 114;
			this.time_point();
			this.state = 119;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === timingParser.AND) {
				{
				{
				this.state = 115;
				this.match(timingParser.AND);
				this.state = 116;
				this.time_point();
				}
				}
				this.state = 121;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public time_point(): Time_pointContext {
		let _localctx: Time_pointContext = new Time_pointContext(this._ctx, this.state);
		this.enterRule(_localctx, 18, timingParser.RULE_time_point);
		let _la: number;
		try {
			this.state = 142;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 14, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 122;
				this.odd_evens();
				this.state = 124;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === timingParser.T__12 || ((((_la - 40)) & ~0x1F) === 0 && ((1 << (_la - 40)) & ((1 << (timingParser.T__39 - 40)) | (1 << (timingParser.T__40 - 40)) | (1 << (timingParser.T__41 - 40)) | (1 << (timingParser.T__42 - 40)) | (1 << (timingParser.T__43 - 40)) | (1 << (timingParser.T__44 - 40)) | (1 << (timingParser.T__45 - 40)) | (1 << (timingParser.T__46 - 40)) | (1 << (timingParser.T__47 - 40)) | (1 << (timingParser.T__48 - 40)) | (1 << (timingParser.T__49 - 40)) | (1 << (timingParser.T__50 - 40)) | (1 << (timingParser.T__51 - 40)) | (1 << (timingParser.T__52 - 40)) | (1 << (timingParser.T__53 - 40)) | (1 << (timingParser.T__54 - 40)) | (1 << (timingParser.T__55 - 40)) | (1 << (timingParser.T__56 - 40)) | (1 << (timingParser.T__57 - 40)) | (1 << (timingParser.T__58 - 40)))) !== 0) || _la === timingParser.NUM) {
					{
					this.state = 123;
					this.time_in_day();
					}
				}

				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 127;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === timingParser.T__12) {
					{
					this.state = 126;
					this.match(timingParser.T__12);
					}
				}

				this.state = 129;
				this.weekdays();
				this.state = 131;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === timingParser.T__12 || ((((_la - 40)) & ~0x1F) === 0 && ((1 << (_la - 40)) & ((1 << (timingParser.T__39 - 40)) | (1 << (timingParser.T__40 - 40)) | (1 << (timingParser.T__41 - 40)) | (1 << (timingParser.T__42 - 40)) | (1 << (timingParser.T__43 - 40)) | (1 << (timingParser.T__44 - 40)) | (1 << (timingParser.T__45 - 40)) | (1 << (timingParser.T__46 - 40)) | (1 << (timingParser.T__47 - 40)) | (1 << (timingParser.T__48 - 40)) | (1 << (timingParser.T__49 - 40)) | (1 << (timingParser.T__50 - 40)) | (1 << (timingParser.T__51 - 40)) | (1 << (timingParser.T__52 - 40)) | (1 << (timingParser.T__53 - 40)) | (1 << (timingParser.T__54 - 40)) | (1 << (timingParser.T__55 - 40)) | (1 << (timingParser.T__56 - 40)) | (1 << (timingParser.T__57 - 40)) | (1 << (timingParser.T__58 - 40)))) !== 0) || _la === timingParser.NUM) {
					{
					this.state = 130;
					this.time_in_day();
					}
				}

				}
				break;

			case 3:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 133;
				this.days();
				this.state = 135;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === timingParser.T__12 || ((((_la - 40)) & ~0x1F) === 0 && ((1 << (_la - 40)) & ((1 << (timingParser.T__39 - 40)) | (1 << (timingParser.T__40 - 40)) | (1 << (timingParser.T__41 - 40)) | (1 << (timingParser.T__42 - 40)) | (1 << (timingParser.T__43 - 40)) | (1 << (timingParser.T__44 - 40)) | (1 << (timingParser.T__45 - 40)) | (1 << (timingParser.T__46 - 40)) | (1 << (timingParser.T__47 - 40)) | (1 << (timingParser.T__48 - 40)) | (1 << (timingParser.T__49 - 40)) | (1 << (timingParser.T__50 - 40)) | (1 << (timingParser.T__51 - 40)) | (1 << (timingParser.T__52 - 40)) | (1 << (timingParser.T__53 - 40)) | (1 << (timingParser.T__54 - 40)) | (1 << (timingParser.T__55 - 40)) | (1 << (timingParser.T__56 - 40)) | (1 << (timingParser.T__57 - 40)) | (1 << (timingParser.T__58 - 40)))) !== 0) || _la === timingParser.NUM) {
					{
					this.state = 134;
					this.time_in_day();
					}
				}

				}
				break;

			case 4:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 137;
				this.date();
				this.state = 139;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === timingParser.T__12 || ((((_la - 40)) & ~0x1F) === 0 && ((1 << (_la - 40)) & ((1 << (timingParser.T__39 - 40)) | (1 << (timingParser.T__40 - 40)) | (1 << (timingParser.T__41 - 40)) | (1 << (timingParser.T__42 - 40)) | (1 << (timingParser.T__43 - 40)) | (1 << (timingParser.T__44 - 40)) | (1 << (timingParser.T__45 - 40)) | (1 << (timingParser.T__46 - 40)) | (1 << (timingParser.T__47 - 40)) | (1 << (timingParser.T__48 - 40)) | (1 << (timingParser.T__49 - 40)) | (1 << (timingParser.T__50 - 40)) | (1 << (timingParser.T__51 - 40)) | (1 << (timingParser.T__52 - 40)) | (1 << (timingParser.T__53 - 40)) | (1 << (timingParser.T__54 - 40)) | (1 << (timingParser.T__55 - 40)) | (1 << (timingParser.T__56 - 40)) | (1 << (timingParser.T__57 - 40)) | (1 << (timingParser.T__58 - 40)))) !== 0) || _la === timingParser.NUM) {
					{
					this.state = 138;
					this.time_in_day();
					}
				}

				}
				break;

			case 5:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 141;
				this.time_in_day();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public priority(): PriorityContext {
		let _localctx: PriorityContext = new PriorityContext(this._ctx, this.state);
		this.enterRule(_localctx, 20, timingParser.RULE_priority);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 144;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << timingParser.T__13) | (1 << timingParser.T__14) | (1 << timingParser.T__15) | (1 << timingParser.T__16) | (1 << timingParser.T__17))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public frequency(): FrequencyContext {
		let _localctx: FrequencyContext = new FrequencyContext(this._ctx, this.state);
		this.enterRule(_localctx, 22, timingParser.RULE_frequency);
		try {
			this.state = 151;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case timingParser.T__18:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 146;
				this.match(timingParser.T__18);
				}
				break;
			case timingParser.T__19:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 147;
				this.match(timingParser.T__19);
				}
				break;
			case timingParser.T__20:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 148;
				this.match(timingParser.T__20);
				}
				break;
			case timingParser.T__71:
			case timingParser.T__72:
			case timingParser.T__73:
			case timingParser.T__74:
			case timingParser.QNUNIT:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 149;
				this.od();
				}
				break;
			case timingParser.T__75:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 150;
				this.frequency_for_one_day();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public date(): DateContext {
		let _localctx: DateContext = new DateContext(this._ctx, this.state);
		this.enterRule(_localctx, 24, timingParser.RULE_date);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 153;
			this.match(timingParser.NUM);
			this.state = 154;
			this.match(timingParser.DASH);
			this.state = 155;
			this.match(timingParser.NUM);
			this.state = 156;
			this.match(timingParser.DASH);
			this.state = 157;
			this.match(timingParser.NUM);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public days(): DaysContext {
		let _localctx: DaysContext = new DaysContext(this._ctx, this.state);
		this.enterRule(_localctx, 26, timingParser.RULE_days);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 159;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << timingParser.T__21) | (1 << timingParser.T__22) | (1 << timingParser.T__23) | (1 << timingParser.T__24) | (1 << timingParser.T__25) | (1 << timingParser.T__26) | (1 << timingParser.T__27) | (1 << timingParser.T__28))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public period_unit(): Period_unitContext {
		let _localctx: Period_unitContext = new Period_unitContext(this._ctx, this.state);
		this.enterRule(_localctx, 28, timingParser.RULE_period_unit);
		try {
			this.state = 165;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case timingParser.T__7:
			case timingParser.T__35:
			case timingParser.T__36:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 161;
				this.day_unit();
				}
				break;
			case timingParser.T__8:
			case timingParser.T__33:
			case timingParser.T__34:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 162;
				this.week_unit();
				}
				break;
			case timingParser.T__9:
			case timingParser.T__31:
			case timingParser.T__32:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 163;
				this.month_unit();
				}
				break;
			case timingParser.T__6:
			case timingParser.T__29:
			case timingParser.T__30:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 164;
				this.hour_unit();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public hour_unit(): Hour_unitContext {
		let _localctx: Hour_unitContext = new Hour_unitContext(this._ctx, this.state);
		this.enterRule(_localctx, 30, timingParser.RULE_hour_unit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 167;
			_la = this._input.LA(1);
			if (!((((_la) & ~0x1F) === 0 && ((1 << _la) & ((1 << timingParser.T__6) | (1 << timingParser.T__29) | (1 << timingParser.T__30))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public month_unit(): Month_unitContext {
		let _localctx: Month_unitContext = new Month_unitContext(this._ctx, this.state);
		this.enterRule(_localctx, 32, timingParser.RULE_month_unit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 169;
			_la = this._input.LA(1);
			if (!(((((_la - 10)) & ~0x1F) === 0 && ((1 << (_la - 10)) & ((1 << (timingParser.T__9 - 10)) | (1 << (timingParser.T__31 - 10)) | (1 << (timingParser.T__32 - 10)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public week_unit(): Week_unitContext {
		let _localctx: Week_unitContext = new Week_unitContext(this._ctx, this.state);
		this.enterRule(_localctx, 34, timingParser.RULE_week_unit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 171;
			_la = this._input.LA(1);
			if (!(((((_la - 9)) & ~0x1F) === 0 && ((1 << (_la - 9)) & ((1 << (timingParser.T__8 - 9)) | (1 << (timingParser.T__33 - 9)) | (1 << (timingParser.T__34 - 9)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public day_unit(): Day_unitContext {
		let _localctx: Day_unitContext = new Day_unitContext(this._ctx, this.state);
		this.enterRule(_localctx, 36, timingParser.RULE_day_unit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 173;
			_la = this._input.LA(1);
			if (!(((((_la - 8)) & ~0x1F) === 0 && ((1 << (_la - 8)) & ((1 << (timingParser.T__7 - 8)) | (1 << (timingParser.T__35 - 8)) | (1 << (timingParser.T__36 - 8)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public time_in_day(): Time_in_dayContext {
		let _localctx: Time_in_dayContext = new Time_in_dayContext(this._ctx, this.state);
		this.enterRule(_localctx, 38, timingParser.RULE_time_in_day);
		let _la: number;
		try {
			this.state = 186;
			this._errHandler.sync(this);
			switch ( this.interpreter.adaptivePredict(this._input, 20, this._ctx) ) {
			case 1:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 176;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === timingParser.NUM) {
					{
					this.state = 175;
					this.time_interval();
					}
				}

				this.state = 179;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === timingParser.T__12 || ((((_la - 53)) & ~0x1F) === 0 && ((1 << (_la - 53)) & ((1 << (timingParser.T__52 - 53)) | (1 << (timingParser.T__53 - 53)) | (1 << (timingParser.T__54 - 53)) | (1 << (timingParser.T__55 - 53)) | (1 << (timingParser.T__56 - 53)) | (1 << (timingParser.T__57 - 53)) | (1 << (timingParser.T__58 - 53)))) !== 0)) {
					{
					this.state = 178;
					this.prefix();
					}
				}

				this.state = 183;
				this._errHandler.sync(this);
				switch (this._input.LA(1)) {
				case timingParser.T__41:
				case timingParser.T__42:
				case timingParser.T__43:
				case timingParser.T__44:
				case timingParser.T__45:
				case timingParser.T__46:
				case timingParser.T__47:
				case timingParser.T__48:
				case timingParser.T__49:
				case timingParser.T__50:
				case timingParser.T__51:
					{
					this.state = 181;
					this.named_time();
					}
					break;
				case timingParser.T__39:
				case timingParser.T__40:
					{
					this.state = 182;
					this.iterative_named_time();
					}
					break;
				default:
					throw new NoViableAltException(this);
				}
				}
				break;

			case 2:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 185;
				this.absolute_time();
				}
				break;
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public absolute_time(): Absolute_timeContext {
		let _localctx: Absolute_timeContext = new Absolute_timeContext(this._ctx, this.state);
		this.enterRule(_localctx, 40, timingParser.RULE_absolute_time);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 188;
			this.time();
			this.state = 189;
			this.am_pm();
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public am_pm(): Am_pmContext {
		let _localctx: Am_pmContext = new Am_pmContext(this._ctx, this.state);
		this.enterRule(_localctx, 42, timingParser.RULE_am_pm);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 191;
			_la = this._input.LA(1);
			if (!(_la === timingParser.T__37 || _la === timingParser.T__38)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public time(): TimeContext {
		let _localctx: TimeContext = new TimeContext(this._ctx, this.state);
		this.enterRule(_localctx, 44, timingParser.RULE_time);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 193;
			this.match(timingParser.NUM);
			this.state = 196;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			if (_la === timingParser.COLON) {
				{
				this.state = 194;
				this.match(timingParser.COLON);
				this.state = 195;
				this.match(timingParser.NUM);
				}
			}

			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public iterative_named_time(): Iterative_named_timeContext {
		let _localctx: Iterative_named_timeContext = new Iterative_named_timeContext(this._ctx, this.state);
		this.enterRule(_localctx, 46, timingParser.RULE_iterative_named_time);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 198;
			_la = this._input.LA(1);
			if (!(_la === timingParser.T__39 || _la === timingParser.T__40)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public named_time(): Named_timeContext {
		let _localctx: Named_timeContext = new Named_timeContext(this._ctx, this.state);
		this.enterRule(_localctx, 48, timingParser.RULE_named_time);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 200;
			_la = this._input.LA(1);
			if (!(((((_la - 42)) & ~0x1F) === 0 && ((1 << (_la - 42)) & ((1 << (timingParser.T__41 - 42)) | (1 << (timingParser.T__42 - 42)) | (1 << (timingParser.T__43 - 42)) | (1 << (timingParser.T__44 - 42)) | (1 << (timingParser.T__45 - 42)) | (1 << (timingParser.T__46 - 42)) | (1 << (timingParser.T__47 - 42)) | (1 << (timingParser.T__48 - 42)) | (1 << (timingParser.T__49 - 42)) | (1 << (timingParser.T__50 - 42)) | (1 << (timingParser.T__51 - 42)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public prefix(): PrefixContext {
		let _localctx: PrefixContext = new PrefixContext(this._ctx, this.state);
		this.enterRule(_localctx, 50, timingParser.RULE_prefix);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 202;
			_la = this._input.LA(1);
			if (!(_la === timingParser.T__12 || ((((_la - 53)) & ~0x1F) === 0 && ((1 << (_la - 53)) & ((1 << (timingParser.T__52 - 53)) | (1 << (timingParser.T__53 - 53)) | (1 << (timingParser.T__54 - 53)) | (1 << (timingParser.T__55 - 53)) | (1 << (timingParser.T__56 - 53)) | (1 << (timingParser.T__57 - 53)) | (1 << (timingParser.T__58 - 53)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public time_interval(): Time_intervalContext {
		let _localctx: Time_intervalContext = new Time_intervalContext(this._ctx, this.state);
		this.enterRule(_localctx, 52, timingParser.RULE_time_interval);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 204;
			this.match(timingParser.NUM);
			this.state = 207;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case timingParser.T__6:
			case timingParser.T__29:
			case timingParser.T__30:
				{
				this.state = 205;
				this.hour_unit();
				}
				break;
			case timingParser.T__59:
			case timingParser.T__60:
			case timingParser.T__61:
				{
				this.state = 206;
				this.minute_unit();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public minute_unit(): Minute_unitContext {
		let _localctx: Minute_unitContext = new Minute_unitContext(this._ctx, this.state);
		this.enterRule(_localctx, 54, timingParser.RULE_minute_unit);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 209;
			_la = this._input.LA(1);
			if (!(((((_la - 60)) & ~0x1F) === 0 && ((1 << (_la - 60)) & ((1 << (timingParser.T__59 - 60)) | (1 << (timingParser.T__60 - 60)) | (1 << (timingParser.T__61 - 60)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public weekdays(): WeekdaysContext {
		let _localctx: WeekdaysContext = new WeekdaysContext(this._ctx, this.state);
		this.enterRule(_localctx, 56, timingParser.RULE_weekdays);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 211;
			_la = this._input.LA(1);
			if (!(((((_la - 63)) & ~0x1F) === 0 && ((1 << (_la - 63)) & ((1 << (timingParser.T__62 - 63)) | (1 << (timingParser.T__63 - 63)) | (1 << (timingParser.T__64 - 63)) | (1 << (timingParser.T__65 - 63)) | (1 << (timingParser.T__66 - 63)) | (1 << (timingParser.T__67 - 63)) | (1 << (timingParser.T__68 - 63)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public odd_evens(): Odd_evensContext {
		let _localctx: Odd_evensContext = new Odd_evensContext(this._ctx, this.state);
		this.enterRule(_localctx, 58, timingParser.RULE_odd_evens);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 213;
			_la = this._input.LA(1);
			if (!(_la === timingParser.T__69 || _la === timingParser.T__70)) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public od(): OdContext {
		let _localctx: OdContext = new OdContext(this._ctx, this.state);
		this.enterRule(_localctx, 60, timingParser.RULE_od);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 215;
			_la = this._input.LA(1);
			if (!(((((_la - 72)) & ~0x1F) === 0 && ((1 << (_la - 72)) & ((1 << (timingParser.T__71 - 72)) | (1 << (timingParser.T__72 - 72)) | (1 << (timingParser.T__73 - 72)) | (1 << (timingParser.T__74 - 72)) | (1 << (timingParser.QNUNIT - 72)))) !== 0))) {
			this._errHandler.recoverInline(this);
			} else {
				if (this._input.LA(1) === Token.EOF) {
					this.matchedEOF = true;
				}

				this._errHandler.reportMatch(this);
				this.consume();
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public frequency_for_one_day(): Frequency_for_one_dayContext {
		let _localctx: Frequency_for_one_dayContext = new Frequency_for_one_dayContext(this._ctx, this.state);
		this.enterRule(_localctx, 62, timingParser.RULE_frequency_for_one_day);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 217;
			this.match(timingParser.T__75);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03U\xDE\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04" +
		"\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t\x12\x04" +
		"\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t\x17\x04" +
		"\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t\x1C\x04" +
		"\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x03\x02\x03\x02" +
		"\x03\x02\x07\x02F\n\x02\f\x02\x0E\x02I\v\x02\x03\x02\x03\x02\x03\x03\x03" +
		"\x03\x05\x03O\n\x03\x03\x03\x05\x03R\n\x03\x03\x03\x05\x03U\n\x03\x03" +
		"\x03\x05\x03X\n\x03\x03\x03\x03\x03\x03\x03\x05\x03]\n\x03\x03\x04\x03" +
		"\x04\x03\x04\x03\x04\x03\x05\x03\x05\x03\x06\x03\x06\x03\x07\x03\x07\x03" +
		"\x07\x05\x07j\n\x07\x03\b\x03\b\x03\b\x03\b\x03\t\x03\t\x03\t\x05\ts\n" +
		"\t\x03\n\x03\n\x03\n\x07\nx\n\n\f\n\x0E\n{\v\n\x03\v\x03\v\x05\v\x7F\n" +
		"\v\x03\v\x05\v\x82\n\v\x03\v\x03\v\x05\v\x86\n\v\x03\v\x03\v\x05\v\x8A" +
		"\n\v\x03\v\x03\v\x05\v\x8E\n\v\x03\v\x05\v\x91\n\v\x03\f\x03\f\x03\r\x03" +
		"\r\x03\r\x03\r\x03\r\x05\r\x9A\n\r\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03" +
		"\x0E\x03\x0E\x03\x0F\x03\x0F\x03\x10\x03\x10\x03\x10\x03\x10\x05\x10\xA8" +
		"\n\x10\x03\x11\x03\x11\x03\x12\x03\x12\x03\x13\x03\x13\x03\x14\x03\x14" +
		"\x03\x15\x05\x15\xB3\n\x15\x03\x15\x05\x15\xB6\n\x15\x03\x15\x03\x15\x05" +
		"\x15\xBA\n\x15\x03\x15\x05\x15\xBD\n\x15\x03\x16\x03\x16\x03\x16\x03\x17" +
		"\x03\x17\x03\x18\x03\x18\x03\x18\x05\x18\xC7\n\x18\x03\x19\x03\x19\x03" +
		"\x1A\x03\x1A\x03\x1B\x03\x1B\x03\x1C\x03\x1C\x03\x1C\x05\x1C\xD2\n\x1C" +
		"\x03\x1D\x03\x1D\x03\x1E\x03\x1E\x03\x1F\x03\x1F\x03 \x03 \x03!\x03!\x03" +
		"!\x02\x02\x02\"\x02\x02\x04\x02\x06\x02\b\x02\n\x02\f\x02\x0E\x02\x10" +
		"\x02\x12\x02\x14\x02\x16\x02\x18\x02\x1A\x02\x1C\x02\x1E\x02 \x02\"\x02" +
		"$\x02&\x02(\x02*\x02,\x02.\x020\x022\x024\x026\x028\x02:\x02<\x02>\x02" +
		"@\x02\x02\x12\x03\x02\x06\b\x03\x02\t\f\x03\x02\x10\x14\x03\x02\x18\x1F" +
		"\x04\x02\t\t !\x04\x02\f\f\"#\x04\x02\v\v$%\x04\x02\n\n&\'\x03\x02()\x03" +
		"\x02*+\x03\x02,6\x04\x02\x0F\x0F7=\x03\x02>@\x03\x02AG\x03\x02HI\x04\x02" +
		"JMOO\x02\xDE\x02B\x03\x02\x02\x02\x04N\x03\x02\x02\x02\x06^\x03\x02\x02" +
		"\x02\bb\x03\x02\x02\x02\nd\x03\x02\x02\x02\ff\x03\x02\x02\x02\x0Ek\x03" +
		"\x02\x02\x02\x10o\x03\x02\x02\x02\x12t\x03\x02\x02\x02\x14\x90\x03\x02" +
		"\x02\x02\x16\x92\x03\x02\x02\x02\x18\x99\x03\x02\x02\x02\x1A\x9B\x03\x02" +
		"\x02\x02\x1C\xA1\x03\x02\x02\x02\x1E\xA7\x03\x02\x02\x02 \xA9\x03\x02" +
		"\x02\x02\"\xAB\x03\x02\x02\x02$\xAD\x03\x02\x02\x02&\xAF\x03\x02\x02\x02" +
		"(\xBC\x03\x02\x02\x02*\xBE\x03\x02\x02\x02,\xC1\x03\x02\x02\x02.\xC3\x03" +
		"\x02\x02\x020\xC8\x03\x02\x02\x022\xCA\x03\x02\x02\x024\xCC\x03\x02\x02" +
		"\x026\xCE\x03\x02\x02\x028\xD3\x03\x02\x02\x02:\xD5\x03\x02\x02\x02<\xD7" +
		"\x03\x02\x02\x02>\xD9\x03\x02\x02\x02@\xDB\x03\x02\x02\x02BG\x05\x04\x03" +
		"\x02CD\x07\x03\x02\x02DF\x05\x04\x03\x02EC\x03\x02\x02\x02FI\x03\x02\x02" +
		"\x02GE\x03\x02\x02\x02GH\x03\x02\x02\x02HJ\x03\x02\x02\x02IG\x03\x02\x02" +
		"\x02JK\x07\x02\x02\x03K\x03\x03\x02\x02\x02LO\x05\x18\r\x02MO\x05\x16" +
		"\f\x02NL\x03\x02\x02\x02NM\x03\x02\x02\x02OT\x03\x02\x02\x02PR\x07\x04" +
		"\x02\x02QP\x03\x02\x02\x02QR\x03\x02\x02\x02RS\x03\x02\x02\x02SU\x05\x12" +
		"\n\x02TQ\x03\x02\x02\x02TU\x03\x02\x02\x02UW\x03\x02\x02\x02VX\x05\x10" +
		"\t\x02WV\x03\x02\x02\x02WX\x03\x02\x02\x02X\\\x03\x02\x02\x02Y]\x05\x0E" +
		"\b\x02Z]\x05\f\x07\x02[]\x05\x06\x04\x02\\Y\x03\x02\x02\x02\\Z\x03\x02" +
		"\x02\x02\\[\x03\x02\x02\x02\\]\x03\x02\x02\x02]\x05\x03\x02\x02\x02^_" +
		"\x07\x05\x02\x02_`\x05\b\x05\x02`a\x05\n\x06\x02a\x07\x03\x02\x02\x02" +
		"bc\t\x02\x02\x02c\t\x03\x02\x02\x02de\t\x03\x02\x02e\v\x03\x02\x02\x02" +
		"fi\x07\r\x02\x02gj\x05\x1C\x0F\x02hj\x05\x1A\x0E\x02ig\x03\x02\x02\x02" +
		"ih\x03\x02\x02\x02j\r\x03\x02\x02\x02kl\x07\x05\x02\x02lm\x07P\x02\x02" +
		"mn\x05\x1E\x10\x02n\x0F\x03\x02\x02\x02or\x07\x0E\x02\x02ps\x05\x1C\x0F" +
		"\x02qs\x05\x1A\x0E\x02rp\x03\x02\x02\x02rq\x03\x02\x02\x02s\x11\x03\x02" +
		"\x02\x02ty\x05\x14\v\x02uv\x07R\x02\x02vx\x05\x14\v\x02wu\x03\x02\x02" +
		"\x02x{\x03\x02\x02\x02yw\x03\x02\x02\x02yz\x03\x02\x02\x02z\x13\x03\x02" +
		"\x02\x02{y\x03\x02\x02\x02|~\x05<\x1F\x02}\x7F\x05(\x15\x02~}\x03\x02" +
		"\x02\x02~\x7F\x03\x02\x02\x02\x7F\x91\x03\x02\x02\x02\x80\x82\x07\x0F" +
		"\x02\x02\x81\x80\x03\x02\x02\x02\x81\x82\x03\x02\x02\x02\x82\x83\x03\x02" +
		"\x02\x02\x83\x85\x05:\x1E\x02\x84\x86\x05(\x15\x02\x85\x84\x03\x02\x02" +
		"\x02\x85\x86\x03\x02\x02\x02\x86\x91\x03\x02\x02\x02\x87\x89\x05\x1C\x0F" +
		"\x02\x88\x8A\x05(\x15\x02\x89\x88\x03\x02\x02\x02\x89\x8A\x03\x02\x02" +
		"\x02\x8A\x91\x03\x02\x02\x02\x8B\x8D\x05\x1A\x0E\x02\x8C\x8E\x05(\x15" +
		"\x02\x8D\x8C\x03\x02\x02\x02\x8D\x8E\x03\x02\x02\x02\x8E\x91\x03\x02\x02" +
		"\x02\x8F\x91\x05(\x15\x02\x90|\x03\x02\x02\x02\x90\x81\x03\x02\x02\x02" +
		"\x90\x87\x03\x02\x02\x02\x90\x8B\x03\x02\x02\x02\x90\x8F\x03\x02\x02\x02" +
		"\x91\x15\x03\x02\x02\x02\x92\x93\t\x04\x02\x02\x93\x17\x03\x02\x02\x02" +
		"\x94\x9A\x07\x15\x02\x02\x95\x9A\x07\x16\x02\x02\x96\x9A\x07\x17\x02\x02" +
		"\x97\x9A\x05> \x02\x98\x9A\x05@!\x02\x99\x94\x03\x02\x02\x02\x99\x95\x03" +
		"\x02\x02\x02\x99\x96\x03\x02\x02\x02\x99\x97\x03\x02\x02\x02\x99\x98\x03" +
		"\x02\x02\x02\x9A\x19\x03\x02\x02\x02\x9B\x9C\x07P\x02\x02\x9C\x9D\x07" +
		"U\x02\x02\x9D\x9E\x07P\x02\x02\x9E\x9F\x07U\x02\x02\x9F\xA0\x07P\x02\x02" +
		"\xA0\x1B\x03\x02\x02\x02\xA1\xA2\t\x05\x02\x02\xA2\x1D\x03\x02\x02\x02" +
		"\xA3\xA8\x05&\x14\x02\xA4\xA8\x05$\x13\x02\xA5\xA8\x05\"\x12\x02\xA6\xA8" +
		"\x05 \x11\x02\xA7\xA3\x03\x02\x02\x02\xA7\xA4\x03\x02\x02\x02\xA7\xA5" +
		"\x03\x02\x02\x02\xA7\xA6\x03\x02\x02\x02\xA8\x1F\x03\x02\x02\x02\xA9\xAA" +
		"\t\x06\x02\x02\xAA!\x03\x02\x02\x02\xAB\xAC\t\x07\x02\x02\xAC#\x03\x02" +
		"\x02\x02\xAD\xAE\t\b\x02\x02\xAE%\x03\x02\x02\x02\xAF\xB0\t\t\x02\x02" +
		"\xB0\'\x03\x02\x02\x02\xB1\xB3\x056\x1C\x02\xB2\xB1\x03\x02\x02\x02\xB2" +
		"\xB3\x03\x02\x02\x02\xB3\xB5\x03\x02\x02\x02\xB4\xB6\x054\x1B\x02\xB5" +
		"\xB4\x03\x02\x02\x02\xB5\xB6\x03\x02\x02\x02\xB6\xB9\x03\x02\x02\x02\xB7" +
		"\xBA\x052\x1A\x02\xB8\xBA\x050\x19\x02\xB9\xB7\x03\x02\x02\x02\xB9\xB8" +
		"\x03\x02\x02\x02\xBA\xBD\x03\x02\x02\x02\xBB\xBD\x05*\x16\x02\xBC\xB2" +
		"\x03\x02\x02\x02\xBC\xBB\x03\x02\x02\x02\xBD)\x03\x02\x02\x02\xBE\xBF" +
		"\x05.\x18\x02\xBF\xC0\x05,\x17\x02\xC0+\x03\x02\x02\x02\xC1\xC2\t\n\x02" +
		"\x02\xC2-\x03\x02\x02\x02\xC3\xC6\x07P\x02\x02\xC4\xC5\x07T\x02\x02\xC5" +
		"\xC7\x07P\x02\x02\xC6\xC4\x03\x02\x02\x02\xC6\xC7\x03\x02\x02\x02\xC7" +
		"/\x03\x02\x02\x02\xC8\xC9\t\v\x02\x02\xC91\x03\x02\x02\x02\xCA\xCB\t\f" +
		"\x02\x02\xCB3\x03\x02\x02\x02\xCC\xCD\t\r\x02\x02\xCD5\x03\x02\x02\x02" +
		"\xCE\xD1\x07P\x02\x02\xCF\xD2\x05 \x11\x02\xD0\xD2\x058\x1D\x02\xD1\xCF" +
		"\x03\x02\x02\x02\xD1\xD0\x03\x02\x02\x02\xD27\x03\x02\x02\x02\xD3\xD4" +
		"\t\x0E\x02\x02\xD49\x03\x02\x02\x02\xD5\xD6\t\x0F\x02\x02\xD6;\x03\x02" +
		"\x02\x02\xD7\xD8\t\x10\x02\x02\xD8=\x03\x02\x02\x02\xD9\xDA\t\x11\x02" +
		"\x02\xDA?\x03\x02\x02\x02\xDB\xDC\x07N\x02\x02\xDCA\x03\x02\x02\x02\x19" +
		"GNQTW\\iry~\x81\x85\x89\x8D\x90\x99\xA7\xB2\xB5\xB9\xBC\xC6\xD1";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!timingParser.__ATN) {
			timingParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(timingParser._serializedATN));
		}

		return timingParser.__ATN;
	}

}

export class MainContext extends ParserRuleContext {
	public timing(): TimingContext[];
	public timing(i: number): TimingContext;
	public timing(i?: number): TimingContext | TimingContext[] {
		if (i === undefined) {
			return this.getRuleContexts(TimingContext);
		} else {
			return this.getRuleContext(i, TimingContext);
		}
	}
	public EOF(): TerminalNode { return this.getToken(timingParser.EOF, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_main; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterMain) {
			listener.enterMain(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitMain) {
			listener.exitMain(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitMain) {
			return visitor.visitMain(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class TimingContext extends ParserRuleContext {
	public frequency(): FrequencyContext | undefined {
		return this.tryGetRuleContext(0, FrequencyContext);
	}
	public priority(): PriorityContext | undefined {
		return this.tryGetRuleContext(0, PriorityContext);
	}
	public time_points(): Time_pointsContext | undefined {
		return this.tryGetRuleContext(0, Time_pointsContext);
	}
	public start_period(): Start_periodContext | undefined {
		return this.tryGetRuleContext(0, Start_periodContext);
	}
	public time_period(): Time_periodContext | undefined {
		return this.tryGetRuleContext(0, Time_periodContext);
	}
	public end_period(): End_periodContext | undefined {
		return this.tryGetRuleContext(0, End_periodContext);
	}
	public ordinal_time(): Ordinal_timeContext | undefined {
		return this.tryGetRuleContext(0, Ordinal_timeContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_timing; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterTiming) {
			listener.enterTiming(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitTiming) {
			listener.exitTiming(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitTiming) {
			return visitor.visitTiming(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Ordinal_timeContext extends ParserRuleContext {
	public ordinal(): OrdinalContext {
		return this.getRuleContext(0, OrdinalContext);
	}
	public ordinal_unit(): Ordinal_unitContext {
		return this.getRuleContext(0, Ordinal_unitContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_ordinal_time; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterOrdinal_time) {
			listener.enterOrdinal_time(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitOrdinal_time) {
			listener.exitOrdinal_time(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitOrdinal_time) {
			return visitor.visitOrdinal_time(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OrdinalContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_ordinal; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterOrdinal) {
			listener.enterOrdinal(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitOrdinal) {
			listener.exitOrdinal(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitOrdinal) {
			return visitor.visitOrdinal(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Ordinal_unitContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_ordinal_unit; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterOrdinal_unit) {
			listener.enterOrdinal_unit(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitOrdinal_unit) {
			listener.exitOrdinal_unit(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitOrdinal_unit) {
			return visitor.visitOrdinal_unit(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class End_periodContext extends ParserRuleContext {
	public days(): DaysContext | undefined {
		return this.tryGetRuleContext(0, DaysContext);
	}
	public date(): DateContext | undefined {
		return this.tryGetRuleContext(0, DateContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_end_period; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterEnd_period) {
			listener.enterEnd_period(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitEnd_period) {
			listener.exitEnd_period(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitEnd_period) {
			return visitor.visitEnd_period(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Time_periodContext extends ParserRuleContext {
	public NUM(): TerminalNode { return this.getToken(timingParser.NUM, 0); }
	public period_unit(): Period_unitContext {
		return this.getRuleContext(0, Period_unitContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_time_period; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterTime_period) {
			listener.enterTime_period(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitTime_period) {
			listener.exitTime_period(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitTime_period) {
			return visitor.visitTime_period(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Start_periodContext extends ParserRuleContext {
	public days(): DaysContext | undefined {
		return this.tryGetRuleContext(0, DaysContext);
	}
	public date(): DateContext | undefined {
		return this.tryGetRuleContext(0, DateContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_start_period; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterStart_period) {
			listener.enterStart_period(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitStart_period) {
			listener.exitStart_period(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitStart_period) {
			return visitor.visitStart_period(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Time_pointsContext extends ParserRuleContext {
	public time_point(): Time_pointContext[];
	public time_point(i: number): Time_pointContext;
	public time_point(i?: number): Time_pointContext | Time_pointContext[] {
		if (i === undefined) {
			return this.getRuleContexts(Time_pointContext);
		} else {
			return this.getRuleContext(i, Time_pointContext);
		}
	}
	public AND(): TerminalNode[];
	public AND(i: number): TerminalNode;
	public AND(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(timingParser.AND);
		} else {
			return this.getToken(timingParser.AND, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_time_points; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterTime_points) {
			listener.enterTime_points(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitTime_points) {
			listener.exitTime_points(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitTime_points) {
			return visitor.visitTime_points(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Time_pointContext extends ParserRuleContext {
	public odd_evens(): Odd_evensContext | undefined {
		return this.tryGetRuleContext(0, Odd_evensContext);
	}
	public time_in_day(): Time_in_dayContext | undefined {
		return this.tryGetRuleContext(0, Time_in_dayContext);
	}
	public weekdays(): WeekdaysContext | undefined {
		return this.tryGetRuleContext(0, WeekdaysContext);
	}
	public days(): DaysContext | undefined {
		return this.tryGetRuleContext(0, DaysContext);
	}
	public date(): DateContext | undefined {
		return this.tryGetRuleContext(0, DateContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_time_point; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterTime_point) {
			listener.enterTime_point(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitTime_point) {
			listener.exitTime_point(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitTime_point) {
			return visitor.visitTime_point(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class PriorityContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_priority; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterPriority) {
			listener.enterPriority(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitPriority) {
			listener.exitPriority(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitPriority) {
			return visitor.visitPriority(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class FrequencyContext extends ParserRuleContext {
	public od(): OdContext | undefined {
		return this.tryGetRuleContext(0, OdContext);
	}
	public frequency_for_one_day(): Frequency_for_one_dayContext | undefined {
		return this.tryGetRuleContext(0, Frequency_for_one_dayContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_frequency; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterFrequency) {
			listener.enterFrequency(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitFrequency) {
			listener.exitFrequency(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitFrequency) {
			return visitor.visitFrequency(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class DateContext extends ParserRuleContext {
	public NUM(): TerminalNode[];
	public NUM(i: number): TerminalNode;
	public NUM(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(timingParser.NUM);
		} else {
			return this.getToken(timingParser.NUM, i);
		}
	}
	public DASH(): TerminalNode[];
	public DASH(i: number): TerminalNode;
	public DASH(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(timingParser.DASH);
		} else {
			return this.getToken(timingParser.DASH, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_date; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterDate) {
			listener.enterDate(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitDate) {
			listener.exitDate(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitDate) {
			return visitor.visitDate(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class DaysContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_days; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterDays) {
			listener.enterDays(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitDays) {
			listener.exitDays(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitDays) {
			return visitor.visitDays(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Period_unitContext extends ParserRuleContext {
	public day_unit(): Day_unitContext | undefined {
		return this.tryGetRuleContext(0, Day_unitContext);
	}
	public week_unit(): Week_unitContext | undefined {
		return this.tryGetRuleContext(0, Week_unitContext);
	}
	public month_unit(): Month_unitContext | undefined {
		return this.tryGetRuleContext(0, Month_unitContext);
	}
	public hour_unit(): Hour_unitContext | undefined {
		return this.tryGetRuleContext(0, Hour_unitContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_period_unit; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterPeriod_unit) {
			listener.enterPeriod_unit(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitPeriod_unit) {
			listener.exitPeriod_unit(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitPeriod_unit) {
			return visitor.visitPeriod_unit(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Hour_unitContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_hour_unit; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterHour_unit) {
			listener.enterHour_unit(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitHour_unit) {
			listener.exitHour_unit(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitHour_unit) {
			return visitor.visitHour_unit(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Month_unitContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_month_unit; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterMonth_unit) {
			listener.enterMonth_unit(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitMonth_unit) {
			listener.exitMonth_unit(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitMonth_unit) {
			return visitor.visitMonth_unit(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Week_unitContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_week_unit; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterWeek_unit) {
			listener.enterWeek_unit(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitWeek_unit) {
			listener.exitWeek_unit(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitWeek_unit) {
			return visitor.visitWeek_unit(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Day_unitContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_day_unit; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterDay_unit) {
			listener.enterDay_unit(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitDay_unit) {
			listener.exitDay_unit(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitDay_unit) {
			return visitor.visitDay_unit(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Time_in_dayContext extends ParserRuleContext {
	public named_time(): Named_timeContext | undefined {
		return this.tryGetRuleContext(0, Named_timeContext);
	}
	public iterative_named_time(): Iterative_named_timeContext | undefined {
		return this.tryGetRuleContext(0, Iterative_named_timeContext);
	}
	public time_interval(): Time_intervalContext | undefined {
		return this.tryGetRuleContext(0, Time_intervalContext);
	}
	public prefix(): PrefixContext | undefined {
		return this.tryGetRuleContext(0, PrefixContext);
	}
	public absolute_time(): Absolute_timeContext | undefined {
		return this.tryGetRuleContext(0, Absolute_timeContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_time_in_day; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterTime_in_day) {
			listener.enterTime_in_day(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitTime_in_day) {
			listener.exitTime_in_day(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitTime_in_day) {
			return visitor.visitTime_in_day(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Absolute_timeContext extends ParserRuleContext {
	public time(): TimeContext {
		return this.getRuleContext(0, TimeContext);
	}
	public am_pm(): Am_pmContext {
		return this.getRuleContext(0, Am_pmContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_absolute_time; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterAbsolute_time) {
			listener.enterAbsolute_time(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitAbsolute_time) {
			listener.exitAbsolute_time(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitAbsolute_time) {
			return visitor.visitAbsolute_time(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Am_pmContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_am_pm; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterAm_pm) {
			listener.enterAm_pm(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitAm_pm) {
			listener.exitAm_pm(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitAm_pm) {
			return visitor.visitAm_pm(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class TimeContext extends ParserRuleContext {
	public NUM(): TerminalNode[];
	public NUM(i: number): TerminalNode;
	public NUM(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(timingParser.NUM);
		} else {
			return this.getToken(timingParser.NUM, i);
		}
	}
	public COLON(): TerminalNode | undefined { return this.tryGetToken(timingParser.COLON, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_time; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterTime) {
			listener.enterTime(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitTime) {
			listener.exitTime(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitTime) {
			return visitor.visitTime(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Iterative_named_timeContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_iterative_named_time; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterIterative_named_time) {
			listener.enterIterative_named_time(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitIterative_named_time) {
			listener.exitIterative_named_time(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitIterative_named_time) {
			return visitor.visitIterative_named_time(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Named_timeContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_named_time; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterNamed_time) {
			listener.enterNamed_time(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitNamed_time) {
			listener.exitNamed_time(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitNamed_time) {
			return visitor.visitNamed_time(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class PrefixContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_prefix; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterPrefix) {
			listener.enterPrefix(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitPrefix) {
			listener.exitPrefix(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitPrefix) {
			return visitor.visitPrefix(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Time_intervalContext extends ParserRuleContext {
	public NUM(): TerminalNode { return this.getToken(timingParser.NUM, 0); }
	public hour_unit(): Hour_unitContext | undefined {
		return this.tryGetRuleContext(0, Hour_unitContext);
	}
	public minute_unit(): Minute_unitContext | undefined {
		return this.tryGetRuleContext(0, Minute_unitContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_time_interval; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterTime_interval) {
			listener.enterTime_interval(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitTime_interval) {
			listener.exitTime_interval(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitTime_interval) {
			return visitor.visitTime_interval(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Minute_unitContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_minute_unit; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterMinute_unit) {
			listener.enterMinute_unit(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitMinute_unit) {
			listener.exitMinute_unit(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitMinute_unit) {
			return visitor.visitMinute_unit(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class WeekdaysContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_weekdays; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterWeekdays) {
			listener.enterWeekdays(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitWeekdays) {
			listener.exitWeekdays(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitWeekdays) {
			return visitor.visitWeekdays(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Odd_evensContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_odd_evens; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterOdd_evens) {
			listener.enterOdd_evens(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitOdd_evens) {
			listener.exitOdd_evens(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitOdd_evens) {
			return visitor.visitOdd_evens(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OdContext extends ParserRuleContext {
	public QNUNIT(): TerminalNode { return this.getToken(timingParser.QNUNIT, 0); }
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_od; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterOd) {
			listener.enterOd(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitOd) {
			listener.exitOd(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitOd) {
			return visitor.visitOd(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Frequency_for_one_dayContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return timingParser.RULE_frequency_for_one_day; }
	// @Override
	public enterRule(listener: timingListener): void {
		if (listener.enterFrequency_for_one_day) {
			listener.enterFrequency_for_one_day(this);
		}
	}
	// @Override
	public exitRule(listener: timingListener): void {
		if (listener.exitFrequency_for_one_day) {
			listener.exitFrequency_for_one_day(this);
		}
	}
	// @Override
	public accept<Result>(visitor: timingVisitor<Result>): Result {
		if (visitor.visitFrequency_for_one_day) {
			return visitor.visitFrequency_for_one_day(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


