export const getTrimmedText = (text) => {
  if (text) {
    return text.trim().replace(/\n\s*\n/g, '\n');
  }
  return text;
};

export const removeTags = (str) => {
  if (str === null || str === '' || str === undefined) return '';
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '');
};
