import React, { useState, useEffect } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import { answerConsult, createDraftReply } from '../../../action/consultAction';
import { addMessage } from '../../../action/generalActions';
import { useHistory, useLocation } from 'react-router-dom';
import usePathAction from '../../../Hook/usePathAction';
import { CheckRtl } from '../../../Utilities/CheckLang';
import { getTrimmedText } from '../../../Utilities/textUtil';
import {
  withStyles,
  makeStyles,
  TextField,
  Checkbox,
  Typography,
  Grid,
  Button,
  Box,
  CircularProgress,
} from '@material-ui/core';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import { AlertDialog } from '../../shared';
import CHECKED_ICON from '../../../Assets/checked.svg';
import CHECKBOX_ICON from '../../../Assets/check.svg';

const defaultReplyText = 'با سلام و تشکر از مشاوره';

const FormControlLabel = withStyles((theme) => ({
  root: {
    '&.checked': {
      '& $label': {
        color: theme.palette.text.primary,
      },
    },
    margin:"30px 0"
  },
  label: {
    fontFamily: 'IRANYekan',
    fontSize: 14,
    lineHeight: '15px',
    color: theme.palette.text.secondary,
    direction: 'rtl',
  },
}))(MuiFormControlLabel);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 24,
    textAlign: 'right',
    backgroundColor: '#fff',
  },
  inputLabel: {
    color: theme.palette.text.primary,
    transform: 'scale(1)',
  },
  paperReplyDescription: {
    fontFamily: 'IRANYekan',
    fontSize: 13,
    lineHeight: '14px',
    color: theme.palette.text.secondary,
    direction: 'rtl',
  },
}));

interface ConsultReplyFormProps {
  consult: any;
  canSubmitReply: boolean;
  canDraftReply: boolean;
  addMessage: (textMessage: string, status: number) => void;
  answerConsult: (consultId: number, reply: any) => any;
  createDraftReply: (consultId: number, reply: any) => any;
}

function ConsultReplyForm({
  consult,
  canSubmitReply,
  canDraftReply,
  addMessage,
  answerConsult,
  createDraftReply,
}: ConsultReplyFormProps) {
  const classes = useStyles();
  const location = useLocation<{ cardName?: string }>();
  const history = useHistory();
  const [pathAction, setPathAction] = usePathAction();
  const [cardName, setCardName] = useState('');
  const [values, setValues] = useState({
    reply: consult?.reply || defaultReplyText,
    paper_reply: Boolean(consult?.paper_reply),
  });
  const [pending, setPending] = useState('');

  useEffect(() => {
    setCardName(location.state?.cardName || '');
  }, []);

  const handleReplyChange = (e: any) => {
    const numberOfLines = e.target.value.split(/\r\n|\r|\n/).length;
    if (numberOfLines > 30 || e.target.value.length > 4000) {
      addMessage('The text is too long!', 400);
      return;
    }
    setValues({ ...values, reply: e.target.value });
  };

  const handlePaperReplyChange = (e: any) => {
    setValues({ ...values, paper_reply: e.target.checked });
  };

  const handleSubmitForm = (saveAsDraft: boolean) => {
    if ((saveAsDraft && !canDraftReply) || (!saveAsDraft && !canSubmitReply)) {
      return;
    }
    const reply = getTrimmedText(values.reply);
    if ((!reply || reply === defaultReplyText) && !values.paper_reply) {
      addMessage('please reply the consult or check for paper reply!', 400);
    } else {
      if (saveAsDraft) {
        handleDraftReply();
      } else {
        setPathAction('confirmReply');
      }
    }
  };

  const handleDraftReply = async () => {
    setPending('draft');
    const response = await createDraftReply(consult?.id, {
      ...values,
      reply: getTrimmedText(values.reply),
    });
    setPending('');
    handleAfterSubmitAction(response);
  };

  const handleSubmitReply = async () => {
    setPathAction('');
    setPending('submit');
    const response = await answerConsult(consult?.id, {
      ...values,
      reply: getTrimmedText(values.reply),
    });
    setPending('');
    handleAfterSubmitAction(response);
  };

  const handleAfterSubmitAction = async (response: any) => {
    if (response && response.status === 200) {
      if (cardName === 'consult') {
        history.replace('/patient', { refreshData: true });
      } else {
        history.goBack();
      }
    }
  };

  return (
    <form className={classes.root} onSubmit={(e) => e.preventDefault()}>
      {values.paper_reply ? (
        <Typography align='left'>Reply</Typography>
      ) : (
        <TextField
          multiline
          fullWidth
          label='Reply'
          InputLabelProps={{
            shrink: true,
            className: classes.inputLabel,
          }}
          inputProps={{
            style: {
              direction: CheckRtl(values.reply) ? 'rtl' : 'ltr',
            },
            className: CheckRtl(values.reply) ? 'farsiFont' : '',
          }}
          rowsMax={12}
          name='reply'
          value={values.reply}
          onChange={handleReplyChange}
        />
      )}

      <FormControlLabel
        control={
          <Checkbox
            icon={<img src={CHECKBOX_ICON} alt='check icon' />}
            checkedIcon={<img src={CHECKED_ICON} alt='checked icon' />}
            checked={values.paper_reply}
            name='paper_reply'
            onChange={handlePaperReplyChange}
          />
        }
        className={values.paper_reply ? 'checked' : ''}
        label='بصورت کاغذی پاسخ می دهم.'
        labelPlacement='start'
      />

      {values.paper_reply && (
        <Typography className={classes.paperReplyDescription}>
          در صورت پاسخ کاغذی، نیازی به ثبت پاسخ در Inlab نمی باشد.
        </Typography>
      )}

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant='outlined'
              color='primary'
              className='mediumPadding'
              disabled={Boolean(pending) || !canDraftReply}
              onClick={() => handleSubmitForm(true)}
            >
              {pending === 'draft' ? (
                <CircularProgress size={22} />
              ) : (
                'Save as Draft'
              )}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              className='mediumPadding'
              disabled={Boolean(pending) || !canSubmitReply}
              onClick={() => handleSubmitForm(false)}
            >
              {pending === 'submit' ? <CircularProgress size={22} /> : 'Reply'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      <AlertDialog
        dialogActionProps={undefined}
        open={pathAction === 'confirmReply'}
        alertText='Are You sure about submitting this answer?'
        confirmButton='true'
        confirmText='Yes'
        closeText='No'
        handleClose={() => setPathAction('')}
        handleConfirm={handleSubmitReply}
      />
    </form>
  );
}

export default connect(
  (state: RootStateOrAny) => ({
    canSubmitReply: Boolean(
      state.userReducer?.userData?.rank?.can_submit_consult_request_reply
    ),
    canDraftReply: Boolean(
      state.userReducer?.userData?.rank?.can_draft_consult_request_reply
    ),
  }),
  { addMessage, answerConsult, createDraftReply }
)(ConsultReplyForm);
