// in this component the word 'ward' and 'shift' can be used interchangeably
import React, { Component } from 'react';
import { BlueButton, ContentLoader, HeaderInput } from '../shared';
import { pickWard } from '../../action/actions';
import { pickShift } from '../../action/actions';
import { getUserCurrentData } from '../../action/userAction';
import {
  getAllRotation,
  getAllWards,
  getAllShifts,
  getAllServices,
} from '../../action/generalActions';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import { bindActionCreators } from 'redux';
import Radio from '@material-ui/core/Radio';
import { NoContent } from '../shared';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

const styles = {
  loadingContainer: {
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
// TODO: this should be refactored to DomainContainer (or s.th. similar)
class WardContainer extends Component {
  constructor() {
    super();
    this.state = {
      currentWardId: null,
      selectedWardId: '',
      wards: [],
      buttonLoader: false,
      loader: true,
      searchValue: '',
    };
  }
  async componentDidMount() {
    this.setState({ loader: true });

    if (this.props.userData) {
      const ward = this.props.openShiftDrawer
        ? this.props.userData.shift?.id?.toString()
        : this.props.userData?.rotation?.id?.toString();
      this.setState({
        currentWardId: ward,
        selectedWardId: ward,
      });
    } else {
      const userDataResponse = await this.props.getUserCurrentData();
      if (userDataResponse) {
        const ward = this.props.openShiftDrawer
          ? userDataResponse.data?.shift?.id?.toString()
          : userDataResponse.data?.rotation?.id?.toString();
        this.setState({
          currentWardId: ward,
          selectedWardId: ward,
        });
      }
    }
    let response;
    if (this.props.openShiftDrawer) {
      response = await this.props.getAllShifts();
    } else if (this.props.openServiceDrawer) {
      await this.props.getAllServices();
    } else if (this.props.openWardDrawer) {
      response = await this.props.getAllWards();
    } else {
      response = await this.props.getAllRotation();
    }
    if (response && !this.props.openServiceDrawer) {
      this.setState({ wards: response });
    }
    this.setState({ loader: false });
  }
  handleSubmit() {
    if (this.state.selectedWardId === this.state.currentWardId) {
      return;
    }
    if (this.props.filter) {
      this.handleFilter();
      return;
    }
    if (this.props.openShiftDrawer) {
      this.handleSubmitShift();
    } else {
      this.handleSubmitWard();
    }
  }
  handleFilter = () => {
    let domainName;
    if (this.props.openServiceDrawer) {
      domainName = this.props.services.find(
        (val) => val.id === +this.state.selectedWardId
      )?.name;
    } else if (this.props.openWardDrawer) {
      domainName = this.state.wards.find(
        (val) => val.id === +this.state.selectedWardId
      )?.name;
    }
    this.props.handleFilter({
      id: this.state.selectedWardId,
      name: domainName,
    });
  };

  async handleSubmitWard() {
    var selectedWard = this.state.wards.find(
      (ward) => this.state.selectedWardId === ward?.id?.toString()
    );
    if (selectedWard) {
      const requestedWardBody = {
        id: selectedWard.id,
        name: selectedWard.name,
        wards: selectedWard.wards,
      };
      this.setState({ buttonLoader: true });
      const wardResponse = await this.props.pickWard(requestedWardBody);
      this.handleCloseDrawer(wardResponse);
    }
  }
  async handleSubmitShift() {
    var selectedShift = this.state.wards.find(
      (shift) => this.state.selectedWardId === shift?.id?.toString()
    );
    if (selectedShift) {
      this.setState({ buttonLoader: true });
      const shiftResponse = await this.props.pickShift(selectedShift.id);
      this.handleCloseDrawer(shiftResponse);
    }
  }
  handleCloseDrawer(response) {
    if (response && response.status === 200 && this.props.history) {
      this.props.history.push('/setting');
    }
    this.setState({ buttonLoader: false });
    if (typeof this.props.handleClose === 'function') this.props.handleClose();
    // this.props.handleSettingCloseOnWardChange();
  }
  handleChange(e) {
    this.setState({ selectedWardId: e.target?.value });
  }
  handleSearchValueChange(e) {
    let value = e.target?.value;
    this.setState({ searchValue: (typeof value === 'string') ? value : '' });
  }
  render() {
    const {
      selectedWardId,
      wards,
      buttonLoader,
      loader,
      currentWardId,
    } = this.state;

    const { classes } = this.props;

    const domainType = this.props.openShiftDrawer ? 'shift' : this.props.openServiceDrawer ? 'service' : 'ward';

    return (
      <div className='dialogContentContainer'>
        {loader ? (
          <div className={classes.loadingContainer}>
            <ContentLoader style={{ top: '40vh' }} />{' '}
          </div>
        ) : (wards && wards.length > 0) || this.props?.services?.length ? (
          <div>
            <div className={classes.header} style={({padding: '1.5em 1em 1em 1em'})}>
              <HeaderInput
                label={`Search for a  ${domainType}`}
                autoFocus={false}
                onChange={(e) => this.handleSearchValueChange(e)}
              />
            </div>
            <div>
              <RadioGroup
                aria-label='OnCall'
                name='OnCall'
                value={selectedWardId}  // TODO: null if not in searched wards?
                onChange={(e) => this.handleChange(e)}
              >
                {(
                  this.props.openServiceDrawer ? this.props.services : wards
                ).filter(
                    (ward) => ward.name.toLowerCase().includes(this.state.searchValue.toLowerCase())
                ).map(
                  (ward) =>
                    ward && (
                      <FormControlLabel
                        key={ward.id}
                        style={{
                          borderBottom: 'solid #EBEBEB 0.5px',
                          marginLeft: '0px',
                          marginRight: '0px',
                        }}
                        classes={{
                          label: 'fa-text-body1',
                        }}
                        value={`${ward.id}`}
                        control={
                          <Radio
                            checked={selectedWardId === ward.id?.toString()}
                            label={ward.name}
                            color='primary'
                          />
                        }
                        label={ward.name}
                      />
                    )
                )}
              </RadioGroup>
            </div>

            <div className='submitBtnContainer'>
              <BlueButton
                className='BlueButton'
                loader={buttonLoader}
                disabled={!selectedWardId || selectedWardId === currentWardId}
                text={'SAVE'}
                handleClick={() => this.handleSubmit()}
              />
            </div>
          </div>
        ) : (
          <NoContent
            text={`There is no ${domainType} for you!`}
          />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserCurrentData,
      getAllWards,
      pickWard,
      getAllRotation,
      getAllShifts,
      pickShift,
      getAllServices,
    },
    dispatch
  );
const mapStateToProps = (state) => {
  return {
    userData: state.userReducer.userData,
    services: state.generalReducers.services,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(WardContainer)));
