import React from 'react';
import {
  Dialog,
  IconButton,
  DialogContent,
  makeStyles,
} from '@material-ui/core';
import CloseIcon from '../../Assets/icon_close.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '40px 0 30px 24px',
  },
  close: {
    position: 'absolute',
    top: 5,
    left: 5,
  },
  list: {
    direction: 'rtl',
    listStyle: 'none',
  },
  item: {
    textAlign: 'right',
    fontSize: 15,
    lineHeight: '18px',
    direction: 'rtl',
    position: 'relative',
    whiteSpace: 'break-spaces',

    '&:before': {
      content: '""',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      top: '0.5rem',
      right: '-0.8rem',
      width: 7,
      height: 7,
      borderRadius: '50%',
    },

    '&:not(:last-child)': {
      marginBottom: 20,
    },

    '& p': {
      marginTop: 0,
    },
  },
}));

// listData format: [{text: ""}, ...]
function TextListDialog({ listData = [], open, handleClose }) {
  const classes = useStyles();
  return (
    <Dialog open={open} onClose={handleClose}>
      <IconButton className={classes.close} onClick={handleClose}>
        <img src={CloseIcon} alt='close icon' />
      </IconButton>
      <DialogContent className={classes.root}>
        <ul className={classes.list}>
          {listData.map((item, index) => (
            <li key={index} className={classes.item}>
              {Boolean(item.title) && <strong>{item.title}</strong>}
              <p>{item.text}</p>
            </li>
          ))}
        </ul>
      </DialogContent>
    </Dialog>
  );
}

export default TextListDialog;
