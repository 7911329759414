import { IPublicRank } from '../types/user';

export const getUserFullName = (
  user:
    | {
        first_name: string | null;
        last_name: string | null;
        rank?: IPublicRank | null;
        role?: string | null;
      }
    | undefined,
  withRole = true
): string => {
  const full_name = `${user?.first_name || ''} ${user?.last_name || ''}`;
  const modifiedRole = (user?.role ?? user?.rank?.role) || '';

  return modifiedRole && withRole ? `${modifiedRole} ${full_name}` : full_name;
};
