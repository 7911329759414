import React, { useEffect, useMemo } from 'react';
import {
  makeStyles,
  Box,
  InputBase,
  InputBaseProps,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../../../Assets/icons/search.svg';
import debounce from 'lodash.debounce';

const WAIT_INTERVAL = 500;

const useStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  input: {
    width: '100%',
    '& input': {
      padding: '6px 0 6px 12px',
    },
  },
  icon: {
    transform: 'rotate(270deg)',
  },
});

type IProps = InputBaseProps & { loading?: boolean; async?: boolean };

const SearchField: React.FC<IProps> = ({
  loading,
  async,
  onChange,
  ...inputProps
}) => {
  const classes = useStyles();

  const debounceChangeHandler = useMemo(
    () =>
      debounce(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          if (onChange) onChange(event);
        },
        WAIT_INTERVAL
      ),
    []
  );

  useEffect(() => {
    return () => debounceChangeHandler.cancel();
  }, []);

  const changeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (async) {
      event.persist();
      debounceChangeHandler(event);
    } else if (onChange) {
      onChange(event);
    }
  };

  return (
    <Box className={classes.root}>
      <InputBase
        className={classes.input}
        onChange={changeHandler}
        {...inputProps}
      />

      <IconButton className={classes.icon} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : <SearchIcon />}
      </IconButton>
    </Box>
  );
};

export default SearchField;
