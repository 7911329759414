import React, { useRef } from 'react';
import { default as MultiDatePicker } from 'react-multi-date-picker';
import { FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import persian from 'react-date-object/calendars/persian';
import persian_fa from 'react-date-object/locales/persian_fa';
import { getFullDate } from '../../../../Utilities/jalaliDate';

const styles = makeStyles({
  rootFormControl: ({
    width,
    fullWidth,
  }: {
    width?: string;
    fullWidth?: boolean;
  }) => ({
    width: fullWidth ? '100%' : width ? width + 'px' : '180px',
    '&:after': {
      content: '" "',
    },
  }),
  label: {
    color: '#767676',
    paddingBottom: '8px',
  },

  datePickerContainer: {
    position: 'relative',
    borderRadius: '4px',
    border: 'solid 1.5px rgb(187,187,187)',
    '&:focus-within': {
      border: 'solid 1.5px #1b90fa',
    },
  },

  datePicker: {
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#ffffff',
    color: '#3d454a',
    height: '24px',
    padding: '6px 10px 7px',
    boxSizing: 'content-box',
    fontSize: '16px',
    position: 'relative',
    fontFamily: 'inherit',
    direction: 'ltr',
    textAlign: 'left',
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },

  datePickerParent: {
    '& > div .rmdp-time-picker input': {
      fontFamily: 'inherit',
    },
  },

  calendarIcon: {
    position: 'absolute',
    left: '6px',
    top: '6px',
    height: '21px',
    width: '21px',
    pointerEvents: 'none',
  },

  inputClass: {
    fontSize: '14px !important',
  },

  placeholder: {
    position: 'absolute',
    left: '0',
    marginInlineStart: '35px',
    top: '8px',
    fontSize: '13px',
    fontWeight: 500,
    pointerEvents: 'none',
  },
  arrowIcon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    height: '20px',
  },
});

const CalendarIcon = (props: any) => {
  return (
    <svg
      fill='#fff'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      {...props}
    >
      <g fill='#989ea3' data-name='vuesax/outline/calendar'>
        <path d='M8 5.75A.755.755 0 017.25 5V2A.755.755 0 018 1.25a.755.755 0 01.75.75v3a.755.755 0 01-.75.75z' />

        <path
          d='M16 5.75a.755.755 0 01-.75-.75V2a.755.755 0 01.75-.75.755.755 0 01.75.75v3a.755.755 0 01-.75.75zM8.5 14.5a1 1 0 01-.38-.08 1.032 1.032 0 01-.33-.214 1.033 1.033 0 01-.29-.706 1 1 0 01.08-.38 1.155 1.155 0 01.21-.33 1.032 1.032 0 01.33-.21 1.021 1.021 0 011.09.21 1.052 1.052 0 01.29.71 1.5 1.5 0 01-.02.2.636.636 0 01-.06.18.757.757 0 01-.09.18 1.576 1.576 0 01-.12.15 1.052 1.052 0 01-.71.29zM12 14.501a1 1 0 01-.38-.08 1.032 1.032 0 01-.33-.21 1.033 1.033 0 01-.29-.71 1 1 0 01.08-.381 1.155 1.155 0 01.21-.33 1.032 1.032 0 01.33-.21 1 1 0 011.09.21 1.052 1.052 0 01.29.711 1.5 1.5 0 01-.02.2.636.636 0 01-.06.18.757.757 0 01-.09.18 1.576 1.576 0 01-.12.15 1.052 1.052 0 01-.71.29zM15.5 14.501a1 1 0 01-.38-.08 1.032 1.032 0 01-.33-.21l-.12-.15a.757.757 0 01-.09-.18.636.636 0 01-.06-.18 1.5 1.5 0 01-.02-.2 1.052 1.052 0 01.29-.711 1.032 1.032 0 01.33-.21 1 1 0 011.09.21 1.052 1.052 0 01.29.711 1.5 1.5 0 01-.02.2.636.636 0 01-.06.18.757.757 0 01-.09.18 1.576 1.576 0 01-.12.15 1.052 1.052 0 01-.71.29zM8.5 18a1 1 0 01-.38-.08 1.155 1.155 0 01-.33-.207A1.052 1.052 0 017.5 17a1 1 0 01.08-.38.933.933 0 01.21-.329 1.047 1.047 0 011.42 0A1.052 1.052 0 019.5 17a1.052 1.052 0 01-.29.71 1.052 1.052 0 01-.71.29zM12 18a1.052 1.052 0 01-.71-.287A1.052 1.052 0 0111 17a1 1 0 01.08-.38.933.933 0 01.21-.329 1.047 1.047 0 011.42 0 .933.933 0 01.21.33A1 1 0 0113 17a1.052 1.052 0 01-.29.71A1.052 1.052 0 0112 18zM15.5 17.999a1.052 1.052 0 01-.71-.29.933.933 0 01-.21-.33 1 1 0 01-.08-.38 1 1 0 01.08-.379.933.933 0 01.21-.33 1 1 0 01.9-.27.6.6 0 01.19.06.757.757 0 01.18.09 1.576 1.576 0 01.15.12 1.052 1.052 0 01.29.709 1.052 1.052 0 01-.29.71 1.052 1.052 0 01-.71.29zM20.5 9.84h-17a.755.755 0 01-.75-.75.755.755 0 01.75-.75h17a.755.755 0 01.75.75.755.755 0 01-.75.75z'
          data-name='Vector'
        />

        <path
          d='M16 22.75H8c-3.65 0-5.75-2.1-5.75-5.75V8.5c0-3.65 2.1-5.75 5.75-5.75h8c3.65 0 5.75 2.1 5.75 5.75V17c0 3.65-2.1 5.75-5.75 5.75zM8 4.25c-2.86 0-4.25 1.39-4.25 4.25V17c0 2.86 1.39 4.25 4.25 4.25h8c2.86 0 4.25-1.39 4.25-4.25V8.5c0-2.86-1.39-4.25-4.25-4.25z'
          data-name='Vector'
        />
      </g>
    </svg>
  );
};

interface IDatePicker {
  width?: string;
  label?: string;
  value: string;
  usePortal?: boolean;
  placeholder?: boolean;
  setValue: (value: string) => void;
  fullWidth?: boolean;
  minDate?: string;
  maxDate?: string;
  currentDate?: string | any;
}
const DatePicker = ({
  width,
  label,
  value,
  usePortal = false,
  fullWidth = false,
  placeholder,
  setValue,
  minDate,
  maxDate,
  currentDate,
  ...props
}: IDatePicker) => {
  const classes = styles({ width, fullWidth });
  const datePickerRef = useRef<any>(null);
  const handleChange = (val: any) => {
    const date = new Date(val);
    const formatDate = getFullDate(date, false);
    setValue(val ? formatDate ?? '' : '');
  };

  return (
    <FormControl fullWidth classes={{ root: classes.rootFormControl }}>
      {label && (
        <label className={classes.label} htmlFor={label} id={label}>
          {label}
        </label>
      )}

      <div
        className={classes.datePickerContainer}
        onKeyDown={(e) => {
          if (e.key === 'Tab') {
            datePickerRef.current.closeCalendar();
          }
        }}
      >
        <MultiDatePicker
          value={new Date(value)}
          className={classes.datePickerParent}
          inputClass={classes.datePicker}
          calendar={persian}
          locale={persian_fa}
          onChange={handleChange}
          arrow={false}
          zIndex={2000}
          portal={usePortal}
          ref={datePickerRef}
          minDate={minDate}
          maxDate={maxDate}
          currentDate={currentDate}
          {...props}
        />
        <div
          className={classes.arrowIcon}
          onClick={() =>
            !datePickerRef.current.isOpen &&
            datePickerRef.current.openCalendar()
          }
        >
          <CalendarIcon />
        </div>
      </div>
    </FormControl>
  );
};
export default DatePicker;
