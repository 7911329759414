import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HOSPITAL from '../../../../Assets/icons/hospital.svg';
import USER from '../../../../Assets/icons/user.svg';
import { bindActionCreators } from 'redux';
import { getUserCurrentData } from '../../../../action/userAction';
import { Divider, Typography } from '@material-ui/core';
import clsx from 'clsx';
import {
  capitalize,
  CheckRtl,
  renderAutoDirText,
} from '../../../../Utilities/CheckLang';
import { getUserFullName } from '../../../../Utilities/user';

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openRotationDrawer:
        props.snackBarMessage === 'You need to set your rotation first.'
          ? true
          : false,
      openShiftDrawer: false,
    };
  }
  componentDidMount() {
    this.props.getUserCurrentData();
  }
  componentWillReceiveProps() {
    console.log(
      'this.props.snackBarMessage inside setting',
      this.props.snackBarMessage
    );
    if (
      this.props.snackBarMessage === 'You need to set your rotation first.' &&
      this.props.snackBarMessage !== this.state.prevMessage
    )
      this.setState({
        openRotationDrawer:
          this.props.snackBarMessage === 'You need to set your rotation first.'
            ? true
            : false,
      });
    this.setState({ prevMessage: this.props.snackBarMessage });
  }
  openShiftDrawer() {
    this.setState({ openShiftDrawer: true, openRotationDrawer: false });
  }
  openRotationDrawer() {
    this.setState({ openShiftDrawer: false, openRotationDrawer: true });
  }
  handleClosingDrawer() {
    this.setState({ openShiftDrawer: false, openRotationDrawer: false });
  }
  render() {
    const { userData, hospitalName } = this.props;

    
    const responsibilityServicesInfoStr = Array.isArray(userData?.responsibility_services) ? 
      userData?.responsibility_services.map( s => typeof s.name === 'string' ? s.name.toString() : '-').join('، ') :
      'هیچ سرویسی انتخاب نشده است';

    return (
      <div className='setting__section'>
        {userData?.username ? (
          <div className='setting__section__title profile'>
            <img className='mr-2' width={21} alt='user' src={USER} />
            <div className='w-50'>
              <Typography
                variant='h5'
                style={{ fontWeight: 'bold' }}
                color='inherit'
              >
                {renderAutoDirText(getUserFullName(userData))}
              </Typography>
              {Boolean(userData?.rank?.title) && (
                <Typography
                  className='mediumWeight'
                  variant='body2'
                  color='inherit'
                >
                  {capitalize(userData.rank.title)}
                </Typography>
              )}
              <Typography variant='body2' color='inherit'>
                {userData?.username}
              </Typography>
            </div>
          </div>
        ) : null}
        <Divider />
        <div className='setting__section__title'>
          <img className='mr-2' alt='Sections' src={HOSPITAL} width={23} />
          Sections
        </div>
        <div
          className='setting__section__element'
          onClick={() =>
            {
              localStorage.removeItem('namespaceId');
              this.props.history.push('/namespace');
            }
          }
        >
          Hospital
          <br />
          {hospitalName && (
            <Typography
              variant='body2'
              className={CheckRtl(hospitalName) ? 'farsiFont' : ''}
            >{hospitalName}
            </Typography>
          )}
        </div>
        <div
          className='setting__section__element'
          onClick={() => {
            this.props.history.push('/setting/section/responsibility_services');
          }}
        >
          My Services
          <br />
          {
            <Typography
              variant='body2'
              className={CheckRtl(responsibilityServicesInfoStr) ? 'farsiFont' : ''}
            >{responsibilityServicesInfoStr}
            </Typography>
          }
        </div>
        <div
          className='setting__section__element'
          onClick={() => {
            this.props.history.push('/setting/section/ward');
          }}
        >
          Rotation
          <br />
          {userData?.rotation?.name && (
            <Typography
              variant='body2'
              className={CheckRtl(userData?.rotation?.name) ? 'farsiFont' : ''}
            >{`Active rotation : ${userData?.rotation?.name}`}</Typography>
          )}
        </div>
        <div
          className='setting__section__element'
          onClick={() => {
            this.props.history.push('/setting/section/shift');
          }}
        >
          Shift
        </div>
        {/* {(this.state.openShiftDrawer || this.state.openRotationDrawer) && (
          <ResponsiveDrawer
            title={
              this.state.openShiftDrawer
                ? 'Select Your On-call Schedule'
                : 'Select Your Rotation Schedule'
            }
            settingClass={true}
            handleClose={() => this.handleClosingDrawer()}
            content={
              <WardContainer
                openShiftDrawer={this.state.openShiftDrawer}
                openRotationDrawer={this.state.openRotationDrawer}
                handleSettingCloseOnWardChange={() =>
                  this.props.handleSettingCloseOnWardChange()
                }
                handleClose={() => this.handleClosingDrawer()}
              />
            }
          />
        )} */}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUserCurrentData }, dispatch);
const mapStateToProps = (state) => {
  return {
    snackBarMessage: state.snackBarReducer.snackBarMessage,
    userData: state.userReducer.userData,
    hospitalName: state.userReducer.hospitalName
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Setting));
