import React, { useState, useEffect } from 'react';
import { useMutation } from 'react-query';
import { getTerms } from '../../../../api/medicalTerms';
import { connect, RootStateOrAny } from 'react-redux';
import { searchICD11 } from '../../../../action/ICD11Actions';
import { GeneralTask, IOrder } from '../../../../types/order';
import ResponsiveAutocomplete from '../../../ResponsiveAutocomplete';
import { removeTags } from '../../../../Utilities/textUtil';
import { IMedicalTermTypes } from '../../../../types/medicalTerms';
import { Grid } from '@material-ui/core';
import OrderNoteField from '../Fields/Note';

interface IProps {
  type: GeneralTask;
  order?: IOrder;
  impOptions: { title: string }[];
  onOrderChange: (order: IOrder) => void;
  searchICD11: (q: string) => any;
}

type ImpValue =
  | { title: string; stemId: string }
  | { title_en: string; id: string };

const GeneralFormContent: React.FC<IProps> = ({
  type,
  order,
  impOptions,
  onOrderChange,
  searchICD11,
}) => {
  const [loadingImp, setLoadingImp] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const getTermsMutation = useMutation((type: IMedicalTermTypes) =>
    getTerms(type, { q: searchQuery })
  );

  useEffect(() => {
    setSearchQuery('');
  }, [type]);

  useEffect(() => {
    if (type === 'impression') {
      handleImpChange(searchQuery);
    } else {
      getTermsMutation.mutate(type);
    }
  }, [searchQuery]);

  const handleImpChange = async (value: string) => {
    await setLoadingImp(true);
    try {
      if (value.trim().length > 0) {
        await searchICD11(value);
      }
    } catch (err) {
      console.log(err);
    }
    await setLoadingImp(false);
  };

  const getImpId = (stemId: string) => {
    const parsed = stemId.split('/');
    const parsedLength = parsed.length;
    const lastSection = parsed[parsedLength - 1];
    if (lastSection === 'other' || lastSection === 'unspecified') {
      return parsed[parsedLength - 2] + '/' + lastSection;
    } else {
      return lastSection;
    }
  };

  switch (order?.task.category) {
    case 'impression':
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ResponsiveAutocomplete
              isAsync
              multiple
              disableCloseOnSelect
              loading={loadingImp}
              options={impOptions}
              filterOptions={(options) => options}
              value={order.task.impressions}
              getOptionLabel={(o) => removeTags(o.title_en ?? o.title)}
              getOptionSelected={(o, v) => o?.id === v?.id}
              onChange={(_, values) => {
                const newOrder = {
                  ...order,
                  task: {
                    ...order.task,
                    impressions: values
                      ? values.map((value: ImpValue) =>
                          'stemId' in value
                            ? {
                                id: getImpId(value.stemId),
                                title_en: removeTags(value.title),
                              }
                            : { ...value }
                        )
                      : [],
                  },
                };
                onOrderChange(newOrder);
              }}
              onInputChange={(val) => setSearchQuery(val)}
              textFieldProps={{
                autoFocus: true,
                placeholder: 'Impression',
              }}
            />
          </Grid>
          {!!order.task.impressions.length && (
            <Grid item xs={12}>
              <OrderNoteField order={order} onChange={onOrderChange} />
            </Grid>
          )}
        </Grid>
      );
    case 'condition':
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ResponsiveAutocomplete
              isAsync
              loading={getTermsMutation.isLoading}
              options={getTermsMutation.data?.data.results ?? []}
              filterOptions={(options) => options}
              getOptionLabel={(o) => o.title_en || ''}
              getOptionSelected={(o, v) => o?.id === v?.id}
              value={order.task.condition}
              onChange={(_, value) => {
                const newOrder = {
                  ...order,
                  task: {
                    ...order.task,
                    condition: value
                      ? { id: value.id, title_en: value.title_en }
                      : {},
                  },
                };
                onOrderChange(newOrder);
              }}
              onInputChange={(val) => setSearchQuery(val)}
              textFieldProps={{
                autoFocus: true,
                placeholder: 'Condition',
              }}
            />
          </Grid>
          {!!order.task.condition.id && (
            <Grid item xs={12}>
              <OrderNoteField order={order} onChange={onOrderChange} />
            </Grid>
          )}
        </Grid>
      );
    case 'diet':
      return (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ResponsiveAutocomplete
              isAsync
              multiple
              disableCloseOnSelect
              loading={getTermsMutation.isLoading}
              options={getTermsMutation.data?.data.results ?? []}
              filterOptions={(options) => options}
              value={order.task.diets}
              getOptionLabel={(o) => o.title_en || ''}
              getOptionSelected={(o, v) => o?.id === v?.id}
              onChange={(_, values) => {
                const newOrder = {
                  ...order,
                  task: {
                    ...order.task,
                    diets: values
                      ? values.map(
                          (value: { id: number; title_en: string }) => ({
                            id: value.id,
                            title_en: value.title_en,
                          })
                        )
                      : [],
                  },
                };
                onOrderChange(newOrder);
              }}
              onInputChange={(val) => setSearchQuery(val)}
              textFieldProps={{
                autoFocus: true,
                placeholder: 'Diet',
              }}
            />
          </Grid>
          {!!order.task.diets.length && (
            <Grid item xs={12}>
              <OrderNoteField order={order} onChange={onOrderChange} />
            </Grid>
          )}
        </Grid>
      );
    default:
      return <></>;
  }
};

export default connect(
  (state: RootStateOrAny) => ({ impOptions: state.generalReducers.icd || [] }),
  {
    searchICD11,
  }
)(GeneralFormContent);
