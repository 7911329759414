import { Get, GetImage } from './apiController';

export const getAllServices = async () => await Get('/service');

export const getUsersByService = async (serviceId) =>
  await Get(`/service/${serviceId}/user`);

export const getAllShifts = async () => await Get('/shift');
export const getAllRotation = async () => await Get('/rotation');
export const getAllWards = async () => await Get('/ward');

export const convertUrlToBlob = async (url) => await GetImage(url);
