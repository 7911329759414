// ************************************************************************
// Note: please update this file after refactor reducers with typescript
// and declare RootState type and use that in redux connectors
// ************************************************************************
import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { addTableDataReducer } from './addTableDataReducer';
import { patientsReducer } from './patientsReducer';
import { snackBarReducer } from './snackBarReducer';
import { fireBaseTokenReducer } from './fireBaseTokenReducer';
import { reducer as reduxFormReducer } from 'redux-form';
import { generalReducers } from './generalReducers';
import { consultReducer } from './consultReducer';
import { userReducer } from './userReducer';
import orderReducer from './order';

const appReducer = combineReducers({
  form: reduxFormReducer,
  authReducer,
  addTableDataReducer,
  patientsReducer,
  fireBaseTokenReducer,
  snackBarReducer,
  generalReducers,
  consultReducer,
  userReducer,
  order: orderReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
