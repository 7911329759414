export const getUserId = () => {
  const data = localStorage.getItem('myData');
  if (data) {
    const parsed = JSON.parse(data);
    const id = parsed.id;
    if (id) {
      return id;
    }
  }
  return '';
};

export const getToken = () => {
  const data = localStorage.getItem('myData');
  if (data) {
    const parsed = JSON.parse(data);
    return parsed.authorization_token;
  }
};
export const getRefreshToken = () => {
  const data = localStorage.getItem('myData');
  if (data) {
    const parsed = JSON.parse(data);
    return parsed.refresh_token;
  }
};
export const setTokens = (newData) => {
  const data = localStorage.getItem('myData');
  if (data) {
    let parsed = JSON.parse(data);
    parsed = {
      ...parsed,
      authorization_token: newData.access || parsed?.authorization_token,
      refresh_token: newData.refresh || parsed?.refresh_token,
    };
    localStorage.setItem('myData', JSON.stringify(parsed));
  }
};


export const getNamespaceId=()=>{
  const namespaceId = localStorage.getItem('namespaceId');
  if (namespaceId) {
    return namespaceId
  } 
}