import React from 'react';
import { withStyles, Chip, Theme, ChipProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const CustomChip = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#edf8fd',
    borderRadius: 4,
    height: 30,
  },
  label: {
    ...theme.typography.body1,
  },
  deleteIcon: {
    color: '#70727a',

    '&:hover': {
      color: theme.palette.error.main,
    },
  },
}))(({ ...props }: ChipProps) => (
  <Chip {...props} deleteIcon={<CloseIcon />} />
));

export default CustomChip;
