import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  makeStyles,
  withStyles,
  Box,
  Checkbox,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircleCheckIcon from '../../Assets/check_circle.svg';
import CircleCheckedIcon from '../../Assets/checked_circle.svg';
import PinIcon from '../../Assets/pin.svg';
import ActivePinIcon from '../../Assets/icons/pin_active.svg';
import useLongPress from '../../Hook/useLongPress';
import Skeleton from 'react-loading-skeleton';
import { UPLOAD_IMAGE_REMOVE } from '../../action/actionTypes';

const GreenCheckbox = withStyles({
  root: {
    color: '#2bd27c',
    '&$checked': {
      color: '#2bd27c',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  imgContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '100%',
    overflow: 'hidden',
    border: '1px solid #ebebeb',
    borderRadius: '0.375rem',
  },
  img: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    objectFit: 'cover',

    '&.blur': {
      filter: 'blur(3px)',
    },
  },
  skeleton: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  uploadLayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#36363636',
    },
  },
  progressContainer: {
    width: 40,
    height: 40,
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    borderRadius: '50%',
  },
  moreErrorAnchor: {
    color: '#fff',
    position: 'absolute',
    top: 5,
    right: 5,
    zIndex: 1,

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      width: 24,
      height: 24,
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
      borderRadius: '50%',
      zIndex: -1,
    },
  },
  errorIcon: {
    width: 40,
    height: 40,
    color: 'red',
  },
  errorMenuItem: {
    minHeight: 35,
  },
  checkbox: {
    position: 'absolute',
    bottom: '5px',
    left: 0,
  },
  checkboxIcon: {
    width: '1rem',
    height: '1rem',
  },
  pin: {
    position: 'absolute',
    top: 5,
    left: 5,
    padding: 6,
    zIndex: 1,

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: 23,
      height: 23,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      borderRadius: '50%',
      zIndex: -1,
    },
  },
}));

function Image({
  src,
  imageData,
  upload = false,
  selectMode,
  checked,
  pinned = false,
  onLongPress,
  onClick,
  onUploadRetry = () => {},
  onPin,
  onUnpin,
  ...rest
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const options = { shouldPreventDefault: true, delay: 500 };
  const longPressEvent = useLongPress(onLongPress, onClick, options);
  const [loaded, setLoaded] = useState(false);
  const { patientId } = useParams();

  const handlePinClick = () => {
    if (pinned) {
      onUnpin(imageData.id, patientId);
    } else {
      onPin(imageData, patientId);
    }
  };

  const handleUploadRetry = () => {
    const { id, blob } = imageData;
    onUploadRetry(blob, id);
    setAnchorEl(null);
  };

  const handleRemoveUpload = () => {
    const { id } = imageData;
    dispatch({ type: UPLOAD_IMAGE_REMOVE, id: id });
  };

  function renderUploadLayer() {
    const { status } = imageData;
    return (
      <Box className={classes.uploadLayer}>
        {status === 'uploading' ? (
          <Box className={classes.progressContainer}>
            <CircularProgress />
          </Box>
        ) : status === 'failed' ? (
          <Box className={classes.errorContainer}>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              className={classes.moreErrorAnchor}
              size='small'
            >
              <MoreVertIcon fontSize='inherit' />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={anchorEl !== null}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                className={classes.errorMenuItem}
                onClick={handleUploadRetry}
              >
                <ListItemIcon>
                  <ReplayIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Retry' />
              </MenuItem>
              <MenuItem
                className={classes.errorMenuItem}
                onClick={handleRemoveUpload}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Delete' />
              </MenuItem>
            </Menu>

            <ErrorOutlineIcon className={classes.errorIcon} />
          </Box>
        ) : null}
      </Box>
    );
  }

  return (
    <Box className={classes.root} {...rest}>
      <Box className={classes.imgContainer}>
        {upload ? (
          <Fragment>
            <img
              alt='profile image'
              src={src}
              className={`${classes.img} blur`}
              style={!loaded ? { display: 'none' } : {}}
              onLoad={() => setLoaded(true)}
              onContextMenu={(e) => e.preventDefault()}
            />
            {renderUploadLayer()}
          </Fragment>
        ) : (
          <img
            alt='profile image'
            src={src}
            className={classes.img}
            style={!loaded ? { display: 'none' } : {}}
            onLoad={() => setLoaded(true)}
            onContextMenu={(e) => e.preventDefault()}
            {...longPressEvent}
          />
        )}

        {!loaded && <Skeleton className={classes.skeleton} />}
      </Box>

      {!upload && loaded && (
        <IconButton
          className={classes.pin}
          onClick={handlePinClick}
          size='small'
        >
          <img src={pinned ? ActivePinIcon : PinIcon} width={11} />
        </IconButton>
      )}

      {selectMode && (
        <GreenCheckbox
          className={classes.checkbox}
          checked={checked}
          onClick={onClick}
          icon={
            <img
              src={CircleCheckIcon}
              className={classes.checkboxIcon}
              alt='check icon'
            />
          }
          checkedIcon={
            <img
              src={CircleCheckedIcon}
              className={classes.checkboxIcon}
              alt='checked icon'
            />
          }
        />
      )}
    </Box>
  );
}

export default Image;
