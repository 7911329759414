import React from 'react';
import {
  withStyles,
  createStyles,
  makeStyles,
  Button,
  Typography,
  Box,
  Badge,
} from '@material-ui/core';
import CALENDAR_ICON from '../../../Assets/icons/vuesax_linear_calendar.svg';
import PROFILE_ICON from '../../../Assets/icons/profile_circle.svg';
import clsx from 'clsx';
import { getTime, jalaliFullDate } from '../../../Utilities/jalaliDate';
import { renderAutoDirText } from '../../../Utilities/CheckLang';

const StyledBadge = withStyles(() =>
  createStyles({
    badge: {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: '0 0 0 2px #f0f1f7',
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  })
)(Badge);

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#f0f1f7',
    border: '1px solid #ccccd2e0',
    borderRadius: 4,
  },
  cardRow: {
    color: '#191e25',
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',

    '&:first-child': {
      borderBottom: '1px solid #ccccd2e0',
    },
  },
});

interface IProps {
  datetime: string;
  doctor: string;
  onEditing?: boolean;
  withActionButton?: boolean;
  actionButtonProps?: {
    label?: string;
    onClick?: (e: any) => void;
  };
  label?: string;
  className?: string;
  [key: string]: any;
}

const DraftVisitCard: React.FC<IProps> = ({
  datetime,
  doctor,
  onEditing = false,
  withActionButton = false,
  actionButtonProps = {},
  label = 'Last revised by',
  className = '',
  ...rest
}) => {
  const classes = useStyles();

  const profileIcon = <img src={PROFILE_ICON} height={20} alt='profile' />;
  const doctorName = (
    <Typography component='span'>{renderAutoDirText(doctor)}</Typography>
  );

  return (
    <Box className={clsx(classes.root, className && className)} {...rest}>
      <Box className={clsx(classes.cardRow, 'flex-space-between')}>
        <Box display='flex'>
          <img
            src={CALENDAR_ICON}
            height={20}
            alt='calendar'
            className='mr-2'
          />
          <Typography variant='h6' className='mr-2'>
            {jalaliFullDate(datetime)}
          </Typography>
          <Typography variant='h6'>{getTime(datetime)}</Typography>
        </Box>
        {withActionButton && (
          <Button
            color='primary'
            variant='contained'
            style={{ minWidth: 100 }}
            onClick={(e) => {
              e.stopPropagation();
              if (actionButtonProps.onClick) actionButtonProps.onClick(e);
            }}
          >
            <Typography variant='subtitle2'>
              {actionButtonProps.label || ''}
            </Typography>
          </Button>
        )}
      </Box>
      <Box className={classes.cardRow}>
        {onEditing ? (
          <>
            <StyledBadge
              overlap='circle'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              variant='dot'
              className='mr-2'
            >
              {profileIcon}
            </StyledBadge>
            {doctorName}
            <Typography component='span'>
              &nbsp;is editing this draft...
            </Typography>
          </>
        ) : (
          <>
            <Box mr={1}>{profileIcon}</Box>
            <Typography>{label}&nbsp;</Typography>
            {doctorName}
          </>
        )}
      </Box>
    </Box>
  );
};

export default DraftVisitCard;
