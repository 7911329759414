import React from 'react';
import { withStyles, Box } from '@material-ui/core';
import ReloadPage from '../ReloadPage/ReloadPage';
import { ContentLoader } from '../shared';
import clsx from 'clsx';

const styles = () => ({
  root: {
    height: '100%',
    overflowY: 'auto',

    '&.grey-bg': {
      backgroundColor: '#f0f1f7',
    },
  },
});

function PageWrapper({
  loading,
  error,
  greyBg = false,
  dataFetchedFromDB,
  onReload = () => {
    //
  },
  reloadWholePage = true,
  children,
  classes,
  className,
  ...rest
}) {
  return (
    <Box
      className={clsx(
        classes.root,
        className,
        greyBg && !loading && !error && 'grey-bg',
        loading && 'flex-center'
      )}
      {...rest}
    >
      {loading ? (
        <ContentLoader />
      ) : !error || dataFetchedFromDB ? (
        children
      ) : error.networkError ? (
        <ReloadPage wholePage={reloadWholePage} reloadPage={onReload} />
      ) : (
        <ReloadPage
          wholePage={reloadWholePage}
          reloadPage={onReload}
          text={'face problem in fetching data!'}
        />
      )}
    </Box>
  );
}

export default withStyles(styles)(PageWrapper);
