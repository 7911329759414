import React from 'react';
import { AbstractAuthWall, styles } from './AbstractAuthWall';
import { Field } from 'redux-form';
import { resetPassword, demandVerification } from '../../action/userAction';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { Loader } from '../../Components/shared';
import moment from 'moment-jalaali';
import { Link } from 'react-router-dom';

class ChangePassword extends AbstractAuthWall {

  validiySeconds(expires) {
    const seconds = expires ? Math.max(expires.diff(new Date(), 'seconds'), 0) : 0;
    return seconds > 0 ? seconds + 1 : seconds;  // one extra second for precenting race condition on expiration edge
  }

  constructor(props) {
    super(props);
    const verificationCodeExpiration = this.props.history.location.state?.verificationCodeExpiration;
    const expires = verificationCodeExpiration ? moment(verificationCodeExpiration) : null;
    const pendingSecondsForRetry = this.validiySeconds(expires);
    this.state = {
      deviceId: null,
      showPassword: false,
      expires: expires,
      pendingSecondsForRetry: pendingSecondsForRetry,
    };
    this.handleResend = this.handleResend.bind(this);
  }

  tick() {
    this.setState((state, props) => ({pendingSecondsForRetry: Math.max(state.pendingSecondsForRetry - 1, 0)}));
  }

  componentDidMount() {
    super.componentDidMount();
    this.timerID = setInterval(
      () => this.tick(), 1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async handleSubmit(values) {
      this.setState({ loader: true });
      const body = {
        username: this.props.history.location.state?.constantUsername,
        new_password: values.password,
        verification_code: values.verification_code,
      };
      const response = await this.props.resetPassword(body);
      this.setState({ loader: false });
      if (response) {
        this.props.history.replace('/password_changed');
      }
    }
  
  async handleResend(e) {
    e.preventDefault();
    this.setState({ altLoader: true });
    const body = {
      username: this.props.history.location.state?.constantUsername
    };
    const response = await this.props.demandVerification(body);
    this.setState({ altLoader: false });
    if (response && response.status / 100 === 2) {
      const verificationCodeExpiration = response.data.expires;
      const expires = verificationCodeExpiration ? moment(verificationCodeExpiration) : null;
      const pendingSecondsForRetry = this.validiySeconds(expires);
      this.setState({
        expires: expires,
        pendingSecondsForRetry: pendingSecondsForRetry,
      });
    }
  }

  abovePasswordFormFields(renderField, classes) {
    // TODO: prevent tracking history of values from the browser
    return (
      <Field
          name='verification_code'
          type='text'
          label='SMS Code'
          component={renderField}
          classes={{ root: classes.root }}
      />
    );
  }

  formButtons() {
    const pendingForRetry = this.state.pendingSecondsForRetry > 0;
    return (
      <>
        <div>
          <button type='submit' className='AuthWallMainButton' disabled={this.props.submitting}>
            {this.state.loader ? (
              <Loader color='#ffffff' height={'30px'} />
            ) : (
              'Change Password'
            )}
          </button>
        </div>
        <div>
          <button className='AuthWallAltButton' disabled={pendingForRetry} onClick={this.handleResend}>
            {this.state.altLoader ? (
              <Loader color='#386fff' height={'30px'} />
            ) : (
              pendingForRetry ? `Retry after ${this.state.pendingSecondsForRetry}s` : 'Resend SMS Code'
            )}
          </button>
        </div>
        <div>
          <button className='AuthWallLink' onClick={async () => (this.props.history.replace('/login'))}>
            Back to Login
          </button>
        </div>
      </>
    );
  }
}

const validate = (values) => {
    const errors = {};
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.verification_code) {
      errors.verification_code = 'Required';
    }
    return errors;
  };
  
  const warn = (values) => {
    const warnings = {};
    return warnings;
  };
  
const mapStateToProps = (state) => {
    return {
        auth: state.authReducer.auth,
        title: 'Change your password here',
        autoCompleteNewPassword: true,
        passwordLabel: 'New Password',
        passwordValueAlwaysVisible: true,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ resetPassword, demandVerification }, dispatch);
  
  
export default reduxForm({
  form: 'changePassword', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn, // <--- warning function given to redux-form
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ChangePassword)));
