import React, { useMemo } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import CardexItem from './Item';
import { categories as CATEGORIES } from '../../data';
import {
  IVisit,
  ICardexGroup,
  ICardexItem,
  ICardex,
  ICardexAppendix,
} from '../../../../types/order';
import Task, { IProps as TaskProps } from '../Task';
import {
  reformatCardex,
  reformatCardexWithChanges,
} from '../../../../Utilities/order';
import ItemSubHeader from './ItemSubHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  cardexItemsLabelListWrapper: {
    position: 'sticky',
    top: 0,
    width: 'calc(100% + 48px)',
    height: 53,
    margin: '0 0 0 -24px',
    overflow: 'hidden',
    backgroundColor: '#fff',
    zIndex: 1,
  },
  cardexItemsLabelList: {
    listStyle: 'none',
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    margin: 0,
    padding: '8px 24px 15px',

    '& li': {
      marginRight: 6,

      '&:last-child': {
        marginRight: 0,
      },

      '& a': {
        color: theme.palette.primary.dark,
        padding: '8px 14px',
        borderRadius: 40,
        whiteSpace: 'nowrap',
      },

      '&.active a': {
        color: '#fff',
      },
    },
  },
}));

interface IProps {
  mode?: 'visit' | 'cardex';
  detailedView?: boolean;
  visit?: IVisit | null;
  cardexAppendix?: ICardexAppendix | null;
  parentVisit?: IVisit | null;
  defaultCardex?: ICardex;
  withGroups?: boolean;
  withNewGroups?: boolean;
  withAddButton?: boolean;
  savingStatuses?: {
    [uuid: string]: { state: 'loading' | 'error'; onReload?: () => void };
  };
  taskProps?: Omit<TaskProps, 'item'>;
}

const CardexList: React.FC<IProps> = ({
  mode = 'cardex',
  detailedView = false,
  visit,
  cardexAppendix,
  parentVisit,
  defaultCardex = {},
  withGroups = false,
  withNewGroups = false,
  withAddButton = false,
  savingStatuses,
  taskProps = {},
}) => {
  const classes = useStyles();

  const cardex = useMemo(() => {
    if (visit) {
      if (mode === 'cardex') {
        return reformatCardex({
          visit,
          parentVisit,
          cardexAppendix,
        });
      } else {
        return reformatCardexWithChanges({
          visit,
          parentVisit,
          cardexAppendix,
        });
      }
    }
    return defaultCardex;
  }, [defaultCardex, visit, parentVisit, cardexAppendix]);

  console.log(cardex);

  function renderGroupItems(
    items: ICardexItem[],
    group: ICardexGroup,
    borderTop?: boolean
  ) {
    if (items.length === 0) return null;
    const showGroupLabel =
      (withNewGroups && group === 'new') || (withGroups && group);

    return (
      <>
        {showGroupLabel ? (
          <ItemSubHeader
            borderTop={borderTop}
            {...(groupLabels[group] ?? {})}
          />
        ) : null}

        {items.map((cardexItem, index) => (
          <Task
            key={index}
            item={cardexItem}
            showDetails={detailedView}
            showGroup={!!showGroupLabel}
            group={group}
            savingStatus={
              cardexItem.task.uuid
                ? savingStatuses?.[cardexItem.task.uuid]?.state
                : undefined
            }
            {...taskProps}
          />
        ))}
      </>
    );
  }

  function renderTasks(categoryEntry?: Record<ICardexGroup, ICardexItem[]>) {
    if (categoryEntry) {
      const hasNewOrdersBorder = Boolean(
        categoryEntry.unchecked.length || categoryEntry.checked.length
      );
      return (
        <>
          {renderGroupItems(categoryEntry.unchecked, 'unchecked')}
          {renderGroupItems(categoryEntry.checked, 'checked')}
          {renderGroupItems(categoryEntry.new, 'new', hasNewOrdersBorder)}
        </>
      );
    }
    return null;
  }

  return (
    <Box className={classes.root}>
      {CATEGORIES.map((category, index) =>
        category.key === 'general' && !cardex[category.key] ? null : (
          <CardexItem
            key={index}
            withAddButton={withAddButton && category.key !== 'general'}
            label={category.label}
            id={category.key}
          >
            {cardex[category.key] ? renderTasks(cardex[category.key]) : null}
          </CardexItem>
        )
      )}
    </Box>
  );
};

export default CardexList;

const groupLabels: Record<
  ICardexGroup,
  | {
      label: string;
      variant: 'darkPrimary' | 'default' | 'primary';
    }
  | undefined
> = {
  new: {
    label: 'New orders',
    variant: 'primary',
  },
  unchecked: {
    label: 'Unchecked',
    variant: 'darkPrimary',
  },
  checked: undefined,
};
