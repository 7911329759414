import React, { Fragment } from 'react';
import withWidth from '@material-ui/core/withWidth';
import toRenderProps from 'recompose/toRenderProps';
import { Drawer, makeStyles } from '@material-ui/core';
import useWindowDimensions from '../../Hook/useWindowDimensions';
import clsx from 'clsx';

const WithWidth = toRenderProps(withWidth());
const drawerWidth = '70vw';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*': {
      scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
      // width: "0.1em",
      [theme.breakpoints.down('sm')]: {
        width: '3px',
      },
      width: '7px',
      overflow: 'scroll',
      '-webkit-overflow-scrolling': 'touch',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      borderRadius: '5px',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#808080',
      borderRadius: '5px',
      // outline: '1px solid slategrey',
    },
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      marginLeft: '30vw',
    },
  },
  drawerPaper: {
    textAlign: 'center',
    color: '#191e25',
    overflowY: 'visible',
    top: '0px',
    width: drawerWidth,
    marginLeft: '30vw',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
      width: '100vw',
      marginLeft: 0,
    },
  },
  content: {
    paddingBottom: '0',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      flexGrow: 1,
      width: '30vw',
    },
  },
}));

export default function ResponsiveLayout({ open, sidebar, content }) {
  const classes = useStyles();
  const { height } = useWindowDimensions();

  return (
    <WithWidth>
      {({ width }) => (
        <Fragment>
          <main className={classes.content} style={{ height: height - 1 }}>
            {content}
          </main>

          <nav className={classes.drawer}>
            <Drawer
              className={width === 'xs' || width === 'sm' ? 'mobile-media' : ''}
              classes={{
                paper: classes.drawerPaper,
              }}
              variant={
                width === 'xs' || width === 'sm' ? 'persistent' : 'permanent'
              }
              open={open}
              anchor='right'
            >
              {sidebar}
            </Drawer>
          </nav>
        </Fragment>
      )}
    </WithWidth>
  );
}
