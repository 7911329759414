import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { Label } from './Label';

const priorityColors = ['#ea2323','#ea2323','#f09917','#2bd27c'];
const useStyles = makeStyles({
    element: (priority) => ({
        color: priorityColors[priority],
        width: '5px'
    })
});


export function PriorityText({priority}) {
        const classes = useStyles(priority);
        const Typo = useMemo(() => {
          switch (priority) {
            case 0:
              return <div className={classes.element}>death expectant</div>;
            case 1:
              return <div className={classes.element}>Urgent</div>;
            case 2:
              return <div className={classes.element}>Emergent</div>;
            case 3:
              return <div className={classes.element}>Elective</div>;
            default:
              return null;
          }
        });

        return (
          <Label title={'Priority'}>
            {Typo}
          </Label>
        );
}
