import React, { Component } from 'react';
import './Namespace.css'; // import the stylesheet
import { setNamespace, setHospitalName } from '../../action/userAction';
import { connect } from 'react-redux';
import { Get } from '../../api/apiController';
import '../Login/AbstractAuthWall.css';

import { BlueButton } from '../../Components/shared';





class Namespace extends Component {

  constructor(props) {
    super(props);
    this.state = {
      namespaces:[]
    };
  }



  componentDidMount(){
    
    if (!localStorage.getItem('myData')) {
        this.props.history.push('/');
      }

    if(localStorage.getItem('namespaceId')){
        this.props.history.replace('/patient');

    }


    Get('user/user_namespaces')
      .then(response => {
        this.setState({
          namespaces: response.data,
          loading: false,
          error: null,
        });
      })
      .catch(error => {
        this.setState({
          namespaces: [],
          loading: false,
          error: 'Failed to fetch namespaces.',
        });
      });

  }
  
  onNamespaceSelect(id){
    this.props.dispatch(setNamespace(id.toString()));
    this.props.history.replace('/');
  }

  onBackToLoginClick(){
    this.props.history.replace('/');
    // TODO: DRY
    localStorage.removeItem('myData');
    localStorage.removeItem('fcm-token');  // is this needed?
  }

  render() {
    return (
      <div className='AuthWallBackground'>
       

          <div className='container' >
          <div className="AuthWallFormTitle"> بیمارستان خود را انتخاب کنید</div>
          <div className="scrollable-list">
            <ul className='namespace-list'> 
            {this.state.namespaces.length > 0 ? (
              this.state.namespaces.map((namespace) => (
                <li key={namespace.id}>
                  <BlueButton
                    className='whiteButton'
                    text={namespace.title}
                    handleClick={() => {
                       localStorage.setItem('hospitalName', namespace.title);
                       this.props.dispatch(setHospitalName(namespace.title));
                       this.onNamespaceSelect(namespace.id);
                      }}
                  />
                </li>
               ))
) : (
  <p>متأسفانه هیچ بیمارستانی برای شما پیدا نشد. لطفا با پشتیبانی تماس بگیرید</p>
)}
            </ul>
          </div>
          <button onClick={()=>{this.onBackToLoginClick();}} className='AuthWallLink'>Back to Login</button>
          
          </div>
        
      </div>
    );
  }
}

export default connect()(Namespace);