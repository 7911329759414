import React, { useState, useEffect } from 'react';
import { Route, useLocation, useHistory, matchPath } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import ResponsiveLayout from '../../Components/ResponsiveLayout';
import SettingMenu from '../../Components/SearchAndSettingBar/Setting/SettingMenu';
import settingRoutes from '../../routes/setting';
import PageHeader from '../../Components/PageHeader/PageHeader';

const useStyles = makeStyles({
  componentWrapper: {
    height: '100%',
    overflowY: 'auto',
  },
  sidebar: {
    height: 'calc(100% - 53px)',
    overflowY: 'auto',
  },
  backButtonRipple: {
    backgroundColor: 'rgb(245 245 245)',
  },
});

export default function SettingContainer() {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [openSidebar, setOpenSidebar] = useState(false);

  useEffect(() => {
    setOpenSidebar(
      !matchPath(location.pathname, { path: '/setting', exact: true })
    );
  }, [location]);

  useEffect(() => {
    if (!localStorage.getItem('myData')) {
      history.push('/');
    }
    if(!localStorage.getItem("namespaceId")){
    history.push('/namespace');

  }
  }, []);

  return (
    <ResponsiveLayout
      open={openSidebar}
      sidebar={settingRoutes.map((route) =>
        route.component ? (
          <Route
            key={route.key}
            path={route.path}
            render={(props) => (
              <>
                <PageHeader
                  showPatientInfo={false}
                  title={route.title}
                  {...route.headerProps}
                />
                <div className={classes.componentWrapper}>
                  <route.component {...props} {...route.componentProps} />
                </div>
              </>
            )}
          />
        ) : null
      )}
      content={
        <>
          <PageHeader
            desktopBackButton
            defaultGoBack={false}
            showPatientInfo={false}
            onBackButtonClick={() => history.push('/patient')}
            title='Setting'
          />
          <Box className={classes.sidebar}>
            <SettingMenu />
          </Box>
        </>
      }
    />
  );
}
