import React, { useMemo, useEffect } from 'react';
import debounce from 'lodash.debounce';
import {
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomChip from './CustomChip';
import { IProps } from '.';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { CheckRtl } from '../../Utilities/CheckLang';

const WAIT_INTERVAL = 500;

const useStyles = makeStyles({
  label: {
    color: '#70727a',
    transform: 'translate(0, 30px) scale(1)',
  },
  shrinkLabel: {
    transform: 'translate(0, 1.5px) scale(.85)',
  },
  inputRoot: {
    '& input': {
      padding: '8.5px 0 !important',
    },
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const CustomAutocomplete = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  isAsync,
  loading,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInputChange = () => {},
  textFieldProps,
  getOptionLabel = (o) => o,
  emptyOption,
  value,
  ...props
}: IProps<Multiple, DisableClearable, FreeSolo>) => {
  const classes = useStyles();

  const debounceChangeHandler = useMemo(
    () =>
      debounce((value: string) => {
        onInputChange(value);
      }, WAIT_INTERVAL),
    []
  );

  useEffect(() => {
    return () => debounceChangeHandler.cancel();
  }, []);

  const changeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (isAsync) {
      debounceChangeHandler(event.target.value);
    } else {
      onInputChange(event.target.value);
    }
  };

  return (
    <Autocomplete
      openOnFocus
      getOptionLabel={getOptionLabel}
      loading={loading}
      renderOption={(option) => (
        <Typography
          noWrap
          className={clsx(
            isEqual(emptyOption, option) && 'fs-italic',
            CheckRtl(getOptionLabel(option)) && 'w-100 text-right farsiFont rtl'
          )}
        >
          {getOptionLabel(option)}
        </Typography>
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <CustomChip
            key={index}
            label={getOptionLabel(option)}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={changeHandler}
          InputProps={{
            ...params.InputProps,
            classes: {
              root: clsx(
                classes.inputRoot,
                CheckRtl(getOptionLabel(value)) && 'rtl'
              ),
            },
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          InputLabelProps={{
            classes: { root: classes.label, shrink: classes.shrinkLabel },
          }}
          {...textFieldProps}
        />
      )}
      value={value}
      {...props}
    />
  );
};
export default CustomAutocomplete;
