function isObject(object) {
  return object != null && typeof object === 'object';
}

// //////////////////////////////////////// //
// This is a function to compare two object //
// //////////////////////////////////////// //

export const deepEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

// //////////////////////////////////////// //
// This is a function to compare two arrays //
// //////////////////////////////////////// //

export const isEqualArray = (
  arr1,
  arr2,
  callback = (item1, item2) => item1 === item2
) => {
  if (arr1.length !== arr2.length) return false;
  let equalItems = 0;
  for (const item1 of arr1) {
    for (const item2 of arr2) {
      if (callback(item1, item2)) equalItems++;
    }
  }
  return equalItems === arr1.length;
};
