import { Dispatch } from 'redux';
import * as API from '../api/order';
import * as actionTypes from './actionTypes';
import {
  IDraftVisitSummery,
  IDraftVisit,
  IVisitSummery,
  IVisit,
  IOrder,
  IDraftVisitPurpose,
  ITaskNoticeLabel,
  ITaskExtensionsMapping,
  ICardexAppendix,
} from '../types/order';
import { addMessage } from './generalActions';

export interface GetSingleVisit {
  type: typeof actionTypes.GET_VISIT;
  visit: IVisit | null;
}

export interface GetHeadVisit {
  type: typeof actionTypes.GET_HEAD_VISIT;
  visit: IVisit | null;
}

export interface GetParentVisit {
  type: typeof actionTypes.GET_PARENT_VISIT;
  visit: IVisit | null;
}

export interface GetLastCheckedVisit {
  type: typeof actionTypes.GET_LAST_CHECKED_VISIT;
  visit: IVisit | null;
}

export interface GetAllVisits {
  type: typeof actionTypes.GET_ALL_VISITS;
  visits: IVisitSummery[];
}

export interface AddVisits {
  type: typeof actionTypes.ADD_VISITS;
  visits: IVisitSummery[];
}

export type GetVisits = GetAllVisits;

export interface GetDraftVisits {
  type: typeof actionTypes.GET_DRAFT_VISITS;
  draftVisits: IDraftVisitSummery[];
}

export interface SetDraftVisit {
  type: typeof actionTypes.SET_DRAFT_VISIT;
  draftVisit: IDraftVisit | null;
}

export interface SetCardexAppendix {
  type: typeof actionTypes.SET_CARDEX_APPENDIX;
  cardexAppendix: ICardexAppendix | null;
}

export const getRecentVisits = (
  profileId: number,
  ancestorsCount?: number,
  type?: 'all' | 'checked' | 'unchecked',
  startId?: number
) => async (dispatch: Dispatch<GetVisits | AddVisits>) => {
  if (!startId) {
    await dispatch({
      type: actionTypes.GET_ALL_VISITS,
      visits: [],
    });
  }

  return API.getRecentVisits(
    profileId,
    ancestorsCount || 0,
    startId,
    type
  ).then((res) => {
    if (startId) {
      dispatch({
        type: actionTypes.ADD_VISITS,
        visits: res.data.filter((visit) => visit.id !== startId),
      });
    } else {
      dispatch({
        type: actionTypes.GET_ALL_VISITS,
        visits: res.data,
      });
    }

    return Promise.resolve(res);
  });
};

export const getVisit = (visitId: number, withParent?: boolean) => async (
  dispatch: Dispatch
) => {
  await dispatch<GetSingleVisit>({ type: actionTypes.GET_VISIT, visit: null });
  await dispatch<GetParentVisit>({
    type: actionTypes.GET_PARENT_VISIT,
    visit: null,
  });
  return API.getVisit(visitId).then(async (res) => {
    await dispatch<GetSingleVisit>({
      type: actionTypes.GET_VISIT,
      visit: res.data,
    });
    if (withParent && res.data.parent) {
      try {
        await dispatch<any>(getParentVisit(res.data.parent));
      } catch (err) {
        console.log(err);
      }
    }
    return Promise.resolve(res);
  });
};

export const getParentVisit = (parentId: number) => async (
  dispatch: Dispatch<GetParentVisit>
) => {
  await dispatch({ type: actionTypes.GET_PARENT_VISIT, visit: null });
  return API.getVisit(parentId).then((res) => {
    dispatch({ type: actionTypes.GET_PARENT_VISIT, visit: res.data });
    return Promise.resolve(res);
  });
};

export const getLastCheckedVisit = (parentId: number | string) => async (
  dispatch: Dispatch<GetLastCheckedVisit>
) => {
  await dispatch({ type: actionTypes.GET_LAST_CHECKED_VISIT, visit: null });
  return API.getSpecificVisit(Number(parentId), 'last_checked').then((res) => {
    dispatch({ type: actionTypes.GET_LAST_CHECKED_VISIT, visit: res.data });
    return Promise.resolve(res);
  });
};

export const updateRecentVisit = (
  visitId: number,
  orders: IOrder[],
  bySiblingDraft?: number
) => (dispatch: Dispatch<GetSingleVisit>) => {
  return API.updateRecentVisit(visitId, orders, bySiblingDraft).then((res) => {
    if (res.data.id) {
      dispatch({ type: actionTypes.GET_VISIT, visit: res.data });
    }
    return Promise.resolve(res);
  });
};

export const deleteVisit = (visitId: number) => () => {
  return API.deleteVisit(visitId);
};

export const getHeadVisit = (profileId: number, withParent?: boolean) => async (
  dispatch: Dispatch
) => {
  await dispatch<GetHeadVisit>({
    type: actionTypes.GET_HEAD_VISIT,
    visit: null,
  });
  if (withParent) {
    await dispatch<GetParentVisit>({
      type: actionTypes.GET_PARENT_VISIT,
      visit: null,
    });
  }
  return API.getSpecificVisit(profileId, 'head').then(async (res) => {
    if (res.data.id) {
      await dispatch<GetHeadVisit>({
        type: actionTypes.GET_HEAD_VISIT,
        visit: res.data,
      });
      if (res.data.parent && withParent) {
        try {
          await dispatch<any>(getParentVisit(res.data.parent));
        } catch (err) {
          console.log(err);
        }
      }
    }
    return Promise.resolve(res);
  });
};

export const signVisit = (visitId: number) => (
  dispatch: Dispatch<GetSingleVisit>
) => {
  return API.signVisit(visitId).then((res) => {
    if (res.data.id) {
      dispatch({ type: actionTypes.GET_VISIT, visit: res.data });
    }
    return Promise.resolve(res);
  });
};

export const checkVisit = (visitId: number) => () => {
  return API.checkVisit(visitId);
};

export const createVisit = (data: any, byDraft?: number) => () => {
  return API.createVisit(data, byDraft);
};

export const getDraftVisits = (
  profileId: number,
  offset: number,
  purpose?: IDraftVisitPurpose,
  limit = 25
) => async (dispatch: Dispatch<GetDraftVisits>) => {
  await dispatch({
    type: actionTypes.GET_DRAFT_VISITS,
    draftVisits: [],
  });
  return API.getDraftVisits(profileId, offset, limit || 25, purpose).then(
    (res) => {
      dispatch({
        type: actionTypes.GET_DRAFT_VISITS,
        draftVisits: res.data,
      });
      return Promise.resolve(res);
    }
  );
};

export const setDraftVisit = (draftVisit: IDraftVisit | null) => async (
  dispatch: Dispatch
) => {
  await dispatch<SetDraftVisit>({
    type: actionTypes.SET_DRAFT_VISIT,
    draftVisit,
  });
};

export const createDraftVisit = (
  data: Parameters<typeof API.createDraftVisit>[0]
) => (dispatch: Dispatch) => {
  return API.createDraftVisit(data).then((res) => {
    dispatch<any>(setDraftVisit(res.data));
    return Promise.resolve(res);
  });
};

export const getDraftVisit = (draftId: number, withParent?: boolean) => async (
  dispatch: Dispatch
) => {
  await dispatch<any>(setDraftVisit(null));
  if (withParent) {
    await dispatch<GetParentVisit>({
      type: actionTypes.GET_PARENT_VISIT,
      visit: null,
    });
  }
  return API.getDraftVisit(draftId).then(async (res) => {
    await dispatch<any>(setDraftVisit(res.data));
    if (withParent && res.data.parent) {
      try {
        await dispatch<any>(getParentVisit(res.data.parent));
      } catch (err) {
        console.log(err);
      }
    }
    return Promise.resolve(res);
  });
};

export const editDraftVisit = (draftId: number, data: any) => (
  dispatch: Dispatch
) => {
  return API.editDraftVisit(draftId, data).then((res) => {
    dispatch<any>(setDraftVisit(res.data));
    return Promise.resolve(res);
  });
};

export const deleteDraftVisit = (draftId: number) => () => {
  return API.deleteDraftVisit(draftId);
};

export const getParsedTimingRaw = (raw: string) => (dispatch: any) => {
  return API.getParsedTimingRaw(raw).catch((err) => {
    dispatch(addMessage('error ocurred on timing', 400));
    return Promise.reject(err);
  });
};

export const setCardexAppendix = (
  cardexAppendix: ICardexAppendix | null
) => async (dispatch: Dispatch) => {
  await dispatch<SetCardexAppendix>({
    type: actionTypes.SET_CARDEX_APPENDIX,
    cardexAppendix,
  });
};

export const createTaskNotice = (
  profile_id: number | string,
  new_task_notices: { [key: string]: { label: ITaskNoticeLabel } }
) => async (dispatch: Dispatch) => {
  return API.createTaskNotice(profile_id, new_task_notices).then(
    async (res) => {
      await dispatch<any>(setCardexAppendix(res.data));
      return Promise.resolve(res);
    }
  );
};

export const updateTaskExtensions = (
  profile_id: number | string,
  task_extensions: ITaskExtensionsMapping
) => async (dispatch: Dispatch) => {
  return API.updateTaskExtensions(profile_id, task_extensions).then(
    async (res) => {
      await dispatch<any>(setCardexAppendix(res.data));
      return Promise.resolve(res);
    }
  );
};

export const getCardexAppendix = (profile_id: number | string) => async (
  dispatch: Dispatch
) => {
  await dispatch<any>(setCardexAppendix(null));
  return API.getCardexAppendix(profile_id).then(async (res) => {
    await dispatch<any>(setCardexAppendix(res.data));
    return Promise.resolve(res);
  });
};
