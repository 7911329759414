const initialState = {
  auth: {},
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_AUTH':
      return { ...state, auth: action.data };
    case 'LOG_OUT':
      return { ...state, auth: {} };
    default:
      return state;
  }
};
