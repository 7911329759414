import React, { useState, useEffect } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import { getRecentVisits } from '../../../action/order';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { IVisitSummery } from '../../../types/order';
import { AxiosError } from 'axios';
import PageWrapper from '../../PageWrapper';
import VisitCard from './Card';
import DraftVisitsDrawer from './DraftVisitsDrawer';
import { getUserFullName } from '../../../Utilities/user';
import VisitDescription from './Description';
import PhysicianOrdersPrint from '../Print';

const descriptions = [
  'صرفا آخرین ویزیت تا زمانی که توسط پرستار چک نشده باشد قابل ویرایش یا حذف میباشد.',
  'جهت ویرایش یا حذف ویزیت های ماقبل آخر، باید به ترتیب از آخرین ویزیت، به ترتیب ویزیت ها حذف شوند.',
  'جهت چک ویزیت ها توسط پرستاری، باید از قدیمی ترین ویزیت به ترتیب ویزیت ها چک شوند و بعد از چک شدن قابل ویرایش یا حذف نیستند.',
];

interface IProps {
  visits: IVisitSummery[];
  getVisits: (profileId: number) => any;
}

const Visit: React.FC<IProps> = ({ visits, getVisits }) => {
  const { patientId } = useParams<{ patientId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | AxiosError>(null);

  useEffect(() => {
    fetchVisits();
  }, []);

  async function fetchVisits() {
    await setLoading(true);
    await setError(null);

    getVisits(parseInt(patientId))
      .then(() => {
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        console.log(err);
        setError(err);
        setLoading(false);
      });
  }

  function renderVisits() {
    const firstUncheckedVisit = visits
      .slice(0)
      .sort((a, b) => a.id - b.id)
      .find((visit) => !visit.checker?.id);

    return visits
      .slice(0)
      .sort((a, b) => b.id - a.id)
      .map((visit, idx) => {
        const firstSigner = visit.signers[0];
        const isChecked = !!visit.checker?.id;
        const isEditable = !isChecked && idx === 0;
        const checkState =
          firstUncheckedVisit?.id === visit.id
            ? 'active'
            : isChecked
            ? 'checked'
            : 'disabled';

        return (
          <VisitCard
            key={visit.id}
            visitId={visit.id}
            datetime={visit.modified}
            doctor={getUserFullName(firstSigner)}
            hasEditButton={isEditable}
            checkState={checkState}
            className={'mt-3'}
          />
        );
      });
  }

  return (
    <PageWrapper
      loading={loading}
      error={error}
      onReload={fetchVisits}
      reloadWholePage={false}
      style={{ minHeight: 'calc(100vh - 111px)' }}
    >
      <Box py={3}>
        <Box display='flex' alignItems='center' mb={2}>
          <Box mr={2}>
            <PhysicianOrdersPrint visits={visits.map((visit) => visit.id)} />
          </Box>

          <DraftVisitsDrawer
            buttonLabel='New visit'
            drawerButtonLabel='Add new visit'
            skipPath={`/patient/${patientId}/order/create-visit`}
            purpose='add'
          />
        </Box>

        <Box>
          {visits.length ? (
            <>
              <VisitDescription descriptions={descriptions} />

              {renderVisits()}
            </>
          ) : (
            <Typography className='hint'>
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out
            </Typography>
          )}
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default connect(
  (state: RootStateOrAny) => ({
    visits: state.order.visits ?? [],
  }),
  {
    getVisits: (profileId: number) => getRecentVisits(profileId, 10, 'all'),
  }
)(Visit);
