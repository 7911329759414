import React, { useRef, useState, useEffect, useMemo } from 'react';
import { IOrder, isRadiographyTask } from '../../../../types/order';
import { useQuery } from 'react-query';
import { getFeasibleTerms } from '../../../../api/medicalTerms';
import { differenceBy } from 'lodash';
import { Grid, Box, IconButton, Button } from '@material-ui/core';
import ResponsiveAutocomplete from '../../../ResponsiveAutocomplete';
import { IGetFeasibleMedicalTermsQuery } from '../../../../types/medicalTerms';
import { ReactComponent as AddIcon } from '../../../../Assets/icons/add_primary.svg';
import { ReactComponent as TrashIcon } from '../../../../Assets/icon_trash_red.svg';
import OrderTimingField from '../Fields/Timing';
import OrderNoteField from '../Fields/Note';

type RadiographyFieldTypes =
  | 'radio_type'
  | 'radio_location'
  | 'radio_laterality'
  | 'radio_timing'
  | 'radio_view'
  | 'radio_contrast';

interface IProps {
  defaultOrder: IOrder;
  onOrderChange: (order: IOrder) => void;
}

const RadiographyFormContent: React.FC<IProps> = ({
  defaultOrder,
  onOrderChange,
}) => {
  const fieldsRef = useRef<{ [key: string]: HTMLInputElement }>({});
  const [currentType, setCurrentType] = useState<RadiographyFieldTypes>(
    'radio_type'
  );
  const [viewsCount, setViewsCount] = useState(0);
  const [hasNoView, setHasNoView] = useState(false);
  const [contrastedViewIndex, setContrastedViewIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [initFieldRef, setInitFieldRef] = useState<
    HTMLInputElement | undefined
  >();

  const { data, isFetching } = useQuery(
    [
      'medical_terms',
      {
        type: currentType,
        q: searchQuery,
      },
    ],
    () => {
      if (currentType === 'radio_timing') return;

      const query: IGetFeasibleMedicalTermsQuery = {
        q: searchQuery || undefined,
      };
      if (isRadiographyTask(defaultOrder.task)) {
        /* eslint-disable no-fallthrough */
        switch (currentType) {
          case 'radio_contrast': {
            query.radio_view_id =
              defaultOrder.task.contrasted_views[contrastedViewIndex]?.view.id;
          }
          case 'radio_view': {
            query.radio_laterality_id = defaultOrder.task.laterality.id;
          }
          case 'radio_laterality': {
            query.radio_location_id = defaultOrder.task.location.id;
          }
          case 'radio_location': {
            query.radio_type_id = defaultOrder.task.image_type.id;
          }
        }
        /* eslint-enable no-fallthrough */
      }
      return getFeasibleTerms(currentType, query);
    }
  );

  const showAddConstrainedView = useMemo(() => {
    const defaultTask = defaultOrder.task;
    if (isRadiographyTask(defaultTask)) {
      if (defaultTask.contrasted_views.length) {
        const isNoViewSelected =
          defaultTask.contrasted_views[0]?.view?.id === '0';

        if (!isNoViewSelected) {
          const lastView =
            defaultTask.contrasted_views[
              defaultTask.contrasted_views.length - 1
            ]?.view;

          if (
            viewsCount &&
            (hasNoView
              ? defaultTask.contrasted_views.length < viewsCount - 1
              : defaultTask.contrasted_views.length < viewsCount) &&
            (defaultTask.contrasted_views.length ? lastView?.id : true)
          ) {
            return true;
          }
        }
      } else {
        if (viewsCount) return true;
      }
    }
    return false;
  }, [defaultOrder, viewsCount, hasNoView]);

  useEffect(() => {
    if (initFieldRef) initFieldRef.focus();
  }, [initFieldRef]);

  useEffect(() => {
    if (currentType === 'radio_view' && data?.data) {
      const containNoView = !!data.data.results.find(
        (item) => String(item.id) === '0'
      );

      setHasNoView(containNoView);
      setViewsCount(data.data.count);
    }
  }, [data]);

  useEffect(() => {
    // Auto add new empty contrasted_views item on edit order
    const defaultTask = defaultOrder.task;
    if (isRadiographyTask(defaultTask) && defaultTask.laterality.id) {
      getFeasibleTerms('radio_view', {
        radio_type_id: defaultTask.image_type.id,
        radio_location_id: defaultTask.location.id,
        radio_laterality_id: defaultTask.laterality.id,
      })
        .then((res) => {
          const containNoView = !!res.data.results.find(
            (item) => String(item.id) === '0'
          );

          setHasNoView(containNoView);
          setViewsCount(res.data.count);
        })
        .catch((err) => console.log(err));
    }
  }, [currentType]);

  const setInputRef = (name: string) => (el: HTMLInputElement) => {
    fieldsRef.current = { ...fieldsRef.current, [name]: el };
  };

  const focusNextField = (
    currentField: RadiographyFieldTypes,
    index?: number
  ) => {
    switch (currentField) {
      case 'radio_type': {
        fieldsRef.current['radio_location'] &&
          fieldsRef.current['radio_location'].focus();
        break;
      }
      case 'radio_location': {
        fieldsRef.current['radio_laterality'] &&
          fieldsRef.current['radio_laterality'].focus();
        break;
      }
      case 'radio_laterality': {
        fieldsRef.current['radio_timing'] &&
          fieldsRef.current['radio_timing'].focus();
        break;
      }
      case 'radio_view': {
        if (index !== undefined) {
          fieldsRef.current['radio_contrast.' + index] &&
            fieldsRef.current['radio_contrast.' + index].focus();
        }
        break;
      }
      case 'radio_contrast': {
        if (index !== undefined) {
          fieldsRef.current['radio_view.' + (index + 1)] &&
            fieldsRef.current['radio_view.' + (index + 1)].focus();
        }
        break;
      }
    }
  };

  const handleInputChange = (type: RadiographyFieldTypes) => async (
    value: string
  ) => {
    await setCurrentType(type);
    setSearchQuery(value);
  };

  const handleInputFocus = (
    type: RadiographyFieldTypes,
    index?: number
  ) => async () => {
    if (
      index !== undefined &&
      (type === 'radio_view' || type === 'radio_contrast')
    ) {
      await setContrastedViewIndex(index);
    }
    await setCurrentType(type);
    setSearchQuery('');
  };

  const handleFieldChange = (
    type: RadiographyFieldTypes,
    index?: number
  ) => async (_: any, value: any) => {
    let newTask = { ...defaultOrder.task };
    if (isRadiographyTask(newTask)) {
      switch (type) {
        case 'radio_type': {
          newTask = {
            ...newTask,
            image_type: value ? { id: value.id, title_en: value.title_en } : {},
            location: {},
            laterality: {},
            contrasted_views: [],
          };
          break;
        }
        case 'radio_location': {
          newTask = {
            ...newTask,
            location: value ? { id: value.id, title_en: value.title_en } : {},
            laterality: {},
            contrasted_views: [],
          };
          break;
        }
        case 'radio_laterality': {
          newTask = {
            ...newTask,
            laterality: value ? { id: value.id, title_en: value.title_en } : {},
            contrasted_views: [],
          };
          setCurrentType('radio_timing');
          break;
        }
        case 'radio_view': {
          if (index !== undefined) {
            const newContrastedViews = [...newTask.contrasted_views];
            newContrastedViews[index] = {
              view: value ? { id: value.id, title_en: value.title_en } : {},
              contrast: {},
            };
            newTask = {
              ...newTask,
              contrasted_views: newContrastedViews,
            };
          }
          break;
        }
        case 'radio_contrast': {
          if (index !== undefined) {
            const newContrastedViews = [...newTask.contrasted_views];
            newContrastedViews[index] = {
              view: newContrastedViews[index].view,
              contrast: value ? { id: value.id, title_en: value.title_en } : {},
            };
            newTask = {
              ...newTask,
              contrasted_views: newContrastedViews,
            };
          }
          break;
        }
      }

      await onOrderChange({ ...defaultOrder, task: newTask });
      await setSearchQuery('');
      if (value?.id) {
        // This condition is necessary to avoid auto focus on clear field
        focusNextField(type, index);
      }
    }
  };

  const handleTimingFieldChange = (value: string, errors: any[]) => {
    if (isRadiographyTask(defaultOrder.task)) {
      let newTask = { ...defaultOrder.task };
      newTask = {
        ...newTask,
        timing: {
          raw: value,
          schedules: [],
          errors: errors,
        },
      };
      onOrderChange({ ...defaultOrder, task: newTask });
    }
  };

  const handleAddContrastedView = () => {
    if (isRadiographyTask(defaultOrder.task)) {
      const newOrder: IOrder = {
        ...defaultOrder,
        task: {
          ...defaultOrder.task,
          contrasted_views: [
            ...defaultOrder.task.contrasted_views,
            { view: {}, contrast: {} },
          ],
        },
      };
      const lastIndex = defaultOrder.task.contrasted_views.length;
      onOrderChange(newOrder);
      setTimeout(() => {
        fieldsRef.current[`radio_view.${lastIndex}`] &&
          fieldsRef.current[`radio_view.${lastIndex}`].focus();
      }, 100);
    }
  };

  const handleDeleteContrastedView = (index: number) => () => {
    if (isRadiographyTask(defaultOrder.task)) {
      const newContrastedViews = [...defaultOrder.task.contrasted_views];
      newContrastedViews.splice(index, 1);
      onOrderChange({
        ...defaultOrder,
        task: { ...defaultOrder.task, contrasted_views: newContrastedViews },
      });
    }
  };

  if (!isRadiographyTask(defaultOrder.task)) return null;

  const fields = [
    {
      key: 'radio_type',
      isVisible: true,
      content: (
        <Grid item xs={4}>
          <ResponsiveAutocomplete
            isAsync
            loading={isFetching && currentType === 'radio_type'}
            options={data?.data.results ?? []}
            filterOptions={(options) => options}
            getOptionLabel={(o) => o.title_en || ''}
            getOptionSelected={(o, v) => o?.id === v?.id}
            value={defaultOrder.task.image_type}
            onChange={handleFieldChange('radio_type')}
            onInputChange={handleInputChange('radio_type')}
            textFieldProps={{
              placeholder: 'Image Type',
              inputRef: (el) => setInitFieldRef(el),
              onFocus: handleInputFocus('radio_type'),
            }}
          />
        </Grid>
      ),
    },
    {
      key: 'radio_location',
      isVisible: !!defaultOrder.task.image_type.id,
      content: (
        <Grid item xs={4}>
          <ResponsiveAutocomplete
            isAsync
            loading={isFetching && currentType === 'radio_location'}
            options={data?.data.results ?? []}
            filterOptions={(options) => options}
            getOptionLabel={(o) => o.title_en || ''}
            getOptionSelected={(o, v) => o?.id === v?.id}
            value={defaultOrder.task.location}
            onChange={handleFieldChange('radio_location')}
            onInputChange={handleInputChange('radio_location')}
            textFieldProps={{
              placeholder: 'Location',
              inputRef: setInputRef('radio_location'),
              onFocus: handleInputFocus('radio_location'),
            }}
          />
        </Grid>
      ),
    },
    {
      key: 'radio_laterality',
      isVisible: !!defaultOrder.task.location.id,
      content: (
        <Grid item xs={4}>
          <ResponsiveAutocomplete
            isAsync
            loading={isFetching && currentType === 'radio_laterality'}
            options={data?.data.results ?? []}
            filterOptions={(options) => options}
            emptyOption={{
              id: '0',
              title_en: 'No Laterality',
            }}
            getOptionLabel={(o) => o.title_en || ''}
            getOptionSelected={(o, v) => o?.id === v?.id}
            value={defaultOrder.task.laterality}
            onChange={handleFieldChange('radio_laterality')}
            onInputChange={handleInputChange('radio_laterality')}
            textFieldProps={{
              placeholder: 'Laterality',
              inputRef: setInputRef('radio_laterality'),
              onFocus: handleInputFocus('radio_laterality'),
            }}
          />
        </Grid>
      ),
    },
    {
      key: 'contrasted_view',
      isVisible: !!defaultOrder.task.laterality.id,
      content: defaultOrder.task.contrasted_views.map(
        (contrasted_view, index) => (
          <>
            <Grid item xs={4}>
              <ResponsiveAutocomplete
                isAsync
                loading={
                  isFetching &&
                  currentType === 'radio_view' &&
                  contrastedViewIndex === index
                }
                options={
                  // filter selected views and dont show 'no view' on multi views
                  differenceBy(
                    data?.data.results ?? [],
                    isRadiographyTask(defaultOrder.task)
                      ? defaultOrder.task.contrasted_views.length > 1
                        ? [
                            ...defaultOrder.task.contrasted_views.map(
                              (item) => item.view
                            ),
                            { id: '0' },
                          ]
                        : defaultOrder.task.contrasted_views.map(
                            (item) => item.view
                          )
                      : [],
                    'id'
                  )
                }
                filterOptions={(options) => options}
                emptyOption={{
                  id: '0',
                  title_en: 'No View',
                }}
                getOptionLabel={(o) => o.title_en || ''}
                getOptionSelected={(o, v) => o?.id === v?.id}
                value={contrasted_view.view}
                onChange={handleFieldChange('radio_view', index)}
                onInputChange={handleInputChange('radio_view')}
                textFieldProps={{
                  placeholder: 'View',
                  inputRef: setInputRef('radio_view.' + index),
                  onFocus: handleInputFocus('radio_view', index),
                }}
              />
            </Grid>
            <Grid item xs={7}>
              {!!contrasted_view.view.id && (
                <ResponsiveAutocomplete
                  isAsync
                  loading={
                    isFetching &&
                    currentType === 'radio_contrast' &&
                    contrastedViewIndex === index
                  }
                  options={data?.data.results ?? []}
                  filterOptions={(options) => options}
                  emptyOption={{
                    id: '0',
                    title_en: 'No Contrast',
                  }}
                  getOptionLabel={(o) => o.title_en || ''}
                  getOptionSelected={(o, v) => o?.id === v?.id}
                  value={contrasted_view.contrast}
                  onChange={handleFieldChange('radio_contrast', index)}
                  onInputChange={handleInputChange('radio_contrast')}
                  textFieldProps={{
                    placeholder: 'Contrast',
                    inputRef: setInputRef('radio_contrast.' + index),
                    onFocus: handleInputFocus('radio_contrast', index),
                  }}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              <Box
                width={1}
                height={1}
                display='flex'
                justifyContent='center'
                alignItems='end'
              >
                <IconButton
                  size='small'
                  onClick={handleDeleteContrastedView(index)}
                >
                  <TrashIcon />
                </IconButton>
              </Box>
            </Grid>
          </>
        )
      ),
    },
    {
      key: 'radio_timing',
      isVisible: !!defaultOrder.task.laterality.id,
      content: (
        <Grid item xs={9}>
          <OrderTimingField
            value={defaultOrder.task.timing?.raw}
            onChange={handleTimingFieldChange}
            inputRef={setInputRef('radio_timing')}
          />
        </Grid>
      ),
    },
    {
      key: 'radio_note',
      isVisible: !!defaultOrder.task.laterality.id,
      content: (
        <Grid item xs={12}>
          <OrderNoteField order={defaultOrder} onChange={onOrderChange} />
        </Grid>
      ),
    },
  ];

  return (
    <Grid container spacing={1}>
      {fields.map((field) => (field.isVisible ? field.content : null))}

      {!!defaultOrder.task.laterality.id && showAddConstrainedView && (
        <Grid item xs={12} className='text-left'>
          <Button
            color='primary'
            startIcon={<AddIcon />}
            onClick={handleAddContrastedView}
          >
            Add View
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default RadiographyFormContent;
