import React, { useState, useEffect, useMemo } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import {
  getDraftVisit,
  getHeadVisit,
  getParentVisit,
  editDraftVisit,
  createVisit,
  updateRecentVisit,
  deleteDraftVisit,
  getCardexAppendix,
} from '../../../action/order';
import { useParams, useHistory } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import {
  IVisit,
  IDraftVisit,
  IOrder,
  ICardexAppendix,
} from '../../../types/order';
import { Box, IconButton } from '@material-ui/core';
import PageHeader from '../../PageHeader/PageHeader';
import PageWrapper from '../../PageWrapper';
import CardexState from '../Cardex/State';
import CardexForm from '../Cardex/Form';
import { AlertDialog } from '../../shared';
import usePathAction from '../../../Hook/usePathAction';
import useAccess from '../../../Hook/useAccess';
import {
  addOrdersToCardex,
  getConflicts,
  getFinishedUUIDs,
  initialCardexStructure,
} from '../../../Utilities/order';
import ReviewConflicts from './ReviewConflicts';
import { ReactComponent as DeleteIcon } from '../../../Assets/icon_trash_red.svg';
import VisitDescription from './Description';

const descriptions = [
  'دستوراتی که به دسته بندی های زیر مربوط نمیشوند را میتوانید در دسته Other قرار دهید.',
];
interface IProps {
  draftVisit: IDraftVisit | null;
  parentVisit: IVisit | null;
  headVisit: IVisit | null;
  cardexAppendix: ICardexAppendix | null;
  getDraftVisit: (
    draftId: number,
    withParent?: boolean
  ) => Promise<AxiosResponse<IDraftVisit>>;
  getHeadVisit: (profileId: number) => Promise<AxiosResponse<IVisit>>;
  getParentVisit: (parentId: number) => Promise<AxiosResponse<IVisit>>;
  editDraftVisit: (draftId: number, data: any) => any;
  createVisit: (data: any, byDraft: number) => any;
  updateRecentVisit: (
    visitId: number,
    orders: IOrder[],
    bySiblingDraft: number
  ) => any;
  deleteDraftVisit: (draftId: number) => any;
  getCardexAppendix: (profileId: number) => Promise<AxiosResponse<any>>;
}

const DraftVisit: React.FC<IProps> = ({
  draftVisit,
  parentVisit,
  headVisit,
  cardexAppendix,
  getDraftVisit,
  getHeadVisit,
  getParentVisit,
  editDraftVisit,
  createVisit,
  updateRecentVisit,
  deleteDraftVisit,
  getCardexAppendix,
}) => {
  const history = useHistory();
  const { patientId, draftId } = useParams<{
    patientId: string;
    draftId: string;
  }>();
  const {
    data: physicianVisitOperatorAccess,
    isLoading: isLoadingPhysicianVisitOperatorAccess,
  } = useAccess('physician_visit_operator');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const [pathAction, setPathAction] = usePathAction();

  if (!isLoadingPhysicianVisitOperatorAccess && !physicianVisitOperatorAccess) {
    history.replace(`/patient/${patientId}/order`);
  }

  const conflicts = useMemo(() => {
    if (draftVisit?.purpose === 'add' && headVisit) {
      return getConflicts(headVisit.cardex.items, draftVisit?.orders ?? []);
    }

    if (draftVisit?.purpose === 'edit' && parentVisit) {
      return getConflicts(parentVisit.cardex.items, draftVisit?.orders ?? []);
    }

    return [];
  }, [headVisit, parentVisit, draftVisit]);

  const pendingCardex = useMemo(() => {
    if (conflicts.length) {
      const finishedUUIDs = getFinishedUUIDs(
        cardexAppendix?.task_notices ?? {}
      );
      let cardex = initialCardexStructure({
        visit: parentVisit,
        headVisit,
        finishedUUIDs,
        skipStates: ['dc', 'extinct'],
      });
      cardex = addOrdersToCardex(cardex, draftVisit?.orders ?? []);
      return cardex;
    }
  }, [parentVisit, draftVisit, headVisit]);

  useEffect(() => {
    if (
      !isLoadingPhysicianVisitOperatorAccess &&
      physicianVisitOperatorAccess
    ) {
      fetchPageData();
    }
  }, [
    draftId,
    patientId,
    physicianVisitOperatorAccess,
    isLoadingPhysicianVisitOperatorAccess,
  ]);

  const fetchPageData = async () => {
    await setLoading(true);
    await setError(null);

    await getHeadVisit(parseInt(patientId))
      .then((res) => res.data)
      .then(async (headVisit) => {
        await getDraftVisit(parseInt(draftId))
          .then((res) =>
            res.data.purpose === 'edit' ? headVisit.parent : res.data.parent
          )
          .then(async (parentId) => {
            if (parentId) await getParentVisit(parentId);
          });
      })
      .catch((err) => {
        console.log(err);
        setError(err);
      });

    await getCardexAppendix(parseInt(patientId)).catch((err) => {
      console.log(err);
      setError(err);
    });

    setLoading(false);
  };

  const handleSubmit = (orders: IOrder[]) => {
    if (draftVisit?.purpose === 'add') {
      return createVisit(
        {
          profile_id: patientId,
          parent: headVisit?.id ?? null,
          orders: orders,
        },
        parseInt(draftId)
      );
    } else {
      if (headVisit) {
        return updateRecentVisit(headVisit.id, orders, parseInt(draftId));
      }
    }
  };

  const handleAfterSubmitAction = () => {
    history.goBack();
  };

  const resolveConflicts = () => {
    if (headVisit && conflicts.length) {
      const tempOrders: IOrder[] = [];
      for (const conflict of conflicts) {
        tempOrders.push({ ...conflict.order, action: null });
      }
      editDraftVisit(parseInt(draftId), {
        parent: headVisit.id,
        orders: tempOrders,
      })
        .then(() => {
          fetchPageData();
        })
        .catch((err: AxiosError) => {
          console.log(err);
        });
    }
  };

  const handleDeleteDraft = async (bypassConfirmation?: boolean) => {
    if (bypassConfirmation) {
      try {
        await deleteDraftVisit(parseInt(draftId));
        await setPathAction('');
        history.goBack();
      } catch (e) {
        console.log(e);
      }
    } else {
      setPathAction('openDeleteConfirmation');
    }
  };

  return (
    <>
      <PageHeader
        desktopBackButton
        showPatientInfo={false}
        title='Draft visit'
        optionComponent={
          loading ? null : (
            <Box mr={-1.5}>
              <IconButton color='secondary' onClick={() => handleDeleteDraft()}>
                <DeleteIcon />
              </IconButton>
            </Box>
          )
        }
      />
      <PageWrapper
        loading={loading}
        error={Boolean(error)}
        onReload={fetchPageData}
      >
        <Box px={3} pt={3} pb={1}>
          {conflicts.length ? (
            <ReviewConflicts
              items={conflicts}
              onConfirmOmitting={resolveConflicts}
            />
          ) : (
            <VisitDescription descriptions={descriptions} />
          )}
        </Box>

        <Box pt={1} px={3}>
          <CardexState
            label='Last revised by'
            user={draftVisit?.last_modifier}
            datetime={draftVisit?.modified}
          />
          <CardexForm
            showSaveAsDraftButton
            purpose={draftVisit?.purpose}
            visit={
              draftVisit?.purpose === 'edit'
                ? parentVisit
                : parentVisit ?? headVisit
            }
            relatedDraft={draftVisit}
            defaultOrders={draftVisit?.orders ?? []}
            defaultCardex={pendingCardex}
            disabled={!!conflicts.length}
            onSubmit={handleSubmit}
            onSuccessSubmit={handleAfterSubmitAction}
          />
        </Box>

        <AlertDialog
          open={pathAction === 'openDeleteConfirmation'}
          alertText='Are you sure you want to delete this visit?'
          confirmButton={true}
          confirmText='Delete'
          confirmButtonColor='secondary'
          withConfirmLoading={true}
          closeText='Cancel'
          handleClose={() => setPathAction('')}
          handleConfirm={() => handleDeleteDraft(true)}
        />
      </PageWrapper>
    </>
  );
};

export default connect(
  (state: RootStateOrAny) => ({
    draftVisit: state.order.draftVisit,
    parentVisit: state.order.parentVisit,
    headVisit: state.order.headVisit,
    cardexAppendix: state.order.cardexAppendix,
  }),
  {
    getDraftVisit,
    getHeadVisit,
    getParentVisit,
    editDraftVisit,
    createVisit,
    updateRecentVisit,
    deleteDraftVisit,
    getCardexAppendix,
  }
)(DraftVisit);
