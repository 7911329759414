import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { renderAutoDirText } from '../../../../../Utilities/CheckLang';
import { getUserFullName } from '../../../../../Utilities/user';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import AutoDirTypography from '../../../../shared/Typography/AutoDirTypography';
import { ReactComponent as ProfileIcon } from '../../../../../Assets/icons/profile_circle_grey.svg';
import Task, { CheckboxState } from '../../../Cardex/Task';
import {
  ICardexGroup,
  ICardexItem,
  IOrder,
  ITask,
  ITaskTemplate,
} from '../../../../../types/order';
import { ReactComponent as CheckedIcon } from '../../../../../Assets/icons/component_checked_prymary_square.svg';
import { ReactComponent as SquareIcon } from '../../../../../Assets/icons/component_square.svg';

const VISIBILITY_DESCRIPTION =
  'در صورت فعال بودن این گزینه، این تمپلیت برای استفاده در دسترس همه کاربران قرار خواهد گرفت. اما فقط شما میتوانید آن را ویرایش یا حذف کنید';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '16px 24px 24px',
  },
  header: {
    paddingBottom: 8,
    marginBottom: 16,
    borderBottom: '2px solid #f0f1f7',
  },
  author: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    columnGap: 4,
    color: theme.palette.grey[700],
    marginTop: 8,
  },
  visibilityTitle: {
    fontWeight: 600,
    marginBottom: 8,
  },
  visibility: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
  },
  note: {
    marginBottom: 16,
  },
  footer: {
    display: 'block',
    margin: 'auto 0 0',
  },
}));

interface IProps {
  template?: ITaskTemplate;
  isSelfAuthor?: boolean;
  onAddOrders?: (orders: IOrder[]) => void;
}

interface IPathParams {
  patientId: string;
  visitId?: string;
}

const VistitTemplateItem: React.FC<IProps> = ({
  template,
  isSelfAuthor = false,
  onAddOrders,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { patientId, visitId } = useParams<IPathParams>();
  const [selectedTasks, setSelectedTasks] = useState<ITask[]>([]);

  useEffect(() => {
    setSelectedTasks(template?.tasks ?? []);
  }, [template]);

  const getCheckboxState = (
    item: ICardexItem,
    _: ICardexGroup
  ): CheckboxState => {
    const currentlySelected = !!selectedTasks.find(
      (t) => t.uuid === item.task.uuid
    );

    return currentlySelected ? 'checked' : 'unchecked';
  };

  const handleCheck = (checked: boolean, task: ITask) => {
    setSelectedTasks(
      checked
        ? [...selectedTasks, task]
        : selectedTasks.filter((t) => t.uuid !== task.uuid)
    );
  };

  const handleAddSelectedOrderButtonClick = () => {
    if (onAddOrders) {
      onAddOrders(selectedTasks.map((task) => ({ task, action: 'add' })));
    }
    history.go(-2);
  };

  const handleEditTemplateBtnClick = () => {
    history.push(
      visitId
        ? `/patient/${patientId}/order/visit/${visitId}/template/${template?.id}/edit`
        : `/patient/${patientId}/order/create-visit/template/${template?.id}/edit`
    );
  };

  if (!template) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <AutoDirTypography
          className='mediumWeight'
          rtlClasses='farsiBold rtl text-right'
        >
          {template.title}
        </AutoDirTypography>

        <Box className={classes.author}>
          <Typography className='mediumWeight' variant='body2'>
            {renderAutoDirText(getUserFullName(template.author))}
          </Typography>

          <ProfileIcon />
        </Box>

        {isSelfAuthor && (
          <Box>
            <Typography className={classes.visibilityTitle}>
              Visibility
            </Typography>

            <Box className={classes.visibility}>
              {template.public ? <CheckedIcon /> : <SquareIcon />}
              <Typography className='mediumWeight'>Public template</Typography>
            </Box>

            <AutoDirTypography>{VISIBILITY_DESCRIPTION}</AutoDirTypography>
          </Box>
        )}
      </Box>

      {template.description && (
        <Box className={classes.note}>
          <Typography align='left' className='mediumWeight'>
            Note:
          </Typography>

          <AutoDirTypography>{template.description}</AutoDirTypography>
        </Box>
      )}

      <Box>
        {template.tasks.map((task) => (
          <Task
            key={task.uuid}
            item={{ task, state: 'active' }}
            getCheckboxState={getCheckboxState}
            onCheck={handleCheck}
          />
        ))}
      </Box>

      <Box className={classes.footer}>
        <Button
          fullWidth
          color='primary'
          variant='contained'
          onClick={handleAddSelectedOrderButtonClick}
        >
          Add selected orders to visit
        </Button>

        {isSelfAuthor && (
          <Box mt={2}>
            <Button
              fullWidth
              color='primary'
              variant='outlined'
              onClick={handleEditTemplateBtnClick}
            >
              Edit template
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VistitTemplateItem;
