import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Typography,
  TextField,
  IconButton,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';
import { CheckRtl } from '../../../Utilities/CheckLang';
import clsx from 'clsx';
import { DrawerListWithInputFilter } from '../../shared';
import usePathAction from '../../../Hook/usePathAction';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  dialogControlWrapper: {
    position: 'absolute',
    top: 6,
    right: 0,
    transform: 'translate(12px, -50%)',
    zIndex: 1,
  },
  dialogControl: {
    color: '#191e25',

    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  loadingWrapper: {
    padding: 15,
  },
  input: {},
  inputLabel: {
    color: (value) =>
      value ? theme.palette.text.secondary : theme.palette.text.primary,
    transform: 'scale(1)',
  },
  label: {
    color: (value) =>
      value ? theme.palette.text.secondary : theme.palette.text.primary,
    cursor: 'pointer',
  },
  valueWrapper: {
    padding: '6px 0 7px',
    minHeight: 32.4,
    borderBottom: '1px solid #ccd2e0',
    cursor: 'pointer',

    '&:hover': {
      padding: '6px 0 6px',
      borderBottom: '2px solid #191e25',
    },
  },
  value: {
    fontSize: '1rem',
    lineHeight: '1.1875em',
    padding: '6px 0 7px',
    minHeight: 32.4,
    borderBottom: '1px solid #ccd2e0',
    cursor: 'pointer',

    '&:hover': {
      padding: '6px 0 6px',
      borderBottom: '2px solid #191e25',
    },
  },
}));

export default function SelectWithDialog({
  loading = false,
  error = '',
  onReload = () => {},
  label,
  name,
  value,
  renderValue,
  maxDisplayedItemsSize = undefined,
  propsObjectForDisplay = null,
  removable = false,
  onRemove = () => {},
  onToggleDialog = () => true,
  hasInput = false,
  inputProps = {},
  renderDialog,
  dialogProps = {},
  dialogControlComponent,
}) {
  const classes = useStyles({ value });
  const [pathAction, setPathAction] = usePathAction();

  const displayValue = useMemo(() => {
    // TODO: DRY
    return propsObjectForDisplay ?
      propsObjectForDisplay
        .map((propName) => {
          let propNameParts = propName.split('.');
          try {
            return propNameParts.length == 1 ? value[propNameParts[0]] : value[propNameParts[0]][propNameParts[1]];
          } catch (e) {
            return '';
          }
        }
      ).join(' ')
      : value;
  }, [value, propsObjectForDisplay]);

  const toggleDialog = () => {
    if (onToggleDialog()) {
      setPathAction(pathAction ? '' : `open${name}Dialog`);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.dialogControlWrapper}>
        {loading ? (
          <Box className={classes.loadingWrapper}>
            <Loader
              type='Oval'
              color='#386fff'
              height={'15px'}
              width={'15px'}
            />
          </Box>
        ) : error ? (
          <IconButton className={classes.dialogControl} onClick={onReload}>
            <ReplayIcon />
          </IconButton>
        ) : removable && displayValue ? (
          <IconButton className={classes.dialogControl} onClick={onRemove}>
            <CloseIcon fontSize='small' />
          </IconButton>
        ) : (
          <Box onClick={toggleDialog}>
            {dialogControlComponent ? (
              dialogControlComponent
            ) : (
              <IconButton className={classes.dialogControl}>
                <AddIcon />
              </IconButton>
            )}
          </Box>
        )}
      </Box>

      {hasInput ? (
        <TextField
          fullWidth
          label={label}
          InputLabelProps={{
            shrink: true,
            className: classes.inputLabel,
            classes: {
              disabled: classes.disabledInputLabel,
            },
          }}
          InputProps={{
            style: {
              direction: CheckRtl(displayValue || '') ? 'rtl' : 'ltr',
            },
            className: CheckRtl(displayValue || '') ? 'farsiFont' : '',
            classes: {
              disabled: classes.disabledInput,
            },
          }}
          className={classes.input}
          {...inputProps}
          name={name}
          value={displayValue}
        />
      ) : (
        <Box textAlign='left'>
          <Typography
            component='span'
            variant='body1'
            className={classes.label}
          >
            {label}
          </Typography>
          {renderValue ? (
            <Box className={classes.valueWrapper} onClick={toggleDialog}>
              {renderValue()}
            </Box>
          ) : (
            <Typography
              className={clsx(
                classes.value,
                CheckRtl(displayValue) && 'fa-text-body1'
              )}
              style={{ textAlign: CheckRtl(displayValue) ? 'right' : 'left' }}
              dir='auto'
              onClick={toggleDialog}
            >
              {displayValue}
            </Typography>
          )}
        </Box>
      )}

      {renderDialog ? (
        renderDialog(
          !loading && !error && pathAction === `open${name}Dialog`,
          toggleDialog
        )
      ) : (
        <DrawerListWithInputFilter
          open={!loading && !error && pathAction === `open${name}Dialog`}
          handleClose={toggleDialog}
          name={name}
          propsObjectForDisplay={propsObjectForDisplay}
          selectedItem={value}
          maxDisplayedItemsSize={maxDisplayedItemsSize}
          {...dialogProps}
        />
      )}
    </Box>
  );
}

SelectWithDialog.propTypes = {
  dialogControlComponent: PropTypes.any,
};
