import React, { useState } from 'react';
import { connect, RootStateOrAny, useDispatch } from 'react-redux';
import { IDraftVisitPurpose, IDraftVisitSummery } from '../../../types/order';
import { useParams, useHistory } from 'react-router-dom';
import {
  withStyles,
  makeStyles,
  Box,
  SwipeableDrawer as MuiSwipeableDrawer,
  Typography,
  Button,
  CircularProgress,
  Dialog as MuiDialog,
  IconButton,
  ButtonProps,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import DraftVisitCard from './DraftCard';
import { getDraftVisits } from '../../../action/order';
import { AxiosError, AxiosResponse } from 'axios';
import useCheckMobileScreen from '../../../Hook/useCheckMobileScreen';
import { getUserFullName } from '../../../Utilities/user';
import useAccess from '../../../Hook/useAccess';
import { addMessage } from '../../../action/generalActions';

const SwipeableDrawer = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '70vw',
      left: '30vw !important',

      '& .MuiBackdrop-root': {
        left: '30vw',
      },
    },
  },
  paper: {
    padding: '40px 24px 24px',
    marginTop: 100,
    textAlign: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,

    [theme.breakpoints.up('md')]: {
      marginLeft: '30vw',
      width: '70vw',
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 6,
      left: '50%',
      transform: 'translateX(-50%)',
      height: 4,
      width: 42,
      borderRadius: 4,
      backgroundColor: theme.palette.grey[500],
    },
  },
}))(MuiSwipeableDrawer);

const Dialog = withStyles({
  paper: {
    textAlign: 'center',
    padding: '52px 24px 16px',
    position: 'relative',
  },
})(MuiDialog);

const useStyles = makeStyles({
  cardWrapper: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 310px)',
    marginTop: 16,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

interface IProps {
  buttonLabel: string;
  drawerButtonLabel: string;
  skipPath: string;
  purpose?: IDraftVisitPurpose;
  buttonProps?: ButtonProps;
  loadingSize?: number;
  draftVisits: IDraftVisitSummery[];
  getDraftVisits: (
    profileId: number,
    purpose?: IDraftVisitPurpose
  ) => Promise<AxiosResponse<IDraftVisitSummery[]>>;
}

const DraftVisitsDrawer: React.FC<IProps> = ({
  buttonLabel,
  drawerButtonLabel,
  purpose,
  buttonProps,
  loadingSize = 16,
  skipPath,
  draftVisits,
  getDraftVisits,
}) => {
  const classes = useStyles();
  const { patientId } = useParams<{ patientId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useCheckMobileScreen();
  const { data: physicianVisitOperatorAccess } = useAccess(
    'physician_visit_operator'
  );
  const [isFetching, setIsFetching] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  async function fetchDraftVisits(): Promise<
    AxiosResponse<IDraftVisitSummery[]>
  > {
    await setIsFetching(true);
    return new Promise((resolve, reject) => {
      getDraftVisits(parseInt(patientId), purpose)
        .then((res) => {
          setIsFetching(false);
          resolve(res);
        })
        .catch((err: AxiosError) => {
          setIsFetching(false);
          reject(err);
        });
    });
  }

  const triggerDrawer = (open?: boolean) => () => {
    setOpenDrawer(!!open);
  };

  const handleNewVisitClick = (checkDrafts?: boolean) => async (
    event: React.MouseEvent<HTMLButtonElement | MouseEvent>
  ) => {
    event.stopPropagation();

    if (physicianVisitOperatorAccess) {
      if (checkDrafts) {
        await fetchDraftVisits()
          .then((res) => {
            if (res.data.length > 0) {
              triggerDrawer(true)();
            } else {
              history.push(skipPath);
            }
          })
          .catch((err) => console.log(err));
      } else {
        history.push(skipPath);
      }
    } else {
      dispatch(addMessage('You need permission to access this page!', 300));
    }
  };

  const handleContinueDraftClick = (visitId: number) => () => {
    history.push(`/patient/${patientId}/order/draft/${visitId}`);
  };

  const content = (
    <>
      <Button
        fullWidth
        color='primary'
        variant='contained'
        onClick={handleNewVisitClick()}
      >
        {purpose === 'edit' ? null : <AddIcon />}
        {drawerButtonLabel}
      </Button>
      <Box my={2}>
        <Typography className='grey700Color leftSideLine rightSideLine'>
          &nbsp;Or&nbsp;
        </Typography>
      </Box>
      <Box>
        <Typography align='left' variant='h4' className='darkPrimaryColor'>
          Continue draft visits
        </Typography>
        <Box className={classes.cardWrapper}>
          {draftVisits.map((visit, index, arr) => (
            <DraftVisitCard
              key={visit.id}
              withActionButton
              actionButtonProps={{
                label: 'Continue',
                onClick: handleContinueDraftClick(visit.id),
              }}
              doctor={getUserFullName(visit.last_modifier)}
              datetime={visit.modified}
              className={index + 1 !== arr.length ? 'mb-3' : ''}
            />
          ))}
        </Box>
      </Box>
    </>
  );

  return (
    <>
      <Button
        fullWidth
        color='primary'
        variant='contained'
        {...buttonProps}
        onClick={handleNewVisitClick(true)}
        disabled={isFetching}
      >
        {isFetching ? (
          <Box py={0.5} mr={0.5} className='flex-center'>
            <CircularProgress size={loadingSize} color='inherit' />
          </Box>
        ) : purpose === 'edit' ? null : (
          <AddIcon />
        )}
        {buttonLabel}
      </Button>

      {isMobile ? (
        <SwipeableDrawer
          disableSwipeToOpen
          anchor='bottom'
          open={openDrawer}
          onOpen={triggerDrawer(true)}
          onClose={triggerDrawer(false)}
          onClick={(e) => e.stopPropagation()}
        >
          {content}
        </SwipeableDrawer>
      ) : (
        <Dialog
          fullWidth
          maxWidth='xs'
          open={openDrawer}
          onClose={triggerDrawer(false)}
        >
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={triggerDrawer(false)}
          >
            <CloseIcon />
          </IconButton>
          {content}
        </Dialog>
      )}
    </>
  );
};

export default connect(
  (state: RootStateOrAny) => ({
    draftVisits: state.order.draftVisits ?? [],
  }),
  {
    getDraftVisits: (profileId: number, purpose?: IDraftVisitPurpose) =>
      getDraftVisits(profileId, 0, purpose),
  }
)(DraftVisitsDrawer);
