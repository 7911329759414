import React, { useMemo, useState } from 'react';
import { setCardexAppendix } from '../../../../../action/order';
import {
  withStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ICardexAppendix, IVisit } from '../../../../../types/order';
import { getCardexHistoryItems } from '../../../../../Utilities/order';
import { connect, RootStateOrAny } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createTaskNotice } from '../../../../../api/order';
import EMPTY_CARDEX_VECTOR from '../../../../../Assets/vectors/empty.svg';
import FinishedItem from './Item';
import { ReactComponent as ArrowRight } from '../../../../../Assets/icons/arrow_right_darkPrimary.svg';
import clsx from 'clsx';

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    cursor: 'pointer',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles({
  root: {
    boxShadow: 'none',
  },
  expandedArrow: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 4,
    transition: '200ms',
    '&.expanded': {
      transform: 'rotate(90deg)',
    },
  },
  detail: {
    padding: 0,
  },
});

interface IRouteParams {
  patientId: string;
}

interface IProps {
  headVisit: IVisit | null;
  cardexAppendix: ICardexAppendix | null;
  setCardexAppendix: (cardexAppendix: ICardexAppendix | null) => void;
}

const FinishedTasks: React.FC<IProps> = ({
  headVisit,
  cardexAppendix,
  setCardexAppendix,
}) => {
  const classes = useStyles();
  const { patientId } = useParams<IRouteParams>();
  const [expanded, setExpanded] = useState(false);

  const items = useMemo(
    () =>
      getCardexHistoryItems(headVisit, cardexAppendix?.task_notices ?? null),
    [headVisit, cardexAppendix]
  );

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleSaveMarkAsUnfinished = (uuid: string) => {
    return createTaskNotice(patientId, {
      [uuid]: { label: 'unfinished' },
    }).then((res) => {
      setCardexAppendix(res.data);
      return Promise.resolve(res);
    });
  };

  return (
    <Accordion expanded={expanded} className={classes.root}>
      <AccordionSummary onClick={toggleExpanded}>
        <Typography
          variant='h5'
          align='left'
          className='darkPrimaryColor semiBoldWeight'
        >
          Finished tasks
        </Typography>
        <Box className={clsx(classes.expandedArrow, expanded && 'expanded')}>
          <ArrowRight />
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.detail}>
        {items.length ? (
          <Box>
            {items.map((item, index) => (
              <Box mb={index !== items.length - 1 ? 1.75 : 0} key={index}>
                <FinishedItem
                  item={item}
                  onMarkAsUnfinished={handleSaveMarkAsUnfinished}
                />
              </Box>
            ))}
          </Box>
        ) : (
          <Box m='auto' width={1} maxWidth='500px' py={4}>
            <img src={EMPTY_CARDEX_VECTOR} height={120} alt='no visit' />
            <Typography color='textSecondary'>There is no item</Typography>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default connect(
  (state: RootStateOrAny) => ({
    headVisit: state.order.headVisit,
    cardexAppendix: state.order.cardexAppendix,
  }),
  { setCardexAppendix }
)(FinishedTasks);
