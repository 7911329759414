import React, { useRef, useState } from 'react';
import { IVisit } from '../../../types/order';
import { IconButton, Button, Box, CircularProgress } from '@material-ui/core';
import useCheckMobileScreen from '../../../Hook/useCheckMobileScreen';
import { ReactComponent as PrintIcon } from '../../../Assets/icons/vuesax_outline_printer.svg';
import PhysicianOrdersComponentToPrint from './ComponentToPrint';
import { useReactToPrint } from 'react-to-print';
import { getSignatureByUserId } from '../../../api/userApi';
import { IPublicUser } from '../../../types/user';
import { getVisit } from '../../../api/order';
import './style.scss';

export interface IPrintItem {
  visit: IVisit | null;
  parentVisit?: IVisit | null;
}

interface IProps {
  visits?: number[];
}

const PhysicianOrdersPrint: React.FC<IProps> = ({ visits = [] }) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const isMobile = useCheckMobileScreen();
  const [items, setItems] = useState<IPrintItem[]>([]);
  const [signatures, setSignatures] = useState<{ [id: string]: string }>({});
  const [loading, setLoading] = useState(false);

  const triggerPrint = useReactToPrint({
    pageStyle: `@media print {
      @page {
        size: A4;
        
      }
    }`,
    content: () => contentRef.current,
  });
  // margin: 16mm 16mm 16mm 16mm;

  const getSignatures = async (items: IPrintItem[]) => {
    const newSignatures: { [id: string]: string } = {};

    const addSignature = async (user?: IPublicUser | null) => {
      if (user && user.has_signature && !newSignatures[String(user.id)]) {
        try {
          const res = await getSignatureByUserId(user.id);

          if (res && res.data) {
            const imageUrl = await URL.createObjectURL(res.data);
            newSignatures[String(user.id)] = imageUrl;
          }
        } catch (err) {
          console.log(err);
        }
      }
    };

    for (const item of items) {
      await addSignature(item.visit?.checker);

      for (const signer of item.visit?.signers ?? []) {
        await addSignature(signer);
      }
    }

    await setSignatures(newSignatures);
  };

  const collectPrintItems = async () => {
    const newItems: IPrintItem[] = [];
    const collectedVisits: { [id: number]: IVisit } = {};

    const collectVisit = async (visitId: number | null) => {
      try {
        if (visitId) {
          if (collectedVisits[visitId]) {
            return collectedVisits[visitId];
          } else {
            const { data: visit } = await getVisit(visitId);
            collectedVisits[visit.id] = visit;
            return visit;
          }
        }
      } catch (err) {
        console.log(err);
      }

      return null;
    };

    for (const visitId of visits) {
      const visit = await collectVisit(visitId);
      const parentVisit = await collectVisit(visit?.parent || null);

      newItems.push({ visit, parentVisit });
    }

    await setItems([...newItems]);
    return newItems;
  };

  const handlePrintClick = async () => {
    if (!loading) {
      await setLoading(true);
      const items = await collectPrintItems();
      await getSignatures(items);

      triggerPrint();
      setLoading(false);
    }
  };

  return (
    <>
      {isMobile ? (
        <IconButton color='primary' onClick={handlePrintClick}>
          {loading ? <CircularProgress size={24} /> : <PrintIcon />}
        </IconButton>
      ) : (
        <Button
          color='primary'
          startIcon={loading ? <CircularProgress size={24} /> : <PrintIcon />}
          onClick={handlePrintClick}
        >
          Print
        </Button>
      )}

      <Box
        position='fixed'
        top={0}
        left={-1000}
        visibility='hidden'
        zIndex={-10}
      >
        <PhysicianOrdersComponentToPrint
          ref={(el) => {
            contentRef.current = el;
          }}
          items={items}
          signatures={signatures}
        />
      </Box>
    </>
  );
};

export default PhysicianOrdersPrint;
