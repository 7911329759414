import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { addMessage } from '../../../../action/generalActions';
import {
  deleteTaskTemplate,
  getTaskTemplate,
  updateTaskTemplate,
} from '../../../../api/order';
import { IBaseTaskTemplate } from '../../../../types/order';
import PageHeader from '../../../PageHeader/PageHeader';
import { Box, IconButton } from '@material-ui/core';
import { ReactComponent as DeleteIcon } from '../../../../Assets/icon_trash_red.svg';
import VisitTemplateForm from './Form';
import { useQuery } from 'react-query';
import { AlertDialog } from '../../../shared';
import usePathAction from '../../../../Hook/usePathAction';
import PageWrapper from '../../../PageWrapper';

interface IProps {
  onRefreshList?: () => void;
}

interface IPathParams {
  patientId: string;
  templateId: string;
  visitId?: string;
}

const VisitTemplateEdit: React.FC<IProps> = ({ onRefreshList }) => {
  const history = useHistory();
  const { patientId, templateId, visitId } = useParams<IPathParams>();
  const dispatch = useDispatch();
  const [pathAction, setPathAction] = usePathAction();

  const { data: taskTemplateRes, isLoading } = useQuery(
    ['taskTemplate', templateId],
    () => getTaskTemplate(templateId),
    { enabled: !!templateId }
  );

  const defaultFormValues = useMemo(
    () => ({
      tasks: taskTemplateRes?.data.tasks ?? [],
      title: taskTemplateRes?.data.title ?? '',
      description: taskTemplateRes?.data.description ?? '',
      public: taskTemplateRes?.data.public ?? true,
    }),
    [taskTemplateRes]
  );

  const handleSubmit = async (data: IBaseTaskTemplate) => {
    return updateTaskTemplate(templateId, data)
      .then(() => {
        history.goBack();
        if (onRefreshList) onRefreshList();
        dispatch(addMessage('Template updated successfully!', 200));
      })
      .catch((err) => {
        console.log(err);
        dispatch(addMessage('An error occurred on update template!', 400));
      });
  };

  const handleDiscard = () => {
    history.goBack();
  };

  const openDeleteConfirmation = () => {
    setPathAction('openDeleteTemplateConfirmation');
  };

  const handleDeleteTemplate = async () => {
    await deleteTaskTemplate(templateId)
      .then(() => {
        dispatch(addMessage('Template has been deleted successfully!', 200));
        history.go(-3);
        if (onRefreshList) onRefreshList();
      })
      .catch((err) => {
        console.log(err);
        dispatch(addMessage('An error occurred on delete template!', 400));
      });
  };

  return (
    <>
      <PageHeader
        desktopBackButton
        showPatientInfo={false}
        title='Edit visit template'
        optionComponent={
          <Box mr={-1.5}>
            <IconButton color='secondary' onClick={openDeleteConfirmation}>
              <DeleteIcon />
            </IconButton>
          </Box>
        }
      />

      <PageWrapper loading={isLoading}>
        <VisitTemplateForm
          defaultValues={defaultFormValues}
          onSubmit={handleSubmit}
          onDiscard={handleDiscard}
        />

        <AlertDialog
          withConfirmLoading
          open={pathAction === 'openDeleteTemplateConfirmation'}
          alertText='Are you sure you want to delete this template?'
          confirmButton={true}
          confirmText='Delete'
          confirmButtonColor='secondary'
          closeText='Cancel'
          handleClose={() => setPathAction('')}
          handleConfirm={handleDeleteTemplate}
        />
      </PageWrapper>
    </>
  );
};

export default VisitTemplateEdit;
