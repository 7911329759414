import { useEffect } from 'react';

const useOutsideClick = (
  ref: HTMLElement | undefined |  (HTMLElement | undefined)[] ,
  callback: () => void
): void => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref) return;

      if(Array.isArray(ref)){
        if (!ref.some(item =>item && item.contains(event.target as Node))) {
         console.log(ref);
            callback();
        }
      }else {
        if (!ref.contains(event.target as Node)) {
            callback();
        }
      }
    };

    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);
};

export default useOutsideClick;
