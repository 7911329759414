import React, { useRef } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import clsx from 'clsx';
import { CheckRtl } from '../../Utilities/CheckLang';

const useStyles = makeStyles({
  inputContainer: {
    position: 'relative',
    width: '100%',
    height: '35px',
  },
  inputPlaceholder: ({ hasValue }) => ({
    position: 'absolute',
    height: '100%',
    width: '100%',
    left: hasValue ? -1 : '8px',
    top: hasValue ? -25 : 0,
    display: 'flex',
    alignItems: 'center',
    zIndex: 0,
    transition: 'all 0.3s ease',
    cursor: 'text',
    color: '#a7a7a7',
    textAlign: 'left !important',
  }),
  input: {
    height: '100%',
    width: '100%',
    background: '#f2f2f2',
    borderRadius: '5px',
    outline: 'none',
    border: 'none',
    padding: '0px 8px',
    margin: '0px',
    fontSize: '1em',

    '&:focus+span,&.has-value+span': {
      top: -25,
      left: -1,
    },
  },
});
export default function HeaderInput({ label, ...props }) {
  const inputRef = useRef();
  const classes = useStyles({ hasValue: inputRef.current?.value });

  const focusOnInput = () => {
    inputRef.current.focus();
  };

  return (
    <div className={classes.inputContainer}>
      <input
        autoFocus
        {...props}
        type='text'
        className={clsx(
          classes.input,
          CheckRtl(inputRef.current?.value || '') && 'farsiFont'
        )}
        dir='auto'
        ref={inputRef}
      />
      <span
        dir='auto'
        className={classes.inputPlaceholder}
        onClick={focusOnInput}
      >
        {label}
      </span>
    </div>
  );
}
