import React from 'react';
import { AbstractAuthWall, styles } from './AbstractAuthWall';
import { demandVerification } from '../../action/userAction';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { Loader } from '../../Components/shared';
import { Link } from 'react-router-dom';

var moment = require('moment-jalaali');

class ForgotPassword extends AbstractAuthWall {
    async handleSubmit(values) {
        this.setState({ loader: true });
        const body = {
          username: values.username
        };
        const response = await this.props.demandVerification(body);
        this.setState({ loader: false });
        if (response) {
          this.props.history.replace(
            '/change_password',
            {constantUsername: values.username, verificationCodeExpiration: response.data.expires}
        );
        }
    };
    
    formButtons() {
        // TODO: timed desabled
        return (
          <>
            <div>
              <button className='AuthWallMainButton'>
                {this.state.loader ? (
                  <Loader color='#ffffff' height={'30px'} />
                ) : (
                  'Send SMS Code'
                )}
              </button>
            </div>
            <div>
              <button className='AuthWallLink' onClick={async () => (this.props.history.replace('/login'))}>
                Back to Login
              </button>
            </div>
            </>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = 'Required';
    }
    return errors;
  };
  
  const warn = (values) => {
    const warnings = {};
    return warnings;
  };
  
const mapStateToProps = (state) => {
    return {
        auth: state.authReducer.auth,
        title: 'Enter username to reset password',
        hidePasswordField: true,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ demandVerification }, dispatch);
  
  
export default reduxForm({
  form: 'forgotPassword', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn, // <--- warning function given to redux-form
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPassword)));
