import React, { useEffect, useRef, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import VisitTemplatesList, { RefreshHandle } from './List';
import DrawerPage from '../../../DrawerPage';
import { IOrder, ITask, IVisit } from '../../../../types/order';
import { connect, RootStateOrAny } from 'react-redux';
import VisitTemplateView from './View';
import VisitTemplateCreate from './Create';
import taskTemplateRoutes from '../../../../routes/taskTemplate';
import VisitTemplateEdit from './Edit';

interface IProps {
  visit: IVisit | null;
  tasks?: ITask[];
  onAddOrders?: (orders: IOrder[]) => void;
}

type PageType =
  | 'task_template_list'
  | 'task_template_create'
  | 'task_template_view'
  | 'task_template_edit'
  | 'none';

interface PageData {
  key: number;
  component: JSX.Element;
  open: boolean;
  innerPages: PageData[];
}

const VisitTemplates: React.FC<IProps> = ({ visit, tasks, onAddOrders }) => {
  const refreshHandleRef = useRef<RefreshHandle>(null);
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<PageType>('none');

  useEffect(() => {
    const matchedRoute = taskTemplateRoutes.find((r) =>
      matchPath(location.pathname, { path: r.path, exact: true })
    );

    if (matchedRoute) {
      setCurrentPage(matchedRoute.key as PageType);
    } else {
      setCurrentPage('none');
    }
  }, [location.pathname]);

  const pageStructure: PageData[] = [
    {
      key: 1,
      component: <VisitTemplatesList ref={refreshHandleRef} />,
      open: currentPage !== 'none',
      innerPages: [
        {
          key: 2,
          component: (
            <VisitTemplateView
              onAddOrders={onAddOrders}
              onRefreshList={() => refreshHandleRef.current?.refreshTemplates()}
            />
          ),
          open: currentPage === 'task_template_view',
          innerPages: [],
        },
        {
          key: 3,
          component: (
            <VisitTemplateCreate
              visit={visit}
              tasks={tasks}
              onRefreshList={() => refreshHandleRef.current?.refreshTemplates()}
            />
          ),
          open: currentPage === 'task_template_create',
          innerPages: [],
        },
        {
          key: 4,
          component: (
            <VisitTemplateEdit
              onRefreshList={() => refreshHandleRef.current?.refreshTemplates()}
            />
          ),
          open: currentPage === 'task_template_edit',
          innerPages: [],
        },
      ],
    },
  ];

  const renderPageStructure = (pages: PageData[]) => {
    return pages.map(({ key, component, open, innerPages }) => (
      <DrawerPage key={key} open={open}>
        {component}

        {renderPageStructure(innerPages)}
      </DrawerPage>
    ));
  };

  return <>{renderPageStructure(pageStructure)}</>;
};

export default connect((state: RootStateOrAny) => ({
  visit: state.order.visit,
}))(VisitTemplates);
