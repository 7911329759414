import * as actionTypes from '../action/actionTypes';
// states related to a specific patient
const initialState = {
  wardPatients: null,
  shiftPatients: null,
  noMoreHttpFetch: null,
  pinnedPatients: null,
  pinnedProfileImages: null,
  searchQuery: '',
  pageNumber: 1,
  patientLoader: false,
  networkError: null,
  patient: {
    labResult: null,
    labResultLastUpdate: null,
    profile: null,
    radiologyResult: null,
    radiologyResultLastUpdate: null,
  },
  imageUploadQueue: [],
  section: '',
};

export const patientsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_WARDS_PATIENTS': {
      var currentWards =
        action.data && state.wardPatients
          ? state.wardPatients.concat(action.data)
          : state.wardPatients;
      return {
        ...state,
        wardPatients: currentWards,
        noMoreHttpFetch: action.noMoreHttpFetch,
      };
    }
    case 'REPLACE_SHIFT_PATIENTS': {
      // var currentShifts = (state.shiftPatients).concat(action.data)
      return {
        ...state,
        shiftPatients: action.data,
        noMoreHttpFetch: action.noMoreHttpFetch,
      };
    }
    case 'REPLACE_PATIENT_SHIFT': {
      const patient = action.payload;
      const newList = state.shiftPatients?.map((val) => {
        if (val.profile_id === patient?.profile_id) {
          return patient;
        } else {
          return val;
        }
      });
      return {
        ...state,
        shiftPatients: newList,
      };
    }
    case 'REPLACE_PATIENT_WARD': {
      const patient = action.payload;
      const newList = state.wardPatients?.map((val) => {
        if (val.profile_id === patient?.profile_id) {
          return patient;
        } else {
          return val;
        }
      });
      return {
        ...state,
        wardPatients: newList,
      };
    }
    case actionTypes.UPDATE_PATIENT_PROFILE: {
      const wardPatients = state.wardPatients?.map((patient) =>
        String(patient.profile_id) === String(action.patientId)
          ? { ...patient, ...action.profile }
          : patient
      );

      return { ...state, wardPatients };
    }
    case 'REPLACE_PINNED_PATIENT_WARD': {
      const patient = action.payload;
      const newList = state.pinnedPatients?.ward?.map((val) => {
        if (val.id === patient?.profile_id) {
          return { ...val, patient };
        } else {
          return val;
        }
      });
      return {
        ...state,
        pinnedPatients: {
          ...state.pinnedPatients,
          ward: newList,
        },
      };
    }
    case 'REPLACE_PINNED_PATIENT_SHIFT': {
      const patient = action.payload;
      const newList = state.pinnedPatients?.shift?.map((val) => {
        if (val.id === patient?.profile_id) {
          return { ...val, patient };
        } else {
          return val;
        }
      });
      return {
        ...state,
        pinnedPatients: {
          ...state.pinnedPatients,
          shift: newList,
        },
      };
    }
    case 'REPLACE_WARD_PATIENTS': {
      // var currentShifts = (state.shiftPatients).concat(action.data)
      return {
        ...state,
        wardPatients: action.data,
        noMoreHttpFetch: action.noMoreHttpFetch,
      };
    }
    case 'ADD_SHIFT_PATIENTS': {
      var currentShifts =
        state.shiftPatients && action.data
          ? state.shiftPatients.concat(action.data)
          : state.shiftPatients;
      return {
        ...state,
        shiftPatients: currentShifts,
        noMoreHttpFetch: action.noMoreHttpFetch,
      };
    }
    case 'ADD_SEARCH_QUERY': {
      return { ...state, searchQuery: action.data };
    }
    case 'ADD_PAGE_NUMBER': {
      return { ...state, pageNumber: action.data };
    }
    case 'PATIENT_LOADER': {
      return {
        ...state,
        patientLoader: action.data,
        networkError: action.networkError,
      };
    }
    case 'REMOVE_PATIENTS': {
      return { ...state, shiftPatients: [], wardPatients: [] };
    }
    case 'GET_LAB_RESULT': {
      return {
        ...state,
        patient: {
          ...state.patient,
          labResult: action.data,
          labResultFetchedFromDB:
            action.dataFetchedFromDB === undefined
              ? state.patient.labResultFetchedFromDB
              : action.dataFetchedFromDB,
          labResultLastUpdate: action.lastUpdate,
        },
      };
    }
    case 'GET_RADIOLOGY_RESULT': {
      return {
        ...state,
        patient: {
          ...state.patient,
          radiologyResult: action.data?.radiology_services,
          pacsUrl: action.data?.pacs_url,
          radiologyResultFetchedFromDB:
            action.dataFetchedFromDB === undefined
              ? state.patient.radiologyResultFetchedFromDB
              : action.dataFetchedFromDB,
          radiologyResultLastUpdate: action.lastUpdate,
        },
      };
    }
    case 'GET_PROFILE': {
      return {
        ...state,
        patient: {
          ...state.patient,
          profile: action.data ? action.data : state.patient.profile,
          profileDataFetchedFromDB:
            action.dataFetchedFromDB === undefined
              ? state.patient.profileDataFetchedFromDB
              : action.dataFetchedFromDB,
        },
      };
    }
    case 'GET_PATIENT_CONSULTS': {
      return {
        ...state,
        patient: {
          ...state.patient,
          consults: action.data,
        },
        // pageDataFetched: action.pageDataFetched,
      };
    }
    case 'GET_PATIENT_CONSULT_DETAIL': {
      return {
        ...state,
        patient: {
          ...state.patient,
          illnesses: action.illnesses,
          lastProblems: action.lastProblems,
          consultDetail: action.data,
          profile: action.profile ? action.profile : state.patient?.profile,
        },
        // pageDataFetched: action.pageDataFetched,
      };
    }
    case 'GET_PINNED_PATIENT': {
      return {
        ...state,
        pinnedPatients: {
          ...state.pinnedPatients,
          [action.section]: action.data,
        },
      };
    }
    case actionTypes.SET_PINNED_PROFILE_IMAGES: {
      return {
        ...state,
        pinnedProfileImages: action.data,
      };
    }

    case actionTypes.UPLOAD_IMAGE_REQUEST: {
      const { queueId: id, profileId, blob } = action;
      const isExistItem = state.imageUploadQueue?.find(
        (item) => item.id === id
      );

      if (isExistItem === undefined) {
        return {
          ...state,
          imageUploadQueue: [
            ...(state.imageUploadQueue || []),
            { id, profileId, blob, status: 'uploading' },
          ],
        };
      } else {
        const newImageUploadQueue = state.imageUploadQueue.map((item) => {
          if (item.id === id) return { ...item, status: 'uploading' };
          return item;
        });

        return {
          ...state,
          imageUploadQueue: newImageUploadQueue || [],
        };
      }
    }

    case actionTypes.UPLOAD_IMAGE_REMOVE: {
      const { id } = action;
      const newImageUploadQueue = state.imageUploadQueue.filter(
        (item) => item.id !== id
      );
      return {
        ...state,
        imageUploadQueue: newImageUploadQueue || [],
      };
    }

    case actionTypes.UPLOAD_IMAGE_FAILURE: {
      const { id } = action;
      const newImageUploadQueue = state.imageUploadQueue.map((item) => {
        if (item.id === id) return { ...item, status: 'failed' };
        return item;
      });
      return {
        ...state,
        imageUploadQueue: newImageUploadQueue || [],
      };
    }

    case actionTypes.SET_SECTION:
      return {
        ...state,
        section: action.section,
      };

    default:
      return state;
  }
};
