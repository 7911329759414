import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../../../action/userAction';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import USER from '../../../../Assets/icons/user.svg';
import { AlertDialog } from '../../../shared';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetPassDialog: false,
      showUserSignatureDialog: false,
      showLogoutAlert: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.state !== this.props.location.state) {
      this.setState({
        ...this.state,
        showLogoutAlert: this.props.location.state?.action === 'logout_alert',
      });
    }
  }

  async handleLogOut() {
    await this.props.logout();
    this.props.history.replace('/');
  }

  render() {
    return (
      <div className='setting__section'>
        <div className='setting__section__title'>
          <img className='mr-2' alt='Sections' width={21} src={USER} />
          Profile
        </div>
        <div
          className='setting__section__element'
          onClick={() => {
            this.props.history.push('/setting/profile/signature');
          }}
        >
          Signature
        </div>
        <div
          className='setting__section__element'
          onClick={() => {
            this.props.history.push('/setting/profile/reset_password');
          }}
        >
          Reset Password
        </div>

        <div
          className='setting__section__element'
          onClick={() =>
            this.props.history.push(this.props.match.url, {
              action: 'logout_alert',
            })
          }
        >
          Log Out
        </div>
        <AlertDialog
          open={this.state.showLogoutAlert}
          handleClose={() => {
            this.props.history.replace(this.props.match.url);
            this.props.history.goBack();
          }}
          handleConfirm={() => this.handleLogOut()}
          alertText={'Are you sure you want to log out?'}
          closeText='No'
          confirmButton
          confirmButtonColor='secondary'
          confirmText='Yes'
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    snackBarMessage: state.snackBarReducer.snackBarMessage,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ logout }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
