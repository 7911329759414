const initialState = {
  snackBarMessage: '',
  snackBarStatus: '',
  snackBarPosition: null,
};

export const snackBarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
      return {
        ...state,
        snackBarMessage: action.message,
        snackBarStatus: action.status,
        snackBarPosition: action.position,
      };
    case 'REMOVE_SNACK_DATA':
      return { ...state, snackBarMessage: '', snackBarStatus: '' };
    default:
      return state;
  }
};
