/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component } from 'react';
// import { ICD11SelectedItem } from '../shared';
import TUM_ICON from '../../../Assets/logos/TUM.jpg';
import SINA_ICON from '../../../Assets/logos/sina_logo.jpg';
import CMC_ICON from '../../../Assets/logos/ChildMedicalCenter_B&W.jpg';
import YAS_ICON from '../../../Assets/logos/yas.png';
import HAKIM_ICON from '../../../Assets/logos/hakim.png';
import { CheckRtl, renderAutoDirText } from '../../../Utilities/CheckLang';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  jalaliFullDateTime,
  jalaliFullDate,
  getTime,
} from '../../../Utilities/jalaliDate';
import { getUserFullName } from '../../../Utilities/user';

const RenderHTML = (props) => (
  <span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>
);
const styles = {
  flexDisplay1: {
    display: 'flex',
    flexFlow: 'row-reverse',
    justifyContent: 'space-around',
  },
  flexDisplay2: {
    display: 'flex',
    flexFlow: 'row-reverse',
    justifyContent: 'space-between',
    paddingBottom: '.2em',
    // alignItems: 'center'
  },
  flexDisplay2WithoutPadding: {
    display: 'flex',
    flexFlow: 'row-reverse',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  borderCells: {
    borderBottom: '1px solid',
    minHeight: '37px',
    padding: '.3em .5em',
    height: '100%',
    borderLeft: '1px solid',
  },
  signature: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  signatureItem: {
    height: '135px',
    maxWidth: '180px',
    padding: '10px',
  },
  signatureContainer: {
    // margin: '1.2em'
  },
};
// ⚠️ react to print only works with class based component
export class ConsultToPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      priorities: [
        { label: 'Elective', value: 3 },
        { label: 'Emergent', value: 2 },
        { label: 'Urgent', value: 1 },
      ],
    };
  }

  getIcon() {
    const organization = (window.env.REACT_APP_ORGANIZATION + '').toLowerCase();

    switch (organization) {
      case 'sina':
        return {
          src: SINA_ICON,
          alt: 'sina_icon',
        };
      case 'yas':
        return {
          src: YAS_ICON,
          alt: 'yas_icon',
        };
      case 'hakim':
        return {
          src: HAKIM_ICON,
          alt: 'hakim_icon',
        };
      default:
        return {
          src: CMC_ICON,
          alt: 'cmc_icon',
        };
    }
  }

  setPriority(priority) {
    if (+priority === 3) {
      return {
        priorityClassName: 'priority-elective',
        priorityText: 'Elective',
      };
    } else if (+priority === 2) {
      return {
        priorityClassName: 'priority-emergent',
        priorityText: 'Emergent',
      };
    } else if (+priority === 1) {
      return {
        priorityClassName: 'priority-critical',
        priorityText: 'Urgent',
      };
    } else if (+priority === 0) {
      return {
        priorityClassName: 'priority-death',
        priorityText: 'Death expectant',
      };
    }
  }
  renderHeader(consult) {
    return (
      <div>
        <div className='uniLogo inline-block' style={{ width: '15%' }}>
          <img alt='tum icon' className='' height={80} src={TUM_ICON} />
        </div>
        <div className='headerText inline-block' style={{ width: '60%' }}>
          <div style={styles.flexDisplay1}>
            <div className='printFarsifont inline-block'>
              دانشگاه علوم پزشکی
            </div>
            <div className='inline-block'>
              The University of Medical Science
            </div>
          </div>
          <div style={styles.flexDisplay1}>
            <div className='inline-block printFarsifont'>مرکز درمانی آموزش</div>
            <div className='inline-block'>Educational & Treatment Center</div>
          </div>
          <div
            className='printFarsifont text-center bold'
            style={{ padding: '6px' }}
          >
            برگ درخواست مشاوره
          </div>
          <div className='text-center bold'>CONSULTATION REQUEST SHEET</div>
        </div>
        <div
          className='unit inline-block'
          style={{
            width: '25%',

            display: 'inline-block',
            flexFlow: 'column',
          }}
        >
          <div className='text-center' style={{ padding: '10px' }}>
            <img
              alt={this.getIcon().alt}
              className=''
              height={60}
              src={this.getIcon().src}
            />
          </div>
          <div
            style={{
              border: '1px solid black',
              borderBottom: 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexFlow: 'row-reverse',
                justifyContent: 'space-between',
                padding: '.5em',
              }}
            >
              <div className='printFarsifont inline-block rtl'>
                شماره پذیرش:
              </div>
              <div className='inline-block '>Admission Number:</div>
            </div>

            <div className='text-center bold'>
              {consult.patient?.admission_id}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderDateSection(consult) {
    return (
      <div style={styles.borderCells}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '33.3% 43.3% 23.3%',
            gridTemplateRows: 'auto auto',
          }}
        >
          <div>
            <div
              style={{
                display: 'grid',
                gridArea: '1/1/2/2',
              }}
            >
              {'Date of Request:'}
            </div>
            <div
              style={{
                display: 'grid',
                gridArea: '2/2/2/2',
              }}
            >
              {'Date of Consultation:'}
            </div>
          </div>

          <div>
            <div
              style={{
                display: 'grid',
                gridArea: '1/1/3/3',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {jalaliFullDate(consult.effective_request_datetime)}{' '}
              {getTime(consult.effective_request_datetime)}
            </div>
            <div
              style={{
                display: 'grid',
                gridArea: '2/2/3/3',
                textAlign: 'center',
                fontWeight: 'bold',
              }}
            >
              {consult.replied_datetime &&
                jalaliFullDate(consult.replied_datetime)}{' '}
              {consult.replied_datetime && getTime(consult.replied_datetime)}
            </div>
          </div>
          <div className='printFarsifont rtl'>
            <div
              style={{
                display: 'grid',
                gridArea: '1/1/4/4',
              }}
            >
              {' تاریخ درخواست:'}
            </div>
            <div
              style={{
                display: 'grid',
                gridArea: '2/2/4/4',
              }}
            >
              {'   تاریخ پاسخ:'}
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderPrioritySection(consult) {
    let priority = this.setPriority(consult.priority);
    return (
      <div
        className={`${priority?.priorityClassName} text-center bold`}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}
      >
        {this.state.priorities.map((priority) => (
          <div className='inline-block' key={priority.value}>
            <FormControlLabel
              style={{ fontFamily: 'Times' }}
              control={
                <Checkbox
                  style={{
                    width: 25,
                    height: 25,
                    padding: 0,
                  }}
                  icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 20 }} />}
                  checkedIcon={
                    <CheckBoxIcon style={{ fontSize: 20, color: 'black' }} />
                  }
                  checked={consult.priority === priority.value}
                  value={priority.value}
                  color='primary'
                />
              }
              label={
                <span
                  style={{
                    fontFamily: 'Times',
                    fontSize: '.7rem',
                  }}
                >
                  {priority.label}
                </span>
              }
            />
          </div>
        ))}
      </div>
    );
  }
  renderSectionWithBlockLabel(
    farsiLabel,
    englishLabel,
    value,
    isInFarsi,
    isRight
  ) {
    const borderRight = isRight ? '1px solid' : 'none';
    const valueToRender = typeof value === 'function' ? value() : value;
    return (
      <div style={{ ...styles.borderCells, borderRight: borderRight }}>
        <div style={styles.flexDisplay2}>
          <div className='printFarsifont rtl'>{farsiLabel}:</div>
          <div className=''> {englishLabel}</div>
        </div>
        <div
          className={`${isInFarsi ? 'printFarsifont' : ''} text-center bold`}
        >
          {valueToRender}
        </div>
      </div>
    );
  }
  renderPrimaryDiagnosis(consult) {
    return (
      <>
        <div style={styles.flexDisplay2}>
          <div className='printFarsifont rtl'>تشخیص اولیه:</div>
          <div className='text-center' style={{ fontSize: '9pt' }}>
            {consult.problem_list
              ? consult.problem_list.length > 0
                ? consult.problem_list.map((problem, i) => {
                    if (i + 1 === consult.problem_list.length) {
                      return (
                        <div key={i}>
                          <RenderHTML HTML={problem.title_en} />
                        </div>
                      );
                    }
                    return (
                      <div key={i}>
                        <RenderHTML HTML={problem.title_en} />,
                      </div>
                    );
                  })
                : '-'
              : '-'}
          </div>
          <div className=''>Primary Diagnosis:</div>
        </div>
      </>
    );
  }
  renderConsultation(consult) {
    return (
      <>
        <div style={styles.flexDisplay2}>
          <div className='printFarsifont rtl'>
            گزارشات کلینیکی و موضوع مشاوره:
          </div>
          <div className=''>Object of Consultation & Clinical Notes:</div>
        </div>
        <div
          className={`showNewLines ${
            consult.illness
              ? CheckRtl(consult.illness[0])
                ? 'printFarsifont rtl'
                : ''
              : ''
          }`}
          style={{ padding: '0 1em' }}
        >
          {consult.illness}
        </div>
      </>
    );
  }
  mkUserInfoText(user) {
    return (
        (user.rank ? `${user.rank.role} ` : '') + `${user.first_name} ${user.last_name}`
    );
  }
  renderCauseOfConsultation(consult) {
    const { requesterSignature, printWithSignatures } = this.props;

    return (
      <>
        <div style={styles.flexDisplay2}>
          <div className='printFarsifont rtl'>علت مشاوره:</div>
          <div className=''>Cause of Consultation:</div>
        </div>
        <div
          className={`showNewLines ${
            consult.cause_of_consult
              ? CheckRtl(consult.cause_of_consult[0])
                ? 'printFarsifont rtl'
                : ''
              : ''
          }`}
          style={{ padding: '0 1em' }}
        >
          {consult.cause_of_consult}
        </div>
        <div className=''>{this.mkUserInfoText(consult.requester)}</div>
        {requesterSignature[consult.id] && printWithSignatures && (
          <div style={styles.signatureContainer}>
            <div style={styles.signatureItem}>
              <img
                style={styles.signature}
                src={requesterSignature[consult.id]}
                alt='signature'
              />
            </div>
          </div>
        )}
      </>
    );
  }
  renderReply(consult) {
    const { signatures, printWithSignatures } = this.props;
    return (
      <div
        className='reply-section-to-print'
        style={{
          minHeight: '10px',
          padding: '.5em',
          borderRight: '1px solid',
          borderLeft: '1px solid',
          borderBottom: '1px solid',
        }}
      >
        <div style={styles.flexDisplay2}>
          <div className='printFarsifont rtl'>
            مشاهدات و نظریات پزشک مشاور (خلاصه نظریات، تشخیص و توصیه ها):
          </div>
          <div className=''>
            {"Consultant Physician's Observation & Notes:"}
          </div>
        </div>
        <div
          className={`showNewLines ${
            consult.reply
              ? CheckRtl(consult.reply[0])
                ? 'printFarsifont rtl'
                : ''
              : ''
          }`}
          style={{ padding: '0 1em' }}
        >
          {consult.paper_reply ? (
            <div className='printFarsifont rtl'>با‌سلام و تشکر از مشاوره</div>
          ) : (
            consult.reply
          )}
        </div>
        <div className=''>
          {consult.consultant && this.mkUserInfoText(consult.consultant)}
          {consult.confirm_entries && consult.confirm_entries.filter(ce => ce.confirmer.id != consult.consultant.id).map(
            ce => `، ${this.mkUserInfoText(ce.confirmer)}`
          ).join()}
        </div>
        {printWithSignatures && signatures[consult.id] && (
          <div style={styles.signatureContainer}>
            {signatures[consult.id].map((signature, id) => (
              <div key={id} style={styles.signatureItem}>
                <img
                  key={id}
                  style={styles.signature}
                  src={signature}
                  alt='signature'
                />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
  render() {
    const { consults } = this.props;
    return (
      <div>
        {(consults ?? []).map((consult) => (
          <table key={consult.id} style={{ width: '100%' }}>
            <thead>
              <tr>
                <td>
                  <div className='header-space'></div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div
                    style={{
                      padding: '10px 30px 0 30px',
                      fontSize: '8.2pt',
                      fontFamily: 'Times',
                      lineHeight: '1.2',
                    }}
                  >
                    {this.renderHeader(consult)}
                    <div
                      className='printMainSection'
                      style={{
                        minHeight: 'calc(100% - 180px)',
                        borderTop: ' 1px solid ',
                      }}
                    >
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns:
                            ' 33% 17% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5%',
                          gridTemplateRows: 'auto auto',
                          // display: 'flex',
                          // flexFlow: 'row wrap',
                          // justifyContent: 'space-around'
                        }}
                      >
                        <div
                          className=''
                          style={{
                            display: 'grid',
                            gridColumnStart: '1',
                            gridColumnEnd: '2',
                            gridRowStart: '1',
                            gridRowEnd: '2',
                          }}
                        >
                          {this.renderSectionWithBlockLabel(
                            'پزشک معالج',
                            'Attending Physician',
                            null,
                            true
                          )}
                        </div>
                        <div
                          className=''
                          style={{
                            display: 'grid',
                            gridColumnStart: '1',
                            gridColumnEnd: '2',
                            gridRowStart: '2',
                            gridRowEnd: '3',
                          }}
                        >
                          {this.renderSectionWithBlockLabel(
                            'تاریخ پذیرش',
                            'Date of Admission',
                            jalaliFullDateTime(
                              consult.patient?.admission_datetime
                            )
                          )}
                        </div>

                        <div
                          style={{
                            display: 'grid',
                            gridColumnStart: '2',
                            gridColumnEnd: '3',
                            gridRowStart: '1',
                            gridRowEnd: '3',
                            borderBottom: '1px solid',
                            borderLeft: '1px solid',
                            padding: '.5em',
                          }}
                        >
                          <div style={styles.flexDisplay2WithoutPadding}>
                            <div className='printFarsifont rtl'>بخش:</div>
                            <div className=''>ward:</div>
                          </div>
                          <div
                            className='text-center printFarsifont bold'
                            style={{ lineHeight: '14px', paddingBottom: '4px' }}
                          >
                            {consult.effective_ward?.name}
                          </div>
                          <div>
                            <div style={styles.flexDisplay2WithoutPadding}>
                              <div className='printFarsifont rtl'>اتاق:</div>
                              <div
                                className='text-center printFarsifont bold'
                                style={{
                                  lineHeight: '14px',
                                  paddingBottom: '4px',
                                }}
                              >
                                {consult.effective_room}
                              </div>
                              <div className=''>room:</div>
                            </div>
                          </div>
                          <div style={styles.flexDisplay2WithoutPadding}>
                            <div className='printFarsifont rtl'>تخت:</div>
                            <div className='text-center bold'>
                              {consult.effective_bed}
                            </div>
                            <div className=''>bed:</div>
                          </div>
                        </div>
                        <div
                          className=''
                          style={{
                            display: 'grid',
                            gridColumnStart: '3',
                            gridColumnEnd: '7',
                            gridRowStart: '1',
                            gridRowEnd: '2',
                          }}
                        >
                          {this.renderSectionWithBlockLabel(
                            'نام',
                            'Name',
                            consult.patient?.first_name,
                            true
                          )}
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            gridColumnStart: '7',
                            gridColumnEnd: '13',
                            gridRowStart: '1',
                            gridRowEnd: '2',
                          }}
                        >
                          {this.renderSectionWithBlockLabel(
                            'نام خانوادگی',
                            'Family Name',
                            consult.patient?.last_name,
                            true,
                            true
                          )}
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            gridColumnStart: '3',
                            gridColumnEnd: '8',
                            gridRowStart: '2',
                            gridRowEnd: '3',
                          }}
                        >
                          {this.renderSectionWithBlockLabel(
                            'تاریخ تولد ',
                            'Date of Birth',
                            jalaliFullDate(consult.patient?.birth_date)
                          )}
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            gridColumnStart: '8',
                            gridColumnEnd: '13',
                            gridRowStart: '2',
                            gridRowEnd: '3',
                          }}
                        >
                          {this.renderSectionWithBlockLabel(
                            'نام پدر',
                            "Father's Name",
                            null,
                            false,
                            true
                          )}
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            gridColumnStart: '1',
                            gridColumnEnd: '3',
                            gridRowStart: '3',
                            gridRowEnd: '4',
                          }}
                        >
                          {this.renderDateSection(consult)}
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            gridColumnStart: '3',
                            gridColumnEnd: '-1',
                            gridRowStart: '3',
                            gridRowEnd: '4',
                          }}
                        >
                          {this.renderSectionWithBlockLabel(
                            'درخواست مشاوره با',
                            'Consultation Requested With',
                            () => (
                              <>
                                <span>
                                  {consult.consultant_service &&
                                    consult.consultant_service?.name}{' '}
                                </span>
                                <span>
                                  {getUserFullName(
                                    consult.suggested_consultant
                                  ) && '('}
                                  {renderAutoDirText(
                                    getUserFullName(
                                      consult.suggested_consultant
                                    ),
                                    { rtlClassName: 'rtl' }
                                  )}
                                  {getUserFullName(
                                    consult.suggested_consultant
                                  ) && ')'}
                                </span>
                              </>
                            ),
                            true,
                            true
                          )}
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            gridColumnStart: '1',
                            gridColumnEnd: '3',
                            gridRowStart: '4',
                            gridRowEnd: '5',
                          }}
                        >
                          {this.renderSectionWithBlockLabel(
                            'نوع مشاوره',
                            'Kind of Consultation',
                            () => this.renderPrioritySection(consult)
                          )}
                        </div>
                        <div
                          style={{
                            display: 'grid',
                            gridColumnStart: '3',
                            gridColumnEnd: '-1',
                            gridRowStart: '4',
                            gridRowEnd: '5',
                          }}
                        >
                          {this.renderSectionWithBlockLabel(
                            'نام پزشک درخواست کننده',
                            'Name of Requestive Physician',
                            () => (
                              <>
                                <span>
                                  {consult?.effective_patient_service?.name}{' '}
                                </span>
                                <span>
                                  {getUserFullName(consult.requester) && '('}
                                  {renderAutoDirText(
                                    getUserFullName(consult.requester),
                                    { rtlClassName: 'rtl' }
                                  )}
                                  {getUserFullName(consult.requester) && ')'}
                                </span>
                              </>
                            ),
                            true,
                            true
                          )}
                        </div>
                      </div>
                      <div className='printSecondMainSection'>
                        <div
                          style={{
                            ...styles.borderCells,
                            borderRight: '1px solid',
                          }}
                        >
                          {this.renderPrimaryDiagnosis(consult)}
                        </div>
                        <div
                          style={{
                            ...styles.borderCells,
                            borderRight: '1px solid',
                          }}
                        >
                          {this.renderConsultation(consult)}
                        </div>
                        <div
                          style={{
                            ...styles.borderCells,
                            borderRight: '1px solid',
                          }}
                        >
                          {this.renderCauseOfConsultation(consult)}
                        </div>

                        {this.renderReply(consult)}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td> {/* <div style={{ height: '50px' }}>&nbsp;</div> */}</td>
              </tr>
            </tfoot>

            <div
              className='printfooter'
              id='print-footer'
              style={{
                display: 'flex',
                flexFlow: 'row-reverse',
                justifyContent: 'space-between',
                backgroundColor: '#bdbdbd',
                padding: '10px 30px',
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
              }}
            >
              <div className='printFarsifont'>5</div>
              <div className='printFarsifont'>برگ درخواست مشاوره</div>
              <div></div>
            </div>
          </table>
        ))}
      </div>
    );
  }
}
export default ConsultToPrint;
