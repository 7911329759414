import { setPatientDataBase } from './actions';
import * as api from '../api/api';
import { getPatientDB } from '../DB/db';
import { getPatientProfile } from './profile';

export const getPatientRadiologyResult = (patientId) => async (dispatch) => {
  await dispatch(loadRadiologyDataFromDB(patientId));

  return api
    .getPatientRadiologyResult(patientId)
    .then(async (response) => {
      await dispatch(setPatientDataBase(patientId, response, 'radiology'));
      await dispatch({
        type: 'GET_RADIOLOGY_RESULT',
        data: response.data,
        last_update: response.data.last_update,
      });
    })
    .catch((error) => Promise.reject(error));
};

export const loadRadiologyDataFromDB = (patientId) => async (dispatch) => {
  if (!patientId) return;

  await dispatch({ type: 'GET_RADIOLOGY_RESULT', dataFetchedFromDB: false });
  try {
    let patientInfoDB;
    await getPatientDB(patientId).then((response) => {
      patientInfoDB = response;
    });

    await dispatch(getPatientProfile(patientId, true)).catch((err) =>
      console.log(err)
    );

    if (patientInfoDB && patientInfoDB.radiologyResult) {
      return dispatch({
        type: 'GET_RADIOLOGY_RESULT',
        data: {radiology_services: patientInfoDB.radiologyResult},
        dataFetchedFromDB: true,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
