import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles, Button, Box } from '@material-ui/core';
import Visit from './Visit';
import LastCardex from './Cardex/Last';
import clsx from 'clsx';

export type IContentType = 'cardex_view' | 'visit_view';

const CONTENT_TYPES: { label: string; value: IContentType }[] = [
  { label: 'Last cardex', value: 'cardex_view' },
  { label: 'Visits', value: 'visit_view' },
];

const useTabStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    columnGap: 4,
    backgroundColor: '#f0f1f7',
    borderRadius: 5,
    padding: 4,
  },
  tab: {
    color: theme.palette.grey['700'],
    flex: 1,

    '&.active': {
      color: theme.palette.primary.main,
      backgroundColor: '#fff',
      border: `1px inside ${theme.palette.grey[100]}`,
      boxShadow: '0 2px 8px 0 #29191e25',
    },
  },
}));

const PatientOrderTabs: React.FC<{ type: IContentType }> = ({ type }) => {
  const classes = useTabStyles();
  const history = useHistory();
  const location = useLocation<{ contentType?: IContentType }>();

  const handleChangeType = (newType: IContentType) => () => {
    if (newType === type) return;

    history.replace(location.pathname, {
      ...location.state,
      contentType: newType,
    });
  };

  return (
    <div className={classes.root}>
      {CONTENT_TYPES.map((item, index) => (
        <Button
          key={index}
          className={clsx(classes.tab, item.value === type && 'active')}
          onClick={handleChangeType(item.value)}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    margin: '16px 0 0 0',
    padding: '0 24px',
  },
});

const PatientOrderContent: React.FC = () => {
  const classes = useStyles();
  const location = useLocation<{ contentType?: IContentType }>();

  const currentType = useMemo(
    () =>
      CONTENT_TYPES.find(
        (item) => item.value === location.state?.contentType
      ) || CONTENT_TYPES[0],
    [location.state?.contentType]
  );

  const renderProperContent = (type: IContentType) => {
    switch (type) {
      case 'visit_view':
        return <Visit />;
      case 'cardex_view':
        return <LastCardex />;
      default:
        return null;
    }
  };

  return (
    <Box className={classes.root}>
      <PatientOrderTabs type={currentType.value} />

      {renderProperContent(currentType.value)}
    </Box>
  );
};

export default PatientOrderContent;
