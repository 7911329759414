import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getUserCurrentData, setHospitalName } from './action/userAction';
import * as Sentry from '@sentry/react';
import HomeContainer from './Containers/MainContainer/HomeContainer';
import SettingContainer from './Containers/SettingContainer';
import Login from './Containers/Login/Login';
import ForgotPassword from './Containers/Login/ForgotPassword';
import ChangePassword from './Containers/Login/ChangePassword';
import PasswordChanged from './Containers/Login/PasswordChanged';
import Namespace from './Containers/Namespace/Namespace';
import { AlertDialog } from './Components/shared';

import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import CustomizedSnackbars from './Components/CustomizedSnackbars/CustomizedSnackbars';
import DisableApp from './Containers/MainContainer/DisableApp';

function AnnouncementModal({ title, body, showAnnouncementBanner, hideAnnouncementModal }) {
 
  return (
    <AlertDialog
          titleText={title || 'اعلامیه'}
          alertText={body}
          backgroundColor='#F2B354'
          open={showAnnouncementBanner}
          handleConfirm={() => {
            hideAnnouncementModal();
          }}
          confirmText='متوجه شدم'
          confirmButton
          confirmButtonColor='#264CA5'

    />
  );
}

function InlabNPSModal({ closeNPSModal,showNPSModal }) {
 
  return (
    <AlertDialog
          open={showNPSModal}
          alertText={'جهت بهبود اینلب، لطفا در این نظرسنجی شرکت کنید (3 دقیقه)'}
          altText='بعداً شرکت می‌کنم'
          handleAlt={() => {
            closeNPSModal();
          }}
          closeText='شرکت نمی‌کنم'
          handleClose={() => {
            localStorage.setItem('showNPSModal','false');
            closeNPSModal();
          }}
          confirmText='شرکت در نظرسنجی'
          handleConfirm={() => {
            // TODO: do not hard-code the link:
            window.open('https://docs.google.com/forms/d/e/1FAIpQLSf3_I8AIuIQ0BF9DftjY90OxyXJFGHgNO3tWhmdK1ARdE8rvQ/viewform?usp=sf_link', '_blank');
            localStorage.setItem('showNPSModal','false');
            closeNPSModal();
          }}
          confirmButton
          confirmButtonColor='primary'
        />
  );
}

function App({ isAuthenticate, userData, getUserCurrentData }) {
  const dispatch = useDispatch();

  const [showNPSModal, setShowNPSModal] = useState(false);

  const [showAnnouncementBanner, setShowAnnouncementBanner] = useState(true);

  const closeNPSModal = () => {
    setShowNPSModal(false);
  };
 
  const hideAnnouncementModal = () => {
    setShowAnnouncementBanner(false);
  };

  const showAnnouncementModal = () => {
    setShowAnnouncementBanner(true);
  };

  useEffect(() => {
    if (!isAuthenticate) return;
    if (userData?.id) {
      Sentry.setUser({
        id: userData.id,
        hospital: window.env.REACT_APP_ORGANIZATION ?? '',
      });
      const showNPSModalFromStorage = localStorage.getItem('showNPSModal');
      
      const currentPathName = window.location.pathname.replace(/\/+$/, '');
      if (showNPSModalFromStorage === 'true' && (currentPathName === '/inlab/patient' || currentPathName === '/inlab/namespace')) {
        setShowNPSModal(true);
      }
      
    } else {
      getUserCurrentData();
    }
    if(localStorage.getItem('hospitalName')){
      dispatch(setHospitalName(localStorage.getItem('hospitalName')));
    }



  }, [isAuthenticate, userData]);

  return (
    <div className="App">
      <Router basename={window.env.REACT_APP_PUBLIC_URL ?? ''}>
        <Switch>
          <Route path={'/patient/:patientId?'} component={HomeContainer} />
          <Route path={'/setting'} component={SettingContainer} />
          <Route path='/update' component={DisableApp} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/forgot_password' component={ForgotPassword} />
          <Route exact path='/change_password' component={ChangePassword} />
          <Route exact path='/password_changed' component={PasswordChanged} />
          <Route exact path='/namespace' component={Namespace} />
          <Redirect from='*' to='/login' />
        </Switch>
      </Router>

      <CustomizedSnackbars />

      {window.env.REACT_APP_ANNOUNCEMENT_BODY && (
        <AnnouncementModal
          title={window.env.REACT_APP_ANNOUNCEMENT_TITLE}
          body={window.env.REACT_APP_ANNOUNCEMENT_BODY}
          showAnnouncementBanner={showAnnouncementBanner}
          hideAnnouncementModal={hideAnnouncementModal}
        />
      )}

      {/* {isAuthenticate && showNPSModal && (
        <InlabNPSModal showNPSModal={showNPSModal} closeNPSModal={closeNPSModal} />
      )} */}

    </div>
  );
}

export default connect(
  (state) => ({
    userData: state.userReducer.userData,
    isAuthenticate: Boolean(state.authReducer?.auth?.authorization_token),
  }),
  { getUserCurrentData }
)(App);
