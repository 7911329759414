import React from 'react';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';

export type ITabValue = 'any' | 'self';

export const CONTENT_TYPES: { label: string; value: ITabValue }[] = [
  { label: 'All templates', value: 'any' },
  { label: 'My templates', value: 'self' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    columnGap: 4,
    borderRadius: 5,
    padding: 4,
    border: '1px solid #babfcc',
  },
  tab: {
    color: theme.palette.grey['700'],
    flex: 1,
    padding: 4,

    '&.active': {
      color: theme.palette.primary.main,
      backgroundColor: '#fff',
      border: `1px inside ${theme.palette.grey[100]}`,
      boxShadow: '0 2px 8px 0 #29191e25',
    },
  },
}));

const VisitTemplateHeaderTabs: React.FC<{ value: ITabValue }> = ({ value }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ contentType?: ITabValue }>();

  const handleChangeType = (newType: ITabValue) => () => {
    if (newType === value) return;

    history.replace(location.pathname, {
      ...location.state,
      contentType: newType,
    });
  };

  return (
    <div className={classes.root}>
      {CONTENT_TYPES.map((item, index) => (
        <Button
          key={index}
          className={clsx(classes.tab, item.value === value && 'active')}
          onClick={handleChangeType(item.value)}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};

export default VisitTemplateHeaderTabs;
