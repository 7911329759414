import { ICategory, IOrder, ITaskCategory } from '../../types/order';
import { v4 as uuidv4 } from 'uuid';
import generalIcon from '../../Assets/icons/note.svg';
import nurseIcon from '../../Assets/icons/nurse.svg';
import labIcon from '../../Assets/icons/lab.svg';
import medicationIcon from '../../Assets/icons/medication.svg';
import graphyIcon from '../../Assets/icons/radiology.svg';
import consultIcon from '../../Assets/icons/consult.svg';
// import serviceRequestIcon from '../../Assets/icons/ambulance.svg';
// import hospitalIcon from '../../Assets/icons/hospital_1.svg';

export const categories: { key: ICategory; label: string; icon: string }[] = [
  {
    key: 'general',
    label: 'General',
    icon: generalIcon,
  },
  {
    key: 'nursing',
    label: 'Nursing',
    icon: nurseIcon,
  },
  {
    key: 'lab',
    label: 'Lab',
    icon: labIcon,
  },
  {
    key: 'medication',
    label: 'Medication',
    icon: medicationIcon,
  },
  {
    key: 'radiography',
    label: 'Imaging',
    icon: graphyIcon,
  },
  {
    key: 'consult',
    label: 'Consult',
    icon: consultIcon,
  },
  // {
  //   key: 'service_request',
  //   label: 'Service Request',
  //   icon: serviceRequestIcon,
  // },
  // {
  //   key: 'hospital_service',
  //   label: 'Hospital Service',
  //   icon: hospitalIcon,
  // },
  {
    key: 'other',
    label: 'Other',
    icon: generalIcon,
  },
];

export const initialOrders = (category: ITaskCategory): IOrder | null => {
  const basicTask = {
    uuid: uuidv4(),
    note: '',
  };

  switch (category) {
    case 'lab':
      return {
        task: { ...basicTask, category: 'lab', tests: [] },
        action: 'add',
      };
    case 'nursing':
      return {
        task: {
          ...basicTask,
          category: 'nursing',
          cares: [],
        },
        action: 'add',
      };
    case 'radiography':
      return {
        task: {
          ...basicTask,
          category: 'radiography',
          image_type: {},
          location: {},
          laterality: {},
          contrasted_views: [],
        },
        action: 'add',
      };
    case 'medication':
      return {
        task: {
          ...basicTask,
          category: 'medication',
          drug: {},
          form: {},
          strength: {},
          amount: '',
          route: {},
          added: [],
        },
        action: 'add',
      };
    case 'consult':
      return {
        task: {
          ...basicTask,
          category: 'consult',
          hospital_service: {},
        },
        action: 'add',
      };
    case 'other':
      return {
        task: {
          ...basicTask,
          category: 'other',
          description: '',
        },
        action: 'add',
      };
    default:
      return null;
  }
};
