import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { PropTypes } from 'prop-types';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { CheckRtl } from '../../Utilities/CheckLang';
const styles = makeStyles({
  contentRoot: {
    padding: '24px',
  },
  actionsRoot: {
    padding: '0 24px 24px',
  },
  button: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  buttonPlaceholder: {
    color: '#a7a7a7',
    textTransform: 'capitalize',
  },
});

export default function AlertDialog({
  alertText,
  open,
  closeText,
  handleClose,
  confirmText,
  handleConfirm,
  confirmButton,
  withConfirmLoading,
  altText = undefined,
  handleAlt = undefined,
  confirmButtonColor = 'primary',
  confirmButtonProps = {},
  dialogActionProps = {},
  titleText,
  backgroundColor,
}) {
  const classes = styles({ confirmButtonColor });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading && !open) setLoading(false);
  }, [open]);

  const handleConfirmClick = async () => {
    if (handleConfirm) {
      if (withConfirmLoading) {
        await setLoading(true);
        await handleConfirm();
        await setLoading(false);
      } else {
        handleConfirm();
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={withConfirmLoading && loading ? undefined : handleClose}
    >
      <DialogContent classes={{ root: classes.contentRoot }} style={{ backgroundColor: backgroundColor || 'white'}}>
        {titleText && (
          <Typography variant="h4" gutterBottom
              style={{ direction: CheckRtl(titleText) ? 'rtl' : 'ltr'}}
              className={CheckRtl(titleText) ? 'farsiFont' : ''}>
            {titleText}
          </Typography>
        )}
        <DialogContentText id='alert-dialog-description'></DialogContentText>
        <Typography variant="body1"
            style={{ direction: CheckRtl(alertText) ? 'rtl' : 'ltr'}}
            className={CheckRtl(alertText) ? 'farsiFont' : ''}>
          {alertText}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actionsRoot} {...dialogActionProps} style={{ backgroundColor: backgroundColor || 'white'}}>
        {confirmButton && (
          <Button
            className={classes.button}
            onClick={handleConfirmClick}
            color={confirmButtonColor}
            disabled={withConfirmLoading && loading}
            {...confirmButtonProps}
          >
            {withConfirmLoading && loading ? (
              <CircularProgress size={21} color={confirmButtonColor} />
            ) : (
              confirmText
            )}
          </Button>
        )}
        {altText && (
          <Button
            className={classes.buttonPlaceholder}
            onClick={handleAlt}
            color='default'
            disabled={withConfirmLoading && loading}
          >
            {altText}
          </Button>
        )}
        {closeText && (
          <Button
            className={classes.buttonPlaceholder}
            onClick={handleClose}
            color='default'
            disabled={withConfirmLoading && loading}
          >
            {closeText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
AlertDialog.propTypes = {
  open: PropTypes.any,
  handleClose: PropTypes.func,
  titleText: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  alertText: PropTypes.string,
  confirmText: PropTypes.string,
  withConfirmLoading: PropTypes.bool,
  closeText: PropTypes.string,
  confirmButton: PropTypes.bool,
  handleConfirm: PropTypes.func,
  confirmButtonProps: PropTypes.object,
  dialogActionProps: PropTypes.object,
};
