import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SearchBar from './SearchBar/SearchBar';
import Setting_Icon from '../../Assets/ic_menu_hamburger_dark_24.png';
import { connect } from 'react-redux';
import './SearchAndSettingBar.scss';

class SearchAndSettingBar extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.snackBarMessage === 'You need to set your rotation first.') {
      this.props.history.push('/setting');
    }
  }

  componentWillReceiveProps() {
    if (
      this.props.snackBarMessage === 'You need to set your rotation first.' &&
      this.props.snackBarMessage !== this.state.prevMessage
    ) {
      this.props.history.push('/setting');
    }
    this.setState({ prevMessage: this.props.snackBarMessage });
  }

  handleSetting() {
    this.props.history.push('/setting');
  }

  render() {
    return (
      <div
        className='SearchAndSettingContainer'
        id='SearchAndSettingContainer'
        style={this.props.style ? this.props.style : {}}
      >
        <div className='settingIconContainer'>
          <img
            alt='Setting'
            className='SearchAndSettingIcon'
            height={30}
            onClick={() => this.handleSetting()}
            src={Setting_Icon}
          />
        </div>

        <SearchBar
          removePaginationNumber={this.props.removePaginationNumber}
          onChangingSearchQuery={this.props.onChangingSearchQuery}
          section={this.props.section}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    snackBarMessage: state.snackBarReducer.snackBarMessage,
  };
};
export default connect(mapStateToProps)(withRouter(SearchAndSettingBar));
