import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAllWardsPatients,
  addSearchQuery,
  removePatients,
} from '../../../action/actions';
import { TextField, IconButton } from '@material-ui/core';
import './SearchBar.scss';
import { CheckRtl } from '../../../Utilities/CheckLang';
import SearchIcon from '../../../Assets/ic_search_dark_24.png';
import CloseIcon from '@material-ui/icons/Close';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: props.searchQuery,
      isOnSearchMode: Boolean(props.searchQuery),
    };
  }
  handleChange(event) {
    const searchTerm = event.target.value;
    clearTimeout(this.timer);
    const searchTriggerDelay = 1500;
    this.setState({ searchQuery: searchTerm });
    if (searchTerm.length > 2 || searchTerm === '') {
      this.timer = setTimeout(() => {
        this.triggerChange();
      }, searchTriggerDelay);
    }
  }
  async triggerChange() {
    if (
      this.state.searchQuery.trim().length > 0 ||
      this.state.searchQuery === ''
    ) {
      await this.props.onChangingSearchQuery();
      await this.props.removePatients();
      this.props.addSearchQuery(this.state.searchQuery);
      await this.props.getAllWardsPatients(
        this.props.section,
        this.state.searchQuery
      );
    }
  }

  async closeEditingMode() {
    await this.setState({ searchQuery: '', isOnSearchMode: false });
    this.triggerChange();
  }

  render() {
    return (
      <div className='inputSearchContainer'>
        <TextField
          fullWidth
          placeholder={this.state.isOnSearchMode ? '' : 'Search Name, PACS,...'}
          value={this.state.searchQuery}
          onChange={(e) => {
            this.handleChange(e);
          }}
          className='search-bar'
          InputProps={{
            classes: { underline: 'search-bar__input__underline' },
            endAdornment: this.state.isOnSearchMode ? (
              <IconButton size='small' onClick={() => this.closeEditingMode()}>
                <CloseIcon />
              </IconButton>
            ) : (
              <img src={SearchIcon} width={32} alt='searchIcon' />
            ),
          }}
          inputProps={{
            className: `search-bar__input
            ${
              this.state.searchQuery
                ? CheckRtl(this.state.searchQuery[0])
                  ? 'farsiNum rtl'
                  : ''
                : ''
            }`,
            onFocus: () => {
              this.setState({ isOnSearchMode: true });
            },
            onBlur: () => {
              this.setState({
                isOnSearchMode: Boolean(this.state.searchQuery),
              });
            },
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { addSearchQuery, getAllWardsPatients, removePatients },
    dispatch
  );
export default connect(
  (state) => ({ searchQuery: state.patientsReducer.searchQuery || '' }),
  mapDispatchToProps
)(SearchBar);
