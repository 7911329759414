import React from 'react';
import { IOrder } from '../../../../types/order';
import { TextField } from '@material-ui/core';
import { CheckRtl } from '../../../../Utilities/CheckLang';

interface IProps {
  order: IOrder;
  onChange: (order: IOrder) => void;
}

const OrderNoteField: React.FC<IProps> = ({ order, onChange }) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newOrder: IOrder = {
      ...order,
      task: {
        ...order.task,
        note: event.target.value,
      },
    };
    onChange(newOrder);
  };

  return (
    <TextField
      fullWidth
      multiline
      placeholder='Note'
      value={order.task.note}
      onChange={handleChange}
      inputProps={{
        style: { direction: CheckRtl(order.task.note) ? 'rtl' : 'ltr' },
        className: CheckRtl(order.task.note) ? 'farsiFont' : '',
      }}
    />
  );
};

export default OrderNoteField;
