import React, { useEffect, useState } from 'react';
import { IOrder, IVisit, IDraftVisit } from '../../../types/order';
import { connect, RootStateOrAny } from 'react-redux';
import { getPatientProfile } from '../../../action/profile';
import {
  getHeadVisit,
  createVisit,
  createDraftVisit,
  setDraftVisit,
  getDraftVisit,
  getCardexAppendix,
} from '../../../action/order';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import useAccess from '../../../Hook/useAccess';
import { Box, Button, Typography } from '@material-ui/core';
import PageHeader from '../../PageHeader/PageHeader';
import PageWrapper from '../../PageWrapper';
import CardexForm from '../Cardex/Form';
import VisitDescription from './Description';
import { ReactComponent as TemplateIcon } from '../../../Assets/vuesax_outline_note_2.svg';

const descriptions = [
  'دستوراتی که به دسته بندی های زیر مربوط نمیشوند را میتوانید در دسته Other قرار دهید.',
];

interface IProps {
  relatedDraft: IDraftVisit | null;
  headVisit: IVisit | null;
  getPatientProfile: (patientId: string, useCacheData?: boolean) => any;
  getHeadVisit: (profileId: number) => any;
  createVisit: (data: any, byDraft?: number) => any;
  getDraftVisit: (draftId: number) => Promise<any>;
  createDraftVisit: (data: any) => any;
  setDraftVisit: (draftVisit: IDraftVisit | null) => any;
  getCardexAppendix: (profileId: number) => any;
}

const VisitCreate: React.FC<IProps> = ({
  relatedDraft,
  headVisit,
  getPatientProfile,
  getHeadVisit,
  createVisit,
  getDraftVisit,
  createDraftVisit,
  setDraftVisit,
  getCardexAppendix,
}) => {
  const history = useHistory<any>();
  const location = useLocation<any>();
  const { patientId } = useParams<{ patientId: string }>();
  const {
    data: physicianVisitOperatorAccess,
    isLoading: isLoadingPhysicianVisitOperator,
  } = useAccess('physician_visit_operator');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<{
    profile: any;
    head: any;
    draft: any;
    cardexAppendix: any;
  }>({
    profile: null,
    head: null,
    draft: null,
    cardexAppendix: null,
  });

  if (!isLoadingPhysicianVisitOperator && !physicianVisitOperatorAccess) {
    history.replace(`/patient/${patientId}/order`);
  }

  const fetchPageData = async (
    isFetchProfileData?: boolean,
    isFetchHeadVisit?: boolean,
    isFetchDraftVisit?: boolean,
    isFetchTaskNotices?: boolean
  ) => {
    await setLoading(true);
    await setError({
      profile: null,
      head: null,
      draft: null,
      cardexAppendix: null,
    });

    if (isFetchProfileData) {
      await getPatientProfile(patientId, true).catch(async (err: any) => {
        console.log(err);
        await setError({ ...error, profile: err });
      });
    }

    if (isFetchHeadVisit) {
      await getHeadVisit(parseInt(patientId)).catch(async (err: any) => {
        console.log(err);
        await setError({ ...error, head: err });
      });
    }

    if (isFetchDraftVisit) {
      const relatedDraftId = parseInt(location.state?.relatedDraftId);

      if (relatedDraftId) {
        await getDraftVisit(relatedDraftId).catch(async (err: any) => {
          console.log(err);
          await setError({ ...error, head: err });
        });
      } else {
        await setDraftVisit(null);
      }
    }

    if (isFetchTaskNotices) {
      await getCardexAppendix(parseInt(patientId)).catch(async (err: any) => {
        console.log(err);
        await setError({ ...error, cardexAppendix: err });
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (!isLoadingPhysicianVisitOperator && physicianVisitOperatorAccess) {
      fetchPageData(true, true, true, true);
    }
  }, [
    patientId,
    isLoadingPhysicianVisitOperator,
    physicianVisitOperatorAccess,
  ]);

  useEffect(() => {
    if (!loading) {
      history.replace(location.pathname, {
        ...(location.state || {}),
        relatedDraftId: relatedDraft?.id,
      });
    }
  }, [relatedDraft, loading]);

  const handleCreateRelatedDraft = (orders: IOrder[]) => {
    const requestData = {
      profile_id: patientId,
      parent: headVisit?.id ?? null,
      orders,
    };

    return createDraftVisit(requestData);
  };

  const handleSubmit = (orders: IOrder[]) => {
    const requestData = {
      profile_id: patientId,
      parent: headVisit?.id ?? null,
      orders: orders,
    };

    return createVisit(requestData, relatedDraft?.id);
  };

  const handleSaveAsTemplate = () => {
    history.push(`/patient/${patientId}/order/create-visit/create-template`);
  };

  const handleAfterSubmitAction = () => {
    history.goBack();
  };

  const handleUseTemplateBtnClick = () => {
    history.push(`/patient/${patientId}/order/create-visit/templates`);
  };

  return (
    <>
      <PageHeader desktopBackButton title='New Visit' />
      <PageWrapper
        loading={loading}
        error={
          Boolean(error.profile) ||
          Boolean(error.head) ||
          Boolean(error.draft) ||
          Boolean(error.cardexAppendix)
        }
        onReload={() =>
          fetchPageData(
            Boolean(error.profile),
            Boolean(error.head),
            Boolean(error.draft),
            Boolean(error.cardexAppendix)
          )
        }
      >
        <Box px={3} mt={3}>
          <VisitDescription descriptions={descriptions} />
        </Box>

        <Box px={3} my={1}>
          <Button
            fullWidth
            color='primary'
            variant='outlined'
            startIcon={<TemplateIcon />}
            onClick={handleUseTemplateBtnClick}
          >
            Use a visit template
          </Button>
        </Box>

        <Box px={3} mb={1}>
          <Typography align='left' variant='h4' className={'darkPrimaryColor'}>
            Cardex
          </Typography>
        </Box>

        <Box px={3}>
          <CardexForm
            showSaveAsDraftButton
            showSaveAsTemplateButton
            firstVisit={headVisit === null}
            visit={headVisit}
            defaultOrders={relatedDraft?.orders}
            relatedDraft={relatedDraft}
            createRelatedDraft={handleCreateRelatedDraft}
            onSubmit={handleSubmit}
            onSaveAsTemplate={handleSaveAsTemplate}
            onSuccessSubmit={handleAfterSubmitAction}
          />
        </Box>
      </PageWrapper>
    </>
  );
};

export default connect(
  (state: RootStateOrAny) => ({
    relatedDraft: state.order.draftVisit,
    headVisit: state.order.headVisit,
  }),
  {
    getHeadVisit,
    getPatientProfile,
    createVisit,
    getDraftVisit,
    createDraftVisit,
    setDraftVisit,
    getCardexAppendix,
  }
)(VisitCreate);
