const initialState = {
  userSignature: null,
  userData: null,
  namespaceId: null,
  hospitalName: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_USER_SIGNATURE': {
      return {
        ...state,
        userSignature: action.data,
      };
    }
    case 'LOAD_USER_DATA': {
      return {
        ...state,
        userData: action.data || null,
      };
    }

    case 'SET_NAMESPACE': {
      return {
        ...state,
        namespaceId:action.data || null
      };
    }
    case 'SET_HOSPITAL_NAME': {
      return {
        ...state,
        hospitalName:action.data || null
      };
    }
    default:
      return state;
  }
};
