import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { confirmConsultReply } from '../../../action/consultAction';
import { CheckRtl, renderAutoDirText } from '../../../Utilities/CheckLang';
import { jalaliFullDateTime } from '../../../Utilities/jalaliDate';
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import { getUserFullName } from '../../../Utilities/user';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 24px 20px',
    backgroundColor: '#fff',
  },
  label: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    margin: '20px 0 0',
  },
  value: {
    margin: '8px 0 4px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',
  },
}));

function ConsultAnswerDetail({
  consult,
  showConfirmReplyButton,
  confirmConsultReply,
}) {
  const classes = useStyles();
  const [pending, setPending] = useState(false);

  const handleConfirmReplyClick = async () => {
    setPending(true);
    await confirmConsultReply(consult?.id);
    setPending(false);
  };

  const TextValue = ({ text }) => (
    <Typography
      className={clsx(classes.value, CheckRtl(text) ? 'fa-text-body1' : 'mt-0')}
      style={{
        textAlign: CheckRtl(text) ? 'right' : 'left',
      }}
      dir='auto'
    >
      {renderAutoDirText(text)}
    </Typography>
  );

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography className={classes.label}>Consult Reply</Typography>
        <TextValue
          text={
            consult?.paper_reply ? (
              <div className='greyText farsiNum rtl text-right'>
                این مشاوره به صورت کاغذی پاسخ داده شده است.
              </div>
            ) : (
              consult?.reply || 'با سلام و تشکر از مشاوره \n '
            )
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>Reply Date</Typography>
        <TextValue text={jalaliFullDateTime(consult?.replied_datetime)} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>Consultant</Typography>
        <TextValue text={getUserFullName(consult?.consultant)} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>Confirmed by</Typography>
        <TextValue
          text={
            consult?.confirm_entries
              .map((confirm) => getUserFullName(confirm.confirmer))
              .join(' , ') || 'No one yet'
          }
        />
      </Grid>

      {showConfirmReplyButton && (
        <Grid item xs={12}>
          <Box mt={2}>
            <Grid container>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  className='mediumPadding'
                  onClick={handleConfirmReplyClick}
                  disabled={pending}
                >
                  {pending ? (
                    <CircularProgress size={22} />
                  ) : (
                    'Confirm This Reply'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

ConsultAnswerDetail.propTypes = {
  consult: PropTypes.object,
  showConfirmReplyButton: PropTypes.bool,
};

export default connect(null, { confirmConsultReply })(ConsultAnswerDetail);
