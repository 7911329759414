import React, { useEffect, useState, useMemo } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import { getConsultDetail } from '../../action/consultAction';
import { useParams } from 'react-router-dom';
import { getUserId } from '../../Utilities/storage';
import { Box } from '@material-ui/core';
import PageHeader from '../PageHeader/PageHeader';
import PageWrapper from '../PageWrapper';
import PatientInfo from '../PatientProfile/PatientInfo';
import ConsultRequestDetail from './Sections/ConsultRequestDetail';
import ConsultReplyForm from './Sections/ConsultReplyForm';
import ConsultAnswerDetail from './Sections/ConsultAnswerDetail';
import PrintContainer from './Print/PrintContainer';

// Consult States
// 0: Draft
// 4: Replied

interface ConsultReplyProps {
  consult: any;
  getConsultDetail: (consultId: string) => any;
}

function ConsultReply({ consult, getConsultDetail }: ConsultReplyProps) {
  const { consultId } = useParams<{ consultId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isReplied = consult?.state === 4;

  const userId = useMemo(() => getUserId(), []);

  const canConfirmReply = useMemo(
    () =>
      isReplied &&
      consult?.requester?.id !== userId &&
      consult?.consultant?.id !== userId &&
      !consult?.confirm_entries.some((val: any) => val.confirmer.id === userId),
    [consult]
  );

  useEffect(() => {
    fetchPageData();
  }, [consultId]);

  const fetchPageData = () => {
    setLoading(true);
    setError(null);
    getConsultDetail(consultId)
      .then(() => setLoading(false))
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
        setError(err);
      });
  };

  return (
    <>
      <PageHeader
        desktopBackButton={true}
        showPatientInfo={false}
        title='Consult Reply'
      />
      <PageWrapper
        greyBg
        loading={loading}
        error={error}
        onReload={fetchPageData}
      >
        <PrintContainer consult={consult} fixedContainer={true} />
        <PatientInfo profile={consult?.patient} />
        <Box mb={2} />
        <ConsultRequestDetail
          consult={consult}
          showRequesterService={
            !!window.env.REACT_APP_CONSULT_REQUESTER_SERVICE
          }
          showLastEditedDatetime={Boolean(
            consult?.last_edited_request_datetime
          )}
        />
        <Box mb={2} />
        {isReplied ? (
          <ConsultAnswerDetail
            consult={consult}
            showConfirmReplyButton={canConfirmReply}
          />
        ) : (
          <ConsultReplyForm consult={consult} />
        )}
      </PageWrapper>
    </>
  );
}

export default connect(
  (state: RootStateOrAny) => ({
    consult: state?.patientsReducer.patient?.consultDetail,
  }),
  { getConsultDetail }
)(ConsultReply);
