import React from 'react';
import { makeStyles, Theme, Drawer, DrawerProps } from '@material-ui/core';
import useCheckMobileScreen from '../../Hook/useCheckMobileScreen';

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: '70vw',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
}));

const DrawerPage: React.FC<DrawerProps> = ({ ...props }) => {
  const classes = useStyles();
  const isMobile = useCheckMobileScreen();

  return (
    <Drawer
      {...props}
      anchor='right'
      variant={isMobile ? 'temporary' : 'persistent'}
      classes={{ paper: classes.drawerPaper }}
    >
      {props.children}
    </Drawer>
  );
};

export default DrawerPage;
