import * as consultAPI from '../api/consultApi';

import { addPageNumber } from './actions';
import { getPatientProfile } from './profile';
export const PAGINATE_DEFAULT_LIMIT =
  window.env.REACT_APP_PAGINATE_DEFAULT_LIMIT;
export const PAGINATE_DEFAULT_OFFSET =
  window.env.REACT_APP_PAGINATE_DEFAULT_OFFSET;

export const getAllConsults = (
  offset,
  limit,
  effective_patient_service_id,
  consultant_service_id,
  effective_ward_id
) => async (dispatch) => {
  offset = offset ? offset : PAGINATE_DEFAULT_OFFSET;
  limit = limit ? limit : PAGINATE_DEFAULT_LIMIT;
  const params = {
    offset,
    limit,
    effective_patient_service_id,
    consultant_service_id,
    effective_ward_id,
  };
  await dispatch({ type: 'PATIENT_LOADER', data: true });
  let noMoreHttpFetch;
  try {
    let response = await consultAPI.getAllConsults(params);
    if (response.data.consults && response.data.consults.length > 0) {
      noMoreHttpFetch = false;
    } else {
      noMoreHttpFetch = true;
    }

    if (offset > 0) {
      await dispatch({
        type: 'LOAD_MORE_CONSULTS',
        data: response.data.consults,
        noMoreHttpFetch,
      });
    } else {
      await dispatch({
        type: 'GET_CONSULTS',
        data: response.data.consults,
        noMoreHttpFetch,
      });
    }
    if (offset && limit) {
      dispatch(addPageNumber(offset / limit + 2));
    } else {
      dispatch(addPageNumber(1));
    }
    await dispatch({ type: 'PATIENT_LOADER', data: false });
  } catch (error) {
    if (error.networkError === true) {
      await dispatch({
        type: 'PATIENT_LOADER',
        data: false,
        networkError: true,
      });
    } else {
      await dispatch({ type: 'PATIENT_LOADER', data: false });
    }
  }
};

export const getPatientConsults = (patientId) => async (dispatch) => {
  await dispatch(getPatientProfile(patientId, true)).catch((err) =>
    console.log(err)
  );

  return await consultAPI
    .getAllPatientConsults(patientId)
    .then(async (response) => {
      await dispatch({
        type: 'GET_PATIENT_CONSULTS',
        data: response.data.consults,
      });
      return response;
    });
};

export const getConsultCreateInitialData = (patientId) => async (dispatch) => {
  let error = '';
  try {
    await dispatch(getPatientProfile(patientId, true)).catch((err) =>
      console.log(err)
    );

    // to clear consult props of consult edit page
    await dispatch({
      type: 'GET_PATIENT_CONSULT_DETAIL',
      data: null,
    });

    const patientIllnesses = await consultAPI.getPatientIllness(patientId);
    const lastProblems = await consultAPI.getPatientLastProblem(patientId);
    await dispatch({
      id: patientId,
      type: 'GET_PATIENT_CONSULT_DETAIL',
      data: null,
      lastProblems: lastProblems.data?.last_problem_list,
      illnesses: patientIllnesses.data?.illnesses,
    });
  } catch (err) {
    error = err;
  }

  return new Promise((resolve, reject) => (error ? reject(error) : resolve()));
};

export const createConsult = (consult, profileId, draftFlag) => async (
  dispatch
) => {
  if (draftFlag) {
    return await consultAPI
      .createPatientConsultDraft(profileId, consult)
      .then((response) => {
        return dispatch({
          type: 'ADD_MESSAGE',
          message: 'consult was successfully saved as draft',
          status: 200,
        });
      });
  } else {
    return await consultAPI
      .createPatientConsult(profileId, consult)
      .then((response) => {
        return dispatch({
          type: 'ADD_MESSAGE',
          message: 'consult was successfully submitted',
          status: 200,
        });
      });
  }
};

export const getConsultDetail = (
  consultId,
  getPatientIllness = false
) => async (dispatch) => {
  let error = '';
  let response = {};
  try {
    response = await consultAPI.getConsultDetail(consultId);
    let patientIllnessesResponse;
    if (
      (getPatientIllness || response.data?.state === 0) &&
      response.data?.patient?.profile_id
    ) {
      patientIllnessesResponse = await consultAPI.getPatientIllness(
        response.data.patient.profile_id
      );
      if (patientIllnessesResponse.status !== 200) {
        throw patientIllnessesResponse;
      }
    }

    await dispatch({
      type: 'GET_PATIENT_CONSULT_DETAIL',
      data: response.data,
      profile: response.data?.patient,
      lastProblems: response.data?.problem_list,
      illnesses: patientIllnessesResponse
        ? patientIllnessesResponse.data?.illnesses
        : null,
    });
  } catch (err) {
    error = err;
    console.log(err);
  }
  return new Promise((resolve, reject) =>
    error ? reject(error) : resolve(response)
  );
};

export const updateConsultRequest = (
  consultId,
  consult,
  draftFlag,
  saveAsDraftFlag
) => async (dispatch) => {
  let response;
  let successMessage;
  if (!draftFlag && !saveAsDraftFlag) {
    response = await consultAPI.updateConsultRequest(consultId, consult);
    successMessage = 'consult was successfully updated';
  } else if (draftFlag && !saveAsDraftFlag) {
    response = await consultAPI.updateConsultDraftAsRequest(consultId, consult);
    successMessage = 'consult was successfully submitted';
  } else if (draftFlag && saveAsDraftFlag) {
    response = await consultAPI.updateConsultDraft(consultId, consult);
    successMessage = 'draft was successfully updated';
  }
  return await dispatch({
    type: 'ADD_MESSAGE',
    message: successMessage,
    status: 200,
  });
};

export const deleteConsultRequest = (consultId) => async (dispatch) => {
  await consultAPI.deleteConsult(consultId);
  return await dispatch({
    type: 'ADD_MESSAGE',
    message: 'consult was successfully deleted.',
    status: 200,
  });
};
// not clean // should be separated cause doing different task the naming do not define the difference
export const answerConsult = (consultId, reply) => async (dispatch) => {
  const response = await consultAPI.replyConsult(consultId, reply);
  await dispatch({
    type: 'ADD_MESSAGE',
    message: 'Consult reply was successfully submitted',
    status: 200,
  });
  await dispatch({
    type: 'GET_PATIENT_CONSULT_DETAIL',
    data: response.data,
  });
  return response;
};
export const createDraftReply = (consultId, reply) => async (dispatch) => {
  let response = await consultAPI.createDraftReplyConsult(consultId, reply);
  await dispatch({
    type: 'ADD_MESSAGE',
    message: 'Consult reply was successfully saved as Draft',
    status: 200,
  });
  await dispatch({
    type: 'GET_PATIENT_CONSULT_DETAIL',
    data: response.data,
  });
  return response;
};
export const confirmConsultReply = (consultId) => async (dispatch) => {
  let response = await consultAPI.confirmRepliedConsultRequest(consultId);
  await dispatch({
    type: 'ADD_MESSAGE',
    message: 'Consult reply was successfully confirmed',
    status: 200,
  });
  await dispatch({
    type: 'GET_PATIENT_CONSULT_DETAIL',
    data: response.data,
  });
  return response;
};
export const getConsultNotification = () => async (dispatch) => {
  return await consultAPI
    .getNotificationConfiguration()
    .then(async (response) => {
      return response.data;
    });
};
export const updateConsultNotification = (notificationConfiguration) => async (
  dispatch
) => {
  return await consultAPI
    .updateNotificationConfiguration(notificationConfiguration)
    .then(async (response) => {
      return await dispatch({
        type: 'ADD_MESSAGE',
        message: 'Notification was successfully updated.',
        status: 200,
      });
    });
};
