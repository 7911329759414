import { setPatientDataBase } from './actions';
import { getPatientDB } from '../DB/db';
import * as api from '../api/api';

export const getPatientProfile = (patientId, useCacheData = false) => async (
  dispatch
) => {
  try {
    const DBResponse = await dispatch(loadProfileDataFromDB(patientId));
    if (DBResponse && useCacheData) return Promise.resolve();
  } catch (err) {
    console.log(err);
  }

  return api
    .getPatientProfile(patientId)
    .then(async (response) => {
      await dispatch(setPatientDataBase(patientId, response, 'profile'));
      await dispatch({
        type: 'GET_PROFILE',
        data: response.data,
        last_update: response.data.last_update,
      });
    })
    .catch(async (error) => {
      return Promise.reject(error);
    });
};

export const loadProfileDataFromDB = (patientId) => async (dispatch) => {
  if (!patientId) return;
  let patientInfoDB;
  let error = '';

  await dispatch({ type: 'GET_PROFILE', fetchedDataFromDB: false });
  try {
    await getPatientDB(patientId).then((response) => {
      patientInfoDB = response;
    });

    if (patientInfoDB && patientInfoDB.profile) {
      await dispatch({
        type: 'GET_PROFILE',
        data: patientInfoDB.profile,
        fetchedDataFromDB: true,
      });
    } else {
      error = patientInfoDB
        ? 'no profile data exist for this patient in db!'
        : 'no data exist for this patient in db!';
    }
  } catch (err) {
    error = err;
    console.log(err);
  }

  return new Promise((resolve, reject) =>
    error ? reject(error) : resolve(patientInfoDB.profile)
  );
};
