import React from 'react';
import { Route, withRouter } from 'react-router-dom';

function RouteWithSubRoutes({ render, route, match }) {
  return route.component ? (
    <Route
      path={route.absolutePath ? route.path : match.path + route.path}
      exact={route.exact}
      render={
        typeof render === 'function'
          ? render((matchProps) => (
              <route.component {...matchProps} routes={route.routes} />
            ))
          : (matchProps) => (
              <route.component {...matchProps} routes={route.routes} />
            )
      }
    />
  ) : null;
}

export default withRouter(RouteWithSubRoutes);
