import React, { Component, Fragment } from 'react';
import './LabResultDetail.scss';
import Divider from '@material-ui/core/Divider';
import { jalaliFullDateTime } from '../../Utilities/jalaliDate';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ContentLoader } from '../shared';
import CHEVRON_Icon from '../../Assets/ic_chevron_down_dark_24.png';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Swipe from 'react-easy-swipe';
import ResponsiveDrawer from '../ResponsiveDrawer/ResponsiveDrawer';

class LabResultDetail extends Component {
  constructor() {
    super();
    this.state = {
      horizontalMove: null,
      verticalMove: null,
    };
  }
  componentDidMount() {
    if (!this.props.location.state) {
      const patientId = this.props.match?.params?.patientId;
      this.props.history.replace(`/patient/${patientId}/laboratory`);
    } else {
      const issueDate = this.props.location.state.issueDateTime;
      const resultGroupIndex = this.props.location.state.index;
      this.handleChangeResultGroup(resultGroupIndex, issueDate);
    }
  }
  handleChangeResultGroup(resultGroupIndex, issueDate) {
    if (
      resultGroupIndex === null ||
      resultGroupIndex === undefined ||
      !this.props.labResult ||
      !this.props.labResult.length
    ) {
      return;
    }
    const totalNumResultGroup = this.props.labResult[resultGroupIndex]?.results
      ?.length;
    var labResultIndex;
    if (issueDate) {
      labResultIndex = this.props.labResult[
        resultGroupIndex
      ].results?.findIndex((result) => result.issued_datetime === issueDate);
      if (labResultIndex === -1) {
        labResultIndex = 0;
      }
    } else {
      labResultIndex = 0;
    }

    var filteredResultGroup = this.props.labResult[resultGroupIndex].results[
      labResultIndex
    ];
    var factors = this.getFactorsOfResultGroup(
      resultGroupIndex,
      filteredResultGroup
    );

    this.setState({
      totalNumResultGroup,
      factors,
      labResultIndex,
      resultGroupIndex,
      result: filteredResultGroup,
      title: this.props.labResult[resultGroupIndex].title,
      reporter_section: this.props.labResult[resultGroupIndex].reporter_section,
    });
  }
  getFactorsOfResultGroup(resultGroupIndex, filteredResultGroup) {
    var factors = [];
    if (
      filteredResultGroup &&
      filteredResultGroup?.values[0]?.length !==
        this.props.labResult[resultGroupIndex]?.factors?.length
    ) {
      for (let i = 0; i < filteredResultGroup.values.length; i++) {
        var partialEdited = this.props.labResult[
          resultGroupIndex
        ].factors.filter(
          (factor) => filteredResultGroup.values[i].factor_id === factor.id
        );
        factors = [...factors, ...partialEdited];
      }
    } else {
      factors = this.props.labResult[resultGroupIndex]?.factors;
    }
    return factors;
  }
  getNextResultOfGroup() {
    const {
      labResultIndex,
      totalNumResultGroup,
      resultGroupIndex,
    } = this.state;
    const nextIndex = labResultIndex + 1;
    if (nextIndex <= totalNumResultGroup) {
      var filteredResultGroup = this.props.labResult[resultGroupIndex].results[
        nextIndex
      ];
      if (filteredResultGroup) {
        const { patientId, groupId } = this.props?.match?.params;
        this.props.history.replace(
          `/patient/${patientId}/laboratory/group/${groupId}/result/${nextIndex}`
        );
        this.setState({
          labResultIndex: nextIndex,
          result: filteredResultGroup,
        });
      }
    }
  }
  getPrevResultOfGroup() {
    const { labResultIndex, resultGroupIndex } = this.state;
    const prevIndex = labResultIndex - 1;
    if (prevIndex >= 0) {
      var filteredResultGroup = this.props.labResult[resultGroupIndex].results[
        prevIndex
      ];
      if (filteredResultGroup) {
        const { patientId, groupId } = this.props?.match?.params;
        this.props.history.replace(
          `/patient/${patientId}/laboratory/group/${groupId}/result/${prevIndex}`
        );
        this.setState({
          labResultIndex: prevIndex,
          result: filteredResultGroup,
        });
      }
    }
  }

  getFactorOfResultDetail(factorId) {
    return this.state.factors?.find((factor) => factor.id === factorId);
  }
  handleSwipeMove(position, event) {
    this.setState({
      horizontalMove: position.x,
      verticalMove: position.y,
    });
  }
  handleSwipeEnd() {
    const { horizontalMove, verticalMove } = this.state;
    if (Math.abs(horizontalMove) < Math.abs(verticalMove)) {
      return;
    } else {
      if (horizontalMove < 0) {
        this.getNextResultOfGroup();
      } else {
        this.getPrevResultOfGroup();
      }
    }
  }
  render() {
    const {
      result,
      totalNumResultGroup,
      labResultIndex,
      resultGroupIndex,
    } = this.state;

    return (
      <ResponsiveDrawer
        handleClose={() => {}}
        title={this.props.fullName}
        settingClass='true'
        notScrollable={true}
      >
        <div className='labResultDetailContainer'>
          {result ? (
            <div>
              <div className='result-header-sticky'>
                <div className='LabDetailRow LabDetailTitle customize-select'>
                  {/* <Tooltip title='Switch to another Lab Result Group'> */}
                  <Select
                    value={resultGroupIndex}
                    onChange={(event) =>
                      this.handleChangeResultGroup(event.target.value, null)
                    }
                    displayEmpty
                  >
                    {this.props.labResult.map((result, resultIndex) => {
                      return (
                        <MenuItem key={resultIndex} value={resultIndex}>
                          {result.title}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* </Tooltip> */}
                </div>
                <div className='labResult-time'>
                  {totalNumResultGroup !== 1 && (
                    <Fragment>
                      <div
                        className={`${
                          labResultIndex === 0 ? 'disabled' : 'hoverable'
                        } prev-icon icon `}
                        onClick={() => this.getPrevResultOfGroup()}
                      >
                        <Tooltip
                          title={`${
                            labResultIndex === 0
                              ? ''
                              : 'Next Result Of this Group'
                          }`}
                        >
                          <img
                            alt='icon'
                            height={20}
                            width={20}
                            src={CHEVRON_Icon}
                          />
                        </Tooltip>
                      </div>

                      <div
                        className={`${
                          labResultIndex === totalNumResultGroup - 1
                            ? 'disabled'
                            : 'hoverable'
                        } next-icon icon`}
                        onClick={() => this.getNextResultOfGroup()}
                      >
                        <Tooltip
                          title={`${
                            labResultIndex === totalNumResultGroup - 1
                              ? ''
                              : 'Previous Result Of this Group'
                          }`}
                        >
                          <img
                            alt='icon'
                            height={20}
                            width={20}
                            src={CHEVRON_Icon}
                          />
                        </Tooltip>
                      </div>
                    </Fragment>
                  )}

                  <div className='minWidth text-center'>
                    <div className='inlineb'>
                      <div className='greyText'>Issued Date</div>
                      <div className='dateTime'>
                        {jalaliFullDateTime(result.issued_datetime)}
                      </div>
                    </div>
                  </div>
                  <div className='minWidth text-center'>
                    <div className='greyText'>Report Date</div>
                    <div className='dateTime'>
                      {result.report_datetime ? (
                        <div> {jalaliFullDateTime(result.report_datetime)}</div>
                      ) : (
                        <div>-</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Swipe
                onSwipeMove={(position, event) =>
                  this.handleSwipeMove(position, event)
                }
                onSwipeEnd={() => this.handleSwipeEnd()}
              >
                <div className='result-detail-container'>
                  {result.values.map((resultValue, index) => (
                    <div
                      className='result-detail-item'
                      key={resultValue.factor_id}
                    >
                      <div className='result-row'>
                        <div className='right-section'>
                          <div className='factor-name '>
                            {
                              this.getFactorOfResultDetail(
                                resultValue.factor_id
                              )?.name
                            }
                          </div>
                          <div
                            className={`result-value ${
                              resultValue.abnormal ? 'abnormalColor' : ''
                            }`}
                          >
                            {resultValue.value}
                          </div>
                        </div>

                        <div className='result-unit left-section'>
                          {resultValue.unit ||
                            this.getFactorOfResultDetail(resultValue.factor_id)
                              ?.default_unit}
                        </div>
                      </div>
                      <div className='greyText result-normal-range'>
                        {resultValue.normal_range ||
                          this.getFactorOfResultDetail(resultValue.factor_id)
                            ?.default_normal_range}
                      </div>
                      {resultValue.description && (
                        <div className='LabDetailRow'>
                          {resultValue.description}
                        </div>
                      )}
                      <div className='LabResDetailDivider'>
                        {result.values.length - 1 !== index && <Divider />}
                      </div>
                    </div>
                  ))}
                </div>
              </Swipe>
            </div>
          ) : (
            <ContentLoader />
          )}
        </div>
      </ResponsiveDrawer>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    fullName:
      state.patientsReducer.patient?.profile?.first_name +
      ' ' +
      state.patientsReducer.patient?.profile?.last_name,
    labResult: state.patientsReducer.patient.labResult,
  };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LabResultDetail);
