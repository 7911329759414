import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
const styles = makeStyles({
  container: {
    '& > div:first-child': {
      borderTopLeftRadius: '6px',
      borderBottomLeftRadius: '6px',
      borderLeft: '1px solid #ccd2e0',
    },
    '& > div:last-child': {
      borderTopRightRadius: '6px',
      borderBottomRightRadius: '6px',
    },
    '& > div': {
      border: '1px solid #ccd2e0',
      padding: '0.25rem',
      margin: '0 -0.5px',
      textAlign: 'center',
      cursor: 'pointer',
      height: 38,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.1rem',
      marginBottom: '25px',
      borderLeft: 'none',
    },
  },
  elective: {},
  activeElective: {
    backgroundColor: '#2bd27c',
    color: 'white',
    borderColor: '#2bd27c !important',
  },
  emergent: {},
  activeEmergent: {
    backgroundColor: '#f09917',
    color: 'white',
    borderColor: '#f09917 !important',
  },
  urgent: {},
  activeUrgent: {
    backgroundColor: '#ea2323',
    color: 'white',
    borderColor: '#ea2323 !important',
  },
});
const priorities = [
  {
    id: 3,
    text: 'Elective',
    activeClassName: 'activeElective',
    className: 'elective',
  },
  {
    id: 2,
    text: 'Emergent',
    activeClassName: 'activeEmergent',
    className: 'emergent',
  },
  {
    id: 1,
    text: 'Urgent',
    activeClassName: 'activeUrgent',
    className: 'urgent',
  },
];
export default function PrioritySelector({
  selectedPriority,
  setConsultPriority,
}) {
  const classes = styles();
  return (
    <div>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className={classes.container}
      >
        {priorities.map((priority) => (
          <Grid
            item
            xs={4}
            md={3}
            key={priority.id}
            className={
              selectedPriority === priority.id
                ? classes[priority.activeClassName]
                : ''
            }
            onClick={() => setConsultPriority(priority.id)}
          >
            <Typography>{priority.text}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
