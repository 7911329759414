import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Button, makeStyles } from '@material-ui/core';
import RadiologyCard from './RadiologyCard/RadiologyCard';
import { NoContent } from '../shared';
import { useParams, withRouter } from 'react-router-dom';
import RouteWithSubRoutes from '../RouteWithSubRoutes';
import { getPatientRadiologyResult } from '../../action/radiology';
import PageWrapper from '../PageWrapper';
import PageHeader from '../PageHeader/PageHeader';

const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    [theme.breakpoints.up('sm')]: {
      padding: '30px 3.8rem 0 3.8rem',
    },
  },
}));

function RadiologyResult({
  radiologyResult,
  radiologyResultFetchedFromDB,
  routes,
  getPatientRadiologyResult,
  pacsUrl,
  ...rest
}) {
  const classes = useStyles();
  const { patientId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPageData();
  }, [patientId]);

  const fetchPageData = async () => {
    await setLoading(true);
    await setError(null);
    getPatientRadiologyResult(patientId)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
        console.log(err);
      });
  };

  var pacsButton = pacsUrl ? (
    <Box className="RadioCardWholeContainer">
      <Button style={{boxSizing: 'border-box', width: '100%'}}
        color='primary'
        variant='contained'
        // radiology-card class name is used to be visually similar to radiology cards in the list
        className="radiology-card"
        onClick={() => window.open(pacsUrl, '_blank', 'noreferrer')}
      >
        View PACS
      </Button>
    </Box>
  ) : <></>;

  return (
    <Fragment>
      <PageHeader
        pageDataFetched={!loading && !error}
        pageDataNotReloaded={!loading && error?.networkError}
        dataFetchedFromDB={radiologyResultFetchedFromDB}
        subTitle='Radiology Result'
        cacheEnable={true}
      />
      <PageWrapper
        loading={loading && !radiologyResultFetchedFromDB && !error}
        onReload={fetchPageData}
        dataFetchedFromDB={radiologyResultFetchedFromDB}
        error={error}
        className={classes.pageWrapper}
      >
        {radiologyResult?.length ? (
          <div className='inner-container-with-padding'>
            {pacsButton}
            {radiologyResult.map((el, index) => (
              <RadiologyCard key={index} radio={el} index={index} {...rest} />
            ))}
          </div>
        ) : (
          <>
            <div className='inner-container-with-padding'>
              {pacsButton}
            </div>
            <NoContent
              width={'65%'}
              text={'No radiology result for the patient.'}
            />
          </>
        )}

        {Boolean(routes?.length) &&
          routes.map((route) => (
            <RouteWithSubRoutes key={route.key} route={route} />
          ))}
      </PageWrapper>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  radiologyResult: state.patientsReducer.patient?.radiologyResult,
  pacsUrl: state.patientsReducer.patient?.pacsUrl,
  radiologyResultFetchedFromDB:
    state.patientsReducer.patient?.radiologyResultFetchedFromDB,
});
export default connect(mapStateToProps, { getPatientRadiologyResult })(
  withRouter(RadiologyResult)
);
