import React, { useState } from 'react';
import { IOrder, isNursingTask } from '../../../../types/order';
import { useQuery } from 'react-query';
import { getTerms } from '../../../../api/medicalTerms';
import { Grid } from '@material-ui/core';
import ResponsiveAutocomplete from '../../../ResponsiveAutocomplete';
import { IGetMedicalTermsQuery } from '../../../../types/medicalTerms';
import OrderTimingField from '../Fields/Timing';
import OrderNoteField from '../Fields/Note';

interface IProps {
  defaultOrder: IOrder;
  onOrderChange: (order: IOrder) => void;
}

const NursingFormContent: React.FC<IProps> = ({
  defaultOrder,
  onOrderChange,
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isFetching } = useQuery(
    [
      'medical_terms',
      {
        type: 'care',
        q: searchQuery,
      },
    ],
    () => {
      const query: IGetMedicalTermsQuery = {
        q: searchQuery || undefined,
      };
      return getTerms('care', query);
    }
  );

  const handleInputChange = () => async (value: string) => {
    setSearchQuery(value);
  };

  const handleCaresFieldChange = () => async (_: any, value: any) => {
    let newTask = { ...defaultOrder.task };
    if (isNursingTask(newTask)) {
      newTask = {
        ...newTask,
        cares: value
          ? value.map((val: { id: number; title_en: string }) => ({
              id: val.id,
              title_en: val.title_en,
            }))
          : [],
      };

      await onOrderChange({ ...defaultOrder, task: newTask });
    }
  };

  const handleTimingFieldChange = (value: string, errors: any[]) => {
    if (isNursingTask(defaultOrder.task)) {
      let newTask = { ...defaultOrder.task };
      newTask = {
        ...newTask,
        timing: {
          raw: value,
          schedules: [],
          errors: errors,
        },
      };
      onOrderChange({ ...defaultOrder, task: newTask });
    }
  };

  if (!isNursingTask(defaultOrder.task)) return null;

  const fields = [
    {
      isVisible: true,
      content: (
        <Grid item xs={12}>
          <ResponsiveAutocomplete
            isAsync
            multiple
            disableCloseOnSelect
            loading={isFetching}
            options={data?.data.results ?? []}
            filterOptions={(options) => options}
            getOptionLabel={(o) => o.title_en || ''}
            getOptionSelected={(o, v) => o?.id === v?.id}
            value={defaultOrder.task.cares}
            onChange={handleCaresFieldChange()}
            onInputChange={handleInputChange()}
            textFieldProps={{
              autoFocus: true,
              placeholder: 'Nursing Care',
            }}
          />
        </Grid>
      ),
    },
    {
      isVisible: !!defaultOrder.task.cares.length,
      content: (
        <Grid item xs={8} md={10}>
          <OrderTimingField
            value={defaultOrder.task.timing?.raw}
            onChange={handleTimingFieldChange}
          />
        </Grid>
      ),
    },
    {
      isVisible: !!defaultOrder.task.cares.length,
      content: (
        <Grid item xs={12}>
          <OrderNoteField order={defaultOrder} onChange={onOrderChange} />
        </Grid>
      ),
    },
  ];

  return (
    <Grid container spacing={1}>
      {fields.map((field) => (field.isVisible ? field.content : null))}
    </Grid>
  );
};

export default NursingFormContent;
