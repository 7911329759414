export const IMAGE_ROLES = [
  {
    title: '',
    text: ' به ازای هر بیمار حداکثر تعداد مشخصی (۱۵تصویر) قابل ثبت است',
  },
  {
    title: '',
    text:
      'کاربرانی که اکنون به یک بیمار دسترسی دارند و دسترسی "ثبت مدیا برای بیمار" را دارند می توانند به آن بیمار تصویر اضافه کنند',
  },
  {
    title: 'پین کردن و آنپین تصاویر بیمار:',
    text:
      'هر کاربر که اکنون به بیمار دسترسی دارد می' +
      'تواند هرتصویر آن بیمار را "پین" کند تا به ' +
      'صورت خودکار حذف نشود. حداکثر تعداد تصاویر' +
      'قابل پین شدن تعداد مشخصی (۵ تصویر) است',
  },
  {
    title: '',
    text:
      ' هر کاربر که اکنون به بیمار دسترسی دارد و دسترسی "آنپین مدیای بیمار" را دارد می تواند هر تصویر پین شده ی آن بیمار را "آنپین" کند',
  },
  {
    title: 'حذف تصاویر بیمار:',
    text:
      'کاربرانی که اکنون به یک بیمار دسترسی دارند و' +
      'دسترسی "حذف مدیای بیمار" را دارند (پروفسور)' +
      ' می توانند هر کدام از تصاویر آن بیمار را حذف' +
      ' کنند.',
  },
  {
    title: '',
    text:
      ' همچنین کاربری که اکنون به یک بیمار دسترسی دارد می تواند تصویری که قبلا برای آن بیمار ثبت کرده است و پین نشده است را حذف کند',
  },
];
