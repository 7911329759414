import { useHistory, useLocation } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function usePathAction(
  hasOtherState = false,
  defaultGoBack = true
) {
  const history = useHistory();
  const location = useLocation();

  const setPathAction = (action) => {
    if (hasOtherState ? location.state !== undefined : location.state?.action) {
      if (action) {
        history.replace(location.pathname, { ...location.state, action });
      } else {
        history.replace(location.pathname, { ...location.state, action: '' });
        if (defaultGoBack) history.goBack();
      }
    } else {
      if (action) history.push(location.pathname, { action });
    }
  };

  return [location.state?.action, setPathAction];
}
