import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonProps,
  ClickAwayListener,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CALENDAR_ICON from '../../../../Assets/icons/vuesax_linear_calendar.svg';
import PROFILE_ICON from '../../../../Assets/icons/profile_circle.svg';
import { getTime, jalaliFullDate } from '../../../../Utilities/jalaliDate';
import { renderAutoDirText } from '../../../../Utilities/CheckLang';
import useCheckMobileScreen from '../../../../Hook/useCheckMobileScreen';

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#f0f1f7',
    border: '1px solid #ccccd2e0',
    borderRadius: 4,
  },
  cardRow: {
    color: '#191e25',
    display: 'flex',
    columnGap: 8,
    alignItems: 'center',
    padding: '12px 16px',

    '&:first-child': {
      borderBottom: '1px solid #ccccd2e0',
    },
  },
  disabledButton: {
    color: '#fff',
    background: '#B9D2FF',
    boxShadow: 'none',
    cursor: 'default',

    '&:hover': {
      background: '#B9D2FF',
    },
  },
});

type IActionButton = ButtonProps & {
  label?: string;
  toolTipTitle?: string;
};

const ActionButton: React.FC<IActionButton> = ({
  label,
  toolTipTitle = '',
  onClick,
  ...props
}) => {
  const isMobileScreen = useCheckMobileScreen();
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => setOpen(true);

  const handleTooltipClose = () => setOpen(false);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        open={isMobileScreen ? open : undefined}
        onClose={handleTooltipClose}
        title={toolTipTitle}
        placement='top'
      >
        <Button
          onClick={(e) => {
            e.stopPropagation();
            handleTooltipOpen();
            if (onClick) onClick(e);
          }}
          {...props}
        >
          <Typography variant='subtitle2'>{label}</Typography>
        </Button>
      </Tooltip>
    </ClickAwayListener>
  );
};

interface IProps {
  datetime: string;
  doctor: string;
  disabled?: boolean;
  onClick?: () => void;
}

const UncheckedVisitsCarouselCard: React.FC<IProps> = ({
  datetime,
  doctor,
  disabled,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.cardRow}>
        <Box display='flex'>
          <img
            src={CALENDAR_ICON}
            height={20}
            alt='calendar'
            className='mr-2'
          />
          <Typography variant='h6' className='mr-2'>
            {jalaliFullDate(datetime)}
          </Typography>
          <Typography variant='h6'>{getTime(datetime)}</Typography>
        </Box>
        <Box ml={1} display='flex'>
          <Box mr={1}>
            <img src={PROFILE_ICON} height={20} alt='profile' />
          </Box>
          <Typography component='span'>{renderAutoDirText(doctor)}</Typography>
        </Box>
      </Box>
      <Box className={classes.cardRow}>
        <ActionButton
          fullWidth
          label='Check'
          color='primary'
          variant='contained'
          disabled={disabled}
          onClick={onClick}
        />
      </Box>
    </Box>
  );
};

export default UncheckedVisitsCarouselCard;
