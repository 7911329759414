import { createTheme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { Shadows } from '@material-ui/core/styles/shadows';
import createPalette from '@material-ui/core/styles/createPalette';
import createTypography from '@material-ui/core/styles/createTypography';

export const palette = createPalette({
  primary: {
    dark: '#000e7a',
    main: '#386fff',
    light: '#a8d2ff',
  },
  secondary: {
    main: '#8b8f99',
  },
  success: {
    main: '#2bd27c',
  },
  warning: {
    main: '#f09917',
    dark: '#cfb53b',
  },
  error: {
    main: '#ea2323',
  },
  grey: {
    100: '#fafbff',
    500: '#ccd2e0',
    600: '#8b8f99',
    700: '#70727a',
    900: '#191e25',
  },
  text: {
    primary: '#191e25',
    secondary: '#8b8f99',
  },
  background: {
    default: '#ffffff',
  },
});

export const typography = createTypography(palette, {
  fontFamily: 'Poppins',
  h4: {
    fontSize: 20,
    lineHeight: '30px',
    fontWeight: 500,
  },
  h5: {
    fontSize: 16,
    lineHeight: '25px',
    fontWeight: 600,
  },
  h6: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 600,
  },
  body1: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 400,
  },
  body2: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 400,
  },
  subtitle1: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 300,
  },
  subtitle2: {
    fontSize: 12,
    lineHeight: '18px',
    fontWeight: 500,
  },
  button: {
    fontSize: 14,
    lineHeight: '21px',
    fontWeight: 500,
    textTransform: 'none',
  },
  caption: {
    fontSize: 10,
    lineHeight: '16px',
    fontWeight: 600,
  },
});

export const shadows: Shadows = [
  'none',
  '0px 2px 8px 0px #8b8f9929',
  '0px 2px 8px 0px #5d5f6629',
  '0px 2px 8px 0px #000e7a29',
  '0px 4px 16px 0px #8b8f9929',
  '0px 4px 16px 0px #5d5f6629',
  '0px 4px 16px 0px #000e7a29',
  '0px 8px 24px 0px #8b8f9929',
  '0px 8px 24px 0px #5d5f6629',
  '0px 8px 24px 0px #000e7a29',
  '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
  '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
  '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
  '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
  '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
  '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
  '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
  '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
  '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
  '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
  '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
  '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
];

export const overrides: Overrides = {
  MuiTypography: {
    root: {
      '&.darkPrimaryColor': {
        color: '#000e7a',
      },

      '&.greyColor, &.hint': {
        color: '#8b8f99',
      },

      '&.grey700Color': {
        color: palette.grey[700],
      },

      '&.regularWeight': {
        fontWeight: '400 !important',
      },

      '&.mediumWeight': {
        fontWeight: '500 !important',
      },

      '&.semiBoldWeight': {
        fontWeight: '600 !important',
      },

      '&.leftSideLine': {
        position: 'relative',
        overflow: 'hidden',

        '&:before': {
          content: '"\\a0"',
          position: 'absolute',
          top: '50%',
          width: '50%',
          height: 1,
          marginLeft: '-50%',
          backgroundColor: 'currentColor',
          transform: 'translateY(-50%)',
        },
      },
      '&.rightSideLine': {
        position: 'relative',
        overflow: 'hidden',

        '&:after': {
          content: '"\\a0"',
          position: 'absolute',
          top: '50%',
          width: '50%',
          height: 1,
          backgroundColor: 'currentColor',
          transform: 'translateY(-50%)',
        },
      },
    },
  },

  MuiButton: {
    root: {
      height: 'fit-content',
    },
    contained: {
      boxShadow: 'none',

      '&:hover, &:active': {
        boxShadow: 'none',
      },
      '&.mediumPadding': {
        padding: '10px 15px',
      },
    },
    containedPrimary: {
      background:
        'linear-gradient(90deg, rgba(0,14,122,1) 0%, rgba(56,111,255,1) 100%)',

      '&:disabled': {
        color: '#fff',
        background: '#B9D2FF',
      },
    },
    outlined: {
      padding: '6px 7px',

      '&.mediumPadding': {
        padding: '8px 13px',
      },
    },
    outlinedPrimary: {
      border: '2px solid #a8d2ff',
      color: palette.primary.dark,

      '&:hover': {
        border: '2px solid #386fff',
      },
      '&:disabled': {
        color: '#babfcc',
        border: '2px solid #a8c8ff',
      },
    },
    outlinedSecondary: {
      color: '#ea2323',
      border: '2px solid #ea2323',

      '&:hover': {
        border: '2px solid #ea2323',
        backgroundColor: '#ea23230a',
      },
    },
    textSecondary: {
      color: '#ea2323',
    },
  },
  MuiFab: {
    primary: {
      '&.gradientBg': {
        background:
          'linear-gradient(90deg, rgba(0,14,122,1) 0%, rgba(56,111,255,1) 100%)',
      },
    },
  },
  MuiSnackbarContent: {
    root: {
      fontFamily: 'inherit',
    },
  },
  MuiInput: {
    underline: {
      '&:before': {
        borderBottom: '1px solid #ccd2e0',
      },
    },
  },
  MuiInputBase: {
    root: {
      fontFamily: 'inherit',
      fontSize: 16,
      color: 'inherit',
    },
  },
  MuiInputLabel: {
    root: {
      '&$focused': {
        color: '#386fff',
      },
    },
  },
  MuiOutlinedInput: {
    root: {
      ...typography['body1'],
      position: 'relative',
      padding: '9px 12px',

      '& $notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
        borderColor: '#4A90E2',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
      },
      '&$focused $notchedOutline': {
        borderColor: '#4A90E2',
        borderWidth: 1,
      },
    },
    input: {
      padding: 0,
      height: 22,
      lineHeight: '22px',
    },
  },
  MuiFormGroup: {
    root: {
      '&.button-group': {
        flexDirection: 'row',

        '& label': {
          padding: '6px 0',
          margin: 0,
          flex: 1,
          justifyContent: 'center',
          border: '2px solid rgba(0, 0, 0, 0.23)',
          borderLeft: 'none',

          '&:first-child': {
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
            borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
          },

          '&:last-child': {
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          },

          '&.active': {
            backgroundColor: palette.primary.main,
            borderColor: palette.primary.main,
          },

          '& *': {
            ...typography.button,
          },

          '&.active *': {
            color: '#fff',
          },
        },
      },
    },
  },
  MuiFormLabel: {
    root: {
      fontFamily: 'inherit',
      '&$focused': {
        color: '#4A90E2',
      },
    },
  },
  MuiPaper: {
    root: {
      color: '#3d3d3d',
    },
  },
  MuiDialogActions: {
    root: {
      justifyContent: 'space-between',
      flexFlow: 'row-reverse',
    },
  },
  MuiDialogContent: {
    root: {
      padding: '0',
      // overflowY: 'visible',
    },
  },
  MuiDialog: {
    paper: {
      // overflowY: 'visible',
    },
  },
  MuiAppBar: {
    root: {
      boxShadow: 'none',
    },
    colorPrimary: {
      backgroundColor: 'white',
      color: '#363636',
    },
  },
  MuiMenu: {
    paper: {
      border: '2px solid #ccd2e066',
      boxShadow: '0 4px 12px 0 #70727a29',
    },
    list: {
      paddingTop: 6,
      paddingBottom: 6,
    },
  },
  MuiMenuItem: {
    root: {
      color: '#191e25',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '21px',
      minHeight: 0,
      minWidth: 96,
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 13,
      lineHeight: '20px',
      fontWeight: 400,
      backgroundColor: palette.grey[700],
      boxShadow: '0 2px 4px 0 #1a191e25',
    },
    tooltipPlacementTop: {
      margin: '2px 0',
    },
    arrow: {
      color: palette.grey[700],
    },
  },
};

// A custom theme for this app
const theme = createTheme({
  palette,
  shadows,
  typography,
  overrides,
});

export default theme;
