import { IPublicUser } from './user';

export interface ITiming {
  raw: string;
  schedules?: string[];
  errors?: any[];
}

//////////////
//// Task ////
//////////////

export type ITaskNoticeLabel = 'finished' | 'unfinished';

export interface ITaskNotice {
  label: ITaskNoticeLabel;
  notified: string;
  notifier: IPublicUser;
}

export interface ITaskNoticesMapping {
  [uuid: string]: {
    all: ITaskNotice[];
    eff: Record<ITaskNoticeLabel, ITaskNotice>;
  };
}

export interface ITaskExtension {
  timetable: string;
  annotation: string;
}

export type ITaskExtensionsMapping = Record<string, ITaskExtension>;

export type ITaskCategory =
  | 'impression'
  | 'condition'
  | 'diet'
  | 'activity'
  | 'lab'
  | 'nursing'
  | 'medication'
  | 'radiography'
  | 'consult'
  | 'other';

export type GeneralTask = 'impression' | 'condition' | 'diet' | 'activity';

interface ITaskSubmitter {
  id: number;
  first_name: string;
  last_name: string;
  role: string;
}

interface IBasicTask {
  uuid?: string;
  created?: string | null;
  note: string;
  old_note?: string;
  submitter?: ITaskSubmitter | null;
  extensions?: ITaskExtension;
}

interface IImpressionTask extends IBasicTask {
  category: 'impression';
  impressions: { id: number | string; title_en?: string }[];
  // old_properties
  old_impressions?: { id: number | string; title_en?: string }[];
}

interface IConditionTask extends IBasicTask {
  category: 'condition';
  condition: { id?: number | string; title_en?: string };
  // old_properties
  old_condition?: { id?: number | string; title_en?: string };
}

interface IDietTask extends IBasicTask {
  category: 'diet';
  diets: { id: number | string; title_en?: string }[];
  // old_properties
  old_diets?: { id: number | string; title_en?: string }[];
}

interface IActivityTask extends IBasicTask {
  category: 'activity';
  activity: { id?: number | string; title_en?: string };
  positions: { id: number | string; title_en?: string }[];
  // old_properties
  old_activity?: { id?: number | string; title_en?: string };
  old_positions?: { id: number | string; title_en?: string }[];
}

interface ILabTask extends IBasicTask {
  category: 'lab';
  tests: { id?: number | string; title_en?: string }[];
  timing?: ITiming;
  // old_properties
  old_tests?: { id?: number | string; title_en?: string }[];
  old_timing?: ITiming;
}

export interface INursingTask extends IBasicTask {
  category: 'nursing';
  cares: { id: number | string; title_en?: string }[];
  timing?: ITiming;
  // old_properties
  old_cares?: { id: number | string; title_en?: string }[];
  old_timing?: ITiming;
}

interface IRadiographyTask extends IBasicTask {
  category: 'radiography';
  image_type: { id?: number | string; title_en?: string };
  location: { id?: number | string; title_en?: string };
  laterality: { id?: number | string; title_en?: string };
  timing?: ITiming;
  contrasted_views: {
    view: { id?: number | string; title_en?: string };
    contrast: { id?: number | string; title_en?: string };
  }[];
  // old_properties
  old_image_type?: { id?: number | string; title_en?: string };
  old_location?: { id?: number | string; title_en?: string };
  old_laterality?: { id?: number | string; title_en?: string };
  old_timing?: ITiming;
  old_contrasted_views?: {
    view: { id?: number | string; title_en?: string };
    contrast: { id?: number | string; title_en?: string };
  }[];
}

export interface IMedicationTask extends IBasicTask {
  category: 'medication';
  drug: { id?: number | string; title_en?: string };
  form: { id?: number | string; title_en?: string };
  strength: { id?: number | string; title_en?: string };
  amount: string;
  route: { id?: number | string; title_en?: string };
  timing?: ITiming;
  added: {
    drug: { id?: number | string; title_en?: string };
    form: { id?: number | string; title_en?: string };
    strength: { id?: number | string; title_en?: string };
    amount: string;
  }[];
  // old_properties
  old_drug?: { id?: number | string; title_en?: string };
  old_form?: { id?: number | string; title_en?: string };
  old_strength?: { id?: number | string; title_en?: string };
  old_amount?: string;
  old_route?: { id?: number | string; title_en?: string };
  old_timing?: ITiming;
  old_added?: {
    drug: { id?: number | string; title_en?: string };
    form: { id?: number | string; title_en?: string };
    strength: { id?: number | string; title_en?: string };
    amount: string;
  }[];
}

export interface IConsultTask extends IBasicTask {
  category: 'consult';
  hospital_service: { id?: number | string; title_en?: string };
  timing?: ITiming;
  // old_properties
  old_hospital_service?: { id?: number | string; title_en?: string };
  old_timing?: ITiming;
}

export interface IOtherTask extends IBasicTask {
  category: 'other';
  description: string;
  timing?: ITiming;
  // old_properties
  old_description?: string;
  old_timing?: ITiming;
}

export type ITask =
  | IImpressionTask
  | IConditionTask
  | IDietTask
  | IActivityTask
  | INursingTask
  | ILabTask
  | IRadiographyTask
  | IMedicationTask
  | IConsultTask
  | IOtherTask;

export interface IBaseTaskTemplate {
  title: string;
  description: string;
  tasks: ITask[];
  public: boolean;
}
export interface ITaskTemplate extends IBaseTaskTemplate {
  id: number;
  author: IPublicUser;
}

export const isActivityTask = (task: ITask): task is IActivityTask =>
  task.category === 'activity';
export const isNursingTask = (task: ITask): task is INursingTask =>
  task.category === 'nursing';
export const isRadiographyTask = (task: ITask): task is IRadiographyTask =>
  task.category === 'radiography';
export const isMedicationTask = (task: ITask): task is IMedicationTask =>
  task.category === 'medication';
export const isConsultTask = (task: ITask): task is IConsultTask =>
  task.category === 'consult';
export const isOtherTask = (task: ITask): task is IOtherTask =>
  task.category === 'other';

///////////////
//// Order ////
///////////////

export type IOrderAction = 'add' | 'change' | 'dc' | 'hold' | 'unhold' | null;

export interface IOrder {
  task: ITask;
  action: IOrderAction;
}

////////////////
//// Cardex ////
////////////////

export type ICategory =
  | 'general'
  | 'lab'
  | 'nursing'
  | 'medication'
  | 'radiography'
  | 'consult'
  | 'other';

export type ICardexGroup = 'unchecked' | 'checked' | 'new';

export type ICardexItemState =
  | 'active'
  | 'dc'
  | 'hold'
  | 'unhold'
  | 'changed'
  | 'extinct';

export interface ICardexItem {
  isNew?: boolean; // isNew CardexItem used to show new dot (compare head visit of draft with head visit)
  old_task?: string | null;
  task: ITask;
  state: ICardexItemState;
  label?: ITaskNoticeLabel;
  restated?: string;
}

export type ICardex = Partial<
  Record<ICategory, Record<ICardexGroup, ICardexItem[]>>
>;

export interface ICardexAppendix {
  task_notices: ITaskNoticesMapping;
  task_extensions: ITaskExtensionsMapping;
}

export interface IVisitSummery {
  id: number;
  modified: string;
  checker: IPublicUser;
  signers: IPublicUser[];
}

export interface IVisit {
  id: number;
  profile_id: number;
  parent: number;
  initial: boolean;
  created: string;
  modified: string;
  checked: string | null;
  checker: IPublicUser | null;
  signers: IPublicUser[];
  orders: IOrder[];
  cardex: {
    items: ICardexItem[];
  };
  staged_drafts: [];
}

export type IDraftVisitPurpose = 'add' | 'edit';

export interface IDraftVisitSummery {
  id: number;
  modified: string;
  last_modifier: IPublicUser;
  purpose: IDraftVisitPurpose;
}

export interface IDraftVisit {
  id: number;
  created: string;
  modified: string;
  profile_id: number;
  initial: boolean;
  parent: null | number;
  last_modifier: IPublicUser;
  orders: IOrder[];
  purpose: IDraftVisitPurpose;
}

export interface IConflictItem {
  order: IOrder;
  state:
    | 1 // Add an existing task id
    | 2 // Non existing task
    | 3 // Hold a held task
    | 4 // Unhold a non-held task
    | 5; // Hold or Dc a changed task
}

/////////////////
//// History ////
/////////////////

export interface IHistoryItem {
  task: ITask;
  type: 'finished' | 'extinct';
  state: ICardexItemState;
  notified?: string | null;
  notifier?: Pick<IPublicUser, 'first_name' | 'last_name'> | null;
  changed?: string | null;
  changer?: Pick<IPublicUser, 'first_name' | 'last_name'> | null;
}
