import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { jalaliFullDateTime } from '../../../Utilities/jalaliDate';
import { getUserFullName } from '../../../Utilities/user';
import Main from './Main';
import Status from './Status';

const useStyles = makeStyles({
  container: (consultantView) => ({
    flex: 1,
    padding: `${consultantView ? '0px' : '0.5em'} 1em`,
  }),
});

export default function ConsultCard({ consult, consultantView = false }) {
  const classes = useStyles(consultantView);
  return (
    <div className={classes.container}>
      <Main
        fromServiceName={consult?.effective_patient_service.name}
        toServiceName={consult?.consultant_service?.name}
        requesterName={getUserFullName(consult?.requester)}
        consultantName={getUserFullName(consult?.suggested_consultant)}
      />
      <Status
        sentDate={jalaliFullDateTime(consult?.effective_request_datetime)}
        state={consult.state}
        consultantView={consultantView}
      />
    </div>
  );
}
