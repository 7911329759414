import React from 'react';
import PropsTypes from 'prop-types';
import { makeStyles, Grid, Typography, Box, Chip } from '@material-ui/core';
import { CheckRtl, renderAutoDirText } from '../../../Utilities/CheckLang';
import { jalaliFullDateTime } from '../../../Utilities/jalaliDate';
import clsx from 'clsx';
import { getUserFullName } from '../../../Utilities/user';
import RenderHTML from '../../shared/RenderHTML';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 24px 20px',
    backgroundColor: '#fff',
  },
  label: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    margin: '20px 0 0',
  },
  value: {
    margin: '8px 0 4px',
    textAlign: 'left',
    whiteSpace: 'pre-wrap',

    '&.priority-critical, &.priority-death': {
      color: theme.palette.error.main,
    },
    '&.priority-emergent': {
      color: theme.palette.warning.main,
    },
    '&.priority-elective': {
      color: theme.palette.success.main,
    },
  },
  diagnosisWrapper: {
    textAlign: 'left',
    padding: '10px 0 4px',
  },
  diagnosisItem: {
    height: 'auto',
    padding: '5px 0',
    borderRadius: 9,
    backgroundColor: '#edf8fd',
    ...theme.typography.body1,

    '& > *': {
      whiteSpace: 'break-spaces',
    },
  },
}));

export const ConsultRequestDetail = ({
  consult,
  showRequesterService,
  showLastEditedDatetime,
}) => {
  const classes = useStyles();
  let priorityClassName;
  let priorityText;

  if (consult?.priority === 3) {
    priorityClassName = 'priority-elective';
    priorityText = 'Elective';
  } else if (consult?.priority === 2) {
    priorityClassName = 'priority-emergent';
    priorityText = 'Emergent';
  } else if (consult?.priority === 1) {
    priorityClassName = 'priority-critical';
    priorityText = 'Urgent';
  } else if (consult?.priority === 0) {
    priorityClassName = 'priority-death';
    priorityText = 'Death expectant';
  }

  const TextValue = ({ text }) => (
    <Typography
      className={clsx(classes.value, CheckRtl(text) ? 'fa-text-body1' : 'mt-0')}
      style={{
        textAlign: CheckRtl(text) ? 'right' : 'left',
      }}
      dir='auto'
    >
      {renderAutoDirText(text)}
    </Typography>
  );

  return (
    <Grid container className={classes.root}>
      {showRequesterService && (
        <Grid item xs={12}>
          <Typography className={classes.label}>Requester Service</Typography>
          <TextValue text={consult?.effective_patient_service?.name} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography className={classes.label}>Consulting Service</Typography>
        <TextValue text={consult?.consultant_service?.name} />
      </Grid>
      {consult?.suggested_consultant && (
        <Grid item xs={12}>
          <Typography className={classes.label}>Consulting Doctor</Typography>
          <TextValue text={getUserFullName(consult?.suggested_consultant)} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography className={classes.label}>Request Date</Typography>
        <TextValue
          text={jalaliFullDateTime(consult?.effective_request_datetime)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>Priority</Typography>
        <Typography
          className={clsx(
            classes.value,
            'mt-0 mediumWeight',
            priorityClassName
          )}
          dir='auto'
          variant='h5'
        >
          {priorityText}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>Present Illness</Typography>
        <TextValue text={consult?.illness} />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>
          Differential Diagnosis
        </Typography>
        <Box className={classes.diagnosisWrapper}>
          {consult?.problem_list?.map((problem, index) => (
            <Box
              key={index}
              mb={index !== consult?.problem_list.length - 1 ? 1 : 0}
            >
              <Chip
                className={classes.diagnosisItem}
                label={<RenderHTML html={problem['title_en']} />}
              />
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.label}>Cause of Consult</Typography>
        <TextValue text={consult?.cause_of_consult} />
      </Grid>
      {showLastEditedDatetime && (
        <Grid item xs={12}>
          <Typography className={classes.label}>Last Edited</Typography>
          <TextValue
            text={jalaliFullDateTime(consult?.last_edited_request_datetime)}
          />
        </Grid>
      )}
    </Grid>
  );
};

ConsultRequestDetail.defaultProps = {
  consult: {},
  showRequesterService: false,
  showLastEditedDatetime: false,
};

ConsultRequestDetail.propsTypes = {
  consult: PropsTypes.object,
  showRequesterService: PropsTypes.bool,
  showLastEditedDatetime: PropsTypes.bool,
};

export default ConsultRequestDetail;
