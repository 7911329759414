// Listen baby this is the most reusable component that you've ever seen in your life
//to show a radio group list with filter input
// @inputs
// @input items: a list of objects or strings
// UID: as unique identifier of objects of list if none we have string array
// propsObjectForDisplay: as the properties that we should use to show the value on radio labels
// hasInputFilter: as the flag that we have input filter or not
// inputLable: to show on input for filtering
//  still filtering use inputLabel to figure out what to filter but we should change it
// it is obvious that we should use propsObjectForDisplay @input fot filtering too
import React, { Component } from 'react';
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Drawer,
  withStyles,
  IconButton,
} from '@material-ui/core';
import { ContentLoader, BlueButton, HeaderInput } from '../shared';
import CHEVRON_Icon from '../../Assets/ic_chevron_down_dark_24.png';
import PropTypes from 'prop-types';
import { CheckRtl } from '../../Utilities/CheckLang';
import toRenderProps from 'recompose/toRenderProps';
import withWidth from '@material-ui/core/withWidth';
import { isIOS } from '../../Utilities/navigator';

const WithWidth = toRenderProps(withWidth());

const styles = {
  root: {
    display: 'inline-block !important',
    padding: '7px 0px',
    marginLeft: '30px',
  },
  fullList: {
    width: 'auto',
  },
  paperSM: {
    border: 'none',
    boxShadow: 'none',
    width: '100vw',
    // paddingLeft: '2vw',
    // paddingTop: '10px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    direction: 'rtl',
    paddingBottom: '53px',
    overflowY: 'visible',
  },
  paperLG: {
    border: 'none',
    boxShadow: 'none',
    width: '70vw',
    marginLeft: '30vw',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    direction: 'rtl',
    paddingBottom: '53px',
    overflowY: 'visible',
  },
  showLines: {
    whiteSpace: 'pre-line',
  },
  radioGroups: {
    padding: '1em 0',
  },
  FormControlLabel: {
    // borderBottom: 'solid #EBEBEB 0.5px',
    marginLeft: '0px',
    marginRight: '0px',
    marginBottom: 7,
    '&:last-child': {
      borderBottom: 'none',
    },
    '& input[type=radio]:checked + div': {
      color: '#386fff !important',
    },
  },
  Scrollable: {
    overflowY: 'auto',
  },
  FixedContainer: {
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '0',
  },
  header: {
    display: 'flex',
    width: '100%',
    // background: 'red',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    padding: '26px 22px 16px 5px',
    borderBottom: '1px solid #ebebeb',
  },
};
class DrawerListWithInputFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: null,
      selectedItem: null,
      searchValue: '',
      filteredItems: this.props.items,
      filtered: false,  // TODO: can omit filtered state? (hint: it is complicated!)
    };
    this.height = window.visualViewport.height;
    this.viewport = window.visualViewport;
  }

  resizeHandler = () => {
    const element = document.getElementById('fixedDrawerButtonContainer');

    let bottom = this.height - this.viewport.height - window.scrollY;
    if (bottom < 0) {
      bottom = 0;
    }

    element.style.bottom = `${bottom}px`;
  };

  componentDidMount() {
    const ios = isIOS();
    if (ios) {
      window.visualViewport.addEventListener('scroll', this.resizeHandler);
      window.visualViewport.addEventListener('resize', this.resizeHandler);
    }
  }

  componentWillUnmount() {
    window.visualViewport.removeEventListener('scroll', this.resizeHandler);
    window.visualViewport.removeEventListener('resize', this.resizeHandler);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedItem && nextProps.UID) {
      this.setState({
        selectedIndex: nextProps.selectedItem[nextProps.UID],
        selectedItem: nextProps.selectedItem,
      });
      return;
    }
    this.setState({
      selectedIndex: null,
      selectedItem: nextProps.selectedItem,
    });
  }

  handleListItemClick = (event) => {
    if (this.props.UID) {
      this.setState({
        selectedIndex: event.target.value,
        selectedItem: this.props.items.find((x) => x.id == event.target.value),
      });
    } else {
      this.setState({
        selectedItem: event.target.value,
      });
    }
  };
  handleChange = (event) => {
    const { inputLabel } = this.props;
    const value = event.target.value;
    let currentFilteredItems;
    if (inputLabel === 'service' || inputLabel === 'requester service') {
      currentFilteredItems = this.props.items.filter(
        (item) =>
          item.name.toLowerCase().indexOf(value.trim().toLowerCase()) >= 0
      );
    } else if (inputLabel === 'receiver') {
      currentFilteredItems = this.props.items.filter(
        (item) =>
          item.first_name.toLowerCase().indexOf(value.trim().toLowerCase()) >=
            0 ||
          item.last_name.toLowerCase().indexOf(value.trim().toLowerCase()) >=
            0 ||
          (
            item.first_name.toLowerCase() +
            ' ' +
            item.last_name.toLowerCase()
          ).indexOf(value.trim().toLowerCase()) >= 0 ||
          (
            item.first_name.toLowerCase() + item.last_name.toLowerCase()
          ).indexOf(value.trim().toLowerCase()) >= 0 ||
          (
            item.last_name.toLowerCase() + item.first_name.toLowerCase()
          ).indexOf(value.trim().toLowerCase()) >= 0 ||
          (
            item.last_name.toLowerCase() +
            ' ' +
            item.first_name.toLowerCase()
          ).indexOf(value.trim().toLowerCase()) >= 0
      );
    }

    this.setState({
      searchValue: value,
      filteredItems: currentFilteredItems,
      filtered: true,
    });
  };
  handleClose = () => {
    this.props.handleClose();
    this.resetStates();
  };
  handleSubmit() {
    this.props.handleSubmit(this.state.selectedItem, this.props.name);
  }
  resetStates() {
    this.setState({
      searchValue: ' ',
      filtered: false,
    });
  }

  render() {
    let {
      open,
      items,
      inputLabel,
      classes,
      hasInputFilter,
      propsObjectForDisplay,
      UID,
      maxDisplayedItemsSize,
    } = this.props;
    let { filteredItems, filtered, selectedItem, selectedIndex } = this.state;
    let submitBtn;
    let inputFilter;
    let targetList = [];
    let radios;
    targetList = filtered ? filteredItems : items;
    if (items) {
      if (items.length > 0) {
        submitBtn = (
          <BlueButton
            className={`BlueButton ${selectedItem ? '' : 'notAllowed'}`}
            loader={!items}
            disabled={!selectedItem}
            text={'Confirm'}
            handleClick={() => {
              this.handleSubmit();
              this.handleClose();
            }}
          />
        );
        if (hasInputFilter) {
          inputFilter = (
            <HeaderInput
              label={
                `Search for a  ${inputLabel}` + (
                  Number.isInteger(maxDisplayedItemsSize) && maxDisplayedItemsSize > 0 ?
                  ' to find it (the list below is not complete)' :
                  ''
                )
              }
              onChange={this.handleChange}
            />
          );
        }
      }
    }

    if (targetList) {
      let displayedItems = 
        Number.isInteger(maxDisplayedItemsSize) && maxDisplayedItemsSize > 0 ?
        targetList.slice(0, maxDisplayedItemsSize) : targetList;
      if (displayedItems.length > 0) {
        radios = (
          <RadioGroup
            className={classes.radioGroups}
            aria-label='radio'
            name='radio'
            value={this.state.selectedItem}
            onChange={(e) => this.handleListItemClick(e)}
          >
            {displayedItems.map((item, key) => {
              let itemsForDisplay = propsObjectForDisplay
                ? propsObjectForDisplay
                    .map((propName) => {
                        let propNameParts = propName.split('.');
                        try {
                          return propNameParts.length == 1 ? item[propNameParts[0]] : item[propNameParts[0]][propNameParts[1]];
                        } catch (e) {
                          return '';
                        }
                      }
                    ).join(' ')
                : item;
              return (
                <FormControlLabel
                  className={
                    CheckRtl(itemsForDisplay[0])
                      ? `farsiNum ${classes.showLines} ${classes.FormControlLabel}`
                      : `${classes.showLines} ${classes.FormControlLabel}`
                  }
                  key={key}
                  value={UID ? item[UID] : item}
                  control={
                    <Radio
                      checked={
                        UID ? selectedIndex == item[UID] : selectedItem === item
                      }
                      label={itemsForDisplay}
                      className={classes.radioButton}
                      color='primary'
                    />
                  }
                  label={itemsForDisplay}
                />
              );
            })}
          </RadioGroup>
        );
      }
    }

    let emptyAlert = (
      <div className='farsiNum align-center normalFont p-2'>موردی یافت نشد</div>
    );
    return (
      <WithWidth>
        {({ width }) => (
          <Drawer
            classes={
              width === 'xs' || width === 'sm'
                ? { paperAnchorBottom: classes.paperSM }
                : { paperAnchorBottom: classes.paperLG }
            }
            anchor='bottom'
            open={open}
            onClose={this.handleClose}
          >
            <div className={classes.header}>
              <IconButton
                className='drawerTopCloseIcon'
                onClick={this.handleClose}
              >
                <img
                  // className='hoverable'
                  alt='icon'
                  height={20}
                  width={20}
                  src={CHEVRON_Icon}
                />
              </IconButton>

              {inputFilter}
            </div>
            <div className={classes.Scrollable}>
              {!items ? (
                <ContentLoader />
              ) : (
                <div>{radios ? radios : emptyAlert}</div>
              )}
            </div>
            <div
              className={classes.FixedContainer}
              id='fixedDrawerButtonContainer'
            >
              {submitBtn}
            </div>
          </Drawer>
        )}
      </WithWidth>
    );
  }
}
DrawerListWithInputFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DrawerListWithInputFilter);
