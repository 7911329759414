import { Button, makeStyles } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import ConsultToPrint from './ConsultToPrint';
import PRINT_ICON from '../../../Assets/printing.png';
import PrintOptions from './PrintOptions';
import { useDispatch, useSelector } from 'react-redux';
import { getConsultDetail } from '../../../action/consultAction';
import {
  getSignatureByUserId,
  getUserCurrentData,
} from '../../../action/userAction';
import usePathAction from '../../../Hook/usePathAction';
import clsx from 'clsx';

const styles = makeStyles((theme) => ({
  fixedPrintButtonContainer: ({ fixedContainer }) => ({
    zIndex: '100',
    display: 'flex',
    flexFlow: 'row-reverse',
    position: fixedContainer ? 'absolute' : 'relative',
    top: 5,
    right: 15,
  }),
  printIcon: ({ fixedContainer }) => ({
    borderRadius: '50%',
    minWidth: '45px',
    height: '45px',
    padding: '0',
    [theme.breakpoints.up('md')]: {
      position: fixedContainer ? 'absolute' : 'relative',
      top: 0,
      right: 0,
      zIndex: 10,
    },
  }),
}));
const PrintContainer = ({
  consult,
  consults,
  fixedContainer,
  match,
  history,
  location,
  className,
  ...rest
}) => {
  const classes = styles({ fixedContainer });
  let printRef = useRef(null);
  const { pathname } = useLocation();
  const userData = useSelector((state) => state.userReducer.userData);
  const [printableConsults, setPrintableConsults] = useState(
    consults ?? (consult ? [consult] : [])
  );
  const [signatures, setSignatures] = useState({});
  const [requesterSignature, setRequesterSignature] = useState({});
  const [isFetching, setIsFetching] = useState('');
  const [isPrintWithSignatures, setIsPrintWithSignatures] = useState(false);
  const [printReplyHeight, setPrintReplyHeight] = useState([]);
  const [haveSignaturesAccess, setSignaturesAccess] = useState(false);
  const [pathAction, setPathAction] = usePathAction();
  const [filteredConsult, setFilteredConsult] = useState([]);
  const isMultiConsults = consults ? true : false;
  const listOfConsults =
    isMultiConsults || (!isMultiConsults && haveSignaturesAccess)
      ? filteredConsult
      : printableConsults;
  const canRenderContent = useMemo(() => {
    if (!printableConsults || !printableConsults.length) return false;
    const hasSubmittedConsult = printableConsults.some(
      (consult) => consult.state !== 0
    );
    return hasSubmittedConsult;
  }, [printableConsults]);

  const triggerPrint = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => handleBeforePrintActions(false),
    onAfterPrint: () =>
      setPrintableConsults(consults ?? (consult ? [consult] : [])),
  });
  const triggerPrintWithSignatures = useReactToPrint({
    content: () => printRef.current,
    onBeforeGetContent: () => handleBeforePrintActions(true),
    onAfterPrint: () =>
      setPrintableConsults(consults ?? (consult ? [consult] : [])),
  });

  const handlePrint = (hasAccess) => {
    if (!isFetching) {
      if (hasAccess) {
        if (!filteredConsult?.length) {
          setPathAction('');
        } else {
          triggerPrintWithSignatures();
        }
      } else {
        return triggerPrint();
      }
    }
  };

  useEffect(() => {
    setPrintableConsults(consults ?? (consult ? [consult] : []));
  }, [consults, consult]);

  useEffect(() => {
    getUserPermission();

    const getConsultAnswerProperHeightForPrint = () => {
      const container = document.getElementById('consult-to-print-container');
      if (!container || container.style.display === 'none') {
        return;
      }
      const tbodyElem = container.querySelector('tbody');
      const replyEls = Array.from(
        container.getElementsByClassName('reply-section-to-print')
      );
      if (!replyEls || !replyEls.length) return;

      const footer = container.querySelector('#print-footer');
      const footerHeight = footer?.clientHeight || 0;
      const replyCollectionHeight = replyEls.map(
        (replyEl) => replyEl.clientHeight || 0
      );
      const tbodyHeight = tbodyElem.clientHeight || 0;
      const heights = replyCollectionHeight.map(
        (replyElemHeight) =>
          1020 - (tbodyHeight - replyElemHeight + footerHeight)
      );
      for (let i = 0; i < replyEls.length; i++) {
        replyEls[i].style.minHeight = heights[i] + 'px';
      }
      container.style.display = 'none';
      setPrintReplyHeight(heights);
    };

    getConsultAnswerProperHeightForPrint();
  }, []);

  const getUserPermission = async () => {
    if (canRenderContent) {
      if (userData?.rank?.can_get_all_signatures) {
        setSignaturesAccess(true);
      } else {
        let response = await dispatch(getUserCurrentData());
        if (response?.data && response.data?.rank?.can_get_all_signatures) {
          setSignaturesAccess(true);
        }
      }
    }
  };

  const fixPrintResultHeight = () => {
    const collection = Array.from(
      document.getElementsByClassName('reply-section-to-print')
    );

    // minus 105 pixel this is height of a signature image
    for (let i = 0; i < collection.length; i++) {
      collection[i].style.minHeight = printReplyHeight[i] - 135 + 'px';
    }
  };

  const dispatch = useDispatch();

  const handleBeforePrintActions = useCallback(
    async function (isWithSignature) {
      setIsPrintWithSignatures(isWithSignature);
      await setIsFetching(
        isWithSignature ? 'withSignature' : 'withoutSignature'
      );
      const tempPrintableConsults = await getAllConsultProperties();
      if (isWithSignature) {
        fixPrintResultHeight();
        await getRequesterSignature(tempPrintableConsults);
        await getConfirmerSignatures(tempPrintableConsults);
      }
      setIsFetching('');
      setPathAction('');
    },
    [printReplyHeight, listOfConsults]
  );

  const getAllConsultProperties = async () => {
    let newPrintableConsults = [];
    for (const consult of listOfConsults) {
      if ('cause_of_consult' in consult) {
        newPrintableConsults = [...newPrintableConsults, consult];
      } else {
        try {
          let response = await dispatch(getConsultDetail(consult.id));
          if (response && response.data) {
            newPrintableConsults = [...newPrintableConsults, response.data];
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
    await setPrintableConsults([...newPrintableConsults]);
    return newPrintableConsults;
  };

  const getConfirmerSignatures = async (consults) => {
    let newSignatures = {};
    for (const consult of consults) {
      let signaturesTemp = [];
      if (consult.confirm_entries?.length) {
        for (let i = 0; i < consult.confirm_entries?.length; i++) {
          try {
            let response = await dispatch(
              getSignatureByUserId(consult?.confirm_entries[i]?.confirmer?.id)
            );
            if (response && response.data) {
              const imageUrl = await URL.createObjectURL(response.data);
              signaturesTemp = await [...signaturesTemp, imageUrl];
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
      newSignatures = { ...newSignatures, [consult.id]: signaturesTemp };
    }
    await setSignatures(newSignatures);
  };

  const getRequesterSignature = async (consults) => {
    let newRequesterSignature = {};
    for (const consult of consults) {
      try {
        let response = await dispatch(
          getSignatureByUserId(consult.requester?.id)
        );
        if (response && response.data) {
          const imageUrl = await URL.createObjectURL(response.data);
          newRequesterSignature = await {
            ...newRequesterSignature,
            [consult.id]: imageUrl,
          };
        }
      } catch (err) {
        console.log(err);
        newRequesterSignature = await {
          ...newRequesterSignature,
          [consult.id]: undefined,
        };
      }
    }

    await setRequesterSignature(newRequesterSignature);
  };

  const handleClose = () => {
    setPathAction('');
  };

  if (!canRenderContent) return null;

  return (
    <div
      className={clsx(classes.fixedPrintButtonContainer, className)}
      {...rest}
    >
      {isMultiConsults || (!isMultiConsults && haveSignaturesAccess) ? (
        <Button
          className={classes.printIcon}
          variant='contained'
          color='primary'
          onClick={() => setPathAction('openPrintOptionsDialog')}
        >
          <img src={PRINT_ICON} alt='print' height={25} />
        </Button>
      ) : (
        <Button
          className={classes.printIcon}
          variant='contained'
          color='primary'
          onClick={() => handlePrint(false)}
        >
          <img src={PRINT_ICON} alt='print' height={25} />
        </Button>
      )}
      <PrintOptions
        handleClose={handleClose}
        pending={isFetching}
        open={pathAction === 'openPrintOptionsDialog'}
        handlePrint={(withSignature) =>
          handlePrint(haveSignaturesAccess ? withSignature : false)
        }
        printableConsults={printableConsults}
        isMultiConsults={isMultiConsults}
        setFilteredResult={setFilteredConsult}
        haveSignaturesAccess={haveSignaturesAccess}
      />
      <div
        id='consult-to-print-container'
        style={{
          position: 'fixed',
          top: '0',
          left: '-1000px',
          visibility: 'hidden',
          zIndex: '-10',
        }}
      >
        <ConsultToPrint
          consults={printableConsults}
          signatures={signatures}
          requesterSignature={requesterSignature}
          printWithSignatures={isPrintWithSignatures}
          ref={printRef}
        />
      </div>
    </div>
  );
};
export default withRouter(PrintContainer);
