import * as API from '../api/ICD11Api';

export const searchICD11 = (q) => async (dispatch) => {
  return await API.searchICD11(
    'mms',
    '2022-02',
    q + '%',
    true,
    false,
    false,
    false
  ).then(async (response) => {
    return await dispatch({
      type: 'GET_ICD11',
      data: response.data.destinationEntities,
    });
  });
};
