import React, { Fragment } from 'react';
import ReactTable from 'react-table';
import { withStyles } from '@material-ui/core';
import 'react-table/react-table.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import NotifDrawer from '../NotifDrawer/Notifdrawer';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Abnormal_Icon from '../../Assets/ic_notif_abnormal_dark_36.png';
import Remove_Icon from '../../Assets/ic_remove_notif_dark_36.png';
import Set_Icon from '../../Assets/ic_set_notif_dark_36.png';
import { triggerNotif } from '../../action/actions';

import { jalaliFullDateTime } from '../../Utilities/jalaliDate';
import { bindActionCreators } from 'redux';
import './LabResult.css';
import { withRouter } from 'react-router-dom';
import { NoContent } from '../shared';
import RouteWithSubRoutes from '../RouteWithSubRoutes';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import { getPatientLabResult } from '../../action/laboratory';
import PageWrapper from '../PageWrapper';
import PageHeader from '../PageHeader/PageHeader';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const styles = (theme) => ({
  pageWrapper: {
    [theme.breakpoints.up('sm')]: {
      padding: '30px 3.8rem 0 3.8rem',
    },
  },
});
class LabResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      tableData: [],
      offlinePageData: false,
      loader: false,
      headerStyle: {
        borderBottom: '1px solid #e7e7e7',
        background: '#f5f5f5',
        borderRight: 'none',
        textAlign: 'left',
        paddingLeft: '20px',
      },
      selected: {
        factorId: null,
        factorSubscription: null,
        groupIndex: null,
      },
      openNotifDialog: false,
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.labResult !== prevProps.labResult) {
      this.changeLabResultToRender();
    }
    if (
      this.props.match?.params?.patientId !== prevProps.match?.params?.patientId
    ) {
      this.fetchPageData();
    }
  }
  static getDerivedStateFromProps(nextProps) {
    let newState = {};
    if (nextProps.labResultFetchedFromDB) {
      newState = {
        ...newState,
        offlinePageData: true,
      };
    } else {
      newState = {
        ...newState,
        offlinePageData: false,
      };
    }
    if (Object.entries(newState)?.length) {
      return newState;
    } else {
      return null;
    }
  }
  componentDidMount() {
    this.fetchPageData();
  }
  fetchPageData() {
    this.setState({
      ...this.state,
      loading: true,
      error: null,
    });
    this.props
      .getPatientLabResult(this.props.match?.params?.patientId)
      .then(() => {
        this.setState({ ...this.state, loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          ...this.state,
          loading: false,
          error: err,
        });
      });
  }
  changeLabResultToRender() {
    if (!this.props.labResult) {
      this.setState({
        tableData: [],
      });
      return;
    }
    var tableData = [];
    // loop through group lab result to render table for each group
    this.props.labResult.forEach((laboratoryResultGroup, groupIndex) => {
      let mainLabResultTableHeader = {
        Header: laboratoryResultGroup.title,
        accessor: 'title',
        fixed: 'left',
        width: 130,
        maxWidth: 200,
        headerStyle: this.state.headerStyle,
      };
      let resultGroupTableData = this.createFixedColumnTableOfLabTestsGroup(
        laboratoryResultGroup,
        groupIndex
      );
      let header = resultGroupTableData.tableColumnHeaders;
      let data3 = resultGroupTableData.tableData;
      header = [...header, mainLabResultTableHeader];

      tableData = [
        ...tableData,
        {
          data: data3,
          header: header,
          wholeTableData: laboratoryResultGroup,
        },
      ];
    });
    this.setState({
      tableData: tableData,
    });
  }

  // loop through important factors and if none just loop once
  createFixedColumnTableOfLabTestsGroup(laboratoryResultGroup, groupIndex) {
    var tableColumnHeaders = [];
    var tableData = [];
    var modifiedResultObject;
    const factors = [...(laboratoryResultGroup.factors ?? []), ''];

    factors.forEach((factor, factorIndex) => {
      modifiedResultObject = this.createFactorTableRows(
        laboratoryResultGroup.results,
        factor,
        factorIndex,
        groupIndex,
        laboratoryResultGroup.id,
        factors.length
      );
      tableData = [...tableData, modifiedResultObject.tableData];
      if (
        modifiedResultObject.tableColumnHeaders &&
        modifiedResultObject.tableColumnHeaders.length
      ) {
        tableColumnHeaders = modifiedResultObject.tableColumnHeaders;
      }
    });

    return { tableData, tableColumnHeaders };
  }
  // loop through lab results of this group to create rows and column with populationg data and header variable
  createFactorTableRows(
    results,
    factor,
    factorIndex,
    groupIndex,
    groupId,
    factorsLength
  ) {
    var resultRow = {
      id: factor.id,
      title: this.createLabResultRowTitle(factor.name),
    };
    var tableColumnHeaders = [];
    results.forEach((labResultItem, labResultIndex) => {
      const value = labResultItem.values.find(
        (v) => v.factor_id === factor?.id
      );

      var shown_value = value?.value ?? '';
      resultRow = {
        ...resultRow,
        [labResultItem.issued_datetime]: this.createLabResultCell(
          labResultIndex,
          labResultItem.issued_datetime,
          groupIndex,
          groupId,
          shown_value,
          factorIndex === factorsLength - 1,
          !!value?.abnormal
        ),
      };

      tableColumnHeaders = [
        ...tableColumnHeaders,
        this.createLabResultHeader(labResultItem.issued_datetime),
      ];
    });

    return {
      tableData: resultRow,
      tableColumnHeaders,
    };
  }
  createLabResultHeader(issueDateTime) {
    return {
      Header: (
        <div className='LabResultHeader'>
          <div>{jalaliFullDateTime(issueDateTime)}</div>
        </div>
      ),
      accessor: issueDateTime,
      style: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 'none',
      },
      headerStyle: {
        background: '#f5f5f5',
        borderBottom: '1px solid #e7e7e7',
        borderRight: 'none',
      },
      minWidth: 150,
      // maxWidth: 150,
    };
  }
  createLabResultRowTitle(factorName) {
    return (
      <div className='flex'>
        <div className='LabResultName'>{factorName}</div>
      </div>
    );
  }
  createLabResultCell(
    resultIndex,
    issueDateTime,
    groupIndex,
    groupId,
    shown_value,
    lastCell,
    isAbnormal
  ) {
    let valueContainer = (
      <div className={`labResultValue ${isAbnormal ? 'abnormalColor' : ''} `}>
        {shown_value}
      </div>
    );
    // we add more link to last cell of column
    if (lastCell) {
      return (
        <div
          className={`labResultLastCellColumn ${
            shown_value ? '' : 'emptyCell'
          }`}
        >
          {valueContainer}

          <Link
            className='LabResultMore'
            onClick={() => this.openLabDetailDialog()}
            to={{
              pathname: `${this.props.location.pathname}/group/${groupId}/result/${resultIndex}`,
              state: {
                issueDateTime,
                index: groupIndex,
              },
            }}
          >
            Detail
          </Link>
        </div>
      );
    }
    return valueContainer;
  }

  async handleTriggerNotif(labValue, radioValue) {
    const { factorId, groupIndex } = this.state.selected;
    var data = {
      profile_id: +this.props.match?.params?.patientId,
      lab_test_factor_id: factorId,
    };
    if (labValue === 'none') {
      data.lab_test_factor_trigger = false;
      data.lab_test_factor_abnormal_only = false;
    } else if (labValue === 'abnormal') {
      data.lab_test_factor_trigger = true;
      data.lab_test_factor_abnormal_only = true;
    } else {
      data.lab_test_factor_trigger = true;
      data.lab_test_factor_abnormal_only = false;
    }
    const response = await this.props.triggerNotif(data);
    if (response && response.status === 200) {
      const foundFactor = this.props.labResult[groupIndex].factors.find(
        (val) => val.id === +factorId
      );
      if (foundFactor) {
        foundFactor.subscription = labValue;
      }

      this.changeLabResultToRender();
    }
    // this.setState({ lab_subscription: labValue, [factor_id]: labValue });
    // var newTableData = this.state.tableData;
    // newTableData[this.state.subscribedRow.keyOfTabl].data[
    //   this.state.subscribedRow.keyOfRow
    // ].title = (
    //   <div className='flex'>
    //     <img
    //       alt='notif icon'
    //       src={
    //         labValue === 'none'
    //           ? Set_Icon
    //           : labValue === 'all'
    //           ? Remove_Icon
    //           : Abnormal_Icon
    //       }
    //       className='LabResultIcon'
    //       onClick={() =>
    //         this.setState({
    //           notif_dialog: true,
    //           lab_subscription: labValue,
    //           factor_id: this.state.subscribedRow.id,
    //         })
    //       }
    //     />
    //     <div className='LabResultName'>{this.state.subscribedRow.title}</div>
    //   </div>
    // );
    // this.setState({ tableData: newTableData });
  }
  handleClose() {
    this.setState({ openLabDetail: false });
  }
  openLabDetailDialog() {
    this.setState({ openLabDetail: true });
  }
  handleNotifClose() {
    this.setState({ openNotifDialog: false });
  }
  setNotifIcon(subscription, factor, groupIndex, impFactorIndex) {
    // check if this user has access to the patient or she/he was just asked about consult requests
    // notification icon should not be shown to user who does not have access to patient (user who was just ask consult request about this patient)
    if (this.props.section === 'consult') {
      return;
    }
    let iconName =
      subscription === 'none'
        ? Set_Icon
        : subscription === 'all'
        ? Remove_Icon
        : Abnormal_Icon;

    const notifIcon = (
      <img
        alt='notif icon'
        className='LabResultIcon'
        src={iconName}
        onClick={() => {
          this.handleClickNotifIcon(groupIndex, factor.id);
        }}
      />
    );
    return notifIcon;
  }
  handleClickNotifIcon(selectedGroupIndex, selectedFactorId) {
    let subscription;
    if (this.props.labResult[selectedGroupIndex]) {
      subscription = this.props.labResult[selectedGroupIndex].factors?.find(
        (val) => val.id === selectedFactorId
      )?.subscription;
    }
    this.setState({
      openNotifDialog: true,
      selected: {
        ...this.state.selected,
        factorId: selectedFactorId,
        groupIndex: selectedGroupIndex,
        subscription,
      },
    });
  }
  render() {
    const { routes } = this.props;
    return (
      <Fragment>
        <PageHeader
          subTitle='Lab Result'
          cacheEnable={true}
          pageDataFetched={!this.state.loading && !this.state.error}
          pageDataNotReloaded={
            !this.state.loading && this.state.error?.networkError
          }
          dataFetchedFromDB={this.props.labResultFetchedFromDB}
        />
        <PageWrapper
          loading={
            this.state.loading &&
            !this.props.labResultFetchedFromDB &&
            !this.state.error
          }
          error={this.state.error}
          dataFetchedFromDB={this.props.labResultFetchedFromDB}
          onReload={() => this.fetchPageData()}
          className={this.props.classes?.pageWrapper}
        >
          {this.state.tableData?.length ? (
            <div className='tableContainer sc'>
              <div className='dashboardScroll-inner sc'>
                {this.state.tableData.map((table, index) => (
                  <div className='dashboardScroll-inner sc' key={index}>
                    <ReactTableFixedColumns
                      resizable={false}
                      sortable={false}
                      data={table.data}
                      columns={table.header}
                      showPagination={false}
                      defaultPageSize={table.data.length}
                    />
                  </div>
                ))}

                {this.state.openNotifDialog && (
                  <div>
                    <NotifDrawer
                      labFactorOnly={true}
                      handleSubmit={(labValue, radioValue) =>
                        this.handleTriggerNotif(labValue, radioValue)
                      }
                      labValue={this.state.selected.subscription}
                      handleClose={() => this.handleNotifClose()}
                    />
                  </div>
                )}
                {Boolean(routes?.length) &&
                  routes.map((route) => (
                    <RouteWithSubRoutes key={route.key} route={route} />
                  ))}
              </div>
            </div>
          ) : (
            <NoContent width={'65%'} text={'No Laboratory result found.'} />
          )}
        </PageWrapper>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    section: state.patientsReducer.section,
    labResult: state.patientsReducer.patient?.labResult,
    labResultFetchedFromDB:
      state.patientsReducer.patient?.labResultFetchedFromDB,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ triggerNotif, getPatientLabResult }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(LabResult)));
