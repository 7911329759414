import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    background: '#e9f4ff',
    minHeight: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'max-content',
    padding: '0px 10px',
    borderRadius: '10px',
    margin: '2px',
    marginBottom: '7px',
    maxWidth: '100%',
  },
  iconButton: {
    background: '#363636',
    '&:hover': {
      background: '#505050',
    },
  },
  icon: {
    fontSize: '1rem',
    color: 'white',
  },
});

export const ICD11SelectedItem = ({
  handleRemoveICD11,
  icdIsRemovable,
  problem,
  ...rest
}) => {
  const classes = useStyles();

  const handleRemove = (e) => {
    e.stopPropagation();
    handleRemoveICD11(problem);
  };
  return (
    <div className={classes.container} {...rest}>
      <span
        className={icdIsRemovable ? 'problemWithPadding' : 'problem'}
        dangerouslySetInnerHTML={{ __html: problem['title_en'] }}
      ></span>
      {icdIsRemovable && (
        <IconButton
          size='small'
          color='inherit'
          className={classes.iconButton}
          onClick={handleRemove}
        >
          <CloseIcon className={classes.icon} color='inherit' />
        </IconButton>
      )}
    </div>
  );
};
export default ICD11SelectedItem;
