import React from 'react';
import {
  withStyles,
  Checkbox as MuiCheckbox,
  CheckboxProps,
} from '@material-ui/core';
import { ReactComponent as SquareIcon } from '../../../../Assets/icons/component_square.svg';
import { ReactComponent as CheckedIcon } from '../../../../Assets/icons/component_checked_prymary_square.svg';

const Checkbox = withStyles((theme) => ({
  root: {
    color: theme.palette.grey[600],
  },
  checked: {},
}))((props) => <MuiCheckbox color='default' {...props} />);

type IProps = CheckboxProps;

const AppCheckbox: React.FC<IProps> = ({ ...props }) => {
  return (
    <Checkbox icon={<SquareIcon />} checkedIcon={<CheckedIcon />} {...props} />
  );
};

export default AppCheckbox;
