/* eslint-disable semi */
import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import useWindowDimensions from '../../Hook/useWindowDimensions';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import useFixedBottom from '../../Hook/useFixedBottom';
import { CheckRtl } from '../../Utilities/CheckLang';

const useStyles = makeStyles({
  root: {
    backgroundColor: 'white',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    maxHeight: 110,
    overflowX: 'auto',
    overflowY: 'hidden',
    zIndex: 1,

    '&::-webkit-scrollbar': {
      height: 2,
    },
  },
  optionsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  option: {
    backgroundColor: 'white',
    border: '1px solid #ccd2e066',
    borderRadius: 4,
    boxShadow: '0px 2px 8px 0px #70727a29',
    padding: '8px 12px',
    margin: 8,
    textAlign: 'left',

    '&[aria-selected="true"]': {
      position: 'relative',
      border: '2px solid #a8c8ff',
      padding: '6px 10px',

      '&:after': {
        content: '""',
        background:
          'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAANlBMVEVHcEyoyP+oyP+oyP+nx/+vz/+oyP+oyP+oyP+oyP+oyP8ADnqcuvYiM5Vpgs0LGoKIpOZOZLkZ2RUMAAAACnRSTlMA0OCPoBDwSbiA21xnPwAAAL9JREFUOMvF1MkOhSAMBVAckDL4wP//2YdIItCCdeXd6OKkpA1UiO8jlYbZVJlBK9k6tRkym6rLLaabpSy6mkHW4lwzzH36NIYTs+BdUj9BzWmlbAd6IOQvZNhhbrdH/h3C6KxnwNPZ3zOs3ADWroHuCB3XwN36QDsEs0SugcFfEru2mUsSDnWdJOHweE5JOGKOSSJHDTxK7Ax1zZzDDt5eXPZTYD8u9nPlLwD2ShESmEsqds5be2mRAmyMRfpB/omaKv58L1TDAAAAAElFTkSuQmCC")',
        backgroundSize: 'cover',
        position: 'absolute',
        top: 0,
        right: 0,
        width: 20,
        height: 20,
        transform: 'translate(50%, -50%)',
      },
    },
  },
});

interface IProps {
  options: any[];
  emptyOption?: any;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getListboxProps?: () => object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  getOptionProps?: ({
    option,
    index,
  }: {
    option: any;
    index: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
  }) => object;
  getOptionLabel?: (option: any) => string;
  filterOption?: (option: any) => boolean;
}

// eslint-disable-next-line react/display-name
const MobileAutocompleteOptionsList = React.forwardRef<any, IProps>(
  (
    {
      options,
      emptyOption,
      getListboxProps,
      getOptionProps,
      getOptionLabel = (o) => o,
      filterOption = () => true,
    },
    ref
  ) => {
    const classes = useStyles();
    const { width: windowWidth } = useWindowDimensions();
    const [rows, setRows] = useState<{ option: any; prevIndex: number }[][]>(
      []
    );
    const [containerWidth, setContainerWidth] = useState(0);
    const [rootRef, setRootRef] = useState<HTMLElement | null>(null);

    useFixedBottom(rootRef, 0);

    const getOptionWidth = (option: any) => {
      // Create an element
      const ele = document.createElement('span');

      // Set styles
      ele.style.position = 'absolute';
      ele.style.visibility = 'hidden';
      ele.style.whiteSpace = 'nowrap';
      ele.style.left = '-9999px';

      // Set styles and text
      ele.innerText = getOptionLabel(option);
      ele.className = isEqual(emptyOption, option) ? 'fs-italic' : '';
      ele.style.fontSize = '14px';
      ele.style.fontWeight = '500';
      ele.style.lineHeight = '21px';

      // Append to the body
      document.body.appendChild(ele);

      // Get the width
      const width = ele.getBoundingClientRect().width;

      // Remove the element
      document.body.removeChild(ele);

      return width + 42;
    };

    const updateRows = (state?: 'single' | 'double' | 'scroll') => {
      const tempRows: any[][] = [[], []];

      let cumulativeWidth1 = 0;
      let cumulativeWidth2 = 0;
      let hasOverflow = false;
      let prevIndex = 0;
      for (const option of options) {
        if (filterOption(option)) {
          const optionWidth = getOptionWidth(option);

          switch (state) {
            case 'scroll': {
              if (cumulativeWidth2 >= cumulativeWidth1) {
                cumulativeWidth1 += optionWidth;
                tempRows[0].push({ option, prevIndex });
              } else {
                cumulativeWidth2 += optionWidth;
                tempRows[1].push({ option, prevIndex });
              }
              break;
            }
            case 'double': {
              if (cumulativeWidth1 + optionWidth > windowWidth) {
                if (cumulativeWidth2 + optionWidth > windowWidth) {
                  hasOverflow = true;
                  break;
                }
                cumulativeWidth2 += optionWidth;
                tempRows[1].push({ option, prevIndex });
              } else {
                cumulativeWidth1 += optionWidth;
                tempRows[0].push({ option, prevIndex });
              }
              break;
            }
            default: {
              cumulativeWidth1 += optionWidth;
              tempRows[0].push({ option, prevIndex });
              if (cumulativeWidth1 > windowWidth) {
                hasOverflow = true;
                break;
              }
            }
          }
        }
        prevIndex++;
      }
      if (hasOverflow) {
        if (state === 'double') updateRows('scroll');
        else if (state !== 'scroll') updateRows('double');
      } else {
        setContainerWidth(
          cumulativeWidth1 > cumulativeWidth2
            ? cumulativeWidth1
            : cumulativeWidth2
        );
        setRows(tempRows);
      }
    };

    useEffect(() => {
      updateRows();
    }, [options]);

    return (
      <Box {...{ ref: (el: any) => setRootRef(el) }} className={classes.root}>
        <Box {...(getListboxProps ? getListboxProps() : {})}>
          <Box {...{ ref: ref }} style={{ width: containerWidth }}>
            {rows.map((row, index) => (
              <Box key={index} className={classes.optionsWrapper}>
                {row.map((item, index) => {
                  const optionProps = getOptionProps
                    ? (getOptionProps({
                        option: item.option,
                        index: item['prevIndex'],
                      }) as any)
                    : {};
                  const isSelected = !!optionProps['aria-selected'];

                  return (
                    <Box
                      key={index}
                      className={classes.option}
                      {...(isSelected
                        ? { 'aria-selected': true }
                        : optionProps)}
                    >
                      <Typography
                        component='span'
                        className={clsx(
                          'mediumWeight',
                          'darkPrimaryColor',
                          isEqual(emptyOption, item.option) && 'fs-italic',
                          CheckRtl(getOptionLabel(item.option)) &&
                            'farsiFont rtl'
                        )}
                      >
                        {getOptionLabel(item.option)}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
);

export default MobileAutocompleteOptionsList;
