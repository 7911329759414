const initialState = {
  fireBaseToken: '',
};

export const fireBaseTokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_FIREBASE_TOKEN':
      return { ...state, fireBaseToken: action.data };
    default:
  }

  return state;
};
