import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import TUM_ICON from '../../../../Assets/logos/TUM.jpg';
import SINA_ICON from '../../../../Assets/logos/sina_logo.jpg';
import CMC_ICON from '../../../../Assets/logos/ChildMedicalCenter_B&W.jpg';
import YAS_ICON from '../../../../Assets/logos/yas.png';
import HAKIM_ICON from '../../../../Assets/logos/hakim.png';
import clsx from 'clsx';
import { connect, RootStateOrAny } from 'react-redux';
import { getPatientProfile } from '../../../../action/profile';
import { IPatient } from '../../../../types/patient';
import { useParams } from 'react-router-dom';
import {
  jalaliFullDate,
  jalaliFullDateTime,
} from '../../../../Utilities/jalaliDate';
import { CheckRtl } from '../../../../Utilities/CheckLang';

const useStyles = makeStyles({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  ministryText: {
    fontFamily: 'TimesNewRoman',
    fontSize: 10,
    lineHeight: '11px',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: 0,
  },
  ministryFaText: {
    fontFamily: 'BTitr',
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 4,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    marginTop: 16,
  },
  gridItem: {
    textAlign: 'right',
    minHeight: 36,
    padding: '1px 4px',
    border: '1px solid #191e25',

    '&.p-0': {
      padding: 0,
    },

    '&.border-0': {
      border: 'none',
    },
    '&.border-top-0': {
      borderTop: 'none',
    },
    '&.border-left-0': {
      borderLeft: 'none',
    },
    '&.border-bottom-0': {
      borderBottom: 'none',
    },
    '&.border-right-0': {
      borderRight: 'none',
    },
  },
  title: {
    fontFamily: 'TimesNewRoman',
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: '11px',
    textAlign: 'center',
    margin: 0,
  },
  titleFa: {
    fontFamily: 'BLotus',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: '25px',
    textAlign: 'center',
    margin: 0,
  },
  label: {
    fontFamily: 'BLotus',
    fontSize: 11,
    lineHeight: '18px',
  },
  value: {
    fontFamily: 'BLotus',
    fontSize: '11px',
    fontWeight: 'bold',
    lineHeight: '18px',
    minHeight: '18px',
    marginTop: -2,

    '&.fontEn': {
      fontFamily: 'Heebo',
    },
  },
});

interface IProps {
  patient: IPatient;
  getPatientProfile: (patientId: string, useCacheData: boolean) => Promise<any>;
}

const PhysicianOrdersPrintHeader: React.FC<IProps> = ({
  patient,
  getPatientProfile,
}) => {
  const classes = useStyles();
  const { patientId } = useParams<{ patientId: string }>();

  useEffect(() => {
    getPatientProfile(patientId, true).catch((err) => console.log(err));
  }, [patientId]);

  const organizationIconAttributes = () => {
    const organization = (
      (window as any).env.REACT_APP_ORGANIZATION + ''
    ).toLowerCase();

    switch (organization) {
      case 'sina':
        return {
          src: SINA_ICON,
          alt: 'sina_icon',
        };
      case 'yas':
        return {
          src: YAS_ICON,
          alt: 'yas_icon',
        };
      case 'hakim':
        return {
          src: HAKIM_ICON,
          alt: 'hakim_icon',
        };
      default:
        return {
          src: CMC_ICON,
          alt: 'cmc_icon',
        };
    }
  };

  const renderSectionContent = (
    label: string,
    value?: string | null,
    checkDirection = false
  ) => {
    return (
      <>
        <Box className={classes.label}>:{label}</Box>
        <Box
          className={clsx(
            classes.value,
            !CheckRtl(value, 'rtl') && checkDirection && 'fontEn'
          )}
        >
          {value}
        </Box>
      </>
    );
  };

  return (
    <Box mb={1.5}>
      <Box className={classes.header}>
        <img alt='tum icon' height={51} src={TUM_ICON} />
        <Box>
          <h4 className={classes.ministryFaText}>
            وزارت بهداشت درمان و آموزش پزشکی
          </h4>
          <h4 className={classes.ministryText}>
            Ministry of Health & Medical Education
          </h4>
        </Box>
        <img
          alt={organizationIconAttributes().alt}
          height={51}
          src={organizationIconAttributes().src}
        />
      </Box>

      <Box className={classes.grid}>
        <Box className={clsx(classes.gridItem, 'border-bottom-0')}>
          {renderSectionContent('کد ملی بیمار', patient.national_id)}
        </Box>
        <Box
          className={clsx(classes.gridItem, 'p-0 border-0')}
          style={{ gridColumn: 'auto / span 3' }}
        >
          <h5 className={classes.titleFa}>برگ دستورات پزشک</h5>
          <h5 className={classes.title}>Physician Orders Sheet</h5>
        </Box>
        <Box className={clsx(classes.gridItem, 'border-bottom-0')}>
          {renderSectionContent('شماره پرونده', patient.admission_id)}
        </Box>

        <Box
          className={clsx(
            classes.gridItem,
            'border-bottom-0 border-right-0 flex'
          )}
        >
          <Box flex={1}>{renderSectionContent('تخت', patient.bed, true)}</Box>
          <Box flex={1}>{renderSectionContent('اتاق', patient.room, true)}</Box>
        </Box>
        <Box
          className={clsx(classes.gridItem, 'border-bottom-0')}
          style={{ gridColumn: 'auto / span 2' }}
        >
          {renderSectionContent('بخش', patient.ward?.name, true)}
        </Box>
        <Box
          className={clsx(
            classes.gridItem,
            'border-right-0 border-left-0 border-bottom-0'
          )}
        >
          {renderSectionContent('نام', patient.first_name, true)}
        </Box>
        <Box className={clsx(classes.gridItem, 'border-bottom-0')}>
          {renderSectionContent('نام خانوادگی', patient.last_name, true)}
        </Box>

        <Box className={clsx(classes.gridItem, 'border-right-0')}>
          {renderSectionContent(
            'تاریخ پذیرش',
            jalaliFullDateTime(patient.admission_datetime)
          )}
        </Box>
        <Box
          className={classes.gridItem}
          style={{ gridColumn: 'auto / span 2' }}
        >
          {renderSectionContent('پزشک معالج', '', true)}
        </Box>
        <Box className={clsx(classes.gridItem, 'border-right-0 border-left-0')}>
          {renderSectionContent(
            'تاریخ تولد',
            jalaliFullDate(patient.birth_date)
          )}
        </Box>
        <Box className={classes.gridItem}>
          {renderSectionContent('نام پدر', patient.father_name, true)}
        </Box>
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootStateOrAny) => ({
    patient: state.patientsReducer.patient?.profile,
  }),
  { getPatientProfile }
)(PhysicianOrdersPrintHeader);
