import * as actionTypes from '../action/actionTypes';
import * as api from '../api/api';
import {
  getPinnedProfileImages,
  setPatientDataBase,
  unsetPinnedProfileImage,
} from './actions';

export const getPatientProfile = (patientId) => async (dispatch) => {
  const response = await api.getPatientProfile(patientId);

  await dispatch(setPatientDataBase(patientId, response, 'profile'));

  return dispatch({
    type: 'GET_PROFILE',
    data: response.data,
  });
};

export const editProfile = (data, profileId) => async (dispatch) => {
  return api.editProfile(data, profileId).then(async (response) => {
    await dispatch({
      type: 'ADD_MESSAGE',
      message: 'successfully updated patient info',
      status: 200,
    });
    return dispatch({
      type: 'GET_PROFILE',
      data: response.data,
    });
  });
};

export const uploadProfileImage = (blob, profileId, queueId = 0) => async (
  dispatch
) => {
  if (queueId === 0) {
    queueId = Date.now();
  }
  await dispatch({
    type: actionTypes.UPLOAD_IMAGE_REQUEST,
    queueId,
    profileId,
    blob,
  });
  const data = new FormData();
  data.append('file', blob);
  api
    .uploadProfileImage(data, profileId)
    .then(async () => {
      await dispatch(getPatientProfile(profileId));
      await dispatch({
        type: actionTypes.UPLOAD_IMAGE_REMOVE,
        id: queueId,
      });
      await dispatch({
        type: 'ADD_MESSAGE',
        message: 'successfully uploaded patient image',
        status: 200,
      });
    })
    .catch(async (error) => {
      await dispatch({
        type: actionTypes.UPLOAD_IMAGE_FAILURE,
        id: queueId,
      });
      console.log(error);
    });
};

export const deleteProfileImage = (
  imageIds,
  profileId,
  silent = false
) => async (dispatch) => {
  let failRequests = 0;
  let refreshPinned = false;
  for (const index in imageIds) {
    const { id, pinned } = imageIds[index];
    if (pinned) {
      refreshPinned = true;
      await dispatch(unsetPinnedProfileImage(id, profileId, true))
        .then(
          async () =>
            await api
              .deleteProfileImage(id, profileId)
              .catch(() => failRequests++)
        )
        .catch(() => failRequests++);
    } else {
      await api.deleteProfileImage(id, profileId).catch(() => failRequests++);
    }
  }

  if (failRequests !== imageIds.length) {
    await dispatch(getPatientProfile(profileId));
  }
  if (refreshPinned) await dispatch(getPinnedProfileImages(profileId));

  if (silent) {
    if (failRequests !== imageIds.length) return Promise.resolve('all done!');
    return Promise.reject('failed!');
  }

  if (failRequests === imageIds.length)
    return await dispatch({
      type: 'ADD_MESSAGE',
      message:
        imageIds.length > 1
          ? 'failed to delete images!'
          : 'failed to delete image!',
      status: 500,
    });

  if (failRequests)
    return await dispatch({
      type: 'ADD_MESSAGE',
      message: `failed to delete ${failRequests} images!`,
      status: 500,
    });

  return await dispatch({
    type: 'ADD_MESSAGE',
    message:
      imageIds.length === 1
        ? 'successfully deleted patient image'
        : 'successfully deleted patient images',
    status: 200,
  });
};
