import React from 'react';
import { makeStyles } from '@material-ui/core';

const priorityColors = ['#ea2323','#ea2323','#f09917','#2bd27c'];
const useStyles = makeStyles({
    element: (priority) => ({
        background: priorityColors[priority],
        width: '5px'
    }),
});


export default function Priority({priority = 0}) {
    const classes = useStyles(priority);

    return (
        <div className={classes.element} />
    );
}
