import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { renderAutoDirText } from '../../../Utilities/CheckLang';

interface IProps {
  descriptions: string[];
}

const VisitDescription: React.FC<IProps> = ({ descriptions }) => {
  return (
    <Box>
      {descriptions.map((description, index) => (
        <Box key={index} mb={descriptions.length === index + 1 ? 0 : 1}>
          <Typography align='right' dir='rtl'>
            {renderAutoDirText(description)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default VisitDescription;
