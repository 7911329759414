import React, { useRef, useState } from 'react';
import { IOrder, isMedicationTask } from '../../../../types/order';
import { useQuery } from 'react-query';
import { getFeasibleTerms } from '../../../../api/medicalTerms';
import { Grid, TextField, Button, makeStyles, Theme } from '@material-ui/core';
import ResponsiveAutocomplete from '../../../ResponsiveAutocomplete';
import { IGetFeasibleMedicalTermsQuery } from '../../../../types/medicalTerms';
import { ReactComponent as AddIcon } from '../../../../Assets/icons/add_primary.svg';
import { ReactComponent as TrashIcon } from '../../../../Assets/icon_trash_red.svg';
import OrderTimingField from '../Fields/Timing';
import OrderNoteField from '../Fields/Note';
import { CheckRtl } from '../../../../Utilities/CheckLang';

type MedicationFieldTypes =
  | 'medication_drug'
  | 'medication_form'
  | 'medication_strength'
  | 'medication_amount'
  | 'medication_route'
  | 'medication_timing'
  | 'medication_added_drug'
  | 'medication_added_form'
  | 'medication_added_strength'
  | 'medication_added_amount';

const useStyle = makeStyles((theme: Theme) => ({
  textField: {
    '& input': {
      padding: '9.5px 4px',
    },
  },
  addedWrapper: {
    paddingLeft: 8,
    margin: '8px 0',
    borderLeft: `1px solid ${theme.palette.primary.main}`,
  },
  addedItem: {
    marginBottom: 8,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  trashBtn: {
    marginBottom: -24,
  },
}));

interface IProps {
  defaultOrder: IOrder;
  onOrderChange: (order: IOrder) => void;
}

const MedicationFormContent: React.FC<IProps> = ({
  defaultOrder,
  onOrderChange,
}) => {
  const classes = useStyle();
  const fieldsRef = useRef<{ [key: string]: HTMLInputElement }>({});
  const [currentType, setCurrentType] = useState<MedicationFieldTypes>(
    'medication_drug'
  );
  const [currentAddedIndex, setCurrentAddedIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  const mapCurrentTypeToMedicalType = (type: MedicationFieldTypes) => {
    switch (type) {
      case 'medication_drug':
      case 'medication_added_drug':
        return 'medication_drug';

      case 'medication_form':
      case 'medication_added_form':
        return 'medication_form';

      case 'medication_strength':
      case 'medication_added_strength':
        return 'medication_strength';

      case 'medication_route':
        return 'medication_route';

      default:
        return undefined;
    }
  };

  const { data, isFetching } = useQuery(
    [
      'medical_terms',
      {
        type: currentType,
        q: searchQuery,
      },
    ],
    () => {
      const mappedType = mapCurrentTypeToMedicalType(currentType);
      if (mappedType) {
        const query: IGetFeasibleMedicalTermsQuery = {
          q: searchQuery || undefined,
        };
        if (isMedicationTask(defaultOrder.task)) {
          /* eslint-disable no-fallthrough */
          switch (currentType) {
            case 'medication_route': {
              query.medication_strength_id = defaultOrder.task.strength.id;
            }
            case 'medication_strength': {
              query.medication_form_id = defaultOrder.task.form.id;
            }
            case 'medication_form': {
              query.medication_drug_id = defaultOrder.task.drug.id;
              break;
            }
            case 'medication_added_strength': {
              query.medication_form_id =
                defaultOrder.task.added[currentAddedIndex]?.form.id;
            }
            case 'medication_added_form': {
              query.medication_drug_id =
                defaultOrder.task.added[currentAddedIndex]?.drug.id;
            }
          }
          /* eslint-enable no-fallthrough */
        }
        return getFeasibleTerms(mappedType, query);
      }
    }
  );

  const setInputRef = (name: string) => (el: HTMLInputElement) => {
    fieldsRef.current = { ...fieldsRef.current, [name]: el };
  };

  const focusNextField = (
    currentField: MedicationFieldTypes,
    index?: number
  ) => {
    switch (currentField) {
      case 'medication_drug': {
        fieldsRef.current['medication_form'] &&
          fieldsRef.current['medication_form'].focus();
        break;
      }
      case 'medication_form': {
        fieldsRef.current['medication_strength'] &&
          fieldsRef.current['medication_strength'].focus();
        break;
      }
      case 'medication_strength': {
        fieldsRef.current['medication_amount'] &&
          fieldsRef.current['medication_amount'].focus();
        break;
      }
      case 'medication_route': {
        fieldsRef.current['medication_timing'] &&
          fieldsRef.current['medication_timing'].focus();
        break;
      }
      case 'medication_timing': {
        fieldsRef.current['medication_added_drug.0'] &&
          fieldsRef.current['medication_added_drug.0'].focus();
        break;
      }
      case 'medication_added_drug': {
        if (index !== undefined) {
          fieldsRef.current['medication_added_form.' + index] &&
            fieldsRef.current['medication_added_form.' + index].focus();
        }
        break;
      }
      case 'medication_added_form': {
        if (index !== undefined) {
          fieldsRef.current['medication_added_strength.' + index] &&
            fieldsRef.current['medication_added_strength.' + index].focus();
        }
        break;
      }
      case 'medication_added_strength': {
        if (index !== undefined) {
          fieldsRef.current['medication_added_amount.' + index] &&
            fieldsRef.current['medication_added_amount.' + index].focus();
        }
        break;
      }
    }
  };

  const handleAutocompleteInputChange = (type: MedicationFieldTypes) => async (
    value: string
  ) => {
    await setCurrentType(type);
    setSearchQuery(value);
  };

  const isAddedType = (type: MedicationFieldTypes) => {
    return (
      type === 'medication_added_drug' ||
      type === 'medication_added_form' ||
      type === 'medication_added_strength' ||
      type === 'medication_added_amount'
    );
  };

  const handleAutocompleteInputFocus = (
    type: MedicationFieldTypes,
    index?: number
  ) => async () => {
    if (index !== undefined && isAddedType(type)) {
      setCurrentAddedIndex(index);
    }

    await setCurrentType(type);
    setSearchQuery('');
  };

  const handleAutocompleteChange = (
    type: MedicationFieldTypes,
    index?: number
  ) => async (_: any, value: any) => {
    if (isMedicationTask(defaultOrder.task)) {
      let newTask = { ...defaultOrder.task };
      switch (type) {
        case 'medication_drug': {
          newTask = {
            ...newTask,
            drug: value ? { id: value.id, title_en: value.title_en } : {},
            form: {},
            strength: {},
            amount: '',
            route: {},
          };
          break;
        }
        case 'medication_form': {
          newTask = {
            ...newTask,
            form: value ? { id: value.id, title_en: value.title_en } : {},
            strength: {},
            amount: '',
            route: {},
          };
          break;
        }
        case 'medication_strength': {
          newTask = {
            ...newTask,
            strength: value ? { id: value.id, title_en: value.title_en } : {},
            amount: '',
            route: {},
          };
          break;
        }
        case 'medication_route': {
          newTask = {
            ...newTask,
            route: value ? { id: value.id, title_en: value.title_en } : {},
          };
          break;
        }
        case 'medication_added_drug': {
          if (index !== undefined) {
            const newAdded = [...newTask.added];
            newAdded[index] = {
              drug: value ? { id: value.id, title_en: value.title_en } : {},
              form: {},
              strength: {},
              amount: '',
            };
            newTask = { ...newTask, added: [...newAdded] };
          }
          break;
        }
        case 'medication_added_form': {
          if (index !== undefined) {
            const newAdded = [...newTask.added];
            newAdded[index] = {
              ...newAdded[index],
              form: value ? { id: value.id, title_en: value.title_en } : {},
              strength: {},
              amount: '',
            };
            newTask = { ...newTask, added: [...newAdded] };
          }
          break;
        }
        case 'medication_added_strength': {
          if (index !== undefined) {
            const newAdded = [...newTask.added];
            newAdded[index] = {
              ...newAdded[index],
              strength: value ? { id: value.id, title_en: value.title_en } : {},
              amount: '',
            };
            newTask = { ...newTask, added: [...newAdded] };
          }
          break;
        }
      }
      await onOrderChange({ ...defaultOrder, task: newTask });
      await setSearchQuery('');
      if (value?.id) {
        // This condition is necessary to avoid auto focus on clear field
        focusNextField(type, index);
      }
    }
  };

  const handleTextFieldChange = (
    type: MedicationFieldTypes,
    index?: number
  ) => async (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isMedicationTask(defaultOrder.task)) {
      let newTask = { ...defaultOrder.task };
      switch (type) {
        case 'medication_amount': {
          newTask = {
            ...newTask,
            amount: event.target.value,
          };
          break;
        }
        case 'medication_added_amount': {
          if (index !== undefined) {
            const newAdded = [...newTask.added];
            newAdded[index] = {
              ...newAdded[index],
              amount: event.target.value,
            };
            newTask = { ...newTask, added: [...newAdded] };
          }
          break;
        }
      }
      await onOrderChange({ ...defaultOrder, task: newTask });
    }
  };

  const handleTimingFieldChange = (value: string, errors: any[]) => {
    if (isMedicationTask(defaultOrder.task)) {
      let newTask = { ...defaultOrder.task };
      newTask = {
        ...newTask,
        timing: {
          raw: value,
          schedules: [],
          errors: errors,
        },
      };
      onOrderChange({ ...defaultOrder, task: newTask });
    }
  };

  if (!isMedicationTask(defaultOrder.task)) return null;

  const handleAddMedication = () => {
    if (isMedicationTask(defaultOrder.task)) {
      const newOrder: IOrder = {
        ...defaultOrder,
        task: {
          ...defaultOrder.task,
          added: [
            ...defaultOrder.task.added,
            { drug: {}, form: {}, strength: {}, amount: '' },
          ],
        },
      };
      onOrderChange(newOrder);
      const lastIndex = defaultOrder.task.added.length;
      setTimeout(() => {
        fieldsRef.current[`medication_added_drug.${lastIndex}`] &&
          fieldsRef.current[`medication_added_drug.${lastIndex}`].focus();
      }, 100);
    }
  };

  const handleRemoveMedication = (index: number) => () => {
    if (isMedicationTask(defaultOrder.task)) {
      const newAdded = [...defaultOrder.task.added];
      newAdded.splice(index, 1);
      const newOrder: IOrder = {
        ...defaultOrder,
        task: {
          ...defaultOrder.task,
          added: [...newAdded],
        },
      };
      onOrderChange(newOrder);
    }
  };

  const renderAutoComplete = ({
    type,
    value,
    placeholder,
    index,
    disableFilter,
    emptyOption,
  }: {
    type: MedicationFieldTypes;
    value: { [key: string]: any };
    placeholder?: string;
    index?: number;
    disableFilter?: boolean;
    emptyOption?: { id: number; title_en: string };
  }) => {
    const isInitialField = type === 'medication_drug' && index === undefined;
    const isLoading =
      isFetching &&
      currentType === type &&
      (isAddedType(type) ? index === currentAddedIndex : true);

    return (
      <ResponsiveAutocomplete
        isAsync
        loading={isLoading}
        options={data?.data.results ?? []}
        filterOptions={(options) => options}
        getOptionLabel={(o) => o.title_en || ''}
        getOptionSelected={(o, v) => o?.id === v?.id}
        value={value}
        emptyOption={emptyOption}
        onChange={handleAutocompleteChange(type, index)}
        onInputChange={handleAutocompleteInputChange(type)}
        textFieldProps={{
          autoFocus: isInitialField,
          placeholder,
          inputRef: setInputRef(
            index === undefined ? type : type + '.' + index
          ),
          onFocus: handleAutocompleteInputFocus(type, index),
        }}
      />
    );
  };

  const fields = [
    {
      key: 'medication_drug',
      isVisible: true,
      content: (
        <Grid item xs={9}>
          {renderAutoComplete({
            type: 'medication_drug',
            value: defaultOrder.task.drug,
            placeholder: 'Medication Name',
            disableFilter: true,
          })}
        </Grid>
      ),
    },
    {
      key: 'medication_form',
      isVisible: !!defaultOrder.task.drug.id,
      content: (
        <Grid item xs={3}>
          {renderAutoComplete({
            type: 'medication_form',
            value: defaultOrder.task.form,
            placeholder: 'Form',
          })}
        </Grid>
      ),
    },
    {
      key: 'medication_strength',
      isVisible: !!defaultOrder.task.form.id,
      content: (
        <Grid item xs={3}>
          {renderAutoComplete({
            type: 'medication_strength',
            value: defaultOrder.task.strength,
            placeholder: 'Strength',
            emptyOption: { id: 0, title_en: 'No Strength' },
          })}
        </Grid>
      ),
    },
    {
      key: 'medication_amount',
      isVisible: defaultOrder.task.strength.id !== undefined,
      content: (
        <Grid item xs={6}>
          <TextField
            fullWidth
            placeholder='Number/Dose'
            className={classes.textField}
            value={defaultOrder.task.amount}
            inputRef={setInputRef('medication_amount')}
            onChange={handleTextFieldChange('medication_amount')}
            inputProps={{
              style: {
                direction: CheckRtl(defaultOrder.task.amount) ? 'rtl' : 'ltr',
              },
              className: CheckRtl(defaultOrder.task.amount) ? 'farsiFont' : '',
            }}
          />
        </Grid>
      ),
    },
    {
      key: 'medication_route',
      isVisible: !!defaultOrder.task.amount,
      content: (
        <Grid item xs={3}>
          {renderAutoComplete({
            type: 'medication_route',
            value: defaultOrder.task.route,
            placeholder: 'Route',
          })}
        </Grid>
      ),
    },
    {
      key: 'medication_timing',
      isVisible: !!defaultOrder.task.route.id,
      content: (
        <Grid item xs={9}>
          <OrderTimingField
            value={defaultOrder.task.timing?.raw}
            onChange={handleTimingFieldChange}
            inputRef={setInputRef('medication_timing')}
          />
        </Grid>
      ),
    },
    {
      key: 'medication_added',
      isVisible:
        !!defaultOrder.task.route.id && !!defaultOrder.task.added.length,
      content: (
        <Grid item xs={12}>
          <Grid container className={classes.addedWrapper}>
            {defaultOrder.task.added.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                container
                spacing={1}
                className={classes.addedItem}
              >
                <Grid item xs={9}>
                  {renderAutoComplete({
                    type: 'medication_added_drug',
                    index: index,
                    value: item.drug,
                    placeholder: 'Name',
                    disableFilter: true,
                  })}
                </Grid>
                <Grid item xs={3}>
                  {renderAutoComplete({
                    type: 'medication_added_form',
                    index: index,
                    value: item.form,
                    placeholder: 'Form',
                  })}
                </Grid>
                <Grid item xs={3}>
                  {renderAutoComplete({
                    type: 'medication_added_strength',
                    index: index,
                    value: item.strength,
                    placeholder: 'Strength',
                  })}
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    placeholder='Number/Dose'
                    className={classes.textField}
                    value={item.amount}
                    inputRef={setInputRef('medication_added_amount.' + index)}
                    onChange={handleTextFieldChange(
                      'medication_added_amount',
                      index
                    )}
                    inputProps={{
                      style: {
                        direction: CheckRtl(item.amount) ? 'rtl' : 'ltr',
                      },
                      className: CheckRtl(item.amount) ? 'farsiFont' : '',
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button
                    color='secondary'
                    startIcon={<TrashIcon />}
                    onClick={handleRemoveMedication(index)}
                    className={classes.trashBtn}
                  >
                    Delete
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ),
    },
    {
      key: 'medication_note',
      isVisible: !!defaultOrder.task.route.id,
      content: (
        <Grid item xs={12}>
          <OrderNoteField order={defaultOrder} onChange={onOrderChange} />
        </Grid>
      ),
    },
  ];

  return (
    <Grid container spacing={1}>
      {fields.map((field) => (field.isVisible ? field.content : null))}

      {!!defaultOrder.task.route.id && (
        <Grid item xs={12} className='text-left'>
          <Button
            color='primary'
            startIcon={<AddIcon />}
            onClick={handleAddMedication}
          >
            Add Combination
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default MedicationFormContent;
