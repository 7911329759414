import React, { useMemo } from 'react';

//ui components
import Loader from 'react-loader-spinner';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

//utils
import makeStyles from '@material-ui/styles/makeStyles';
//assets
import ADD_ICON from '../../../Assets/ic_add_dark_24.png';
import ADD_ICON_GREY from '../../../Assets/ic_add_grey.svg';
import Close from '@material-ui/icons/Close';
import clsx from 'clsx';
import { CheckRtl } from '../../../Utilities/CheckLang';

const useStyles = makeStyles({
  container: ({ notEditable }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderBottom: notEditable ? undefined : '1px solid #ccd2e0',
    paddingBottom: '4px',
    marginBottom: '40px',
    cursor: notEditable ? undefined : 'pointer !important',
  }),
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'inherit',
  },
  icon: {
    marginRight: -10,
    width: 40,
    height: 40,
    zIndex: 10,
  },
  title: ({ value }) => ({
    color: value ? '#8b8f99' : '#191e25',
  }),
  value: {
    textAlign: 'auto',
    minHeight: 17,
  },
});

export const InputPlaceholder = ({
  loading,
  hasIcon,
  value,
  notEditable,
  openDialog,
  title,
  removable,
  deleteVariable,
}) => {
  const classes = useStyles({ notEditable, value });

  const handleDelete = (ec) => {
    ec.stopPropagation();
    deleteVariable(true);
  };

  const Icon = useMemo(() => {
    if (hasIcon) {
      if (loading) {
        return (
          <IconButton className={classes.icon} disabled>
            <Loader
              type='Oval'
              color='#00BFFF'
              height={'15px'}
              width={'15px'}
            />
          </IconButton>
        );
      } else {
        return (
          <IconButton
            className={classes.icon}
            onClick={removable && value ? handleDelete : null}
          >
            {removable && value ? (
              <Close fontSize='small' />
            ) : !notEditable ? (
              <img
                alt='icon'
                height={20}
                src={value ? ADD_ICON_GREY : ADD_ICON}
              />
            ) : null}
          </IconButton>
        );
      }
    }
  }, [hasIcon, loading, value, removable]);

  return (
    <div
      className={classes.container}
      onClick={() => !notEditable && openDialog()}
    >
      <div className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
        {Icon}
      </div>
      <Typography
        className={clsx(classes.value, CheckRtl(value) && 'fa-text-body1')}
        style={{ textAlign: CheckRtl(value) ? 'right' : 'left' }}
        dir='auto'
      >
        {value}
      </Typography>
    </div>
  );
};
