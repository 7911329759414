import React, { Component } from 'react';
import { resetPassword } from '../../action/userAction';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { BlueButton } from '../shared';
import { isIOS } from '../../Utilities/navigator';

const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: '1em',
      },
    },
    MuiInputBase: {
      root: {
        // color: "green",
        fontSize: '1.2em',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: '2px solid #EBEBEB',
          borderColor: 'yellow',
        },
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          border: 'none !important',
          // borderColor: "yellow"
        },
        '&:before': {
          // borderBottomColor: 'green',
          border: 'none !important',
        },
        '&:after': {
          // borderBottomColor: 'black',
          border: 'none !important',
        },
        '&:hover': {
          borderBottom: 'none !important',
        },
      },
    },
  },
});
const styles = {
  root: {
    position: 'relative',
    background: 'white',
    boxShadow: 'none',
    color: 'black',
    minWidth: '250px',
  },
  flex: {
    flex: 1,
  },
  justifyAlignCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
const validate = (values) => {
  const errors = {};
  if (!values.pass2) {
    errors.pass2 = 'Required';
  }
  if (!values.pass3) {
    errors.pass3 = 'Required';
  }
  if (!values.pass1) {
    errors.pass1 = 'Required';
  }
  if (values.pass2 !== values.pass3) {
    errors.pass3 = "New Passwords doesn't match";
  }
  return errors;
};

const warn = (values) => {
  const warnings = {};
  return warnings;
};
var defType = null;
const renderField = ({
  input,
  label,
  type,
  classes,
  InputProps,
  meta: { touched, error, warning },
}) => (
  <div>
    <div classes={classes}>
      <TextField
        classes={classes}
        label={label}
        {...input}
        type={defType ? defType : type}
        style={
          touched && error
            ? { border: 'none', borderBottom: 'solid #e54400' }
            : { border: 'none', borderBottom: 'solid #EBEBEB' }
        }
        InputProps={InputProps}
      />
      {touched ? (
        error ? (
          <div
            className='flexC red text-left'
            style={{
              paddingTop: '5px',
              paddingBottom: '7px',
              fontSize: '14px',
            }}
          >
            {error}
          </div>
        ) : warning ? (
          <div>{warning}</div>
        ) : (
          <div style={{ minHeight: '30px', width: '100%' }}></div>
        )
      ) : (
        <div style={{ minHeight: '30px', width: '100%' }}></div>
      )}
    </div>
  </div>
);

class SyncValidationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      password: '',
      weight: '',
      weightRange: '',
      showPassword: false,
    };
    this.height = window.visualViewport.height;
    this.viewport = window.visualViewport;
  }

  resizeHandler = () => {
    const element = document.getElementById('resetPassSubmitBtnContainer');

    let bottom = this.height - this.viewport.height - window.scrollY;
    if (bottom < 0) {
      bottom = 0;
    }

    element.style.bottom = `${bottom}px`;
  };

  componentDidMount() {
    const ios = isIOS();
    if (ios) {
      window.visualViewport.addEventListener('scroll', this.resizeHandler);
      window.visualViewport.addEventListener('resize', this.resizeHandler);
    }
  }

  componentWillUnmount() {
    window.visualViewport.removeEventListener('scroll', this.resizeHandler);
    window.visualViewport.removeEventListener('resize', this.resizeHandler);
  }

  async handleSubmit(values) {
    this.setState({ loader: true });
    const body = {
      old_password: values.pass1,
      new_password: values.pass2,
    };
    const response = await this.props.resetPassword(body);
    if (response) {
      // this.props.history.replace('/setting');
      this.props.history.goBack();
    }
    this.setState({ loader: false });
  }
  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  render() {
    const { handleSubmit, submitting, classes } = this.props;
    return (
      <div className={`dialogContentContainer ${classes.justifyAlignCenter}`}>
        <form onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
          <MuiThemeProvider theme={theme}>
            <Field
              name='pass1'
              type='password'
              component={renderField}
              label='Old Password'
              classes={{ root: classes.root }}
            />

            <Field
              name='pass2'
              type='password'
              component={renderField}
              classes={{ root: classes.root }}
              label='Password'
            />
            <Field
              name='pass3'
              type='password'
              component={renderField}
              classes={{ root: classes.root }}
              label='Re-Enter New Password'
            />
          </MuiThemeProvider>
          <div className='submitBtnContainer' id='resetPassSubmitBtnContainer'>
            <BlueButton
              className='BlueButton'
              text={'SAVE'}
              handleClick={() =>
                this.props.handleSubmit(this.handleSubmit.bind(this))
              }
              loader={this.state.loader}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetPassword }, dispatch);
const mapStateToProps = (state) => {
  return {
    auth: state.authReducer.auth,
  };
};

export default reduxForm({
  form: 'syncValidation', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn, // <--- warning function given to redux-form
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withRouter(SyncValidationForm)))
);
