/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useRef, useMemo, useState, useEffect } from 'react';
import {
  makeStyles,
  withStyles,
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  CheckboxProps,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment-jalaali';
import ReactHtmlParser from 'react-html-parser';
import { ReactComponent as MoreIcon } from '../../../Assets/icons/vuesax_linear_more_grey.svg';
import { ReactComponent as AlertIcon } from '../../../Assets/icons/alert_circle.svg';
import { ICardexGroup, ICardexItem, ITask } from '../../../types/order';
import { stringifyTaskObject } from '../../../Utilities/order';
import { renderAutoDirText, CheckRtl } from '../../../Utilities/CheckLang';
import { getTime } from '../../../Utilities/jalaliDate';
import { getUserFullName } from '../../../Utilities/user';

const CustomCheckbox = withStyles({
  root: {},
  checked: {},
})((props: CheckboxProps) => <Checkbox color='default' {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: '4px 0',

    '&.hoverable': {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
    },

    '&.changed': {
      paddingLeft: 4,

      '&:after': {
        content: '""',
        position: 'absolute',
        top: 4,
        left: 0,
        width: 1,
        height: 'calc(100% - 8px)',
        backgroundColor: theme.palette.success.main,
      },

      '&.has-checkbox': {
        paddingLeft: 0,

        '&:after': {
          left: 26,
        },

        '& $checkboxWrapper': {
          minWidth: 31,
        },
      },
    },

    '&.unchecked, &.new': {
      paddingLeft: 8,

      '&.changed': {
        paddingLeft: 12,

        '&:after': {
          left: 8,
        },

        '&.has-checkbox': {
          paddingLeft: 8,

          '&:after': {
            left: 32,
          },
        },
      },
    },
    '&.new': {
      borderLeft: `1px solid ${theme.palette.primary.main}`,
    },
    '&.unchecked': {
      borderLeft: `1px solid ${theme.palette.primary.dark}`,
    },
  },
  task: {
    color: '#191e25',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    position: 'relative',

    '&.hold, &.dc, &.changed:first-child': {
      color: theme.palette.grey[600],
    },

    '&.dc': {
      textDecorationLine: 'line-through',
    },
  },
  state: {
    padding: '0 12px',
    marginLeft: 8,
    height: 20.8,
    borderRadius: 20,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',

    '&.dc': {
      color: theme.palette.error.main,
      backgroundColor: '#ea1a2323',
    },

    '&.hold': {
      color: theme.palette.warning.main,
      backgroundColor: '#f0991a17',
    },

    '&.changed, &.unhold': {
      color: theme.palette.success.main,
      backgroundColor: '#2bd27c1a',
    },
  },
  label: {
    padding: '0 12px',
    marginLeft: 8,
    height: 20.8,
    borderRadius: 20,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    color: theme.palette.secondary.main,
    backgroundColor: '#8b8f9933',
  },
  primaryBullet: {
    position: 'relative',
    margin: '6.5px 0 0 8px',
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  menuControlWrapper: {
    alignSelf: 'flex-start',
    position: 'relative',
    minHeight: 21,
    minWidth: 30,
  },
  menuControl: {
    position: 'absolute',
    top: -4,
    left: 0,
    marginRight: -3,
  },
  checkboxWrapper: {
    alignSelf: 'flex-start',
    position: 'relative',
    minHeight: 21,
    minWidth: 26,
  },
  checkbox: {
    position: 'absolute',
    bottom: -8,
    left: -11,
    marginRight: -3,
  },
  detail: {
    color: theme.palette.grey[700],
    textAlign: 'left',
  },
  extensionItem: {
    display: 'flex',
    backgroundColor: '#f0f1f7',
    padding: '4px 8px',
    borderRadius: 4,
    marginTop: 4,
  },
  annotation: {
    width: '100%',
  },
}));

export interface ITaskAction {
  label: string;
  handler: (task: ITask) => void;
}

export type CheckboxState = 'unchecked' | 'checked' | 'disabled';
export interface IProps {
  item: ICardexItem;
  group?: ICardexGroup;
  showDetails?: boolean;
  onCheck?: (checked: boolean, task: ITask) => void;
  getCheckboxState?: (item: ICardexItem, group: ICardexGroup) => CheckboxState;
  checkboxProps?: CheckboxProps;
  getTaskActions?: (item: ICardexItem, group?: ICardexGroup) => ITaskAction[];
  showGroup?: boolean;
  savingStatus?: 'error' | 'loading';
  classes?: {
    root?: string;
    task?: string;
    state?: string;
  };
}

const Task: React.FC<IProps> = ({
  item,
  group = 'checked',
  showDetails,
  onCheck = () => {},
  getCheckboxState = () => 'disabled',
  checkboxProps,
  getTaskActions = () => [],
  showGroup,
  savingStatus,
  classes: classesProps,
}) => {
  const classes = useStyles();
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const checkboxState = getCheckboxState(item, group);
  const [openMenu, setOpenMenu] = useState(false);
  const [checked, setChecked] = useState(checkboxState === 'checked');
  const enableCheck = checkboxState !== 'disabled';

  const actions = useMemo(() => getTaskActions(item, group), [
    getTaskActions,
    item,
    group,
  ]);

  useEffect(() => {
    setChecked(checkboxState === 'checked')
  }, [checkboxState])

  const renderTaskActions = () => {
    if (actions.length) {
      return (
        <Box
          className={classes.menuControlWrapper}
          onClick={(e) => e.stopPropagation()}
        >
          {savingStatus === 'loading' ? (
            <CircularProgress size={17} />
          ) : (
            <>
              <IconButton
                ref={anchorEl}
                size='small'
                color={savingStatus === 'error' ? 'secondary' : undefined}
                className={classes.menuControl}
                onClick={triggerMenu(true)}
              >
                {savingStatus === 'error' ? <AlertIcon /> : <MoreIcon />}
              </IconButton>
              <Menu
                keepMounted
                open={openMenu}
                anchorEl={anchorEl.current}
                onClose={triggerMenu(false)}
              >
                {getTaskActions(item, group).map((action, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      action.handler(item.task);
                      triggerMenu(false)();
                    }}
                  >
                    {action.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Box>
      );
    }

    return null;
  };

  const renderTaskContent = () => {
    const stringifyTask = stringifyTaskObject(item.task);
    const stringifyOldTask = stringifyTaskObject(item.task, true);

    return (
      <Box>
        {item.state === 'changed' && (
          <Typography
            className={clsx(
              classes.task,
              classesProps?.task,
              CheckRtl(stringifyOldTask) && 'stringifyOldTask',
              'changed'
            )}
            style={{
              direction: CheckRtl(stringifyOldTask) ? 'rtl' : 'ltr',
            }}
          >
            <span>{ReactHtmlParser(stringifyOldTask)}</span>
          </Typography>
        )}
        <Typography
          component='div'
          className={clsx(
            classes.task,
            classesProps?.task,
            item.state,
            CheckRtl(stringifyTask) && 'farsiFont'
          )}
          style={{
            direction: CheckRtl(stringifyTask) ? 'rtl' : 'ltr',
          }}
        >
          {ReactHtmlParser(stringifyTask)}
        </Typography>
      </Box>
    );
  };

  const renderTaskState = () => {
    if (
      item.state === 'dc' ||
      item.state === 'changed' ||
      item.state === 'hold' ||
      item.state === 'unhold'
    ) {
      return (
        <Box
          className={clsx(
            classes.state,
            classesProps?.state,
            'flex-center',
            item.state
          )}
        >
          <Typography variant='caption'>{item.state}</Typography>
        </Box>
      );
    }

    return null;
  };

  const renderTaskLabel = () => {
    if (item.label === 'finished') {
      return (
        <Box className={clsx(classes.label, 'flex-center')}>
          <Typography variant='caption'>{item.label}</Typography>
        </Box>
      );
    }

    return null;
  };

  const handleCheckClick = (checked: boolean) => {
    onCheck(checked, item.task);
    setChecked(checked);
  };

  const renderTaskCheckbox = () => {
    return enableCheck ? (
      <Box className={classes.checkboxWrapper}>
        <CustomCheckbox
          color='primary'
          size='small'
          checked={checked}
          onChange={(e) => handleCheckClick(e.target.checked)}
          {...checkboxProps}
          classes={{
            ...checkboxProps?.classes,
            root: clsx(classes.checkbox, checkboxProps?.classes?.root),
          }}
        />
      </Box>
    ) : null;
  };

  const renderTaskDetails = () => {
    if (showDetails && item.task.created && item.task.submitter) {
      return (
        <Typography variant='subtitle1' className={classes.detail}>
          from&nbsp;{moment(item.task.created).fromNow()}&nbsp;
          {getTime(item.task.created)}&nbsp;|&nbsp;by&nbsp;
          <span>{renderAutoDirText(getUserFullName(item.task.submitter))}</span>
        </Typography>
      );
    }

    return null;
  };

  const renderTaskTimeNote = () => {
    const timetable = item.task.extensions?.timetable;
    const annotation = item.task.extensions?.annotation;

    return (
      <>
        {!!timetable && (
          <Box className={clsx(classes.extensionItem, 'text-left')}>
            <Typography variant='body2'>Time:</Typography>
            &nbsp;
            <Typography variant='body2' className='mediumWeight'>
              {timetable}
            </Typography>
          </Box>
        )}
        {!!annotation && (
          <Box className={classes.extensionItem}>
            <Typography
              variant='body2'
              className={clsx(
                classes.annotation,
                CheckRtl(annotation) ? 'text-right' : 'text-left'
              )}
            >
              {renderAutoDirText(annotation)}
            </Typography>
          </Box>
        )}
      </>
    );
  };

  const triggerMenu = (open: boolean) => () => {
    if (actions.length) setOpenMenu(open);
  };

  const handleRootBoxClick = () => {
    if (enableCheck) {
      handleCheckClick(!checked);
    } else {
      triggerMenu(true)();
    }
  };

  return (
    <Box
      className={clsx(
        classes.root,
        classesProps?.root,
        item.state,
        showGroup && group,
        actions.length && 'hoverable',
        enableCheck && 'has-checkbox'
      )}
      onClick={handleRootBoxClick}
    >
      <Box display='flex' alignItems='center' width={1}>
        {renderTaskCheckbox()}

        <Box width={1}>
          <Box display='flex' alignItems='start' width={1}>
            {renderTaskContent()}

            {renderTaskState()}

            {renderTaskLabel()}

            {item.isNew && <Box className={classes.primaryBullet} />}
          </Box>
        </Box>

        {renderTaskActions()}
      </Box>

      {renderTaskDetails()}

      {renderTaskTimeNote()}
    </Box>
  );
};

export default Task;
