import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';
import { Grid, withStyles } from '@material-ui/core';
import { BlueButton } from '../shared';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateUserSignature } from '../../action/userAction';
import { addMessage } from '../../action/generalActions';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import Compressor from 'compressorjs';
import SWITCH_CAMERA from '../../Assets/switch-camera.png';
import { isIOS, isSafari } from '../../Utilities/navigator';
import PageHeader from '../PageHeader/PageHeader';

const styles = () => ({
  cameraWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000,

    '& .react-html5-camera-photo': {
      height: 'calc(100% - 56px)',

      '& video': {
        height: '100% !important',
      },
    },
  },
});

const validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];
class UserSignatureUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      cameraCapture: false,
      cameraMode: FACING_MODES.ENVIRONMENT,
      multipleCamera: false,
    };
  }
  componentDidMount() {
    if (navigator.mediaDevices) {
      navigator.mediaDevices.enumerateDevices().then((sources) => {
        var videoSources = sources.filter(
          (source) => source.kind === 'videoinput'
        );
        if (videoSources.length > 1) {
          this.setState({ multipleCamera: true });
        }
      });
    }
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.location.state !== prevProps.location.state) {
  //     if (this.props.location.state?.action) {

  //     }
  //   }
  // }

  setPathAction(action) {
    const { location, history } = this.props;
    if (location.state?.action) {
      if (action) {
        history.replace(location.pathname, { ...location.state, action });
      } else {
        history.replace(location.pathname, { ...location.state, action: '' });
        history.goBack();
      }
    } else {
      if (action) history.push(location.pathname, { action });
    }
  }

  handleFileUpload() {
    document.getElementById('uploadFile').click();
  }

  handleFileChange = (event) => {
    this.setState({ confirmSignatureDescription: true });
    const file = event.target.files[0];
    const fileName = file.name;
    let fileExtensionValid;
    validFileExtensions.forEach((extension) => {
      if (
        fileName
          .substr(fileName.length - extension.length, extension.length)
          .toLowerCase() === extension.toLowerCase()
      ) {
        fileExtensionValid = true;
      }
    });
    if (!fileExtensionValid) {
      this.props.addMessage('Please upload image file', 300);
      return;
    }
    let fileReader = new FileReader();

    fileReader.onload = () => {
      this.setState({ userSignatureURL: fileReader.result });
    };
    fileReader.readAsDataURL(file);
  };

  toggleCameraCapture() {
    const prevAction = this.props.location.state?.action;
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        stream.getTracks().forEach((track) => {
          stream.removeTrack(track);
          track.stop();
        });
      })
      .then(() => {
        this.setPathAction(prevAction === 'showCamera' ? '' : 'showCamera');
        // this.props.history.replace(this.props.match.url, {
        //   action: prevAction === 'showCamera' ? '' : 'showCamera',
        // });
      })
      .catch((err) => {
        console.log(err);
        const response = this.checkIOSProblem();
        if (!response) {
          this.props.addMessage(
            'Please first allow us to access your camera',
            400
          );
        }
      });
  }
  handleTakePhoto = (dataUri) => {
    this.setPathAction('');
    this.setState(() => {
      return {
        userSignatureURL: dataUri,
        cameraCapture: true,
      };
    });
  };

  // async handleSignatureUploadSuccess() {
  //   this.setState({ loading: true, showUserSignatureUploadDialog: false });
  //   const response = await this.props.getUserSignature();
  //   if (response) {
  //     if (response.data) {
  //       let url = URL.createObjectURL(response.data);
  //       this.setState({
  //         userSignatureURL: url,
  //         userSignature: response.data,
  //         cancelButtonText: 'Done',
  //       });
  //     }
  //   }

  //   this.setState({ loading: false });
  // }

  async handleUploadSignature() {
    // let compressionOptions = {
    //   maxSizeMB: 2,
    //   maxIteration: 100,
    //   useWebWorker: true,
    //   onProgress: p => this.onProgress(p, true)
    // };
    this.setState({ uploading: true });
    try {
      const that = this;
      this.cropper.getCroppedCanvas().toBlob(async (blob) => {
        new Compressor(blob, {
          quality: 0.6,
          convertSize: 1000000,
          async success(result) {
            let formData = new FormData();
            formData.append('file', result, result.name);
            let response = await that.props.updateUserSignature(formData);
            that.setState({ uploading: false });
            if (response?.status === 200) {
              // TODO: Go back instead of push
              // that.props.history.push('/setting/profile/signature', {
              //   updateSignature: true,
              // });
              that.props.history.go(-2);
            }
          },
          error(error) {
            that.setState({ uploading: false });
            console.log(error.message);
            that.props.addMessage(error.message, 300);
          },
        });
        // let formData = new FormData();
        //   const compressedFile = await imageCompression(blob, compressionOptions);
        //   formData.append('file', compressedFile);
        //   let response = await this.props.updateUserSignature(formData);
        //   this.setState({ uploading: false });
        //   if (response?.status === 200) {
        //     this.props.handleSignatureUploadSuccess();
        //   }
      });
    } catch (error) {
      this.setState({ uploading: false });
      this.props.addMessage(
        'Failure compressing image, Please try smaller size image',
        300
      );
      console.log('compressionError', error);
    }
  }
  switchCamera() {
    if (navigator.mediaDevices.getSupportedConstraints().facingMode) {
      if (this.state.cameraMode === FACING_MODES.ENVIRONMENT) {
        this.setState({ cameraMode: FACING_MODES.USER });
      } else {
        this.setState({ cameraMode: FACING_MODES.ENVIRONMENT });
      }
    }
  }
  handleCameraError(error) {
    console.log('camera error', error);
    this.checkIOSProblem();
  }
  checkIOSProblem() {
    const ios = isIOS();
    const safari = isSafari();
    if (ios && !safari) {
      this.props.addMessage(
        'Please upload image from your gallery or open inlab with safari to use camera, ios do not support WebRTC from this browser yet!'
      );
      return true;
    }
  }

  render() {
    const { userSignatureURL, cameraMode, multipleCamera } = this.state;
    const { classes } = this.props;
    return (
      <>
        <div className='dialogContentContainer'>
          <div>
            <Grid container>
              <Grid item xs={6}>
                <div className='flex align-items-center justify-content-center'>
                  <div
                    className='upload-button flex-center file '
                    onClick={() => this.handleFileUpload()}
                  >
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                    >
                      <InsertDriveFile />
                    </IconButton>
                    <span className='upload-type'>Upload File</span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className='flex align-items-center justify-content-center'>
                  <div
                    className='upload-button flex-center camera'
                    onClick={() => this.toggleCameraCapture()}
                  >
                    <IconButton
                      color='primary'
                      aria-label='upload picture'
                      component='span'
                      className='icon'
                    >
                      <PhotoCamera className='icon' />
                    </IconButton>
                    <span className='upload-type'>Use Camera</span>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='hidden-input'>
            <input
              id='uploadFile'
              type='file'
              accept='image/*'
              onChange={this.handleFileChange}
            />
          </div>
          <div>
            {userSignatureURL && (
              <Cropper
                style={{
                  height: 'calc(100vh - 205px)',
                  width: '100%',
                  overflow: 'hidden',
                }}
                aspectRatio={4 / 3}
                viewMode={2}
                dragMode={'none'}
                guides={false}
                background={true}
                scalable={false}
                cropBoxResizable={true}
                src={userSignatureURL}
                ref={(cropper) => {
                  this.cropper = cropper;
                }}
              />
            )}
          </div>
          <div className='submitBtnContainer'>
            <Grid container>
              <Grid item xs={5}>
                <BlueButton
                  className='whiteButton'
                  disabled={this.state.uploading}
                  text={'Cancel'}
                  handleClick={() => {
                    this.props.history.goBack();
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <BlueButton
                  className='BlueButton'
                  loader={this.state.uploading}
                  disabled={!userSignatureURL}
                  text={'Upload'}
                  handleClick={() => this.handleUploadSignature()}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        {this.props.location.state?.action === 'showCamera' && (
          <div className={classes.cameraWrapper}>
            <PageHeader
              mobileMedia={true}
              onBackButtonClick={() => this.toggleCameraCapture()}
            />
            <Camera
              isImageMirror={cameraMode === FACING_MODES.USER}
              isDisplayStartCameraError={false}
              idealFacingMode={cameraMode}
              onTakePhoto={(dataUri) => {
                this.handleTakePhoto(dataUri);
              }}
              onCameraError={(error) => {
                this.handleCameraError(error);
              }}
            />
            {multipleCamera ? (
              <div
                className='switch-camrera'
                onClick={() => {
                  this.switchCamera();
                }}
              >
                <img src={SWITCH_CAMERA} alt='switch-camera' />
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateUserSignature, addMessage }, dispatch);
export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(withRouter(UserSignatureUpload)));
