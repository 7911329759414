import React, { useMemo, useEffect } from 'react';
import debounce from 'lodash.debounce';
import {
  makeStyles,
  Box,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import CustomChip from './CustomChip';
import { IProps } from '.';
import clsx from 'clsx';
import MobileAutocompleteOptionsList from './MobileAutocompleteOptionsList';
import { CheckRtl } from '../../Utilities/CheckLang';

const WAIT_INTERVAL = 500;

const useStyles = makeStyles({
  label: {
    color: '#70727a',
    transform: 'translate(0, 30px) scale(1)',
  },
  shrinkLabel: {
    transform: 'translate(0, 1.5px) scale(.85)',
  },
  multiInputRoot: {
    flexWrap: 'wrap',
  },
  inputRoot: {
    '& $input': {
      padding: '9.5px 4px',
      width: 0,
      minWidth: 30,
    },
  },
  input: {
    flexGrow: 1,
    textOverflow: 'ellipsis',
  },
  tag: {
    margin: 3,
    maxWidth: 'calc(100% - 6px)',
  },
  optionsWrapper: {
    backgroundColor: 'white',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    maxHeight: 120,
    overflowY: 'auto',
    padding: '0 16px 10px',
  },
  option: {
    backgroundColor: 'white',
    border: '1px solid #ccd2e066',
    borderRadius: 4,
    boxShadow: '0px 2px 8px 0px #70727a29',
    padding: '8px 12px',
    margin: 8,
    textAlign: 'left',
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const MobileAutocomplete = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  isAsync,
  loading,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onInputChange = () => {},
  textFieldProps,
  getOptionLabel = (o) => o,
  emptyOption,
  value,
  ...props
}: IProps<Multiple, DisableClearable, FreeSolo>) => {
  const classes = useStyles();
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
  } = useAutocomplete({
    openOnFocus: true,
    getOptionLabel: getOptionLabel,
    value: value,
    ...props,
  });

  const debounceChangeHandler = useMemo(
    () =>
      debounce((value: string) => {
        onInputChange(value);
      }, WAIT_INTERVAL),
    []
  );

  useEffect(() => {
    return () => debounceChangeHandler.cancel();
  }, []);

  const changeHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (isAsync) {
      debounceChangeHandler(event.target.value);
    } else {
      onInputChange(event.target.value);
    }
  };

  return (
    <Box {...getRootProps()}>
      {Array.isArray(value) ? (
        <TextField
          fullWidth
          inputProps={{
            className: classes.input,
            onChange: changeHandler,
            onFocus: textFieldProps?.onFocus,
          }}
          InputProps={{
            ...getInputProps(),
            classes: {
              root: clsx(classes.inputRoot, classes.multiInputRoot),
            },
            startAdornment:
              value?.map((option: any, index: number) => (
                <CustomChip
                  key={index}
                  label={getOptionLabel(option)}
                  className={classes.tag}
                  {...getTagProps({ index })}
                />
              )) || null,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
              </>
            ),
          }}
          InputLabelProps={{
            shrink: focused || Boolean(value ? value.length : false),
            classes: { root: classes.label, shrink: classes.shrinkLabel },
          }}
          {...textFieldProps}
        />
      ) : (
        <TextField
          fullWidth
          inputProps={{
            className: classes.input,
            onChange: changeHandler,
            onFocus: textFieldProps?.onFocus,
          }}
          InputProps={{
            ...getInputProps(),
            classes: {
              root: clsx(
                classes.inputRoot,
                CheckRtl(getOptionLabel(value)) && 'rtl'
              ),
            },
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
              </>
            ),
          }}
          InputLabelProps={{
            classes: { root: classes.label, shrink: classes.shrinkLabel },
          }}
          {...textFieldProps}
        />
      )}

      <MobileAutocompleteOptionsList
        options={groupedOptions}
        emptyOption={emptyOption}
        getListboxProps={getListboxProps}
        getOptionProps={getOptionProps}
        getOptionLabel={getOptionLabel}
        // filterOption={(option) =>
        //   Array.isArray(props.value) ? true : props.getOptionSelected ? !props.getOptionSelected(option, props.value ?? {}) : option !== props.value)
        // }
      />
    </Box>
  );
};

export default MobileAutocomplete;
