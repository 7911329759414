import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import toRenderProps from 'recompose/toRenderProps';
import DrawerList from '../../Components/MainDrawer/DrawerList';
import { setSection, addAuthData } from '../../action/actions';

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import './HomeContainer.scss';
import Cookies from 'universal-cookie';
import { messaging } from '../../init-fcm';
import Fingerprint2 from 'fingerprintjs2';
import { handleFlushingDB } from '../../DB/flushDB';
import { addMessage } from '../../action/generalActions';
import {
  addFireBaseToken,
  registerFireBaseToken,
} from '../../action/userAction';
import mainRoutes from '../../routes/main';
import ResponsiveLayout from '../../Components/ResponsiveLayout';

const WithWidth = toRenderProps(withWidth());
const drawerWidth = '30vw';

const styles = (theme) => ({
  '@global': {
    '*': {
      scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
      // width: "0.1em",
      [theme.breakpoints.down('sm')]: {
        width: '3px',
      },
      width: '7px',
      overflow: 'scroll',
      '-webkit-overflow-scrolling': 'touch',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      borderRadius: '5px',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: '#808080',
      borderRadius: '5px',
      // outline: '1px solid slategrey',
    },
  },
  TabRoot: {
    minWidth: '50%',
    textTransform: 'none',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth})`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    overflowY: 'visible',
    top: '0px',
    width: drawerWidth,
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  content: {
    // paddingTop: '56px',
    paddingBottom: '0',
    position: 'relative',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexGrow: 1,
      marginLeft: '30vw',
      // padding: '85px 3.8rem 0 3.8rem' ,
    },
  },
});

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mobileOpen: false,
    };
  }

  async componentDidMount() {
    if (window.env.REACT_APP_DISABLE) {
      this.props.history.push('/update');
    }

    const section = localStorage.getItem('section');
    await this.props.setSection(section || 'ward');

    this.handleWindowHeightProblemInMobile();
    if (JSON.parse(localStorage.getItem('myData'))) {
      const myData = JSON.parse(localStorage.getItem('myData'));
      await this.props.addAuthData(myData);

      //
      const cookies = new Cookies();
      if (!cookies.get('dev_id')) {
        await this.setFingerPrint();
      } else {
        await this.triggerRequestPermission();
      }
    } else {
      this.props.history.push('/');
    }
    if(!localStorage.getItem("namespaceId")){
      this.props.history.push('/namespace');

  }
    handleFlushingDB();
    if (this.props.match?.params?.patientId) {
      this.openNavDrawer();
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.match?.params?.patientId !== this.props.match?.params?.patientId
    ) {
      if (this.props.match?.params?.patientId) {
        this.openNavDrawer();
      } else {
        this.closeNavigationDrawer();
      }
    }
  }
  handleWindowHeightProblemInMobile() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
  closeNavigationDrawer() {
    this.setState({ open: false });
  }
  openNavDrawer() {
    this.setState({ open: true });
  }
  async setFingerPrint() {
    setTimeout(() => {
      var options = {
        NOT_AVAILABLE: 'not available',
        ERROR: 'error',
        EXCLUDED: 'excluded',
        synchronous: true,
      };
      Fingerprint2.getV18(options, async (result) => {
        const cookies = new Cookies();
        cookies.set('dev_id', result, { path: '/' });
        this.triggerRequestPermission();
      });
    }, 500);
  }
  async triggerRequestPermission() {
    if (!messaging) {
      return;
    }
    try {
      const cookies = new Cookies();
      var device_id = cookies.get('dev_id');
      if (Notification.permission !== 'granted') {
        await Notification.requestPermission();
      }
      if ('serviceWorker' in navigator) {
        const that = this;
        navigator.serviceWorker.ready.then(function (registration) {
          // if (!localStorage.getItem('fcm-token')) {
          messaging
            .getToken({
              vapidKey:
                'BBHuJCSAwTxmY1a0ClStBUNwOGi0jTChXr7M6HFFwN953vPi1XWouzs5gmSQH9wNhPlv4LS-lDRCbyzhQ5gw35M',
              serviceWorkerRegistration: registration,
            })
            .then((token) => {
              that.props.addFireBaseToken(token);
              that.props.registerFireBaseToken(token, device_id);
              localStorage.setItem('fcm-token', token);
            })
            .catch((error) => console.log(error));
          // }

          messaging.onMessage((payload) => {
            console.log(payload);
            const options = {
              body: payload.data.body,
              data: payload.data.link,
              icon: '/inlab/assets/images/icons/icon-96x96.png',
              vibrate: [200, 100, 200, 100, 200, 100, 200],
              tag: 'inlab-app-notification',
            };
            const title = payload.data.title;
            registration.showNotification(title, options);
          });
        });
      }
    } catch (error) {
      console.log('On Notification Register Error:');
      console.log(error);
      if ( (
          error.hasOwnProperty('code') &&
          error.code === 'messaging/permission-default'
        ) || Notification.permission !== 'granted'
      ) {
        console.log('You need to allow the site to send notifications');
        this.props.addMessage(
          'You need to allow the site to send notifications',
          300
        );
      } else if (
        error.hasOwnProperty('code') &&
        error.code === 'messaging/permission-blocked'
      ) {
        console.log(
          'Currently, the site is blocked from sending notifications. Please unblock the same in your browser settings'
        );
        this.props.addMessage(
          'For receiving notifications, please unblock sending notification in your browser setting.',
          300
        );
      } else {
        console.log('Unable to subscribe you to notifications');
      }
    }
  }

  render() {
    return (
      <Fragment>
        <CssBaseline />
        <WithWidth>
          {({ width }) => (
            <ResponsiveLayout
              open={this.state.open}
              sidebar={mainRoutes.map((route) =>
                route.component ? (
                  <Route
                    key={route.key}
                    path={route.path}
                    exact={route.exact}
                    render={(matchProps) => (
                      <route.component
                        {...matchProps}
                        {...route.componentProps}
                        routes={route.routes}
                      />
                    )}
                    route={route}
                  />
                ) : null
              )}
              content={
                <DrawerList mobileMedia={width === 'xs' || width === 'sm'} />
              }
            />
          )}
        </WithWidth>
      </Fragment>
    );
  }
}

HomeContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSection,
      addMessage,
      addFireBaseToken,
      registerFireBaseToken,
      addAuthData,
    },
    dispatch
  );
export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withRouter(HomeContainer)));
