import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AxiosResponse } from 'axios';
import { addMessage } from '../../../action/generalActions';
import { getParsedTimingRaw } from '../../../action/order';
import { useLocation, useHistory } from 'react-router-dom';
import usePathAction from '../../../Hook/usePathAction';
import { makeStyles, Box, Button, CircularProgress } from '@material-ui/core';
import { IOrder, ITaskCategory } from '../../../types/order';
import GeneralFormContent from './Forms/General';
import OrderCategory from './Category';
import { AlertDialog } from '../../shared';
import LabFormContent from './Forms/Lab';
import DrawerPage from '../../DrawerPage';
import {
  isValidTask,
  isEmptyTask,
  isEqualOrder,
} from '../../../Utilities/order';
import NursingFormContent from './Forms/Nursing';
import RadiographyFormContent from './Forms/Radiography';
import ActivityFormContent from './Forms/Activity';
import MedicationFormContent from './Forms/Medication';
import OtherFormContent from './Forms/Other';
import { initialOrders } from '../data';
import ConsultFormContent from './Forms/Consult';

const useStyles = makeStyles({
  formContent: {
    padding: '24px 24px 40px',
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    padding: '0 24px 110px',
    width: '100%',
  },
});

interface IProps {
  hideFloatButton?: boolean;
  onSubmit: (newOrder: IOrder) => void;
  addMessage: (message: string, status: number) => void;
  getParsedTimingRaw: (
    raw: string
  ) => Promise<AxiosResponse<{ schedules: string[] }>>;
}

interface ILocationState {
  orderType?: ITaskCategory;
  defaultOrder?: IOrder;
}

const NewOrder: React.FC<IProps> = ({
  hideFloatButton,
  onSubmit,
  addMessage,
  getParsedTimingRaw,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const defaultOrder = location.state?.defaultOrder || null;
  const orderType = location.state?.orderType;
  const [tempOrder, setTempOrder] = useState(
    defaultOrder ? { ...defaultOrder } : null
  );
  const [pathAction, setPathAction] = usePathAction(true, false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingAndAddNew, setIsSubmittingAndAddNew] = useState(false);

  const canSubmitChanges = useMemo(() => {
    if (tempOrder && isValidTask(tempOrder.task)) {
      // Edit Order
      if (defaultOrder && tempOrder)
        return !isEqualOrder(defaultOrder, tempOrder);
      // Add New Order
      if (defaultOrder === null && tempOrder) {
        return !isEmptyTask(tempOrder.task);
      }
    }
    // Wrong Rendering Page
    return false;
  }, [defaultOrder, tempOrder]);

  const showAddNewButton = useMemo(
    () =>
      orderType !== 'impression' &&
      orderType !== 'condition' &&
      orderType !== 'activity' &&
      orderType !== 'diet',
    [orderType]
  );

  useEffect(() => {
    if (defaultOrder) {
      setTempOrder({ ...defaultOrder });
    } else if (orderType && initialOrders(orderType)) {
      setTempOrder(initialOrders(orderType));
    }
  }, [location.state?.orderType, location.state?.defaultOrder]);

  const handleClose = () => {
    history.goBack();
  };

  const updateTimingSchedules = async (order: IOrder) => {
    switch (order.task.category) {
      case 'nursing':
      case 'lab':
      case 'medication':
      case 'radiography':
      case 'consult':
      case 'other': {
        if (order.task.timing) {
          if (order.task.timing.raw) {
            const { schedules } = await (
              await getParsedTimingRaw(order.task.timing.raw)
            ).data;

            return {
              ...order,
              task: {
                ...order.task,
                timing: {
                  ...order.task.timing,
                  schedules,
                },
              },
            };
          }
        }
      }
    }

    return order;
  };

  const modifyTempOrder = async () => {
    let modifiedOrder: null | IOrder | IOrder[] = null;

    if (tempOrder) {
      modifiedOrder = await updateTimingSchedules(tempOrder);

      switch (modifiedOrder.task.category) {
        case 'radiography': {
          // Remove contrasted views that view or contrast not selected.
          modifiedOrder.task.contrasted_views = await modifiedOrder.task.contrasted_views.filter(
            (contrasted_view) =>
              contrasted_view.view.id && contrasted_view.contrast.id
          );
          break;
        }
      }
    }

    return modifiedOrder;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await setIsSubmitting(true);
      const order = await modifyTempOrder();
      if (order) {
        await handleClose();
        onSubmit(order);
      }
    } catch (err) {
      console.log(err);
    }
    setIsSubmitting(false);
  };

  const handleSubmitAndAddNew = async () => {
    try {
      await setIsSubmittingAndAddNew(true);
      const order = await modifyTempOrder();
      if (order && orderType) {
        await onSubmit(order);
        history.replace(location.pathname, {
          orderType,
          defaultOrder: initialOrders(orderType),
        });
        addMessage('Order is added successfully', 300);
      }
    } catch (err) {
      console.log(err);
    }
    setIsSubmittingAndAddNew(false);
  };

  const handleDiscard = (bypassConfirmation?: boolean) => () => {
    if (bypassConfirmation) {
      handleClose();
    } else {
      setPathAction('confirmGoBack');
    }
  };

  const handleOrderChange = (value: IOrder): void => {
    setTempOrder(value);
  };

  function renderFormContent() {
    if (tempOrder) {
      switch (orderType) {
        case 'impression':
        case 'condition':
        case 'diet':
          return (
            <GeneralFormContent
              type={orderType}
              order={tempOrder}
              onOrderChange={handleOrderChange}
            />
          );
        case 'activity':
          return (
            <ActivityFormContent
              defaultOrder={tempOrder}
              onOrderChange={handleOrderChange}
            />
          );
        case 'lab':
          return (
            <LabFormContent
              defaultOrder={tempOrder}
              onOrderChange={handleOrderChange}
            />
          );
        case 'nursing':
          return (
            <NursingFormContent
              defaultOrder={tempOrder}
              onOrderChange={handleOrderChange}
            />
          );
        case 'radiography':
          return (
            <RadiographyFormContent
              defaultOrder={tempOrder}
              onOrderChange={handleOrderChange}
            />
          );
        case 'medication':
          return (
            <MedicationFormContent
              defaultOrder={tempOrder}
              onOrderChange={handleOrderChange}
            />
          );
        case 'consult':
          return (
            <ConsultFormContent
              defaultOrder={tempOrder}
              onOrderChange={handleOrderChange}
            />
          );
        case 'other':
          return (
            <OtherFormContent
              defaultOrder={tempOrder}
              onOrderChange={handleOrderChange}
            />
          );
      }
    }
    return null;
  }

  return (
    <>
      {!hideFloatButton && <OrderCategory />}
      <DrawerPage open={Boolean(orderType)} onClose={handleClose}>
        {orderType && (
          <form onSubmit={handleSubmit}>
            <Box className={classes.formContent}>{renderFormContent()}</Box>

            <Box className={classes.formFooter}>
              <Button
                color='secondary'
                variant='outlined'
                onClick={handleDiscard(!canSubmitChanges)}
                disabled={isSubmitting}
              >
                Discard
              </Button>
              <Box pr={1.5} />
              <Button
                type='submit'
                color='primary'
                variant='outlined'
                disabled={!canSubmitChanges || isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress color='inherit' size={20} />
                ) : (
                  'Save'
                )}
              </Button>
              {showAddNewButton && (
                <>
                  <Box pr={1.5} />
                  <Button
                    color='primary'
                    variant='outlined'
                    disabled={!canSubmitChanges || isSubmittingAndAddNew}
                    onClick={handleSubmitAndAddNew}
                  >
                    {isSubmittingAndAddNew ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : (
                      'Save & add new'
                    )}
                  </Button>
                </>
              )}
            </Box>
          </form>
        )}
        <AlertDialog
          open={pathAction === 'confirmGoBack'}
          alertText='You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?'
          confirmButton={true}
          confirmText='Discard'
          confirmButtonColor='secondary'
          closeText='Cancel'
          handleClose={() => setPathAction('')}
          handleConfirm={handleDiscard(true)}
        />
      </DrawerPage>
    </>
  );
};

export default connect(null, { addMessage, getParsedTimingRaw })(NewOrder);
