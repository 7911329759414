import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import DRAFT_ICON from '../../../Assets/icons/draft_badge.svg';
import REQUEST_ICON from '../../../Assets/icon_sent.svg';
import DELIVERED_ICON from '../../../Assets/icon_delivered.svg';
import SEEN_ICON from '../../../Assets/icons/icon_seen.svg';
import REPLIED_ICON from '../../../Assets/icons/replied_badge.svg';
import { useMemo } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '13px 0px 5px 0px',
  },
  state: {
    maxWidth: 20,
  },
  observedIcon: {
    maxWidth: 20,
  },
  stateBadge: {
    height: 20,
  },
  date: {
    display: 'flex',
    '& p': {
      fontSize: '0.8rem',
    },
    '& p:first-child': {
      color: '#a7a7a7',
      marginRight: 5,
    },
  },
  new: {
    color: theme.palette.warning.dark,
  },
  notReplied: {
    color: theme.palette.primary.main,
  },
  replied: {
    color: theme.palette.success.main,
  },
}));

function getStateIcon(state, classes) {
  switch (state) {
    case 0:
      return (
        <img className={classes.stateBadge} alt='draft' src={DRAFT_ICON} />
      );
    case 1:
      return (
        <img className={classes.state} alt='requested' src={REQUEST_ICON} />
      );
    case 2:
      return (
        <img className={classes.state} alt='delivered' src={DELIVERED_ICON} />
      );
    case 3:
      return (
        <img className={classes.observedIcon} alt='observed' src={SEEN_ICON} />
      );
    case 4:
      return (
        <img className={classes.stateBadge} alt='replied' src={REPLIED_ICON} />
      );
    default:
      break;
  }
}
function getStateConsultantText(state, classes) {
  switch (state) {
    case 1:
    case 2:
    case 3:  // same style for states 1-3; as they have no meaningful difference for users at the client
      return (
        <Typography variant='subtitle2' className={classes.new}>
          New
        </Typography>
      );
    case 4:
      return (
        <Typography variant='subtitle2' className={classes.replied}>
          Replied
        </Typography>
      );
    default:
      break;
  }
}

export default function Status({ sentDate, state, consultantView }) {
  const classes = useStyles();

  const consultState = useMemo(
    () =>
      consultantView
        ? getStateConsultantText(state, classes)
        : getStateIcon(state, classes),
    [state]
  );

  return (
    <div className={classes.container}>
      <div className={classes.date}>
        {state !== 0 && (
          <>
            <Typography variant='subtitle2' color='textSecondary'>
              Sent
            </Typography>
            &nbsp;
            <Typography variant='subtitle2'>{sentDate}</Typography>
          </>
        )}
      </div>
      {consultState}
    </div>
  );
}
