import React from 'react';
import { Box, FormControlLabel, makeStyles } from '@material-ui/core';
import AppCheckbox from '../shared/Inputs/AppCheckbox';

const useStyles = makeStyles({
  root: {
    textAlign: 'left',
    backgroundColor: '#fff',
    borderRadius: 4,
    padding: '0 8px',
  },
});

interface IProps {
  onChange?: (checked: boolean) => void;
}

const PatientsWithUncheckedVisitCheckbox: React.FC<IProps> = ({ onChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <FormControlLabel
        control={
          <AppCheckbox
            onChange={(_, checked) => onChange && onChange(checked)}
          />
        }
        label='Only patients with unchecked visits'
      />
    </Box>
  );
};

export default PatientsWithUncheckedVisitCheckbox;
