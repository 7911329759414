import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { getUserSignature, updateUserSignature } from '../../action/userAction';
import { BlueButton, ContentLoader } from '../shared';
import { Grid } from '@material-ui/core';

class UserSignature extends Component {
  state = {
    loading: true,
    userSignature: null,
    userSignatureURL: null,
    submitButtonText: 'Add Signature',
    cancelButtonText: 'Cancel',
  };
  async componentDidMount() {
    try {
      const response = await this.props.getUserSignature();
      if (response) {
        if (response.data) {
          let url = URL.createObjectURL(response.data);
          this.setState({
            userSignatureURL: url,
            userSignature: response.data,
            submitButtonText: 'Replace Signature',
          });
        }
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({ loading: false });
  }

  render() {
    const {
      userSignature,
      userSignatureURL,
      loading,
      submitButtonText,
      cancelButtonText,
    } = this.state;

    return (
      <div className='dialogContentContainer align-items-center justify-content-center'>
        {loading ? (
          <ContentLoader />
        ) : (
          <div className='signature-container'>
            {userSignature ? (
              <img src={userSignatureURL} alt='signature' />
            ) : (
              <div className='farsiNum rtl text-center'>
                شما عکس مهر و امضای خود را آپلود نکرده اید جهت آپلود روی دکمه
                Add کلیک کنید
              </div>
            )}
          </div>
        )}
        <div className='submitBtnContainer'>
          {!loading && (
            <Grid container>
              <Grid item xs={5}>
                <BlueButton
                  className='whiteButton'
                  loader={this.state.buttonLoader}
                  text={cancelButtonText}
                  handleClick={() => {
                    this.props.history.goBack();
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <BlueButton
                  className='BlueButton'
                  loader={this.state.buttonLoader}
                  text={submitButtonText}
                  handleClick={() => {
                    this.props.history.push(
                      '/setting/profile/signature_description'
                    );
                  }}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUserSignature, updateUserSignature }, dispatch);
const mapStateToProps = (state) => {
  return {
    userSignature: state.userReducer.userSignature,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserSignature));
