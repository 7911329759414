import { useEffect } from 'react';
import { isIOS } from '../Utilities/navigator';

type IElement = HTMLElement | null;

const useFixedBottom = (element: IElement | IElement[], bottom = 0): void => {
  const setElementBottomStyle = (
    el: IElement,
    height: number,
    viewport: VisualViewport
  ) => {
    if (el) {
      let newElementBottom = height - viewport.height + bottom - window.scrollY;
      if (newElementBottom < bottom) newElementBottom = bottom;

      el.style.bottom = `${newElementBottom}px`;
    }
  };

  useEffect(() => {
    const ios = isIOS();

    if (ios && element) {
      const height = document.getElementById('root')?.clientHeight ?? 0;
      const viewport = window.visualViewport;

      const resizeHandler = () => {
        if (viewport) {
          if (Array.isArray(element)) {
            for (const el of element) {
              setElementBottomStyle(el, height, viewport);
            }
          } else {
            setElementBottomStyle(element, height, viewport);
          }
        }
      };

      resizeHandler();

      if (window.visualViewport) {
        window.visualViewport.addEventListener('resize', resizeHandler);
        window.visualViewport.addEventListener('scroll', resizeHandler);
      }

      return () => {
        if (window.visualViewport) {
          window.visualViewport.removeEventListener('resize', resizeHandler);
          window.visualViewport.removeEventListener('scroll', resizeHandler);
        }
      };
    }
  }, [bottom, element]);
};

export default useFixedBottom;
