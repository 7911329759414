import React from 'react';
import { connect } from 'react-redux';
import {
  makeStyles,
  Drawer,
  AppBar,
  Grid,
  Toolbar,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import DownloadIcon from '../../Assets/icon_feather_download.svg';
import TrashIcon from '../../Assets/icon_trash.svg';
import BackArrow from '../../Assets/white_arrow_back-16px.svg';
import { jalaliFullDate } from '../../Utilities/jalaliDate';
import { AlertDialog } from '../shared';
import { downloadFile } from '../../action/generalActions';
import { CheckRtl, renderAutoDirText } from '../../Utilities/CheckLang';
import clsx from 'clsx';
import usePathAction from '../../Hook/usePathAction';
import { getUserFullName } from '../../Utilities/user';

const REACT_APP_HOST_URL = window.env.REACT_APP_HOST_URL ?? '';

const useStyles = makeStyles(() => ({
  rootPaper: {
    backgroundColor: '#000',
    color: '#fff',
    width: '100vw',
  },
  appBar: {
    color: '#fff',
    backgroundColor: '#000',
  },
  imageContainer: {
    width: '100%',
    height: 'calc(100vh - 60px)',
    alignItems: 'center',
    display: 'flex',
    paddingTop: 56,
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  submitter: {
    position: 'fixed',
    bottom: 19,
    right: 24,

    '&.ltr': {
      left: 24,
    },
  },
  dialogAction: {
    justifyContent: 'end',
  },
}));

function ImagePreview({
  open,
  image,
  handleClose,
  handleDelete,
  downloadFile,
}) {
  const classes = useStyles();
  const [pathAction, setPathAction] = usePathAction();

  const handleDownload = () => {
    const now = new Date();
    const day = String(now.getDate());
    const month = String(now.getMonth());
    const year = String(now.getFullYear());
    const hour = String(now.getHours());
    const minute = String(now.getMinutes());
    const second = String(now.getSeconds());
    let fileName = `image_${year}_${month}_${day}_${hour}_${minute}_${second}.jpg`;
    downloadFile(image?.url, fileName);
  };

  const handleDeleteConfirm = () => {
    handleClose();
    handleDelete();
  };

  return (
    <Drawer
      className={classes.root}
      anchor={'right'}
      open={open}
      PaperProps={{ className: classes.rootPaper }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid item xs={6} className='align-items-center'>
              <IconButton
                aria-label='Close'
                className={classes.backArrow}
                onClick={handleClose}
              >
                <img alt='Back' src={BackArrow} />
              </IconButton>
              <Typography variant='h5' className='regularWeight'>
                {jalaliFullDate(image?.submitted)}
              </Typography>
            </Grid>
            <Grid item xs={6} className='text-right'>
              <IconButton
                color='primary'
                aria-label='Download'
                onClick={handleDownload}
              >
                <img alt='Download' src={DownloadIcon} />
              </IconButton>
              <IconButton
                color='secondary'
                aria-label='Delete'
                onClick={() => setPathAction('deleteImageConfirmation')}
              >
                <img alt='Trash' src={TrashIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {image && (
        <Box className={classes.imageContainer}>
          <PinchZoomPan
            style={{ width: 'auto' }}
            doubleTapBehavior='zoom'
            position='center'
            initialScale={1}
            minScale={1}
            maxScale={8}
            zoomButtons={false}
          >
            <img
              className={classes.image}
              alt='preview'
              src={image.url ? REACT_APP_HOST_URL + image.url : null}
            />
          </PinchZoomPan>
        </Box>
      )}

      <Typography
        variant='h5'
        className={clsx(
          classes.submitter,
          'regularWeight',
          CheckRtl(getUserFullName(image?.submitter)) ? '' : 'ltr'
        )}
      >
        {renderAutoDirText(getUserFullName(image?.submitter))}
      </Typography>

      <AlertDialog
        open={pathAction === 'deleteImageConfirmation'}
        handleClose={() => setPathAction('')}
        handleConfirm={handleDeleteConfirm}
        alertText='Are you sure you want to delete this image?'
        dialogActionProps={{
          className: classes.dialogAction,
        }}
        closeText='Cancel'
        confirmButton
        confirmButtonColor='secondary'
        confirmText='Delete'
      />
    </Drawer>
  );
}

export default connect(null, { downloadFile })(ImagePreview);
