import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import store from './store';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';

// Sentry
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

Sentry.init({
  dsn: window.env.REACT_APP_SENTRY_DSN || '',
  beforeSend(event) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception && window.env.REACT_APP_ENABLE_SENTRY_FEEDBACK) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
  integrations: [new Integrations.BrowserTracing()],
  normalizeDepth: 10,
  sampleRate: 1.0,
  tracesSampleRate: 0.5,
});

if(!localStorage.getItem("namespaceId")){
 console.log("not exist")
}

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>

      {process.env.NODE_ENV === 'development' && (
        <div dir='ltr'>
          <ReactQueryDevtools initialIsOpen={false} />
        </div>
      )}
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch({ type: 'SW_INIT' }),
  onUpdate: (reg) => store.dispatch({ type: 'SW_UPDATE', data: reg }),
});
