import React from 'react';
import Profile_Icon from '../../Assets/icons/profile.svg';
import Lab_Icon from '../../Assets/icons/lab.svg';
import Radio_Icon from '../../Assets/icons/radiology.svg';
import Lab_Abnormal_Icon from '../../Assets/ic_lab_abnormal_dark_36.png';
import Lab_Normal_Icon from '../../Assets/ic_lab_normal_dark_36.png';
import Consult_Icon from '../../Assets/icons/consult.svg';
import { Link } from 'react-router-dom';

export const CardOptions = (props) => {
  let labResultImageSrc = Lab_Icon;
  if (props.patient?.lab_tests_result === 'none') {
    labResultImageSrc = Lab_Icon;
  } else if (props.patient?.lab_tests_result === 'normal') {
    labResultImageSrc = Lab_Normal_Icon;
  } else if (props.patient?.lab_tests_result === 'abnormal') {
    labResultImageSrc = Lab_Abnormal_Icon;
  }
  return (
    <div className='IconContainer'>
      <div className='EachIcon'>
        <Link
          to={{
            pathname: `/patient/${props.patient.profile_id}/laboratory`,
            // search: `/${patient.profile_id}`,
            state: {
              id: props.patient.profile_id,
              title: 'laboratory',
              cardName: props.cardName,
            },
          }}
        >
          <img
            alt='lab icon'
            className='PatientCardIcon'
            height={35}
            src={labResultImageSrc}
          />
        </Link>
      </div>
      <div className='EachIcon'>
        <Link
          to={{
            pathname: `/patient/${props.patient.profile_id}/radiology`,
            state: {
              id: props.patient.profile_id,
              title: 'radiology',
            },
          }}
        >
          <img
            alt='radio icon'
            className='PatientCardIcon'
            height={35}
            src={Radio_Icon}
            // onClick={() => props.pageChanged('radio')}
          />
        </Link>
      </div>

      <div className='EachIcon'>
        <Link
          to={{
            pathname: `/patient/${props.patient.profile_id}/consult`,
            state: {
              id: props.patient.profile_id,
              cardName: props.cardName,
            },
          }}
        >
          <img
            alt='consult icon'
            className='PatientCardIcon'
            height={25}
            src={Consult_Icon}
          />
        </Link>
      </div>
      <div className='EachIcon'>
        <Link
          to={{
            pathname: `/patient/${props.patient.profile_id}/profile`,
            state: {
              id: props.patient.profile_id,
            },
          }}
        >
          <img
            alt='profile icon'
            className='PatientCardIcon'
            height={35}
            src={Profile_Icon}
          />
        </Link>
      </div>
    </div>
  );
};
export default CardOptions;
