import React, { useRef, useState } from 'react';
import { IOrder, isActivityTask } from '../../../../types/order';
import { useQuery } from 'react-query';
import { getTerms } from '../../../../api/medicalTerms';
import { Grid } from '@material-ui/core';
import ResponsiveAutocomplete from '../../../ResponsiveAutocomplete';
import OrderNoteField from '../Fields/Note';

type ActivityFieldTypes = 'activity' | 'position';

interface IProps {
  defaultOrder: IOrder;
  onOrderChange: (order: IOrder) => void;
}

const ActivityFormContent: React.FC<IProps> = ({
  defaultOrder,
  onOrderChange,
}) => {
  const positionRef = useRef<HTMLInputElement | undefined>();
  const [currentType, setCurrentType] = useState<ActivityFieldTypes>(
    'activity'
  );
  const [searchQuery, setSearchQuery] = useState('');

  const { data, isFetching } = useQuery(
    [
      'medical_terms',
      {
        type: currentType,
        q: searchQuery,
      },
    ],
    () => getTerms(currentType, { q: searchQuery || undefined })
  );

  const handleInputChange = (type: ActivityFieldTypes) => async (
    value: string
  ) => {
    await setCurrentType(type);
    setSearchQuery(value);
  };

  const handleInputFocus = (type: ActivityFieldTypes) => async () => {
    console.log('focused', type);
    await setCurrentType(type);
    setSearchQuery('');
  };

  const handleFieldChange = (type: ActivityFieldTypes) => async (
    _: any,
    value: any
  ) => {
    let newTask = { ...defaultOrder.task };
    if (isActivityTask(newTask)) {
      switch (type) {
        case 'activity': {
          newTask = {
            ...newTask,
            activity: value ? { id: value.id, title_en: value.title_en } : {},
          };
          break;
        }
        case 'position': {
          newTask = {
            ...newTask,
            positions: value
              ? value.map((val: { id: number; title_en: string }) => ({
                  id: val.id,
                  title_en: val.title_en,
                }))
              : [],
          };
          break;
        }
      }
      await onOrderChange({ ...defaultOrder, task: newTask });
      if (value?.id && type === 'activity') {
        positionRef.current && positionRef.current.focus();
      }
    }
  };

  if (!isActivityTask(defaultOrder.task)) return null;

  const fields = [
    {
      isVisible: true,
      content: (
        <Grid item xs={4} md={2}>
          <ResponsiveAutocomplete
            isAsync
            loading={isFetching && currentType === 'activity'}
            options={data?.data.results ?? []}
            filterOptions={(options) => options}
            getOptionLabel={(o) => o.title_en || ''}
            getOptionSelected={(o, v) => o?.id === v?.id}
            value={defaultOrder.task.activity}
            onChange={handleFieldChange('activity')}
            onInputChange={handleInputChange('activity')}
            textFieldProps={{
              autoFocus: true,
              placeholder: 'Activity',
              onFocus: handleInputFocus('activity'),
            }}
          />
        </Grid>
      ),
    },
    {
      isVisible: !!defaultOrder.task.activity.id,
      content: (
        <Grid item xs={8} md={10}>
          <ResponsiveAutocomplete
            isAsync
            multiple
            disableCloseOnSelect
            loading={isFetching && currentType === 'position'}
            options={data?.data.results ?? []}
            filterOptions={(options) => options}
            getOptionLabel={(o) => o?.title_en || ''}
            getOptionSelected={(o, v) => o?.id === v?.id}
            value={defaultOrder.task.positions}
            onChange={handleFieldChange('position')}
            onInputChange={handleInputChange('position')}
            textFieldProps={{
              placeholder: 'Position (optional)',
              inputRef: positionRef,
              onFocus: handleInputFocus('position'),
            }}
          />
        </Grid>
      ),
    },
    {
      isVisible: !!defaultOrder.task.activity.id,
      content: (
        <Grid item xs={12}>
          <OrderNoteField order={defaultOrder} onChange={onOrderChange} />
        </Grid>
      ),
    },
  ];

  return (
    <Grid container spacing={1}>
      {fields.map((field) => (field.isVisible ? field.content : null))}
    </Grid>
  );
};

export default ActivityFormContent;
