import React, {
  useMemo,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import PageWrapper from '../../../../PageWrapper';
import SearchField from '../../../../shared/Inputs/Search';
import VisitTemplateCard from './Card';
import VisitTemplateHeaderTabs, {
  CONTENT_TYPES,
  ITabValue,
} from './HeaderTabs';
import PageHeader from '../../../../PageHeader/PageHeader';
import { useInfiniteQuery } from 'react-query';
import { getAllTaskTemplates } from '../../../../../api/order';
import InfiniteScroll from '../../../../InfiniteScroll';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  topStickyWrapper: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#f0f1f7',
  },
  wrapper: {
    backgroundColor: '#f0f1f7',
    flex: 1,
  },
}));

export type RefreshHandle = {
  refreshTemplates: () => void;
};

const VisitTemplatesList = forwardRef<RefreshHandle>(({}, ref) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ contentType?: ITabValue }>();
  const { patientId, visitId } = useParams<{
    patientId: string;
    visitId?: string;
  }>();
  const [searchQuery, setSearchQuery] = useState<string>();

  const currentType = useMemo(
    () =>
      CONTENT_TYPES.find(
        (item) => item.value === location.state?.contentType
      ) || CONTENT_TYPES[0],
    [location.state?.contentType]
  );

  const TEMPLATE_PAGINATION_LIMIT = 25;

  const {
    data,
    fetchNextPage,
    isLoading,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    ['task_templates', { author: currentType.value, q: searchQuery }],
    ({ pageParam = 1 }) =>
      getAllTaskTemplates(
        (pageParam - 1) * TEMPLATE_PAGINATION_LIMIT,
        TEMPLATE_PAGINATION_LIMIT,
        {
          author: currentType.value,
          q: searchQuery,
        }
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.data.results.length !== 0 ? nextPage : undefined;
      },
    }
  );

  useImperativeHandle(ref, () => ({
    refreshTemplates() {
      refetch();
    },
  }));

  const handleCardClick = (templateId: number) => () => {
    history.push(
      visitId
        ? `/patient/${patientId}/order/visit/${visitId}/template/${templateId}`
        : `/patient/${patientId}/order/create-visit/template/${templateId}`
    );
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSearchQuery(value || undefined);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.topStickyWrapper}>
        <PageHeader
          desktopBackButton
          showPatientInfo={false}
          title='Visit templates'
        />

        <Box px={3} py={2}>
          <Box mb={2}>
            <VisitTemplateHeaderTabs value={currentType.value} />
          </Box>

          <SearchField
            async
            placeholder='Search template name'
            onChange={handleSearchInputChange}
          />
        </Box>
      </Box>

      <PageWrapper loading={isLoading} className={classes.wrapper}>
        <Box px={3}>
          <InfiniteScroll hasNextPage={hasNextPage} onFetchMore={fetchNextPage}>
            {data?.pages.map((page) =>
              page.data.results.map((template) => (
                <Box key={template.id} mb={1.5}>
                  <VisitTemplateCard
                    template={template}
                    onClick={handleCardClick(template.id)}
                  />
                </Box>
              ))
            )}
          </InfiniteScroll>
        </Box>
      </PageWrapper>
    </Box>
  );
});

export default VisitTemplatesList;
