import Dexie from 'dexie';

const db = new Dexie('inLab');
db.version(5).stores({
  patient: 'id, date',
  pinnedWardPatient: 'id, date',
  pinnedShiftPatient: 'id, date',
  pinnedProfileImage: 'id, profile_id, date',
});

export default db;

export const setPatientDB = async (id, profile, labResult, radiologyResult) => {
  return await db
    .table('patient')
    .put({
      radiologyResult: radiologyResult ? radiologyResult : '',
      labResult: labResult ? labResult : '',
      profile: profile ? profile : '',
      date: new Date(),
      id: id,
    })
    .then((result) => result);
};

export const getPatientDB = async (id) => {
  return await db
    .table('patient')
    .get(id)
    .then((result) => result);
};

export const replacePinnedPatient = async (id, patient, section) => {
  if (section === 'shift') {
    const patientDB = await db
      .table('pinnedShiftPatient')
      .get(id)
      .then((res) => res);
    if (!patientDB) {
      return;
    }
    const newDBItem = {
      ...patientDB,
      patient: patient,
    };
    await db
      .table('pinnedShiftPatient')
      .put(newDBItem)
      .then((res) => res);
  } else {
    const patientDB = await db
      .table('pinnedWardPatient')
      .get(id)
      .then((res) => res);
    if (!patientDB) {
      return;
    }
    const newDBItem = {
      ...patientDB,
      patient: patient,
    };
    await db
      .table('pinnedWardPatient')
      .put(newDBItem)
      .then((res) => res);
  }
};
export const setPinnedPatientDB = async (patient, id, section) => {
  if (section === 'shift') {
    return await db
      .table('pinnedShiftPatient')
      .put({
        patient,
        id,
        date: new Date(),
      })
      .then((result) => result);
  } else {
    return await db
      .table('pinnedWardPatient')
      .put({
        patient,
        id,
        date: new Date(),
      })
      .then((result) => result);
  }
};
export const unsetPinnedPatientDB = async (patientId, section) => {
  if (section === 'shift') {
    return await db
      .table('pinnedShiftPatient')
      .delete(patientId)
      .then((res) => res);
  } else {
    return await db
      .table('pinnedWardPatient')
      .delete(patientId)
      .then((res) => res);
  }
};

export const getPinnedPatientsDbBySection = async (section) => {
  // return await db.table('pinnedPatient').where('section').equals(section).toArray().then(res => res);
  if (section === 'shift') {
    return await db
      .table('pinnedShiftPatient')
      .reverse()
      .sortBy('date')
      .then((res) => res);
  } else {
    return await db
      .table('pinnedWardPatient')
      .reverse()
      .sortBy('date')
      .then((res) => res);
  }
};

export const getPinnedPatientsDB = async () => {
  return await db.table('pinnedPatient').toArray();
};

export const removeInaccessiblePinnedPatientsDB = async (inaccessibleIds) => {
  await db
    .table('pinnedShiftPatient')
    .bulkDelete(inaccessibleIds)
    .then((res) => res);
  return await db
    .table('pinnedWardPatient')
    .bulkDelete(inaccessibleIds)
    .then((res) => res);
};

export const getPinnedProfileImagesDB = async (profileId) =>
  await db.pinnedProfileImage
    .where({ profile_id: String(profileId) })
    .toArray()
    .then((res) => res);

export const setPinnedProfileImageDB = async (image, profileId, blobImage) =>
  await db
    .table('pinnedProfileImage')
    .put({
      ...image,
      profile_id: profileId,
      blob: blobImage,
      date: new Date(),
    })
    .then((result) => result);

export const unsetPinnedProfileImageDB = async (id) =>
  await db.pinnedProfileImage.delete(id).then((result) => result);
