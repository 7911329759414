import React, { useState } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import PageWrapper from '../../../PageWrapper';
import CardexState from '../State';
import CardexList from '../List';
import { useParams } from 'react-router-dom';
import useFixedBottom from '../../../../Hook/useFixedBottom';
import {
  ICardex,
  ICardexItem,
  ITask,
  ITaskNoticeLabel,
  IVisit,
} from '../../../../types/order';
import { CheckboxState } from '../Task';
import {
  getCategory,
  updateCardexItemLabel,
} from '../../../../Utilities/order';
import { createTaskNotice } from '../../../../api/order';
import { addMessage } from '../../../../action/generalActions';
import { ReactComponent as CloseIcon } from '../../../../Assets/icon_close.svg';

const useStyles = makeStyles((theme) => ({
  headerRoot: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    minHeight: 53,
    padding: '0 24px',
    borderBottom: '1px solid #ccccd2e0',
    zIndex: 20,
  },
  headerLabel: {
    marginTop: 4,
  },
  floatingBtn: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    padding: 16,
    width: '100%',

    [theme.breakpoints.up('md')]: {
      marginLeft: '30vw',
      width: '70vw',
    },
  },
}));

interface IProps {
  headVisit: IVisit | null;
  cardex: ICardex;
  uncheckedVisitsCount?: number;
  setCardex: (cardex: ICardex) => void;
  onClose?: () => void;
  checkPermissions?: () => boolean;
  addMessage: (textMessage: string, status: number) => void;
}

const NursingMultiFinish: React.FC<IProps> = ({
  headVisit,
  cardex,
  setCardex,
  addMessage,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  checkPermissions = () => false,
}) => {
  const classes = useStyles();
  const { patientId } = useParams<{ patientId: string }>();
  const [floatBtnRef, setFloatBtnRef] = useState<HTMLElement | null>(null);
  const [finishedTasks, setFinishedTasks] = useState<ITask[]>([]);
  const [pending, setPending] = useState(false);

  useFixedBottom(floatBtnRef, 0);

  const getCheckboxState = (item: ICardexItem, _: any): CheckboxState => {
    const category = getCategory(item.task);
    let state: CheckboxState = 'disabled';

    if (item.state !== 'hold' && category !== 'general') {
      state = 'unchecked';
    }

    return state;
  };

  const handleCheckTask = (checked: boolean, task: ITask) => {
    if (task.uuid) {
      if (checked) {
        setFinishedTasks([...finishedTasks, task]);
      } else {
        setFinishedTasks((tasks) => tasks.filter((t) => t.uuid !== task.uuid));
      }
    }
  };

  const handleSubmit = async () => {
    if (checkPermissions()) {
      if (finishedTasks.length) {
        await setPending(true);

        const requestData = finishedTasks.reduce<{
          [uuid: string]: {
            label: ITaskNoticeLabel;
          };
        }>(
          (prev, curr) =>
            curr.uuid
              ? { ...prev, [curr.uuid]: { label: 'finished' } }
              : { ...prev },
          {}
        );

        await createTaskNotice(patientId, requestData)
          .then(onClose)
          .catch((err: any) => console.log(err));

        let newCardex = { ...cardex };
        for (const task of finishedTasks) {
          newCardex = await updateCardexItemLabel({
            cardex: newCardex,
            task,
            label: 'finished',
          });
        }

        setCardex({ ...newCardex });
        addMessage('Tasks have been finished successfully!', 200);
        setPending(false);
      } else {
        onClose();
      }
    }
  };

  return (
    <>
      <Box className={classes.headerRoot}>
        <IconButton onClick={onClose}>
          <CloseIcon style={{ transform: 'scale(1.4)' }} />
        </IconButton>

        <Typography variant='h6' className={classes.headerLabel}>
          {finishedTasks.length} tasks selected
        </Typography>
      </Box>

      <PageWrapper>
        <Box px={3} py={2}>
          <Box mb={1}>
            <CardexState
              label='Last revised by'
              user={headVisit?.signers[0]}
              datetime={headVisit?.modified}
            />
            {!!headVisit?.checker?.id && (
              <CardexState
                label='Checked by'
                user={headVisit?.checker}
                datetime={headVisit?.checked}
              />
            )}
          </Box>

          <Box mb={6}>
            <CardexList
              withGroups
              defaultCardex={cardex}
              taskProps={{
                onCheck: handleCheckTask,
                getCheckboxState,
              }}
            />
          </Box>

          <Box
            {...{ ref: (el: any) => setFloatBtnRef(el) }}
            className={classes.floatingBtn}
          >
            <Button
              fullWidth
              variant='contained'
              color='primary'
              disabled={pending}
              onClick={handleSubmit}
            >
              {pending ? <CircularProgress size={22} /> : 'Mark as finished'}
            </Button>
          </Box>
        </Box>
      </PageWrapper>
    </>
  );
};

export default connect(
  (state: RootStateOrAny) => ({
    headVisit: state.order.headVisit,
  }),
  { addMessage }
)(NursingMultiFinish);
