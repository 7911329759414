import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/analytics';
import 'firebase/compat/auth';
let messaging;
if (firebase.messaging.isSupported()) {
  if (!firebase.apps.length) {
    const initializedFirebaseApp = firebase.initializeApp({
      apiKey: 'AIzaSyAfnOBK6JHsp9SSdyhx4h84cFq3VZBkks0',
      authDomain: 'inlab-project.firebaseapp.com',
      databaseURL: 'https://inlab-project.firebaseio.com',
      projectId: 'inlab-project',
      storageBucket: 'inlab-project.appspot.com',
      messagingSenderId: '2389489938',
      appId: '1:2389489938:web:63f05949bcde8f60',
      measurementId: 'G-MREJC9FHT8',
    });
    messaging = initializedFirebaseApp.messaging();
    firebase.analytics();
  } else {
    messaging = firebase.messaging();
  }
}

export { messaging };
