import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import { Snackbar, SnackbarContent, Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { removeSnackData } from '../../action/actions';
import { bindActionCreators } from 'redux';
import store from '../../store';
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  rootContent: {
    padding: '0px',
  },
  messageContent: {
    fontSize: '0.9em',
  },
  actionContent: {
    paddingLeft: '0px',
  },
});

class MySnackbarContent extends Component {
  render() {
    const {
      classes,
      className,
      message,
      onClose,
      variant,
      ...other
    } = this.props;
    const Icon = variantIcon[variant];

    return (
      <SnackbarContent
        classes={{
          root: classes.rootContent,
          message: classes.messageContent,
          action: classes.actionContent,
        }}
        className={classNames(classes[variant], className)}
        aria-describedby='client-snackbar'
        message={
          <span id='client-snackbar' className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            className={classes.close}
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    );
  }
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = (theme) => ({
  root: {
    height: '60px',
  },
});

class CustomizedSnackbars extends React.Component {
  state = {
    open: false,
  };

  componentWillReceiveProps() {
    this.setState({ open: true });
  }

  handleClick = () => {
    this.setState({ open: true, message: this.props.snackBarMessage });
  };

  async handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    await this.props.removeSnackData();
    store.dispatch({ type: 'SW_CLEAR' });
    this.setState({ open: false });
  }
  handleUpdate() {
    const registrationWaiting = this.props.serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }

  render() {
    const {
      classes,
      snackBarStatus,
      snackBarMessage,
      serviceWorkerInitialized,
      serviceWorkerUpdated,
    } = this.props;

    return (
      <div>
        {snackBarMessage ? (
          <Snackbar
            classes={{ root: classes.root }}
            anchorOrigin={{
              vertical: this.props.snackBarPosition
                ? this.props.snackBarPosition
                : 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open}
            autoHideDuration={6000}
            onClose={(event, reason) => this.handleClose(event, reason)}
          >
            <MySnackbarContentWrapper
              onClose={(event, reason) => this.handleClose(event, reason)}
              variant={
                snackBarStatus === 200
                  ? 'success'
                  : snackBarStatus < 400
                  ? 'info'
                  : 'error'
              }
              message={snackBarMessage}
            />
          </Snackbar>
        ) : serviceWorkerInitialized ? (
          <Snackbar
            classes={{ root: classes.root }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open}
            autoHideDuration={6000}
            onClose={(event, reason) => this.handleClose(event, reason)}
          >
            <MySnackbarContentWrapper
              onClose={(event, reason) => this.handleClose(event, reason)}
              variant={'success'}
              message={'Page has been saved for offline use'}
            />
          </Snackbar>
        ) : serviceWorkerUpdated ? (
          <Snackbar
            classes={{ root: classes.root }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={this.state.open}
            onClose={(event, reason) => this.handleClose(event, reason)}
          >
            <SnackbarContent
              classes={{
                root: classes.rootContent,
                message: classes.messageContent,
                action: classes.actionContent,
              }}
              className={classNames(classes['success'])}
              aria-describedby='client-snackbar'
              message={
                <span id='client-snackbar' className={classes.message}>
                  New Version Available
                </span>
              }
              action={[
                <Fragment>
                  <Button color='primary' onClick={() => this.handleUpdate()}>
                    UPDATE
                  </Button>
                  <Button
                    color='secondary'
                    onClick={(event, reason) => this.handleClose(event, reason)}
                  >
                    DISMISS
                  </Button>
                </Fragment>,
              ]}
            />
          </Snackbar>
        ) : (
          ''
        )}
      </div>
    );
  }
}

CustomizedSnackbars.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    snackBarMessage: state.snackBarReducer.snackBarMessage,
    snackBarStatus: state.snackBarReducer.snackBarStatus,
    snackBarPosition: state.snackBarReducer.snackBarPosition,
    serviceWorkerInitialized: state.generalReducers.serviceWorkerInitialized,
    serviceWorkerUpdated: state.generalReducers.serviceWorkerUpdated,
    serviceWorkerRegistration: state.generalReducers.serviceWorkerRegistration,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ removeSnackData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles2)(CustomizedSnackbars));
