import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OTHER from '../../../../Assets/icons/other.svg';

function OtherSectionInSetting() {
  const history = useHistory();

  return (
    <div
      className='setting__section'
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <div className='setting__section__title'>
        <img className='mr-2' alt='Sections' src={OTHER} height={21} />
        Other
      </div>
      <div
        className='setting__section__element lastElement'
        onClick={() => {
          history.push('/setting/other/contact');
        }}
      >
        Contact Us
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    snackBarMessage: state.snackBarReducer.snackBarMessage,
  };
};
export default connect(mapStateToProps)(OtherSectionInSetting);
