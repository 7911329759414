import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import EMPTY_CARDEX_VECTOR from '../../Assets/vectors/empty.svg';

const useStyles = makeStyles({
  root: {
    padding: '8px 0',
  },
  defaultLoader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 8,
  },
});

type IProps = IntersectionObserverInit & {
  children?: ReactNode;
  className?: string;
  totalLength?: number;
  hasNextPage?: boolean;
  loading?: boolean;
  emptyContent?: ReactNode;
  onFetchMore?: () => Promise<any>;
};

const InfiniteScroll: React.FC<IProps> = ({
  children,
  className,
  totalLength = 0,
  hasNextPage,
  loading,
  emptyContent,
  threshold,
  root,
  rootMargin,
  onFetchMore,
}) => {
  const classes = useStyles();
  const lastElementRef = useRef<HTMLDivElement>(null);
  const [isFetching, setIsFetching] = useState(!!loading);

  const canFetchMore = useMemo(
    () => hasNextPage ?? React.Children.count(children) < totalLength,
    [hasNextPage, totalLength, children]
  );

  useEffect(() => {
    setIsFetching(!!loading);
  }, [loading]);

  const handleObserveCallback = async (
    entries: IntersectionObserverEntry[]
  ) => {
    for (const entry of entries) {
      if (entry.isIntersecting && canFetchMore && !isFetching) {
        if (onFetchMore) {
          if (loading === undefined) await setIsFetching(true);
          try {
            await onFetchMore();
          } catch (err) {
            console.log(err);
          }
          if (loading === undefined) setIsFetching(false);
        }
      }
    }
  };

  useEffect(() => {
    const lastElement = lastElementRef?.current;

    if (lastElement) {
      const observerOptions = { threshold, root, rootMargin };
      const observer = new IntersectionObserver(
        handleObserveCallback,
        observerOptions
      );

      observer.observe(lastElement);

      return () => observer.disconnect();
    }
  }, [lastElementRef, threshold, root, rootMargin]);

  const EmptyContent = (
    <Box m='auto' width={1} maxWidth='500px' textAlign='center' py={4}>
      <img src={EMPTY_CARDEX_VECTOR} height={120} alt='no visit' />
      <Typography color='textSecondary'>There is no item</Typography>
    </Box>
  );

  return (
    <Box className={clsx(classes.root, className)}>
      {React.Children.count(children) ? children : emptyContent ?? EmptyContent}
      {isFetching && (
        <Box className={classes.defaultLoader}>
          <CircularProgress size={22} />
          <Typography>Loading ...</Typography>
        </Box>
      )}
      <div ref={lastElementRef} />
    </Box>
  );
};

export default InfiniteScroll;
