import React from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import AppCheckbox from '../shared/Inputs/AppCheckbox';

const useStyles = makeStyles({
  root: {
    textAlign: 'left',
    backgroundColor: '#fff',
    borderRadius: 4,
    padding: '0 8px',
  },
});

interface IProps {
  responsibility_services: Array<Record<string, unknown>>, onClick : () => void;
}

const GoToResponsibilityServicesSettingBanner: React.FC<IProps> = ({ responsibility_services, onClick }) => {
  const classes = useStyles();

  const servicehayeStr = `سرویس${responsibility_services?.length > 1 ? '‌های' : ''}`;
  const serviceNamesStr = responsibility_services?.map(s => typeof s.name === 'string' ? s.name.toString() : '-').join('، ');
  const responsibilityServicesInfo = responsibility_services?.length > 0 ? (
    <>شما برای مشاوره {servicehayeStr} <b>{serviceNamesStr}</b> نوتیف دریافت می‌کنید.</>
  ) : (
    <>شما برای هیچ سرویسی نوتیف دریافت نمی‌کنید.</>
  );

  return (
    <Box p={2} pb={1} pt={0} className='farsiNum rtl text-right' onClick={onClick}>
      <Typography variant='body2' className='farsiFont'>
        {responsibilityServicesInfo}
        &nbsp;
        {
          <span style={{color: '#386fff'}}  // TODO: define proper css class reuse attributes
          >
            تغییر
          </span>
        }
      </Typography>
    </Box>
  );
};

export default GoToResponsibilityServicesSettingBanner;
