import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import { IHistoryItem } from '../../../../../types/order';
import { stringifyTaskObject } from '../../../../../Utilities/order';
import clsx from 'clsx';
import { ReactComponent as MoreIcon } from '../../../../../Assets/icons/vuesax_linear_more_grey.svg';
import { ReactComponent as AlertIcon } from '../../../../../Assets/icons/alert_circle.svg';
import { CheckRtl } from '../../../../../Utilities/CheckLang';
import moment from 'moment-jalaali';
import { getTime } from '../../../../../Utilities/jalaliDate';
import { IPublicUser } from '../../../../../types/user';
import useAccess from '../../../../../Hook/useAccess';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../../../action/generalActions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    padding: '4px 0',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
    },
  },
  task: {
    color: '#191e25',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    whiteSpace: 'break-spaces',
    textAlign: 'left',
    position: 'relative',
  },
  state: {
    padding: '0 12px',
    marginLeft: 8,
    height: 20.8,
    borderRadius: 20,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',

    '&.dc': {
      color: theme.palette.error.main,
      backgroundColor: '#ea1a2323',
    },

    '&.changed': {
      color: theme.palette.success.main,
      backgroundColor: '#2bd27c1a',
    },

    '&.finished': {
      color: theme.palette.secondary.main,
      backgroundColor: '#8b8f9933',
    },
  },
  menuControlWrapper: {
    alignSelf: 'flex-start',
    position: 'relative',
    minHeight: 21,
    minWidth: 30,
  },
  menuControl: {
    position: 'absolute',
    top: -4,
    left: 0,
    marginRight: -3,
  },
  detail: {
    color: theme.palette.grey[700],
    textAlign: 'left',
    marginTop: 2,

    '&:first-child': {
      marginTop: 4,
    },
  },
}));

interface IProps {
  item: IHistoryItem;
  onMarkAsUnfinished?: (uuid: string) => Promise<any>;
}

const FinishedItem: React.FC<IProps> = ({ item, onMarkAsUnfinished }) => {
  const classes = useStyles();
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const { data: nurseVisitOperatorAccess } = useAccess('nurse_visit_operator');

  const triggerMenu = (open: boolean) => () => {
    setOpenMenu(open);
  };

  const renderTaskStates = () => {
    const showState = item.state === 'dc' || item.state === 'changed';
    const isFinished = item.type === 'finished';

    return (
      <>
        {showState && (
          <Box className={clsx(classes.state, item.state)}>
            <Typography variant='caption'>{item.state}</Typography>
          </Box>
        )}
        {isFinished && (
          <Box className={clsx(classes.state, 'finished')}>
            <Typography variant='caption'>finished</Typography>
          </Box>
        )}
      </>
    );
  };

  const onMarkAsUnfinishedClicked = async () => {
    triggerMenu(false)();
    if (nurseVisitOperatorAccess) {
      if (item.task.uuid && onMarkAsUnfinished) {
        await setLoading(true);
        await setError(null);

        await onMarkAsUnfinished(item.task.uuid).catch((err) => {
          console.log(err);
          setError(err);
        });

        setLoading(false);
      }
    } else {
      dispatch(addMessage('You need permission to do that!', 300));
    }
  };

  const renderActionMenu = () => {
    return item.type === 'finished' ? (
      <Box
        className={classes.menuControlWrapper}
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <Box width='30px' height='30px' className='flex-center'>
            <CircularProgress size={17} />
          </Box>
        ) : (
          <>
            <IconButton
              ref={anchorEl}
              size='small'
              color={error ? 'secondary' : undefined}
              className={classes.menuControl}
              onClick={triggerMenu(true)}
            >
              {error ? <AlertIcon /> : <MoreIcon />}
            </IconButton>
            <Menu
              keepMounted
              open={openMenu}
              anchorEl={anchorEl.current}
              onClose={triggerMenu(false)}
            >
              <MenuItem onClick={onMarkAsUnfinishedClicked}>
                Mark as unfinished
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
    ) : null;
  };

  const renderDetails = () => {
    const Detail: React.FC<{
      label: string;
      datetime?: string | null;
      user?: Pick<IPublicUser, 'first_name' | 'last_name'> | null;
    }> = ({ label, datetime, user }) => (
      <Typography variant='subtitle1' className={classes.detail}>
        {label}&nbsp;{datetime && moment(datetime).fromNow()}&nbsp;
        {datetime && getTime(datetime)}&nbsp;|&nbsp;by&nbsp;
        <span className={CheckRtl(user?.first_name) ? 'farsiFont' : ''}>
          {user?.first_name}&nbsp;{user?.last_name}
        </span>
      </Typography>
    );

    return (
      <Box>
        <Detail
          label='Created'
          datetime={item.task.created}
          user={item.task.submitter}
        />
        {!!item.changed && (
          <Detail label='Changed' datetime={item.changed} user={item.changer} />
        )}
        {item.type === 'finished' && (
          <Detail
            label='Finished'
            datetime={item.notified}
            user={item.notifier}
          />
        )}
      </Box>
    );
  };

  return (
    <Box className={classes.root} onClick={triggerMenu(true)}>
      <Box display='flex' alignItems='center' width={1}>
        <Box width={1}>
          <Box display='flex' alignItems='start' width={1}>
            <Typography component='div' className={classes.task}>
              {ReactHtmlParser(stringifyTaskObject(item.task))}
            </Typography>

            {renderTaskStates()}
          </Box>
        </Box>

        {renderActionMenu()}
      </Box>

      {renderDetails()}
    </Box>
  );
};

export default FinishedItem;
