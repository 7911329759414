import React, { useState, useEffect, useMemo } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import {
  getVisit,
  getRecentVisits,
  updateRecentVisit,
  deleteVisit,
  signVisit,
  getDraftVisit,
  createDraftVisit,
  setDraftVisit,
  getCardexAppendix,
} from '../../../action/order';
import {
  useParams,
  useHistory,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { getUserId } from '../../../Utilities/storage';
import {
  IOrder,
  IVisit,
  IVisitSummery,
  IDraftVisit,
} from '../../../types/order';
import { Box, IconButton, Button, CircularProgress } from '@material-ui/core';
import PageHeader from '../../PageHeader/PageHeader';
import PageWrapper from '../../PageWrapper';
import CardexState from '../Cardex/State';
import CardexList from '../Cardex/List';
import CardexForm from '../Cardex/Form';
import { AlertDialog } from '../../shared';
import usePathAction from '../../../Hook/usePathAction';
import useAccess from '../../../Hook/useAccess';
import { jalaliFullDate } from '../../../Utilities/jalaliDate';
import { getPatientProfile } from '../../../action/profile';
import { ReactComponent as DeleteIcon } from '../../../Assets/icon_trash_red.svg';
import PhysicianOrdersPrint from '../Print';
import VisitDescription from './Description';
import VisitTemplates from '../Template/Visit';

const descriptions = [
  'دستوراتی که به دسته بندی های زیر مربوط نمیشوند را میتوانید در دسته Other قرار دهید.',
];

interface IProps {
  relatedDraft: IDraftVisit | null;
  visit: IVisit | null;
  parentVisit: IVisit | null;
  canEditVisit: boolean;
  getPatientProfile: (patientId: string, useCacheData?: boolean) => any;
  getVisit: (visitId: number, withParent: true) => any;
  getVisits: (profileId: number) => any;
  updateRecentVisit: (
    visitId: number,
    orders: IOrder[],
    bySiblingDraft?: number
  ) => Promise<AxiosResponse<IVisit>>;
  deleteVisit: (visitId: number) => any;
  signVisit: (visitId: number) => any;
  addMessage: (message: string, status: number) => void;
  getDraftVisit: (draftId: number) => Promise<any>;
  createDraftVisit: (data: any) => any;
  setDraftVisit: (draftVisit: IDraftVisit | null) => any;
  getCardexAppendix: (patientId: string) => any;
}

const VisitView: React.FC<IProps> = ({
  relatedDraft,
  visit,
  parentVisit,
  canEditVisit,
  getPatientProfile,
  getVisit,
  getVisits,
  updateRecentVisit,
  deleteVisit,
  signVisit,
  getDraftVisit,
  createDraftVisit,
  setDraftVisit,
  getCardexAppendix,
}) => {
  const history = useHistory<any>();
  const location = useLocation<any>();
  const { patientId, visitId } = useParams<{
    patientId: string;
    visitId: string;
  }>();
  const {
    data: physicianVisitOperatorAccess,
    isLoading: isLoadingPhysicianVisitOperatorAccess,
  } = useAccess('physician_visit_operator');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const [pending, setPending] = useState(false);
  const [pathAction, setPathAction] = usePathAction();

  const isEditMode = useMemo(() => {
    const match = matchPath(location.pathname, {
      path: '/patient/:patientId/order/visit/:visitId/edit',
    });
    return match !== null;
  }, [location.pathname]);

  if (
    isEditMode &&
    !isLoadingPhysicianVisitOperatorAccess &&
    !physicianVisitOperatorAccess
  ) {
    history.replace(`/patient/${patientId}/order`);
  }

  const pageTitle = useMemo(
    () =>
      isEditMode
        ? 'Edit Visit'
        : `visit ${visit?.modified ? jalaliFullDate(visit.modified) : ''}`,
    [visit, isEditMode]
  );

  const fetchPageData = async () => {
    await setLoading(true);
    await setError(null);

    await getVisits(parseInt(patientId)).catch((err: AxiosError) => {
      console.log(err);
      setError(err);
    });

    await getVisit(parseInt(visitId), true).catch((err: AxiosError) => {
      console.log(err);
      setError(err);
    });

    if (isEditMode) {
      const relatedDraftId = parseInt(location.state?.relatedDraftId);
      if (relatedDraftId) {
        await getDraftVisit(relatedDraftId).catch(async (err: AxiosError) => {
          console.log(err);
          await setError(err);
        });
      } else {
        await setDraftVisit(null);
      }

      await getCardexAppendix(patientId).catch((err: AxiosError) => {
        console.log(err);
        setError(err);
      });
    } else {
      await getPatientProfile(patientId, true).catch((err: AxiosError) => {
        console.log(err);
        setError(err);
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (
      !isEditMode ||
      (!isLoadingPhysicianVisitOperatorAccess && physicianVisitOperatorAccess)
    ) {
      fetchPageData();
    }
  }, [
    isEditMode,
    patientId,
    visitId,
    isLoadingPhysicianVisitOperatorAccess,
    physicianVisitOperatorAccess,
  ]);

  useEffect(() => {
    if (!loading) {
      history.replace(location.pathname, {
        ...(location.state || {}),
        relatedDraftId: relatedDraft?.id,
      });
    }
  }, [relatedDraft]);

  const handleCreateRelatedDraft = (orders: IOrder[]) => {
    return createDraftVisit({
      profile_id: patientId,
      purpose: 'edit',
      parent: visit?.parent ?? null,
      orders,
    });
  };

  const handleSubmitChanges = (orders: IOrder[]) => {
    return updateRecentVisit(parseInt(visitId), orders, relatedDraft?.id).then(
      (res) => {
        if (res.data.id) history.goBack();
        return Promise.resolve(res);
      }
    );
  };

  const handleDiscard = () => {
    history.goBack();
  };

  const canSignVisit = useMemo(() => {
    const userId = getUserId();
    if (!userId || isEditMode) return false;
    return !visit?.signers.some((signer) => signer.id === userId);
  }, [visit, isEditMode]);

  const handleSignVisit = async () => {
    if (!canSignVisit) return;
    await setPending(true);
    await signVisit(parseInt(visitId)).catch((err: any) => {
      console.log(err);
    });
    await setPending(false);
  };

  const handleDeleteVisit = async (bypassConfirmation?: boolean) => {
    if (bypassConfirmation) {
      try {
        setPathAction('');
        await deleteVisit(parseInt(visitId));
        history.goBack();
      } catch (e) {
        console.log(e);
      }
    } else {
      setPathAction('openDeleteConfirmation');
    }
  };

  const handleCreateTemplateBtnClick = () => {
    history.push(
      `/patient/${patientId}/order/visit/${visitId}/create-template`
    );
  };

  return (
    <>
      <PageHeader
        desktopBackButton
        showPatientInfo={false}
        title={pageTitle}
        optionComponent={
          loading ? null : isEditMode ? (
            canEditVisit &&
            physicianVisitOperatorAccess && (
              <Box mr={-1.5}>
                <IconButton
                  color='secondary'
                  onClick={() => handleDeleteVisit()}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )
          ) : (
            <Box mr={-1.5}>
              <PhysicianOrdersPrint visits={visit?.id ? [visit.id] : []} />
            </Box>
          )
        }
      />
      <PageWrapper loading={loading} error={error} onReload={fetchPageData}>
        <Box px={3} pt={1} pb={3}>
          {isEditMode && (
            <Box mb={1}>
              <VisitDescription descriptions={descriptions} />
            </Box>
          )}

          <CardexState label='Last revised by' user={visit?.signers[0]} />

          {visit?.signers.map((signer) => (
            <CardexState key={signer.id} label='Signed by' user={signer} />
          ))}

          {!!visit?.checker?.id && (
            <CardexState
              label='Checked by'
              user={visit?.checker}
              datetime={visit?.checked}
            />
          )}

          {isEditMode ? (
            <CardexForm
              purpose='edit'
              visit={parentVisit}
              defaultOrders={relatedDraft?.orders ?? visit?.orders}
              relatedDraft={relatedDraft}
              createRelatedDraft={handleCreateRelatedDraft}
              onSubmit={handleSubmitChanges}
              onDiscard={handleDiscard}
            />
          ) : (
            <>
              <CardexList
                withNewGroups
                detailedView
                mode='visit'
                visit={visit}
                parentVisit={parentVisit}
              />

              <Box pt={4}>
                <Button
                  fullWidth
                  variant='outlined'
                  color='primary'
                  onClick={handleCreateTemplateBtnClick}
                >
                  Add this visit to templates
                </Button>

                <VisitTemplates />
              </Box>
            </>
          )}

          {physicianVisitOperatorAccess && canSignVisit && (
            <Box pt={2}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                className='mediumPadding'
                disabled={pending}
                onClick={handleSignVisit}
              >
                {pending ? <CircularProgress size={22} /> : 'Sign'}
              </Button>
            </Box>
          )}
        </Box>

        <AlertDialog
          open={pathAction === 'openDeleteConfirmation'}
          alertText='Are you sure you want to delete this visit?'
          confirmButton={true}
          confirmText='Delete'
          confirmButtonColor='secondary'
          closeText='Cancel'
          handleClose={() => setPathAction('')}
          handleConfirm={() => handleDeleteVisit(true)}
        />
      </PageWrapper>
    </>
  );
};

export default connect(
  (state: RootStateOrAny) => {
    const currentVisit = state.order.visit;
    const visits: IVisitSummery[] = state.order.visits ?? [];
    const isChecked = !!currentVisit?.checker?.id;

    const canEditVisit =
      !isChecked &&
      visits.slice(0).sort((a, b) => b.id - a.id)[0]?.id === currentVisit?.id;

    return {
      relatedDraft: state.order.draftVisit,
      visit: state.order.visit,
      parentVisit: state.order.parentVisit,
      canEditVisit,
    };
  },
  {
    getPatientProfile,
    getVisits: (profileId: number) => getRecentVisits(profileId, 10, 'all'),
    getVisit,
    updateRecentVisit,
    deleteVisit,
    signVisit,
    getDraftVisit,
    createDraftVisit,
    setDraftVisit,
    getCardexAppendix,
  }
)(VisitView);
