import * as API from '../api/generalAPI';
import * as agentAPI from '../api/agent';
import { GET_CONFIGURATION } from './actionTypes';

export const getConfiguration = () => (dispatch) => {
  return agentAPI.getConfiguration().then(async (res) => {
    await dispatch({
      type: GET_CONFIGURATION,
      data: res.data,
    });
    return Promise.resolve(res);
  });
};

export const getAllShifts = () => async () => {
  return API.getAllShifts().then(async (response) => {
    return response.data?.shifts;
  });
};
export const getAllRotation = () => async () => {
  return API.getAllRotation().then(async (response) => {
    return response.data?.rotations;
  });
};
export const getAllWards = () => async () => {
  return API.getAllWards().then(async (response) => {
    return response.data.wards;
  });
};
export const getAllServices = () => async (dispatch) => {
  return API.getAllServices().then(async (response) => {
    return dispatch({
      type: 'GET_SERVICES',
      data: response.data.services,
    });
  });
};
export const getUsersByService = (serviceId) => async (dispatch) => {
  return API.getUsersByService(serviceId).then(async (response) => {
    return dispatch({
      type: 'GET_USERS_BY_SERVICE',
      data: response.data.users,
    });
  });
};
export const addMessage = (textMessage, status) => async (dispatch) => {
  dispatch({
    type: 'ADD_MESSAGE',
    message: textMessage,
    status,
  });
};

export const downloadFile = (url, fileName) => async () =>
  API.convertUrlToBlob(url).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  });
