import React from 'react';
import { useMediaQuery, useTheme, TextFieldProps } from '@material-ui/core';
import CustomAutocomplete from './CustomAutocomplete';
import MobileAutocomplete from './MobileAutocomplete';
import { AutocompleteProps } from '@material-ui/lab';


export type IProps< 
  Multiple extends boolean | undefined, 
  DisableClearable extends boolean | undefined, 
  FreeSolo extends boolean | undefined
  > =  Omit<AutocompleteProps<any, Multiple, DisableClearable, FreeSolo>, 'onInputChange'|'renderInput'> & {
    isAsync?:boolean;
    onInputChange?: (value: string) => void;
    emptyOption?: any,
    textFieldProps?: TextFieldProps;
  };

const ResponsiveAutocomplete = <
  T, 
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
  >({ options, ...rest }:IProps<Multiple, DisableClearable, FreeSolo>) => {
  const theme = useTheme();
  const isMobileMedia = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });

  return isMobileMedia ? (
    <MobileAutocomplete
      options={rest.isAsync && rest.loading ? [] : options}
      {...rest}
    />
  ) : (
    <CustomAutocomplete
      options={rest.isAsync && rest.loading ? [] : options}
      {...rest}
    />
  );
};

export default ResponsiveAutocomplete;
