import React from 'react';
import { AbstractAuthWall, styles } from './AbstractAuthWall';
import { resetPassword, demandVerification } from '../../action/userAction';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';

class PasswordChanged extends AbstractAuthWall {

  async handleSubmit(values) {
      this.props.history.replace('/login');
  }
  
  formButtons() {
    return (
      <button type='submit' className='AuthWallMainButton'>
        Go to login page
      </button>
    );
  }
}

const validate = (values) => {
    const errors = {};
    return errors;
  };
  
  const warn = (values) => {
    const warnings = {};
    return warnings;
  };
  
const mapStateToProps = (state) => {
    return {
        auth: state.authReducer.auth,
        title: 'Password changed successfully!',
        hideUsernameField: true,
        hidePasswordField: true,
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ resetPassword, demandVerification }, dispatch);
  
  
export default reduxForm({
  form: 'passwordChangedDummyForm', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  warn, // <--- warning function given to redux-form
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PasswordChanged)));
