import db from './db';

export const handleFlushingDB = () => {
  var lastFlushTime = localStorage.getItem('lastFlushTime');

  if (lastFlushTime) {
    let diffTime = Math.abs(new Date() - new Date(lastFlushTime));
    let diffDate = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDate > 1) {
      flushDB();
      localStorage.setItem('lastFlushTime', new Date());
    }
  } else {
    flushDB();
    localStorage.setItem('lastFlushTime', new Date());
  }
};

function flushDB() {
  var TODAY = new Date();
  var threeDaysBefore = new Date(TODAY.getTime() - 3 * 24 * 60 * 60 * 1000);
  db.table('patient').where('date').belowOrEqual(threeDaysBefore).delete();
}

export const dropIndexedDB = async () => {
  await db.delete().then(() => {
    db.open();
  });
};
