import React from 'react';
import REFRESH_ICON from '../../Assets/icon_refresh.svg';
import { makeStyles } from '@material-ui/core/styles';
const styles = makeStyles({
  reloadPageContainer: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexFlow: 'column',
    cursor: 'pointer',
    width: '100%',
    fontSize: '.9em',
    color: '#A7A7A7',
  },
  reloadPageImg: {
    padding: '5px',
  },
  reloadWholePage: {
    height: '67vh',
  },
});
const ReloadPage = ({ reloadPage, wholePage, text }) => {
  const classes = styles();
  return (
    <div
      className={`${classes.reloadPageContainer} ${
        wholePage ? classes.reloadWholePage : ''
      }`}
    >
      {text ? (
        <div>{text}</div>
      ) : (
        <>
          <div>You&apos;re using inLab in offline mode. </div>
          <div> Check your internet connection or</div>
          <div>try again</div>
        </>
      )}
      <img
        className={classes.reloadPageImg}
        width='55'
        src={REFRESH_ICON}
        alt='refresh page'
        onClick={() => reloadPage()}
      />
    </div>
  );
};
export default ReloadPage;
