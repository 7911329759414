import React from 'react';

export const CheckRtl = (character, defaultDirection = 'ltr') => {
  let farsiRegex = /^[\u0600-\u06FF\s]+$/;
  //   var RTL = [ 'ا', 'ب', 'پ', 'ت', 'س', 'ج', 'چ', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'ژ', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ک', 'گ', 'ل', 'م', 'ن', 'و', 'ه', 'ی' ];
  if (character) {
    if (character.length > 1) {
      return farsiRegex.test(character[0]);
    } else {
      return farsiRegex.test(character);
    }
  } else {
    return defaultDirection === 'rtl';
  }
};

export const renderAutoDirText = (
  text,
  options = {
    rtlClassName: 'rtl farsiFont',
    ltrClassName: '',
    defaultDirection: 'ltr',
  }
) => {
  const result = [];

  if (text && typeof text === 'string') {
    let swap = '';
    for (const char of text) {
      if (
        CheckRtl(char, options.defaultDirection) ===
        CheckRtl(swap, options.defaultDirection)
      ) {
        swap += char;
      } else {
        result.push(swap);
        swap = char;
      }
    }
    result.push(swap);

    return React.createElement(
      'span',
      {},
      result.map((txt) =>
        React.createElement(
          'span',
          {
            className: CheckRtl(txt, options.defaultDirection)
              ? options.rtlClassName
              : options.ltrClassName,
          },
          txt
        )
      )
    );
  }

  return text;
};

export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';
