import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CheckRtl } from '../../../Utilities/CheckLang';
import { jalaliFullDateTime } from '../../../Utilities/jalaliDate';
import ResponsiveDrawer from '../../ResponsiveDrawer/ResponsiveDrawer';
import './RadioResultDetail.css';
class RadioResultDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    if (!this.props.location.state) this.props.history.replace('/patient');
    else {
      this.setState({
        radio: this.props.location.state.radio,
      });
    }
  }
  createMarkup(title) {
    if (title) {
      title = title.replace(/(?:\r\n|\r|\n|↵)/g, '<br />');
      return { __html: `${title}` };
    } else return { __html: '' };
  }
  render() {
    const { radio } = this.state;
    const fullName =
      this.props.profile?.first_name + ' ' + this.props.profile?.last_name;
    return (
      <ResponsiveDrawer title={fullName} handleClose={() => {}}>
        <div className='farsiNum'>
          {radio ? (
            <div className='flexC'>
              <div className='RadioDetailTitleAndDateContainer'>
                <div className='float-left'>{radio.service_name}</div>
                <div
                  style={{ fontFamily: 'heebo' }}
                  className='flex float-right'
                >
                  {jalaliFullDateTime(radio.service_datetime)}
                </div>
              </div>
              <div
                className={`RadioDetailService rtl ${
                  radio.title && CheckRtl(radio.title[0])
                    ? 'farsiNum rtl'
                    : 'ltr'
                }`}
                dangerouslySetInnerHTML={this.createMarkup(radio.title)}
              />
              <div
                className={`RadioDetailReport rtl ${
                  radio.report && CheckRtl(radio.report[0])
                    ? 'farsiNum rtl'
                    : 'rtl'
                }`}
                dangerouslySetInnerHTML={this.createMarkup(radio.report)}
              />
            </div>
          ) : null}
        </div>
      </ResponsiveDrawer>
    );
  }
}
export default connect((state) => ({
  profile: state.patientsReducer.patient?.profile,
}))(RadioResultDetail);
