import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { getConfiguration } from '../action/generalActions';
import { getUserCurrentData } from '../action/userAction';

type IAccess =
  | 'can_search_dismissed_patients'
  | 'can_manage_patients'
  | 'nurse_visit_operator'
  | 'physician_visit_operator'
  | 'standalone_mode';

type InputType = IAccess | IAccess[];
type AccessesType<T> = T extends IAccess[] ? boolean[] : boolean;
type ReturnType<T> = {
  data: AccessesType<T>;
  isLoading: boolean;
};

const useAccess = <T extends InputType>(access: T): ReturnType<T> => {
  const [isLoading, setIsLoading] = useState(true);
  const rank = useSelector(
    (state: RootStateOrAny) => state.userReducer?.userData?.rank
  );
  const config = useSelector(
    (state: RootStateOrAny) => state.generalReducers?.config
  );
  const dispatch = useDispatch();

  const getAccessData = async () => {
    try {
      if (rank === undefined) {
        await dispatch(getUserCurrentData());
      }
      if (config === undefined) {
        await dispatch(getConfiguration());
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getAccessData();
  }, [rank, config]);

  const hasAccess = (a: IAccess) => {
    return (
      (rank === undefined ? false : !!rank[a]) ||
      (config === undefined ? false : !!config[a])
    );
  };

  const data = Array.isArray(access)
    ? <AccessesType<T>>access.map((a) => hasAccess(a))
    : <AccessesType<T>>hasAccess(access);

  return { data, isLoading };
};

export default useAccess;
