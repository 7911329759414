var moment = require('moment-jalaali');

export const jalaliYear = (date) => {
  if (!date) {
    return;
  }
  return moment(date, 'YYYY-MM-DD').format('jYY');
};
export const jalaliMonth = (date) => {
  if (!date) {
    return;
  }
  return moment(date, 'YYYY-MM-DD').format('jM');
};
export const jalaliDay = (date) => {
  if (!date) {
    return;
  }
  return moment(date, 'YYYY-MM-DD').format('jD');
};

export const persianMonthName = (date) => {
  if (!date) {
    return;
  }
  return getPersianMonthName(+moment(date, 'YYYY-MM-DD').format('jM'));
};
export const jalaliFullDateTime = (date) => {
  if (!date) {
    return;
  }
  if (!date) {
    return;
  }
  return moment(date).format('jYY/jM/jD HH:mm');
};
export const jalaliFullDateWithMonthName = (date) => {
  if (!date) {
    return;
  }
  return (
    jalaliDay(date) + ' ' + persianMonthName(date) + ' ' + jalaliYear(date)
  );
};
export const jalaliFullDate = (date) => {
  if (!date) {
    return;
  }
  return moment(date, 'YYYY-MM-DD').format('jYY/jM/jD');
};

export const getAgeWithUnit = (date) => {
  if (!date) {
    return;
  }
  if (moment(new Date()).diff(date, 'years') > 0) {
    return { age: moment(new Date()).diff(date, 'years'), unit: '' };
  } else if (moment(new Date()).diff(date, 'months') > 0) {
    return { age: moment(new Date()).diff(date, 'months'), unit: 'months' };
  } else if (moment(new Date()).diff(date, 'days') > 0) {
    return { age: moment(new Date()).diff(date, 'days'), unit: 'days' };
  }
};
export const getTime = (date) => {
  if (!date) {
    return;
  }
  return moment(date).format('HH:mm');
};

function getPersianMonthName(monthNum) {
  switch (monthNum) {
    case 1:
      return 'فروردین';
    case 2:
      return 'اردیبهشت';
    case 3:
      return 'خرداد';
    case 4:
      return 'تیر';
    case 5:
      return 'مرداد';
    case 6:
      return 'شهریور';
    case 7:
      return 'مهر';
    case 8:
      return 'آبان';
    case 9:
      return 'آذر';
    case 10:
      return 'دی';
    case 11:
      return 'بهمن';
    case 12:
      return 'اسفند';
    default:
      return '';
  }
}
export const getFullDate = (date, isJalali = true) => {
  if (!date) {
    return;
  }
  if (isJalali) {
    return moment(date).format('jYYYY/jMM/jDD');
  } else {
    return moment(date).format('YYYY/MM/DD');
  }
};
