import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles, Box, Typography, IconButton } from '@material-ui/core';
import ADD_ICON from '../../../../Assets/icons/add_primary.svg';
import { ICategory } from '../../../../types/order';

const useStyles = makeStyles({
  root: {
    width: '100%',

    '&:first-child $header': {
      marginTop: 0,
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: '18px 0 4px',
  },
  addBtnWrapper: {
    position: 'relative',
    minWidth: 30,
    minHeight: 30,
  },
  addBtn: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
interface IProps {
  label: string;
  withAddButton?: boolean;
  children?: React.ReactNode;
  id?: ICategory;
  [x: string]: any;
}

const CardexItem: React.FC<IProps> = ({
  label,
  children,
  withAddButton = false,
  id,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const openNewOrderPage = () => {
    history.push(location.pathname, { orderType: id });
  };

  return (
    <Box className={classes.root} id={id}>
      <Box className={classes.header}>
        <Typography
          variant='h4'
          color='primary'
          onClick={withAddButton ? openNewOrderPage : undefined}
        >
          {label}
        </Typography>
        <Box className={classes.addBtnWrapper}>
          {withAddButton && (
            <IconButton onClick={openNewOrderPage} className={classes.addBtn}>
              <img src={ADD_ICON} alt='add order' />
            </IconButton>
          )}
        </Box>
      </Box>
      {children && <Box>{children}</Box>}
    </Box>
  );
};

export default CardexItem;
