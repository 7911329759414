import React from 'react';
import { Drawer, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(({ breakpoints: { between } }) => ({
  container: {},
  paper: {
    border: 'none',
    boxShadow: 'none',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    direction: 'rtl',
    marginLeft: '30vw',
    width: '70vw',
    overflow: 'hidden',
    [between('xs', 'sm')]: {
      width: '100vw',
      paddingTop: '10px',
      marginLeft: 'unset',
      marginRight: 'unset',
    },
  },
  button: {
    width: '100%',
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #ebebeb',
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '25px',
    cursor: 'pointer',
    userSelect: 'none',
  },
  submit: {
    color: '#386fff',
  },
  discard: {
    color: '#ea2323',
  },
  cancel: {
    color: '#8b8f99',
  },
}));

export function DiscardModal({
  open,
  handleClose,
  handleDiscard,
  handleSubmit,
  discardText = 'Discard',
  cancelText = 'Cancel',
  submitText,
}) {
  const classes = useStyles();

  return (
    <Drawer
      classes={{ paperAnchorBottom: classes.paper }}
      anchor='bottom'
      open={open}
      onClose={handleClose}
    >
      <div className={classes.container}>
        {submitText && (
          <div
            className={`${classes.button} ${classes.submit}`}
            onClick={handleSubmit}
          >
            {submitText}
          </div>
        )}
        <div
          className={`${classes.button} ${classes.discard}`}
          onClick={handleDiscard}
        >
          {discardText}
        </div>
        <div
          className={`${classes.button} ${classes.cancel}`}
          onClick={handleClose}
        >
          {cancelText}
        </div>
      </div>
    </Drawer>
  );
}
