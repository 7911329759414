import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  List,
  ListItem,
  Paper,
  Popper,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import getSuggestions from './timing-parser';
import { makeStyles, useTheme } from '@material-ui/styles';
import useOutsideClick from '../../../../Hook/useOutsideClick';
import MobileAutocompleteOptionsList from '../../../ResponsiveAutocomplete/MobileAutocompleteOptionsList';

const useOptionsListStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: 1300,
  },
  paper: {
    margin: '4px 0',
    overflow: 'hidden',
    ...theme.typography.body1,
  },
  tempDiv: {
    width: 'auto',
    display: 'inline-block',
    visibility: 'hidden',
    position: 'fixed',
    overflow: 'auto',
  },
  listbox: {
    margin: 0,
    padding: '8px 0',
    overflow: 'auto',
    listStyle: 'none',
    maxHeight: '40vh',
  },
  option: {
    cursor: 'pointer',
    display: 'flex',
    outline: 0,
    boxSizing: 'border-box',
    minHeight: 48,
    alignItems: 'center',
    padding: '6px 16px',
    justifyContent: 'flex-start',

    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },

    [theme.breakpoints.up('sm')]: {
      minHeight: 'auto',
    },
  },
}));

interface OptionsListProps {
  open?: boolean;
  value?: any;
  options: any[];
  anchorEl?: HTMLElement;
  onOptionClick?: (option: any) => void;
}

// eslint-disable-next-line react/display-name
const OptionsList = React.forwardRef<any, OptionsListProps>(
  ({ open, value, options, anchorEl, onOptionClick }, ref) => {
    const classes = useOptionsListStyles();
    const [divRef, setDivRef] = useState<HTMLDivElement | null>();

    return (
      <Popper
        className={classes.popper}
        open={!!open}
        anchorEl={anchorEl}
        placement='top-start'
      >
        <Paper
          ref={ref}
          className={classes.paper}
          style={{ transform: `translateX(${divRef?.clientWidth}px)` }}
        >
          <List className={classes.listbox}>
            {options.map((option, index) => (
              <ListItem
                key={index}
                disableGutters
                className={classes.option}
                onClick={() => onOptionClick && onOptionClick(option)}
              >
                <Typography noWrap>{option}</Typography>
              </ListItem>
            ))}
          </List>
        </Paper>
        <div ref={(el) => setDivRef(el)} className={classes.tempDiv}>
          {value}
        </div>
      </Popper>
    );
  }
);

interface IProps {
  value?: string;
  onChange?: (value: string, errors: any[]) => void;
  inputRef?: (el: any) => void;
  onInputFocus?: () => void;
}

const OrderTimingField: React.FC<IProps> = ({
  value: defaultValue,
  onChange,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>();
  const theme = useTheme<Theme>();
  const isMobileMedia = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const [value, setValue] = useState(defaultValue ?? '');
  const [options, setOptions] = useState<string[]>([]);
  const [caretPosition, setCaretPosition] = useState<number | null>();
  const [showOptions, setShowOptions] = useState(false);
  const [listRef, setListRef] = useState<HTMLDivElement>();

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  useOutsideClick([inputRef.current, listRef], () => {
    setShowOptions(false);
  });

  const { errors, candidates } = useMemo(
    () =>
      getSuggestions({
        input: value.toLowerCase(),
        caretPosition: caretPosition ?? 0,
      }),
    [value, caretPosition]
  );

  useEffect(() => {
    if (onChange) onChange(value, value ? errors : []);
  }, [value, errors]);

  useEffect(() => {
    const lastSpaceIndex = value.lastIndexOf(' ');
    const lastWord = value.substring(lastSpaceIndex + 1).toLowerCase();

    const regex = new RegExp(lastWord);

    const matchedKeywords: string[] = [];
    const notMatchedKeywords: string[] = [];
    for (const keyword of candidates.keywords) {
      if (regex.test(keyword)) matchedKeywords.push(keyword);
      else notMatchedKeywords.push(keyword);
    }

    setOptions([...matchedKeywords, ...notMatchedKeywords]);
  }, [value, candidates]);

  const handleSelectOption = (option: any) => {
    const lastSpaceIndex = value.lastIndexOf(' ');

    let newValue = value;
    if (value.length - 1 === lastSpaceIndex) {
      newValue = value + option + ' ';
    } else {
      newValue = value.substring(0, lastSpaceIndex + 1) + option + ' ';
    }

    setValue(newValue);
    setCaretPosition(newValue.length);
    inputRef.current && inputRef.current.focus();
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!showOptions) setShowOptions(true);
    setValue(e.target.value);
  };

  const handleInputFocus = () => {
    if (props.onInputFocus) props.onInputFocus();
    setShowOptions(true);
  };

  const handleInputKeyUp = () => {
    if (inputRef.current) {
      setCaretPosition(inputRef.current.selectionStart);
    }
  };

  return (
    <>
      <TextField
        fullWidth
        placeholder='Timing'
        inputRef={(el) => {
          inputRef.current = el;
          if (props.inputRef) props.inputRef(el);
        }}
        value={value}
        onChange={handleInputChange}
        onKeyUp={handleInputKeyUp}
        helperText={
          !!errors.length && value ? 'please enter correct value' : ''
        }
        error={!!errors.length && !!value}
        inputProps={{
          style: { padding: '9.5px 4px' },
          onFocus: handleInputFocus,
        }}
      />

      {isMobileMedia ? (
        showOptions ? (
          <MobileAutocompleteOptionsList
            ref={(el) => setListRef(el)}
            options={options}
            getOptionProps={({ option }) => ({
              onClick: () => handleSelectOption(option),
            })}
          />
        ) : null
      ) : (
        <OptionsList
          ref={(el) => setListRef(el)}
          open={showOptions}
          value={value}
          options={options}
          anchorEl={inputRef.current}
          onOptionClick={handleSelectOption}
        />
      )}
    </>
  );
};

export default OrderTimingField;
