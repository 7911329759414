const initialState = {
  tableData: [],
};

export const addTableDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TABLE_DATA':
      return {
        ...state,
        tableData: [
          ...state.tableData,
          {
            data: action.data,
            header: action.header,
            wholeTableData: action.wholeTableData,
          },
        ],
      };
    default:
      return state;
  }
};
