/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { IDraftVisit, IOrder } from '../types/order';

interface ISaveState {
  state: 'loading' | 'error';
  onReload?: () => void;
}

interface IParams {
  enabled?: boolean;
  orders: IOrder[];
  draft: IDraftVisit | null;
  onCreateDraft?: (orders: IOrder[]) => Promise<any>;
  onEditDraft?: (
    draftId: number,
    data: any
  ) => Promise<AxiosResponse<IDraftVisit>>;
}

const useAutoSaveOrder = ({
  enabled = true,
  orders,
  draft,
  onCreateDraft,
  onEditDraft,
}: IParams) => {
  const [newOrders, setNewOrders] = useState<IOrder[]>([]);
  const [states, setStates] = useState<Record<string, ISaveState>>({});
  const [creationLoading, setCreationLoading] = useState(false);

  const updateStates = (newOrders: IOrder[], state: ISaveState['state']) => {
    setStates(
      newOrders.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.task.uuid ?? '']: { state, onReload: () => save(newOrders) },
        }),
        { ...states }
      )
    );
  };

  const removeStates = (newOrders: IOrder[]) => {
    setStates((prevStates) =>
      newOrders.reduce(
        (prev, curr) => {
          if (
            curr.task.uuid &&
            Object.keys(prevStates).includes(curr.task.uuid)
          ) {
            delete prev[curr.task.uuid];
          }

          return prev;
        },
        { ...prevStates }
      )
    );
  };

  const modifyOrders = async (newOrders: IOrder[], isCreateMode = false) => {
    const modifiedOrders: IOrder[] = [];

    for (const newOrder of newOrders) {
      if (newOrder.action === 'add') {
        const previousAddedOrder = await orders.find(
          (o) => o.task.uuid === newOrder.task.uuid
        );

        if (!isCreateMode && previousAddedOrder) {
          modifiedOrders.push({ ...previousAddedOrder, action: null });
        }

        modifiedOrders.push({
          ...newOrder,
          task: { ...newOrder.task, uuid: undefined },
        });
      } else {
        modifiedOrders.push(newOrder);
      }
    }

    return modifiedOrders;
  };

  const createDraft = async (newOrders: IOrder[]) => {
    if (onCreateDraft) {
      await setCreationLoading(true);
      const modifiedOrders = await modifyOrders(newOrders, true);
      await onCreateDraft(modifiedOrders).finally(() =>
        setCreationLoading(false)
      );
    } else {
      throw new Error('required "onCreateDraft" parameter!');
    }
  };

  const editDraft = async (newOrders: IOrder[]) => {
    if (onEditDraft) {
      const modifiedOrders = await modifyOrders(newOrders);
      await onEditDraft(draft?.id ?? 0, {
        parent: draft?.parent,
        orders: modifiedOrders,
      });
    } else {
      throw new Error('required "onEditDraft" parameter!');
    }
  };

  const save = async (newOrders: IOrder[]) => {
    if (enabled) {
      await setNewOrders(newOrders);
      try {
        await updateStates(newOrders, 'loading');

        if (draft || creationLoading) {
          await editDraft(newOrders);
        } else {
          await createDraft(newOrders);
        }
        removeStates(newOrders);
      } catch (err) {
        console.log(err);
        updateStates(newOrders, 'error');
      }
    }
  };

  const removeExtraOrders = (orders: IOrder[]) => {
    return orders.filter((order) => {
      if (order.action === 'add') {
        const isNewOrder = newOrders.find(
          (o) => o.task.uuid === order.task.uuid
        );

        if (isNewOrder) return false;
      }
      return true;
    });
  };

  console.log('states', states);

  return { states, save, removeExtraOrders };
};

export default useAutoSaveOrder;
