import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core';
import CloseIcon from '../../../Assets/icon_close.svg';

const styles = makeStyles({
  container: ({ maxWidth }: { maxWidth?: string }) => ({
    padding: '40px 24px 24px',
    position: 'relative',
    minHeight: '300px',
    background: '#fff',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: maxWidth ? maxWidth + 'px' : '520px',
    outline: 'none',
    borderRadius: '6px',
  }),
  closeIcon: {
    position: 'absolute',
    top: '12px',
    left: '20px',
    cursor: 'pointer',
  },
});

interface IModal {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  maxWidth?: string;
}

const CustomModal = ({ open, handleClose, children, maxWidth }: IModal) => {
  const classes = styles({ maxWidth });

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <div className={classes.container}>
          <div className={classes.closeIcon} onClick={handleClose}>
            <img src={CloseIcon} alt='closeIcon' />
          </div>
          {children}
        </div>
      </Modal>
    </>
  );
};
export default CustomModal;
