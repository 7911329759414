import * as API from '../api/userApi';
import { dropIndexedDB } from '../DB/flushDB';
import { SET_NAMESPACE, SET_HOSPITAL_NAME } from './actionTypes';

// TODO: make returned responses the as consistent type

export const login = (body) => async (dispatch) => {
  return API.login(body)
    .then(async (response) => {
      var myData = {
        authorization_token: response.data.authorization_token,
        refresh_token: response.data.refresh_token,
        first_name: response.data.user.first_name,
        last_name: response.data.user.last_name,
        id: response.data.user.id,
      };
      localStorage.setItem('myData', JSON.stringify(myData));
      await dispatch({ type: 'ADD_AUTH', data: myData });
      return true;
    })
    .catch(async () => {
      return false;
    });
};
export const logout = () => async (dispatch) => {
  let myData = JSON.parse(localStorage.getItem('myData'));
  const body = { refresh_token: myData.refresh_token };
  return API.logOut(body).then(async () => {
    await dropIndexedDB();
    // TODO: DRY
    localStorage.removeItem('myData');
    localStorage.removeItem('fcm-token');
    await dispatch({ type: 'LOG_OUT' });
  });
};
export const demandVerification = (body) => async (dispatch) => {
  return API.demandVerification(body).then(async (response) => {
    return await dispatch({
      type: 'ADD_MESSAGE',
      message: 'verification code has been sent',
      status: response.status,
      data: response.data,
    });
  })
  .catch(async () => {
    return false;
  });
};
export const resetPassword = (body) => async (dispatch) => {
  return API.resetPassword(body).then(async (response) => {
    await dispatch({
      type: 'ADD_MESSAGE',
      message: 'password has changed successfully',
      status: response.status,
    });
    return true;
  })
  .catch(async () => {
    return false;
  });
};
export const registerFireBaseToken = (fireBaseToken, device_id) => async () => {
  const data = {
    firebase_token: fireBaseToken,
    device_id: device_id,
  };
  return API.notifRegistration(data);
};
export const addFireBaseToken = (data) => async (dispatch) => {
  dispatch({ type: 'ADD_FIREBASE_TOKEN', data: data });
};

export const getUserCurrentData = () => async (dispatch) => {
  return API.getUserCurrentData()
    .then(async (response) => {
      return await dispatch({
        type: 'LOAD_USER_DATA',
        data: response.data,
        status: response.status,
      });
    })
    .catch((err) => console.log(err));
};
export const getUserSignature = () => async (dispatch) => {
  return API.getUserSignature().then(async (response) => {
    return await dispatch({
      type: 'LOAD_USER_SIGNATURE',
      data: response.status === 404 ? null : response.data,
    });
  });
};
export const getSignatureByUserId = (userId) => async (dispatch) => {
  return API.getSignatureByUserId(userId).then(async (response) => {
    return await dispatch({
      type: 'LOAD_USER_SIGNATURE',
      data: response.status === 404 ? null : response.data,
    });
  });
};
export const updateUserSignature = (signature) => async (dispatch) => {
  return API.updateUserSignature(signature).then(async () => {
    return await dispatch({
      type: 'ADD_MESSAGE',
      message: 'signature successfully uploaded',
      status: 200,
      position: 'top',
    });
  });
};


export const setNamespace = (namespaceId) => {
  localStorage.setItem('namespaceId', namespaceId);
  return {
    type: SET_NAMESPACE,
    data: namespaceId
  };
};


export const setHospitalName = (hospitalName) => {
  return {
    type: SET_HOSPITAL_NAME,
    data: hospitalName
  };
};