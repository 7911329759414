import React, { Component } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import { ListItem, Drawer, withStyles, IconButton } from '@material-ui/core';
import { BlueButton, ContentLoader, HeaderInput } from '../shared';
import CHEVRON_Icon from '../../Assets/ic_chevron_down_dark_24.png';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { searchICD11 } from '../../action/ICD11Actions';
import { ICD11SelectedItem } from './index.js';
import toRenderProps from 'recompose/toRenderProps';
import withWidth from '@material-ui/core/withWidth';

const WithWidth = toRenderProps(withWidth());
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;
const RenderHTML = (props) => (
  <div dangerouslySetInnerHTML={{ __html: props.HTML }}></div>
);
const styles = {
  root: {
    display: 'inline-block !important',
    padding: '7px 0px',
    marginLeft: '30px',
  },
  fullList: {
    width: 'auto',
  },
  paperSM: {
    border: 'none',
    boxShadow: 'none',
    width: '100vw',
    // paddingLeft: '2vw',
    paddingTop: '10px',
    paddingBottom: '53px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    height: '99%',
    overflowY: 'visible',
  },
  paperLG: {
    border: 'none',
    boxShadow: 'none',
    width: '70vw',
    marginLeft: '30vw',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    height: '99%',
    paddingBottom: '53px',
    overflowY: 'visible',
  },
  nothingFound: {
    padding: '25px',
  },
  Scrollable: {
    overflowY: 'auto',
  },
  FixedContainer: {
    position: 'absolute',
    left: '0',
    right: '0',
    bottom: '0',
  },
  icdItem: {
    display: 'block',
  },
  selectedContainer: {
    padding: '5px 2px 0',
  },
  header: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 22px 16px 5px',
    borderBottom: '1px solid #ebebeb',
  },
};
class ICD11SearchDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '', problem_list: [], loading: false };
    if (this.props.problems) {
      this.state.problem_list = [...this.props.problems];
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.problems !== prevProps.problems) {
      this.setState({ ...this.state, problem_list: [...this.props.problems] });
    }
  }

  handleSubmit() {
    this.props.handleSubmit(this.state.problem_list);
  }
  handleChange(event) {
    clearTimeout(this.timer);
    this.setState({ value: event.target.value });
    this.timer = setTimeout(() => {
      this.triggerChange();
    }, WAIT_INTERVAL);
  }
  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.triggerChange();
    }
  }
  async triggerChange() {
    this.setState({ loading: true });
    if (this.state.value.trim().length > 0) {
      await this.props.searchICD11(this.state.value);
    }
    this.setState({ loading: false });
  }
  handleRemoveICD11(item) {
    let { problem_list } = this.state;
    let foundIndex;
    if (problem_list) {
      foundIndex = problem_list.findIndex(
        (problem) => problem.theCode === item.theCode
      );
    }
    if (foundIndex >= 0) {
      problem_list.splice(foundIndex, 1);
      this.setState({ problem_list });
    }
  }
  // recursive function to render nested ICD List
  createICDList(icdList) {
    return (
      <List
        className='icdSearchResult'
        style={{
          fontSize: '.9rem',
        }}
      >
        {icdList.map((item) => {
          return this.createICDItem(item);
        })}
      </List>
    );
  }
  createICDItem(icd) {
    if (icd.descendants.length > 0) {
      return (
        <ListItem
          className={this.props.classes.icdItem}
          button
          key={icd.id}
          selected={this.state.selectedIndex == icd.id}
          data-value={icd}
        >
          <div
            className='icdTopItem hoverable'
            onClick={() => this.handleICD11Select(icd)}
          >
            <RenderHTML HTML={icd.title} />
          </div>

          {this.createICDList(icd.descendants)}
        </ListItem>
      );
    } else {
      return (
        <ListItem
          className={`hoverable ${this.props.classes.icdItem}`}
          button
          key={icd.id}
          selected={this.state.selectedIndex == icd.id}
          data-value={icd}
          onClick={() => this.handleICD11Select(icd)}
        >
          <RenderHTML HTML={icd.title} />
        </ListItem>
      );
    }
  }
  handleICD11Select(selectedValue) {
    let { problem_list } = this.state;
    if (problem_list) {
      if (
        !problem_list.find(
          (problem) => problem.theCode === selectedValue.theCode
        )
      ) {
        problem_list.push(this.createProblemForSubmission(selectedValue));
        this.setState({ problem_list });
      }
    } else {
      problem_list.push(this.createProblemForSubmission(selectedValue));
      this.setState({ problem_list });
    }
  }
  // the fetched list of icd is different from the list we should post then map selected icd to the desired property object
  createProblemForSubmission(icd) {
    return {
      valid: true,
      fetched: false,
      theCode: icd.theCode,
      title_en: icd.title,
      nid: this.getNIDfromStemId(icd.stemId),
    };
  }

  getNIDfromStemId(stemId) {
    let parsed = stemId.split('/');
    let parsedLength = parsed.length;
    let lastSection = parsed[parsedLength - 1];
    if (lastSection === 'other' || lastSection === 'unspecified') {
      return parsed[parsedLength - 2] + '/' + lastSection;
    } else {
      return lastSection;
    }
  }

  render() {
    const { open, inputLabel, classes, icd } = this.props;
    let { value, problem_list, loading } = this.state;

    let list;
    let problemElements;
    if (problem_list) {
      if (problem_list.length > 0) {
        problemElements = (
          <div className={classes.selectedContainer}>
            {problem_list.map((problem, id) => (
              <ICD11SelectedItem
                key={id}
                icdIsRemovable={true}
                problem={problem}
                handleRemoveICD11={(item) => this.handleRemoveICD11(item)}
              />
            ))}
          </div>
        );
      }
    }

    if (icd) {
      if (icd.length > 0) {
        list = this.createICDList(icd);
      } else {
        list = (
          <div className={`farsiNum align-center ${classes.nothingFound}`}>
            موردی یافت نشد
          </div>
        );
      }
    }
    return (
      <WithWidth>
        {({ width }) => (
          <Drawer
            classes={
              width === 'xs' || width === 'sm'
                ? { paperAnchorBottom: classes.paperSM }
                : { paperAnchorBottom: classes.paperLG }
            }
            anchor='bottom'
            open={open}
            onClose={() => this.props.handleClose()}
          >
            <div className={classes.header}>
              <IconButton
                className='drawerTopCloseIcon'
                onClick={() => this.props.handleClose()}
              >
                <img
                  // className='hoverable '
                  alt='icon'
                  height={20}
                  width={20}
                  src={CHEVRON_Icon}
                />
              </IconButton>
              <HeaderInput
                label={inputLabel}
                defaultValue={value}
                onChange={(event) => this.handleChange(event)}
                onKeyDown={(event) => this.handleKeyDown(event)}
              />
            </div>

            {problemElements}
            <div className={classes.Scrollable}>{!loading && list}</div>

            {loading && <ContentLoader />}

            <div className={classes.FixedContainer}>
              <BlueButton
                className={`BlueButton ${
                  problem_list && problem_list.length > 0 ? '' : 'notAllowed'
                }`}
                loader={false}
                disabled={!problem_list || problem_list.length <= 0}
                text={'Confirm'}
                handleClick={() => this.handleSubmit()}
              />
            </div>
          </Drawer>
        )}
      </WithWidth>
    );
  }
}
ICD11SearchDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
  return {
    icd: state.generalReducers.icd,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ searchICD11 }, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ICD11SearchDrawer));
