import React, { useMemo } from 'react';
import { IPrintItem } from '..';
import { Box, Theme, Typography } from '@material-ui/core';
import { getFullDate, getTime } from '../../../../Utilities/jalaliDate';
import { makeStyles } from '@material-ui/styles';
import { reformatCardexWithChanges } from '../../../../Utilities/order';
import { categories as CATEGORIES } from '../../data';
import Task from '../../Cardex/Task';
import { renderAutoDirText } from '../../../../Utilities/CheckLang';
import { getUserFullName } from '../../../../Utilities/user';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  head: {
    display: 'flex',
    fontWeight: 'bold',
    marginBottom: 12,
  },
  taskRoot: {
    padding: '4px 0 0',
  },
  taskContent: {
    color: theme.palette.text.primary,
    fontSize: 11,
    lineHeight: '17px',

    '&.changed:first-child': {
      color: theme.palette.text.primary,
      textDecorationLine: 'line-through',
    },

    '&.dc, &.hold': {
      color: theme.palette.text.primary,
      textDecorationLine: 'none',
    },
  },
  taskState: {
    height: 16,
    padding: '0 9px',

    '& *': {
      fontSize: 9,
      lineHeight: '13px',
    },
  },
  details: {
    margin: '18px 0 9px 0',

    '& *': {
      fontSize: 11,
      lineHeight: '17px',
    },
  },
  signatures: {
    display: 'flex',

    '& img': {
      height: '135px',
      maxWidth: '180px',
      margin: '0 5px 5px 0',
    },
  },
}));

interface IProps {
  item: IPrintItem;
  signatures: { [id: string]: string };
}

const PhysicianOrdersPrintContent: React.FC<IProps> = ({
  item: { visit, parentVisit },
  signatures,
}) => {
  const classes = useStyles();

  const cardex = useMemo(
    () => (visit ? reformatCardexWithChanges({ visit, parentVisit }) : {}),
    [visit, parentVisit]
  );

  const renderDetail = () => {
    return (
      <Box className={classes.details}>
        <Typography className='avoid-break-inside'>
          Signed by &nbsp;
          {renderAutoDirText(
            visit?.signers.map((signer) => getUserFullName(signer)).join(' ,')
          )}
        </Typography>

        {!!visit?.checker && (
          <Typography className='avoid-break-inside'>
            Checked by &nbsp;
            {renderAutoDirText(getUserFullName(visit.checker))}
          </Typography>
        )}
      </Box>
    );
  };

  const renderSignatures = () => {
    return (
      <Box className={classes.signatures}>
        {visit?.signers.map((signer) =>
          signatures[signer.id] ? (
            <Box className='avoid-break-inside'>
              <img
                key={signer.id}
                alt='signer signature'
                src={signatures[signer.id]}
              />
            </Box>
          ) : null
        )}

        {!!visit?.checker && signatures[visit.checker.id] && (
          <Box className='avoid-break-inside'>
            <img alt='checker signature' src={signatures[visit.checker.id]} />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Box className={clsx(classes.head, 'avoid-break-inside')}>
        <Typography variant='body2' className='semiBoldWeight'>
          New visit created
        </Typography>
        &nbsp;
        <Typography variant='body2' className='semiBoldWeight'>
          {getFullDate(visit?.created)}&nbsp;{getTime(visit?.created)}
        </Typography>
        {!!visit?.checked && (
          <Typography variant='body2' className='semiBoldWeight'>
            &nbsp;and&nbsp;checked&nbsp;{getFullDate(visit?.checked)}&nbsp;
            {getTime(visit?.checked)}
          </Typography>
        )}
        <Typography variant='body2'>, Please</Typography>
      </Box>

      <Box>
        {CATEGORIES.map((category, index) =>
          cardex[category.key]?.new.length ? (
            <Box mb={1} key={index}>
              <Typography
                variant='body2'
                className='semiBoldWeight avoid-break-inside'
              >
                {category.label}
              </Typography>

              {cardex[category.key]?.new.map((item, index) => (
                <Task
                  key={index}
                  item={item}
                  classes={{
                    root: clsx(classes.taskRoot, 'avoid-break-inside'),
                    task: classes.taskContent,
                    state: classes.taskState,
                  }}
                />
              ))}
            </Box>
          ) : null
        )}
      </Box>

      {renderDetail()}

      {renderSignatures()}
    </Box>
  );
};

export default PhysicianOrdersPrintContent;
