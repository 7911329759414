import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getPatientConsults } from '../../action/consultAction';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

//ui components
import ConsultCard from './ConsultCards';
import PriorityLine from './ConsultCards/Priority';
import { NoContent } from '../shared';

import PLUS_ICON from '../../Assets/ic_add_white_24.png';

import './Consult.scss';
import PageWrapper from '../PageWrapper';
import PageHeader from '../PageHeader/PageHeader';
import { getUserId } from '../../Utilities/storage';
import PrintContainer from './Print/PrintContainer';

const PatientConsult = ({ headerOptions }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const params = useParams();
  const patientId = params.patientId;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const consults = useSelector(
    (state) => state.patientsReducer?.patient?.consults
  );

  const printableConsults = useMemo(() => {
    if (consults && consults.length > 0) {
      return consults.filter((consult) => consult.state === 4);
    }
    return [];
  }, [consults]);

  useEffect(() => {
    if (!patientId) {
      history.replace('/patient');
      return;
    }
    fetchPageData();
  }, [patientId]);

  const fetchPageData = () => {
    setLoading(true);
    setError(null);
    dispatch(getPatientConsults(patientId))
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(err);
      });
  };

  const createConsultItem = (consult, key) => {
    let fixedPart = (
      <>
        <PriorityLine priority={consult.priority} />
        <ConsultCard consult={consult} />
      </>
    );
    return createLink(consult, fixedPart, key);
  };
  const createLink = (consult, fixedPart, key) => {
    if (consult.state === 0) {
      return (
        <Link
          className='patient-card hoverable'
          key={key}
          to={{
            pathname: `/patient/${patientId}/consult/${consult.id}/draft`,
            state: {
              id: patientId,
              consultId: consult.id,
              title: '',
              prevPath: location.pathname,
            },
          }}
        >
          {fixedPart}
        </Link>
      );
    }
    if (consult.requester?.id === getUserId() && consult.state !== 4) {
      return (
        <Link
          className='patient-card hoverable'
          key={key}
          to={{
            pathname: `/patient/${patientId}/consult/${consult.id}/view`,
            state: {
              id: patientId,
              consultId: consult.id,
              title: 'consult-edit',
            },
          }}
        >
          {fixedPart}
        </Link>
      );
    } else {
      return (
        <Link
          className='patient-card hoverable'
          key={key}
          to={{
            pathname: `/patient/${patientId}/consult/${consult.id}/answer`,
            state: {
              id: patientId,
              title: 'consult-create',
              consultReply: '',
            },
          }}
        >
          {fixedPart}
        </Link>
      );
    }
  };

  return (
    <Fragment>
      <PageHeader
        centerAdjustment
        {...headerOptions}
        optionComponent={
          printableConsults?.length ? (
            <PrintContainer
              consults={printableConsults}
              style={{ top: 0, left: 0 }}
            />
          ) : undefined
        }
      />
      <PageWrapper loading={loading} error={error} onReload={fetchPageData}>
        <div className='patient__consult inner-container-with-padding'>
          <div className='patient__consult__list'>
            {consults && consults.length > 0 ? (
              consults.map((consult, key) => {
                return createConsultItem(consult, key);
              })
            ) : (
              <NoContent
                width={'65%'}
                text={'There is no consult for the patient'}
                buttonContent={
                  location?.state?.cardName &&
                  location?.state?.cardName !== 'consult'
                    ? 'New consult'
                    : ''
                }
                onButtonClick={() =>
                  history.push(`/patient/${patientId}/consult/create`, {
                    id: patientId,
                    title: 'consult-create',
                  })
                }
              />
            )}
          </div>
          <div>
            {consults &&
              consults.length > 0 &&
              location?.state?.cardName &&
              location?.state?.cardName !== 'consult' && (
                <Link
                  className='addButton hoverable'
                  to={{
                    pathname: `/patient/${patientId}/consult/create`,
                    state: {
                      id: patientId,
                      title: 'consult-create',
                    },
                  }}
                >
                  <div className='add-button-description farsiNum rtl'>
                    مشاوره جدید
                  </div>
                  <div className='inner-plus-container'>
                    <img
                      alt='add icon'
                      className='addIcon'
                      height={35}
                      src={PLUS_ICON}
                    />
                  </div>
                </Link>
              )}
          </div>
        </div>
      </PageWrapper>
    </Fragment>
  );
};

export default PatientConsult;
