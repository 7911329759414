const initialState = {
  consults: null,
  noMoreHttpFetch: null,
};

export const consultReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_CONSULTS': {
      return {
        ...state,
        consults: action.data,
        noMoreHttpFetch: action.noMoreHttpFetch,
      };
    }
    case 'LOAD_MORE_CONSULTS': {
      return {
        ...state,
        consults: [...state.consults, ...action.data],
        noMoreHttpFetch: action.noMoreHttpFetch,
      };
    }
    case 'CREATE_CONSULT': {
      return { ...state, consults: action.data };
    }
    // case 'GET_PATIENT_CONSULTS': {
    //   return { ...state, consults: action.data };
    // }
    default:
      return state;
  }
};
