import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import withWidth from '@material-ui/core/withWidth';
import toRenderProps from 'recompose/toRenderProps';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Button from '@material-ui/core/Button';
const WithWidth = toRenderProps(withWidth());

const styles = {
  formGroupRoot: {
    margin: '5px 30px',
  },
  labelRoot: {
    justifyContent: 'space-between',
    flexFlow: 'row-reverse',
    padding: '7px 7px 7px 3px',
    margin: '0',
    borderBottom: '1px solid #0000001f',
  },
  paperSM: {
    border: 'none',
    boxShadow: 'none',
    width: '98vw',
    background: 'none',
    paddingLeft: '2vw',
    marginBottom: '20px',
  },
  paperLG: {
    marginBottom: '20px',
    border: 'none',
    boxShadow: 'none',
    width: '29.5vw',
    background: 'none',
    paddingLeft: '0.5vw',
  },
  label: {
    fontSize: '14px',
  },
  rootRadio: {
    padding: '0px',
  },
  notifSettingTitle: {
    fontSize: '15px',
    margin: '10px 0px',
    fontWeight: 'bold',
  },
  notifRadioTitle: {
    marginTop: '15px',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  notifContainer: {
    background: 'white',
    borderRadius: '10px',
    padding: '8px 31px',
  },
  submitBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '7px',
  },
};

class NotifDrawer2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      top: false,
      left: false,
      bottom: true,
      right: false,
      laboratoryNotifOptions: [
        { label: 'None', value: 'none' },
        { label: 'Out of Range', value: 'abnormal' },
        { label: 'New Result', value: 'all' },
      ],
      radiologyNotifOptions: [
        { label: 'None', value: 'none' },
        { label: 'New Result', value: 'all' },
      ],
    };
  }

  componentDidMount() {
    this.setState({
      labValue: this.props.labValue,
      radioValue: this.props.radioValue,
    });
  }

  toggleDrawer = (side, open) => () => {
    this.props.handleClose();
    this.setState({
      [side]: open,
    });
  };
  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  }
  handleSubmit() {
    if (
      this.props.labValue === this.state.labValue &&
      this.props.radioValue === this.state.radioValue
    ) {
      return;
    }
    this.props.handleSubmit(this.state.labValue, this.state.radioValue);
  }

  render() {
    const { classes } = this.props;
    const laboratoryNotifList = this.state.laboratoryNotifOptions.map(
      (notif, key) => (
        <FormControlLabel
          key={key}
          classes={{ root: classes.labelRoot, label: classes.label }}
          value={notif.value}
          control={
            <Radio classes={{ root: classes.rootRadio }} color='primary' />
          }
          label={notif.label}
          labelPlacement='start'
        />
      )
    );
    const radiologyNotifList = this.state.radiologyNotifOptions.map(
      (notif, key) => (
        <FormControlLabel
          key={key}
          classes={{ root: classes.labelRoot, label: classes.label }}
          value={notif.value}
          control={
            <Radio classes={{ root: classes.rootRadio }} color='primary' />
          }
          label={notif.label}
          labelPlacement='start'
        />
      )
    );
    const fullList = (
      <div className={classes.notifContainer}>
        <div className={classes.notifSettingTitle}>Notification Setting</div>

        {/* from here */}

        <div className={classes.notifRadioTitle}>Lab Result</div>
        <RadioGroup
          name='labValue'
          value={this.state.labValue}
          onChange={(e) => this.handleChange(e)}
        >
          {laboratoryNotifList}
        </RadioGroup>

        {/* to here */}
        {!this.props.labFactorOnly && (
          <Fragment>
            <div className={classes.notifRadioTitle}>Radiology</div>
            <RadioGroup
              name='radioValue'
              value={this.state.radioValue}
              onChange={(e) => this.handleChange(e)}
            >
              {radiologyNotifList}
            </RadioGroup>
          </Fragment>
        )}
        <div className={classes.submitBtnContainer}>
          <Button
            color='primary'
            onClick={() => {
              this.handleSubmit();
              this.props.handleClose();
            }}
          >
            DONE
          </Button>
        </div>
      </div>
    );

    return (
      <div>
        <WithWidth>
          {({ width }) => (
            <Drawer
              classes={
                width === 'xs' || width === 'sm'
                  ? { paperAnchorBottom: classes.paperSM }
                  : { paperAnchorBottom: classes.paperLG }
              }
              anchor='bottom'
              open={this.state.bottom}
              onClose={this.toggleDrawer('bottom', false)}
            >
              <div>{fullList}</div>
            </Drawer>
          )}
        </WithWidth>
      </div>
    );
  }
}

NotifDrawer2.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotifDrawer2);
