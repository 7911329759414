import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import BackArrow from '../../Assets/baseline-arrow_back-24px.svg';
import { useHistory } from 'react-router';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  dialogPaper: {
    maxHeight: '100vh !important',
    height: '100% !important',
    // left: '-6% ',
    minWidth: '30vw',
    minHeight: '250px',
  },
  paperFullScreen: {
    left: '0% !important',
  },
  paperHeightForModifyingData: {
    height: 'auto',
  },
  backArrow: {
    position: 'absolute',
    top: 'auto',
    left: '0',
    opacity: '.8',
  },
  toolbar: {
    justifyContent: 'center',
    boxShadow: '0px 2px 14px rgba(0,0,0,0.1)',
  },
  paperScrollPaper: {
    overflow: 'visible',
  },
  title: {
    fontFamily: 'IRANYekan,Heebo',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: '600',
  },
};

function ResponsiveDrawer({
  title,
  fullScreen,
  classes,
  content,
  children,
  settingClass,
  modifyProfileData,
  notScrollable,
  defaultGoBackAction,
  ...rest
}) {
  const history = useHistory();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    rest.handleClose();
    setOpen(false);
    if (defaultGoBackAction || defaultGoBackAction === undefined) {
      history.goBack();
    }
    // if (this.props.match) {
    //   this.props.history.replace(
    //     `/${this.props.match.params.section}/patient/${this.props.match.params.id}/${this.props.match.params.title}`
    //   );
    // }
  };

  return (
    <div style={{ height: '100vh' }}>
      <Dialog
        classes={
          notScrollable
            ? {
                paper: classes.dialogPaper,
                paperFullScreen: classes.paperFullScreen,
                paperWidthSm: classes.paperWidthSm,
                paperScrollPaper: classes.paperScrollPaper,
              }
            : settingClass
            ? {
                paper: classes.dialogPaper,
                paperFullScreen: classes.paperFullScreen,
                paperWidthSm: classes.paperWidthSm,
              }
            : modifyProfileData
            ? { paperFullScreen: classes.paperHeightForModifyingData }
            : { paperWidthSm: classes.paperWidthSm }
        }
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color='inherit'
              onClick={handleClose}
              aria-label='Close'
              className={classes.backArrow}
            >
              <img alt='Back' src={BackArrow} />
            </IconButton>
            <div className={classes.title}>{title}</div>
          </Toolbar>
        </AppBar>
        <DialogContent
          className={notScrollable ? classes.paperScrollPaper : ''}
        >
          {content ? (
            content
          ) : children ? (
            children
          ) : (
            <DialogContentText>No Content</DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default withMobileDialog()(withStyles(styles)(ResponsiveDrawer));
