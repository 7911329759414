import React from 'react';
import PropsTypes from 'prop-types';
import { Button, Grid, makeStyles, Typography, Box } from '@material-ui/core';
import { getAgeWithUnit } from '../../Utilities/jalaliDate';
import { jalaliFullDate } from '../../Utilities/jalaliDate';
import FEMALE_ICON from '../../Assets/icons/vuesax_linear_woman.svg';
import MALE_ICON from '../../Assets/icons/vuesax_linear_man.svg';
import clsx from 'clsx';
import { CheckRtl } from '../../Utilities/CheckLang';
import { ReactComponent as EditIcon } from '../../Assets/icons/edit.svg';
import { useHistory } from 'react-router';
import useAccess from '../../Hook/useAccess';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px 24px 18px',
    backgroundColor: '#ffffff',
  },
  item: {
    marginBottom: 15,
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
    },
  },
  info: {
    padding: '9px 24px',
    textAlign: 'right',
    direction: 'rtl',
    fontSize: 13,
    color: '#999999',
    margin: '10px 0 0',
    backgroundColor: '#fff',
  },
}));

export default function PatientInfo({ profile, withEditButton, className, bioArcPatientDeepLink=undefined }) {
  const classes = useStyles();
  const history = useHistory();
  const {
    data: [standaloneMode, canManagePatients],
  } = useAccess(['standalone_mode', 'can_manage_patients']);

  const handleEditButtonClick = () => {
    history.push(`/patient/${profile.profile_id}/profile/edit`);
  };

  const genderIcon = profile?.gender ? (
    profile.gender === 'F' ? (
      <img alt='gender icon' height={24} src={FEMALE_ICON} />
    ) : (
      <img alt='gender icon' height={24} src={MALE_ICON} />
    )
  ) : null;

  var bioArcButton = (
    <Box className="RadioCardWholeContainer">
      <Button style={{boxSizing: 'border-box', width: '100%', direction: 'rtl'}}
        color='primary'
        variant='contained'
        // radiology-card class name is used to be visually similar to radiology cards
        className="radiology-card farsiFont"
        onClick={() => window.open(bioArcPatientDeepLink, '_blank', 'noreferrer')}
      >
        نسخه نویسی با بایوآرک
      </Button>
    </Box>
  );

  return (
    <Grid
      container
      direction='row-reverse'
      className={clsx(classes.container, className)}
    >
      <Grid item xs={12} className={clsx(classes.item, classes.titleRow)}>
        <Box mr={2}>
          <Typography variant='h6' className='darkPrimaryColor'>
            Patient Information
          </Typography>
        </Box>

        {standaloneMode && withEditButton && canManagePatients ? (
          <Button
            color='primary'
            startIcon={<EditIcon />}
            onClick={handleEditButtonClick}
          >
            Edit
          </Button>
        ) : null}
      </Grid>

      <Grid item xs={8} className={`text-right ${classes.item}`}>
        <div className='align-items-center justify-content-end'>
          {genderIcon}
          &nbsp;
          <Typography variant='h5' className='mediumWeight'>
            {getAgeWithUnit(profile?.birth_date)?.age}{' '}
          </Typography>
          <Typography color='textSecondary'>
            &nbsp;
            {getAgeWithUnit(profile?.birth_date)?.unit}
          </Typography>
          &nbsp;&nbsp;
          <div className='profile-title farsiBold'>
            {profile?.first_name} {profile?.last_name}
          </div>
        </div>
      </Grid>
      <Grid item xs={4} className={`text-left ${classes.item}`}>
        <Typography color='secondary'>National ID</Typography>
        <div
          className={clsx(
            'defaultFontSize',
            CheckRtl(profile?.national_id) && 'farsiFont'
          )}
        >
          {profile?.national_id}
        </div>
      </Grid>

      <Grid item xs={8} className={`text-right ${classes.item}`}>
        <Typography color='textSecondary'>Service</Typography>
        <div
          className={clsx(
            'defaultFontSize',
            CheckRtl(profile?.primary_service?.name) && 'farsiFont'
          )}
        >
          {profile?.primary_service?.name}
        </div>
      </Grid>
      <Grid item xs={4} className={`text-left ${classes.item}`}>
        <Typography color='textSecondary'>Admission</Typography>
        <Typography variant='h5' className='regularWeight'>
          {jalaliFullDate(profile?.admission_datetime)}
        </Typography>
      </Grid>
      <Grid item xs={8} className={`text-right ${classes.item}`}>
        <Typography color='textSecondary'>Ward</Typography>
        <div
          className={clsx(
            'defaultFontSize',
            CheckRtl(profile?.ward?.name) && 'farsiFont'
          )}
        >
          {profile?.ward?.name}
        </div>
      </Grid>
      <Grid item xs={4} className={`text-left ${classes.item}`}>
        <Typography color='textSecondary'>No.</Typography>
        <Typography variant='h5' className='regularWeight'>
          {profile?.profile_id}
        </Typography>
      </Grid>
      <Grid item xs={8} className={`text-right ${classes.item}`}>
        <Typography color='textSecondary'>Bed</Typography>
        <div
          className={clsx(
            'defaultFontSize',
            CheckRtl(`${profile?.room || ''} - ${profile?.bed || ''}`) &&
              'farsiFont'
          )}
        >{`${profile?.room || ''} - ${profile?.bed || ''}`}</div>
      </Grid>
      <Grid item xs={4} className={`text-left ${classes.item}`}>
        <Typography color='textSecondary'>PACS</Typography>
        <Typography variant='h5' className='regularWeight'>
          {profile?.admission_id}
        </Typography>
      </Grid>
      {bioArcPatientDeepLink ? (
        <Grid item xs={12}>
          {bioArcButton}
        </Grid>
        ) : (
          <></>
        )
      }
    </Grid>
  );
}

PatientInfo.defaultProps = {
  profile: {},
  className: '',
  withEditButton: false,
};

PatientInfo.propsTypes = {
  profile: PropsTypes.object,
  className: PropsTypes.string,
  withEditButton: PropsTypes.bool,
};
