import React from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import NOTIFICATION from '../../../../Assets/icons/Notification.svg';

function Notification() {
  const history = useHistory();

  return (
    <div className='setting__section'>
      <div className='setting__section__title'>
        <img className='mr-2' alt='Sections' src={NOTIFICATION} height={23} />
        Notifications
      </div>
      <div
        className='setting__section__element'
        onClick={() => {
          history.push('/setting/notifications/consult');
        }}
      >
        Consult
      </div>
    </div>
  );
}

export default Notification;
