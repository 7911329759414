import React from 'react';
import { useHistory } from 'react-router-dom';
import { BlueButton } from '../shared';
import Dr_Movahedi_Signature from '../../Assets/EMZA_HINT_INLAB.png';

export const UserSignatureUploadHint = () => {
  const history = useHistory();

  return (
    <div className='dialogContentContainer'>
      <div className='signature-hint'>
        <div className='text-center signature-hint-img'>
          <img className='' src={Dr_Movahedi_Signature} alt='signature-hint' />
        </div>
        <div className='text-center signature-hint-text text-right farsiNum rtl'>
          <h4 className='signature-hint-text-title'>
            لطفا برای ثبت و آپلود مهر و امضای خود نکات زیر را رعایت فرمائید:
          </h4>
          <ul className='signature-hint-text-list'>
            <li>
              برای رعایت موارد قانونی به مهر و امضای شما کنار هم نیاز است.
            </li>
            <li>
              روی کاغذ سفید، بدون خط و عکس زمینه، با نور کافی، مهر و امضا کنید.
            </li>
            <li>مهر و امضای ثبت شده‌ی شما کج و زاویه دار نباشد.</li>
            <li>برای مهر و امضا صرفا جوهر رنگ آبی یا مشکی قابل قبول است.</li>
            <li>
              مهر و امضا به طور کامل در کادر باشد و هیچ بخشی از آن کمرنگ، حذف یا
              تار نشده باشد.
            </li>
            <li>عکس برداری با کیفیت و از نزدیک بدون لرزش باشد.</li>
          </ul>
        </div>
      </div>

      <div className='submitBtnContainer'>
        <BlueButton
          className='BlueButton'
          text={'Upload'}
          handleClick={() => {
            history.push('/setting/profile/signature_upload');
          }}
        />
      </div>
    </div>
  );
};

export default UserSignatureUploadHint;
