import React from 'react';
import NoContentImage from '../../Assets/vectors/empty.svg';
import { Button, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100% - 73px)',
    paddingBottom: 50,
    paddingTop: 23,
  },
  NoContentImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 200,
  },
  text: {
    marginBottom: 24,
  },
  button: {
    width: '100%',
    maxWidth: 312,
  },
});

function NoContent({
  width,
  text,
  buttonContent,
  onButtonClick = () => {},
  ...rest
}) {
  const classes = styles();
  return (
    <div className={classes.container} {...rest}>
      <img
        alt='No Content'
        className={classes.NoContentImage}
        width={width || '75%'}
        src={NoContentImage}
      />
      <Typography
        color='textSecondary'
        className={clsx('text-center', classes.text)}
      >
        {text || 'No patient data'}
      </Typography>
      {buttonContent && (
        <Button
          color='primary'
          variant='contained'
          className={classes.button}
          onClick={onButtonClick}
        >
          {buttonContent}
        </Button>
      )}
    </div>
  );
}
export default NoContent;
