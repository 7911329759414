import React, { Component } from 'react';
import { BlueButton, ContentLoader, HeaderInput } from '../shared';
import { pickResponsibilityServices } from '../../action/actions';
import { getUserCurrentData } from '../../action/userAction';
import { addMessage, getAllServices } from '../../action/generalActions';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, FormGroup } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { NoContent } from '../shared';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/styles';

const styles = {
  loadingContainer: {
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function setsAreEqual(setA, setB) {
  return setA.size == setB.size && [...setA].every(x => setB.has(x));
}

class MultiServiceContainer extends Component {
  constructor() {
    super();
    this.state = {
      currentServiceIds: new Set(),
      selectedServiceIds: new Set(),
      services: [],
      buttonLoader: false,
      loader: true,
      searchValue: '',
    };
  }
  async componentDidMount() {
    this.setState({ loader: true });

    var userData;
    const userDataResponse = await this.props.getUserCurrentData();
    userData = userDataResponse && userDataResponse.status == 200 ? userDataResponse.data : undefined;
    if (userData === undefined && this.props.userData) {
      userData = this.props.userData;
    }
    if (userData === undefined) {
      addMessage('Could not load your services due to network error!');
      this.props.history.push('/setting');
      return;
    }
    const responsibility_services_ids = Array.isArray(userData.responsibility_services) ? userData.responsibility_services.map(s => s.id) : [];
    this.setState({
      currentServiceIds: new Set(responsibility_services_ids),
      selectedServiceIds: new Set(responsibility_services_ids),
    });

    let response = await this.props.getAllServices();
    if (response) {
      this.setState({ services: response.data });
    }
    this.setState({ loader: false });
  }
  handleSubmit() {
    if (setsAreEqual(this.state.selectedServiceIds, this.state.currentServiceIds)) {
      return;
    }
    // if (this.props.filter) {
    //   this.handleFilter();
    //   return;
    // }
    this.handleSubmitServices();
  }
  // handleFilter = () => {
  //   this.props.handleFilter({
  //     ids: this.state.selectedServiceIds,
  //   });
  // };

  async handleSubmitServices() {
    const requestBody = {
      responsibility_services: this.state.services.filter(
        s => this.state.selectedServiceIds.has(s.id)
      ).map(s => s.id),
    };
    this.setState({ buttonLoader: true });
    const responsibilityServicesResponse = await this.props.pickResponsibilityServices(requestBody);
    this.handleCloseDrawer(responsibilityServicesResponse);
  }
  handleCloseDrawer(response) {
    if (response && response.status === 200 && this.props.history) {
      if (this.props.history.length > 1) {
        this.props.history.goBack();
      }
      else {
        this.props.history.push('/');
      }
    }
    this.setState({ buttonLoader: false });
    if (typeof this.props.handleClose === 'function') this.props.handleClose();
    // this.props.handleSettingCloseOnWardChange();
  }
  handleChange(e) {
    // console.log(`${e.target?.value} | ${e.target?.checked}`);
    var selectedServiceIds = new Set(this.state.selectedServiceIds);
    if (e.target?.checked) {
      selectedServiceIds.add(Number(e.target?.value));
    }
    else {
      selectedServiceIds.delete(Number(e.target?.value));
    }
    this.setState({ selectedServiceIds: selectedServiceIds });
  }
  handleSearchValueChange(e) {
    let searchValue = e.target?.value;
    this.setState({ searchValue: (typeof searchValue === 'string') ? searchValue : '' });
  }
  render() {
    const {
      selectedServiceIds,
      services,
      buttonLoader,
      loader,
      currentServiceIds,
    } = this.state;

    const { classes } = this.props;

    console.log(selectedServiceIds);

    return (
      <div className='dialogContentContainer'>
        {loader ? (
          <div className={classes.loadingContainer}>
            <ContentLoader style={{ top: '40vh' }} />{' '}
          </div>
        ) : (services && services.length > 0) ? (
          <div>
            <div className={classes.header} style={({padding: '1.5em 1em 1em 1em'})}>
              <HeaderInput
                label={'Search for services'}
                autoFocus={false}
                onChange={(e) => this.handleSearchValueChange(e)}
              />
            </div>
            <div>
              <FormGroup
                aria-label='OnCall'
                name='OnCall'
                value={selectedServiceIds}
                onChange={(e) => this.handleChange(e)}
              >
                {services.filter(
                    s => s.name.toLowerCase().includes(this.state.searchValue.toLowerCase())
                ).map(
                  s =>
                    s && (
                      <FormControlLabel
                        key={s.id}
                        style={{
                          borderBottom: 'solid #EBEBEB 0.5px',
                          marginLeft: '0px',
                          marginRight: '0px',
                        }}
                        classes={{
                          label: 'fa-text-body1',
                        }}
                        value={`${s.id}`}
                        control={
                          <Checkbox
                            checked={selectedServiceIds.has(s.id)}
                            label={s.name}
                            color='primary'
                          />
                        }
                        label={s.name}
                      />
                    )
                )}
              </FormGroup>
            </div>

            <div className='submitBtnContainer'>
              <BlueButton
                className='BlueButton'
                loader={buttonLoader}
                disabled={setsAreEqual(selectedServiceIds, currentServiceIds)}
                text={`SAVE (${selectedServiceIds.size} services)`}
                handleClick={() => this.handleSubmit()}
              />
            </div>
          </div>
        ) : (
          <NoContent
            text={'There is no service for you!'}
          />
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserCurrentData,
      pickResponsibilityServices,
      getAllServices,
    },
    dispatch
  );
const mapStateToProps = (state) => {
  return {
    userData: state.userReducer.userData,
    services: state.generalReducers.services,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(MultiServiceContainer)));
