import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  makeStyles,
  withStyles,
  Fab,
  Drawer as MuiDrawer,
  Typography,
  Box,
  Grid,
  Button as MuiButton,
} from '@material-ui/core';
import clsx from 'clsx';
import { categories as CATEGORIES } from '../data';
import addIcon from '../../../Assets/icons/add_white.svg';

const Drawer = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      width: '70vw',
      left: '30vw !important',

      '& .MuiBackdrop-root': {
        left: '30vw',
      },
    },
  },
  paper: {
    padding: '34px 0 24px',
    textAlign: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,

    [theme.breakpoints.up('md')]: {
      marginLeft: '30vw',
      width: '70vw',
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 6,
      left: '50%',
      transform: 'translateX(-50%)',
      height: 4,
      width: 42,
      borderRadius: 4,
      backgroundColor: theme.palette.grey[500],
    },
  },
}))(MuiDrawer);

const CategoryButton = withStyles(() => ({
  root: {
    width: '100%',
    cursor: 'pointer',

    '& *': { cursor: 'pointer' },
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
}))(MuiButton);

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'sticky',
    left: '100%',
    bottom: 24,
    width: 48,
    height: 48,

    '& img': {
      width: 36,
    },
  },
  categoryItemIconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
    marginBottom: 8,
    border: `1.5px solid ${theme.palette.grey[500]}`,
    borderRadius: '50%',
  },
}));

interface CategoryItemProps {
  icon: any;
  label: string;
  name: string;
}

const OrderCategory: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (state: boolean) => {
    setOpen(state);
  };

  const handleItemClick = (name: string) => {
    history.push(location.pathname, { orderType: name });
    toggleDrawer(false);
  };

  const CategoryItem = ({ label, icon, name }: CategoryItemProps) => (
    <CategoryButton onClick={() => handleItemClick(name)}>
      <Box className={classes.categoryItemIconWrapper}>
        <img src={icon} alt={`${label} icon`} height={28} />
      </Box>
      <Box>{label}</Box>
    </CategoryButton>
  );

  return (
    <>
      <Fab
        color='primary'
        className={clsx('gradientBg', classes.fab)}
        onClick={() => toggleDrawer(true)}
      >
        <img src={addIcon} alt='add icon' />
      </Fab>

      <Drawer anchor={'bottom'} open={open} onClose={() => toggleDrawer(false)}>
        <Box mb={3}>
          <Typography variant='h5' className='darkPrimaryColor'>
            New Order
          </Typography>
        </Box>
        <Box px={1}>
          <Grid container spacing={1}>
            {CATEGORIES.map((category) =>
              category.key === 'general' ? null : (
                <Grid item key={category.label} xs={4}>
                  <CategoryItem
                    label={category.label}
                    name={category.key}
                    icon={category.icon}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export default OrderCategory;
