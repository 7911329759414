import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Box, Checkbox, withStyles } from '@material-ui/core';
import { BlueButton, ContentLoader } from '../shared';
import {
  getConsultNotification,
  updateConsultNotification,
} from '../../action/consultAction';

const styles = {
  container: {
    paddingTop: 16,
  },
  loadingContainer: {
    display: 'flex',
    height: '80vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const consultNotificationList = [
  {
    title: 'Notification for Service Consults',
    description: 'Receive in app notification for new consults of my service',
    name: 'relatedConsultRequest',
    backendName: 'notify_related_consult_requests',
  },
  {
    title: 'Notification on Reply',
    description: 'Receive in app notification on replied consults',
    name: 'notifyConsultReplies',
    backendName: 'notify_consult_replies',
  },
];

class ConsultNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedConsultRequest: null,
      notifyConsultReplies: null,
      loading: true,
      pendingSubmission: false,
    };
  }
  async componentDidMount() {
    let response = await this.props.getConsultNotification();
    if (response) {
      consultNotificationList.forEach((notif) => {
        this.setState({
          [notif.name]: response[notif.backendName],
        });
      });
      this.setState({
        loading: false,
      });
    }
  }

  handleChangeNotificationSetting(event) {
    this.setState({ [event.target.name]: event.target.checked });
  }
  handleToggleNotification(name) {
    this.setState((prevState) => {
      return { [name]: !prevState[name] };
    });
  }
  async handleSubmitConsultNotification() {
    this.setState({ pendingSubmission: true });
    const response = await this.props.updateConsultNotification(
      this.createConsultNotificationBodyRequest()
    );
    this.setState({ pendingSubmission: false });
    if (response) {
      if (response.status === 200) {
        if (typeof this.props.handleCloseNotifDialog === 'function') {
          this.props.handleCloseNotifDialog();
        }
      }
    }
  }
  createConsultNotificationBodyRequest() {
    let requestObject = {};
    consultNotificationList.forEach((notif) => {
      requestObject[notif.backendName] = this.state[notif.name];
    });
    return requestObject;
  }
  render() {
    let { loading, pendingSubmission } = this.state;
    const { classes } = this.props;
    const checkboxList = consultNotificationList.map((notif, index) => (
      <div key={index} className='notifSection'>
        <Box
          className='notifInfo'
          textAlign='left'
          onClick={() => this.handleToggleNotification(notif.name)}
        >
          <div className='notifTitle'>{notif.title}</div>
          <div className='notifDescript'>{notif.description}</div>
        </Box>
        <div className='notifCheckBox'>
          <Checkbox
            checked={this.state[notif.name]}
            onChange={(event) => this.handleChangeNotificationSetting(event)}
            value={this.state[notif.name]}
            name={notif.name}
            color='primary'
          />
        </div>
      </div>
    ));

    return (
      <div className={`dialogContentContainer ${classes.container}`}>
        {!loading ? (
          <Fragment>
            <div className='notifContainer'>{checkboxList}</div>
            <div className='submitBtnContainer'>
              <BlueButton
                className='BlueButton'
                text={'SAVE'}
                handleClick={() => this.handleSubmitConsultNotification()}
                loader={pendingSubmission}
              />
            </div>
          </Fragment>
        ) : (
          <div className={classes.loadingContainer}>
            <ContentLoader style={{ top: '40%' }} />
          </div>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { getConsultNotification, updateConsultNotification },
    dispatch
  );
const mapStateToProps = (state) => {
  return {
    notifications: state.consultReducer.notifications,
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConsultNotification));
