import PatientOrder from '../Components/PatientOrder';
import VisitCreate from '../Components/PatientOrder/Visit/Create';
import DraftVisit from '../Components/PatientOrder/Visit/DraftVisit';
import VisitView from '../Components/PatientOrder/Visit/View';
import VisitCheck from '../Components/PatientOrder/Visit/Check';
import PatientProfile from '../Components/PatientProfile/PatientProfile';
import ConsultView from '../Components/Consult/ConsultView';
import PatientConsult from '../Components/Consult/PatientConsult';
import ConsultCreate from '../Components/Consult/ConsultCreate';
import ConsultReply from '../Components/Consult/ConsultReply';
import RadiologyResult from '../Components/RadiologyResult/RadiologyResult';
import RadioResultDetail from '../Components/RadiologyResult/RadioResultDetail/RadioResultDetail';
import LabResult from '../Components/LabResult/LabResult';
import LabResultDetail from '../Components/LabResultDetail/LabResultDetail';
import PatientCreate from '../Components/Patient/PatientCreate';

/*
#######################
    Route Options
#######################
{
  key: '',                        // required, unique
  path: '',                       // required, unique
  component: Component,           // required
  
  absolutePath: false,            // optional, default: false
  defaultContainerPadding: true,  // optional, default: true. * just work in parent components
  routes: []                      // optional
  componentProps: {}              // optional
},
*/
const isOrderEnabled = !!window.env.REACT_APP_ENABLE_ORDER;
const orderRoutes = isOrderEnabled
  ? [
      {
        key: 'order',
        path: '/patient/:patientId/order',
        component: PatientOrder,
        exact: true,
        absolutePath: true,
      },
      {
        key: 'order_new_visit',
        path: [
          '/patient/:patientId/order/create-visit/create-template',
          '/patient/:patientId/order/create-visit/template/:templateId/edit',
          '/patient/:patientId/order/create-visit/template/:templateId',
          '/patient/:patientId/order/create-visit/templates',
          '/patient/:patientId/order/create-visit',
        ],
        component: VisitCreate,
        absolutePath: true,
        exact: true,
      },
      {
        key: 'order_draft_visit',
        path: '/patient/:patientId/order/draft/:draftId',
        component: DraftVisit,
        absolutePath: true,
      },
      {
        key: 'order_visit',
        path: [
          '/patient/:patientId/order/visit/:visitId/create-template',
          '/patient/:patientId/order/visit/:visitId/template/:templateId/edit',
          '/patient/:patientId/order/visit/:visitId/template/:templateId',
          '/patient/:patientId/order/visit/:visitId/templates',
          '/patient/:patientId/order/visit/:visitId/edit',
          '/patient/:patientId/order/visit/:visitId',
        ],
        component: VisitView,
        absolutePath: true,
        exact: true,
      },
      {
        key: 'order_check_visit',
        path: '/patient/:patientId/order/visit/:visitId/check',
        component: VisitCheck,
        absolutePath: true,
      },
    ]
  : [];

const mainRoutes = [
  ...orderRoutes,
  {
    key: 'create-patient',
    path: '/patient/create',
    component: PatientCreate,
    absolutePath: true,
  },
  {
    key: 'profile',
    path: [
      '/patient/:patientId/profile',
      '/patient/:patientId/profile/image/:imageId',
    ],
    component: PatientProfile,
    absolutePath: true,
    exact: true,
  },
  {
    key: 'edit-patient',
    path: '/patient/:patientId/profile/edit',
    component: PatientCreate,
    absolutePath: true,
  },
  {
    key: 'consult',
    path: '/patient/:patientId/consult',
    component: PatientConsult,
    exact: true,
    absolutePath: true,
    defaultContainerPadding: false,
  },
  {
    key: 'consult_create',
    path: '/patient/:patientId/consult/create',
    component: ConsultCreate,
    absolutePath: true,
    defaultContainerPadding: false,
    componentProps: { mode: 'create' },
  },
  {
    key: 'consult_draft',
    path: '/patient/:patientId/consult/:consultId/draft',
    component: ConsultCreate,
    absolutePath: true,
    defaultContainerPadding: false,
    componentProps: { mode: 'draft' },
  },
  {
    key: 'consult_view',
    path: '/patient/:patientId/consult/:consultId/view',
    component: ConsultView,
    absolutePath: true,
    defaultContainerPadding: false,
    componentProps: { mode: 'view' },
  },
  {
    key: 'consult_edit',
    path: '/patient/:patientId/consult/:consultId/edit',
    component: ConsultCreate,
    absolutePath: true,
    defaultContainerPadding: false,
    componentProps: { mode: 'edit' },
  },
  {
    key: 'consult_answer',
    path: '/patient/:patientId/consult/:consultId/answer',
    component: ConsultReply,
    absolutePath: true,
  },
  {
    key: 'radiology',
    path: '/patient/:patientId/radiology',
    component: RadiologyResult,
    absolutePath: true,
    routes: [
      {
        key: 'radiology_service',
        path: '/:serviceId',
        component: RadioResultDetail,
      },
    ],
  },
  {
    key: 'laboratory',
    path: '/patient/:patientId/laboratory',
    component: LabResult,
    absolutePath: true,
    routes: [
      {
        key: 'laboratory_group_result',
        path: '/group/:groupId/result/:resultIndex',
        component: LabResultDetail,
      },
    ],
  },
];

export default mainRoutes;
